import React from "react";
;

export default function SixTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_16{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_16{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_16{left:153px;bottom:1138px;}
#t4_16{left:180px;bottom:1138px;letter-spacing:-0.14px;word-spacing:2.28px;}
#t5_16{left:180px;bottom:1120px;letter-spacing:-0.13px;word-spacing:2.69px;}
#t6_16{left:180px;bottom:1102px;letter-spacing:-0.13px;word-spacing:1.24px;}
#t7_16{left:180px;bottom:1084px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t8_16{left:153px;bottom:1060px;}
#t9_16{left:180px;bottom:1060px;letter-spacing:-0.14px;word-spacing:4.72px;}
#ta_16{left:180px;bottom:1042px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tb_16{left:110px;bottom:1017px;letter-spacing:-0.13px;word-spacing:0.02px;}
#tc_16{left:153px;bottom:993px;}
#td_16{left:180px;bottom:993px;letter-spacing:-0.1px;word-spacing:1.95px;}
#te_16{left:180px;bottom:975px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_16{left:153px;bottom:951px;}
#tg_16{left:180px;bottom:951px;letter-spacing:-0.11px;word-spacing:4.89px;}
#th_16{left:180px;bottom:933px;letter-spacing:-0.13px;word-spacing:-0.45px;}
#ti_16{left:180px;bottom:915px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tj_16{left:153px;bottom:890px;}
#tk_16{left:180px;bottom:890px;letter-spacing:-0.12px;word-spacing:0.48px;}
#tl_16{left:180px;bottom:872px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tm_16{left:153px;bottom:848px;}
#tn_16{left:180px;bottom:848px;letter-spacing:-0.15px;word-spacing:1.1px;}
#to_16{left:180px;bottom:830px;letter-spacing:-0.14px;word-spacing:1.86px;}
#tp_16{left:180px;bottom:812px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tq_16{left:153px;bottom:787px;}
#tr_16{left:180px;bottom:787px;letter-spacing:-0.15px;word-spacing:0.21px;}
#ts_16{left:180px;bottom:769px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tt_16{left:153px;bottom:745px;}
#tu_16{left:180px;bottom:745px;letter-spacing:-0.15px;word-spacing:0.52px;}
#tv_16{left:180px;bottom:727px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tw_16{left:110px;bottom:678px;letter-spacing:-0.14px;word-spacing:-0.45px;}
#tx_16{left:110px;bottom:654px;letter-spacing:-0.18px;word-spacing:0.07px;}
#ty_16{left:153px;bottom:630px;}
#tz_16{left:180px;bottom:630px;letter-spacing:-0.14px;word-spacing:1.81px;}
#t10_16{left:180px;bottom:612px;letter-spacing:-0.13px;word-spacing:3.51px;}
#t11_16{left:180px;bottom:594px;letter-spacing:-0.12px;word-spacing:1.05px;}
#t12_16{left:180px;bottom:575px;letter-spacing:-0.15px;word-spacing:4.88px;}
#t13_16{left:180px;bottom:557px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t14_16{left:153px;bottom:533px;}
#t15_16{left:180px;bottom:533px;letter-spacing:-0.16px;word-spacing:0.96px;}
#t16_16{left:180px;bottom:515px;letter-spacing:-0.15px;word-spacing:2.12px;}
#t17_16{left:180px;bottom:497px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t18_16{left:153px;bottom:473px;}
#t19_16{left:180px;bottom:473px;letter-spacing:-0.17px;word-spacing:2.08px;}
#t1a_16{left:180px;bottom:454px;letter-spacing:-0.16px;word-spacing:2.17px;}
#t1b_16{left:180px;bottom:436px;letter-spacing:-0.17px;word-spacing:3.88px;}
#t1c_16{left:180px;bottom:418px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1d_16{left:153px;bottom:394px;}
#t1e_16{left:180px;bottom:394px;letter-spacing:-0.15px;word-spacing:5.91px;}
#t1f_16{left:180px;bottom:376px;letter-spacing:-0.11px;word-spacing:1.72px;}
#t1g_16{left:180px;bottom:358px;letter-spacing:-0.18px;word-spacing:0.03px;}
#t1h_16{left:180px;bottom:339px;letter-spacing:-0.13px;word-spacing:0.22px;}
#t1i_16{left:180px;bottom:321px;letter-spacing:-0.15px;word-spacing:1.65px;}
#t1j_16{left:180px;bottom:303px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1k_16{left:153px;bottom:279px;}
#t1l_16{left:180px;bottom:279px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1m_16{left:153px;bottom:255px;}
#t1n_16{left:180px;bottom:255px;letter-spacing:-0.15px;word-spacing:5.08px;}
#t1o_16{left:180px;bottom:237px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1p_16{left:153px;bottom:212px;}
#t1q_16{left:180px;bottom:212px;letter-spacing:-0.14px;word-spacing:1.14px;}
#t1r_16{left:180px;bottom:194px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1s_16{left:153px;bottom:170px;}
#t1t_16{left:180px;bottom:170px;letter-spacing:-0.09px;word-spacing:1.01px;}
#t1u_16{left:314px;bottom:170px;letter-spacing:-0.13px;word-spacing:1.04px;}
#t1v_16{left:180px;bottom:152px;letter-spacing:-0.14px;word-spacing:-0.48px;}
#t1w_16{left:180px;bottom:134px;letter-spacing:-0.14px;word-spacing:2.55px;}
#t1x_16{left:180px;bottom:116px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1y_16{left:314px;bottom:116px;letter-spacing:-0.5px;}

.s0_16{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_16{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_16{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s3_16{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_16{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>
        <style id="fonts16" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg16" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="16/16.svg"
          type="image/svg+xml"
          id="pdf16"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_16" class="t s0_16">
          14{" "}
        </span>
        <span id="t2_16" class="t s1_16">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t4_16" class="t s1_16">
          Бага боловсролын 6-10 настай хүүхдүүд аюулгүй байхыг хамгийн чухалд
          үзсэн{" "}
        </span>
        <span id="t5_16" class="t s1_16">
          бол бие даасан байх, бусдаар хүндлүүлэхийг харьцангуй чухал биш гэж
          үзсэн{" "}
        </span>
        <span id="t6_16" class="t s1_16">
          байна. Харин бусдад хүндэтгэлтэй хандах, уламжлал, ёс заншлаа чухал
          хэмээн{" "}
        </span>
        <span id="t7_16" class="t s1_16">
          хүүхдүүд үздэг байна.{" "}
        </span>
        <span id="t8_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t9_16" class="t s1_16">
          Бага боловсролын 6-10 настай хүүхдүүд нийгмийн болон ёс суртахууны хэм{" "}
        </span>
        <span id="ta_16" class="t s1_16">
          хэмжээ, заавар зөвлөгөөг даган биелүүлэх чадвартай байна.{" "}
        </span>
        <span id="tb_16" class="t s3_16">
          Бие бялдрын хөгжлийн онцлог:{" "}
        </span>
        <span id="tc_16" class="t s2_16">
          •{" "}
        </span>
        <span id="td_16" class="t s1_16">
          6-10 настай эрэгтэй, эмэгтэй хүүхдүүдийн өндөр нь жилд дунджаар 4-6
          см-ээр,{" "}
        </span>
        <span id="te_16" class="t s1_16">
          биеийн жин 2-4 кгаар нэмэгддэг байна.{" "}
        </span>
        <span id="tf_16" class="t s2_16">
          •{" "}
        </span>
        <span id="tg_16" class="t s1_16">
          Хөвгүүдийн биеийн цээжний тойргийн хэмжээ жилд 1-2 см-ээр, охидынх 2-3{" "}
        </span>
        <span id="th_16" class="t s1_16">
          см-ээр нэмэгдсэн байна.Хөвгүүдийн бүсэлхийн тойрог 8, 10 насанд
          нэмэгддэг бол{" "}
        </span>
        <span id="ti_16" class="t s1_16">
          охидынх 8 насанд илүү нэмэгджээ.{" "}
        </span>
        <span id="tj_16" class="t s2_16">
          •{" "}
        </span>
        <span id="tk_16" class="t s1_16">
          Хөвгүүд дунджаар гар дээрээ 30 секундэд 17 удаа суниадаг бол охид
          13-14 удаа{" "}
        </span>
        <span id="tl_16" class="t s1_16">
          суниадаг байна.{" "}
        </span>
        <span id="tm_16" class="t s2_16">
          •{" "}
        </span>
        <span id="tn_16" class="t s1_16">
          Авхаалж самбаатай байх чадвар, хурдны чанар охид хөвгүүдийн хувьд
          ялгаагүй{" "}
        </span>
        <span id="to_16" class="t s1_16">
          байна. Холын зайд гүйх тэсвэрт чанар хөвгүүдийнх охидтой харьцуулахад
          илүү{" "}
        </span>
        <span id="tp_16" class="t s1_16">
          үзүүлэлттэй байна.{" "}
        </span>
        <span id="tq_16" class="t s2_16">
          •{" "}
        </span>
        <span id="tr_16" class="t s1_16">
          Хөвгүүдийн хөлийн булчингийн тэсрэлтийн хүч буюу холын зайд харайх нь
          уртын{" "}
        </span>
        <span id="ts_16" class="t s1_16">
          хэмжээ дунджаар 122 см бол охидынх 108 см байна.{" "}
        </span>
        <span id="tt_16" class="t s2_16">
          •{" "}
        </span>
        <span id="tu_16" class="t s1_16">
          Уян хатан чанар буюу суугаа байдлаас бөхийх чадвар нь хөвгүүдийнх 4.4
          см бол{" "}
        </span>
        <span id="tv_16" class="t s1_16">
          охидынх 6.4 см байна.{" "}
        </span>
        <span id="tw_16" class="t s4_16">
          Суурь боловсролын түвшинд сурагчдын танин мэдэхүй, нийгэмшил бие
          бялдрын онцлог:{" "}
        </span>
        <span id="tx_16" class="t s3_16">
          Танин мэдэхүйн онцлог:{" "}
        </span>
        <span id="ty_16" class="t s2_16">
          •{" "}
        </span>
        <span id="tz_16" class="t s1_16">
          11-14 настай хүүхдүүд хялбар танил зүйлсийг ерөнхийд нь таньж хүртэх,
          харин{" "}
        </span>
        <span id="t10_16" class="t s1_16">
          анхаарлаа төвлөрүүлэн, хүчин чармайлттайгаар нарийн бүтэц
          бүрэлдэхүүнийг{" "}
        </span>
        <span id="t11_16" class="t s1_16">
          хүртэж ажиглах, ажиллах чадварыг нь дэмжих хэрэгтэй байна. Хүүхдүүд
          нарийн{" "}
        </span>
        <span id="t12_16" class="t s1_16">
          мэдээлэл тэмдэгтүүдтэй ажиллахдаа анхаарал сарнидаг, ажиллах чадвар нь{" "}
        </span>
        <span id="t13_16" class="t s1_16">
          хурдан буурдаг талтай байна.{" "}
        </span>
        <span id="t14_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t15_16" class="t s1_16">
          Богино хугацаанд 9 хүртэлх дүрс тэмдэгтийг, 5 хүртэлх цифр тоо гэх мэт
          нарийн{" "}
        </span>
        <span id="t16_16" class="t s1_16">
          мэдээллийг тогтоож сэргээн санадаг, дунджаар 6 хүртэлх үгийг сонсоод
          богино{" "}
        </span>
        <span id="t17_16" class="t s1_16">
          хугацаанд тогтоож сэргээн санадаг.{" "}
        </span>
        <span id="t18_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t19_16" class="t s1_16">
          Мэдээллийг харж тогтоохдоо эхэнд байгаа 5 хүртэлх мэдээллийг тогтоодог
          бол{" "}
        </span>
        <span id="t1a_16" class="t s1_16">
          сүүлийн мэдээллийг сэргээн санахгүй байх нь элбэг, харин сонсоод
          тогтоохдоо{" "}
        </span>
        <span id="t1b_16" class="t s1_16">
          эхний болон сүүлийн үгсийг тогтоож сэргээн санадаг, харин дундах
          мэдээлэл{" "}
        </span>
        <span id="t1c_16" class="t s1_16">
          мартагдах магадлалтай байна.{" "}
        </span>
        <span id="t1d_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t1e_16" class="t s1_16">
          11-14 настай хүүхдүүдийн тооцоолох сэтгэхүй, юмсыг бүтэц
          бүрэлдэхүүнээр{" "}
        </span>
        <span id="t1f_16" class="t s1_16">
          задлан шинжлэх, юмсыг ижил төсөөтэй шинжээр нь нэгтгэн дүгнэх, хэл
          ярианы{" "}
        </span>
        <span id="t1g_16" class="t s1_16">
          чадвар зэрэг нь нас нэмэгдэхэд аажмаар нэмэгджээ. Тодруулбал, нас
          нэмэгдэхэд{" "}
        </span>
        <span id="t1h_16" class="t s1_16">
          үгийн нөөц сайжирч, юмсыг учир шалтгаанд нь сэтгэх чадвар нь нэмэгдэн
          гадаад{" "}
        </span>
        <span id="t1i_16" class="t s1_16">
          байдал, төрхөөр нь тоочиж ярих нь буурч ерөнхийлөн дүгнэж ярих
          хандлагатай{" "}
        </span>
        <span id="t1j_16" class="t s1_16">
          болдог байна.{" "}
        </span>
        <span id="t1k_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t1l_16" class="t s1_16">
          11-14 насанд нийт хүүхдүүдийн анхаарлын тогтворжилт сул байна.{" "}
        </span>
        <span id="t1m_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t1n_16" class="t s1_16">
          Хүүхдүүдийн богино хугацаанд шинжлэх ухааны, утга агуулгатай
          мэдээллийг{" "}
        </span>
        <span id="t1o_16" class="t s1_16">
          тогтоох чадвар сул байгаа нь анхаарлын тогтворжилт сул байгаатай
          холбоотой.{" "}
        </span>
        <span id="t1p_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t1q_16" class="t s1_16">
          Хүүхдүүд мэдээллийн утга санааг ойлгохдоо нийтлэг шинжээр нь
          харьцуулах нь{" "}
        </span>
        <span id="t1r_16" class="t s1_16">
          хангалтгүй байна.{" "}
        </span>
        <span id="t1s_16" class="t s2_16">
          •{" "}
        </span>
        <span id="t1t_16" class="t s1_16">
          Нас ахих тусам{" "}
        </span>
        <span id="t1u_16" class="t s1_16">
          хүүхдүүдийн үгийн нөөц нэмэгдсэн ба бүх насанд “бөгөөд, гэвч,{" "}
        </span>
        <span id="t1v_16" class="t s1_16">
          учраас, боловч, миний бодлоор” гэх мэт холбоос хэрэглэсэн нийлмэл
          өгүүлбэрийг{" "}
        </span>
        <span id="t1w_16" class="t s1_16">
          хэрэглэх нь маш сул. Гэтэл энэхүү хэрэглээ нь хүүхдүүдийн хийсвэр
          сэтгэхүйн{" "}
        </span>
        <span id="t1x_16" class="t s1_16">
          хөгжлийн илрэл{" "}
        </span>
        <span id="t1y_16" class="t s1_16">
          байдаг.{" "}
        </span>
      </div>
    </div>
  );
}
