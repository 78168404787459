import React from "react";
;

export default function Four() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_4{left:34px;bottom:19px;}
#t2_4{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_4{left:110px;bottom:1157px;letter-spacing:-0.4px;word-spacing:0.29px;}
#t4_4{left:110px;bottom:1139px;letter-spacing:-0.14px;word-spacing:0.42px;}
#t5_4{left:539px;bottom:1147px;}
#t6_4{left:550px;bottom:1139px;letter-spacing:-0.15px;word-spacing:0.44px;}
#t7_4{left:110px;bottom:1121px;letter-spacing:-0.18px;word-spacing:1.98px;}
#t8_4{left:110px;bottom:1102px;letter-spacing:-0.15px;word-spacing:-1.01px;}
#t9_4{left:110px;bottom:1084px;letter-spacing:-0.14px;word-spacing:2.2px;}
#ta_4{left:110px;bottom:1066px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tb_4{left:110px;bottom:1048px;letter-spacing:-0.17px;word-spacing:3.89px;}
#tc_4{left:110px;bottom:1030px;letter-spacing:-0.12px;word-spacing:-0.61px;}
#td_4{left:110px;bottom:1012px;letter-spacing:-0.16px;word-spacing:8.14px;}
#te_4{left:110px;bottom:994px;letter-spacing:-0.16px;word-spacing:3.04px;}
#tf_4{left:110px;bottom:975px;letter-spacing:-0.15px;word-spacing:6.35px;}
#tg_4{left:110px;bottom:957px;letter-spacing:-0.15px;word-spacing:-1.41px;}
#th_4{left:110px;bottom:939px;letter-spacing:-0.15px;word-spacing:2.95px;}
#ti_4{left:110px;bottom:921px;letter-spacing:-0.13px;word-spacing:4.97px;}
#tj_4{left:110px;bottom:903px;letter-spacing:-0.14px;word-spacing:0.17px;}
#tk_4{left:110px;bottom:885px;letter-spacing:-0.12px;word-spacing:0.65px;}
#tl_4{left:110px;bottom:867px;letter-spacing:-0.12px;word-spacing:0.76px;}
#tm_4{left:110px;bottom:848px;letter-spacing:-0.12px;word-spacing:-1.09px;}
#tn_4{left:110px;bottom:830px;letter-spacing:-0.14px;word-spacing:2.64px;}
#to_4{left:110px;bottom:812px;letter-spacing:-0.23px;word-spacing:0.13px;}
#tp_4{left:110px;bottom:794px;letter-spacing:-0.14px;word-spacing:-0.7px;}
#tq_4{left:110px;bottom:776px;letter-spacing:-0.12px;word-spacing:2.94px;}
#tr_4{left:110px;bottom:758px;letter-spacing:-0.13px;word-spacing:-1.72px;}
#ts_4{left:110px;bottom:740px;letter-spacing:-0.12px;word-spacing:0.71px;}
#tt_4{left:110px;bottom:722px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tu_4{left:110px;bottom:703px;letter-spacing:-0.12px;}
#tv_4{left:211px;bottom:703px;letter-spacing:-0.14px;word-spacing:9.33px;}
#tw_4{left:110px;bottom:685px;letter-spacing:-0.16px;word-spacing:-2.52px;}
#tx_4{left:110px;bottom:667px;letter-spacing:-0.13px;word-spacing:3.13px;}
#ty_4{left:110px;bottom:649px;letter-spacing:-0.12px;word-spacing:2.22px;}
#tz_4{left:110px;bottom:631px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t10_4{left:110px;bottom:613px;letter-spacing:-0.16px;word-spacing:-0.42px;}
#t11_4{left:110px;bottom:595px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t12_4{left:110px;bottom:576px;letter-spacing:-0.15px;word-spacing:-1.49px;}
#t13_4{left:110px;bottom:558px;letter-spacing:-0.14px;word-spacing:0.63px;}
#t14_4{left:110px;bottom:540px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t15_4{left:110px;bottom:522px;letter-spacing:-0.15px;word-spacing:0.37px;}
#t16_4{left:110px;bottom:504px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t17_4{left:110px;bottom:486px;letter-spacing:-0.13px;word-spacing:1.84px;}
#t18_4{left:110px;bottom:468px;letter-spacing:-0.12px;word-spacing:2.57px;}
#t19_4{left:110px;bottom:449px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1a_4{left:110px;bottom:413px;letter-spacing:-0.29px;word-spacing:0.17px;}
#t1b_4{left:110px;bottom:390px;letter-spacing:-0.13px;word-spacing:1.97px;}
#t1c_4{left:110px;bottom:367px;letter-spacing:-0.16px;word-spacing:1.04px;}
#t1d_4{left:110px;bottom:344px;letter-spacing:-0.17px;word-spacing:1.33px;}
#t1e_4{left:110px;bottom:322px;letter-spacing:-0.13px;word-spacing:2.83px;}
#t1f_4{left:110px;bottom:303px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1g_4{left:110px;bottom:285px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1h_4{left:110px;bottom:267px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1i_4{left:55px;bottom:189px;}
#t1j_4{left:75px;bottom:192px;letter-spacing:0.07px;}
#t1k_4{left:55px;bottom:177px;letter-spacing:0.08px;word-spacing:0.03px;}
#t1l_4{left:55px;bottom:163px;letter-spacing:0.09px;word-spacing:2.07px;}
#t1m_4{left:766px;bottom:163px;letter-spacing:0.05px;word-spacing:2.1px;}
#t1n_4{left:55px;bottom:148px;letter-spacing:0.11px;}
#t1o_4{left:118px;bottom:148px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1p_4{left:55px;bottom:133px;letter-spacing:0.08px;word-spacing:0.32px;}
#t1q_4{left:55px;bottom:119px;letter-spacing:0.08px;word-spacing:0.04px;}
#t1r_4{left:55px;bottom:104px;letter-spacing:0.08px;word-spacing:-1.13px;}
#t1s_4{left:55px;bottom:89px;letter-spacing:0.08px;word-spacing:0.03px;}
#t1t_4{left:55px;bottom:75px;letter-spacing:0.08px;word-spacing:0.03px;}

.s0_4{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_4{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_4{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_4{font-size:10px;font-family:ArialMT_5-;color:#231F20;}
.s4_4{font-size:17px;font-family:ArialMT_5-;color:#375FB2;}
.s5_4{font-size:18px;font-family:MinionPro-Regular_6m;color:#231F20;}
.s6_4{font-size:12px;font-family:Arial-ItalicMT_6i;color:#375FB2;}
.s7_4{font-size:12px;font-family:ArialMT_5-;color:#000;}
.s8_4{font-size:12px;font-family:Arial-ItalicMT_6y;color:#333;}
.s9_4{font-size:12px;font-family:ArialMT_5-;color:#333;}`}
        </style>
        <style id="fonts4" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6i;
	src: url("fonts/Arial-ItalicMT_6i.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg2" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="4/4.svg"
          type="image/svg+xml"
          id="pdf4"
          style={pdfStyle}></object>
      </div>

      <div class="text-container">
        <span id="t1_4" class="t s0_4">
          2{" "}
        </span>
        <span id="t2_4" class="t s1_4">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_4" class="t s2_4">
          НЭГ. ҮНДЭСЛЭЛ{" "}
        </span>
        <span id="t4_4" class="t s1_4">
          Бага, дунд боловсролын тухай хуулийн 18 дугаар зүйл{" "}
        </span>
        <span id="t5_4" class="t s3_4">
          1{" "}
        </span>
        <span id="t6_4" class="t s1_4">
          дэх “Хичээлээс гадуурх сургалтын{" "}
        </span>
        <span id="t7_4" class="t s1_4">
          ажил нь суралцагчийн танхимд эзэмшсэн мэдлэг, чадварыг бататгах,
          баяжуулах, тэдний{" "}
        </span>
        <span id="t8_4" class="t s1_4">
          авъяас, сонирхлыг гүнзгийрүүлэн хөгжүүлэхэд чиглэнэ”, Ерөнхий
          боловсролын сургалт, үйл{" "}
        </span>
        <span id="t9_4" class="t s1_4">
          ажиллагаанд тогтвортой хөгжлийн боловсролын үзэл санааг тусган,
          хэрэгжүүлэх үндсэн{" "}
        </span>
        <span id="ta_4" class="t s1_4">
          шаардлагад “Ерөнхий боловсролын сургалт, үйл ажиллагаа, сургалтын
          хөтөлбөрт байгаль{" "}
        </span>
        <span id="tb_4" class="t s1_4">
          орчин, эдийн засаг, нийгэм, соёлын уялдаа холбоог хангасан цогц
          байдлаар агуулгын{" "}
        </span>
        <span id="tc_4" class="t s1_4">
          болон арга зүйн шийдлийг хийх, суралцагчдад хандлага, үнэт зүйлс,
          хэрэглээний чадварыг{" "}
        </span>
        <span id="td_4" class="t s1_4">
          төлөвшүүлэх ажлыг эрчимжүүлэх шаардлагатай”, Ерөнхий боловсролын
          сургуулийн{" "}
        </span>
        <span id="te_4" class="t s1_4">
          үлгэрчилсэн дүрмийн “Сургалтын ажил нь хичээл, хичээлээс гадуурх
          сургалт, хүүхдийн{" "}
        </span>
        <span id="tf_4" class="t s1_4">
          хөгжил, төлөвшлийг хангах тэдгээртэй холбоотой бусад ажлаас бүрдэнэ”,
          “Сургууль{" "}
        </span>
        <span id="tg_4" class="t s1_4">
          хичээлээс гадуурх сургалт, хүмүүжлийн ажлын төлөвлөгөөтэй ажиллана”
          гэж тус тус заасан.{" "}
        </span>
        <span id="th_4" class="t s1_4">
          Монгол Улсын Засгийн Газрын 2016-2020 оны үйл ажиллагааны хөтөлбөрийн
          3.2.13.“...{" "}
        </span>
        <span id="ti_4" class="t s1_4">
          хичээл, сургуулиас гадуурх сонгон, секц, дугуйланг хичээллүүлэх, үр
          дүнд суурилсан{" "}
        </span>
        <span id="tj_4" class="t s1_4">
          санхүүжилтийн аргачлалыг нэвтрүүлж, хүүхдийн авьяас чадварыг нээн
          хөгжүүлнэ” зорилт,{" "}
        </span>
        <span id="tk_4" class="t s1_4">
          БСШУ-ны сайдын 2016 оны А/186 оны “ЕБС, насан туршийн боловсролын төвд
          дугуйлан,{" "}
        </span>
        <span id="tl_4" class="t s1_4">
          секц, клуб ажиллуулах зөвлөмж”, МУ-ын Засгийн газрын 2017 оны 270
          дугаар тогтоолоор{" "}
        </span>
        <span id="tm_4" class="t s1_4">
          баталсан “Хүүхдийн хөгжил, хамгааллын үндэсний хөтөлбөр”, БСШУС-ын
          сайдын 2019 оны{" "}
        </span>
        <span id="tn_4" class="t s1_4">
          А/528 дугаар тушаалаар Ерөнхий боловсролын сургуулийн сургалтын үйл
          ажиллагаанд{" "}
        </span>
        <span id="to_4" class="t s1_4">
          баримтлах чиглэл батлагдсан.{" "}
        </span>
        <span id="tp_4" class="t s1_4">
          2018 онд Дэлхийн банк, БХАН-ээс “Хичээлээс гадуурх үйл ажиллагааны
          нөхцөл байдал”-ыг{" "}
        </span>
        <span id="tq_4" class="t s1_4">
          илрүүлэх зорилгоор хийсэн шинжилгээний зарим үр дүнд болон “ЕБС-ийн
          дунд ангийн{" "}
        </span>
        <span id="tr_4" class="t s1_4">
          хичээлээс гадуурх үйл ажиллагаанд хийсэн хөндлөнгийн үнэлгээ”-ний
          тайлан, Боловсролын{" "}
        </span>
        <span id="ts_4" class="t s1_4">
          хүрээлэнгийн хийсэн “ЕБС-ийн хичээлээс гадуурх үйл ажиллагааны
          хэрэгжилтийн нөхцөл{" "}
        </span>
        <span id="tt_4" class="t s1_4">
          байдал”-ын судалгааг үндэслэл болгосон.{" "}
        </span>
        <span id="tu_4" class="t s1_4">
          “ЕБС-ийн{" "}
        </span>
        <span id="tv_4" class="t s1_4">
          хичээлээс гадуурх үйл ажиллагааны хэрэгжилтийн нөхцөл байдал”-ын{" "}
        </span>
        <span id="tw_4" class="t s1_4">
          судалгаагаар ЕБС-ийн ХГҮА-ны төлөвлөлтийг авч үзэхэд сургуулийн
          хэмжээнд төлөвлөгдсөн{" "}
        </span>
        <span id="tx_4" class="t s1_4">
          ажлын 47.0 хувь нь хүүхдэд ээлтэй орчин бүрдүүлэх, хамгаалах, 17.6
          хувь нь эцэг эх,{" "}
        </span>
        <span id="ty_4" class="t s1_4">
          олон нийтийн оролцоог нэмэгдүүлж, хүүхдийн хөгжлийг дэмжих, 35.2 хувь
          нь сургалтын{" "}
        </span>
        <span id="tz_4" class="t s1_4">
          хөтөлбөрийг хэрэгжүүлэх, үнэлэх, зөвлөн туслахад чиглэсэн байна.{" "}
        </span>
        <span id="t10_4" class="t s1_4">
          Судалгаанд хамрагдсан сурагчдын 76.0 хувь нь урлагийн үзлэг, 58.1 хувь
          нь секц дугуйлан,{" "}
        </span>
        <span id="t11_4" class="t s1_4">
          олимпиад, 64.0 хувь нь спортын уралдаан тэмцээнд оролцдог гэж
          хариулсан байна.{" "}
        </span>
        <span id="t12_4" class="t s1_4">
          Сургуулиас зохион байгуулж байгаа ажил тэдний сонирхолд нийцсэн үйл
          ажиллагааг зохион{" "}
        </span>
        <span id="t13_4" class="t s1_4">
          байгуулдаг гэж 81.1 хувь нь хариулсан байгаа нь энэ чиглэлийн үйл
          ажиллагаа сурагчдад{" "}
        </span>
        <span id="t14_4" class="t s1_4">
          хэрэгцээтэй байгааг илэрхийлж байна.{" "}
        </span>
        <span id="t15_4" class="t s1_4">
          ХГҮА-г зохион байгуулдаг багш нарын хувьд улсын хэмжээнд нэгдсэн нэг
          загвартай болох{" "}
        </span>
        <span id="t16_4" class="t s1_4">
          ба зөвлөмж, гарын авлагатай байх саналуудыг илэрхийлсэн байна.{" "}
        </span>
        <span id="t17_4" class="t s1_4">
          Иймд энэхүү үлгэрчилсэн хөтөлбөр нь хүүхдийн оролцоонд тулгуурлан
          сурагчдын танин{" "}
        </span>
        <span id="t18_4" class="t s1_4">
          мэдэхүй, бие бялдар, нийгэмшлийн хөгжлийг дэмжих, ХГҮА-г сургуулийн
          жилийн болон{" "}
        </span>
        <span id="t19_4" class="t s1_4">
          ангийн төлөвлөлтөд тусган хэрэгжүүлэхэд багш нарт чиглэл болно.{" "}
        </span>
        <span id="t1a_4" class="t s4_4">
          ХОЁР. ХИЧЭЭЛЭЭС ГАДУУРХ ҮЙЛ АЖИЛЛАГААНЫ ТУХАЙ ОЙЛГОЛТ{" "}
        </span>
        <span id="t1b_4" class="t s1_4">
          Хичээлээс гадуурх үйл ажиллагаа (extracurricular activities)-г
          сургалтын нэг чухал талбар{" "}
        </span>
        <span id="t1c_4" class="t s1_4">
          хэмээн тодорхойлсон байдаг. Хичээлээс гадуурх үйл ажиллагаа (ХГҮА) дэх
          суралцагчийн{" "}
        </span>
        <span id="t1d_4" class="t s1_4">
          оролцооны талаарх хийгдсэн судалгааны үр дүн багагүй байна. Тухайлбал,
          Boone, Kurtz,{" "}
        </span>
        <span id="t1e_4" class="t s1_4">
          Fleenor (1988) нарын судалгаанд, ХГҮА-д оролцоо илүү байсан
          суралцагчийн ирээдүйн{" "}
        </span>
        <span id="t1f_4" class="t s1_4">
          мэргэжлийн ахиц дэвшил (карьер), амжилт дагаад илүү байгааг дурдсан
          бол Рубин,{" "}
        </span>
        <span id="t1g_4" class="t s1_4">
          Боммер, Болдуин нар (2002) судалгаандаа ХГҮА-ны оролцоо илүү байсан
          суралцагчийн{" "}
        </span>
        <span id="t1h_4" class="t s1_4">
          манлайлал, харилцааны ур чадвар илүү байна гэдгийг онцолж дурджээ.{" "}
        </span>
        <span id="t1i_4" class="t s5_4">
          1{" "}
        </span>
        <span id="t1j_4" class="t s6_4">
          http://www.legalinfo.mn/{" "}
        </span>
        <span id="t1k_4" class="t s7_4">
          Бага, дунд боловсролын тухай хууль, 18 зүйл. Хичээлээс гадуурх
          сургалтын ажил{" "}
        </span>
        <span id="t1l_4" class="t s8_4">
          18.1.Хичээлээс гадуурх сургалтын ажил нь суралцагчийн танхимд эзэмшсэн
          мэдлэг, чадварыг бататгах, баяжуулах,{" "}
        </span>
        <span id="t1m_4" class="t s7_4">
          тэдний авъяас,{" "}
        </span>
        <span id="t1n_4" class="t s7_4">
          сонирхлыг{" "}
        </span>
        <span id="t1o_4" class="t s9_4">
          гүнзгийрүүлэн хөгжүүлэхэд чиглэнэ.{" "}
        </span>
        <span id="t1p_4" class="t s8_4">
          18.2.Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөрийг
          боловсролын асуудал эрхэлсэн төрийн захиргааны төв байгууллага{" "}
        </span>
        <span id="t1q_4" class="t s8_4">
          холбогдох мэргэжлийн байгууллагатай хамтран тогтооно.{" "}
        </span>
        <span id="t1r_4" class="t s8_4">
          18.З.Сургуулийн захиргаа нь хичээлээс гадуурх сургалтын ажлыг
          дугуйлан, түр сургалт, соёл, урлаг, спортын арга хэмжээ зэрэг
          хэлбэрээр{" "}
        </span>
        <span id="t1s_4" class="t s8_4">
          үлгэрчилсэн хөтөлбөр, хуваарийн дагуу холбогдох байгууллага, эцэг, эх,
          асран хамгаалагч, иргэдтэй хамтран зохион байгуулна.{" "}
        </span>
        <span id="t1t_4" class="t s8_4">
          18.4.Сургууль хүндэтгэлийн танхим, булантай байж болно.{" "}
        </span>
      </div>
    </div>
  );
}
