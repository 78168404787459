import React from "react";
;

export default function TwentyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p29" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_29{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_29{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_29{left:110px;bottom:1157px;letter-spacing:-0.14px;word-spacing:0.84px;}
#t4_29{left:110px;bottom:1139px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t5_29{left:153px;bottom:1114px;}
#t6_29{left:180px;bottom:1114px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t7_29{left:153px;bottom:1096px;}
#t8_29{left:180px;bottom:1096px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t9_29{left:153px;bottom:1078px;}
#ta_29{left:180px;bottom:1078px;letter-spacing:-0.16px;word-spacing:7.27px;}
#tb_29{left:180px;bottom:1060px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tc_29{left:110px;bottom:1036px;letter-spacing:-0.12px;word-spacing:-2px;}
#td_29{left:110px;bottom:1018px;letter-spacing:-0.14px;word-spacing:2.52px;}
#te_29{left:110px;bottom:999px;letter-spacing:-0.17px;word-spacing:0.03px;}
#tf_29{left:110px;bottom:981px;letter-spacing:-0.14px;word-spacing:0.06px;}
#tg_29{left:110px;bottom:963px;letter-spacing:-0.14px;word-spacing:0.05px;}
#th_29{left:110px;bottom:939px;letter-spacing:-0.15px;word-spacing:0.73px;}
#ti_29{left:110px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.35px;}
#tj_29{left:110px;bottom:903px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tk_29{left:186px;bottom:854px;letter-spacing:-0.22px;word-spacing:0.1px;}
#tl_29{left:110px;bottom:753px;letter-spacing:-0.11px;}
#tm_29{left:228px;bottom:753px;letter-spacing:-0.14px;word-spacing:1.6px;}
#tn_29{left:110px;bottom:735px;letter-spacing:-0.14px;word-spacing:4.25px;}
#to_29{left:110px;bottom:717px;letter-spacing:-0.13px;word-spacing:-1.71px;}
#tp_29{left:110px;bottom:668px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tq_29{left:153px;bottom:644px;}
#tr_29{left:180px;bottom:644px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ts_29{left:153px;bottom:626px;}
#tt_29{left:180px;bottom:626px;letter-spacing:-0.13px;word-spacing:9.25px;}
#tu_29{left:180px;bottom:608px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tv_29{left:153px;bottom:590px;}
#tw_29{left:180px;bottom:590px;letter-spacing:-0.12px;word-spacing:-0.65px;}
#tx_29{left:180px;bottom:572px;letter-spacing:-0.14px;word-spacing:6.22px;}
#ty_29{left:180px;bottom:554px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tz_29{left:153px;bottom:535px;}
#t10_29{left:180px;bottom:535px;letter-spacing:-0.16px;word-spacing:-1.1px;}
#t11_29{left:180px;bottom:517px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t12_29{left:180px;bottom:499px;letter-spacing:-0.16px;word-spacing:0.64px;}
#t13_29{left:180px;bottom:481px;letter-spacing:-0.24px;}
#t14_29{left:153px;bottom:463px;}
#t15_29{left:180px;bottom:463px;letter-spacing:-0.15px;word-spacing:5.7px;}
#t16_29{left:180px;bottom:445px;letter-spacing:-0.17px;word-spacing:2.78px;}
#t17_29{left:180px;bottom:427px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t18_29{left:110px;bottom:378px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t19_29{left:110px;bottom:354px;letter-spacing:-0.13px;word-spacing:2.22px;}
#t1a_29{left:110px;bottom:336px;letter-spacing:-0.19px;word-spacing:4.12px;}
#t1b_29{left:110px;bottom:318px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1c_29{left:110px;bottom:269px;letter-spacing:-0.1px;word-spacing:0.02px;}
#t1d_29{left:110px;bottom:245px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1e_29{left:179px;bottom:245px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1f_29{left:110px;bottom:220px;letter-spacing:-0.12px;word-spacing:0.01px;}
#t1g_29{left:110px;bottom:202px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1h_29{left:183px;bottom:202px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t1i_29{left:218px;bottom:176px;}
#t1j_29{left:449px;bottom:176px;}
#t1k_29{left:702px;bottom:170px;}
#t1l_29{left:119px;bottom:151px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1m_29{left:119px;bottom:133px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1n_29{left:119px;bottom:115px;letter-spacing:-0.28px;}
#t1o_29{left:344px;bottom:151px;letter-spacing:-0.19px;word-spacing:0.1px;}
#t1p_29{left:344px;bottom:133px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1q_29{left:344px;bottom:115px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1r_29{left:582px;bottom:146px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1s_29{left:583px;bottom:128px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1t_29{left:583px;bottom:110px;letter-spacing:-0.23px;}
#t1u_29{left:131px;bottom:816px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_29{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_29{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_29{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_29{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s4_29{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}`}
        </style>
        <style id="fonts29" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg29" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="29/29.svg"
          type="image/svg+xml"
          id="pdf29"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_29" class="t s0_29">
          27{" "}
        </span>
        <span id="t2_29" class="t s1_29">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_29" class="t s1_29">
          Сурагчдын хийх үйл ажиллагаа: Сурагчид багт хуваагдан хугацааг товлон,
          дарааллаараа{" "}
        </span>
        <span id="t4_29" class="t s1_29">
          өртөөгөөр тойрон явна.{" "}
        </span>
        <span id="t5_29" class="t s1_29">
          -{" "}
        </span>
        <span id="t6_29" class="t s1_29">
          Анхны тусламжийн хэрэгцээ, шаардлага, зорилгыг ойлгох{" "}
        </span>
        <span id="t7_29" class="t s1_29">
          -{" "}
        </span>
        <span id="t8_29" class="t s1_29">
          Өртөөгөөр тойрч анхны тусламжийн аргачлалд суралцах, туршилт хийж үзэх{" "}
        </span>
        <span id="t9_29" class="t s1_29">
          -{" "}
        </span>
        <span id="ta_29" class="t s1_29">
          Сурч авсан зүйлсийн талаар багаараа ярилцах өөрт төрсөн сэтгэгдлээрээ{" "}
        </span>
        <span id="tb_29" class="t s1_29">
          илэрхийлэн бичих, ярих{" "}
        </span>
        <span id="tc_29" class="t s2_29">
          Анхаарах зүйл: Өртөө тус бүрээр аялах үед өртөө хариуцсан ажилтан
          анхны тусламжийн{" "}
        </span>
        <span id="td_29" class="t s2_29">
          хэрэгцээ шаардлага, зорилгыг тайлбарлана. Ялангуяа гамшгийн үед хүнд
          өвчнүүдийг{" "}
        </span>
        <span id="te_29" class="t s2_29">
          эмнэлэгт хүргэх, хөнгөн гэмтэлтэй бол хэрхэн арга хэмжээ авах талаар
          мэдээлэл өгнө.{" "}
        </span>
        <span id="tf_29" class="t s2_29">
          Мөн гарын доорх материалаар эмнэлгийн тусламж үзүүлэх аргуудыг
          бодитоор турших{" "}
        </span>
        <span id="tg_29" class="t s2_29">
          дадлага хийнэ. Тухайлбал,{" "}
        </span>
        <span id="th_29" class="t s2_29">
          Зүсэгдэх, зулгарах үед - Усаар угаах, хүнсний скочоор ороох, уяа
          ашиглан цус тогтоох,{" "}
        </span>
        <span id="ti_29" class="t s2_29">
          боолт хийх, Яс хугарах үед- Борооны шүхэр, сонин, сэтгүүл зэргээр чиг
          барьж скоч, уяа{" "}
        </span>
        <span id="tj_29" class="t s2_29">
          зэргээр боох, сойлт хийх, Булгалах үед- Нийлэг уутанд мөс хийж хүйтэн
          жин тавих{" "}
        </span>
        <span id="tk_29" class="t s1_29">
          ГУРАВ. БОЛЗОШГҮЙ ЭРСДЭЛ, ЗӨРЧЛӨӨС УРЬДЧИЛАН СЭРГИЙЛЭХ{" "}
        </span>
        <span id="tl_29" class="t s2_29">
          3.1.1.Зорилго.{" "}
        </span>
        <span id="tm_29" class="t s1_29">
          ЕБС-ийн суралцагчдад болзошгүй эрсдэл, зөрчил гэж юуг ойлгох, зөрчил,{" "}
        </span>
        <span id="tn_29" class="t s1_29">
          хүчирхийлэл үйлдэгдэн гарахад нөлөөлж байгаа шалтгаан нөхцөл, түүнээс
          урьдчилан{" "}
        </span>
        <span id="to_29" class="t s1_29">
          сэргийлэх арга, хувь хүний хариуцлагын талаар анхан шатны мэдлэг
          эзэмшүүлэхэд оршино.{" "}
        </span>
        <span id="tp_29" class="t s2_29">
          3.1.2.Үндсэн ойлголт{" "}
        </span>
        <span id="tq_29" class="t s1_29">
          -{" "}
        </span>
        <span id="tr_29" class="t s1_29">
          Болзошгүй эрсдэл, зөрчлөөс урьдчилан сэргийлэх тухай ойлголт{" "}
        </span>
        <span id="ts_29" class="t s1_29">
          -{" "}
        </span>
        <span id="tt_29" class="t s1_29">
          Хүүхдүүд өөрсдийгөө хүчирхийллээс хамгаалахын тулд аюулгүйн дүрмийг{" "}
        </span>
        <span id="tu_29" class="t s1_29">
          баримталж сурах, түүнчлэн дүрмийг уншиж, хэвшүүлэх талаарх ойлголт{" "}
        </span>
        <span id="tv_29" class="t s1_29">
          -{" "}
        </span>
        <span id="tw_29" class="t s1_29">
          Сургууль, нийгмийн орчинд өөрийгөө болон найз нөхдийгөө эрсдэлээс
          хамгаалах,{" "}
        </span>
        <span id="tx_29" class="t s1_29">
          сонирхол хэрэгцээндээ тулгуурлан болзошгүй эрсдэл, зөрчилд орох,
          түүний{" "}
        </span>
        <span id="ty_29" class="t s1_29">
          золиос болохгүй байх талаарх мэдлэг ойлголт{" "}
        </span>
        <span id="tz_29" class="t s1_29">
          -{" "}
        </span>
        <span id="t10_29" class="t s1_29">
          Оролцогчдод аливаа зөрчлийг шийдвэрлэдэг гол хэрэгсэл нь хууль байдаг
          хуулийг{" "}
        </span>
        <span id="t11_29" class="t s1_29">
          дагаж мөрдөхийн ач холбогдол, иргэн хэдэн насандаа ямар үйлдлийг хийж
          болох{" "}
        </span>
        <span id="t12_29" class="t s1_29">
          ба болохгүй тухай хуулийн заалтууд, иргэний хүлээх үүрэг, хариуцлагын
          талаарх{" "}
        </span>
        <span id="t13_29" class="t s1_29">
          мэдээлэл{" "}
        </span>
        <span id="t14_29" class="t s1_29">
          -{" "}
        </span>
        <span id="t15_29" class="t s1_29">
          Суралцагчдад аливаа зөрчлийг шийдвэрлэдэг гол хэрэгсэл нь хууль бөгөөд{" "}
        </span>
        <span id="t16_29" class="t s1_29">
          хуулийг дагаж мөрдөхийн ач холбогдол, олон нийтийн дунд биеэ зөв авч
          явах{" "}
        </span>
        <span id="t17_29" class="t s1_29">
          чадвар хандлага{" "}
        </span>
        <span id="t18_29" class="t s1_29">
          3.13. Үйл ажиллагаа{" "}
        </span>
        <span id="t19_29" class="t s1_29">
          Үндсэн үйл ажиллагааг суралцагчдад болзошгүй эрсдэл, зөрчил, хууль эрх
          зүйн талаар{" "}
        </span>
        <span id="t1a_29" class="t s1_29">
          мэдээлэл өгч, хэлэлцүүлэг, хэлбэрээр зохион байгуулна. Хэлэлцүүлгийг
          хэрхэн зохион{" "}
        </span>
        <span id="t1b_29" class="t s1_29">
          байгуулах талаар дараах жишээг санал болгож байна.{" "}
        </span>
        <span id="t1c_29" class="t s2_29">
          3.1.4.Арга зүй{" "}
        </span>
        <span id="t1d_29" class="t s3_29">
          Загвар 1
        </span>
        <span id="t1e_29" class="t s4_29">
          . Нийгмийн харилцаа хэлэлцүүлэг{" "}
        </span>
        <span id="t1f_29" class="t s2_29">
          Үйл ажиллагаа:{" "}
        </span>
        <span id="t1g_29" class="t s2_29">
          Алхам 1.{" "}
        </span>
        <span id="t1h_29" class="t s1_29">
          Дараах хүснэгтийг дэлгэцээр үзүүлж асуулт дэвшүүлэн ярилцана.{" "}
        </span>
        <span id="t1i_29" class="t s1_29">
          а{" "}
        </span>
        <span id="t1j_29" class="t s1_29">
          в{" "}
        </span>
        <span id="t1k_29" class="t s1_29">
          с{" "}
        </span>
        <span id="t1l_29" class="t s1_29">
          Идэр найз нартаа{" "}
        </span>
        <span id="t1m_29" class="t s1_29">
          үргэлж худлаа ярьдаг нь{" "}
        </span>
        <span id="t1n_29" class="t s1_29">
          батлагджээ.{" "}
        </span>
        <span id="t1o_29" class="t s1_29">
          Сурагч Цэвэл 11 цагийн{" "}
        </span>
        <span id="t1p_29" class="t s1_29">
          хичээлийг шалтгаангүй{" "}
        </span>
        <span id="t1q_29" class="t s1_29">
          тасалсан нь нотлогджээ.{" "}
        </span>
        <span id="t1r_29" class="t s1_29">
          Иргэн Бадарч саахалт айлын{" "}
        </span>
        <span id="t1s_29" class="t s1_29">
          сүрэг адууг хулгайлаад{" "}
        </span>
        <span id="t1t_29" class="t s1_29">
          баригджээ.{" "}
        </span>
        <span id="t1u_29" class="t s1_29">
          3.1.Хүүхдийн эрх зүйн мэдлэг, соёлыг дээшлүүлэхэд чиглэсэн ажлыг
          эрчимжүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
