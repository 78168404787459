import React from "react";
;

export default function Six() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_6{left:34px;bottom:19px;}
#t2_6{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_6{left:110px;bottom:1157px;letter-spacing:-0.24px;word-spacing:0.13px;}
#t4_6{left:110px;bottom:1139px;letter-spacing:-0.13px;word-spacing:-0.6px;}
#t5_6{left:110px;bottom:1121px;letter-spacing:-0.13px;word-spacing:-0.86px;}
#t6_6{left:110px;bottom:1102px;letter-spacing:-0.16px;word-spacing:4.09px;}
#t7_6{left:110px;bottom:1084px;letter-spacing:-0.08px;word-spacing:-0.01px;}
#t8_6{left:110px;bottom:1048px;letter-spacing:-0.19px;word-spacing:0.07px;}
#t9_6{left:110px;bottom:1030px;letter-spacing:-0.11px;word-spacing:0.01px;}
#ta_6{left:157px;bottom:1012px;}
#tb_6{left:185px;bottom:1012px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tc_6{left:157px;bottom:994px;}
#td_6{left:185px;bottom:994px;letter-spacing:-0.14px;word-spacing:0.04px;}
#te_6{left:157px;bottom:975px;}
#tf_6{left:185px;bottom:975px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tg_6{left:157px;bottom:957px;}
#th_6{left:185px;bottom:957px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ti_6{left:157px;bottom:939px;}
#tj_6{left:185px;bottom:939px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_6{left:157px;bottom:921px;}
#tl_6{left:185px;bottom:921px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tm_6{left:157px;bottom:903px;}
#tn_6{left:185px;bottom:903px;letter-spacing:-0.1px;}
#to_6{left:157px;bottom:885px;}
#tp_6{left:185px;bottom:885px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tq_6{left:110px;bottom:848px;letter-spacing:-0.21px;word-spacing:0.09px;}
#tr_6{left:110px;bottom:830px;letter-spacing:-0.11px;word-spacing:0.01px;}
#ts_6{left:153px;bottom:806px;}
#tt_6{left:180px;bottom:806px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tu_6{left:153px;bottom:788px;}
#tv_6{left:180px;bottom:788px;letter-spacing:-0.13px;word-spacing:5.3px;}
#tw_6{left:180px;bottom:770px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tx_6{left:110px;bottom:746px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ty_6{left:153px;bottom:721px;}
#tz_6{left:180px;bottom:721px;letter-spacing:-0.17px;word-spacing:3.23px;}
#t10_6{left:180px;bottom:703px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t11_6{left:153px;bottom:685px;}
#t12_6{left:180px;bottom:685px;letter-spacing:-0.11px;word-spacing:-2.39px;}
#t13_6{left:180px;bottom:667px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t14_6{left:153px;bottom:649px;}
#t15_6{left:180px;bottom:649px;letter-spacing:-0.13px;word-spacing:0.13px;}
#t16_6{left:180px;bottom:631px;letter-spacing:-0.12px;}
#t17_6{left:110px;bottom:606px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t18_6{left:153px;bottom:582px;}
#t19_6{left:180px;bottom:582px;letter-spacing:-0.17px;word-spacing:-1.36px;}
#t1a_6{left:180px;bottom:564px;letter-spacing:-0.12px;word-spacing:0.53px;}
#t1b_6{left:180px;bottom:546px;letter-spacing:-0.1px;}
#t1c_6{left:153px;bottom:528px;}
#t1d_6{left:180px;bottom:528px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1e_6{left:153px;bottom:510px;}
#t1f_6{left:180px;bottom:510px;letter-spacing:-0.12px;word-spacing:6.43px;}
#t1g_6{left:180px;bottom:491px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1h_6{left:153px;bottom:473px;}
#t1i_6{left:180px;bottom:473px;letter-spacing:-0.14px;word-spacing:5.55px;}
#t1j_6{left:180px;bottom:455px;letter-spacing:-0.12px;}
#t1k_6{left:110px;bottom:431px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1l_6{left:153px;bottom:407px;}
#t1m_6{left:180px;bottom:407px;letter-spacing:-0.13px;word-spacing:4.15px;}
#t1n_6{left:180px;bottom:389px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1o_6{left:153px;bottom:370px;}
#t1p_6{left:180px;bottom:370px;letter-spacing:-0.14px;word-spacing:10px;}
#t1q_6{left:180px;bottom:352px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1r_6{left:153px;bottom:334px;}
#t1s_6{left:180px;bottom:334px;letter-spacing:-0.16px;word-spacing:2.62px;}
#t1t_6{left:180px;bottom:316px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1u_6{left:153px;bottom:298px;}
#t1v_6{left:180px;bottom:298px;letter-spacing:-0.19px;word-spacing:4.86px;}
#t1w_6{left:180px;bottom:280px;letter-spacing:-0.19px;word-spacing:11.03px;}
#t1x_6{left:180px;bottom:262px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1y_6{left:110px;bottom:237px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1z_6{left:153px;bottom:213px;}
#t20_6{left:180px;bottom:213px;letter-spacing:-0.15px;word-spacing:-1.71px;}
#t21_6{left:180px;bottom:195px;letter-spacing:-0.1px;}
#t22_6{left:153px;bottom:177px;}
#t23_6{left:180px;bottom:177px;letter-spacing:-0.12px;word-spacing:3.54px;}
#t24_6{left:180px;bottom:159px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t25_6{left:153px;bottom:141px;}
#t26_6{left:180px;bottom:141px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_6{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_6{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_6{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_6{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>
        <style id="fonts6" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg6" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="6/6.svg"
          type="image/svg+xml"
          id="pdf6"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_6" class="t s0_6">
          4{" "}
        </span>
        <span id="t2_6" class="t s1_6">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_6" class="t s2_6">
          ГУРАВ. ЗОРИЛГО, ЗОРИЛТ{" "}
        </span>
        <span id="t4_6" class="t s1_6">
          Сурагч өөртөө итгэлтэй, бие бялдар, оюун сэтгэхүй, ёс суртахууны
          төлөвшилтэй, нийгмийн{" "}
        </span>
        <span id="t5_6" class="t s1_6">
          олон талт харилцаанд зохицон, бусдыг манлайлж, хамт олны хөгжилд
          өөрийн хувь нэмрийг{" "}
        </span>
        <span id="t6_6" class="t s1_6">
          оруулах, шийдвэр гаргах чадвартай, бүтээлч, санаачилгатай хувь хүн
          болон хөгжихөд{" "}
        </span>
        <span id="t7_6" class="t s1_6">
          дэмжлэг үзүүлнэ.{" "}
        </span>
        <span id="t8_6" class="t s2_6">
          ДӨРӨВ. ЗОРИЛГО, ЗОРИЛТ{" "}
        </span>
        <span id="t9_6" class="t s1_6">
          Хөтөлбөрийн зорилгыг хэрэгжүүлэхийн тулд дараах зорилтыг дэвшүүлж
          байна.{" "}
        </span>
        <span id="ta_6" class="t s3_6">
          •{" "}
        </span>
        <span id="tb_6" class="t s1_6">
          Гэр бүлийн хүрээнд олгох хүмүүжил, төлөвшлийг дэмжих{" "}
        </span>
        <span id="tc_6" class="t s3_6">
          •{" "}
        </span>
        <span id="td_6" class="t s1_6">
          Эрүүл, аюулгүй амьдрах арга ухаанд сургах{" "}
        </span>
        <span id="te_6" class="t s3_6">
          •{" "}
        </span>
        <span id="tf_6" class="t s1_6">
          Болзошгүй эрсдэл, зөрчлөөс урьдчилан сэргийлэх ур чадвар эзэмшүүлэх{" "}
        </span>
        <span id="tg_6" class="t s3_6">
          •{" "}
        </span>
        <span id="th_6" class="t s1_6">
          Бие бялдар, авьяас чадварыг хөгжүүлэх{" "}
        </span>
        <span id="ti_6" class="t s3_6">
          •{" "}
        </span>
        <span id="tj_6" class="t s1_6">
          Мэдээллийн технологийн зохистой хэрэглээг дэмжих{" "}
        </span>
        <span id="tk_6" class="t s3_6">
          •{" "}
        </span>
        <span id="tl_6" class="t s1_6">
          Суралцагчийн хөгжил болон нийгэм, олон нийтийн үйл ажиллагааг дэмжих{" "}
        </span>
        <span id="tm_6" class="t s3_6">
          •{" "}
        </span>
        <span id="tn_6" class="t s1_6">
          Эх оронч болон хөдөлмөрийн хүмүүжлийг дэмжих{" "}
        </span>
        <span id="to_6" class="t s3_6">
          •{" "}
        </span>
        <span id="tp_6" class="t s1_6">
          Байгаль, экологийн хүмүүжлийг төлөвшүүлэх{" "}
        </span>
        <span id="tq_6" class="t s2_6">
          ТАВ. ЗОРИЛТУУДЫН ХҮРЭЭНД ХЭРЭГЖҮҮЛЭХ ҮЙЛ АЖИЛЛАГАА{" "}
        </span>
        <span id="tr_6" class="t s1_6">
          Зорилт 1. Гэр бүлийн хүрээнд олгох хүмүүжил, төлөвшлийг дэмжих{" "}
        </span>
        <span id="ts_6" class="t s1_6">
          -{" "}
        </span>
        <span id="tt_6" class="t s1_6">
          Хүмүүжлийн үйл ажиллагаанд гэр бүлийн оролцоог нэмэгдүүлэх{" "}
        </span>
        <span id="tu_6" class="t s1_6">
          -{" "}
        </span>
        <span id="tv_6" class="t s1_6">
          Хүүхэд хүмүүжүүлэх гэр бүлийн уламжлалт соёл, ёс суртахууны үнэт зүйлд{" "}
        </span>
        <span id="tw_6" class="t s1_6">
          тулгуурласан хүмүүжлийн ажилд дэмжлэг үзүүлэх{" "}
        </span>
        <span id="tx_6" class="t s1_6">
          Зорилт 2. Эрүүл, аюулгүй амьдрах арга ухаанд сургах{" "}
        </span>
        <span id="ty_6" class="t s1_6">
          -{" "}
        </span>
        <span id="tz_6" class="t s1_6">
          Амьдралын аюулгүй байдлыг эрхэмлэдэг, хорт зуршлаас урьдчилан
          сэргийлэх{" "}
        </span>
        <span id="t10_6" class="t s1_6">
          соёлыг төлөвшүүлэх үйл ажиллагааг эрчимжүүлэх{" "}
        </span>
        <span id="t11_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t12_6" class="t s1_6">
          Хүүхдийг идэвхтэй, эрүүл амьдралын хэв маягт сургах, эрүүл хоол хүнс
          хэрэглэхийн{" "}
        </span>
        <span id="t13_6" class="t s1_6">
          ач холбогдлыг таниулахад чиглэсэн үйл ажиллагаанд дэмжлэг үзүүлэх{" "}
        </span>
        <span id="t14_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t15_6" class="t s1_6">
          Хүүхдийн эрүүл мэнд, аюулгүй амьдрах ухааны боловсрол олгох
          хөтөлбөрүүдийг{" "}
        </span>
        <span id="t16_6" class="t s1_6">
          хөгжүүлэх{" "}
        </span>
        <span id="t17_6" class="t s1_6">
          Зорилт 3. Болзошгүй эрсдэл, зөрчлөөс урьдчилан сэргийлэх ур чадвар
          эзэмшүүлэх{" "}
        </span>
        <span id="t18_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t19_6" class="t s1_6">
          Хүүхдийг шударга ёс, шударга байдал, нинжин сэтгэлийг эрхэмлэдэг, эцэг
          эх, багш,{" "}
        </span>
        <span id="t1a_6" class="t s1_6">
          ахмад үе, үе тэнгийнхэн болон бусад хүмүүсийг хүндэтгэх үзлийг
          төлөвшүүлэхэд{" "}
        </span>
        <span id="t1b_6" class="t s1_6">
          дэмжлэг үзүүлэх{" "}
        </span>
        <span id="t1c_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1d_6" class="t s1_6">
          Хүүхдийн эрх зүйн мэдлэг, соёлыг дээшлүүлэхэд чиглэсэн ажлыг
          эрчимжүүлэх{" "}
        </span>
        <span id="t1e_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1f_6" class="t s1_6">
          Өсвөр насныхны дундах дарамт дээрэлхэлтээс урьдчилан сэргийлэх орчин{" "}
        </span>
        <span id="t1g_6" class="t s1_6">
          нөхцөл бүрдүүлэх, сургалт нөлөөллийн ажлыг зохион байгуулах{" "}
        </span>
        <span id="t1h_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1i_6" class="t s1_6">
          Замын хөдөлгөөний дүрмийг сурталчилан таниулах, соёлтой оролцох дадал{" "}
        </span>
        <span id="t1j_6" class="t s1_6">
          төлөвшүүлэх{" "}
        </span>
        <span id="t1k_6" class="t s1_6">
          Зорилт 4. Бие бялдар, авьяас чадвараа хөгжүүлэх{" "}
        </span>
        <span id="t1l_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1m_6" class="t s1_6">
          Хүүхдийн оюун ухааныг хөгжүүлэхэд чиглэсэн шинжлэх ухаан-танин
          мэдэхүй,{" "}
        </span>
        <span id="t1n_6" class="t s1_6">
          зохион бүтээх чиглэлийн үйл ажиллагааг дэмжин хэрэгжүүлэх{" "}
        </span>
        <span id="t1o_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1p_6" class="t s1_6">
          Олон нийтийг хамарсан спортын арга хэмжээнд оролцох, эерэг жишээг{" "}
        </span>
        <span id="t1q_6" class="t s1_6">
          сурталчилах, дэлгэрүүлэх{" "}
        </span>
        <span id="t1r_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1s_6" class="t s1_6">
          Уламжлалт өв соёл, түүн дотроо соёлын биет болон биет бус өвийг
          өвлүүлэх,{" "}
        </span>
        <span id="t1t_6" class="t s1_6">
          түгээн дэлгэрүүлэх, сурталчилах үйл ажиллагааг тогтмол хэрэгжүүлэх{" "}
        </span>
        <span id="t1u_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t1v_6" class="t s1_6">
          Орчин үеийн цаашилбал дэлхийн урлаг, уран сайхны бүтээлийг сурталчилах{" "}
        </span>
        <span id="t1w_6" class="t s1_6">
          зорилготой хүүхдийн урлаг, соёлын үйл ажиллагааг нэмэгдүүлэх, түгээн{" "}
        </span>
        <span id="t1x_6" class="t s1_6">
          дэлгэрүүлэх, дэмжин хөгжүүлэх{" "}
        </span>
        <span id="t1y_6" class="t s1_6">
          Зорилт 5. Мэдээллийн технологийн ёс зүйтэй хэрэглээг дэмжих{" "}
        </span>
        <span id="t1z_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t20_6" class="t s1_6">
          Уламжлалт соёл, ёс зүй, гэр бүлийн үнэт зүйлийг мэдээллийн орчинд
          сурталчлахад{" "}
        </span>
        <span id="t21_6" class="t s1_6">
          дэмжлэг үзүүлэх{" "}
        </span>
        <span id="t22_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t23_6" class="t s1_6">
          Интернетийн орчинд хүүхдийн эрүүл мэнд, өсөлт хөгжилтөд сөргөөр
          нөлөөлж{" "}
        </span>
        <span id="t24_6" class="t s1_6">
          болохуйц мэдээллээс хамгаалах төрөл бүрийн арга, орчин нөхцөл
          бүрдүүлэх{" "}
        </span>
        <span id="t25_6" class="t s1_6">
          -{" "}
        </span>
        <span id="t26_6" class="t s1_6">
          Цахим хэрэглээний эерэг жишээг сурталчлах{" "}
        </span>
      </div>
    </div>
  );
}
