import React from "react";
;

export default function TwoTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`
#t1_12{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_12{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_12{left:110px;bottom:1157px;letter-spacing:-0.27px;word-spacing:0.15px;}
#t4_12{left:153px;bottom:1139px;letter-spacing:-0.05px;}
#t5_12{left:180px;bottom:1139px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t6_12{left:153px;bottom:1121px;letter-spacing:-0.05px;}
#t7_12{left:180px;bottom:1121px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_12{left:153px;bottom:1102px;letter-spacing:-0.05px;}
#t9_12{left:180px;bottom:1102px;letter-spacing:-0.14px;word-spacing:-1.69px;}
#ta_12{left:180px;bottom:1084px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tb_12{left:153px;bottom:1066px;letter-spacing:-0.15px;word-spacing:4.52px;}
#tc_12{left:180px;bottom:1048px;letter-spacing:-0.14px;word-spacing:0.04px;}
#td_12{left:153px;bottom:1030px;letter-spacing:-0.19px;word-spacing:8.89px;}
#te_12{left:263px;bottom:1030px;letter-spacing:-0.13px;}
#tf_12{left:379px;bottom:1030px;letter-spacing:-0.09px;}
#tg_12{left:469px;bottom:1030px;letter-spacing:-0.43px;}
#th_12{left:554px;bottom:1030px;letter-spacing:-0.08px;}
#ti_12{left:624px;bottom:1030px;letter-spacing:-0.09px;}
#tj_12{left:713px;bottom:1030px;letter-spacing:-0.17px;}
#tk_12{left:180px;bottom:1012px;letter-spacing:-0.17px;word-spacing:1.03px;}
#tl_12{left:180px;bottom:994px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tm_12{left:153px;bottom:975px;letter-spacing:-0.05px;}
#tn_12{left:180px;bottom:975px;letter-spacing:-0.11px;word-spacing:1.05px;}
#to_12{left:180px;bottom:957px;letter-spacing:-0.13px;}
#tp_12{left:153px;bottom:939px;letter-spacing:-0.15px;word-spacing:3.17px;}
#tq_12{left:180px;bottom:921px;letter-spacing:-0.15px;word-spacing:2.84px;}
#tr_12{left:180px;bottom:903px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ts_12{left:153px;bottom:885px;letter-spacing:-0.16px;word-spacing:4.1px;}
#tt_12{left:180px;bottom:867px;letter-spacing:-0.14px;word-spacing:-0.26px;}
#tu_12{left:180px;bottom:848px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tv_12{left:153px;bottom:830px;letter-spacing:-0.05px;}
#tw_12{left:180px;bottom:830px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tx_12{left:153px;bottom:812px;letter-spacing:-0.13px;word-spacing:-0.05px;}
#ty_12{left:153px;bottom:794px;letter-spacing:-0.15px;word-spacing:0.16px;}
#tz_12{left:153px;bottom:776px;letter-spacing:-0.15px;word-spacing:-0.03px;}
#t10_12{left:153px;bottom:758px;letter-spacing:-0.16px;word-spacing:0.42px;}
#t11_12{left:180px;bottom:740px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t12_12{left:153px;bottom:722px;letter-spacing:-0.14px;word-spacing:-0.04px;}
#t13_12{left:153px;bottom:703px;letter-spacing:-0.14px;word-spacing:-0.04px;}
#t14_12{left:153px;bottom:685px;letter-spacing:-0.13px;word-spacing:-0.05px;}
#t15_12{left:485px;bottom:649px;letter-spacing:-0.13px;word-spacing:-0.89px;}
#t16_12{left:110px;bottom:631px;letter-spacing:-0.16px;word-spacing:7.8px;}
#t17_12{left:110px;bottom:613px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t18_12{left:153px;bottom:595px;letter-spacing:-0.05px;}
#t19_12{left:180px;bottom:595px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1a_12{left:153px;bottom:576px;letter-spacing:-0.05px;}
#t1b_12{left:180px;bottom:576px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1c_12{left:153px;bottom:558px;letter-spacing:-0.16px;word-spacing:7.48px;}
#t1d_12{left:180px;bottom:540px;letter-spacing:-0.13px;word-spacing:5.69px;}
#t1e_12{left:180px;bottom:522px;letter-spacing:-0.25px;}
#t1f_12{left:153px;bottom:504px;letter-spacing:-0.05px;}
#t1g_12{left:180px;bottom:504px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1h_12{left:153px;bottom:486px;letter-spacing:-0.05px;}
#t1i_12{left:180px;bottom:486px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1j_12{left:153px;bottom:468px;letter-spacing:-0.05px;}
#t1k_12{left:180px;bottom:468px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1l_12{left:153px;bottom:449px;letter-spacing:-0.05px;}
#t1m_12{left:180px;bottom:449px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1n_12{left:153px;bottom:431px;letter-spacing:-0.05px;}
#t1o_12{left:180px;bottom:431px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1p_12{left:153px;bottom:413px;letter-spacing:-0.05px;}
#t1q_12{left:180px;bottom:413px;letter-spacing:-0.12px;word-spacing:-1.03px;}
#t1r_12{left:180px;bottom:395px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1s_12{left:153px;bottom:377px;letter-spacing:-0.14px;word-spacing:0.1px;}
#t1t_12{left:180px;bottom:359px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1u_12{left:153px;bottom:341px;letter-spacing:-0.13px;word-spacing:3.48px;}
#t1v_12{left:180px;bottom:323px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1w_12{left:153px;bottom:304px;letter-spacing:-0.12px;word-spacing:-0.91px;}
#t1x_12{left:180px;bottom:286px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1y_12{left:719px;bottom:286px;letter-spacing:-0.11px;}
#t1z_12{left:153px;bottom:268px;letter-spacing:-0.11px;word-spacing:-0.72px;}
#t20_12{left:180px;bottom:250px;letter-spacing:-0.12px;word-spacing:5.96px;}
#t21_12{left:180px;bottom:232px;letter-spacing:-0.16px;}
#t22_12{left:270px;bottom:232px;letter-spacing:-0.11px;}
#t23_12{left:153px;bottom:214px;letter-spacing:-0.11px;word-spacing:0.78px;}
#t24_12{left:180px;bottom:196px;letter-spacing:-0.12px;word-spacing:1.86px;}
#t25_12{left:180px;bottom:177px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t26_12{left:319px;bottom:177px;letter-spacing:-0.11px;}
#t27_12{left:242px;bottom:99px;letter-spacing:-0.14px;word-spacing:-0.23px;}

.s0_12{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_12{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_12{font-size:17px;font-family:ArialMT_5-;color:#375FB2;}
.s3_12{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s4_12{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}`}
        </style>
        <style id="fonts12" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg12" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="12/12.svg"
          type="image/svg+xml"
          id="pdf12"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_12" class="t s0_12">
          10{" "}
        </span>
        <span id="t2_12" class="t s1_12">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_12" class="t s2_12">
          ЭХ СУРВАЛЖ:{" "}
        </span>
        <span id="t4_12" class="t s1_12">
          1.{" "}
        </span>
        <span id="t5_12" class="t s1_12">
          БСШУЯ. (2014). Бага боловсролын сургалтын цөм хөтөлбөр. УБ. 15, 145{" "}
        </span>
        <span id="t6_12" class="t s1_12">
          2.{" "}
        </span>
        <span id="t7_12" class="t s1_12">
          БСШУЯ. (2015). Суурь боловсролын сургалтын цөм хөтөлбөр. УБ. 160-179{" "}
        </span>
        <span id="t8_12" class="t s1_12">
          3.{" "}
        </span>
        <span id="t9_12" class="t s1_12">
          БСШУ-ны сайдын 2010 оны 471 дүгээр тушаалын хавсралт. “Ерөнхий
          боловсролын{" "}
        </span>
        <span id="ta_12" class="t s1_12">
          сургуулийн үлгэрчилсэн дүрэм” УБ{" "}
        </span>
        <span id="tb_12" class="t s1_12">
          4. БСШУ. (2016). ЩБС, “Насан туршийн боловсролын төвд дугуйлан, секц,
          клуб{" "}
        </span>
        <span id="tc_12" class="t s1_12">
          ажиллуулах зөвлөмж” УБ{" "}
        </span>
        <span id="td_12" class="t s1_12">
          5. БСШУЯ.{" "}
        </span>
        <span id="te_12" class="t s1_12">
          Швейцарийн{" "}
        </span>
        <span id="tf_12" class="t s1_12">
          хөгжлийн{" "}
        </span>
        <span id="tg_12" class="t s1_12">
          агентлаг.{" "}
        </span>
        <span id="th_12" class="t s1_12">
          (2016).{" "}
        </span>
        <span id="ti_12" class="t s1_12">
          “Ерөнхий{" "}
        </span>
        <span id="tj_12" class="t s1_12">
          боловсролын{" "}
        </span>
        <span id="tk_12" class="t s1_12">
          сургалт, үйл ажиллагаанд тогтвортой хөгжлийн боловсролын үзэл санааг
          тусган,{" "}
        </span>
        <span id="tl_12" class="t s1_12">
          хэрэгжүүлэх үндсэн шаардлага” УБ. ВСI{" "}
        </span>
        <span id="tm_12" class="t s1_12">
          6.{" "}
        </span>
        <span id="tn_12" class="t s1_12">
          МУ-ын Засгийн газар.(2017). “Хүүхдийн хөгжил, хамгааллын үндэсний
          хөтөлбөр”{" "}
        </span>
        <span id="to_12" class="t s1_12">
          УБ{" "}
        </span>
        <span id="tp_12" class="t s1_12">
          7. Боловсролын хүрээлэн. (2017). “Ерөнхий боловсролын сургуулиудын
          хичээлээс{" "}
        </span>
        <span id="tq_12" class="t s1_12">
          гадуурх үйл ажиллагааг зохион байгуулж буй нөхцөл байдлын
          шинжилгээ”-ний{" "}
        </span>
        <span id="tr_12" class="t s1_12">
          тайлан, УБ{" "}
        </span>
        <span id="ts_12" class="t s1_12">
          8. Дэлхийн банк, Боловсролын хамтын ажиллагааны нийгэмлэг. (2018).
          “Ерөнхий{" "}
        </span>
        <span id="tt_12" class="t s1_12">
          боловсролын сургуулийн дунд ангийн хичээлээс гадуурх үйл ажиллагаанд
          хийсэн{" "}
        </span>
        <span id="tu_12" class="t s1_12">
          хөндлөнгийн үнэлгээ”-ний тайлан, УБ{" "}
        </span>
        <span id="tv_12" class="t s1_12">
          9.{" "}
        </span>
        <span id="tw_12" class="t s1_12">
          БСШУСЯ. (2018).”Аюулгүй амьдрах ухааны хөтөлбөр” сургалтын хөтөлбөр{" "}
        </span>
        <span id="tx_12" class="t s1_12">
          10. (2014)., Аврагч мазаалайн зөвлөмж., гарын авлага{" "}
        </span>
        <span id="ty_12" class="t s1_12">
          11. (2015). “Өсвөрийн аврагч” бүлгийн багшийн гарын авлага{" "}
        </span>
        <span id="tz_12" class="t s1_12">
          12. (2017). “Бэлэн бай” сургалтын багц материал{" "}
        </span>
        <span id="t10_12" class="t s1_12">
          13. (2016).“Сургуулийн гамшгаас хамгаалах менежмент” сургуулийн
          удирдлага, багш{" "}
        </span>
        <span id="t11_12" class="t s1_12">
          ажилтнуудад зориулсан гарын авлага{" "}
        </span>
        <span id="t12_12" class="t s1_12">
          14. http://ontsgoisur.gov.mn Аврагч мазаалайн түүх видео хичээл{" "}
        </span>
        <span id="t13_12" class="t s1_12">
          15. “Бэлэн бай” интерактив сургалтын хичээл{" "}
        </span>
        <span id="t14_12" class="t s1_12">
          16. Бага, дунд боловсролын тухай хууль. http://www.legalinfo.mn/{" "}
        </span>
        <span id="t15_12" class="t s3_12">
          Хавсралт 1. Нэмэлт хөтөлбөрийн жагсаалт{" "}
        </span>
        <span id="t16_12" class="t s1_12">
          Хичээлээс гадуурх сургалтын ажлын хүрээнд нэмэлтээр сонгон судлах
          боломжтой{" "}
        </span>
        <span id="t17_12" class="t s1_12">
          хөтөлбөрийн жагсаалт{" "}
        </span>
        <span id="t18_12" class="t s1_12">
          1.{" "}
        </span>
        <span id="t19_12" class="t s1_12">
          БСШУСЯ. (2018). “Аюулгүй амьдрах ухааны хөтөлбөр” сургалтын хөтөлбөр{" "}
        </span>
        <span id="t1a_12" class="t s1_12">
          2.{" "}
        </span>
        <span id="t1b_12" class="t s1_12">
          БСШУСЯ. (2017). “Хичээлийн бус цагийн нэмэлт хөтөлбөр”{" "}
        </span>
        <span id="t1c_12" class="t s1_12">
          3. ХНХЯ. Монголын Урлагийн Сэтгэл Заслын Мэргэжлийн холбоо. (2021) “Үе{" "}
        </span>
        <span id="t1d_12" class="t s1_12">
          тэнгийнхний дээрэлхэлт хүчирхийллийг зогсоох, урьдчилан сэргийлэх”
          гарын{" "}
        </span>
        <span id="t1e_12" class="t s1_12">
          авлага{" "}
        </span>
        <span id="t1f_12" class="t s1_12">
          4.{" "}
        </span>
        <span id="t1g_12" class="t s1_12">
          ГБХЗХГ. НҮБ-ын Хүүхдийн Сан (2017). “Миний ертөнц” хөгжлийн хөтөлбөр{" "}
        </span>
        <span id="t1h_12" class="t s1_12">
          5.{" "}
        </span>
        <span id="t1i_12" class="t s1_12">
          ГБХЗХГ. НҮБ-ын Хүүхдийн Сан (2017). “Миний гэр бүл” хөгжлийн хөтөлбөр{" "}
        </span>
        <span id="t1j_12" class="t s1_12">
          6.{" "}
        </span>
        <span id="t1k_12" class="t s1_12">
          ГБХЗХГ. НҮБ-ын Хүүхдийн Сан (2017). “Хүүхдийн оролцоог үнэлэх арга
          зүй”{" "}
        </span>
        <span id="t1l_12" class="t s1_12">
          7.{" "}
        </span>
        <span id="t1m_12" class="t s1_12">
          Дэлхийн Зөн МОУБ (2016) “Оролцоод хөгжье” хөтөлбөр{" "}
        </span>
        <span id="t1n_12" class="t s1_12">
          8.{" "}
        </span>
        <span id="t1o_12" class="t s1_12">
          БНБ-ын төлөө эвсэл “Үе тэнгийн дээрэлхэлээс урьдчилан сэргийлэх
          хөтөлбөр”{" "}
        </span>
        <span id="t1p_12" class="t s1_12">
          9.{" "}
        </span>
        <span id="t1q_12" class="t s1_12">
          “Оролцоод хөгжье” - 12-18 насны хүүхдүүдэд зориулсан амьдрах ухааны
          хөтөлбөр{" "}
        </span>
        <span id="t1r_12" class="t s1_12">
          (2016 оноос хойш хэрэгжиж буй){" "}
        </span>
        <span id="t1s_12" class="t s1_12">
          10. “Оролцоод хөгжье” - 6-11 насны хүүхдүүдэд зориулсан амьдрах ухааны
          хөтөлбөр{" "}
        </span>
        <span id="t1t_12" class="t s1_12">
          (2021 оноос хойш хэрэгжиж буй){" "}
        </span>
        <span id="t1u_12" class="t s1_12">
          11. “Нөлөөлөл +” - 12-18 насны хүүхдүүдэд зориулсан амьдрах ухааны
          хөтөлбөр{" "}
        </span>
        <span id="t1v_12" class="t s1_12">
          (2021 оноос хойш хэрэгжиж буй){" "}
        </span>
        <span id="t1w_12" class="t s1_12">
          12. “Ухаалаг хөтөч” - Хүн худалдаалах гэмт хэргээс өөрийгөө болон үе
          тэнгийнхнийгээ{" "}
        </span>
        <span id="t1x_12" class="t s1_12">
          хамгаалахад чиглэсэн хөтөлбөр 12-18 насны хүүхдүүдэд зориулсан{" "}
        </span>
        <span id="t1y_12" class="t s4_12">
          (Шинэ){" "}
        </span>
        <span id="t1z_12" class="t s1_12">
          13. “Өөрчлөлтийн төлөөх дуу хоолой” - Хүн худалдаалах гэмт хэргээс
          өөрийгөө болон{" "}
        </span>
        <span id="t20_12" class="t s1_12">
          үе тэнгийнхнийгээ хамгаалахад чиглэсэн хөтөлбөр, 12-18 насны
          хүүхдүүдэд{" "}
        </span>
        <span id="t21_12" class="t s1_12">
          зориулсан{" "}
        </span>
        <span id="t22_12" class="t s4_12">
          (Шинэ){" "}
        </span>
        <span id="t23_12" class="t s1_12">
          14. “Цахим орчин дахь хүүхдийн аюулгүй байдал” - Хүүхдийг цахим орчны
          эрсдлээс{" "}
        </span>
        <span id="t24_12" class="t s1_12">
          хамгаалахад чиглэсэн хөтөбөлбөр, 10-18 насны хүүхдүүдэд зориулсан
          амьдрах{" "}
        </span>
        <span id="t25_12" class="t s1_12">
          ухааны хөтөлбөр{" "}
        </span>
        <span id="t26_12" class="t s4_12">
          (Шинэ){" "}
        </span>
        <span id="t27_12" class="t s3_12">
          Хавсралт 2. Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн төлөвлөгөө{" "}
        </span>
      </div>
    </div>
  );
}
