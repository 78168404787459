import React from "react";
;

export default function OneTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`#t1_11{left:844px;bottom:21px;}
#t2_11{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_11{left:110px;bottom:1157px;letter-spacing:-0.23px;word-spacing:0.11px;}
#t4_11{left:110px;bottom:1133px;letter-spacing:-0.14px;word-spacing:2.49px;}
#t5_11{left:110px;bottom:1114px;letter-spacing:-0.14px;word-spacing:3.87px;}
#t6_11{left:110px;bottom:1096px;letter-spacing:-0.13px;word-spacing:-1.81px;}
#t7_11{left:110px;bottom:1078px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t8_11{left:165px;bottom:1054px;letter-spacing:-0.14px;word-spacing:5.31px;}
#t9_11{left:110px;bottom:1036px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ta_11{left:153px;bottom:1012px;}
#tb_11{left:180px;bottom:1012px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tc_11{left:539px;bottom:1012px;letter-spacing:-0.13px;word-spacing:0.04px;}
#td_11{left:153px;bottom:993px;}
#te_11{left:180px;bottom:993px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_11{left:153px;bottom:975px;}
#tg_11{left:180px;bottom:975px;letter-spacing:-0.13px;word-spacing:1.29px;}
#th_11{left:180px;bottom:957px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ti_11{left:153px;bottom:939px;}
#tj_11{left:180px;bottom:939px;letter-spacing:-0.13px;word-spacing:2.13px;}
#tk_11{left:180px;bottom:921px;letter-spacing:-0.19px;word-spacing:0.1px;}
#tl_11{left:110px;bottom:878px;letter-spacing:-0.15px;word-spacing:0.17px;}
#tm_11{left:110px;bottom:860px;letter-spacing:-0.15px;word-spacing:1.21px;}
#tn_11{left:110px;bottom:842px;letter-spacing:-0.15px;word-spacing:4.97px;}
#to_11{left:110px;bottom:824px;letter-spacing:-0.14px;word-spacing:5.35px;}
#tp_11{left:331px;bottom:824px;letter-spacing:-0.15px;word-spacing:5.35px;}
#tq_11{left:110px;bottom:806px;letter-spacing:-0.15px;word-spacing:1.85px;}
#tr_11{left:110px;bottom:788px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ts_11{left:110px;bottom:764px;letter-spacing:-0.16px;word-spacing:4.29px;}
#tt_11{left:110px;bottom:745px;letter-spacing:-0.11px;}
#tu_11{left:227px;bottom:745px;letter-spacing:-0.15px;word-spacing:0.2px;}
#tv_11{left:110px;bottom:727px;letter-spacing:-0.17px;word-spacing:2.67px;}
#tw_11{left:110px;bottom:709px;letter-spacing:-0.15px;word-spacing:0.51px;}
#tx_11{left:110px;bottom:691px;letter-spacing:-0.19px;word-spacing:-0.45px;}
#ty_11{left:210px;bottom:691px;letter-spacing:-0.12px;word-spacing:-0.54px;}
#tz_11{left:362px;bottom:691px;letter-spacing:-0.2px;word-spacing:-0.46px;}
#t10_11{left:512px;bottom:691px;letter-spacing:-0.13px;word-spacing:-0.51px;}
#t11_11{left:667px;bottom:691px;letter-spacing:-0.12px;word-spacing:-0.54px;}
#t12_11{left:110px;bottom:673px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t13_11{left:312px;bottom:673px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t14_11{left:110px;bottom:624px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t15_11{left:110px;bottom:324px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t16_11{left:110px;bottom:306px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t17_11{left:110px;bottom:288px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t18_11{left:110px;bottom:270px;letter-spacing:-0.18px;word-spacing:0.09px;}

.s0_11{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_11{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_11{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_11{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>
        <style id="fonts11" type="text/css">
          {`@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg11" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="11/11.svg"
          type="image/svg+xml"
          id="pdf11"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_11" class="t s0_11">
          9{" "}
        </span>
        <span id="t2_11" class="t s1_11">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_11" class="t s2_11">
          ДОЛОО. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫГ ҮНЭЛЭХ{" "}
        </span>
        <span id="t4_11" class="t s1_11">
          Хичээлээс гадуурх сургалтын ажлын үр дүнг үнэлэхдээ уралдаан,
          тэмцээний хэлбэрийг{" "}
        </span>
        <span id="t5_11" class="t s1_11">
          сонгох нь тийм ч оновчтой биш юм. Харин суралцагчдын ур чадвар, ахиц,
          бүтээлийн{" "}
        </span>
        <span id="t6_11" class="t s1_11">
          чанарын өсөлтийг өөрийнх нь гүйцэтгэлтэй харьцуулан үзэх нь оновчтой
          төдийгүй суралцагч{" "}
        </span>
        <span id="t7_11" class="t s1_11">
          урамшин өөртөө итгэлтэй болдог байна.{" "}
        </span>
        <span id="t8_11" class="t s1_11">
          Сонгон, секц, дугуйлангийн үр дүнг дараах хэлбэрээр тодруулж, үнэлгээ
          хийх{" "}
        </span>
        <span id="t9_11" class="t s1_11">
          боломжтой. Үүнд:{" "}
        </span>
        <span id="ta_11" class="t s3_11">
          •{" "}
        </span>
        <span id="tb_11" class="t s1_11">
          Сэтгэл ханамжийн судалгаа, ярилцлага хийх{" "}
        </span>
        <span id="tc_11" class="t s1_11">
          (багш, эцэг эх, сурагчдаас авах){" "}
        </span>
        <span id="td_11" class="t s3_11">
          •{" "}
        </span>
        <span id="te_11" class="t s1_11">
          Сурагчдыг өөрөө өөрийгөө үнэлэх өөрийн үнэлгээний хуудсаар үнэлэх{" "}
        </span>
        <span id="tf_11" class="t s3_11">
          •{" "}
        </span>
        <span id="tg_11" class="t s1_11">
          Хүүхдийн хувийн хөгжилд чиглэсэн хандлагийн талаарх найдвартай
          мэдээллийг{" "}
        </span>
        <span id="th_11" class="t s1_11">
          олж авахад чиглэсэн сэтгэл зүйн болон социологийн судалгаа явуулах{" "}
        </span>
        <span id="ti_11" class="t s3_11">
          •{" "}
        </span>
        <span id="tj_11" class="t s1_11">
          Суралцагчдын авьяасаа хөгжүүлж, харилцан суралцаж буй байдлын
          бүтээлийн{" "}
        </span>
        <span id="tk_11" class="t s1_11">
          үзэсгэлэн, тайлан тоглолт, бүтээлийн сангаар үнэлэх{" "}
        </span>
        <span id="tl_11" class="t s1_11">
          ХГҮА - г сэтгэл ханамжийн судалгаагаар дэмжинэ. Сэтгэл ханамжийн
          судалгааг сургалтын{" "}
        </span>
        <span id="tm_11" class="t s1_11">
          агуулга, хэлбэр нь уян хатан, багш, суралцагч, эцэг эх, асран
          хамгаалагчдад ойлгомжтой{" "}
        </span>
        <span id="tn_11" class="t s1_11">
          эсэх, нээлттэй, ил тод байгаа эсэх; сул байгааг төлөвлөх дэмжих, үйл
          ажиллагааны{" "}
        </span>
        <span id="to_11" class="t s1_11">
          төлөвлөгөөг сайжруулах,{" "}
        </span>
        <span id="tp_11" class="t s1_11">
          харилцаа, хандлага, уур амьсгал, үйл ажиллагаанд сэтгэл{" "}
        </span>
        <span id="tq_11" class="t s1_11">
          ханамж хэр байгаа талаар тодорхойлж, үнэлэлт дүгнэлт хийж, илэрсэн
          алдаа дутагдлыг{" "}
        </span>
        <span id="tr_11" class="t s1_11">
          арилгах, цаашид сайжруулах зорилготой хийнэ.{" "}
        </span>
        <span id="ts_11" class="t s1_11">
          Сэтгэл ханамж нь багшийн санал болгож буй хөтөлбөр, сургалтын үйл
          ажиллагаа нь{" "}
        </span>
        <span id="tt_11" class="t s1_11">
          суралцагчийн{" "}
        </span>
        <span id="tu_11" class="t s1_11">
          хүлээлтэд хэрхэн нийцэж байна вэ гэдгийг тодорхойлдог өргөн ойлголт
          юм.{" "}
        </span>
        <span id="tv_11" class="t s1_11">
          Сэтгэл ханамж нь тодорхой хэмжүүрийн дагуу тооцогддог ба сургалт үйл
          ажиллагаагаа{" "}
        </span>
        <span id="tw_11" class="t s1_11">
          хөгжүүлэх шат дамжлагыг нарийн тодорхойлж өгдгөөрөө давуу талтай.
          Сэтгэл ханамжийн{" "}
        </span>
        <span id="tx_11" class="t s1_11">
          судалгаа нь{" "}
        </span>
        <span id="ty_11" class="t s1_11">
          ХГҮА -н хөтөлбөрт{" "}
        </span>
        <span id="tz_11" class="t s1_11">
          гарч буй өөрчлөлт{" "}
        </span>
        <span id="t10_11" class="t s1_11">
          чанар, сайжруулах{" "}
        </span>
        <span id="t11_11" class="t s1_11">
          эсэх талаарх санал{" "}
        </span>
        <span id="t12_11" class="t s1_11">
          бодлыг багш, суралцагч,{" "}
        </span>
        <span id="t13_11" class="t s1_11">
          эцэг эх хэрхэн үнэлж байгааг илтгэнэ.{" "}
        </span>
        <span id="t14_11" class="t s1_11">
          Сэтгэл ханамжийн судалгаа хийх арга аргачлал{" "}
        </span>
        <span id="t15_11" class="t s1_11">
          Сэтгэл ханамжийн асуулга нь (тодорхой асуулгын тоо хэд ч байж болно)
          Лайкартийн 1- 5{" "}
        </span>
        <span id="t16_11" class="t s1_11">
          гэсэн шкалаар үнэлэгдэх бөгөөд “1” сэтгэлд огт нийцэхгүй, “2” сэтгэлд
          нийцэхгүй, “3” дунд{" "}
        </span>
        <span id="t17_11" class="t s1_11">
          зэрэг нийцнэ, “4“ сэтгэлд нийцнэ, ”5” маш их сэтгэлд нийцэж байна
          гэсэн хувилбартай{" "}
        </span>
        <span id="t18_11" class="t s1_11">
          байж болно.{" "}
        </span>
      </div>
    </div>
  );
}
