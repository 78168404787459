import React from "react";
;

export default function FiveZero() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}`}
        </style>
        <style type="text/css">
          {`#t1_50{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_50{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_50{left:110px;bottom:1156px;letter-spacing:-0.16px;word-spacing:2.76px;}
#t4_50{left:110px;bottom:1138px;letter-spacing:-0.12px;word-spacing:1.79px;}
#t5_50{left:110px;bottom:1120px;letter-spacing:-0.12px;word-spacing:1.07px;}
#t6_50{left:110px;bottom:1102px;letter-spacing:-0.14px;word-spacing:4.09px;}
#t7_50{left:110px;bottom:1084px;letter-spacing:-0.14px;word-spacing:-1.66px;}
#t8_50{left:110px;bottom:1066px;letter-spacing:-0.13px;word-spacing:-0.3px;}
#t9_50{left:110px;bottom:1047px;letter-spacing:-0.14px;word-spacing:-0.34px;}
#ta_50{left:110px;bottom:1029px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tb_50{left:110px;bottom:993px;letter-spacing:-0.11px;}
#tc_50{left:389px;bottom:993px;letter-spacing:-0.22px;word-spacing:0.12px;}
#td_50{left:110px;bottom:957px;letter-spacing:-0.09px;word-spacing:0.14px;}
#te_50{left:110px;bottom:939px;letter-spacing:-0.06px;word-spacing:3.57px;}
#tf_50{left:110px;bottom:920px;letter-spacing:-0.06px;}
#tg_50{left:110px;bottom:902px;letter-spacing:-0.13px;word-spacing:0.08px;}
#th_50{left:110px;bottom:884px;letter-spacing:-0.06px;word-spacing:3.58px;}
#ti_50{left:110px;bottom:866px;letter-spacing:-0.06px;}
#tj_50{left:110px;bottom:848px;letter-spacing:-0.13px;word-spacing:1.39px;}
#tk_50{left:110px;bottom:830px;letter-spacing:-0.09px;word-spacing:-0.01px;}
#tl_50{left:110px;bottom:812px;letter-spacing:-0.06px;word-spacing:3.57px;}
#tm_50{left:110px;bottom:793px;letter-spacing:-0.06px;}
#tn_50{left:110px;bottom:775px;letter-spacing:-0.09px;word-spacing:-0.06px;}
#to_50{left:110px;bottom:757px;letter-spacing:-0.06px;word-spacing:3.58px;}
#tp_50{left:110px;bottom:739px;letter-spacing:-0.06px;}
#tq_50{left:210px;bottom:703px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tr_50{left:110px;bottom:594px;letter-spacing:-0.11px;}
#ts_50{left:228px;bottom:594px;letter-spacing:-0.14px;word-spacing:1.6px;}
#tt_50{left:110px;bottom:576px;letter-spacing:-0.17px;word-spacing:6.94px;}
#tu_50{left:110px;bottom:558px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tv_50{left:110px;bottom:521px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tw_50{left:153px;bottom:497px;}
#tx_50{left:180px;bottom:497px;letter-spacing:-0.19px;word-spacing:0.09px;}
#ty_50{left:153px;bottom:479px;}
#tz_50{left:180px;bottom:479px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t10_50{left:153px;bottom:461px;}
#t11_50{left:180px;bottom:461px;letter-spacing:-0.14px;word-spacing:0.55px;}
#t12_50{left:180px;bottom:443px;letter-spacing:-0.17px;}
#t13_50{left:153px;bottom:425px;}
#t14_50{left:180px;bottom:425px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t15_50{left:492px;bottom:433px;}
#t16_50{left:153px;bottom:407px;}
#t17_50{left:180px;bottom:407px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t18_50{left:585px;bottom:415px;}
#t19_50{left:153px;bottom:388px;}
#t1a_50{left:180px;bottom:388px;letter-spacing:-0.12px;word-spacing:1.55px;}
#t1b_50{left:180px;bottom:370px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t1c_50{left:327px;bottom:379px;}
#t1d_50{left:153px;bottom:352px;}
#t1e_50{left:180px;bottom:352px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1f_50{left:153px;bottom:334px;}
#t1g_50{left:180px;bottom:334px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1h_50{left:153px;bottom:316px;}
#t1i_50{left:180px;bottom:316px;letter-spacing:-0.17px;word-spacing:0.06px;}
#t1j_50{left:153px;bottom:298px;}
#t1k_50{left:180px;bottom:298px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1l_50{left:153px;bottom:280px;}
#t1m_50{left:180px;bottom:280px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1n_50{left:153px;bottom:261px;}
#t1o_50{left:180px;bottom:261px;letter-spacing:-0.24px;word-spacing:0.14px;}
#t1p_50{left:153px;bottom:243px;}
#t1q_50{left:180px;bottom:243px;letter-spacing:-0.22px;word-spacing:0.12px;}
#t1r_50{left:110px;bottom:201px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1s_50{left:153px;bottom:177px;}
#t1t_50{left:180px;bottom:177px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1u_50{left:55px;bottom:145px;}
#t1v_50{left:113px;bottom:146px;letter-spacing:0.13px;}
#t1w_50{left:55px;bottom:123px;}
#t1x_50{left:113px;bottom:124px;letter-spacing:0.13px;}
#t1y_50{left:55px;bottom:101px;}
#t1z_50{left:113px;bottom:102px;letter-spacing:0.12px;word-spacing:0.01px;}
#t20_50{left:141px;bottom:655px;letter-spacing:-0.15px;word-spacing:-1.34px;}
#t21_50{left:141px;bottom:637px;letter-spacing:-0.08px;word-spacing:-0.02px;}

.s0_50{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_50{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_50{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_50{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_50{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s5_50{font-size:10px;font-family:ArialMT_5-;color:#231F20;}
.s6_50{font-size:10px;font-family:ArialMT_5q;color:#231F20;}
.s7_50{font-size:18px;font-family:MinionPro-Regular_6m;color:#231F20;}
.s8_50{font-size:15px;font-family:MinionPro-Regular_6m;color:#000;}`}
        </style>
        <style id="fonts50" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg50" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="50/50.svg"
          type="image/svg+xml"
          id="pdf50"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_50" class="t s0_50">
          48{" "}
        </span>
        <span id="t2_50" class="t s1_50">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_50" class="t s1_50">
          Дээрх жагсаалтыг дахин харна уу. Хийх дуртай, гүйцэтгэх сонирхолтой
          ажлаа дугуйлан{" "}
        </span>
        <span id="t4_50" class="t s1_50">
          тэмдэглээрэй. Жагсаалтыг уншин тэмдэглэж дууссаны дараа 4 ба 5 гэсэн
          үнэлгээ өгсөн{" "}
        </span>
        <span id="t5_50" class="t s1_50">
          мөрүүдээ дахин дугуйлан тэмдэглэнэ. Учир нь таны давуу талуудыг
          илрүүлэх нь цаашид{" "}
        </span>
        <span id="t6_50" class="t s1_50">
          суралцах, ажиллахад тустай болно. Та хийж гүйцэтгэх дуртай, илүү
          сонрхдог зүйлсээ{" "}
        </span>
        <span id="t7_50" class="t s1_50">
          тодорхойлж байх явцад тэдгээр ур чадвар, туршлагаа хаана хэрхэн
          сурсан, хэрэглэж байсан{" "}
        </span>
        <span id="t8_50" class="t s1_50">
          тухайгаа санан, эргэцүүлэн бодоорой. Эдгээр туршлага нь магадгүй таны
          сургалтын явцад{" "}
        </span>
        <span id="t9_50" class="t s1_50">
          хуримтлагдсан бөгөөд эдгээр нь таныг суралцах, ажил хайх, мөн ажил
          мэргэжлээ сонгоход{" "}
        </span>
        <span id="ta_50" class="t s1_50">
          сайн жишээ, түүх болох юм.{" "}
        </span>
        <span id="tb_50" class="t s2_50">
          Ажлын хуудас{" "}
        </span>
        <span id="tc_50" class="t s1_50">
          БИ ХЭН БЭ? (Товч, жагсаан бичнэ үү){" "}
        </span>
        <span id="td_50" class="t s1_50">
          Би ямар авьяас, ур чадвар, давуу чанартай вэ?
          -----------------------------------------------------------{" "}
        </span>
        <span id="te_50" class="t s1_50">
          --------------------------------------------------------
          ---------------------------------------------------------------------{" "}
        </span>
        <span id="tf_50" class="t s1_50">
          -------------- -------------------------------{" "}
        </span>
        <span id="tg_50" class="t s1_50">
          Миний сонирхол юу болох, юунд илүү идэвхитэй ханддаг, үнэт зүйл юу вэ?
          ---------------------{" "}
        </span>
        <span id="th_50" class="t s1_50">
          -----------------------------------------------------------------------------------------------
          ------------------------------{" "}
        </span>
        <span id="ti_50" class="t s1_50">
          --------------------------------------------------------------------------------------{" "}
        </span>
        <span id="tj_50" class="t s1_50">
          Миний зорилго, мөрөөдөл буюу 1-2 жилийн дараа би юу сурч эзэмшсэн,
          эсвэл туршлага{" "}
        </span>
        <span id="tk_50" class="t s1_50">
          цуглуулсан, хаана ямар хөдөлмөр эрхлэх вэ?
          --------------------------------------------------------------{" "}
        </span>
        <span id="tl_50" class="t s1_50">
          --------------------------------------------------------
          ---------------------------------------------------------------------{" "}
        </span>
        <span id="tm_50" class="t s1_50">
          -------------------------------------------------{" "}
        </span>
        <span id="tn_50" class="t s1_50">
          Миний зан төлвийн сайн ба муу гурван чанар юу болох?
          -----------------------------------------------{" "}
        </span>
        <span id="to_50" class="t s1_50">
          -----------------------------------------------------------------------
          ------------------------------------------------------{" "}
        </span>
        <span id="tp_50" class="t s1_50">
          ----------------------------------------------------------------{" "}
        </span>
        <span id="tq_50" class="t s3_50">
          НАЙМ. БАЙГАЛЬ, ЭКОЛОГИЙН ХҮМҮҮЖЛИЙГ ТӨЛӨВШҮҮЛЭХ{" "}
        </span>
        <span id="tr_50" class="t s4_50">
          8.1.1.Зорилго:{" "}
        </span>
        <span id="ts_50" class="t s1_50">
          Сурагчид үйл ажиллагааны явцад бусдаас суралцах, бусдын үзэл бодлыг{" "}
        </span>
        <span id="tt_50" class="t s1_50">
          ойлгож, хүндэтгэх, хамтын оролцоонд тулгуурлан асуудлыг шийдвэрлэх,
          зохицуулах{" "}
        </span>
        <span id="tu_50" class="t s1_50">
          чадварт суралцах юм.{" "}
        </span>
        <span id="tv_50" class="t s1_50">
          8.1.2.Үндсэн ойлголт:{" "}
        </span>
        <span id="tw_50" class="t s1_50">
          -{" "}
        </span>
        <span id="tx_50" class="t s1_50">
          Тогтвортой хөгжил гэж юу вэ?{" "}
        </span>
        <span id="ty_50" class="t s1_50">
          -{" "}
        </span>
        <span id="tz_50" class="t s1_50">
          Тогтвортой хөгжлийн боловсрол гэж юу вэ?{" "}
        </span>
        <span id="t10_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t11_50" class="t s1_50">
          НҮБ, Эх дэлхийгээ хувирган өөрчилье: Тогтвортой хөгжлийн хөтөлбөр –
          2030 (17{" "}
        </span>
        <span id="t12_50" class="t s1_50">
          зорилго){" "}
        </span>
        <span id="t13_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t14_50" class="t s1_50">
          Монгол улсын ногоон хөгжлийн бодлого{" "}
        </span>
        <span id="t15_50" class="t s5_50">
          3{" "}
        </span>
        <span id="t16_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t17_50" class="t s1_50">
          Монгол улсын тогтвортой хөгжлийн үзэл баримтлал{" "}
        </span>
        <span id="t18_50" class="t s6_50">
          4{" "}
        </span>
        <span id="t19_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1a_50" class="t s1_50">
          ЕБ-ын сургалт үйл ажиллагаанд ТХБ-ын үзэл санааг тусган хэрэгжүүлэх
          үндсэн{" "}
        </span>
        <span id="t1b_50" class="t s1_50">
          чиглэл, шаардлага{" "}
        </span>
        <span id="t1c_50" class="t s6_50">
          5{" "}
        </span>
        <span id="t1d_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1e_50" class="t s1_50">
          Байгаль орчны тогтвортой байдал{" "}
        </span>
        <span id="t1f_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1g_50" class="t s1_50">
          Байгалийн нөөц баялгийн зохистой хэрэглээ{" "}
        </span>
        <span id="t1h_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1i_50" class="t s1_50">
          Экологийн асуудлууд{" "}
        </span>
        <span id="t1j_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1k_50" class="t s1_50">
          Хүн, байгалийн харилцан хамаарал{" "}
        </span>
        <span id="t1l_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1m_50" class="t s1_50">
          Уур амьсгалын өөрчлөлт{" "}
        </span>
        <span id="t1n_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1o_50" class="t s1_50">
          Гамшгийн хор уршиг, эрсдэлийг бууруулах{" "}
        </span>
        <span id="t1p_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1q_50" class="t s1_50">
          Тогтвортой хэрэглээ, үйлдвэрлэл{" "}
        </span>
        <span id="t1r_50" class="t s1_50">
          8.1.3.Үйл ажиллагаа:{" "}
        </span>
        <span id="t1s_50" class="t s1_50">
          -{" "}
        </span>
        <span id="t1t_50" class="t s1_50">
          Тогтвортой хөгжил болон ТХБ-ын хоорондын хамаарал, ялгааг таниулах{" "}
        </span>
        <span id="t1u_50" class="t s7_50">
          3{" "}
        </span>
        <span id="t1v_50" class="t s8_50">
          УИХ, 2014/43{" "}
        </span>
        <span id="t1w_50" class="t s7_50">
          4{" "}
        </span>
        <span id="t1x_50" class="t s8_50">
          УИХ, 2016/19{" "}
        </span>
        <span id="t1y_50" class="t s7_50">
          5{" "}
        </span>
        <span id="t1z_50" class="t s8_50">
          БСШУСЯ, 2015, А/458{" "}
        </span>
        <span id="t20_50" class="t s1_50">
          8.1. Хүүхдэд байгаль дэлхий, эх орон газар нутгаа хайрлах хандлага
          төлөвшүүлэх{" "}
        </span>
        <span id="t21_50" class="t s1_50">
          ажлыг дэмжих{" "}
        </span>
      </div>
    </div>
  );
}
