import React from "react";
;

export default function TwentyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_24{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_24{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_24{left:110px;bottom:1157px;letter-spacing:-0.1px;word-spacing:0.02px;}
#t4_24{left:110px;bottom:1139px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t5_24{left:110px;bottom:1121px;letter-spacing:-0.15px;word-spacing:1.97px;}
#t6_24{left:110px;bottom:1102px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t7_24{left:280px;bottom:1102px;letter-spacing:-0.19px;word-spacing:0.08px;}
#t8_24{left:414px;bottom:1102px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t9_24{left:110px;bottom:1084px;letter-spacing:-0.14px;word-spacing:6.28px;}
#ta_24{left:110px;bottom:1066px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tb_24{left:110px;bottom:1048px;letter-spacing:-0.13px;word-spacing:0.34px;}
#tc_24{left:110px;bottom:1030px;letter-spacing:-0.16px;word-spacing:0.06px;}
#td_24{left:110px;bottom:1012px;letter-spacing:-0.15px;}
#te_24{left:187px;bottom:1012px;letter-spacing:-0.11px;word-spacing:2.39px;}
#tf_24{left:316px;bottom:1012px;letter-spacing:-0.16px;word-spacing:2.43px;}
#tg_24{left:110px;bottom:994px;letter-spacing:-0.15px;word-spacing:0.91px;}
#th_24{left:110px;bottom:975px;letter-spacing:-0.17px;}
#ti_24{left:110px;bottom:939px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tj_24{left:110px;bottom:903px;letter-spacing:-0.13px;word-spacing:2.53px;}
#tk_24{left:188px;bottom:903px;letter-spacing:-0.12px;word-spacing:2.52px;}
#tl_24{left:110px;bottom:885px;letter-spacing:-0.15px;word-spacing:6.4px;}
#tm_24{left:110px;bottom:867px;letter-spacing:-0.14px;word-spacing:3.78px;}
#tn_24{left:110px;bottom:848px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_24{left:110px;bottom:812px;letter-spacing:-0.15px;word-spacing:1.08px;}
#tp_24{left:110px;bottom:794px;letter-spacing:-0.13px;word-spacing:0.35px;}
#tq_24{left:110px;bottom:776px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tr_24{left:153px;bottom:752px;}
#ts_24{left:180px;bottom:752px;letter-spacing:-0.12px;word-spacing:-0.57px;}
#tt_24{left:153px;bottom:734px;}
#tu_24{left:180px;bottom:734px;letter-spacing:-0.13px;word-spacing:0.75px;}
#tv_24{left:180px;bottom:715px;letter-spacing:-0.09px;}
#tw_24{left:153px;bottom:697px;}
#tx_24{left:180px;bottom:697px;letter-spacing:-0.14px;word-spacing:8.43px;}
#ty_24{left:180px;bottom:679px;letter-spacing:-0.21px;}
#tz_24{left:110px;bottom:655px;letter-spacing:-0.19px;word-spacing:2.45px;}
#t10_24{left:110px;bottom:637px;letter-spacing:-0.16px;word-spacing:8.31px;}
#t11_24{left:110px;bottom:619px;letter-spacing:-0.14px;word-spacing:1.27px;}
#t12_24{left:110px;bottom:600px;letter-spacing:-0.14px;word-spacing:0.99px;}
#t13_24{left:110px;bottom:582px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t14_24{left:110px;bottom:455px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t15_24{left:110px;bottom:437px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t16_24{left:153px;bottom:413px;}
#t17_24{left:180px;bottom:413px;letter-spacing:-0.16px;word-spacing:1.36px;}
#t18_24{left:180px;bottom:395px;letter-spacing:-0.13px;word-spacing:1.14px;}
#t19_24{left:180px;bottom:377px;letter-spacing:-0.12px;word-spacing:0.27px;}
#t1a_24{left:180px;bottom:359px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1b_24{left:110px;bottom:334px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1c_24{left:153px;bottom:310px;}
#t1d_24{left:180px;bottom:310px;letter-spacing:-0.14px;word-spacing:-0.17px;}
#t1e_24{left:180px;bottom:292px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1f_24{left:153px;bottom:274px;}
#t1g_24{left:180px;bottom:274px;letter-spacing:-0.15px;word-spacing:2.18px;}
#t1h_24{left:180px;bottom:256px;letter-spacing:-0.16px;word-spacing:6.26px;}
#t1i_24{left:180px;bottom:238px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1j_24{left:110px;bottom:213px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1k_24{left:153px;bottom:189px;}
#t1l_24{left:180px;bottom:189px;letter-spacing:-0.13px;word-spacing:-0.5px;}
#t1m_24{left:180px;bottom:171px;letter-spacing:-0.12px;word-spacing:-1.72px;}
#t1n_24{left:180px;bottom:153px;letter-spacing:-0.12px;word-spacing:-1.14px;}
#t1o_24{left:153px;bottom:135px;}
#t1p_24{left:180px;bottom:135px;letter-spacing:-0.15px;word-spacing:1.73px;}
#t1q_24{left:180px;bottom:117px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t1r_24{left:141px;bottom:530px;letter-spacing:-0.12px;word-spacing:6.2px;}
#t1s_24{left:141px;bottom:512px;letter-spacing:-0.15px;word-spacing:-1.01px;}

.s0_24{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_24{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_24{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_24{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s4_24{font-size:17px;font-family:Arial-BoldItalicMT_7b;color:#231F20;}`}
        </style>
        <style id="fonts24" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldItalicMT_7b;
	src: url("fonts/Arial-BoldItalicMT_7b.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg24" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="24/24.svg"
          type="image/svg+xml"
          id="pdf24"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_24" class="t s0_24">
          22{" "}
        </span>
        <span id="t2_24" class="t s1_24">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_24" class="t s2_24">
          2.1.4. Арга зүй{" "}
        </span>
        <span id="t4_24" class="t s3_24">
          Чиглүүлэгтэй уншлага:{" "}
        </span>
        <span id="t5_24" class="t s1_24">
          Багш өөрийн бэлдсэн сургамжтай зохиол ба мэдээлэл бүхий текстийг
          уншиж, ойлгуулах{" "}
        </span>
        <span id="t6_24" class="t s1_24">
          боломжтой энэ аргыг{" "}
        </span>
        <span id="t7_24" class="t s4_24">
          Мэдлэг бүтээх{" "}
        </span>
        <span id="t8_24" class="t s1_24">
          үед ашиглаж болно.{" "}
        </span>
        <span id="t9_24" class="t s1_24">
          Хүүхдүүдэд зохиол, мэдээллийн текстийн гол санааг ойлгуулах, утгыг нь
          ангийнхаа{" "}
        </span>
        <span id="ta_24" class="t s1_24">
          хүүхдүүдтэй ярилцах боломжтой арга юм.{" "}
        </span>
        <span id="tb_24" class="t s1_24">
          Энэ аргыг 8-25 хүүхдүүдтэй ангид хэрэглэхэд илүү зохимжтой байдаг ба
          түүнээс олон бол{" "}
        </span>
        <span id="tc_24" class="t s1_24">
          хүүхдүүдийн оролцоо буурах сул тал байж болно.{" "}
        </span>
        <span id="td_24" class="t s2_24">
          Хугацаа:{" "}
        </span>
        <span id="te_24" class="t s1_24">
          30-40 минутад{" "}
        </span>
        <span id="tf_24" class="t s1_24">
          зохион байгуулалт хийж ашиглаж болох ба заавал 1 удаа бүх{" "}
        </span>
        <span id="tg_24" class="t s1_24">
          зүйлийг хийх шаардлагагүй хэд хэдэн хэсэгт хувааж сурах хүртэл нь бага
          багаар хийлгэж{" "}
        </span>
        <span id="th_24" class="t s1_24">
          болно.{" "}
        </span>
        <span id="ti_24" class="t s2_24">
          Сурагчдын хийх үйл ажиллагаа{" "}
        </span>
        <span id="tj_24" class="t s2_24">
          Алхам 1:{" "}
        </span>
        <span id="tk_24" class="t s1_24">
          Эхний үед хүүхдүүдийн сонирхлыг төрүүлэх, идэвхжүүлэх боломжтой
          сэдвээр{" "}
        </span>
        <span id="tl_24" class="t s1_24">
          хүүхдүүдийн өмнөх мэдлэг, мэдээллийг сэргээх, сэдвийн түлхүүр үгтэй
          танилцуулах{" "}
        </span>
        <span id="tm_24" class="t s1_24">
          ойлгуулах ажлуудыг хийнэ. Түлхүүр үгээ унших эх болон зохиол, үлгэрийн
          гол санааг{" "}
        </span>
        <span id="tn_24" class="t s1_24">
          зургаар илэрхийлэн түүнээс хүүхэд гол түлхүүр үгийг тааварлан олох
          дасгал байж болно.{" "}
        </span>
        <span id="to_24" class="t s1_24">
          Алхам 2: Энэ аргыг хэрэглэхийн өмнө багш урьдчилан зохиол, эх, үлгэр,
          текстийг гол гол{" "}
        </span>
        <span id="tp_24" class="t s1_24">
          утга санаагаар нь хэсгүүдэд хуваасан байна. Мөн хэсэг бүрийн гол
          санааг нь ойлгуулахад{" "}
        </span>
        <span id="tq_24" class="t s1_24">
          дөхөм болох асуултыг урьдчилан бэлтгэнэ.{" "}
        </span>
        <span id="tr_24" class="t s1_24">
          -{" "}
        </span>
        <span id="ts_24" class="t s1_24">
          Зохиол, эх, үлгэр, текстийг хүүхдүүд тэмдэглэсэн хэсэг хүртэл
          зогсолттой уншина.{" "}
        </span>
        <span id="tt_24" class="t s1_24">
          -{" "}
        </span>
        <span id="tu_24" class="t s1_24">
          Хүүхдүүд зохиол, эх үлгэр, текстийг чимээгүй уншаад багшийн асуултад
          хариулт{" "}
        </span>
        <span id="tv_24" class="t s1_24">
          өгнө.{" "}
        </span>
        <span id="tw_24" class="t s1_24">
          -{" "}
        </span>
        <span id="tx_24" class="t s1_24">
          Хүүхдүүд хариулахдаа зохиол, эх үлгэр, текстийн тохирох хэсгийг уншиж{" "}
        </span>
        <span id="ty_24" class="t s1_24">
          баталгаажуулна.{" "}
        </span>
        <span id="tz_24" class="t s1_24">
          Алхам 3: Хүүхдүүд ойлголтоо баталгаажуулж, улмаар шинэ мэдлэг,
          мэдээллээ хэрэглэх{" "}
        </span>
        <span id="t10_24" class="t s1_24">
          бололцоо олгох дасгалыг багш хүүхдүүдээр байнга хийлгэж хэвшүүлэх нь
          гэрийн{" "}
        </span>
        <span id="t11_24" class="t s1_24">
          даалгавраа бие дааж хийх боломж олгодог тул гэрийн даалгавар заавал
          өгч маргааш нь{" "}
        </span>
        <span id="t12_24" class="t s1_24">
          заавал шалгаж хэвшүүлэх нь хүүхдийн хөгжилд чухал хувь нэмэр оруулах
          ба бусад багш{" "}
        </span>
        <span id="t13_24" class="t s1_24">
          нар бүгд даалгавар өгч байгаатай уялдуулан холбож өгөх нь бие даах
          боломжийг олгоно.{" "}
        </span>
        <span id="t14_24" class="t s2_24">
          2.2.1.Үйл ажиллагаа{" "}
        </span>
        <span id="t15_24" class="t s2_24">
          а.Бага боловсролд{" "}
        </span>
        <span id="t16_24" class="t s1_24">
          -{" "}
        </span>
        <span id="t17_24" class="t s1_24">
          Ямар зүйлийг аюул гэх вэ?, ахуйн осол, аюул юунаас болж үүсдэг,
          амьдарч буй{" "}
        </span>
        <span id="t18_24" class="t s1_24">
          орчинд эргэн тойронд ямар зүйл аюултай байж болохыг ойлгуулах
          (Цахилгааны{" "}
        </span>
        <span id="t19_24" class="t s1_24">
          тог дамжуулдаг утаснаас болон хөл бөмбөгийн талбай дээр хүүхэд амь
          эрсэдсэн{" "}
        </span>
        <span id="t1a_24" class="t s1_24">
          аюулыг жишээгээр ярьж үзүүлэн ойлгуулах){" "}
        </span>
        <span id="t1b_24" class="t s1_24">
          б.Суурь боловсролд{" "}
        </span>
        <span id="t1c_24" class="t s1_24">
          -{" "}
        </span>
        <span id="t1d_24" class="t s1_24">
          Амьдрах орчны аюулгүй байдлаас илүү сэтгэл санааны аюулгүй байдал гэж
          ямар{" "}
        </span>
        <span id="t1e_24" class="t s1_24">
          ойлголт болохыг нийгмийн ажилтантай хамтран хэлэлцүүлэг хийж таниулах,{" "}
        </span>
        <span id="t1f_24" class="t s1_24">
          -{" "}
        </span>
        <span id="t1g_24" class="t s1_24">
          Эцэг эх багш ойр дотны хүмүүс яагаад өөрт нь санаа тавьж байгаа нь
          өөрт нь{" "}
        </span>
        <span id="t1h_24" class="t s1_24">
          дарамт юм шиг бодогдож байгааг жишээгээр харьцуулан ойлгуулах, хэнийг{" "}
        </span>
        <span id="t1i_24" class="t s1_24">
          хамгийн сайн найз гэх вэ гэсэн сэдвээр маргаан зохиох, хэлэлцүүлэг
          хийх{" "}
        </span>
        <span id="t1j_24" class="t s1_24">
          в.Бүрэн дунд боловсролд{" "}
        </span>
        <span id="t1k_24" class="t s1_24">
          -{" "}
        </span>
        <span id="t1l_24" class="t s1_24">
          Өөрийн аюулгүй байдлаа хэрхэн бий болгож амьдрах, энэ нь ирээдүйд
          мэргэжлээ{" "}
        </span>
        <span id="t1m_24" class="t s1_24">
          хэрхэн зөв сонгох, найз нөхдөө зөв сонгохоос эхлэлтэй талаар эцэг
          эхийн төлөөлөл{" "}
        </span>
        <span id="t1n_24" class="t s1_24">
          болон амжилттай яваа болон жирийн хүмүүстэй ярилцлага хийх замаар
          ойлгуулах{" "}
        </span>
        <span id="t1o_24" class="t s1_24">
          -{" "}
        </span>
        <span id="t1p_24" class="t s1_24">
          Өөртөө болон бусдад аюулгүй аж төрөх боломжийн тухай сонголттой
          мэдээлэл{" "}
        </span>
        <span id="t1q_24" class="t s1_24">
          цуглуулан мэтгэлцэх урлагт сургах{" "}
        </span>
        <span id="t1r_24" class="t s1_24">
          2.2.Хүүхдийг идэвхтэй, эрүүл амьдралын хэв маягт сургах, эрүүл хоол
          хүнс{" "}
        </span>
        <span id="t1s_24" class="t s1_24">
          хэрэглэхийн ач холбогдлыг таниулахад чиглэсэн үйл ажиллагаанд дэмжлэг
          үзүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
