import React from "react";
;

export default function SixTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`
#t1_62{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_62{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_62{left:110px;bottom:1140px;letter-spacing:-0.17px;word-spacing:0.06px;}
#t4_62{left:165px;bottom:1116px;letter-spacing:-0.12px;}
#t5_62{left:330px;bottom:1116px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t6_62{left:165px;bottom:1092px;letter-spacing:-0.13px;}
#t7_62{left:330px;bottom:1092px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t8_62{left:165px;bottom:1068px;letter-spacing:-0.13px;}
#t9_62{left:330px;bottom:1068px;letter-spacing:-0.16px;word-spacing:0.05px;}
#ta_62{left:165px;bottom:1044px;letter-spacing:-0.17px;}
#tb_62{left:330px;bottom:1044px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tc_62{left:165px;bottom:1019px;letter-spacing:-0.13px;}
#td_62{left:330px;bottom:1019px;letter-spacing:-0.16px;word-spacing:0.05px;}
#te_62{left:110px;bottom:146px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tf_62{left:110px;bottom:122px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tg_62{left:469px;bottom:131px;}
#th_62{left:110px;bottom:98px;letter-spacing:-0.17px;word-spacing:0.07px;}

.s0_62{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_62{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_62{font-size:10px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts62" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg62" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="62/62.svg"
          type="image/svg+xml"
          id="pdf62"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_62" class="t s0_62">
          60{" "}
        </span>
        <span id="t2_62" class="t s1_62">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_62" class="t s1_62">
          Зөвлөмж боловсруулсан:{" "}
        </span>
        <span id="t4_62" class="t s1_62">
          Н.Болормаа{" "}
        </span>
        <span id="t5_62" class="t s1_62">
          БМДИ арга зүйч{" "}
        </span>
        <span id="t6_62" class="t s1_62">
          Ц.Лхагвасүрэн{" "}
        </span>
        <span id="t7_62" class="t s1_62">
          Боловсролын хүрээлэн ЭША{" "}
        </span>
        <span id="t8_62" class="t s1_62">
          С.Отгонтуяа{" "}
        </span>
        <span id="t9_62" class="t s1_62">
          Боловсролын хүрээлэн ЭША{" "}
        </span>
        <span id="ta_62" class="t s1_62">
          Б.Энхтулга{" "}
        </span>
        <span id="tb_62" class="t s1_62">
          БМДИ арга зүйч{" "}
        </span>
        <span id="tc_62" class="t s1_62">
          Х.Энхжаргал{" "}
        </span>
        <span id="td_62" class="t s1_62">
          Боловсролын хүрээлэн ЭША{" "}
        </span>
        <span id="te_62" class="t s1_62">
          Формат 60х84/8{" "}
        </span>
        <span id="tf_62" class="t s1_62">
          Офсет хэвлэл. Офсет хэдлэлийн цаас. 80гр/м{" "}
        </span>
        <span id="tg_62" class="t s2_62">
          2{" "}
        </span>
        <span id="th_62" class="t s1_62">
          Хэвлэлийн хуудас 7 х.х{" "}
        </span>
      </div>
    </div>
  );
}
