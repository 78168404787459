import React from "react";
;

export default function FourFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_45{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_45{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_45{left:110px;bottom:1157px;letter-spacing:-0.15px;word-spacing:-1.03px;}
#t4_45{left:110px;bottom:1139px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t5_45{left:110px;bottom:1102px;letter-spacing:-0.18px;word-spacing:4.54px;}
#t6_45{left:110px;bottom:1084px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t7_45{left:110px;bottom:1066px;letter-spacing:-0.13px;word-spacing:3.57px;}
#t8_45{left:110px;bottom:1048px;letter-spacing:-0.16px;word-spacing:6.04px;}
#t9_45{left:110px;bottom:1030px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ta_45{left:110px;bottom:994px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_45{left:110px;bottom:975px;letter-spacing:-0.14px;word-spacing:-0.39px;}
#tc_45{left:110px;bottom:957px;letter-spacing:-0.14px;word-spacing:2.88px;}
#td_45{left:110px;bottom:939px;letter-spacing:-0.13px;word-spacing:0.03px;}
#te_45{left:110px;bottom:903px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_45{left:110px;bottom:885px;letter-spacing:-0.08px;}
#tg_45{left:146px;bottom:885px;letter-spacing:-0.14px;word-spacing:-0.79px;}
#th_45{left:110px;bottom:867px;letter-spacing:-0.12px;word-spacing:-0.46px;}
#ti_45{left:110px;bottom:848px;letter-spacing:-0.11px;word-spacing:0.81px;}
#tj_45{left:110px;bottom:830px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tk_45{left:110px;bottom:794px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_45{left:110px;bottom:776px;letter-spacing:-0.12px;word-spacing:-0.98px;}
#tm_45{left:110px;bottom:758px;letter-spacing:-0.13px;word-spacing:-1.37px;}
#tn_45{left:110px;bottom:740px;letter-spacing:-0.14px;word-spacing:0.04px;}
#to_45{left:110px;bottom:722px;letter-spacing:-0.14px;word-spacing:-1.19px;}
#tp_45{left:110px;bottom:703px;letter-spacing:-0.13px;word-spacing:-0.47px;}
#tq_45{left:110px;bottom:685px;letter-spacing:-0.17px;word-spacing:0.41px;}
#tr_45{left:110px;bottom:667px;letter-spacing:-0.12px;word-spacing:-0.26px;}
#ts_45{left:110px;bottom:649px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tt_45{left:110px;bottom:613px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tu_45{left:110px;bottom:595px;letter-spacing:-0.14px;word-spacing:-2.67px;}
#tv_45{left:110px;bottom:576px;letter-spacing:-0.13px;word-spacing:0.5px;}
#tw_45{left:110px;bottom:558px;letter-spacing:-0.14px;word-spacing:1.42px;}
#tx_45{left:110px;bottom:540px;letter-spacing:-0.13px;word-spacing:1.95px;}
#ty_45{left:110px;bottom:522px;letter-spacing:-0.16px;word-spacing:0.36px;}
#tz_45{left:110px;bottom:504px;letter-spacing:-0.15px;word-spacing:-0.38px;}
#t10_45{left:110px;bottom:486px;letter-spacing:-0.17px;word-spacing:0.2px;}
#t11_45{left:110px;bottom:468px;letter-spacing:-0.11px;word-spacing:-1.41px;}
#t12_45{left:153px;bottom:443px;}
#t13_45{left:180px;bottom:443px;letter-spacing:-0.13px;word-spacing:4.48px;}
#t14_45{left:180px;bottom:425px;letter-spacing:-0.17px;}
#t15_45{left:153px;bottom:407px;}
#t16_45{left:180px;bottom:407px;letter-spacing:-0.13px;}
#t17_45{left:153px;bottom:389px;}
#t18_45{left:180px;bottom:389px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t19_45{left:110px;bottom:365px;letter-spacing:-0.12px;word-spacing:3.21px;}
#t1a_45{left:110px;bottom:347px;letter-spacing:-0.16px;word-spacing:5.95px;}
#t1b_45{left:110px;bottom:328px;letter-spacing:-0.13px;word-spacing:2.63px;}
#t1c_45{left:110px;bottom:310px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1d_45{left:156px;bottom:292px;}
#t1e_45{left:180px;bottom:292px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1f_45{left:156px;bottom:274px;}
#t1g_45{left:180px;bottom:274px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1h_45{left:156px;bottom:256px;}
#t1i_45{left:180px;bottom:256px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1j_45{left:156px;bottom:238px;}
#t1k_45{left:185px;bottom:238px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1l_45{left:156px;bottom:220px;}
#t1m_45{left:180px;bottom:220px;letter-spacing:-0.17px;word-spacing:-0.92px;}
#t1n_45{left:180px;bottom:202px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1o_45{left:156px;bottom:183px;}
#t1p_45{left:180px;bottom:183px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1q_45{left:156px;bottom:165px;}
#t1r_45{left:180px;bottom:165px;letter-spacing:-0.09px;word-spacing:-0.01px;}
#t1s_45{left:110px;bottom:147px;letter-spacing:-0.12px;word-spacing:-0.75px;}
#t1t_45{left:110px;bottom:129px;letter-spacing:-0.13px;word-spacing:2.21px;}
#t1u_45{left:110px;bottom:111px;letter-spacing:-0.14px;word-spacing:-0.15px;}
#t1v_45{left:110px;bottom:93px;letter-spacing:-0.13px;word-spacing:5px;}
#t1w_45{left:110px;bottom:75px;letter-spacing:-0.13px;word-spacing:0.04px;}

.s0_45{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_45{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_45{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_45{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts45" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg45" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="45/45.svg"
          type="image/svg+xml"
          id="pdf45"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_45" class="t s0_45">
          43{" "}
        </span>
        <span id="t2_45" class="t s1_45">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_45" class="t s1_45">
          хэрэгтэй. Энэ мэдээлэл нь тэдэнд тухайн асуудлыг шийдвэрлэх шаардлага
          хэрэгцээ байгаа{" "}
        </span>
        <span id="t4_45" class="t s1_45">
          эсэх, хэрхэн шийдвэрлэх ёстойг тодорхойлоход туслах баримт нотолгоо
          болно.{" "}
        </span>
        <span id="t5_45" class="t s1_45">
          Алхам-3. Тодорхой мэдээлэлтэй болсоны дараа асуудлыг шийдвэрлэх арга
          зам буюу{" "}
        </span>
        <span id="t6_45" class="t s1_45">
          шийдлийг боловсруулах{" "}
        </span>
        <span id="t7_45" class="t s1_45">
          Хүн бүрийн оролцоо маш чухал. Бүгд өөрсдийн хувилбарыг дэвшүүлэн санал
          хурааж,{" "}
        </span>
        <span id="t8_45" class="t s1_45">
          багаараа боломжит хувилбаруудыг сонгон авна. Тэгээд цаг хугацаа,
          хэрэглэгдэхүүн,{" "}
        </span>
        <span id="t9_45" class="t s1_45">
          сургуулийн нөхцөл байдлын хувьд хамгийн боломжтой нэг хувилбарыг
          сонгоно.{" "}
        </span>
        <span id="ta_45" class="t s1_45">
          Алхам-4. Арга замаа сонгосны дараа ажлын төлөвлөгөө боловсруулах{" "}
        </span>
        <span id="tb_45" class="t s1_45">
          Ажлын төлөвлөгөө нь шийдэлд хүрэх арга замууд байна. Хийх ажлуудыг
          жагсаан хариуцах{" "}
        </span>
        <span id="tc_45" class="t s1_45">
          хүн, цаг хугацааг тодорхой болгосон төлөвлөгөө боловсруулна. Ингэхдээ
          нэг нэгнийхээ{" "}
        </span>
        <span id="td_45" class="t s1_45">
          санал хүсэлтийг харилцан сонсож хамтын шийдвэр гаргах ёстойг мартаж
          болохгүй.{" "}
        </span>
        <span id="te_45" class="t s1_45">
          Алхам-5. Төлөвлөгөөгөө ажил хэрэг болгох{" "}
        </span>
        <span id="tf_45" class="t s1_45">
          Үйл{" "}
        </span>
        <span id="tg_45" class="t s1_45">
          ажиллагааны мэдээллийг олон нийтэд хүргэх, бусад хүмүүсийг үйл
          ажиллагаанд татан{" "}
        </span>
        <span id="th_45" class="t s1_45">
          оролцуулна. Багийн гишүүн бүр өөр өөрийн хүлээсэн үүрэг хариуцлагаа
          гүйцэтгэх нь чухал{" "}
        </span>
        <span id="ti_45" class="t s1_45">
          гэдгийг хүүхдүүдээ сайн ойлгуулах хэрэгтэй. Тиймээс хүүхдүүд өөрийн
          хийж чадах ажлыг{" "}
        </span>
        <span id="tj_45" class="t s1_45">
          сонгон авах нь зүйтэй.{" "}
        </span>
        <span id="tk_45" class="t s1_45">
          Алхам-6. Төслийн үйл ажиллагааг үнэлэх{" "}
        </span>
        <span id="tl_45" class="t s1_45">
          Төслийн үр дүнг сургууль дээр олон нийтэд танилцуулна. Багш, ажилчдаас
          бүрдсэн 3 хүний{" "}
        </span>
        <span id="tm_45" class="t s1_45">
          бүрэлдэхүүнтэй шүүгчид төслийг тодорхой шалгуураар үнэлж төслийн
          хаалтын ажиллагаан{" "}
        </span>
        <span id="tn_45" class="t s1_45">
          дээр дүнг танилцуулж, шагнал гардуулна.{" "}
        </span>
        <span id="to_45" class="t s1_45">
          Энэ ажлыг хийхдээ багш дээрх алхмуудыг хүүхдүүдэд тайлбарлан ойлгуулж
          асуудал сонгох{" "}
        </span>
        <span id="tp_45" class="t s1_45">
          хүртэл чиглүүлнэ. Түүнээс цааш хүүхдүүд багаар өдөр өдөрт ажлаа
          хуваарилан ажиллана.{" "}
        </span>
        <span id="tq_45" class="t s1_45">
          Мэдээж багш болон сургуулийн бусад ажилтнуудаас тусламж авч болно.
          Улирлын амралт{" "}
        </span>
        <span id="tr_45" class="t s1_45">
          эхлэхээс 1-2 хоногийн өмнө төслийн үр дүнг ханын сонин бэлтгэн олны
          өмнө танилцуулна.{" "}
        </span>
        <span id="ts_45" class="t s1_45">
          Энэ хугацааг эхлээд хүүхдүүдтэй тохирох хэрэгтэй.{" "}
        </span>
        <span id="tt_45" class="t s2_45">
          Төслийн хэлэлцүүлэг{" "}
        </span>
        <span id="tu_45" class="t s1_45">
          Алхам-1. Хүүхдүүдээс тэдний хүрээлэн буй орчинд өөрчлөх, сайжруулах
          шаардлагатай зүйлс{" "}
        </span>
        <span id="tv_45" class="t s1_45">
          байгаа эсэхийг асуун ярилцаарай. Хүрээлэн буй орчныг сайжруулах нь
          орон нутагт болон{" "}
        </span>
        <span id="tw_45" class="t s1_45">
          хүүхдүүдэд, оршин суугчид, амьтан ургамалд үр ашигтай эсэх, тийм
          хэрэгцээ шаардлага{" "}
        </span>
        <span id="tx_45" class="t s1_45">
          байгаа эсэхийг асуугаарай. Хэрвээ тэд санал нэг байвал энэ төслийн үйл
          ажиллагаа нь{" "}
        </span>
        <span id="ty_45" class="t s1_45">
          тэр ажилд тус нэмэр болно гэдгийг тайлбарлана. Хэрэгцээ шаардлага
          байгаа нь тодорхой{" "}
        </span>
        <span id="tz_45" class="t s1_45">
          бол төслийн цар хүрээ хамгийн гол асуудал болно. Хүрээлэн буй орчныг
          сайжруулах төсөл{" "}
        </span>
        <span id="t10_45" class="t s1_45">
          нь жижиг, том байж болно. Хэрвээ энэ дасгал ажлаас улбаалан төсөл
          хэрэгжүүлэх болбол{" "}
        </span>
        <span id="t11_45" class="t s1_45">
          дараах зүйлсийг анхааран үзэх ёстой. Хүүхдүүдэд дараах зөвлөмжүүдийг
          өгч чиглүүлээрэй.{" "}
        </span>
        <span id="t12_45" class="t s1_45">
          -{" "}
        </span>
        <span id="t13_45" class="t s1_45">
          Төслийг амжилттай хэрэгжүүлэх боломжтой цар хүрээтэй, арга замыг
          сонгон{" "}
        </span>
        <span id="t14_45" class="t s1_45">
          хэрэглэх{" "}
        </span>
        <span id="t15_45" class="t s1_45">
          -{" "}
        </span>
        <span id="t16_45" class="t s1_45">
          ёстой.{" "}
        </span>
        <span id="t17_45" class="t s1_45">
          -{" "}
        </span>
        <span id="t18_45" class="t s1_45">
          Төслийн үр дүн орон нутаг, амьд байгальд, хүмүүст ашигтай байх ёстой.{" "}
        </span>
        <span id="t19_45" class="t s1_45">
          Алхам 2. Ерөнхий хэлэлцүүлэг хийсний дараа сурагчдыг 8-10 гишүүнтэй
          багт хуваана.{" "}
        </span>
        <span id="t1a_45" class="t s1_45">
          Тэгээд баг бүрийг хүрээлэн буй орчныг сайжруулах төслийн загвар
          боловсруулахыг{" "}
        </span>
        <span id="t1b_45" class="t s1_45">
          даалгана. Тэдэнд дараах асуултын дагуу ярилцаж, бэлдэх хангалттай
          хугацаа өгөөрэй.{" "}
        </span>
        <span id="t1c_45" class="t s1_45">
          Асуудлыг нухацтай хэлэлцэн 1, 2 алхмын дагуу асуудлаа тодорхойлох
          даалгавар өгнө.{" "}
        </span>
        <span id="t1d_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1e_45" class="t s1_45">
          Өөрчлөх сайжруулах шаардлагатай ямар асуудал байна вэ?{" "}
        </span>
        <span id="t1f_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1g_45" class="t s1_45">
          Аль асуудал нь хамгийн чухал асуудал вэ?{" "}
        </span>
        <span id="t1h_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1i_45" class="t s1_45">
          Уг асуудлыг богино хугацаанд шийдвэрлэх боломжтой юу?{" "}
        </span>
        <span id="t1j_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1k_45" class="t s1_45">
          Тухайн асуудлыг шийдвэрлэхэд мөнгө санхүү шаардлагатай юу?{" "}
        </span>
        <span id="t1l_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1m_45" class="t s1_45">
          Тухайн асуудлыг хүүхдүүд дангаараа шийдвэрлэх боломжтой юу? Туслалцаа
          авах{" "}
        </span>
        <span id="t1n_45" class="t s1_45">
          боломж хэр байна вэ?{" "}
        </span>
        <span id="t1o_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1p_45" class="t s1_45">
          Асуудлыг шийдвэрлэх нь хэнд ашигтай вэ? Хэнд ашиггүй вэ?{" "}
        </span>
        <span id="t1q_45" class="t s3_45">
          •{" "}
        </span>
        <span id="t1r_45" class="t s1_45">
          Хэн та нарыг дэмжих, хэн дэмжихгүй вэ?{" "}
        </span>
        <span id="t1s_45" class="t s1_45">
          Алхам 3. Төслийн дараагийн алхмуудыг хүүхдүүд дараагийн өдрөөс эхлэн
          бие даан багаар{" "}
        </span>
        <span id="t1t_45" class="t s1_45">
          хийж гүйцэтгэнэ. Хүүхдүүд өөрсдөө хэрэгжүүлэн хийх ёстой, багш зөвхөн
          шаардлагатай{" "}
        </span>
        <span id="t1u_45" class="t s1_45">
          үед зөвөлгөө өгөн чиглүүлнэ. Төслийн танилцуулга болохоос өмнө
          хүүхдүүд багаар ажлын{" "}
        </span>
        <span id="t1v_45" class="t s1_45">
          төлөвлөгөөний дагуу гүйцэтгэн ханын сонин бэлдэж дууссан байх ёстой.
          Мөн ханын{" "}
        </span>
        <span id="t1w_45" class="t s1_45">
          сонингийн 4 хэсгийг багийн гишүүүд хуваан авч танилцуулахаар бэлдэнэ.{" "}
        </span>
      </div>
    </div>
  );
}
