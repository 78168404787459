import React from "react";
;

export default function TwentySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p26" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_26{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_26{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_26{left:110px;bottom:1138px;letter-spacing:-0.16px;word-spacing:1.83px;}
#t4_26{left:110px;bottom:1120px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t5_26{left:110px;bottom:1102px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t6_26{left:110px;bottom:1084px;letter-spacing:-0.11px;}
#t7_26{left:229px;bottom:1084px;}
#t8_26{left:153px;bottom:1060px;}
#t9_26{left:180px;bottom:1060px;letter-spacing:-0.13px;word-spacing:8.06px;}
#ta_26{left:180px;bottom:1042px;letter-spacing:-0.19px;word-spacing:0.08px;}
#tb_26{left:153px;bottom:1024px;}
#tc_26{left:180px;bottom:1024px;letter-spacing:-0.14px;word-spacing:0.04px;}
#td_26{left:153px;bottom:1005px;}
#te_26{left:180px;bottom:1005px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tf_26{left:110px;bottom:981px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_26{left:110px;bottom:963px;letter-spacing:-0.2px;word-spacing:0.1px;}
#th_26{left:153px;bottom:939px;}
#ti_26{left:180px;bottom:939px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tj_26{left:153px;bottom:921px;}
#tk_26{left:180px;bottom:921px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tl_26{left:153px;bottom:902px;}
#tm_26{left:180px;bottom:902px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tn_26{left:110px;bottom:878px;letter-spacing:-0.14px;word-spacing:0.04px;}
#to_26{left:153px;bottom:854px;}
#tp_26{left:180px;bottom:854px;letter-spacing:-0.18px;word-spacing:2.01px;}
#tq_26{left:180px;bottom:836px;letter-spacing:-0.09px;}
#tr_26{left:153px;bottom:818px;}
#ts_26{left:180px;bottom:818px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tt_26{left:110px;bottom:793px;letter-spacing:-0.15px;word-spacing:3.29px;}
#tu_26{left:110px;bottom:775px;letter-spacing:-0.17px;word-spacing:0.32px;}
#tv_26{left:110px;bottom:757px;letter-spacing:-0.15px;word-spacing:0.38px;}
#tw_26{left:110px;bottom:739px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tx_26{left:110px;bottom:721px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ty_26{left:444px;bottom:721px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tz_26{left:110px;bottom:703px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t10_26{left:110px;bottom:685px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t11_26{left:110px;bottom:667px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t12_26{left:110px;bottom:648px;letter-spacing:-0.14px;word-spacing:9.98px;}
#t13_26{left:110px;bottom:630px;letter-spacing:-0.16px;word-spacing:5.06px;}
#t14_26{left:110px;bottom:612px;letter-spacing:-0.14px;word-spacing:6px;}
#t15_26{left:110px;bottom:594px;letter-spacing:-0.14px;word-spacing:1.05px;}
#t16_26{left:110px;bottom:576px;letter-spacing:-0.13px;word-spacing:0.24px;}
#t17_26{left:110px;bottom:558px;letter-spacing:-0.13px;word-spacing:2.14px;}
#t18_26{left:110px;bottom:540px;letter-spacing:-0.11px;}
#t19_26{left:110px;bottom:521px;letter-spacing:-0.14px;word-spacing:6.87px;}
#t1a_26{left:110px;bottom:503px;letter-spacing:-0.15px;word-spacing:3.85px;}
#t1b_26{left:110px;bottom:485px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1c_26{left:110px;bottom:467px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1d_26{left:633px;bottom:467px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1e_26{left:153px;bottom:443px;}
#t1f_26{left:180px;bottom:443px;letter-spacing:-0.12px;word-spacing:-1.04px;}
#t1g_26{left:180px;bottom:425px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1h_26{left:153px;bottom:407px;}
#t1i_26{left:180px;bottom:407px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1j_26{left:153px;bottom:388px;}
#t1k_26{left:180px;bottom:388px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1l_26{left:153px;bottom:370px;}
#t1m_26{left:180px;bottom:370px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1n_26{left:153px;bottom:352px;}
#t1o_26{left:180px;bottom:352px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1p_26{left:153px;bottom:334px;}
#t1q_26{left:180px;bottom:334px;letter-spacing:-0.16px;word-spacing:11.98px;}
#t1r_26{left:180px;bottom:316px;letter-spacing:-0.19px;}
#t1s_26{left:110px;bottom:292px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t1t_26{left:110px;bottom:273px;letter-spacing:-0.12px;word-spacing:-0.32px;}
#t1u_26{left:219px;bottom:273px;letter-spacing:-0.13px;word-spacing:-0.32px;}
#t1v_26{left:110px;bottom:255px;letter-spacing:-0.14px;word-spacing:6.15px;}
#t1w_26{left:110px;bottom:237px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1x_26{left:110px;bottom:219px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1y_26{left:110px;bottom:201px;letter-spacing:-0.17px;word-spacing:0.06px;}
#t1z_26{left:153px;bottom:177px;}
#t20_26{left:180px;bottom:177px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t21_26{left:153px;bottom:159px;}
#t22_26{left:180px;bottom:159px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t23_26{left:110px;bottom:134px;letter-spacing:-0.29px;word-spacing:0.19px;}
#t24_26{left:153px;bottom:110px;}
#t25_26{left:180px;bottom:110px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t26_26{left:153px;bottom:92px;}
#t27_26{left:180px;bottom:92px;letter-spacing:-0.18px;word-spacing:0.08px;}

.s0_26{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_26{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_26{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_26{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>
        <style id="fonts26" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg26" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="26/26.svg"
          type="image/svg+xml"
          id="pdf26"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_26" class="t s0_26">
          24{" "}
        </span>
        <span id="t2_26" class="t s1_26">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_26" class="t s1_26">
          орчинд зохион байгуулж болох юм. Тухайлбал, “Эрүүл мэндэд сурагчийн
          оролцоо” зэрэг{" "}
        </span>
        <span id="t4_26" class="t s1_26">
          сурагчдын өөрийн удирдлагын клуб байж болно.{" "}
        </span>
        <span id="t5_26" class="t s1_26">
          А. Эрүүл мэнд сурагчийн оролцоо{" "}
        </span>
        <span id="t6_26" class="t s2_26">
          2.3.А1.Зорилго
        </span>
        <span id="t7_26" class="t s1_26">
          :{" "}
        </span>
        <span id="t8_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t9_26" class="t s1_26">
          Эрүүл мэндийн хичээлээр эзэмшсэн мэдлэгийг бодит амьдрал дээрх үйл{" "}
        </span>
        <span id="ta_26" class="t s1_26">
          ажиллагаатай уялдуулан холбох{" "}
        </span>
        <span id="tb_26" class="t s1_26">
          -{" "}
        </span>
        <span id="tc_26" class="t s1_26">
          Эрүүл байх арга замуудыг сурталчлах, дэмжих чадвар, дадал эзэмшүүлэх{" "}
        </span>
        <span id="td_26" class="t s1_26">
          -{" "}
        </span>
        <span id="te_26" class="t s1_26">
          Хамтдаа сурч, хамтдаа хөгжихөд чиглэгдэнэ.{" "}
        </span>
        <span id="tf_26" class="t s1_26">
          2.3.А2.Үйл ажиллагаа:{" "}
        </span>
        <span id="tg_26" class="t s2_26">
          Бага боловсролд{" "}
        </span>
        <span id="th_26" class="t s1_26">
          -{" "}
        </span>
        <span id="ti_26" class="t s1_26">
          Эрүүл ахуйн зөв дэглэм баримтлах{" "}
        </span>
        <span id="tj_26" class="t s1_26">
          -{" "}
        </span>
        <span id="tk_26" class="t s1_26">
          Өөрийгөө болон бусдыг хүндэтгэх{" "}
        </span>
        <span id="tl_26" class="t s1_26">
          -{" "}
        </span>
        <span id="tm_26" class="t s1_26">
          Эрүүл зан үйлд суралцах{" "}
        </span>
        <span id="tn_26" class="t s1_26">
          Суурь, бүрэн дунд боловсролд{" "}
        </span>
        <span id="to_26" class="t s1_26">
          -{" "}
        </span>
        <span id="tp_26" class="t s1_26">
          Өөрт болон бусдад тулгарч буй асуудлыг тодорхойлох, шийдвэрлэх арга
          замыг{" "}
        </span>
        <span id="tq_26" class="t s1_26">
          дэвшүүлэх{" "}
        </span>
        <span id="tr_26" class="t s1_26">
          -{" "}
        </span>
        <span id="ts_26" class="t s1_26">
          Өөрийн болон бусдын эрүүл мэндийг хамгаалахад хариуцлагатай хандах{" "}
        </span>
        <span id="tt_26" class="t s1_26">
          Бага боловсролын түвшинд сурагчдын өөрийн удирдлагын клуб байх
          шаардлагагүй ба{" "}
        </span>
        <span id="tu_26" class="t s1_26">
          багшийн удирдлага дор сурагчид хамтран эрүүл мэндийн мэдлэг, дадал,
          чадварыг бусдад{" "}
        </span>
        <span id="tv_26" class="t s1_26">
          түгээх, үлгэр дуурайл болох үйл ажиллагааг зохион байгуулна. Мөн
          холбогдох тэмдэглэлт{" "}
        </span>
        <span id="tw_26" class="t s1_26">
          өдрүүдийг угтсан үйл ажиллагаа зохион байгуулж болно.{" "}
        </span>
        <span id="tx_26" class="t s1_26">
          Суурь, бүрэн дунд боловсролын түвшинд{" "}
        </span>
        <span id="ty_26" class="t s1_26">
          сургуулиуд сурагчдын өөрийн удирдлагын клуб,{" "}
        </span>
        <span id="tz_26" class="t s1_26">
          бүлгэм ажиллуулах нь илүү тохиромжтой.{" "}
        </span>
        <span id="t10_26" class="t s1_26">
          2.3. Арга зүй{" "}
        </span>
        <span id="t11_26" class="t s3_26">
          А.“Эрүүл мэндэд сурагчийн оролцоо” сурагчдын өөрийн удирдлагын клуб{" "}
        </span>
        <span id="t12_26" class="t s1_26">
          Сургууль дээрх сурагчдын өөрийн удирдлагын клуб нь өөрсдийн
          боловсруулсан{" "}
        </span>
        <span id="t13_26" class="t s1_26">
          төлөвлөгөөтэй байна. Клубын гишүүд нь үйл ажиллагааны төлөвлөлтдөө
          орон нутаг,{" "}
        </span>
        <span id="t14_26" class="t s1_26">
          сургуулийнхаа онцлогт тохирсон бусад хүмүүсийн дэмжлэгтэй зохион
          байгуулах үйл{" "}
        </span>
        <span id="t15_26" class="t s1_26">
          ажиллагаанаас гадна сурагчид өөрсдийн санаачилгаар зохион байгуулах
          үйл ажиллагааг{" "}
        </span>
        <span id="t16_26" class="t s1_26">
          тусгах хэрэгтэй. Клубын үйл ажиллагаанд олон хүмүүсийг татан
          оролцуулах нь нэг талаас{" "}
        </span>
        <span id="t17_26" class="t s1_26">
          сурагчдад сонирхолтой байхын зэрэгцээ ажлын цар хүрээг өргөжүүлэх,
          нийтэд хүргэхэд{" "}
        </span>
        <span id="t18_26" class="t s1_26">
          тустай.{" "}
        </span>
        <span id="t19_26" class="t s1_26">
          Зохион байгуулах үйл ажиллагааны сэдэв, агуулгыг сонгохдоо тухайн
          сэдэв нь ач{" "}
        </span>
        <span id="t1a_26" class="t s1_26">
          холбогдолтой, чухал байх, хийж хэрэгжүүлэхүйц байх, сурагчдын сонирхол
          хэрэгцээнд{" "}
        </span>
        <span id="t1b_26" class="t s1_26">
          нийцсэн байхыг анхаарах хэрэгтэй.{" "}
        </span>
        <span id="t1c_26" class="t s1_26">
          Үйл ажиллагааны төлөвлөлт, зохион байгуулалтад дараах алхам,{" "}
        </span>
        <span id="t1d_26" class="t s1_26">
          үе шатыг харгалзана.{" "}
        </span>
        <span id="t1e_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t1f_26" class="t s1_26">
          Үе тэнгийн сурагчид, өсвөр үеийнхний эрүүл мэндэд тулгамдсан асуудлаас
          зохион{" "}
        </span>
        <span id="t1g_26" class="t s1_26">
          байгуулах үйл ажиллагааны сэдэв, агуулгыг сонгох{" "}
        </span>
        <span id="t1h_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t1i_26" class="t s1_26">
          Үйл ажиллагааны зорилго, зорилтыг тодорхойлох{" "}
        </span>
        <span id="t1j_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t1k_26" class="t s1_26">
          Холбогдох мэдээлэл цуглуулах, боловсруулалт хийх{" "}
        </span>
        <span id="t1l_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t1m_26" class="t s1_26">
          Үйл ажиллагааг төлөвлөн, хэрэгжүүлэх{" "}
        </span>
        <span id="t1n_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t1o_26" class="t s1_26">
          Үйл ажиллагааны үр дүнг хэлэлцэх, тайлагнах{" "}
        </span>
        <span id="t1p_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t1q_26" class="t s1_26">
          Дараагийн үйл ажиллагааны төлөвлөлт, хэрэгжилтэд анхаарах зүйлсийг{" "}
        </span>
        <span id="t1r_26" class="t s1_26">
          тодорхойлох{" "}
        </span>
        <span id="t1s_26" class="t s3_26">
          Б.“Аюулгүй амьдрах ухаан” сургалтын хөтөлбөр{" "}
        </span>
        <span id="t1t_26" class="t s1_26">
          2.3. Зорилго:{" "}
        </span>
        <span id="t1u_26" class="t s1_26">
          Ерөнхий боловсролын сургуулийн сурагчдын амьдрах ур ухааныг хөгжүүлэх,{" "}
        </span>
        <span id="t1v_26" class="t s1_26">
          аюулгүй байдлын сургалтыг нэвтрүүлж, хүүхдийг эрсдэлгүй, эрүүл аюулгүй
          амьдрах{" "}
        </span>
        <span id="t1w_26" class="t s1_26">
          чадварт сургахад чиглэнэ.{" "}
        </span>
        <span id="t1x_26" class="t s1_26">
          2.3.Үндсэн ойлголт{" "}
        </span>
        <span id="t1y_26" class="t s1_26">
          Орчны аюулгүй байдал:{" "}
        </span>
        <span id="t1z_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t20_26" class="t s1_26">
          Ахуй орчны аюулгүй байдал{" "}
        </span>
        <span id="t21_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t22_26" class="t s1_26">
          Нийгмийн орчны аюулгүй байдал{" "}
        </span>
        <span id="t23_26" class="t s1_26">
          Гамшиг, осол, аюулт үзэгдэл{" "}
        </span>
        <span id="t24_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t25_26" class="t s1_26">
          Байгаль, цаг уурын гаралтай аюулт үзэгдэл{" "}
        </span>
        <span id="t26_26" class="t s1_26">
          -{" "}
        </span>
        <span id="t27_26" class="t s1_26">
          Хүний үйл ажиллагаатай холбоотой аюулт үзэгдэл{" "}
        </span>
      </div>
    </div>
  );
}
