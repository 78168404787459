import React from "react";
;

export default function Five() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p5" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`#t1_5{left:844px;bottom:21px;}
#t2_5{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_5{left:110px;bottom:1157px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t4_5{left:110px;bottom:1139px;letter-spacing:-0.19px;word-spacing:-1.36px;}
#t5_5{left:110px;bottom:1121px;letter-spacing:-0.13px;word-spacing:-0.49px;}
#t6_5{left:110px;bottom:1102px;letter-spacing:-0.14px;word-spacing:-0.99px;}
#t7_5{left:110px;bottom:1084px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t8_5{left:110px;bottom:1066px;letter-spacing:-0.17px;word-spacing:-0.5px;}
#t9_5{left:110px;bottom:1048px;letter-spacing:-0.17px;word-spacing:1.79px;}
#ta_5{left:110px;bottom:1030px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tb_5{left:153px;bottom:1012px;letter-spacing:-0.13px;word-spacing:4.12px;}
#tc_5{left:180px;bottom:994px;letter-spacing:-0.14px;word-spacing:-2.51px;}
#td_5{left:180px;bottom:975px;letter-spacing:-0.12px;word-spacing:0.89px;}
#te_5{left:180px;bottom:957px;letter-spacing:-0.11px;word-spacing:1.12px;}
#tf_5{left:180px;bottom:939px;letter-spacing:-0.13px;word-spacing:-0.25px;}
#tg_5{left:180px;bottom:921px;letter-spacing:-0.13px;word-spacing:-0.5px;}
#th_5{left:180px;bottom:903px;letter-spacing:-0.14px;word-spacing:4.43px;}
#ti_5{left:180px;bottom:885px;letter-spacing:-0.21px;word-spacing:0.11px;}
#tj_5{left:153px;bottom:867px;letter-spacing:-0.05px;}
#tk_5{left:180px;bottom:867px;letter-spacing:-0.1px;word-spacing:0.01px;}
#tl_5{left:153px;bottom:848px;letter-spacing:-0.05px;}
#tm_5{left:180px;bottom:848px;letter-spacing:-0.12px;word-spacing:-0.84px;}
#tn_5{left:180px;bottom:830px;letter-spacing:-0.14px;word-spacing:4.62px;}
#to_5{left:180px;bottom:812px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tp_5{left:153px;bottom:794px;letter-spacing:-0.13px;word-spacing:9.86px;}
#tq_5{left:180px;bottom:776px;letter-spacing:-0.2px;word-spacing:1.69px;}
#tr_5{left:180px;bottom:758px;letter-spacing:-0.14px;word-spacing:0.32px;}
#ts_5{left:180px;bottom:740px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tt_5{left:153px;bottom:722px;letter-spacing:-0.12px;word-spacing:8.39px;}
#tu_5{left:180px;bottom:703px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_5{left:153px;bottom:685px;letter-spacing:-0.15px;word-spacing:7.19px;}
#tw_5{left:180px;bottom:667px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tx_5{left:110px;bottom:649px;letter-spacing:-0.18px;word-spacing:-1.24px;}
#ty_5{left:110px;bottom:631px;letter-spacing:-0.17px;word-spacing:1.31px;}
#tz_5{left:110px;bottom:613px;letter-spacing:-0.15px;word-spacing:2.71px;}
#t10_5{left:110px;bottom:595px;letter-spacing:-0.18px;word-spacing:1.77px;}
#t11_5{left:110px;bottom:576px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t12_5{left:110px;bottom:558px;letter-spacing:-0.13px;word-spacing:-0.96px;}
#t13_5{left:110px;bottom:540px;letter-spacing:-0.13px;word-spacing:0.67px;}
#t14_5{left:110px;bottom:522px;letter-spacing:-0.13px;word-spacing:-0.13px;}
#t15_5{left:110px;bottom:504px;letter-spacing:-0.15px;word-spacing:-0.18px;}
#t16_5{left:110px;bottom:486px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t17_5{left:153px;bottom:468px;}
#t18_5{left:180px;bottom:468px;letter-spacing:-0.17px;word-spacing:0.01px;}
#t19_5{left:180px;bottom:449px;letter-spacing:-0.28px;}
#t1a_5{left:153px;bottom:431px;}
#t1b_5{left:180px;bottom:431px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1c_5{left:153px;bottom:413px;}
#t1d_5{left:180px;bottom:413px;letter-spacing:-0.18px;word-spacing:3.86px;}
#t1e_5{left:180px;bottom:395px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1f_5{left:153px;bottom:377px;}
#t1g_5{left:180px;bottom:377px;letter-spacing:-0.17px;word-spacing:-1.51px;}
#t1h_5{left:180px;bottom:359px;letter-spacing:-0.16px;word-spacing:4.18px;}
#t1i_5{left:180px;bottom:341px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1j_5{left:110px;bottom:323px;letter-spacing:-0.17px;word-spacing:0.25px;}
#t1k_5{left:110px;bottom:304px;letter-spacing:-0.14px;word-spacing:2.51px;}
#t1l_5{left:110px;bottom:286px;letter-spacing:-0.17px;word-spacing:6.36px;}
#t1m_5{left:110px;bottom:268px;letter-spacing:-0.17px;word-spacing:-0.93px;}
#t1n_5{left:110px;bottom:250px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1o_5{left:110px;bottom:232px;letter-spacing:-0.19px;word-spacing:1.02px;}
#t1p_5{left:110px;bottom:214px;letter-spacing:-0.14px;word-spacing:-1.05px;}
#t1q_5{left:110px;bottom:196px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_5{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_5{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_5{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts5" type="text/css">
          {`@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg5" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="5/5.svg"
          type="image/svg+xml"
          id="pdf5"
          style={pdfStyle}></object>
      </div>

      <div class="text-container">
        <span id="t1_5" class="t s0_5">
          3{" "}
        </span>
        <span id="t2_5" class="t s1_5">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_5" class="t s1_5">
          ХГҮА (extracurricular activities) гэдгийг эх сурвалжуудад ихэнхдээ
          жишээ хэлбэрээр{" "}
        </span>
        <span id="t4_5" class="t s1_5">
          тайлбарласан байдаг байна. Тухайлбал, Барнетт (2007) сурагчдын спорт,
          хичээлээс гадуурх{" "}
        </span>
        <span id="t5_5" class="t s1_5">
          төрөл бүрийн үйл ажиллагааны оролцоо, дэмжлэг гэж; Кесер нар (2011)
          мэргэжлийн болон{" "}
        </span>
        <span id="t6_5" class="t s1_5">
          оюутан суралцагчдын сонирхлын бүлэг, клуб, төсөл зэргийг дурдсан бол,
          Бирзеа нар (2004){" "}
        </span>
        <span id="t7_5" class="t s1_5">
          ХГҮА-г албан бус сургалтын хөтөлбөрийн нэг хэсэг гэж тодорхойлсон
          байна.{" "}
        </span>
        <span id="t8_5" class="t s1_5">
          2012 онд АНУ-ын Юта мужийн их сургуулийн судлаач Кеннет Р. Барткус нар
          ХГҮА-ны тухай{" "}
        </span>
        <span id="t9_5" class="t s1_5">
          ойлголтыг тодруулах зорилгоор судалгаа хийсэн байна. Ойлголтыг
          тодруулах үүднээс уг{" "}
        </span>
        <span id="ta_5" class="t s1_5">
          судалгаанд авч үзсэн зарим эх сурвалж, тодорхойлолтуудыг харъя. Үүнд:{" "}
        </span>
        <span id="tb_5" class="t s1_5">
          1. ХГҮА (extracurricular activities) гэдгийг сургууль, коллеж, их дээд
          сургуулиудын{" "}
        </span>
        <span id="tc_5" class="t s1_5">
          хичээл, сургалтын хөтөлбөрөөс гадуур зохион байгуулах үйл ажиллагааг
          хэлнэ, гээд{" "}
        </span>
        <span id="td_5" class="t s1_5">
          үүнд хичээлээс гадуурх нэмэлт академик үйл ажиллагаа, соёлын үйл
          ажиллагаа{" "}
        </span>
        <span id="te_5" class="t s1_5">
          зэргийг багтааж үзжээ. Мөн ХГҮА нь сайн дурын шинжтэй нийгэм,
          хүмүүнлэгийн{" "}
        </span>
        <span id="tf_5" class="t s1_5">
          үйл ажиллагаа бөгөөд ижил насны суралцагчдын оролцоог хангасан байна
          гэжээ.{" "}
        </span>
        <span id="tg_5" class="t s1_5">
          ХГҮА-г сургууль, багш нарын удирдлага, чиглүүлэгтэйгээр, мөн сурагчид
          бие даан{" "}
        </span>
        <span id="th_5" class="t s1_5">
          санаачилж зохион байгуулж болно. ХГҮА-г суралцагчид бие даан,
          санаачлан{" "}
        </span>
        <span id="ti_5" class="t s1_5">
          зохион байгуулах нь илүү түгээмэл байдаг.{" "}
        </span>
        <span id="tj_5" class="t s1_5">
          2.{" "}
        </span>
        <span id="tk_5" class="t s1_5">
          lpExtracurricular activities (ХГҮА) нь сургалтыг дэмжсэн үйл ажиллагаа{" "}
        </span>
        <span id="tl_5" class="t s1_5">
          3.{" "}
        </span>
        <span id="tm_5" class="t s1_5">
          Extracurricular activities (ХГҮА) сурагчдын өөрийн удирдлагын
          байгууллага, клубын{" "}
        </span>
        <span id="tn_5" class="t s1_5">
          гишүүнчлэл, удирдлага, сайн дурын оролцоо гэсэн утгыг агуулна.
          Сурагчдын{" "}
        </span>
        <span id="to_5" class="t s1_5">
          өөрийн удирдлагын байгууллага, клубын үйл ажиллагааг хэлнэ.{" "}
        </span>
        <span id="tp_5" class="t s1_5">
          4. Extracurricular activitie (ХГҮА)-г сургуулийн хүрээнд
          сонголттойгоор зохион{" "}
        </span>
        <span id="tq_5" class="t s1_5">
          байгуулах нэмэлт хөтөлбөр, үйл ажиллагааг хэлнэ. Үүнд, нэмэлт сургалт,
          спорт,{" "}
        </span>
        <span id="tr_5" class="t s1_5">
          урлаг соёлын үйл ажиллагааны тусгай хөтөлбөр, аялал, оролцооны
          байгууллага,{" "}
        </span>
        <span id="ts_5" class="t s1_5">
          клубээр олгох боловсролыг авч үзнэ.{" "}
        </span>
        <span id="tt_5" class="t s1_5">
          5. Extracurricular activitie (ХГҮА) нь академик мэдлэгийг бататгах,
          өргөжүүлэх{" "}
        </span>
        <span id="tu_5" class="t s1_5">
          сургуулийн хүрээн дэх хичээлээс гадуурх үйл ажиллагааг ойлгоно.{" "}
        </span>
        <span id="tv_5" class="t s1_5">
          6. ХГҮА гэдэг нь сургуулийн хүрээнд хичээлээс гадуурх сургалтын
          хөтөлбөрт{" "}
        </span>
        <span id="tw_5" class="t s1_5">
          тусгагдаагүй академик болон хичээлийн бус бусад үйл ажиллагааг хэлнэ.{" "}
        </span>
        <span id="tx_5" class="t s1_5">
          Уг судалгааны эцэст, ХГҮА-ны тухай нийтээр хүлээн зөвшөөрөгдсөн
          тодорхойлолт одоогоор{" "}
        </span>
        <span id="ty_5" class="t s1_5">
          байхгүй байна гээд, судлаачийн зүгээс үүнийг “хичээлээс гадуурх
          хамтарсан сургалт, үйл{" "}
        </span>
        <span id="tz_5" class="t s1_5">
          ажиллагаа” хэмээн ойлгож болохоор байна гэжээ. Санал болгож буй энэхүү
          (хичээлээс{" "}
        </span>
        <span id="t10_5" class="t s1_5">
          гадуурх хамтарсан сургалт, үйл ажиллагаа) тодорхойлолт нь ХГҮА-ны
          талаарх хэт өргөн{" "}
        </span>
        <span id="t11_5" class="t s1_5">
          хүрээний ойлголтыг багасгахад сургууль багш нарт дөхөм болох талтай.{" "}
        </span>
        <span id="t12_5" class="t s1_5">
          ХГҮА нь тасралтгүй шинжтэй, шууд ба шууд бус байдлаар сурагчдын
          хөгжлийг дэмжсэн үйл{" "}
        </span>
        <span id="t13_5" class="t s1_5">
          ажиллагаа байх ба албан ёсны сургалтын хөтөлбөрийн хэрэгжилтийг
          дэмжихэд чиглэсэн,{" "}
        </span>
        <span id="t14_5" class="t s1_5">
          тодорхой хөтөлбөр, төлөвлөгөөтэй байж болно. ХГҮА-ны агуулгын хүрээ нь
          тэнцвэртэй, үр{" "}
        </span>
        <span id="t15_5" class="t s1_5">
          дүнтэй байх шаардлагатай. Эх сурвалжуудаас авч үзэхэд ХГҮА-ны агуулгын
          хүрээг дараах{" "}
        </span>
        <span id="t16_5" class="t s1_5">
          байдлаар тодорхойлж болохоор байна. Үүнд,{" "}
        </span>
        <span id="t17_5" class="t s2_5">
          •{" "}
        </span>
        <span id="t18_5" class="t s1_5">
          Суралцсан зүйлийг бататгах (мэдлэгээ бататгах, хэрэглэх, өргөжүүлэх
          боломжийг{" "}
        </span>
        <span id="t19_5" class="t s1_5">
          олгох){" "}
        </span>
        <span id="t1a_5" class="t s2_5">
          •{" "}
        </span>
        <span id="t1b_5" class="t s1_5">
          Зайлшгүй шаардлагатай болон сонгон суралцах нэмэлт хөтөлбөр (курсын
          ажил){" "}
        </span>
        <span id="t1c_5" class="t s2_5">
          •{" "}
        </span>
        <span id="t1d_5" class="t s1_5">
          Мэдлэгийг нэгтгэх/интеграцилах (нэгдмэл шинж чанартай, олон талын
          мэдлэг,{" "}
        </span>
        <span id="t1e_5" class="t s1_5">
          туршлагыг нэгтгэх чиглэсэн үйл ажиллагаа){" "}
        </span>
        <span id="t1f_5" class="t s2_5">
          •{" "}
        </span>
        <span id="t1g_5" class="t s1_5">
          Сурагчдын оролцох боломж олгох, нээлттэй байх, оролцооны байгууллага,
          зөвлөл,{" "}
        </span>
        <span id="t1h_5" class="t s1_5">
          клуб, сайн дурын бүлэг, зохион байгуулалттай үйл ажиллагаа гэсэн
          агуулгын{" "}
        </span>
        <span id="t1i_5" class="t s1_5">
          хүрээтэй байна.{" "}
        </span>
        <span id="t1j_5" class="t s1_5">
          Манай улсын боловсролын салбарт ХГҮА хэмээх ойлголтыг албан ба албан
          бус байдлаар{" "}
        </span>
        <span id="t1k_5" class="t s1_5">
          өргөн хэрэглэж ирсэн ба бага, дунд боловсролын тухай хуульд ХГҮА
          гэдэгтэй адилтгах{" "}
        </span>
        <span id="t1l_5" class="t s1_5">
          ойлголтыг “Хичээлээс гадуурх сургалтын ажил” гэж тодорхойлж,
          “Хичээлээс гадуурх{" "}
        </span>
        <span id="t1m_5" class="t s1_5">
          сургалтын ажил нь суралцагчийн танхимд эзэмшсэн мэдлэг, чадварыг
          бататгах, баяжуулах,{" "}
        </span>
        <span id="t1n_5" class="t s1_5">
          тэдний авъяас, сонирхлыг гүнзгийрүүлэн хөгжүүлэхэд чиглэнэ” гэсэн
          байна.{" "}
        </span>
        <span id="t1o_5" class="t s1_5">
          Мөн хичээлээс гадуурх сургалтын ажлыг дугуйлан, түр сургалт, соёл,
          урлаг, спортын арга{" "}
        </span>
        <span id="t1p_5" class="t s1_5">
          хэмжээ зэрэг хэлбэрээр зохион байгуулахыг дурджээ. Боловсролын бүх
          түвшний сургалтын{" "}
        </span>
        <span id="t1q_5" class="t s1_5">
          байгууллагуудад ХГҮА-ны хөтөлбөр, төлөвлөгөө боловсруулж хэрэгжүүлж
          ирсэн байна.{" "}
        </span>
      </div>
    </div>
  );
}
