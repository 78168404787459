import React from "react";
;

export default function FourZero() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_40{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_40{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_40{left:110px;bottom:1157px;letter-spacing:-0.23px;word-spacing:0.12px;}
#t4_40{left:110px;bottom:1139px;letter-spacing:-0.14px;}
#t5_40{left:210px;bottom:1139px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t6_40{left:153px;bottom:1114px;}
#t7_40{left:180px;bottom:1114px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_40{left:153px;bottom:1096px;}
#t9_40{left:180px;bottom:1096px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ta_40{left:153px;bottom:1078px;}
#tb_40{left:180px;bottom:1078px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tc_40{left:153px;bottom:1060px;}
#td_40{left:180px;bottom:1060px;letter-spacing:-0.15px;word-spacing:0.05px;}
#te_40{left:153px;bottom:1042px;}
#tf_40{left:180px;bottom:1042px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tg_40{left:153px;bottom:1024px;}
#th_40{left:180px;bottom:1024px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_40{left:153px;bottom:1006px;}
#tj_40{left:180px;bottom:1006px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tk_40{left:153px;bottom:987px;}
#tl_40{left:180px;bottom:987px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tm_40{left:153px;bottom:969px;}
#tn_40{left:180px;bottom:969px;letter-spacing:-0.18px;word-spacing:3.96px;}
#to_40{left:180px;bottom:951px;letter-spacing:-0.17px;}
#tp_40{left:153px;bottom:933px;}
#tq_40{left:180px;bottom:933px;letter-spacing:-0.26px;word-spacing:0.17px;}
#tr_40{left:153px;bottom:915px;}
#ts_40{left:180px;bottom:915px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tt_40{left:153px;bottom:897px;}
#tu_40{left:180px;bottom:897px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_40{left:153px;bottom:879px;}
#tw_40{left:180px;bottom:879px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tx_40{left:153px;bottom:860px;}
#ty_40{left:180px;bottom:860px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tz_40{left:153px;bottom:842px;}
#t10_40{left:180px;bottom:842px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t11_40{left:110px;bottom:800px;letter-spacing:-0.45px;}
#t12_40{left:144px;bottom:800px;}
#t13_40{left:153px;bottom:800px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t14_40{left:110px;bottom:655px;letter-spacing:-0.1px;}
#t15_40{left:227px;bottom:655px;letter-spacing:-0.11px;word-spacing:-0.03px;}
#t16_40{left:110px;bottom:637px;letter-spacing:-0.14px;word-spacing:5.61px;}
#t17_40{left:110px;bottom:619px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t18_40{left:110px;bottom:582px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t19_40{left:153px;bottom:564px;}
#t1a_40{left:168px;bottom:564px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1b_40{left:153px;bottom:546px;}
#t1c_40{left:168px;bottom:546px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1d_40{left:153px;bottom:528px;letter-spacing:-0.16px;word-spacing:4.15px;}
#t1e_40{left:153px;bottom:510px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1f_40{left:153px;bottom:486px;}
#t1g_40{left:180px;bottom:486px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1h_40{left:153px;bottom:467px;}
#t1i_40{left:180px;bottom:467px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1j_40{left:153px;bottom:449px;}
#t1k_40{left:180px;bottom:449px;letter-spacing:-0.11px;word-spacing:-0.66px;}
#t1l_40{left:180px;bottom:431px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1m_40{left:153px;bottom:413px;}
#t1n_40{left:180px;bottom:413px;letter-spacing:-0.15px;word-spacing:4.63px;}
#t1o_40{left:180px;bottom:395px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1p_40{left:153px;bottom:377px;}
#t1q_40{left:153px;bottom:359px;}
#t1r_40{left:180px;bottom:359px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1s_40{left:153px;bottom:340px;}
#t1t_40{left:180px;bottom:340px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1u_40{left:153px;bottom:322px;}
#t1v_40{left:180px;bottom:322px;letter-spacing:-0.13px;word-spacing:-1.26px;}
#t1w_40{left:180px;bottom:304px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1x_40{left:153px;bottom:286px;}
#t1y_40{left:180px;bottom:286px;letter-spacing:-0.14px;word-spacing:1.39px;}
#t1z_40{left:180px;bottom:268px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t20_40{left:110px;bottom:244px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t21_40{left:110px;bottom:226px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t22_40{left:153px;bottom:201px;}
#t23_40{left:180px;bottom:201px;letter-spacing:-0.2px;word-spacing:2.58px;}
#t24_40{left:180px;bottom:183px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t25_40{left:153px;bottom:165px;}
#t26_40{left:180px;bottom:165px;letter-spacing:-0.12px;word-spacing:4.84px;}
#t27_40{left:180px;bottom:147px;letter-spacing:-0.13px;word-spacing:-2.3px;}
#t28_40{left:153px;bottom:129px;}
#t29_40{left:180px;bottom:129px;letter-spacing:-0.13px;word-spacing:1.71px;}
#t2a_40{left:180px;bottom:111px;letter-spacing:-0.11px;word-spacing:1.06px;}
#t2b_40{left:180px;bottom:92px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t2c_40{left:142px;bottom:756px;letter-spacing:-0.14px;word-spacing:5.45px;}
#t2d_40{left:142px;bottom:738px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t2e_40{left:142px;bottom:714px;letter-spacing:-0.13px;word-spacing:-0.35px;}
#t2f_40{left:142px;bottom:695px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_40{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_40{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_40{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_40{font-size:17px;font-family:Arial-BoldItalicMT_7b;color:#231F20;}
.s4_40{font-size:17px;font-family:Arial-ItalicMT_6i;color:#231F20;}`}
        </style>

        <style id="fonts40" type="text/css">
          {`@font-face {
	font-family: Arial-BoldItalicMT_7b;
	src: url("fonts/Arial-BoldItalicMT_7b.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6i;
	src: url("fonts/Arial-ItalicMT_6i.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg40" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="40/40.svg"
          type="image/svg+xml"
          id="pdf40"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_40" class="t s0_40">
          38{" "}
        </span>
        <span id="t2_40" class="t s1_40">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_40" class="t s2_40">
          Нэмэлт мэдээлэл{" "}
        </span>
        <span id="t4_40" class="t s1_40">
          ЮНЕСКО-д{" "}
        </span>
        <span id="t5_40" class="t s1_40">
          Монголын дараах өвүүд бүртгэгдсэн байна.{" "}
        </span>
        <span id="t6_40" class="t s1_40">
          →{" "}
        </span>
        <span id="t7_40" class="t s1_40">
          2008 он Морин хуур хөгжмийн уламжлалт урлаг{" "}
        </span>
        <span id="t8_40" class="t s1_40">
          →{" "}
        </span>
        <span id="t9_40" class="t s1_40">
          2008 он Монгол ардын уртын дуу{" "}
        </span>
        <span id="ta_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tb_40" class="t s1_40">
          2009 он Монгол тууль{" "}
        </span>
        <span id="tc_40" class="t s1_40">
          →{" "}
        </span>
        <span id="td_40" class="t s1_40">
          2009 он Монгол цуур хөгжмийн уламжлалт урлаг{" "}
        </span>
        <span id="te_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tf_40" class="t s1_40">
          2009 он Монгол бий биелгээ, уламжлалт бүжгийн урлаг{" "}
        </span>
        <span id="tg_40" class="t s1_40">
          →{" "}
        </span>
        <span id="th_40" class="t s1_40">
          2010 он Монгол наадам{" "}
        </span>
        <span id="ti_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tj_40" class="t s1_40">
          2010 он Монгол ардын Хөөмэйн урлаг{" "}
        </span>
        <span id="tk_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tl_40" class="t s1_40">
          2010 он Шувуучлахуй буюу бүргэдээр ан хийх зан үйл{" "}
        </span>
        <span id="tm_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tn_40" class="t s1_40">
          2011 он Монгол Лимбэчдийн уртын дуу тоглох уламжлалт арга барил –битүү{" "}
        </span>
        <span id="to_40" class="t s1_40">
          амьсгаа{" "}
        </span>
        <span id="tp_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tq_40" class="t s1_40">
          2011 он Алтан товч{" "}
        </span>
        <span id="tr_40" class="t s1_40">
          →{" "}
        </span>
        <span id="ts_40" class="t s1_40">
          2011 он Монгол Данжуур{" "}
        </span>
        <span id="tt_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tu_40" class="t s1_40">
          2013 он Монгол гэрийн уламжлалт урлал, зан үйл{" "}
        </span>
        <span id="tv_40" class="t s1_40">
          →{" "}
        </span>
        <span id="tw_40" class="t s1_40">
          2013 он Монгол уран бичлэг{" "}
        </span>
        <span id="tx_40" class="t s1_40">
          →{" "}
        </span>
        <span id="ty_40" class="t s1_40">
          2014 он Шагайн харваа{" "}
        </span>
        <span id="tz_40" class="t s1_40">
          →{" "}
        </span>
        <span id="t10_40" class="t s1_40">
          2015 он Ботго авахуулах зан үйл{" "}
        </span>
        <span id="t11_40" class="t s3_40">
          ТАВ
        </span>
        <span id="t12_40" class="t s1_40">
          .{" "}
        </span>
        <span id="t13_40" class="t s2_40">
          МЭДЭЭЛЛИЙН ТЕХНОЛОГИЙН ЁС ЗҮЙТЭЙ ХЭРЭГЛЭЭГ ДЭМЖИХ{" "}
        </span>
        <span id="t14_40" class="t s2_40">
          5.1.1.Зорилго:{" "}
        </span>
        <span id="t15_40" class="t s1_40">
          Мэдээллийн орчны нөлөө хүүхдийн хүмүүжил төлөвшилд хэрхэн нөлөөлөх,{" "}
        </span>
        <span id="t16_40" class="t s1_40">
          гэр бүл болон сургуулийн орчинд цахим хэрэгслийг зохистой ашиглах
          дүрэм журам{" "}
        </span>
        <span id="t17_40" class="t s1_40">
          баримтлах, зөв хэрэглэх, эерэг нөлөөг нэмэгдүүлэх хандлагыг
          төлөвшүүлэхэд оршино.{" "}
        </span>
        <span id="t18_40" class="t s2_40">
          5.1.2.Үндсэн ойлголт{" "}
        </span>
        <span id="t19_40" class="t s4_40">
          -{" "}
        </span>
        <span id="t1a_40" class="t s1_40">
          Цахим мэдээллийн орчны тухай ойлголт{" "}
        </span>
        <span id="t1b_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1c_40" class="t s1_40">
          Цахим орчин дахь сурч хөгжих боломжууд{" "}
        </span>
        <span id="t1d_40" class="t s1_40">
          - Цахим мэдээллийн хэрэгсэл ашиглах гэр бүлийн дэг, дүрэм баримтлах
          талаар{" "}
        </span>
        <span id="t1e_40" class="t s1_40">
          ярилцах, өөрсдөө зохиох{" "}
        </span>
        <span id="t1f_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1g_40" class="t s1_40">
          Цахим орчин дахь эрсдэлүүдийг таньж мэдэх{" "}
        </span>
        <span id="t1h_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1i_40" class="t s1_40">
          Цахим эрсдэлээс өөрийгөө хамгаалах чадварт суралцах{" "}
        </span>
        <span id="t1j_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1k_40" class="t s1_40">
          Цахим хэрэгслийг зохисгүй хэрэглэснээс үүдэлтэй хэн нэгний эрх
          хохирсон загвар{" "}
        </span>
        <span id="t1l_40" class="t s1_40">
          баримт, асуудлыг өөрсдөө дүгнэж ярилцах, тайлбарлах{" "}
        </span>
        <span id="t1m_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1n_40" class="t s1_40">
          Сургуулийн орчинд цахим хэрэгсэл ашиглах, анги хамт олны цахим группын{" "}
        </span>
        <span id="t1o_40" class="t s1_40">
          дүрмийг харилцан ярилцаж зохиох{" "}
        </span>
        <span id="t1p_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1q_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1r_40" class="t s1_40">
          Уламжлалт соёл, ёс зүй, гэр бүлийн үнэт зүйлийг мэдээллийн орчинд
          сурталчлах{" "}
        </span>
        <span id="t1s_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1t_40" class="t s1_40">
          Видео аудио хичээлээр авьяас сонирхлын дагуу өөрийгөө хөгжүүлэх,{" "}
        </span>
        <span id="t1u_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1v_40" class="t s1_40">
          Өөрийн цахим хуудсаараа сэтгэлийн хөдөлгөөнөө илэрхийлэх, гомдсон,
          уурласан,{" "}
        </span>
        <span id="t1w_40" class="t s1_40">
          сэтгэлээр унасан үеийн зан байдлаа илэрхийлэх талаар хамтран хэлэлцэх{" "}
        </span>
        <span id="t1x_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t1y_40" class="t s1_40">
          Бусдын цахим хуудсанд шүүмжлэлттэй хандаж өөрийн санал бодлыг
          зохимжгүй{" "}
        </span>
        <span id="t1z_40" class="t s1_40">
          үг хэллэгээр илэрхийлэх талаар харилцан ярилцах{" "}
        </span>
        <span id="t20_40" class="t s1_40">
          5.1.3. Үйл ажиллагаа{" "}
        </span>
        <span id="t21_40" class="t s2_40">
          а.Бага боловсролд{" "}
        </span>
        <span id="t22_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t23_40" class="t s1_40">
          Цахим мэдээллийн орчноос дуу, хөгжим, шүлэг, үлгэр, домог, хүүхэлдэйн
          кино,{" "}
        </span>
        <span id="t24_40" class="t s1_40">
          урлан бүтээх, гадаад хэл зэргийн видео, аудио хичээлийг дагалдан
          суралцах{" "}
        </span>
        <span id="t25_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t26_40" class="t s1_40">
          Хүүхдийн үзсэн үлгэр, хүүхэлдэйн киноны талаар санаа бодлыг нь
          яриулах,{" "}
        </span>
        <span id="t27_40" class="t s1_40">
          харьцуулах дүгнүүлэх, асуултад хариулах байдлаар сэтгэхүйн үйлүүдийг
          хөгжүүлэх{" "}
        </span>
        <span id="t28_40" class="t s1_40">
          -{" "}
        </span>
        <span id="t29_40" class="t s1_40">
          Цахим зөв зохистой хэрэглээний дадал хэвшилд сургах, дэлгэцийн
          хамаарлаас{" "}
        </span>
        <span id="t2a_40" class="t s1_40">
          урьдчилан сэргийлэх, эцэг эх гэр бүл нь цахим хэрэглээндээ зөв
          зохистой үлгэр{" "}
        </span>
        <span id="t2b_40" class="t s1_40">
          дууриал үзүүлэх{" "}
        </span>
        <span id="t2c_40" class="t s1_40">
          5.1 Уламжлалт соёл, ёс зүй, гэр бүлийн үнэт зүйлийг мэдээллийн орчинд{" "}
        </span>
        <span id="t2d_40" class="t s1_40">
          сурталчлахад дэмжтлэг үзүүлэх{" "}
        </span>
        <span id="t2e_40" class="t s1_40">
          5.2 Интернетийн орчинд хүүхдийн эрүүл мэнд, өсөлт хөгжилтөд хортой
          нөлөөлж{" "}
        </span>
        <span id="t2f_40" class="t s1_40">
          болохуйц мэдээллээс хамгаалах төрөл бүрийн арга, орчин нөхцөл
          бүрдүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
