import React from "react";
;

export default function FiveFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_55{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_55{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_55{left:110px;bottom:1140px;letter-spacing:-0.12px;word-spacing:0.01px;}
#t4_55{left:110px;bottom:1121px;letter-spacing:-0.15px;word-spacing:-0.75px;}
#t5_55{left:110px;bottom:1103px;letter-spacing:-0.15px;word-spacing:-0.42px;}
#t6_55{left:704px;bottom:1103px;letter-spacing:-0.15px;word-spacing:-0.43px;}
#t7_55{left:110px;bottom:1085px;letter-spacing:-0.12px;word-spacing:-0.57px;}
#t8_55{left:401px;bottom:1085px;letter-spacing:-0.14px;word-spacing:-0.56px;}
#t9_55{left:110px;bottom:1067px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ta_55{left:110px;bottom:1049px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tb_55{left:110px;bottom:1031px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tc_55{left:542px;bottom:1031px;letter-spacing:-0.11px;}
#td_55{left:604px;bottom:1031px;letter-spacing:-0.24px;word-spacing:0.15px;}
#te_55{left:110px;bottom:1013px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tf_55{left:110px;bottom:995px;letter-spacing:-0.16px;word-spacing:4.39px;}
#tg_55{left:406px;bottom:995px;letter-spacing:-0.23px;}
#th_55{left:474px;bottom:995px;letter-spacing:-0.21px;word-spacing:4.42px;}
#ti_55{left:110px;bottom:976px;letter-spacing:-0.14px;word-spacing:4px;}
#tj_55{left:537px;bottom:976px;letter-spacing:-0.14px;word-spacing:4px;}
#tk_55{left:110px;bottom:958px;letter-spacing:-0.17px;word-spacing:0.06px;}
#tl_55{left:382px;bottom:958px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tm_55{left:115px;bottom:940px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tn_55{left:153px;bottom:922px;}
#to_55{left:180px;bottom:922px;letter-spacing:-0.16px;word-spacing:0.88px;}
#tp_55{left:180px;bottom:904px;letter-spacing:-0.1px;}
#tq_55{left:153px;bottom:886px;}
#tr_55{left:180px;bottom:886px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ts_55{left:503px;bottom:886px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tt_55{left:153px;bottom:868px;}
#tu_55{left:180px;bottom:868px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tv_55{left:653px;bottom:868px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tw_55{left:153px;bottom:849px;}
#tx_55{left:180px;bottom:849px;letter-spacing:-0.16px;word-spacing:0.07px;}
#ty_55{left:110px;bottom:831px;letter-spacing:-0.13px;word-spacing:-0.89px;}
#tz_55{left:110px;bottom:813px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t10_55{left:110px;bottom:777px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t11_55{left:110px;bottom:759px;letter-spacing:-0.16px;word-spacing:6.48px;}
#t12_55{left:110px;bottom:741px;letter-spacing:-0.15px;word-spacing:3.29px;}
#t13_55{left:110px;bottom:722px;letter-spacing:-0.16px;word-spacing:-0.21px;}
#t14_55{left:110px;bottom:704px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t15_55{left:110px;bottom:686px;letter-spacing:-0.18px;word-spacing:0.07px;}
#t16_55{left:110px;bottom:668px;letter-spacing:-0.16px;word-spacing:0.4px;}
#t17_55{left:110px;bottom:650px;letter-spacing:-0.16px;word-spacing:2.78px;}
#t18_55{left:110px;bottom:632px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t19_55{left:110px;bottom:614px;letter-spacing:-0.21px;word-spacing:0.1px;}
#t1a_55{left:110px;bottom:596px;letter-spacing:-0.13px;word-spacing:-0.84px;}
#t1b_55{left:110px;bottom:577px;letter-spacing:-0.14px;}
#t1c_55{left:176px;bottom:577px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1d_55{left:110px;bottom:559px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t1e_55{left:110px;bottom:541px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1f_55{left:145px;bottom:523px;letter-spacing:-0.05px;}
#t1g_55{left:173px;bottom:523px;letter-spacing:-0.16px;word-spacing:1.15px;}
#t1h_55{left:173px;bottom:505px;letter-spacing:-0.21px;}
#t1i_55{left:145px;bottom:487px;letter-spacing:-0.16px;word-spacing:4.72px;}
#t1j_55{left:173px;bottom:469px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1k_55{left:145px;bottom:450px;letter-spacing:-0.05px;}
#t1l_55{left:173px;bottom:450px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1m_55{left:145px;bottom:432px;letter-spacing:-0.05px;}
#t1n_55{left:173px;bottom:432px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1o_55{left:145px;bottom:414px;letter-spacing:-0.2px;word-spacing:8.91px;}
#t1p_55{left:264px;bottom:414px;letter-spacing:-0.18px;}
#t1q_55{left:360px;bottom:414px;letter-spacing:-0.12px;}
#t1r_55{left:449px;bottom:414px;letter-spacing:-0.16px;}
#t1s_55{left:518px;bottom:414px;letter-spacing:-0.17px;}
#t1t_55{left:621px;bottom:414px;letter-spacing:-0.23px;}
#t1u_55{left:720px;bottom:414px;letter-spacing:-0.2px;}
#t1v_55{left:173px;bottom:396px;letter-spacing:-0.19px;}
#t1w_55{left:145px;bottom:378px;letter-spacing:-0.05px;}
#t1x_55{left:173px;bottom:378px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1y_55{left:145px;bottom:360px;letter-spacing:-0.14px;word-spacing:2.97px;}
#t1z_55{left:110px;bottom:342px;letter-spacing:-0.1px;}
#t20_55{left:139px;bottom:323px;letter-spacing:-0.13px;word-spacing:6.88px;}
#t21_55{left:556px;bottom:323px;letter-spacing:-0.18px;word-spacing:6.28px;}
#t22_55{left:167px;bottom:305px;letter-spacing:-0.18px;word-spacing:2.71px;}
#t23_55{left:167px;bottom:287px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t24_55{left:139px;bottom:269px;letter-spacing:-0.12px;word-spacing:8.8px;}
#t25_55{left:332px;bottom:269px;letter-spacing:-0.1px;word-spacing:8.76px;}
#t26_55{left:167px;bottom:251px;letter-spacing:-0.15px;word-spacing:0.45px;}
#t27_55{left:167px;bottom:233px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t28_55{left:139px;bottom:215px;letter-spacing:-0.13px;word-spacing:3px;}
#t29_55{left:300px;bottom:215px;letter-spacing:-0.17px;word-spacing:0.13px;}
#t2a_55{left:167px;bottom:197px;letter-spacing:-0.15px;word-spacing:0.06px;}

.s0_55{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_55{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_55{font-size:17px;font-family:Arial-BoldItalicMT_7b;color:#231F20;}
.s3_55{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_55{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s5_55{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts55" type="text/css">
          {`@font-face {
	font-family: Arial-BoldItalicMT_7b;
	src: url("fonts/Arial-BoldItalicMT_7b.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg55" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="55/55.svg"
          type="image/svg+xml"
          id="pdf55"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_55" class="t s0_55">
          53{" "}
        </span>
        <span id="t2_55" class="t s1_55">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_55" class="t s2_55">
          Сэтгэл ханамжийн судалгаа хийх үед{" "}
        </span>
        <span id="t4_55" class="t s1_55">
          Сэтгэл ханамжийн судалгааг үйл ажиллагаа зохион байгуулсны дараа сэдэв
          тус бүрээр авч{" "}
        </span>
        <span id="t5_55" class="t s1_55">
          болох ба нэгдсэн байдлаар ч авч болох юм. Хэрэв та сэдэв тус бүрээр
          авах{" "}
        </span>
        <span id="t6_55" class="t s1_55">
          юм бол түүний{" "}
        </span>
        <span id="t7_55" class="t s1_55">
          хариулт зөвхөн тэр сэдвийн хүрээнд{" "}
        </span>
        <span id="t8_55" class="t s1_55">
          байх ба сэдэв тус бүрээр авч нэгтгэж үзэж боломжтой{" "}
        </span>
        <span id="t9_55" class="t s1_55">
          мөн өөрт хэрэгтэй бусад дэлгэрэнгүй мэдээллийг магадгүй авч болох юм.{" "}
        </span>
        <span id="ta_55" class="t s2_55">
          Сэтгэл ханамжийн судалгаагаар ямар зүйлийг заавал асуух ёстой вэ?{" "}
        </span>
        <span id="tb_55" class="t s1_55">
          Суралцагч, эцэг эхийн сэтгэл ханамжийн судалгаагаар{" "}
        </span>
        <span id="tc_55" class="t s2_55">
          заавал{" "}
        </span>
        <span id="td_55" class="t s1_55">
          асуух ёстой асуулт.{" "}
        </span>
        <span id="te_55" class="t s2_55">
          Та энэ үйл ажиллагаанд (сургалтад) дахин хамрагдах уу?{" "}
        </span>
        <span id="tf_55" class="t s1_55">
          Судалгаанд хамрагдсан субьектууд{" "}
        </span>
        <span id="tg_55" class="t s3_55">
          “Тийм”{" "}
        </span>
        <span id="th_55" class="t s1_55">
          гэж хариулах нь хамаагүй хялбар байдаг.{" "}
        </span>
        <span id="ti_55" class="t s1_55">
          Үүнийг тодруулахын тулд бидний үйл ажиллагаанд{" "}
        </span>
        <span id="tj_55" class="t s4_55">
          аль сэдвийг өөрчлөх, аль сэдвийг{" "}
        </span>
        <span id="tk_55" class="t s4_55">
          үргэлжлүүлэн тодруулах талаар{" "}
        </span>
        <span id="tl_55" class="t s1_55">
          бусад асуултуудыг бэлтгэж асуух хэрэгтэй.{" "}
        </span>
        <span id="tm_55" class="t s4_55">
          Бүх талаас суралцагч, эцэг эхийн, багшийн сэтгэл ханамжийн асуултыг
          асуух жишээ:{" "}
        </span>
        <span id="tn_55" class="t s5_55">
          •{" "}
        </span>
        <span id="to_55" class="t s1_55">
          Та энэ үйл ажиллагаанд (сургалтад) хамрагдсанаар хэр сэтгэл хангалуун
          байдаг{" "}
        </span>
        <span id="tp_55" class="t s1_55">
          вэ?{" "}
        </span>
        <span id="tq_55" class="t s5_55">
          •{" "}
        </span>
        <span id="tr_55" class="t s1_55">
          Та бидний үйл ажиллагаанд (сургалтад){" "}
        </span>
        <span id="ts_55" class="t s1_55">
          дахин хамрагдах боломжтой юу?{" "}
        </span>
        <span id="tt_55" class="t s5_55">
          •{" "}
        </span>
        <span id="tu_55" class="t s1_55">
          Та хүүхдүүдээ ийм чиглэлийн сургалтад (үйл ажиллагаанд){" "}
        </span>
        <span id="tv_55" class="t s1_55">
          дахин хамруулах уу?{" "}
        </span>
        <span id="tw_55" class="t s5_55">
          •{" "}
        </span>
        <span id="tx_55" class="t s1_55">
          Та манай үйл ажиллагааг (сургалт) бусдад санал болгох уу?{" "}
        </span>
        <span id="ty_55" class="t s1_55">
          Үйл ажиллагааны (сургалт) талаар суралцагч, эцэг эхэд таалагдсан эсвэл
          хүсээгүй зүйлийн{" "}
        </span>
        <span id="tz_55" class="t s1_55">
          талаар асуухыг үгүйсгэх хэрэггүй.{" "}
        </span>
        <span id="t10_55" class="t s2_55">
          Сэтгэл ханамжийн судалгаа хийхийн тулд Та юу хийх ёстой вэ?{" "}
        </span>
        <span id="t11_55" class="t s1_55">
          Сэтгэл ханамжийн судалгаа хийдэг давтамж нь таны сургалтын (үйл
          ажиллагааны){" "}
        </span>
        <span id="t12_55" class="t s1_55">
          давтамжаас хамаарна. Хамгийн гол нь судалгаанд өгсөн хариултыг үнэлж,
          саналыг нь{" "}
        </span>
        <span id="t13_55" class="t s1_55">
          хэрэгжүүлж байгаа гэдгээ эцэг, эх сурагчдад мэдэгдэх хэрэгтэй.
          Магадгүй сурагчид, эцэг эх{" "}
        </span>
        <span id="t14_55" class="t s1_55">
          саналаа хувиараа өгсөн байж болно тэр саналыг ч гэсэн оруулж тусгаж
          авна.{" "}
        </span>
        <span id="t15_55" class="t s2_55">
          Судалгааны үр дүнг хэрхэн тооцох вэ?{" "}
        </span>
        <span id="t16_55" class="t s1_55">
          Үйл ажиллагаанд хамрагдаж байгаа өөр өөр субектуудээс сэтгэл ханамжийн
          судалгаа авч{" "}
        </span>
        <span id="t17_55" class="t s1_55">
          сургалт (үйл ажиллагаа) -ын талаарх мэдээлэл мэдээллийн баазтай болно.
          Эцэг эхийн{" "}
        </span>
        <span id="t18_55" class="t s1_55">
          судалгааг сургалтын эхэнд болон төгсөлд авч болно.{" "}
        </span>
        <span id="t19_55" class="t s2_55">
          Үр дүнг тооцох аргачлал{" "}
        </span>
        <span id="t1a_55" class="t s1_55">
          Эцэг эхийн нас, боловсролын түвшин, ажил мэргэжил, өрхийн сарын
          орлого, айлын хэд дэх{" "}
        </span>
        <span id="t1b_55" class="t s1_55">
          хүүхэд,{" "}
        </span>
        <span id="t1c_55" class="t s1_55">
          зэрэг ерөнхий мэдээллийг авч болно.{" "}
        </span>
        <span id="t1d_55" class="t s2_55">
          Үр дүнд боловсруулалт, шинжилгээ хийх{" "}
        </span>
        <span id="t1e_55" class="t s1_55">
          Боловсруулалт хийхэд дараах алхмуудыг анхаарч үзэх шаардлагатай.{" "}
        </span>
        <span id="t1f_55" class="t s1_55">
          1.{" "}
        </span>
        <span id="t1g_55" class="t s1_55">
          Өгөгдлийг цэвэрлэх: Хоосон, шаардлага хангаагүй, хэт худлаа, хүчингүй
          утгуудыг{" "}
        </span>
        <span id="t1h_55" class="t s1_55">
          цэвэрлэх{" "}
        </span>
        <span id="t1i_55" class="t s1_55">
          2. Хэрвээ 2 буюу түүнээс дээш судалгаа нэгтгэх шаардлагатай бол хялбар
          арга{" "}
        </span>
        <span id="t1j_55" class="t s1_55">
          замуудыг хэрэглэх{" "}
        </span>
        <span id="t1k_55" class="t s1_55">
          3.{" "}
        </span>
        <span id="t1l_55" class="t s1_55">
          Судалгаанд цугласан өгөгдлүүдийн төлөөлөх чадвартай эсэхийг хянах{" "}
        </span>
        <span id="t1m_55" class="t s1_55">
          4.{" "}
        </span>
        <span id="t1n_55" class="t s1_55">
          Түүврийн бус алдаа гарахаас сэргийлэх{" "}
        </span>
        <span id="t1o_55" class="t s1_55">
          5. Судалгаа{" "}
        </span>
        <span id="t1p_55" class="t s1_55">
          цуглуулах{" "}
        </span>
        <span id="t1q_55" class="t s1_55">
          процесст{" "}
        </span>
        <span id="t1r_55" class="t s1_55">
          гарсан{" "}
        </span>
        <span id="t1s_55" class="t s1_55">
          өгөгдөлтэй{" "}
        </span>
        <span id="t1t_55" class="t s1_55">
          холбоотой{" "}
        </span>
        <span id="t1u_55" class="t s1_55">
          асуудлуудыг{" "}
        </span>
        <span id="t1v_55" class="t s1_55">
          шийдвэрлэх{" "}
        </span>
        <span id="t1w_55" class="t s1_55">
          6.{" "}
        </span>
        <span id="t1x_55" class="t s1_55">
          Кодлох, кодын тайлбарыг оновчтой оруулах{" "}
        </span>
        <span id="t1y_55" class="t s1_55">
          7. Түүврийн мэдээллийн нягтлах{" "}
        </span>
        <span id="t1z_55" class="t s1_55">
          Үр дүнд шинжилгээ хийх{" "}
        </span>
        <span id="t20_55" class="t s3_55">
          1. Тайлбарлах статистик (Descriptive statistic){" "}
        </span>
        <span id="t21_55" class="t s1_55">
          - Тайлбарлах (Хувьсагч бүрийн{" "}
        </span>
        <span id="t22_55" class="t s1_55">
          дундаж утга, хамгийн их, бага утга, стандарт хазайлт, давтамж,
          стандарт алдаа,{" "}
        </span>
        <span id="t23_55" class="t s1_55">
          нийт тоо, хоосон утгуудын тоо, ... зэргийг тодорхойлж шинжилгээ хийхэд
          бэлтгэх){" "}
        </span>
        <span id="t24_55" class="t s3_55">
          2. Дүгнэх статистик{" "}
        </span>
        <span id="t25_55" class="t s1_55">
          (Inferential statistic) - Дүгнэх (Хүчин зүйлийн шинжилгээ,{" "}
        </span>
        <span id="t26_55" class="t s1_55">
          найдвартай байдлын шинжилгээ, хэвийн тархалтын шинжилгээ, ялгаатай
          байдлын{" "}
        </span>
        <span id="t27_55" class="t s1_55">
          шинжилгээ, хамаарлын шинжилгээ, регрессийн шинжилгээ, ...){" "}
        </span>
        <span id="t28_55" class="t s3_55">
          3. Дүгнэлт гаргах (
        </span>
        <span id="t29_55" class="t s1_55">
          Шаардлагагүй асуулгыг устгах, сайжруулах, холбоо хамаарлуудыг{" "}
        </span>
        <span id="t2a_55" class="t s1_55">
          тогтоох, хэрхэн хамаарч байгаа эсэх, зорилгоо хангасан эсэх, ...){" "}
        </span>
      </div>
    </div>
  );
}
