import React from "react";
;

export default function Eighteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_18{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_18{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_18{left:110px;bottom:1138px;letter-spacing:-0.14px;word-spacing:2.32px;}
#t4_18{left:110px;bottom:1120px;letter-spacing:-0.1px;}
#t5_18{left:199px;bottom:1087px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t6_18{left:167px;bottom:1062px;letter-spacing:-0.14px;word-spacing:-1.85px;}
#t7_18{left:167px;bottom:1044px;letter-spacing:-0.15px;word-spacing:0.72px;}
#t8_18{left:167px;bottom:1026px;letter-spacing:-0.15px;word-spacing:4.14px;}
#t9_18{left:167px;bottom:1008px;letter-spacing:-0.12px;word-spacing:-1.07px;}
#ta_18{left:313px;bottom:1008px;letter-spacing:-0.14px;word-spacing:-1.05px;}
#tb_18{left:167px;bottom:990px;letter-spacing:-0.17px;word-spacing:0.13px;}
#tc_18{left:167px;bottom:972px;letter-spacing:-0.14px;word-spacing:-0.08px;}
#td_18{left:167px;bottom:954px;letter-spacing:-0.11px;word-spacing:3.77px;}
#te_18{left:167px;bottom:936px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_18{left:110px;bottom:897px;letter-spacing:-0.18px;word-spacing:0.07px;}
#tg_18{left:153px;bottom:873px;}
#th_18{left:180px;bottom:873px;letter-spacing:-0.13px;word-spacing:5.35px;}
#ti_18{left:180px;bottom:855px;letter-spacing:-0.15px;word-spacing:3.8px;}
#tj_18{left:180px;bottom:837px;letter-spacing:-0.13px;word-spacing:-1.05px;}
#tk_18{left:180px;bottom:819px;letter-spacing:-0.24px;word-spacing:0.15px;}
#tl_18{left:153px;bottom:794px;}
#tm_18{left:180px;bottom:794px;letter-spacing:-0.15px;word-spacing:4.49px;}
#tn_18{left:180px;bottom:776px;letter-spacing:-0.11px;word-spacing:-0.77px;}
#to_18{left:180px;bottom:758px;letter-spacing:-0.1px;word-spacing:0.01px;}
#tp_18{left:153px;bottom:734px;}
#tq_18{left:180px;bottom:734px;letter-spacing:-0.15px;word-spacing:4.58px;}
#tr_18{left:180px;bottom:716px;letter-spacing:-0.13px;word-spacing:-0.54px;}
#ts_18{left:180px;bottom:698px;letter-spacing:-0.12px;word-spacing:0.73px;}
#tt_18{left:180px;bottom:680px;letter-spacing:-0.17px;word-spacing:0.38px;}
#tu_18{left:180px;bottom:661px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tv_18{left:153px;bottom:637px;}
#tw_18{left:180px;bottom:637px;letter-spacing:-0.14px;word-spacing:3.62px;}
#tx_18{left:180px;bottom:619px;letter-spacing:-0.14px;word-spacing:3.81px;}
#ty_18{left:180px;bottom:601px;letter-spacing:-0.13px;word-spacing:1.09px;}
#tz_18{left:180px;bottom:583px;letter-spacing:-0.12px;word-spacing:3.79px;}
#t10_18{left:180px;bottom:565px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t11_18{left:153px;bottom:540px;}
#t12_18{left:180px;bottom:540px;letter-spacing:-0.14px;word-spacing:2.03px;}
#t13_18{left:180px;bottom:522px;letter-spacing:-0.16px;word-spacing:3.41px;}
#t14_18{left:180px;bottom:504px;letter-spacing:-0.15px;word-spacing:2.72px;}
#t15_18{left:180px;bottom:486px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t16_18{left:153px;bottom:462px;}
#t17_18{left:180px;bottom:462px;letter-spacing:-0.16px;word-spacing:3.49px;}
#t18_18{left:180px;bottom:444px;letter-spacing:-0.16px;word-spacing:1.28px;}
#t19_18{left:180px;bottom:425px;letter-spacing:-0.14px;word-spacing:0.44px;}
#t1a_18{left:180px;bottom:407px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1b_18{left:127px;bottom:383px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t1c_18{left:153px;bottom:359px;}
#t1d_18{left:180px;bottom:359px;letter-spacing:-0.1px;word-spacing:-2.19px;}
#t1e_18{left:180px;bottom:341px;letter-spacing:-0.23px;word-spacing:0.13px;}
#t1f_18{left:153px;bottom:316px;}
#t1g_18{left:180px;bottom:316px;letter-spacing:-0.13px;word-spacing:-0.18px;}
#t1h_18{left:180px;bottom:298px;letter-spacing:-0.14px;word-spacing:1.02px;}
#t1i_18{left:180px;bottom:280px;letter-spacing:-0.12px;word-spacing:1.34px;}
#t1j_18{left:180px;bottom:262px;letter-spacing:-0.17px;word-spacing:-0.64px;}
#t1k_18{left:180px;bottom:244px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1l_18{left:153px;bottom:220px;}
#t1m_18{left:180px;bottom:220px;letter-spacing:-0.14px;word-spacing:4.17px;}
#t1n_18{left:180px;bottom:202px;letter-spacing:-0.14px;word-spacing:4.46px;}
#t1o_18{left:180px;bottom:183px;letter-spacing:-0.13px;word-spacing:5.77px;}
#t1p_18{left:180px;bottom:165px;letter-spacing:-0.18px;word-spacing:2.52px;}
#t1q_18{left:180px;bottom:147px;letter-spacing:-0.09px;}
#t1r_18{left:153px;bottom:123px;}
#t1s_18{left:180px;bottom:123px;letter-spacing:-0.16px;word-spacing:0.96px;}
#t1t_18{left:180px;bottom:105px;letter-spacing:-0.22px;word-spacing:5.63px;}
#t1u_18{left:180px;bottom:87px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_18{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_18{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_18{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_18{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_18{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>
        <style id="fonts18" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg2" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="18/18.svg"
          type="image/svg+xml"
          id="pdf18"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_18" class="t s0_18">
          16{" "}
        </span>
        <span id="t2_18" class="t s1_18">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_18" class="t s2_18">
          Бүрэн дунд боловсролын түвшинд сурагчдын танин мэдэхүй, нийгэмшил бие
          бялдрын{" "}
        </span>
        <span id="t4_18" class="t s2_18">
          хөгжлийн онцлог:{" "}
        </span>
        <span id="t5_18" class="t s3_18">
          15-17 настай хүүхдүүдийн хэл яриа, сэтгэхүйн хөгжлийн онцлог{" "}
        </span>
        <span id="t6_18" class="t s1_18">
          Хэл яриа, сэтгэхүйн логик чадварын хувьд, үзэгдэл юмсыг дотоод мөн
          чанарыг{" "}
        </span>
        <span id="t7_18" class="t s1_18">
          таньж нэгтгэн дүгнэх чадвартай хэдий ч гадаад шинж байдлыг нь
          голчилдог{" "}
        </span>
        <span id="t8_18" class="t s1_18">
          хандлага давамгай байна. Хийсвэр сэтгэхүй нь нас нэмэгдэхэд аажмаар{" "}
        </span>
        <span id="t9_18" class="t s1_18">
          сайжирсан байна.{" "}
        </span>
        <span id="ta_18" class="t s1_18">
          Бодит, ахуйн шинжтэй үгийн утгыг илүү мэддэг салаа/ижил{" "}
        </span>
        <span id="tb_18" class="t s1_18">
          утгатай үг, тэмдэг нэрийн үгийн утгыг таньж тайлбарлах, бүлэглэх нь
          дундаж{" "}
        </span>
        <span id="tc_18" class="t s1_18">
          түвшинд, өмнөх 11-14 настай харьцуулахад үгийн нөөц, хэл ярианы
          сэтгэхүй{" "}
        </span>
        <span id="td_18" class="t s1_18">
          сайжирсан. Энгийн богино өгүүлбэрээр ерөнхийлөн дүгнэж, дунджаар 90{" "}
        </span>
        <span id="te_18" class="t s1_18">
          гаруй минутад ойролцоогоор 32 үгээр ярьдаг онцлогтой байна.{" "}
        </span>
        <span id="tf_18" class="t s3_18">
          Танин мэдэхүйн онцлог:{" "}
        </span>
        <span id="tg_18" class="t s4_18">
          •{" "}
        </span>
        <span id="th_18" class="t s1_18">
          Хүүхдүүдийн танин мэдэхүйн хөгжилд мэдээллийг утгаар нь тогтоох,
          богино{" "}
        </span>
        <span id="ti_18" class="t s1_18">
          хугацаанд харж тогтоох, анхаарлын төвлөрөл, сэтгэхүйн харьцуулан,
          ангилах,{" "}
        </span>
        <span id="tj_18" class="t s1_18">
          нэгтгэн дүгнэх, математик үйлдэл хийх чадвар болон үгийн утгын
          эзэмшилт голлох{" "}
        </span>
        <span id="tk_18" class="t s1_18">
          байр суурьтай байна.{" "}
        </span>
        <span id="tl_18" class="t s4_18">
          •{" "}
        </span>
        <span id="tm_18" class="t s1_18">
          Энгийн богино өгүүлбэрээр ерөнхий дүгнэж ярих нь давамгай байр
          суурьтай{" "}
        </span>
        <span id="tn_18" class="t s1_18">
          байна. Хүүхдүүдийн сэтгэхүй, хэл яриа үгийн нөөцийг дэмжих нь тухайн
          насныхны{" "}
        </span>
        <span id="to_18" class="t s1_18">
          танин мэдэхүйн хөгжилд эерэг нөлөө үзүүлнэ.{" "}
        </span>
        <span id="tp_18" class="t s4_18">
          •{" "}
        </span>
        <span id="tq_18" class="t s1_18">
          Богино хугацаанд энгийн дүрс тэмдэглэгээг хараад 10-11 хүртэлхийг
          тогтоож{" "}
        </span>
        <span id="tr_18" class="t s1_18">
          сэргээн санадаг бол цифр, нарийн мэдээлэл зэргийг 6 хүртэлхийг тогтоож
          сэргээн{" "}
        </span>
        <span id="ts_18" class="t s1_18">
          санадаг онцлогтой. Сонсоод богино хугацаанд 6-7 хүртэлх үгийг тогтоож
          сэргээн{" "}
        </span>
        <span id="tt_18" class="t s1_18">
          санадаг, хийсвэр агуулгатай, танил бус үг хэллэгтэй үйл явдал бүхий
          мэдээллээс{" "}
        </span>
        <span id="tu_18" class="t s1_18">
          16 хүртэлх утгыг тогтоодог онцлогтой байна.{" "}
        </span>
        <span id="tv_18" class="t s4_18">
          •{" "}
        </span>
        <span id="tw_18" class="t s1_18">
          5 жилийн өмнөх судалгааны үр дүнтэй харьцуулахад, хүүхдүүдийн
          анхаарлаа{" "}
        </span>
        <span id="tx_18" class="t s1_18">
          төвлөрүүлэх, үгийн утгыг таньж тайлбарлах нь сайжирсан байна. Мөн
          богино{" "}
        </span>
        <span id="ty_18" class="t s1_18">
          өгүүлбэрээр ярьдаг нь өссөн үзүүлэлттэй. Өөрөөр хэлбэл, нийлмэл
          өгүүлбэрээр{" "}
        </span>
        <span id="tz_18" class="t s1_18">
          ярих нь буурсан гэсэн үг юм. 17 насанд хүүхдүүд нийлмэл өгүүлбэрээр
          ярих{" "}
        </span>
        <span id="t10_18" class="t s1_18">
          чадвар нь нэмэгддэг байна.{" "}
        </span>
        <span id="t11_18" class="t s4_18">
          •{" "}
        </span>
        <span id="t12_18" class="t s1_18">
          Хүүхдүүд мэдээллийг дотоод мөн чанар, учир шалтгаанд нь сэтгэж
          харьцуулан{" "}
        </span>
        <span id="t13_18" class="t s1_18">
          ойлгох чадвар хангалтгүй байгаа нь шийдвэр гаргах, юмсыг ойлгохдоо
          хөнгөн{" "}
        </span>
        <span id="t14_18" class="t s1_18">
          хийсвэр, гадаад хэлбэр, шинжээр нь хандах хандлагыг бий болгодог тал
          байж{" "}
        </span>
        <span id="t15_18" class="t s1_18">
          болох тул үүнийг анхаарах нь зүйтэй байна.{" "}
        </span>
        <span id="t16_18" class="t s4_18">
          •{" "}
        </span>
        <span id="t17_18" class="t s1_18">
          Үгийн нөөц нь нас нэмэгдэхэд сайжирсан хандлагатай байна. Нас
          нэмэгдэхэд{" "}
        </span>
        <span id="t18_18" class="t s1_18">
          энэ нь хийсвэр, салаа утгатай үг, тэмдэг нэрийн ойлголт хэрэглээгээр
          нэмэгддэг{" "}
        </span>
        <span id="t19_18" class="t s1_18">
          онцлогтой байна. Анги ахих тусам юмсыг харагдаж байгаагаар нь биш илүү
          цаад{" "}
        </span>
        <span id="t1a_18" class="t s1_18">
          учир шалтгааныг дүгнэж товчхон илэрхийлэх хандлага нэмэгдэж байна.{" "}
        </span>
        <span id="t1b_18" class="t s3_18">
          Нийгэмшлийн онцлог:{" "}
        </span>
        <span id="t1c_18" class="t s4_18">
          •{" "}
        </span>
        <span id="t1d_18" class="t s1_18">
          Хүүхдүүдийн нийгэмшлийн хөгжилд үнэт зүйл, нийгмийн хэм хэмжээ,
          ээнэгшилтийн{" "}
        </span>
        <span id="t1e_18" class="t s1_18">
          чадварууд голлох байр суурьтай байна.{" "}
        </span>
        <span id="t1f_18" class="t s4_18">
          •{" "}
        </span>
        <span id="t1g_18" class="t s1_18">
          Эрэгтэй хүүхдүүдийн өөртөө итгэх итгэл, өөрийн сэтгэлийн хөдөлгөөнийг
          удирдах{" "}
        </span>
        <span id="t1h_18" class="t s1_18">
          чадвар нь эмэгтэй хүүхдүүдтэй харьцуулахад нэмэгдсэн үзүүлэлттэй
          байгаа бол{" "}
        </span>
        <span id="t1i_18" class="t s1_18">
          эмэгтэй хүүхдүүд эрэгтэй хүүхдүүдтэй харьцуулахад ёс суртахууны хэм
          хэмжээ,{" "}
        </span>
        <span id="t1j_18" class="t s1_18">
          дүрэм журам, олон нийтийн газар биеэ авч явахад илүү ач холбогдол
          өгдөг бөгөөд{" "}
        </span>
        <span id="t1k_18" class="t s1_18">
          охид стресс, түгшүүрт хөвгүүдээс илүү өртөмтгий, эмзэг байдаг байна.{" "}
        </span>
        <span id="t1l_18" class="t s4_18">
          •{" "}
        </span>
        <span id="t1m_18" class="t s1_18">
          Сэтгэлийн хөдөлгөөний чадваруудын хувьд, өсвөр насны онцлог
          ажиглагдсан{" "}
        </span>
        <span id="t1n_18" class="t s1_18">
          бөгөөд бусдын болон өөрийн сэтгэлийн хөдөлгөөний илрэлийг мэдэх,
          ойлгох{" "}
        </span>
        <span id="t1o_18" class="t s1_18">
          чадвар илүү хөгжсөн ч заримдаа сэтгэлийн хөдөлгөөнөө бусдад илэрхийлж{" "}
        </span>
        <span id="t1p_18" class="t s1_18">
          гаргахдаа нөхцөл байдалд автагдан өөрийн үг, үйлдлээ тохиромжгүй
          байдлаар{" "}
        </span>
        <span id="t1q_18" class="t s1_18">
          илэрхийлдэг онцлогтой.{" "}
        </span>
        <span id="t1r_18" class="t s4_18">
          •{" "}
        </span>
        <span id="t1s_18" class="t s1_18">
          Дасан зохицох чадваруудын хувьд, аливаа харилцаанд илүү тэвчээртэй,
          бусдын{" "}
        </span>
        <span id="t1t_18" class="t s1_18">
          ялгаатай байдлыг хүлээн зөвшөөрдөг, ойлголцохыг эрмэлздэг, шүүн
          тунгаах,{" "}
        </span>
        <span id="t1u_18" class="t s1_18">
          өөрийн хариуцлагыг ухамсарлан бие даан шийдвэр гаргах чадвартай болсон{" "}
        </span>
      </div>
    </div>
  );
}
