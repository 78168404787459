import React from "react";
;

export default function FourSix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_46{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_46{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_46{left:110px;bottom:1157px;letter-spacing:-0.16px;word-spacing:-0.26px;}
#t4_46{left:110px;bottom:1139px;letter-spacing:-0.13px;word-spacing:-0.83px;}
#t5_46{left:110px;bottom:1121px;letter-spacing:-0.15px;word-spacing:3.15px;}
#t6_46{left:110px;bottom:1102px;letter-spacing:-0.15px;word-spacing:-0.24px;}
#t7_46{left:110px;bottom:1084px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t8_46{left:110px;bottom:1048px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t9_46{left:110px;bottom:1030px;letter-spacing:-0.12px;word-spacing:1.89px;}
#ta_46{left:548px;bottom:1030px;letter-spacing:-0.19px;word-spacing:1.95px;}
#tb_46{left:110px;bottom:1012px;letter-spacing:-0.11px;}
#tc_46{left:166px;bottom:1012px;letter-spacing:-0.15px;word-spacing:0.05px;}
#td_46{left:153px;bottom:994px;}
#te_46{left:180px;bottom:994px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tf_46{left:153px;bottom:975px;}
#tg_46{left:180px;bottom:975px;letter-spacing:-0.18px;word-spacing:0.08px;}
#th_46{left:153px;bottom:957px;}
#ti_46{left:180px;bottom:957px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tj_46{left:153px;bottom:939px;}
#tk_46{left:180px;bottom:939px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tl_46{left:153px;bottom:921px;}
#tm_46{left:180px;bottom:921px;letter-spacing:-0.09px;word-spacing:-0.01px;}
#tn_46{left:153px;bottom:903px;}
#to_46{left:180px;bottom:903px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tp_46{left:153px;bottom:885px;}
#tq_46{left:180px;bottom:885px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tr_46{left:110px;bottom:867px;letter-spacing:-0.15px;word-spacing:0.19px;}
#ts_46{left:110px;bottom:848px;letter-spacing:-0.16px;word-spacing:4.31px;}
#tt_46{left:110px;bottom:830px;letter-spacing:-0.12px;word-spacing:1.07px;}
#tu_46{left:110px;bottom:812px;letter-spacing:-0.14px;word-spacing:-1.13px;}
#tv_46{left:110px;bottom:794px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tw_46{left:110px;bottom:758px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tx_46{left:110px;bottom:740px;letter-spacing:-0.14px;word-spacing:2.86px;}
#ty_46{left:110px;bottom:722px;letter-spacing:-0.14px;}
#tz_46{left:110px;bottom:703px;letter-spacing:-0.17px;word-spacing:8.27px;}
#t10_46{left:138px;bottom:685px;letter-spacing:-0.16px;word-spacing:3.3px;}
#t11_46{left:138px;bottom:667px;letter-spacing:-0.15px;word-spacing:4.87px;}
#t12_46{left:138px;bottom:649px;letter-spacing:-0.22px;word-spacing:0.13px;}
#t13_46{left:153px;bottom:631px;}
#t14_46{left:180px;bottom:631px;letter-spacing:-0.19px;word-spacing:8.94px;}
#t15_46{left:180px;bottom:613px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t16_46{left:153px;bottom:595px;}
#t17_46{left:180px;bottom:595px;letter-spacing:-0.15px;word-spacing:-0.26px;}
#t18_46{left:180px;bottom:576px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t19_46{left:153px;bottom:558px;}
#t1a_46{left:180px;bottom:558px;letter-spacing:-0.17px;word-spacing:2.28px;}
#t1b_46{left:180px;bottom:540px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1c_46{left:110px;bottom:522px;letter-spacing:-0.05px;}
#t1d_46{left:138px;bottom:522px;letter-spacing:-0.13px;word-spacing:-0.71px;}
#t1e_46{left:138px;bottom:504px;letter-spacing:-0.15px;word-spacing:0.82px;}
#t1f_46{left:138px;bottom:486px;letter-spacing:-0.14px;word-spacing:2.22px;}
#t1g_46{left:138px;bottom:468px;letter-spacing:-0.16px;}
#t1h_46{left:110px;bottom:449px;letter-spacing:-0.12px;word-spacing:2.78px;}
#t1i_46{left:138px;bottom:431px;letter-spacing:-0.17px;word-spacing:-0.38px;}
#t1j_46{left:138px;bottom:413px;letter-spacing:-0.14px;word-spacing:-1.1px;}
#t1k_46{left:138px;bottom:395px;letter-spacing:-0.14px;word-spacing:0.53px;}
#t1l_46{left:138px;bottom:377px;letter-spacing:-0.13px;word-spacing:-0.51px;}
#t1m_46{left:138px;bottom:359px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1n_46{left:110px;bottom:341px;letter-spacing:-0.19px;word-spacing:4.18px;}
#t1o_46{left:138px;bottom:323px;letter-spacing:-0.13px;word-spacing:2.9px;}
#t1p_46{left:663px;bottom:323px;letter-spacing:-0.28px;word-spacing:3.04px;}
#t1q_46{left:138px;bottom:304px;letter-spacing:-0.16px;word-spacing:2.76px;}
#t1r_46{left:138px;bottom:286px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1s_46{left:110px;bottom:268px;letter-spacing:-0.13px;word-spacing:4.49px;}
#t1t_46{left:138px;bottom:250px;letter-spacing:-0.14px;word-spacing:3.43px;}
#t1u_46{left:138px;bottom:232px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1v_46{left:110px;bottom:214px;letter-spacing:-0.17px;word-spacing:2.96px;}
#t1w_46{left:138px;bottom:196px;letter-spacing:-0.15px;word-spacing:5.56px;}
#t1x_46{left:138px;bottom:177px;letter-spacing:-0.17px;word-spacing:-1.24px;}
#t1y_46{left:138px;bottom:159px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1z_46{left:110px;bottom:141px;letter-spacing:-0.15px;word-spacing:5.25px;}
#t20_46{left:110px;bottom:123px;letter-spacing:-0.14px;word-spacing:-2px;}
#t21_46{left:110px;bottom:105px;letter-spacing:-0.12px;word-spacing:1.73px;}
#t22_46{left:110px;bottom:87px;letter-spacing:-0.15px;word-spacing:0.06px;}

.s0_46{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_46{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_46{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_46{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s4_46{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts46" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg46" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="46/46.svg"
          type="image/svg+xml"
          id="pdf46"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_46" class="t s0_46">
          44{" "}
        </span>
        <span id="t2_46" class="t s1_46">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_46" class="t s1_46">
          Төслийн танилцуулга. Төслийн танилцуулгад сургуулийн багш ажилтнууд,
          удирдлага, орон{" "}
        </span>
        <span id="t4_46" class="t s1_46">
          нутгийн хүмүүс, байгаль хамгаалагчийг урьж болно. Төслөө
          танилцуулахдаа багийн гишүүд{" "}
        </span>
        <span id="t5_46" class="t s1_46">
          бүгд оролцоно. Төсөл 1 сарын дотор бүгд хэрэгжих боломжгүй. Иймд
          асуудлыг хэрхэн{" "}
        </span>
        <span id="t6_46" class="t s1_46">
          шийдвэрлэх сайн боловсруулсан төлөвлөгөө байж болно. Зарим нь хийж
          дуусгасан ч байж{" "}
        </span>
        <span id="t7_46" class="t s1_46">
          болно. Зарим хүүхдүүд гэртээ буцаж очоод хийж дуусгах ажил байж болно.{" "}
        </span>
        <span id="t8_46" class="t s2_46">
          Асуулт хариулт- дүгнэлт{" "}
        </span>
        <span id="t9_46" class="t s1_46">
          Дараах шалгуураар үнэлэн бусдаас илүү сайн хийсэн{" "}
        </span>
        <span id="ta_46" class="t s3_46">
          “Хүрээлэн буй орчноо сайжруулах{" "}
        </span>
        <span id="tb_46" class="t s3_46">
          төсөл”
        </span>
        <span id="tc_46" class="t s1_46">
          -ийн эхний 2-3 байрыг шалгаруулна.{" "}
        </span>
        <span id="td_46" class="t s4_46">
          •{" "}
        </span>
        <span id="te_46" class="t s1_46">
          Багийн хамтын ажиллагаа{" "}
        </span>
        <span id="tf_46" class="t s4_46">
          •{" "}
        </span>
        <span id="tg_46" class="t s1_46">
          Асуудал шийдвэрлэх арга зам хэр оновчтой{" "}
        </span>
        <span id="th_46" class="t s4_46">
          •{" "}
        </span>
        <span id="ti_46" class="t s1_46">
          Баримт нотолгоо, мэдээлэл хэр цуглуулсан{" "}
        </span>
        <span id="tj_46" class="t s4_46">
          •{" "}
        </span>
        <span id="tk_46" class="t s1_46">
          Хууль дүрэм журамтай хэр нийцэж байгаа эсэх{" "}
        </span>
        <span id="tl_46" class="t s4_46">
          •{" "}
        </span>
        <span id="tm_46" class="t s1_46">
          Ханын сонингийн бүтэц{" "}
        </span>
        <span id="tn_46" class="t s4_46">
          •{" "}
        </span>
        <span id="to_46" class="t s1_46">
          Төслийн хэрэгжих боломж{" "}
        </span>
        <span id="tp_46" class="t s4_46">
          •{" "}
        </span>
        <span id="tq_46" class="t s1_46">
          Танилцуулгаа хүмүүст хүргэх байдал{" "}
        </span>
        <span id="tr_46" class="t s1_46">
          Бидний санал болгож буй энэ ажлын алхам бүрт хүүхдийн оролцоог дэмжих
          ойлголтуудыг{" "}
        </span>
        <span id="ts_46" class="t s1_46">
          суулгаж өгсөн байгаа гэдгийг санаарай. Урамшуулал нь үнэтэй биш
          энгийн, хүүхдийн{" "}
        </span>
        <span id="tt_46" class="t s1_46">
          оролцоо, идэвхийг үнэлсэн бусдыг гомдоох учиргүй. Болж өгвөл бүх
          хүүхдийг ямар нэгэн{" "}
        </span>
        <span id="tu_46" class="t s1_46">
          байдлаар урамшуулах нь зүйтэй. Энгийн сертификат хэвлэн өгөх, багийн
          хамт олны зургийг{" "}
        </span>
        <span id="tv_46" class="t s1_46">
          угаалган өгөх, ямар нэгэн амттан ч байж болно.{" "}
        </span>
        <span id="tw_46" class="t s1_46">
          Хүүхдийн оролцооны байгууллагын удирдагч багшид нарт:{" "}
        </span>
        <span id="tx_46" class="t s1_46">
          А. Аливаа үйл ажиллагааг чанар үр дүнтэй зохион байгуулахад
          хэрэглэгддэг арга зүйн{" "}
        </span>
        <span id="ty_46" class="t s1_46">
          элементүүд:{" "}
        </span>
        <span id="tz_46" class="t s1_46">
          1. Анхаарлыг төвлөрүүлэх - Аливаа үр дүнтэй сургалт, үйл ажиллагааг
          эхлэхдээ{" "}
        </span>
        <span id="t10_46" class="t s1_46">
          оролцогчдын сонирхлыг татсан эсэхээс ихээхэн шалтгаалдаг. Иймд үйл
          ажиллагаа,{" "}
        </span>
        <span id="t11_46" class="t s1_46">
          сургалт хичээлийг эхлэхийн өмнө суралцагчдын сонирхлыг татдаг олон
          аргуудаас{" "}
        </span>
        <span id="t12_46" class="t s1_46">
          суралцах хэрэгтэй болдог. Тухайлбал:{" "}
        </span>
        <span id="t13_46" class="t s4_46">
          •{" "}
        </span>
        <span id="t14_46" class="t s1_46">
          Сургалт, үйл ажиллагааны гол үзэл санааг танилцуулах ямар нэгэн үйл{" "}
        </span>
        <span id="t15_46" class="t s1_46">
          ажиллагаагаар эхлэх{" "}
        </span>
        <span id="t16_46" class="t s4_46">
          •{" "}
        </span>
        <span id="t17_46" class="t s1_46">
          Тэдний амьдралын туршлага дээр үндэслэх бөгөөд сэдэвтэй уялдах асуулт,
          ямар{" "}
        </span>
        <span id="t18_46" class="t s1_46">
          нэгэн түүхээс эхлэх{" "}
        </span>
        <span id="t19_46" class="t s4_46">
          •{" "}
        </span>
        <span id="t1a_46" class="t s1_46">
          Үйл ажиллагаа, сургалтын сэдэв буюу товч асуудлыг танилцуулах
          (жишээлбэл:{" "}
        </span>
        <span id="t1b_46" class="t s1_46">
          тухайн сэдэвтэй холбоотой нийтлэл, өгүүлэл унших) гэх мэт{" "}
        </span>
        <span id="t1c_46" class="t s1_46">
          2.{" "}
        </span>
        <span id="t1d_46" class="t s1_46">
          Үйл ажиллагаа, сургалтын үр дүнгийн талаар мэдэгдэл хийх – тэднээс юуг
          хүсэн хүлээж{" "}
        </span>
        <span id="t1e_46" class="t s1_46">
          байгааг оролцогчдод мэдэгдэнэ. Оролцогчдын амжилт нь “Энэ үйл
          ажиллагааны дүнд{" "}
        </span>
        <span id="t1f_46" class="t s1_46">
          би тэднийг юу мэдсэн байгаасай гэж хүсэж байна вэ?” гэсэн асуултын
          хариулт байх{" "}
        </span>
        <span id="t1g_46" class="t s1_46">
          болно.{" "}
        </span>
        <span id="t1h_46" class="t s1_46">
          3. Хүлээгдэж буй үр дүн нь танин мэдэхүйн, зан төлөвийн, үнэт зүйл,
          хандлагын гэсэн{" "}
        </span>
        <span id="t1i_46" class="t s1_46">
          төрлүүдтэй байдаг. Танин мэдэхүйн гэдэг нь суралцагчид юу сурах, тэр
          нь яагаад чухал{" "}
        </span>
        <span id="t1j_46" class="t s1_46">
          болохыг мэддэг байх юм. Зан төлөвийн гэдэг нь суралцагчид тодорхой ур
          чадварыг сурч{" "}
        </span>
        <span id="t1k_46" class="t s1_46">
          хэрэглэж түүнийгээ харуулж чаддаг байхыг хэлдэг бол үнэт зүйл
          хандлагын хүлээгдэж{" "}
        </span>
        <span id="t1l_46" class="t s1_46">
          буй үр дүн нь суралцагчдын аливаа зүйлс болон хүмүүсийн харилцааны
          талаарх бодол{" "}
        </span>
        <span id="t1m_46" class="t s1_46">
          мэдрэмж, харилцаа хандлага өөрчлөгдөх тухай асуудал юм.{" "}
        </span>
        <span id="t1n_46" class="t s1_46">
          4. Мэдээллээр хангах, мэдээлэл хуваалцах–багш сургалт, үйл ажиллагаанд
          холбогдох{" "}
        </span>
        <span id="t1o_46" class="t s1_46">
          мэдээллийг өгөхөөс гадна оролцогчдыг тухайн сэдвээр өөрийн{" "}
        </span>
        <span id="t1p_46" class="t s1_46">
          мэдлэг, мэдээллийг{" "}
        </span>
        <span id="t1q_46" class="t s1_46">
          бусадтай хуваалцах, нэмэлт мэдээллийг хаанаас олж болох буюу эх
          сурвалжийг нь{" "}
        </span>
        <span id="t1r_46" class="t s1_46">
          тодорхой зааж өгөх хэрэгтэй.{" "}
        </span>
        <span id="t1s_46" class="t s1_46">
          5. Хамтын ажиллагааны стратеги хэрэгжүүлэх–энэ нь хичээлийн хамгийн
          чухал хэсэг{" "}
        </span>
        <span id="t1t_46" class="t s1_46">
          бөгөөд оролцогчид хооронд болон багш суралцагчдын хамтын үйл ажиллагаа
          маш{" "}
        </span>
        <span id="t1u_46" class="t s1_46">
          чухал байдагтай холбоотой.{" "}
        </span>
        <span id="t1v_46" class="t s1_46">
          6. Дүгнэлт хийх – Сургалт,үйл ажиллагаа зорилгодоо хүрч чадсан эсэхийг
          тодорхойлох{" "}
        </span>
        <span id="t1w_46" class="t s1_46">
          болон тухайн үйл ажиллагааны арга зүйн, зохион байгуулалтын алдаа
          дутагдал,{" "}
        </span>
        <span id="t1x_46" class="t s1_46">
          цаашид анхаарах асуудлуудыг ярилцан тодорхойлж болно. Суралцагчид
          сурсан мэдлэг,{" "}
        </span>
        <span id="t1y_46" class="t s1_46">
          чадвараа нөхцөл байдалд хэрхэн хэрэглэж байгаагаар дүгнэлт үнэлгээг
          хийх нь чухал.{" "}
        </span>
        <span id="t1z_46" class="t s1_46">
          Б. Сургалт, үйл ажиллагааны явцад бүлгээр ажиллахыг дэмжих- Бүлгээр
          ажиллахад{" "}
        </span>
        <span id="t20_46" class="t s1_46">
          суралцагч бүрийн оролцоо чухал бөгөөд тэд оролцооны аргад суралцаад
          зогсохгүй бусадтай{" "}
        </span>
        <span id="t21_46" class="t s1_46">
          зөв харилцах чадварыг хөгжүүлнэ. Тиймээс бүлгийн ажлыг төлөвлөх,
          хэрэгжүүлэх чухал{" "}
        </span>
        <span id="t22_46" class="t s1_46">
          үүрэг багшид оногдоно.{" "}
        </span>
      </div>
    </div>
  );
}
