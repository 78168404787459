import React from "react";
;

export default function TwentyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p28" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`

.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_28{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_28{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_28{left:110px;bottom:1157px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t4_28{left:110px;bottom:1139px;letter-spacing:-0.14px;word-spacing:-1.07px;}
#t5_28{left:562px;bottom:1147px;}
#t6_28{left:567px;bottom:1139px;letter-spacing:-0.14px;word-spacing:-1.06px;}
#t7_28{left:110px;bottom:1121px;letter-spacing:-0.13px;word-spacing:-1.12px;}
#t8_28{left:110px;bottom:1102px;letter-spacing:-0.18px;word-spacing:2.56px;}
#t9_28{left:110px;bottom:1084px;letter-spacing:-0.16px;}
#ta_28{left:110px;bottom:1066px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tb_28{left:110px;bottom:1048px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tc_28{left:289px;bottom:1048px;letter-spacing:-0.15px;word-spacing:0.03px;}
#td_28{left:110px;bottom:1030px;letter-spacing:-0.14px;word-spacing:0.04px;}
#te_28{left:110px;bottom:1012px;letter-spacing:-0.21px;}
#tf_28{left:159px;bottom:1012px;}
#tg_28{left:169px;bottom:1012px;letter-spacing:-0.19px;word-spacing:0.09px;}
#th_28{left:110px;bottom:994px;letter-spacing:-0.14px;word-spacing:0.05px;}
#ti_28{left:110px;bottom:975px;letter-spacing:-0.16px;word-spacing:6px;}
#tj_28{left:110px;bottom:957px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tk_28{left:110px;bottom:939px;letter-spacing:-0.15px;word-spacing:1.5px;}
#tl_28{left:110px;bottom:921px;letter-spacing:-0.17px;}
#tm_28{left:116px;bottom:892px;letter-spacing:0.15px;}
#tn_28{left:188px;bottom:892px;letter-spacing:0.08px;}
#to_28{left:116px;bottom:880px;letter-spacing:0.03px;word-spacing:0.1px;}
#tp_28{left:116px;bottom:867px;letter-spacing:0.14px;}
#tq_28{left:116px;bottom:855px;letter-spacing:0.16px;}
#tr_28{left:475px;bottom:890px;}
#ts_28{left:490px;bottom:891px;letter-spacing:0.09px;word-spacing:4.06px;}
#tt_28{left:475px;bottom:873px;letter-spacing:0.1px;word-spacing:0.04px;}
#tu_28{left:633px;bottom:873px;letter-spacing:0.14px;}
#tv_28{left:475px;bottom:854px;letter-spacing:-0.21px;word-spacing:2.17px;}
#tw_28{left:475px;bottom:836px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tx_28{left:475px;bottom:818px;letter-spacing:-0.13px;word-spacing:8.34px;}
#ty_28{left:475px;bottom:799px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tz_28{left:475px;bottom:781px;letter-spacing:-0.2px;word-spacing:11.67px;}
#t10_28{left:475px;bottom:763px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t11_28{left:116px;bottom:718px;letter-spacing:0.15px;}
#t12_28{left:188px;bottom:718px;letter-spacing:0.11px;}
#t13_28{left:116px;bottom:706px;letter-spacing:0.11px;word-spacing:0.02px;}
#t14_28{left:116px;bottom:694px;letter-spacing:0.16px;}
#t15_28{left:471px;bottom:698px;letter-spacing:0.12px;word-spacing:0.02px;}
#t16_28{left:779px;bottom:698px;letter-spacing:0.16px;}
#t17_28{left:471px;bottom:686px;letter-spacing:0.11px;word-spacing:0.04px;}
#t18_28{left:471px;bottom:669px;letter-spacing:0.1px;word-spacing:0.03px;}
#t19_28{left:471px;bottom:652px;letter-spacing:0.1px;word-spacing:-0.29px;}
#t1a_28{left:471px;bottom:635px;word-spacing:0.13px;}
#t1b_28{left:471px;bottom:619px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1c_28{left:471px;bottom:602px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1d_28{left:471px;bottom:585px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1e_28{left:471px;bottom:568px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1f_28{left:471px;bottom:551px;letter-spacing:0.13px;}
#t1g_28{left:471px;bottom:534px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1h_28{left:471px;bottom:518px;letter-spacing:0.08px;word-spacing:0.07px;}
#t1i_28{left:116px;bottom:487px;letter-spacing:0.16px;}
#t1j_28{left:179px;bottom:487px;letter-spacing:0.07px;word-spacing:0.03px;}
#t1k_28{left:116px;bottom:475px;letter-spacing:0.1px;}
#t1l_28{left:116px;bottom:462px;letter-spacing:0.12px;word-spacing:0.01px;}
#t1m_28{left:116px;bottom:450px;letter-spacing:0.1px;}
#t1n_28{left:116px;bottom:341px;letter-spacing:0.16px;}
#t1o_28{left:179px;bottom:341px;letter-spacing:0.13px;}
#t1p_28{left:116px;bottom:329px;letter-spacing:0.04px;word-spacing:0.08px;}
#t1q_28{left:116px;bottom:317px;letter-spacing:0.13px;}
#t1r_28{left:436px;bottom:337px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1s_28{left:436px;bottom:325px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1t_28{left:436px;bottom:312px;letter-spacing:0.15px;}
#t1u_28{left:436px;bottom:288px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1v_28{left:436px;bottom:276px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1w_28{left:436px;bottom:251px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1x_28{left:436px;bottom:239px;letter-spacing:0.08px;}
#t1y_28{left:278px;bottom:214px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1z_28{left:278px;bottom:202px;letter-spacing:0.08px;word-spacing:0.06px;}
#t20_28{left:278px;bottom:190px;letter-spacing:0.1px;word-spacing:0.04px;}
#t21_28{left:278px;bottom:178px;letter-spacing:0.14px;}
#t22_28{left:55px;bottom:70px;}
#t23_28{left:69px;bottom:70px;letter-spacing:-0.1px;word-spacing:-0.03px;}

.s0_28{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_28{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_28{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_28{font-size:10px;font-family:ArialMT_5-;color:#231F20;}
.s4_28{font-size:17px;font-family:Arial-ItalicMT_6i;color:#231F20;}
.s5_28{font-size:15px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s6_28{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s7_28{font-size:14px;font-family:Cambria_20;color:#231F20;}
.s8_28{font-size:14px;font-family:Cambria_1w;color:#000;}`}
        </style>
        <style id="fonts28" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6i;
	src: url("fonts/Arial-ItalicMT_6i.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: Cambria_1w;
	src: url("fonts/Cambria_1w.woff") format("woff");
}

@font-face {
	font-family: Cambria_20;
	src: url("fonts/Cambria_20.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg28" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="28/28.svg"
          type="image/svg+xml"
          id="pdf28"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_28" class="t s0_28">
          26{" "}
        </span>
        <span id="t2_28" class="t s1_28">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_28" class="t s2_28">
          Нэмэлт мэдээлэл:{" "}
        </span>
        <span id="t4_28" class="t s1_28">
          Хүн нэг бүрийн үүргэвчид байвал зохих зүйлсийн жагсаалт{" "}
        </span>
        <span id="t5_28" class="t s3_28">
          1{" "}
        </span>
        <span id="t6_28" class="t s1_28">
          -д ус, хатаасан болон лаазалсан{" "}
        </span>
        <span id="t7_28" class="t s1_28">
          хүнс, сонин, харандаа, цаас, бээлий, ороолт, дулаан хувцас, гар
          чийдэн, лаа, чүдэнз, шүгэл,{" "}
        </span>
        <span id="t8_28" class="t s1_28">
          хувийн бичиг баримт, иж бүрэн эмийн хайрцаг, жижиг радио хүлээн авагч
          зэрэг багтдаг{" "}
        </span>
        <span id="t9_28" class="t s1_28">
          байна.{" "}
        </span>
        <span id="ta_28" class="t s2_28">
          Суурь боловсролын түвшинд:{" "}
        </span>
        <span id="tb_28" class="t s1_28">
          Үйл ажиллагааны нэр:{" "}
        </span>
        <span id="tc_28" class="t s2_28">
          Анхны тусламж{" "}
        </span>
        <span id="td_28" class="t s1_28">
          Зорилго: Бусдад анхны тусламж үзүүлэх аргачлалд суралцана.{" "}
        </span>
        <span id="te_28" class="t s1_28">
          Орчин
        </span>
        <span id="tf_28" class="t s4_28">
          :{" "}
        </span>
        <span id="tg_28" class="t s1_28">
          Танхимаас гадуурх сургалт{" "}
        </span>
        <span id="th_28" class="t s1_28">
          Арга зүй:{" "}
        </span>
        <span id="ti_28" class="t s1_28">
          Тухайн үйл ажиллагааг багш, ажилтнууд, сувилагч гэх мэт бусад
          мэргэжилтнүүдийн{" "}
        </span>
        <span id="tj_28" class="t s1_28">
          туслалцаатайгаар зохион байгуулна.{" "}
        </span>
        <span id="tk_28" class="t s1_28">
          Биеийн тамирын талбай зэрэг зай, талбайтай газарт анхны тусламжийн хэд
          хэдэн өртөө{" "}
        </span>
        <span id="tl_28" class="t s1_28">
          байгуулна.{" "}
        </span>
        <span id="tm_28" class="t s5_28">
          Өртөө 1.{" "}
        </span>
        <span id="tn_28" class="t s6_28">
          Зүсэгдэн,{" "}
        </span>
        <span id="to_28" class="t s6_28">
          зулгарч цус алдах{" "}
        </span>
        <span id="tp_28" class="t s6_28">
          үеийн анхны{" "}
        </span>
        <span id="tq_28" class="t s6_28">
          тусламж{" "}
        </span>
        <span id="tr_28" class="t s1_28">
          -{" "}
        </span>
        <span id="ts_28" class="t s6_28">
          Бугалгаас цус алдвал мөрний дунд дотор{" "}
        </span>
        <span id="tt_28" class="t s6_28">
          талаас нь судсыг мөр{" "}
        </span>
        <span id="tu_28" class="t s6_28">
          рүү дарна.{" "}
        </span>
        <span id="tv_28" class="t s1_28">
          - Гарын шуу, хуруунаас цус алдвал шуун{" "}
        </span>
        <span id="tw_28" class="t s1_28">
          дээрх хоёр судсыг дарна.{" "}
        </span>
        <span id="tx_28" class="t s1_28">
          - Шилбэнээс цус алдвал өвдөг дээрх{" "}
        </span>
        <span id="ty_28" class="t s1_28">
          судсыг дарна.{" "}
        </span>
        <span id="tz_28" class="t s1_28">
          - Гуянаас цус алдвал гуяны судсыг{" "}
        </span>
        <span id="t10_28" class="t s1_28">
          аарцагны яс руу шахаж дарна.{" "}
        </span>
        <span id="t11_28" class="t s5_28">
          Өртөө 2.{" "}
        </span>
        <span id="t12_28" class="t s6_28">
          Яс{" "}
        </span>
        <span id="t13_28" class="t s6_28">
          хугарах үеийн анхны{" "}
        </span>
        <span id="t14_28" class="t s6_28">
          тусламж{" "}
        </span>
        <span id="t15_28" class="t s6_28">
          - Ил болон битүү хугаралтын аль алинд нь{" "}
        </span>
        <span id="t16_28" class="t s5_28">
          (цус{" "}
        </span>
        <span id="t17_28" class="t s5_28">
          тогтоож, ариутгасан боолт тавьсны дараа){" "}
        </span>
        <span id="t18_28" class="t s6_28">
          эмч иртэл гэмтсэн мөчийг хөдөлгөөнгүй байлгана.{" "}
        </span>
        <span id="t19_28" class="t s6_28">
          Энэ нь өвдөлтийг багасгаж, хугарсан ясны цаашдын{" "}
        </span>
        <span id="t1a_28" class="t s6_28">
          шилжилтийг зогсоодог.{" "}
        </span>
        <span id="t1b_28" class="t s6_28">
          - Хөдөлгөөнгүй байлгахын тулд бэлэн чиг баригч{" "}
        </span>
        <span id="t1c_28" class="t s6_28">
          болон саваа, банз, шугам, фанерийг ашиглана.{" "}
        </span>
        <span id="t1d_28" class="t s6_28">
          Битүү хугаралтын үед өвчтний хувцсыг тайлах{" "}
        </span>
        <span id="t1e_28" class="t s6_28">
          хэрэггүй ба чиг баригчийг хувцас дээр шууд тавих{" "}
        </span>
        <span id="t1f_28" class="t s6_28">
          хэрэгтэй.{" "}
        </span>
        <span id="t1g_28" class="t s6_28">
          - Өвдөлтийг багасгахын тулд бэртсэн газар хүйтэн{" "}
        </span>
        <span id="t1h_28" class="t s6_28">
          жин тавих шаардлагатай.{" "}
        </span>
        <span id="t1i_28" class="t s5_28">
          Өртөө 3
        </span>
        <span id="t1j_28" class="t s6_28">
          . Зүрх,{" "}
        </span>
        <span id="t1k_28" class="t s6_28">
          амьсгалын{" "}
        </span>
        <span id="t1l_28" class="t s6_28">
          эрхтний сэргээн{" "}
        </span>
        <span id="t1m_28" class="t s6_28">
          амьдруулалт{" "}
        </span>
        <span id="t1n_28" class="t s5_28">
          Өртөө 4
        </span>
        <span id="t1o_28" class="t s6_28">
          . Нарших{" "}
        </span>
        <span id="t1p_28" class="t s6_28">
          үед авах арга{" "}
        </span>
        <span id="t1q_28" class="t s6_28">
          хэмжээ{" "}
        </span>
        <span id="t1r_28" class="t s6_28">
          - Буцалсан усыг хөргөөж задгай саванд хийж наранд{" "}
        </span>
        <span id="t1s_28" class="t s6_28">
          халаагаад уг усаа уух буюу толгой биеийг угааж{" "}
        </span>
        <span id="t1t_28" class="t s6_28">
          шавших, шингэн уух{" "}
        </span>
        <span id="t1u_28" class="t s6_28">
          - Дээрх шинж тэмдэг илэрвэл эмчид яаралтай хандаж{" "}
        </span>
        <span id="t1v_28" class="t s6_28">
          үзүүлж, зөвлөгөө авах{" "}
        </span>
        <span id="t1w_28" class="t s6_28">
          - Нарнаас хамгаалж гэртээ чийгтэй сэрүүн орчин{" "}
        </span>
        <span id="t1x_28" class="t s6_28">
          бүрдүүлэх{" "}
        </span>
        <span id="t1y_28" class="t s6_28">
          - Хэрэв халуурвал хүйтэн жинг толгой, шилэн хүзүү{" "}
        </span>
        <span id="t1z_28" class="t s6_28">
          орчимд тавьж халуунаа бууруулах;{" "}
        </span>
        <span id="t20_28" class="t s6_28">
          - Хоол унд хорихгүй учраас шим тэжээлтэй хоол идэх{" "}
        </span>
        <span id="t21_28" class="t s6_28">
          нь зүйтэй{" "}
        </span>
        <span id="t22_28" class="t s7_28">
          1{" "}
        </span>
        <span id="t23_28" class="t s8_28">
          Эх сурвалж: Аврагч мазаалайн зөвлөмж.,2014.{" "}
        </span>
      </div>
    </div>
  );
}
