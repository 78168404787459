import React from "react";
;

export default function FourFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_44{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_44{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_44{left:110px;bottom:1157px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t4_44{left:153px;bottom:1133px;}
#t5_44{left:180px;bottom:1133px;letter-spacing:-0.14px;word-spacing:0.68px;}
#t6_44{left:180px;bottom:1114px;letter-spacing:-0.14px;word-spacing:1.69px;}
#t7_44{left:180px;bottom:1096px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t8_44{left:153px;bottom:1078px;}
#t9_44{left:180px;bottom:1078px;letter-spacing:-0.13px;word-spacing:1.28px;}
#ta_44{left:180px;bottom:1060px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tb_44{left:110px;bottom:1018px;letter-spacing:-0.11px;word-spacing:0.03px;}
#tc_44{left:110px;bottom:999px;letter-spacing:-0.17px;word-spacing:0.06px;}
#td_44{left:110px;bottom:981px;letter-spacing:-0.14px;word-spacing:4.91px;}
#te_44{left:110px;bottom:963px;letter-spacing:-0.14px;word-spacing:2.71px;}
#tf_44{left:110px;bottom:945px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_44{left:110px;bottom:909px;letter-spacing:-0.15px;word-spacing:0.05px;}
#th_44{left:188px;bottom:909px;letter-spacing:-0.16px;word-spacing:0.05px;}
#ti_44{left:110px;bottom:891px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tj_44{left:110px;bottom:873px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tk_44{left:110px;bottom:854px;letter-spacing:-0.13px;word-spacing:-0.09px;}
#tl_44{left:110px;bottom:836px;letter-spacing:-0.2px;word-spacing:1.84px;}
#tm_44{left:202px;bottom:836px;letter-spacing:-0.05px;}
#tn_44{left:245px;bottom:836px;letter-spacing:-0.12px;word-spacing:1.77px;}
#to_44{left:110px;bottom:818px;letter-spacing:-0.12px;word-spacing:-0.29px;}
#tp_44{left:110px;bottom:800px;letter-spacing:-0.14px;word-spacing:0.39px;}
#tq_44{left:110px;bottom:782px;letter-spacing:-0.18px;word-spacing:-0.32px;}
#tr_44{left:110px;bottom:764px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ts_44{left:110px;bottom:746px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tt_44{left:110px;bottom:727px;letter-spacing:-0.13px;word-spacing:-1.47px;}
#tu_44{left:110px;bottom:709px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_44{left:110px;bottom:673px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tw_44{left:188px;bottom:673px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tx_44{left:110px;bottom:655px;letter-spacing:-0.14px;word-spacing:-1.82px;}
#ty_44{left:110px;bottom:637px;letter-spacing:-0.13px;word-spacing:0.38px;}
#tz_44{left:110px;bottom:619px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t10_44{left:110px;bottom:600px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t11_44{left:110px;bottom:582px;letter-spacing:-0.19px;word-spacing:0.1px;}
#t12_44{left:110px;bottom:546px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t13_44{left:110px;bottom:528px;letter-spacing:-0.18px;word-spacing:2.66px;}
#t14_44{left:110px;bottom:510px;letter-spacing:-0.17px;word-spacing:0.76px;}
#t15_44{left:110px;bottom:492px;letter-spacing:-0.15px;word-spacing:1.8px;}
#t16_44{left:110px;bottom:474px;letter-spacing:-0.13px;word-spacing:3.33px;}
#t17_44{left:110px;bottom:455px;letter-spacing:-0.14px;word-spacing:6.88px;}
#t18_44{left:110px;bottom:437px;letter-spacing:-0.14px;word-spacing:6.59px;}
#t19_44{left:110px;bottom:419px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1a_44{left:110px;bottom:401px;letter-spacing:-0.12px;word-spacing:-0.67px;}
#t1b_44{left:110px;bottom:383px;letter-spacing:-0.16px;}
#t1c_44{left:110px;bottom:365px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1d_44{left:110px;bottom:347px;letter-spacing:-0.14px;word-spacing:-0.7px;}
#t1e_44{left:110px;bottom:328px;letter-spacing:-0.15px;word-spacing:-0.29px;}
#t1f_44{left:110px;bottom:310px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1g_44{left:110px;bottom:292px;letter-spacing:-0.13px;word-spacing:1.84px;}
#t1h_44{left:110px;bottom:274px;letter-spacing:-0.13px;word-spacing:4.14px;}
#t1i_44{left:110px;bottom:256px;letter-spacing:-0.14px;word-spacing:3.6px;}
#t1j_44{left:110px;bottom:238px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1k_44{left:110px;bottom:220px;letter-spacing:-0.13px;word-spacing:1.53px;}
#t1l_44{left:110px;bottom:202px;letter-spacing:-0.14px;word-spacing:-0.32px;}
#t1m_44{left:110px;bottom:183px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1n_44{left:110px;bottom:147px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1o_44{left:110px;bottom:129px;letter-spacing:-0.17px;word-spacing:5.8px;}
#t1p_44{left:110px;bottom:111px;letter-spacing:-0.19px;word-spacing:-2.3px;}
#t1q_44{left:596px;bottom:111px;letter-spacing:-0.19px;word-spacing:-2.29px;}
#t1r_44{left:110px;bottom:93px;letter-spacing:-0.18px;word-spacing:0.01px;}

.s0_44{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_44{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_44{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_44{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>

        <style id="fonts44" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg44" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="44/44.svg"
          type="image/svg+xml"
          id="pdf44"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_44" class="t s0_44">
          42{" "}
        </span>
        <span id="t2_44" class="t s1_44">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_44" class="t s1_44">
          в.Бүрэн дунд боловсролд{" "}
        </span>
        <span id="t4_44" class="t s1_44">
          -{" "}
        </span>
        <span id="t5_44" class="t s1_44">
          Өөрийн итгэл үнэмшилд суурилан зорилгоо тодорхойлох, бусдыг сонсож
          ойлгон,{" "}
        </span>
        <span id="t6_44" class="t s1_44">
          чин сэтгэлээсээ хандах амьдралын зорилгоо тодорхойлж, ирээдүйгээ
          төлөвлөх,{" "}
        </span>
        <span id="t7_44" class="t s1_44">
          мэргэжлээ сонгох{" "}
        </span>
        <span id="t8_44" class="t s1_44">
          -{" "}
        </span>
        <span id="t9_44" class="t s1_44">
          Бусдын ялгаатай байдалд мэдрэмжтэй хандах, өөр соёл, үндэстнүүдийн
          талаар{" "}
        </span>
        <span id="ta_44" class="t s1_44">
          мэдлэг ойлголттой байх, тэнцвэртэй харилцах{" "}
        </span>
        <span id="tb_44" class="t s1_44">
          6.1.4.Арга зүй{" "}
        </span>
        <span id="tc_44" class="t s2_44">
          Бага боловсролын түвшинд{" "}
        </span>
        <span id="td_44" class="t s1_44">
          Оролцооны тухай ойлголт өгөх, хүүхэд өөрийн хүсэл мөрөөдлийнхөө тухай
          бусадтай{" "}
        </span>
        <span id="te_44" class="t s1_44">
          хуваалцах, үзэл бодол байр сууриа илэрхийлэх, шийдвэр гаргах, хамтран
          ажиллах үйл{" "}
        </span>
        <span id="tf_44" class="t s1_44">
          явцад суралцах боломжтой.{" "}
        </span>
        <span id="tg_44" class="t s3_44">
          Загвар 1:{" "}
        </span>
        <span id="th_44" class="t s3_44">
          Би том болоод.{" "}
        </span>
        <span id="ti_44" class="t s1_44">
          Зорилго: Хүүхэд бүр өөрийн хүсэл мөрөөдөл давуу талаа олж мэдэхэд
          суралцана.{" "}
        </span>
        <span id="tj_44" class="t s1_44">
          Хэрэглэгдэхүүн: Ангийн бүх хүүхдүүд чөлөөтэй тоглох боломжтой өрөө
          тасалгаа{" "}
        </span>
        <span id="tk_44" class="t s1_44">
          Заавар: Бүх оролцогчид тойрог болон сууна. Хүүхдүүд нэг нэгээрээ
          тойргийн голд гарч “Би{" "}
        </span>
        <span id="tl_44" class="t s1_44">
          том болоод
        </span>
        <span id="tm_44" class="t s1_44">
          ........{" "}
        </span>
        <span id="tn_44" class="t s1_44">
          ийм мэргэжилтэй хүн болно” хэмээн ирээдүйд өөрийн эзэмшихээр хүсч{" "}
        </span>
        <span id="to_44" class="t s1_44">
          буй ажил мэргэжлээ ярьж хэлэлгүй зөвхөн үйл хөдлөлөөрөө хийж үзүүлнэ.
          Бусад хүүхдүүд{" "}
        </span>
        <span id="tp_44" class="t s1_44">
          түүний үйл хөдлөлөөр ямар мэргэжилтэй болохыг хүсч байгааг таана.
          Загвар барилгачин,{" "}
        </span>
        <span id="tq_44" class="t s1_44">
          эмч, тогооч, хуульч, багш гэх мэт. Үйл ажиллагааны төгсгөлд багш
          мэргэжил бүхний талаар{" "}
        </span>
        <span id="tr_44" class="t s1_44">
          харилцан ярьж тайлбарлаж өгнө.{" "}
        </span>
        <span id="ts_44" class="t s2_44">
          Суурь, бүрэн дунд боловсролын түвшинд{" "}
        </span>
        <span id="tt_44" class="t s1_44">
          Хүүхдийн байгаль экологийн оролцоог идэвхижүүлэх үйл ажиллагааны
          загвар санал болгож{" "}
        </span>
        <span id="tu_44" class="t s1_44">
          байна. Энэ санааг оролцооны өөр хэлбэр, хувилбарт хөрвүүлэн ашиглах
          боломжтой.{" "}
        </span>
        <span id="tv_44" class="t s3_44">
          Загвар 2:{" "}
        </span>
        <span id="tw_44" class="t s3_44">
          “Хүрээлэн буй орчноо сайжруулах” төсөл{" "}
        </span>
        <span id="tx_44" class="t s1_44">
          Зорилго: Хүүхдүүд өөрсдийн хүрээлэн буй орчин, экосистемийг бүрдүүлэгч
          амьтад, ургамал,{" "}
        </span>
        <span id="ty_44" class="t s1_44">
          ус, ой мод, хөрс шороо болон өөрсдийнх нь амьдралд ямар нэгэн асуудал
          байгаа эсэхийг{" "}
        </span>
        <span id="tz_44" class="t s1_44">
          тодорхойлон, асуудлыг шийдвэрлэн хүрээлэн буй орчноо сайжруулах төсөл
          боловсруулан{" "}
        </span>
        <span id="t10_44" class="t s1_44">
          хэрэгжүүлэх арга замд суралцана.{" "}
        </span>
        <span id="t11_44" class="t s1_44">
          Хэрэглэгдэхүүн: Самбар, ватум цаас, өнгийн харандаа, үзэг, цавуу,
          фломастер, скоч{" "}
        </span>
        <span id="t12_44" class="t s1_44">
          Үйл ажиллагаа:{" "}
        </span>
        <span id="t13_44" class="t s1_44">
          Энэ ажил нь сурагчдад урьд өмнө судалж мэдсэн хүрээлэн буй орчны
          талаарх мэдлэг,{" "}
        </span>
        <span id="t14_44" class="t s1_44">
          мэдээллээ бодитоор хэрэглэх боломж олгоно. Үндсэн зорилго нь сурагчид
          сурсан мэдлэг,{" "}
        </span>
        <span id="t15_44" class="t s1_44">
          чадвараа ашиглан өөрийн орон нутаг, хүмүүс болон амьд байгаль зэрэгцэн
          сайн сайхан{" "}
        </span>
        <span id="t16_44" class="t s1_44">
          амьдрах орчныг сайжруулан өөрчлөх төсөл хэрэгжүүлэх юм. Багийн гишүүн
          бүр төсөл{" "}
        </span>
        <span id="t17_44" class="t s1_44">
          хэрэгжүүлэхэд оролцох ёстой. Хүн бүрийг оролцуулан, тохирсон ажлыг
          хуваарилан{" "}
        </span>
        <span id="t18_44" class="t s1_44">
          хариуцуулах, хийх ажил, хугацаа, хариуцах хүнийг тодорхойлон ажлын
          төлөвлөгөөг{" "}
        </span>
        <span id="t19_44" class="t s1_44">
          боловсруулан хэрэгжүүлнэ.{" "}
        </span>
        <span id="t1a_44" class="t s1_44">
          Төсөл хэрэгжүүлэхдээ дараах 6 алхмыг хэрэглэх нь хүүхдэд илүү
          ойлгомжтой, хялбар байх{" "}
        </span>
        <span id="t1b_44" class="t s1_44">
          болно.{" "}
        </span>
        <span id="t1c_44" class="t s1_44">
          Алхам-1 Багаар буюу бүлгээр ажиллан асуудлыг тодорхойлох.{" "}
        </span>
        <span id="t1d_44" class="t s1_44">
          Ингэхдээ дараах асуултыг асуун хэлэлцээрэй. Та бүхнийг хүрээлэн буй
          орчинд (сургуулийн{" "}
        </span>
        <span id="t1e_44" class="t s1_44">
          гадаад, дотоод) болохгүй, чамд таалагдахгүй, өөрчилмөөр ямар асуудал
          байна вэ? Хүүхэд{" "}
        </span>
        <span id="t1f_44" class="t s1_44">
          бүр нэг асуудал гаргаж ирнэ.{" "}
        </span>
        <span id="t1g_44" class="t s1_44">
          Асуудлыг самбар дээр дараалуулан бичих эсвэл хүүхэд бүр өнгийн цаасан
          дээр бичээд{" "}
        </span>
        <span id="t1h_44" class="t s1_44">
          самбарт наана. Хамгийн чухал, цаг хугацаа, санхүү болон хүн хүчний
          хувьд 1 сарын{" "}
        </span>
        <span id="t1i_44" class="t s1_44">
          хугацаанд хэрэгжих боломжтой нэг асуудлыг сонгон авахад багш тусална.
          Хүүхэд бүр{" "}
        </span>
        <span id="t1j_44" class="t s1_44">
          гаргасан жагсаалтаас нэг ажлыг өөрийн сонголтоороо тэмдэглэж саналаа
          илэрхийлнэ.{" "}
        </span>
        <span id="t1k_44" class="t s1_44">
          Дараа нь саналыг тоолж олон санал авсан ажлуудыг сонгоно. Цөөнх болсон
          хүүхдүүдэд{" "}
        </span>
        <span id="t1l_44" class="t s1_44">
          өөрийн санааг илэрхийлэх, олонх өөрийн санааг нотлох боломжийг олгон
          ил тод байдлаар{" "}
        </span>
        <span id="t1m_44" class="t s1_44">
          санал асуулга явуулан багийн шийдвэрлэх асуудал буюу төслийн санааг
          сонгоно.{" "}
        </span>
        <span id="t1n_44" class="t s1_44">
          Алхам-2. Сонгосон асуудлаар мэдээлэл цуглуулах:{" "}
        </span>
        <span id="t1o_44" class="t s1_44">
          Бусад хүүхдүүд, сургуулийн багш, ажилтнуудаас санал асуулга, товч
          судалгаа авах,{" "}
        </span>
        <span id="t1p_44" class="t s1_44">
          боломжтой бол хэвлэл мэдээлэл, ном, сурах бичиг, хууль дүрэм{" "}
        </span>
        <span id="t1q_44" class="t s1_44">
          судлах аргаар шаардлагатай{" "}
        </span>
        <span id="t1r_44" class="t s1_44">
          мэдээлэл цуглуулна. Шаардлагатай бол багш, ажилтнууд нэмэлт мэдээлэл
          сурагчдад өгөх{" "}
        </span>
      </div>
    </div>
  );
}
