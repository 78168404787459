import React from "react";
;

export default function TwentyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p22" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`#t1_22{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_22{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_22{left:110px;bottom:1157px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t4_22{left:341px;bottom:1157px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t5_22{left:110px;bottom:1139px;letter-spacing:-0.14px;word-spacing:2.4px;}
#t6_22{left:110px;bottom:1121px;letter-spacing:-0.14px;word-spacing:2px;}
#t7_22{left:110px;bottom:1102px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t8_22{left:110px;bottom:1084px;letter-spacing:-0.15px;word-spacing:5.93px;}
#t9_22{left:110px;bottom:1066px;letter-spacing:-0.15px;word-spacing:0.26px;}
#ta_22{left:110px;bottom:1048px;letter-spacing:-0.14px;word-spacing:0.49px;}
#tb_22{left:110px;bottom:1030px;letter-spacing:-0.15px;word-spacing:1.86px;}
#tc_22{left:110px;bottom:1012px;letter-spacing:-0.12px;word-spacing:-0.89px;}
#td_22{left:110px;bottom:994px;letter-spacing:-0.18px;word-spacing:0.08px;}
#te_22{left:110px;bottom:975px;letter-spacing:-0.15px;word-spacing:-0.72px;}
#tf_22{left:110px;bottom:957px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tg_22{left:110px;bottom:939px;letter-spacing:-0.11px;word-spacing:0.03px;}
#th_22{left:186px;bottom:939px;letter-spacing:-0.08px;word-spacing:-0.01px;}
#ti_22{left:110px;bottom:921px;letter-spacing:-0.12px;word-spacing:-1.12px;}
#tj_22{left:110px;bottom:903px;letter-spacing:-0.14px;word-spacing:2.08px;}
#tk_22{left:110px;bottom:885px;letter-spacing:-0.18px;word-spacing:1.98px;}
#tl_22{left:110px;bottom:867px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tm_22{left:110px;bottom:848px;letter-spacing:-0.14px;word-spacing:2.8px;}
#tn_22{left:110px;bottom:830px;letter-spacing:-0.15px;word-spacing:2.05px;}
#to_22{left:110px;bottom:812px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tp_22{left:110px;bottom:794px;letter-spacing:-0.12px;word-spacing:-0.8px;}
#tq_22{left:110px;bottom:776px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tr_22{left:110px;bottom:758px;letter-spacing:-0.1px;}
#ts_22{left:263px;bottom:758px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tt_22{left:110px;bottom:740px;letter-spacing:-0.13px;word-spacing:0.37px;}
#tu_22{left:110px;bottom:722px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tv_22{left:110px;bottom:703px;letter-spacing:-0.14px;word-spacing:2.67px;}
#tw_22{left:110px;bottom:685px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tx_22{left:110px;bottom:667px;letter-spacing:-0.23px;word-spacing:0.13px;}
#ty_22{left:153px;bottom:643px;}
#tz_22{left:180px;bottom:643px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t10_22{left:153px;bottom:625px;}
#t11_22{left:180px;bottom:625px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t12_22{left:153px;bottom:607px;}
#t13_22{left:180px;bottom:607px;letter-spacing:-0.16px;word-spacing:-0.62px;}
#t14_22{left:153px;bottom:588px;}
#t15_22{left:180px;bottom:588px;letter-spacing:-0.16px;word-spacing:5.04px;}
#t16_22{left:180px;bottom:570px;letter-spacing:-0.33px;word-spacing:0.25px;}
#t17_22{left:153px;bottom:552px;}
#t18_22{left:180px;bottom:552px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t19_22{left:153px;bottom:534px;}
#t1a_22{left:180px;bottom:534px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1b_22{left:715px;bottom:534px;letter-spacing:-0.18px;}
#t1c_22{left:153px;bottom:516px;}
#t1d_22{left:180px;bottom:516px;letter-spacing:-0.17px;word-spacing:-1.58px;}
#t1e_22{left:180px;bottom:498px;letter-spacing:-0.17px;}
#t1f_22{left:180px;bottom:480px;letter-spacing:-0.17px;word-spacing:1.65px;}
#t1g_22{left:180px;bottom:462px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1h_22{left:153px;bottom:443px;}
#t1i_22{left:180px;bottom:443px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1j_22{left:153px;bottom:425px;}
#t1k_22{left:180px;bottom:425px;letter-spacing:-0.15px;word-spacing:3.97px;}
#t1l_22{left:180px;bottom:407px;letter-spacing:-0.15px;word-spacing:0.75px;}
#t1m_22{left:180px;bottom:389px;letter-spacing:-0.15px;word-spacing:2.81px;}
#t1n_22{left:180px;bottom:371px;letter-spacing:-0.16px;word-spacing:0.32px;}
#t1o_22{left:180px;bottom:353px;letter-spacing:-0.14px;word-spacing:1.22px;}
#t1p_22{left:180px;bottom:335px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1q_22{left:153px;bottom:316px;}
#t1r_22{left:180px;bottom:316px;letter-spacing:-0.14px;word-spacing:4.33px;}
#t1s_22{left:180px;bottom:298px;letter-spacing:-0.13px;word-spacing:0.84px;}
#t1t_22{left:180px;bottom:280px;letter-spacing:-0.11px;word-spacing:1.86px;}
#t1u_22{left:180px;bottom:262px;letter-spacing:-0.1px;}
#t1v_22{left:110px;bottom:220px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1w_22{left:110px;bottom:202px;letter-spacing:-0.12px;word-spacing:1.85px;}
#t1x_22{left:110px;bottom:183px;letter-spacing:-0.16px;word-spacing:2.83px;}
#t1y_22{left:110px;bottom:165px;letter-spacing:-0.16px;}
#t1z_22{left:110px;bottom:147px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t20_22{left:110px;bottom:129px;letter-spacing:-0.13px;word-spacing:0.8px;}
#t21_22{left:110px;bottom:111px;letter-spacing:-0.17px;word-spacing:3.58px;}
#t22_22{left:509px;bottom:111px;letter-spacing:-0.18px;word-spacing:3.6px;}
#t23_22{left:110px;bottom:93px;letter-spacing:-0.13px;word-spacing:-1.12px;}
#t24_22{left:110px;bottom:75px;letter-spacing:-0.16px;}

.s0_22{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_22{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_22{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_22{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}`}
        </style>
        <style id="fonts22" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg22" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="22/22.svg"
          type="image/svg+xml"
          id="pdf22"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_22" class="t s0_22">
          20{" "}
        </span>
        <span id="t2_22" class="t s1_22">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_22" class="t s2_22">
          Багш жишээгээр авсан кейс{" "}
        </span>
        <span id="t4_22" class="t s2_22">
          тохиолдлын үйл явдлыг сайн ойлгуулах тодруулж{" "}
        </span>
        <span id="t5_22" class="t s2_22">
          тайлбарлаж өгөх хэрэгтэй. Бүх хүүхэд нэг ижил баримтад тулгуурлах
          ёстой бөгөөд{" "}
        </span>
        <span id="t6_22" class="t s2_22">
          тэднийг идэвхтэй ажиллах боломж олгохын тулд багш сургалтын явцад
          хүүхдүүдийг{" "}
        </span>
        <span id="t7_22" class="t s2_22">
          идэвхжүүлэхэд анхаарч байх нь маш чухал юм.{" "}
        </span>
        <span id="t8_22" class="t s1_22">
          Үүний хамт уншлагын дадал суугаагүй суралцагчдад ч энэ үе нь их ач
          холбогдол,{" "}
        </span>
        <span id="t9_22" class="t s1_22">
          нөлөөлөлтэй байдаг. Багш өөрөө болон хүүхдүүдээр өөрсдөөр нь асуулт
          бэлдүүлж болно.{" "}
        </span>
        <span id="ta_22" class="t s1_22">
          Хүүхдүүд хэрэгтэй мэдээллээ авч тохиолдлыг тал бүрээс нь сайн судалж,
          ойлгосны эцэст{" "}
        </span>
        <span id="tb_22" class="t s1_22">
          шийдэл гаргах үйл ажиллагаандаа орно. Хүүхдүүд мэдээллээ боловсруулан
          тохиолдлоо{" "}
        </span>
        <span id="tc_22" class="t s1_22">
          ойлгосны үндсэн дээр шийдэх бөгөөд ийнхүү шийдвэр гаргах үйл явцыг
          багш ямар ч аргаар{" "}
        </span>
        <span id="td_22" class="t s1_22">
          зохион байгуулж болно.{" "}
        </span>
        <span id="te_22" class="t s1_22">
          Багш сэдэвтэй холбоотой ангид тохиолдсон бодит зүйл болон хүүхдүүдэд
          сургамж авахаар{" "}
        </span>
        <span id="tf_22" class="t s1_22">
          баримтууд цуглуулан асуулт хариултын хувилбартай бэлтгэнэ.{" "}
        </span>
        <span id="tg_22" class="t s1_22">
          Загвар 1:{" "}
        </span>
        <span id="th_22" class="t s3_22">
          Кейс – 1. Би сайн багш бас ...{" "}
        </span>
        <span id="ti_22" class="t s1_22">
          Дорж нэгэн дунд сургуулийн багш. Тэр сүүлийн үед сайн багшийн тоонд
          орж байлаа. Тэрээр{" "}
        </span>
        <span id="tj_22" class="t s1_22">
          сүүлийн хэдэн жил 7-р ангид математикийн хичээл орж байгаа. 7б ангийн
          Бат гэдэг хүү{" "}
        </span>
        <span id="tk_22" class="t s1_22">
          тооны хичээлдээ сайн хүүхдүүдийн тоонд ордог. Дорж багшийгаа хүү маш
          их хүндэлдэг,{" "}
        </span>
        <span id="tl_22" class="t s1_22">
          Дорж ч Бат хүүд сайн ханддаг.{" "}
        </span>
        <span id="tm_22" class="t s1_22">
          Нэгэн удаагийн Доржийн найдвар тавьж байсан Бат хүү хангалтгүй дүн
          үзүүлж, багийн{" "}
        </span>
        <span id="tn_22" class="t s1_22">
          үнэлгээнд нөлөөлснөөр хүүг ангийнхны дунд босгож байгаад ...Тэнэг юм
          уу... гэх мэтээр{" "}
        </span>
        <span id="to_22" class="t s1_22">
          загнажээ. Хүү эхэндээ толгойгоо илээд зогсож байснаа гэнэт хаалга
          саван гарч явав.{" "}
        </span>
        <span id="tp_22" class="t s1_22">
          Энэ тухай Дорж багш хүүг хүмүүжилгүй, итгэлгүй гэх мэтээр багш нарт
          ярьснаас болж, цөөн{" "}
        </span>
        <span id="tq_22" class="t s1_22">
          хэдэн багш нар түүнийг зэмлэжээ.{" "}
        </span>
        <span id="tr_22" class="t s1_22">
          Үүнээс хойш Дорж{" "}
        </span>
        <span id="ts_22" class="t s1_22">
          багш ч түүнд таагүй хандаж хүүг үл тоосон царай гаргаж эхлэв.{" "}
        </span>
        <span id="tt_22" class="t s1_22">
          Бат ч түүний хичээлийг хийхээ больж, ингэх тусам нь багш нь улам л
          тааруухан дүн тавих{" "}
        </span>
        <span id="tu_22" class="t s1_22">
          болсноор Бат хичээлдээ ч суух нь багасаж, аль л болохгүй гэсэн зүйлийг
          хийх болжээ.{" "}
        </span>
        <span id="tv_22" class="t s1_22">
          Хожим хүү сургуулиа төгсөөд ажил амьдралтай болсон ч тэр багштайгаа
          тааралдсан ч{" "}
        </span>
        <span id="tw_22" class="t s1_22">
          харилцахаа больжээ.{" "}
        </span>
        <span id="tx_22" class="t s1_22">
          Хэлэлцэх асуудлууд{" "}
        </span>
        <span id="ty_22" class="t s1_22">
          -{" "}
        </span>
        <span id="tz_22" class="t s1_22">
          Харилцааны зөрчил үүсэхийн эх үүсвэр юу вэ?{" "}
        </span>
        <span id="t10_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t11_22" class="t s1_22">
          Харилцааны зөрчлөөс үүдэн ямар үр дагавар үүссэн бэ?{" "}
        </span>
        <span id="t12_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t13_22" class="t s1_22">
          Хэрэв та Дорж багшийн оронд байсан бол яах байсан бэ? Нэг бүрчлэн
          дурдана уу.{" "}
        </span>
        <span id="t14_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t15_22" class="t s1_22">
          Энэ тохиолдолд хүүгийн хувьд байдал ямар байх байсан бол? Нэг бүрчлэн{" "}
        </span>
        <span id="t16_22" class="t s1_22">
          дурдана уу.{" "}
        </span>
        <span id="t17_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t18_22" class="t s1_22">
          Энд багшийн ёс зүйн ямар зарчим зөрчигдөж байна вэ?{" "}
        </span>
        <span id="t19_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t1a_22" class="t s1_22">
          Кейсийг ийнхүү нэрлэх болсны шалтгааныг юу гэж бодож байна вэ?{" "}
        </span>
        <span id="t1b_22" class="t s2_22">
          Чадвар{" "}
        </span>
        <span id="t1c_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t1d_22" class="t s1_22">
          Тодорхой өгсөн баримт эх, кейсын мэдээллийг уншиж ашиглан (үндэслэл,
          нотолгоо{" "}
        </span>
        <span id="t1e_22" class="t s1_22">
          болгох, Загвар баримт татах, тайлбарлах) тухайн асуудлыг тайлбарлаж,
          шийдвэр{" "}
        </span>
        <span id="t1f_22" class="t s1_22">
          гаргаж чадаж байгаа болон бодлого бодох, тохиолдол шийдвэрлэхдээ
          мэдлэгээ{" "}
        </span>
        <span id="t1g_22" class="t s1_22">
          ашиглаж чадаж байгаа байдал{" "}
        </span>
        <span id="t1h_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t1i_22" class="t s1_22">
          Амаар болон бичгээр өөрийгөө илэрхийлж сурч байгаа байдал{" "}
        </span>
        <span id="t1j_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t1k_22" class="t s1_22">
          Өөрийн үзэл бодол, байр суурь, үнэлгээ дүгнэлтийг илэрхийлж чадаж
          байгаа{" "}
        </span>
        <span id="t1l_22" class="t s1_22">
          байдал түүнчлэн, илэрхийлэхдээ бусдын үзэл бодол, байр сууринд
          хүндэтгэлтэй{" "}
        </span>
        <span id="t1m_22" class="t s1_22">
          хандаж, түүнээс үнэ цэнэтэй зүйлийг олж харж, авч чадаж байгаа эсэх
          багийн{" "}
        </span>
        <span id="t1n_22" class="t s1_22">
          гишүүн байх, бусдыг төлөөлөх чадвар, ялгаатай үзэл бодол байр сууринд
          хандах{" "}
        </span>
        <span id="t1o_22" class="t s1_22">
          хандлага, түүнийг хүлээн зөвшөөрч байгаа байдал асуудалд хууль зүйн
          үүднээс{" "}
        </span>
        <span id="t1p_22" class="t s1_22">
          хандаж байгаа байдал{" "}
        </span>
        <span id="t1q_22" class="t s1_22">
          -{" "}
        </span>
        <span id="t1r_22" class="t s1_22">
          Бага боловсролын түвшинд уншсан үлгэр юм уу эхээ бүрэлдэхүүн хэсгүүдэд{" "}
        </span>
        <span id="t1s_22" class="t s1_22">
          хувааж задлан ялгах, суурь болон бүрэн дунд боловсролын түвшинд
          ажилласан{" "}
        </span>
        <span id="t1t_22" class="t s1_22">
          кейс тус бүрээ учир шалтгаанаар нь хэд хэдэн хэсэгт хувааж задлан
          ялгах үйл{" "}
        </span>
        <span id="t1u_22" class="t s1_22">
          хийнэ.{" "}
        </span>
        <span id="t1v_22" class="t s1_22">
          Бага, суурь бүрэн дунд боловсролын түвшинд задлан үзсэн хэсгүүдээ
          тухайлбал бага{" "}
        </span>
        <span id="t1w_22" class="t s1_22">
          боловсролын түвшинд яагаад хэн нэгнийг гомдоохгүй туслах нь хүний хийх
          энгийн наад{" "}
        </span>
        <span id="t1x_22" class="t s1_22">
          захын үйлдэл байна гэх мэтээр нийлүүлэн нэг бүхэл болгож ойлгож авч
          үзэх үйл хийж{" "}
        </span>
        <span id="t1y_22" class="t s1_22">
          байна.{" "}
        </span>
        <span id="t1z_22" class="t s2_22">
          Бусад судлагдахуунтай хамтрах боломж{" "}
        </span>
        <span id="t20_22" class="t s1_22">
          Бага боловсролын түвшинд ангийн багш өөрөө хичээлүүдээ орж байгаа учир
          Хүн нийгэм,{" "}
        </span>
        <span id="t21_22" class="t s1_22">
          Хүн ба орчин, Иргэний боловсрол, Монгол хэл{" "}
        </span>
        <span id="t22_22" class="t s1_22">
          судлагдахуунтай уялдуулан сургалтын{" "}
        </span>
        <span id="t23_22" class="t s1_22">
          явцад хүүхэд бүрийг оролцуулан хэл яриа болон сэтгэхүйн үйлүүдийг
          хөгжүүлэх боломжтой{" "}
        </span>
        <span id="t24_22" class="t s1_22">
          болно.{" "}
        </span>
      </div>
    </div>
  );
}
