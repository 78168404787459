import React from "react";
;

export default function ThirtySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p36" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_36{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_36{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_36{left:110px;bottom:1159px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t4_36{left:178px;bottom:1159px;}
#t5_36{left:187px;bottom:1159px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t6_36{left:110px;bottom:706px;letter-spacing:-0.23px;word-spacing:0.12px;}
#t7_36{left:110px;bottom:688px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t8_36{left:110px;bottom:669px;letter-spacing:-0.16px;word-spacing:0.93px;}
#t9_36{left:110px;bottom:651px;letter-spacing:-0.12px;word-spacing:1.96px;}
#ta_36{left:110px;bottom:633px;letter-spacing:-0.15px;word-spacing:-0.02px;}
#tb_36{left:110px;bottom:615px;letter-spacing:-0.13px;word-spacing:0.47px;}
#tc_36{left:110px;bottom:597px;letter-spacing:-0.12px;word-spacing:0.02px;}
#td_36{left:110px;bottom:579px;letter-spacing:-0.13px;word-spacing:0.03px;}
#te_36{left:153px;bottom:555px;}
#tf_36{left:180px;bottom:555px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_36{left:153px;bottom:536px;}
#th_36{left:180px;bottom:536px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ti_36{left:153px;bottom:518px;}
#tj_36{left:180px;bottom:518px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tk_36{left:153px;bottom:500px;}
#tl_36{left:180px;bottom:500px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tm_36{left:153px;bottom:482px;}
#tn_36{left:180px;bottom:482px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_36{left:238px;bottom:440px;letter-spacing:-0.25px;word-spacing:0.13px;}
#tp_36{left:110px;bottom:295px;letter-spacing:-0.14px;word-spacing:1.45px;}
#tq_36{left:110px;bottom:276px;letter-spacing:-0.17px;word-spacing:1.73px;}
#tr_36{left:110px;bottom:258px;letter-spacing:-0.19px;word-spacing:2.21px;}
#ts_36{left:110px;bottom:240px;letter-spacing:-0.16px;word-spacing:-0.17px;}
#tt_36{left:110px;bottom:222px;letter-spacing:-0.16px;word-spacing:2.21px;}
#tu_36{left:110px;bottom:204px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tv_36{left:55px;bottom:104px;}
#tw_36{left:69px;bottom:105px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tx_36{left:125px;bottom:399px;letter-spacing:-0.13px;word-spacing:4.32px;}
#ty_36{left:125px;bottom:381px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tz_36{left:125px;bottom:357px;letter-spacing:-0.13px;word-spacing:-1.2px;}
#t10_36{left:129px;bottom:333px;letter-spacing:-0.13px;}

.s0_36{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_36{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_36{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_36{font-size:17px;font-family:ArialMT_5-;color:#D9D9D9;}
.s4_36{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s5_36{font-size:15px;font-family:MinionPro-Regular_6m;color:#000;}
.s6_36{font-size:14px;font-family:ArialMT_5-;color:#333;}`}
        </style>
        <style id="fonts36" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg36" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="36/36.svg"
          type="image/svg+xml"
          id="pdf36"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_36" class="t s0_36">
          34{" "}
        </span>
        <span id="t2_36" class="t s1_36">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_36" class="t s2_36">
          Дасгал 2
        </span>
        <span id="t4_36" class="t s3_36">
          :{" "}
        </span>
        <span id="t5_36" class="t s1_36">
          Дараах тэмдэг, санамжийг сурагчдад дэлгэцээр харуулж, утгыг хэлэлцэнэ.{" "}
        </span>
        <span id="t6_36" class="t s4_36">
          Нэмэлт мэдээлэл{" "}
        </span>
        <span id="t7_36" class="t s4_36">
          Машин болон авто замаар хөдөлгөөнд болгоомжтой оролц{" "}
        </span>
        <span id="t8_36" class="t s1_36">
          Авто замаар гарахдаа заавал гарцтай газраар ногоон гэрлээр гарч
          байгаарай. Машинаар{" "}
        </span>
        <span id="t9_36" class="t s1_36">
          явахдаа суудлын бүс заавал зүүж хэвш. Гэр бүлийнхэндээ ч суудлын бүсээ
          даруулахыг{" "}
        </span>
        <span id="ta_36" class="t s1_36">
          сануулж байгаарай. Мөн бага насны дүүгээ урд талын суудалд хэзээ ч
          битгийг суулгаарай.{" "}
        </span>
        <span id="tb_36" class="t s1_36">
          Машин зам гарахдаа хөгжим сонсож чихэвчтэй явж болохгүй. Хэрэв суух
          машины жолооч{" "}
        </span>
        <span id="tc_36" class="t s1_36">
          согтуу байвал тэр машинаар хэзээ ч битгий зорчоорой.{" "}
        </span>
        <span id="td_36" class="t s1_36">
          10 хүртэлх насны хүүхдийг{" "}
        </span>
        <span id="te_36" class="t s1_36">
          -{" "}
        </span>
        <span id="tf_36" class="t s1_36">
          Харгалзах хүнгүйгээр тээврийн хэрэгсэлд үлдээх{" "}
        </span>
        <span id="tg_36" class="t s1_36">
          -{" "}
        </span>
        <span id="th_36" class="t s1_36">
          Мотоциклийн арын суудалд тээвэрлэх{" "}
        </span>
        <span id="ti_36" class="t s1_36">
          -{" "}
        </span>
        <span id="tj_36" class="t s1_36">
          Харгалзах хүнгүйгээр замын хөдөлгөөнд явганаар оролцуулах{" "}
        </span>
        <span id="tk_36" class="t s1_36">
          -{" "}
        </span>
        <span id="tl_36" class="t s1_36">
          Унадаг дугуйгаар замын хөдөлгөөнд оролцох{" "}
        </span>
        <span id="tm_36" class="t s1_36">
          -{" "}
        </span>
        <span id="tn_36" class="t s1_36">
          Зориулалтын бус суудал дээр тээвэрлэх{" "}
        </span>
        <span id="to_36" class="t s1_36">
          ДӨРӨВ. БИЕ БЯЛДАР, АВЬЯАС ЧАДВАРЫГ ХӨГЖҮҮЛЭХ{" "}
        </span>
        <span id="tp_36" class="t s1_36">
          Хичээлээс гадуурх сургалтын ажлын энэхүү чиглэл, агуулгын хүрээнд
          танхимд эзэмшсэн{" "}
        </span>
        <span id="tq_36" class="t s1_36">
          мэдлэг, чадварыг бататгах, баяжуулах, бие бялдар, авьяасыг
          гүнзгийрүүлэн хөгжүүлэхэд{" "}
        </span>
        <span id="tr_36" class="t s1_36">
          чиглэсэн шинжлэх ухаан, танин мэдэхүйн болон урлаг, спортын мэдээлэл
          сурталчилгаа,{" "}
        </span>
        <span id="ts_36" class="t s1_36">
          уралдаан тэмцээн, уулзалт ярилцлага, өдөрлөг, үдэшлэг, төрөл бүрийн
          секц, дугуйлангийн{" "}
        </span>
        <span id="tt_36" class="t s1_36">
          үйл ажиллагааг сургуулийнхаа онцлог, нөөц боломжид тулгуурлан төлөвлөн
          хэрэгжүүлж{" "}
        </span>
        <span id="tu_36" class="t s1_36">
          болно. Ерөнхий боловсролын сургуулиудад энэ чиглэлээр IQ болон оюуны
          ухааныг{" "}
        </span>
        <span id="tv_36" class="t s5_36">
          2{" "}
        </span>
        <span id="tw_36" class="t s6_36">
          Замын хөдөлгөөний шинэчлэгдсэн дүрмийг 2018 оны арваннэгдүгээр сарын
          1-нээс мөрдсөн.{" "}
        </span>
        <span id="tx_36" class="t s1_36">
          4.1. Хүүхдийн оюун ухааныг хөгжүүлэхэд чиглэсэн шинжлэх ухаан-танин
          мэдэхүй,{" "}
        </span>
        <span id="ty_36" class="t s1_36">
          зохион бүтээх чиглэлийн үйл ажиллагааг дэмжин хэрэгжүүлэх{" "}
        </span>
        <span id="tz_36" class="t s1_36">
          4.2. Олон нийтийг хамарсан спортын арга хэмжээнд оролцох, эерэг жишээг
          сурталчлах,{" "}
        </span>
        <span id="t10_36" class="t s1_36">
          дэлгэрүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
