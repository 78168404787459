import React from "react";
;

export default function FiveFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_54{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_54{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_54{left:142px;bottom:1139px;letter-spacing:-0.25px;word-spacing:0.13px;}
#t4_54{left:219px;bottom:1102px;letter-spacing:-0.29px;word-spacing:0.17px;}
#t5_54{left:110px;bottom:1084px;letter-spacing:-0.14px;word-spacing:0.55px;}
#t6_54{left:110px;bottom:1066px;letter-spacing:-0.15px;word-spacing:-0.51px;}
#t7_54{left:110px;bottom:1048px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t8_54{left:110px;bottom:1030px;letter-spacing:-0.17px;word-spacing:1.66px;}
#t9_54{left:110px;bottom:1012px;letter-spacing:-0.16px;word-spacing:-0.19px;}
#ta_54{left:110px;bottom:994px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tb_54{left:153px;bottom:975px;}
#tc_54{left:180px;bottom:975px;letter-spacing:-0.17px;word-spacing:8.65px;}
#td_54{left:180px;bottom:957px;letter-spacing:-0.17px;}
#te_54{left:261px;bottom:957px;letter-spacing:-0.12px;}
#tf_54{left:153px;bottom:939px;}
#tg_54{left:180px;bottom:939px;letter-spacing:-0.14px;word-spacing:-1.4px;}
#th_54{left:180px;bottom:921px;letter-spacing:-0.13px;}
#ti_54{left:153px;bottom:903px;}
#tj_54{left:180px;bottom:903px;letter-spacing:-0.18px;word-spacing:6.92px;}
#tk_54{left:180px;bottom:885px;letter-spacing:-0.17px;}
#tl_54{left:153px;bottom:867px;}
#tm_54{left:180px;bottom:867px;letter-spacing:-0.17px;word-spacing:-1.66px;}
#tn_54{left:180px;bottom:848px;letter-spacing:-0.15px;word-spacing:0.06px;}
#to_54{left:190px;bottom:812px;letter-spacing:-0.27px;word-spacing:0.14px;}
#tp_54{left:110px;bottom:794px;letter-spacing:-0.18px;word-spacing:1.99px;}
#tq_54{left:345px;bottom:794px;letter-spacing:-0.14px;word-spacing:1.96px;}
#tr_54{left:110px;bottom:776px;letter-spacing:-0.16px;word-spacing:2.97px;}
#ts_54{left:110px;bottom:758px;letter-spacing:-0.16px;word-spacing:3.16px;}
#tt_54{left:110px;bottom:740px;letter-spacing:-0.17px;word-spacing:8.02px;}
#tu_54{left:110px;bottom:722px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tv_54{left:110px;bottom:703px;letter-spacing:-0.15px;word-spacing:0.18px;}
#tw_54{left:110px;bottom:685px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tx_54{left:115px;bottom:667px;letter-spacing:-0.19px;word-spacing:0.1px;}
#ty_54{left:110px;bottom:649px;letter-spacing:-0.16px;word-spacing:7.66px;}
#tz_54{left:110px;bottom:631px;letter-spacing:-0.16px;word-spacing:0.21px;}
#t10_54{left:110px;bottom:613px;letter-spacing:-0.17px;word-spacing:3.35px;}
#t11_54{left:110px;bottom:595px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t12_54{left:115px;bottom:576px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t13_54{left:110px;bottom:558px;letter-spacing:-0.14px;word-spacing:2.64px;}
#t14_54{left:110px;bottom:540px;letter-spacing:-0.38px;word-spacing:3.45px;}
#t15_54{left:200px;bottom:540px;letter-spacing:-0.17px;word-spacing:3.23px;}
#t16_54{left:110px;bottom:522px;letter-spacing:-0.18px;word-spacing:4.06px;}
#t17_54{left:110px;bottom:504px;letter-spacing:-0.13px;word-spacing:0.38px;}
#t18_54{left:110px;bottom:486px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t19_54{left:110px;bottom:468px;letter-spacing:-0.05px;}
#t1a_54{left:133px;bottom:468px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1b_54{left:110px;bottom:449px;letter-spacing:-0.14px;}
#t1c_54{left:620px;bottom:449px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1d_54{left:110px;bottom:431px;letter-spacing:-0.16px;word-spacing:-0.32px;}
#t1e_54{left:110px;bottom:413px;letter-spacing:-0.18px;word-spacing:-0.7px;}
#t1f_54{left:536px;bottom:413px;letter-spacing:-0.11px;word-spacing:-0.78px;}
#t1g_54{left:110px;bottom:395px;letter-spacing:-0.14px;word-spacing:-1.8px;}
#t1h_54{left:378px;bottom:395px;letter-spacing:-0.11px;word-spacing:-1.84px;}
#t1i_54{left:575px;bottom:395px;letter-spacing:-0.12px;word-spacing:-1.83px;}
#t1j_54{left:110px;bottom:377px;letter-spacing:-0.15px;word-spacing:2.5px;}
#t1k_54{left:110px;bottom:359px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1l_54{left:110px;bottom:341px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1m_54{left:121px;bottom:323px;}
#t1n_54{left:148px;bottom:323px;letter-spacing:-0.1px;word-spacing:1.56px;}
#t1o_54{left:376px;bottom:323px;letter-spacing:-0.18px;word-spacing:1.67px;}
#t1p_54{left:148px;bottom:304px;letter-spacing:-0.13px;word-spacing:2.21px;}
#t1q_54{left:148px;bottom:286px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1r_54{left:299px;bottom:286px;letter-spacing:-0.12px;}
#t1s_54{left:121px;bottom:268px;}
#t1t_54{left:148px;bottom:268px;letter-spacing:-0.14px;word-spacing:0.89px;}
#t1u_54{left:435px;bottom:268px;letter-spacing:-0.13px;word-spacing:0.91px;}
#t1v_54{left:148px;bottom:250px;letter-spacing:-0.15px;word-spacing:1.42px;}
#t1w_54{left:148px;bottom:232px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1x_54{left:121px;bottom:214px;}
#t1y_54{left:148px;bottom:214px;letter-spacing:-0.12px;word-spacing:2.18px;}
#t1z_54{left:430px;bottom:214px;letter-spacing:-0.17px;word-spacing:2.25px;}
#t20_54{left:148px;bottom:196px;letter-spacing:-0.19px;word-spacing:-0.84px;}
#t21_54{left:148px;bottom:177px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t22_54{left:121px;bottom:159px;}
#t23_54{left:148px;bottom:159px;letter-spacing:-0.16px;word-spacing:5.78px;}
#t24_54{left:435px;bottom:159px;letter-spacing:-0.19px;word-spacing:5.84px;}
#t25_54{left:148px;bottom:141px;letter-spacing:-0.15px;word-spacing:0.06px;}

.s0_54{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_54{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_54{font-size:17px;font-family:Arial-BoldMT_67;color:#375FB2;}
.s3_54{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s4_54{font-size:17px;font-family:Arial-BoldItalicMT_7b;color:#231F20;}
.s5_54{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}`}
        </style>

        <style id="fonts54" type="text/css">
          {`@font-face {
	font-family: Arial-BoldItalicMT_7b;
	src: url("fonts/Arial-BoldItalicMT_7b.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg54" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="54/54.svg"
          type="image/svg+xml"
          id="pdf54"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_54" class="t s0_54">
          52{" "}
        </span>
        <span id="t2_54" class="t s1_54">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_54" class="t s2_54">
          Хавсралт 1. ХГСА-Д СЭТГЭЛ ХАНАМЖИЙН СУДАЛГАА ХИЙХ АРГА АРГАЧЛАЛ{" "}
        </span>
        <span id="t4_54" class="t s1_54">
          НЭГ. СЭТГЭЛ ХАНАМЖИЙН СУДАЛГАА ЗОХИОН БАЙГУУЛАХ{" "}
        </span>
        <span id="t5_54" class="t s1_54">
          ХГҮА -н үр дүнг сайжруулах, үйл ажиллагааг төлөвлөх, агуулгыг
          тодруулах зорилгоор бид{" "}
        </span>
        <span id="t6_54" class="t s1_54">
          сурагчид, эцэг эх багш нараас сэтгэл ханамжийн судалгаагаар дүгнэлт
          хийж судалгааны үр{" "}
        </span>
        <span id="t7_54" class="t s1_54">
          дүнг тооцоолох боломжтой юм.{" "}
        </span>
        <span id="t8_54" class="t s1_54">
          Сэтгэл ханамжийн судалгааг эцэг, эх асран хамгаалагчид - суралцагчид
          (бага анги, дунд{" "}
        </span>
        <span id="t9_54" class="t s1_54">
          анги, ахлах анги), багш нараас авч болох ба энэ үйл ажиллагаанд
          хамрагдаж байгаа бусад{" "}
        </span>
        <span id="ta_54" class="t s1_54">
          хүмүүсээс авч болох юм. Сэтгэл ханамжийн судалгааг дараах зорилгоор
          хэрэглэнэ. Үүнд:{" "}
        </span>
        <span id="tb_54" class="t s3_54">
          •{" "}
        </span>
        <span id="tc_54" class="t s1_54">
          ХГСА-ын хүрээнд төлөвлөгдсөн үйл ажиллагааг хэрэгжүүлэхэд тулгамдсан{" "}
        </span>
        <span id="td_54" class="t s1_54">
          асуудлыг{" "}
        </span>
        <span id="te_54" class="t s1_54">
          илрүүлэх{" "}
        </span>
        <span id="tf_54" class="t s3_54">
          •{" "}
        </span>
        <span id="tg_54" class="t s1_54">
          ХГСА-ны явцад үндсэн чадваруудыг сурагчдын болон эцэг эхийн сэтгэл
          ханамжийг{" "}
        </span>
        <span id="th_54" class="t s1_54">
          илрүүлэх{" "}
        </span>
        <span id="ti_54" class="t s3_54">
          •{" "}
        </span>
        <span id="tj_54" class="t s1_54">
          ХГСА-г хэрэгжүүлэх явцад гарч буй өөрчлөлтүүдийг тодруулах, сайжруулах{" "}
        </span>
        <span id="tk_54" class="t s1_54">
          харьцуулах{" "}
        </span>
        <span id="tl_54" class="t s3_54">
          •{" "}
        </span>
        <span id="tm_54" class="t s1_54">
          Хөтөлбөрт хамрагдаж буй сурагчдын ялгаатай хэрэгцээг хангаж байгааг
          тодруулах,{" "}
        </span>
        <span id="tn_54" class="t s1_54">
          тэгш бус байдал байгаа эсэхийг шалгах, илрүүлэх{" "}
        </span>
        <span id="to_54" class="t s4_54">
          ХОЁР. СЭТГЭЛ ХАНАМЖИЙН СУДАЛГААНЫ ТУХАЙ ОЙЛГОЛТУУД{" "}
        </span>
        <span id="tp_54" class="t s1_54">
          Сэтгэл ханамжийн судалгаа{" "}
        </span>
        <span id="tq_54" class="t s1_54">
          нь аль ч салбарт маш өргөн хэрэглэгддэг үг хэллэг бөгөөд{" "}
        </span>
        <span id="tr_54" class="t s1_54">
          ХГСА-ны сургалтын талаарх санал болгож буй үйлчилгээ нь хэрэглэгчийн
          (сурагч, эцэг{" "}
        </span>
        <span id="ts_54" class="t s1_54">
          эх) хүлээлтэд хэрхэн нийцэж байна вэ гэдгийг тодорхойлдог өргөн
          ойлголт юм. Сэтгэл{" "}
        </span>
        <span id="tt_54" class="t s1_54">
          ханамжийн судалгаа нь тодорхой хэмжүүрийн дагуу тооцогддог ба
          сургалтын үйл{" "}
        </span>
        <span id="tu_54" class="t s1_54">
          ажиллагаагаа хөгжүүлэх шат дамжлагыг нарийн тодорхойлж өгдгөөрөө давуу
          талтай.{" "}
        </span>
        <span id="tv_54" class="t s1_54">
          Та сэтгэл ханамжийн судалгааг хийснээр дараах үр дүнг болон
          төлөвлөгөөг боловсруулан{" "}
        </span>
        <span id="tw_54" class="t s1_54">
          гаргах боломжтой. Үүнд:{" "}
        </span>
        <span id="tx_54" class="t s5_54">
          1. Давуу болон сул талаа мэдэх{" "}
        </span>
        <span id="ty_54" class="t s1_54">
          Сэтгэл ханамжийн судалгаанаас гарах эхний үр дүн бол тухайн сэдвийн
          талаарх{" "}
        </span>
        <span id="tz_54" class="t s1_54">
          сурагчдын мэдэрч буй сэтгэл ханамжийн түвшнийг үнэлэн гаргадаг. Эндээс
          сурагчдын үйл{" "}
        </span>
        <span id="t10_54" class="t s1_54">
          ажиллагаанд хамрагдсанаар сэтгэл ханамжтай байгаа болон сэтгэл
          ханамжгүй байгааг{" "}
        </span>
        <span id="t11_54" class="t s1_54">
          тодорхой болгоно.{" "}
        </span>
        <span id="t12_54" class="t s5_54">
          2. Ач холбогдол ба бодит байдлын зөрүүг тодорхойлох{" "}
        </span>
        <span id="t13_54" class="t s1_54">
          Сурагчдад олгож буй бидний сонгож авсан сэдэв тус бүр сурагчдын
          сонирхолд нийцэж{" "}
        </span>
        <span id="t14_54" class="t s1_54">
          байна уу,{" "}
        </span>
        <span id="t15_54" class="t s1_54">
          сурагчдын сонирхлыг татаж байна уу, бодож байсан хүлээж байсан үр дүнг{" "}
        </span>
        <span id="t16_54" class="t s1_54">
          илэрхийлж чадаж байна уу, тэдний хэрэгцээг нь хангаж байна уу, эцэг
          эхийн хүүхдээ{" "}
        </span>
        <span id="t17_54" class="t s1_54">
          хөгжүүлэх гэсэн сонирхолд хүлээж байгаа үр дүнд хэрхэн нийцэж байгаа
          болон нийцэхгүй{" "}
        </span>
        <span id="t18_54" class="t s1_54">
          байгааг тодорхойлж болно.{" "}
        </span>
        <span id="t19_54" class="t s5_54">
          3.{" "}
        </span>
        <span id="t1a_54" class="t s5_54">
          Үр дүнг тодруулах{" "}
        </span>
        <span id="t1b_54" class="t s1_54">
          Сэтгэл ханамжийн судалгааны үр дүнгээс бид үйл ажиллагааны{" "}
        </span>
        <span id="t1c_54" class="t s1_54">
          түвшинд гаргасан зардал{" "}
        </span>
        <span id="t1d_54" class="t s1_54">
          буюу багшийн гаргасан хөдөлмөр үр дүнтэй байсан эсэхийг үнэлэх
          боломжтой. Тухайлбал,{" "}
        </span>
        <span id="t1e_54" class="t s1_54">
          багш тодорхой нэг сэдэв үзүүлэлтэд ач холбогдол өгч,{" "}
        </span>
        <span id="t1f_54" class="t s1_54">
          цаг заваа их хэмжээгээр зарцуулсан{" "}
        </span>
        <span id="t1g_54" class="t s1_54">
          байж болно. Гэтэл тухайн сэдэв нь{" "}
        </span>
        <span id="t1h_54" class="t s1_54">
          тухайн үйл ажиллагааны{" "}
        </span>
        <span id="t1i_54" class="t s1_54">
          онцлогоос хамааран сурагчдын{" "}
        </span>
        <span id="t1j_54" class="t s1_54">
          хувьд тийм ч ач холбогдолтой сэдэв байгаагүй бөгөөд үйл ажиллагаанд
          нөлөөгүй байж{" "}
        </span>
        <span id="t1k_54" class="t s1_54">
          болох юм.{" "}
        </span>
        <span id="t1l_54" class="t s5_54">
          4. Судалгааны үр дүнд тулгуурлан төлөвлөгөөгөө сайжруулах{" "}
        </span>
        <span id="t1m_54" class="t s4_54">
          •{" "}
        </span>
        <span id="t1n_54" class="t s4_54">
          Өнөөгийн нөхцөл байдалд
        </span>
        <span id="t1o_54" class="t s1_54">
          : ХГСА-г зохион байгуулах явцад тохиолдож буй хүчин{" "}
        </span>
        <span id="t1p_54" class="t s1_54">
          зүйлийг илрүүлэн түүнийг хэрхэн хадгалж үлдэх болон бусдаас ялгарах
          давуу тал{" "}
        </span>
        <span id="t1q_54" class="t s1_54">
          болгон ашиглахад{" "}
        </span>
        <span id="t1r_54" class="t s1_54">
          анхаарах.{" "}
        </span>
        <span id="t1s_54" class="t s4_54">
          •{" "}
        </span>
        <span id="t1t_54" class="t s4_54">
          Богино хугацааны төлөвлөлтөд
        </span>
        <span id="t1u_54" class="t s1_54">
          : Сурагчид, эцэг эхийн хувьд маш чухал боловч{" "}
        </span>
        <span id="t1v_54" class="t s1_54">
          сэтгэл ханамжтай байлгаж чадахгүй байгаа үзүүлэлт тус бүрийг хэрхэн
          сайжруулах{" "}
        </span>
        <span id="t1w_54" class="t s1_54">
          талаар төлөвлөх.{" "}
        </span>
        <span id="t1x_54" class="t s4_54">
          •{" "}
        </span>
        <span id="t1y_54" class="t s4_54">
          Дунд хугацааны төлөвлөлтөд:{" "}
        </span>
        <span id="t1z_54" class="t s1_54">
          Сурагчид, эцэг эх, багшийн хувьд ач холбогдол{" "}
        </span>
        <span id="t20_54" class="t s1_54">
          бага боловч сэтгэл ханамжийг бий болгож байгаа үзүүлэлтийг хэрхэн ач
          холбогдолтой{" "}
        </span>
        <span id="t21_54" class="t s1_54">
          болгох ба сургах тал дээр анхаарч ажиллах.{" "}
        </span>
        <span id="t22_54" class="t s4_54">
          •{" "}
        </span>
        <span id="t23_54" class="t s4_54">
          Урт хугацааны төлөвлөлтөд:{" "}
        </span>
        <span id="t24_54" class="t s1_54">
          Төгсгөлд нь ач холбогдол, сэтгэл ханамжийн{" "}
        </span>
        <span id="t25_54" class="t s1_54">
          түвшинд хамгийн бага байгаа үзүүлэлтээ сайжруулахад анхаарна.{" "}
        </span>
      </div>
    </div>
  );
}
