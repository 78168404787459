import React from "react";
;

export default function TwentySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p27" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_27{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_27{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_27{left:110px;bottom:1139px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t4_27{left:110px;bottom:1121px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t5_27{left:153px;bottom:1096px;}
#t6_27{left:180px;bottom:1096px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t7_27{left:153px;bottom:1078px;}
#t8_27{left:180px;bottom:1078px;letter-spacing:-0.14px;word-spacing:0.48px;}
#t9_27{left:180px;bottom:1060px;letter-spacing:-0.08px;}
#ta_27{left:110px;bottom:1036px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tb_27{left:153px;bottom:1012px;}
#tc_27{left:180px;bottom:1012px;letter-spacing:-0.15px;word-spacing:0.05px;}
#td_27{left:153px;bottom:993px;}
#te_27{left:180px;bottom:993px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tf_27{left:153px;bottom:975px;}
#tg_27{left:180px;bottom:975px;letter-spacing:-0.12px;word-spacing:0.02px;}
#th_27{left:153px;bottom:957px;}
#ti_27{left:180px;bottom:957px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tj_27{left:110px;bottom:933px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tk_27{left:153px;bottom:909px;}
#tl_27{left:180px;bottom:909px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tm_27{left:153px;bottom:890px;}
#tn_27{left:180px;bottom:890px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_27{left:153px;bottom:872px;}
#tp_27{left:180px;bottom:872px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tq_27{left:110px;bottom:848px;letter-spacing:-0.15px;word-spacing:9.53px;}
#tr_27{left:110px;bottom:830px;letter-spacing:-0.17px;word-spacing:2.9px;}
#ts_27{left:110px;bottom:812px;letter-spacing:-0.14px;word-spacing:4.09px;}
#tt_27{left:110px;bottom:794px;letter-spacing:-0.13px;word-spacing:1.16px;}
#tu_27{left:110px;bottom:776px;letter-spacing:-0.15px;word-spacing:1.59px;}
#tv_27{left:110px;bottom:757px;letter-spacing:-0.14px;word-spacing:7.74px;}
#tw_27{left:110px;bottom:739px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tx_27{left:110px;bottom:703px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ty_27{left:110px;bottom:667px;letter-spacing:-0.11px;word-spacing:0.03px;}
#tz_27{left:186px;bottom:667px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t10_27{left:110px;bottom:649px;letter-spacing:-0.14px;word-spacing:2.93px;}
#t11_27{left:110px;bottom:630px;letter-spacing:-0.18px;word-spacing:0.71px;}
#t12_27{left:110px;bottom:612px;letter-spacing:-0.14px;word-spacing:4.81px;}
#t13_27{left:110px;bottom:594px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_27{left:110px;bottom:558px;letter-spacing:-0.16px;word-spacing:3.89px;}
#t15_27{left:752px;bottom:558px;letter-spacing:-0.08px;word-spacing:3.84px;}
#t16_27{left:110px;bottom:540px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t17_27{left:110px;bottom:504px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t18_27{left:153px;bottom:479px;}
#t19_27{left:180px;bottom:479px;letter-spacing:-0.12px;word-spacing:-0.43px;}
#t1a_27{left:180px;bottom:461px;letter-spacing:-0.11px;}
#t1b_27{left:153px;bottom:443px;}
#t1c_27{left:180px;bottom:443px;letter-spacing:-0.13px;word-spacing:3.8px;}
#t1d_27{left:180px;bottom:425px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1e_27{left:153px;bottom:407px;}
#t1f_27{left:180px;bottom:407px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1g_27{left:153px;bottom:389px;}
#t1h_27{left:180px;bottom:389px;letter-spacing:-0.15px;word-spacing:5.05px;}
#t1i_27{left:180px;bottom:370px;letter-spacing:-0.23px;}
#t1j_27{left:153px;bottom:352px;}
#t1k_27{left:180px;bottom:352px;letter-spacing:-0.14px;word-spacing:2.31px;}
#t1l_27{left:180px;bottom:334px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1m_27{left:153px;bottom:316px;}
#t1n_27{left:180px;bottom:316px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1o_27{left:110px;bottom:292px;letter-spacing:-0.14px;word-spacing:2.03px;}
#t1p_27{left:110px;bottom:274px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_27{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_27{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_27{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_27{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s4_27{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>
        <style id="fonts27" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg27" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="27/27.svg"
          type="image/svg+xml"
          id="pdf27"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_27" class="t s0_27">
          25{" "}
        </span>
        <span id="t2_27" class="t s1_27">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_27" class="t s1_27">
          2.3. Үйл ажиллагаа:{" "}
        </span>
        <span id="t4_27" class="t s2_27">
          Бага боловсролд{" "}
        </span>
        <span id="t5_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t6_27" class="t s1_27">
          Сурагч тохиолдож болзошгүй аюул, ослын төсөөлөлтэй болох{" "}
        </span>
        <span id="t7_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t8_27" class="t s1_27">
          Урьдчилан сэргийлэх, өөрийгөө хамгаалах, шийдвэр гаргах оновчтой үйл
          хөдлөл{" "}
        </span>
        <span id="t9_27" class="t s1_27">
          хийх{" "}
        </span>
        <span id="ta_27" class="t s2_27">
          Суурь боловсролд{" "}
        </span>
        <span id="tb_27" class="t s1_27">
          -{" "}
        </span>
        <span id="tc_27" class="t s1_27">
          Сурагч болзошгүй аюулт үзэгдэл, ослыг төсөөлөх{" "}
        </span>
        <span id="td_27" class="t s1_27">
          -{" "}
        </span>
        <span id="te_27" class="t s1_27">
          Аюулгүй байдлын бэлтгэлтэй байх, өөрийгөө хамгаалах{" "}
        </span>
        <span id="tf_27" class="t s1_27">
          -{" "}
        </span>
        <span id="tg_27" class="t s1_27">
          Бусдад туслах, анхны тусламж үзүүлэх{" "}
        </span>
        <span id="th_27" class="t s1_27">
          -{" "}
        </span>
        <span id="ti_27" class="t s1_27">
          Аюулгүй зөв сонголт бүхий үйл хөдлөл хийж чаддаг болох{" "}
        </span>
        <span id="tj_27" class="t s2_27">
          Бүрэн дунд боловсрол{" "}
        </span>
        <span id="tk_27" class="t s1_27">
          -{" "}
        </span>
        <span id="tl_27" class="t s1_27">
          Сурагч болзошгүй аюулт үзэгдэл, ослыг төсөөлж хариу арга хэмжээ авах{" "}
        </span>
        <span id="tm_27" class="t s1_27">
          -{" "}
        </span>
        <span id="tn_27" class="t s1_27">
          Анхны тусламж үзүүлэх, сайн дурын үйл ажиллагаанд оролцох{" "}
        </span>
        <span id="to_27" class="t s1_27">
          -{" "}
        </span>
        <span id="tp_27" class="t s1_27">
          Дасан зохицож амьдрах чадвартай, амьдралын зөв дадал хэвшилтэй болох{" "}
        </span>
        <span id="tq_27" class="t s1_27">
          Аюулгүй амьдрах ухааны сургалтын хөтөлбөрийн агуулгын ерөнхий
          ойлголтуудыг{" "}
        </span>
        <span id="tr_27" class="t s1_27">
          танхимд зохион байгуулж болох ба харин гамшгаас хамгаалах дадлага
          сургуулилалтыг{" "}
        </span>
        <span id="ts_27" class="t s1_27">
          танхимаас гадуур байгаль, нийгэм, соёлын орчинд хэрэгжүүлэх бүрэн
          боломжтой юм.{" "}
        </span>
        <span id="tt_27" class="t s1_27">
          Аюулгүй амьдрах ухааны сургалтын хөтөлбөрийн агуулгыг хэрэгжүүлэхдээ
          бусад хичээл,{" "}
        </span>
        <span id="tu_27" class="t s1_27">
          судлагдахуутай интеграцчилан хэрэгжүүлэх агуулга, хичээлээс гадуурх
          сургалтын ажлын{" "}
        </span>
        <span id="tv_27" class="t s1_27">
          хүрээнд хэрэгжүүлэх боломжтой агуулгыг судалж, сургуулийнхаа менежмент
          болон{" "}
        </span>
        <span id="tw_27" class="t s1_27">
          гамшгаас хамгаалах төлөвлөгөөтэй уялдуулан зохион байгуулна.{" "}
        </span>
        <span id="tx_27" class="t s1_27">
          2.3.Арга зүй{" "}
        </span>
        <span id="ty_27" class="t s3_27">
          Загвар 1:{" "}
        </span>
        <span id="tz_27" class="t s4_27">
          Бага боловсролын түвшинд /3-5 анги/{" "}
        </span>
        <span id="t10_27" class="t s1_27">
          Үйл ажиллагааны нэр: Гамшгийн үед хоол хүнс, ус зэрэг наад захын
          хэрэглээний зүйл{" "}
        </span>
        <span id="t11_27" class="t s1_27">
          дутагдах нөхцөл байдал бий болдог. Энэ үед хэрэглэх хүн нэг бүрийн
          үүргэвчинд юу байх{" "}
        </span>
        <span id="t12_27" class="t s1_27">
          талаар эргэцүүлэн бодуулах цаашид гэр бүлийнхэнтэйгээ ярилцах,
          бодитоор бэлтгэл{" "}
        </span>
        <span id="t13_27" class="t s1_27">
          ажлыг хангуулахад тустай үйл ажиллагаа{" "}
        </span>
        <span id="t14_27" class="t s1_27">
          Зорилго: Гамшгийн үед хэрэглэх цүнх (үүргэвч)-д юу байх талаар
          эргэцүүлэх{" "}
        </span>
        <span id="t15_27" class="t s2_27">
          (хүн нэг{" "}
        </span>
        <span id="t16_27" class="t s2_27">
          бүрийн үүргэвч){" "}
        </span>
        <span id="t17_27" class="t s2_27">
          Сурагчдын хийх үйл ажиллагаа:{" "}
        </span>
        <span id="t18_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t19_27" class="t s1_27">
          Өглөөнөөс орой унтах хүртэлх нэг бүтэн өдрийн турш хэрэглэсэн зүйлийг
          жагсаан{" "}
        </span>
        <span id="t1a_27" class="t s1_27">
          бичих{" "}
        </span>
        <span id="t1b_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t1c_27" class="t s1_27">
          Жагсаан бичсэн зүйлийг хэрэглэж чадахгүй нөхцөлд ямар бэрхшээл
          тулгарах{" "}
        </span>
        <span id="t1d_27" class="t s1_27">
          талаар эргэцүүлэн бодох, санаагаа хуваалцах{" "}
        </span>
        <span id="t1e_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t1f_27" class="t s1_27">
          Хүн нэг бүрийн үүргэвчинд зайлшгүй байх 5 зүйлийг эрэмбэлэн тэмдэглэх{" "}
        </span>
        <span id="t1g_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t1h_27" class="t s1_27">
          Бусдадаа эдгээр 5 зүйлийг яагаад үүргэвчинд байхаар сонгосон
          шалтгааныг{" "}
        </span>
        <span id="t1i_27" class="t s1_27">
          тайлбарлах{" "}
        </span>
        <span id="t1j_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t1k_27" class="t s1_27">
          Өөрийн сонголтоо бусдынхаа сонгосон зүйлстэй харьцуулан бүх хүний
          нийтлэг{" "}
        </span>
        <span id="t1l_27" class="t s1_27">
          хэрэглэдэг зүйл, хувь хүнд хэрэгтэй зүйл байдаг эсэх талаар ойлголттой
          болох{" "}
        </span>
        <span id="t1m_27" class="t s1_27">
          -{" "}
        </span>
        <span id="t1n_27" class="t s1_27">
          3 өдрийн хүнс, усны хэмжээг тооцоолон гаргах{" "}
        </span>
        <span id="t1o_27" class="t s2_27">
          Багшийн анхаарах зүйл: Сурагчид өөрийн өдөр тутмын хэрэглээг харгалзан
          3 өдрийн{" "}
        </span>
        <span id="t1p_27" class="t s2_27">
          хүнс, усны хэмжээг тооцоолон гаргаж байгаа эсэхийг анхаарч чиглүүлнэ.{" "}
        </span>
      </div>
    </div>
  );
}
