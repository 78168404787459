import React from "react";
;

export default function Nineteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_19{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_19{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_19{left:180px;bottom:1138px;letter-spacing:-0.17px;word-spacing:-0.12px;}
#t4_19{left:180px;bottom:1120px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t5_19{left:153px;bottom:1096px;}
#t6_19{left:180px;bottom:1096px;letter-spacing:-0.12px;word-spacing:3.23px;}
#t7_19{left:180px;bottom:1078px;letter-spacing:-0.13px;word-spacing:1.4px;}
#t8_19{left:180px;bottom:1060px;letter-spacing:-0.18px;}
#t9_19{left:153px;bottom:1036px;}
#ta_19{left:180px;bottom:1036px;letter-spacing:-0.16px;word-spacing:1.93px;}
#tb_19{left:180px;bottom:1017px;letter-spacing:-0.13px;word-spacing:0.09px;}
#tc_19{left:180px;bottom:999px;letter-spacing:-0.12px;word-spacing:-0.73px;}
#td_19{left:180px;bottom:981px;letter-spacing:-0.15px;word-spacing:-0.2px;}
#te_19{left:153px;bottom:957px;}
#tf_19{left:180px;bottom:957px;letter-spacing:-0.08px;}
#tg_19{left:240px;bottom:957px;letter-spacing:-0.12px;}
#th_19{left:310px;bottom:957px;letter-spacing:-0.09px;}
#ti_19{left:425px;bottom:957px;letter-spacing:-0.09px;}
#tj_19{left:480px;bottom:957px;letter-spacing:-0.1px;}
#tk_19{left:586px;bottom:957px;letter-spacing:-0.2px;}
#tl_19{left:694px;bottom:957px;letter-spacing:-0.09px;}
#tm_19{left:765px;bottom:957px;letter-spacing:-0.16px;}
#tn_19{left:180px;bottom:939px;letter-spacing:-0.17px;word-spacing:1.7px;}
#to_19{left:180px;bottom:921px;letter-spacing:-0.14px;word-spacing:0.71px;}
#tp_19{left:180px;bottom:902px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tq_19{left:153px;bottom:878px;}
#tr_19{left:180px;bottom:878px;letter-spacing:-0.13px;word-spacing:1.57px;}
#ts_19{left:180px;bottom:860px;letter-spacing:-0.2px;word-spacing:-0.01px;}
#tt_19{left:180px;bottom:842px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tu_19{left:153px;bottom:818px;}
#tv_19{left:180px;bottom:818px;letter-spacing:-0.15px;word-spacing:4.39px;}
#tw_19{left:180px;bottom:800px;letter-spacing:-0.13px;word-spacing:-0.54px;}
#tx_19{left:180px;bottom:781px;letter-spacing:-0.14px;word-spacing:7.44px;}
#ty_19{left:180px;bottom:763px;letter-spacing:-0.12px;word-spacing:2.17px;}
#tz_19{left:180px;bottom:745px;letter-spacing:-0.15px;word-spacing:1.37px;}
#t10_19{left:180px;bottom:727px;letter-spacing:-0.13px;word-spacing:-0.61px;}
#t11_19{left:180px;bottom:709px;letter-spacing:-0.09px;}
#t12_19{left:110px;bottom:685px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t13_19{left:153px;bottom:660px;}
#t14_19{left:180px;bottom:660px;letter-spacing:-0.16px;word-spacing:4.78px;}
#t15_19{left:180px;bottom:642px;letter-spacing:-0.19px;word-spacing:0.42px;}
#t16_19{left:180px;bottom:624px;letter-spacing:-0.13px;word-spacing:-0.52px;}
#t17_19{left:180px;bottom:606px;letter-spacing:-0.1px;word-spacing:-1.5px;}
#t18_19{left:180px;bottom:588px;letter-spacing:-0.1px;word-spacing:0.34px;}
#t19_19{left:180px;bottom:570px;letter-spacing:-0.16px;word-spacing:3.11px;}
#t1a_19{left:180px;bottom:552px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1b_19{left:153px;bottom:527px;}
#t1c_19{left:180px;bottom:527px;letter-spacing:-0.15px;word-spacing:3.58px;}
#t1d_19{left:180px;bottom:509px;letter-spacing:-0.16px;word-spacing:-0.71px;}
#t1e_19{left:180px;bottom:491px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1f_19{left:153px;bottom:467px;}
#t1g_19{left:180px;bottom:467px;letter-spacing:-0.13px;word-spacing:2.06px;}
#t1h_19{left:180px;bottom:449px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1i_19{left:153px;bottom:424px;}
#t1j_19{left:180px;bottom:424px;letter-spacing:-0.16px;word-spacing:2.81px;}
#t1k_19{left:180px;bottom:406px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1l_19{left:153px;bottom:382px;}
#t1m_19{left:180px;bottom:382px;letter-spacing:-0.16px;word-spacing:2.97px;}
#t1n_19{left:180px;bottom:364px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1o_19{left:166px;bottom:315px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t1p_19{left:110px;bottom:194px;letter-spacing:-0.11px;word-spacing:7.81px;}
#t1q_19{left:110px;bottom:176px;letter-spacing:-0.14px;word-spacing:1.11px;}
#t1r_19{left:110px;bottom:158px;letter-spacing:-0.14px;word-spacing:1.11px;}
#t1s_19{left:110px;bottom:140px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1t_19{left:144px;bottom:275px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1u_19{left:144px;bottom:250px;letter-spacing:-0.13px;word-spacing:3.13px;}
#t1v_19{left:144px;bottom:232px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_19{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_19{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_19{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s3_19{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}`}
        </style>
        <style id="fonts19" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg19" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="19/19.svg"
          type="image/svg+xml"
          id="pdf19"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_19" class="t s0_19">
          17{" "}
        </span>
        <span id="t2_19" class="t s1_19">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_19" class="t s1_19">
          хэдий ч заримдаа гаднын болон орчин тойрны хүмүүсийн нөлөөнд автдаг,
          аялдан{" "}
        </span>
        <span id="t4_19" class="t s1_19">
          дагалдах хандлагатай байгааг харуулж байна.{" "}
        </span>
        <span id="t5_19" class="t s2_19">
          •{" "}
        </span>
        <span id="t6_19" class="t s1_19">
          Эцэг эхийн ойр дотнын хүмүүсийн дэмжлэгт эргэлздэггүй ч заримдаа эцэг
          эх,{" "}
        </span>
        <span id="t7_19" class="t s1_19">
          асран хамгаалагчтайгаа нээлттэй, чөлөөтэй санаа бодлоо илэрхийлж
          чаддаггүй{" "}
        </span>
        <span id="t8_19" class="t s1_19">
          байна.{" "}
        </span>
        <span id="t9_19" class="t s2_19">
          •{" "}
        </span>
        <span id="ta_19" class="t s1_19">
          Харилцааны хувьд, 15-17 настай хүүхдүүд бусдын сэтгэл санааг илүү
          ойлгодог,{" "}
        </span>
        <span id="tb_19" class="t s1_19">
          бусадтай харилцах дуртай болсон ч заримдаа илэн далангүй, нээлттэй
          харилцан{" "}
        </span>
        <span id="tc_19" class="t s1_19">
          ярианд орж чаддаггүй, ихэнх тохиолдолд тогтсон жишиг зааврыг дагах,
          хариуцсан{" "}
        </span>
        <span id="td_19" class="t s1_19">
          ажилдаа хариуцлагатай байхаас илүү бусадтай хамтрахыг илүү эрмэлздэг
          байна.{" "}
        </span>
        <span id="te_19" class="t s2_19">
          •{" "}
        </span>
        <span id="tf_19" class="t s1_19">
          15-17{" "}
        </span>
        <span id="tg_19" class="t s1_19">
          настай{" "}
        </span>
        <span id="th_19" class="t s1_19">
          хүүхдүүдийн{" "}
        </span>
        <span id="ti_19" class="t s1_19">
          дунд{" "}
        </span>
        <span id="tj_19" class="t s1_19">
          амьдралын{" "}
        </span>
        <span id="tk_19" class="t s1_19">
          зорилготой,{" "}
        </span>
        <span id="tl_19" class="t s1_19">
          хувийн{" "}
        </span>
        <span id="tm_19" class="t s1_19">
          зохион{" "}
        </span>
        <span id="tn_19" class="t s1_19">
          байгуулалттай, хариуцлагатай, дүрэм журмыг баримталдаг, өөрийн
          амьдралдаа{" "}
        </span>
        <span id="to_19" class="t s1_19">
          урам зоригтой, баяр баясгалантай, хэм хэмжээг зөрчихгүй байхыг
          анхаардаг бие{" "}
        </span>
        <span id="tp_19" class="t s1_19">
          хүний онцлог шинжүүд түлхүү ажиглагдаж байна.{" "}
        </span>
        <span id="tq_19" class="t s2_19">
          •{" "}
        </span>
        <span id="tr_19" class="t s1_19">
          15-аас дээш насны хүүхдүүдийн хувьд олон нийтийн газар биеэ зөв авч
          явахад{" "}
        </span>
        <span id="ts_19" class="t s1_19">
          анхаардаг, дүрэм журам баримтлахыг хичээдэг, ёс суртахууны хэм хэмжээг
          дагаж{" "}
        </span>
        <span id="tt_19" class="t s1_19">
          мөрддөг байна.{" "}
        </span>
        <span id="tu_19" class="t s2_19">
          •{" "}
        </span>
        <span id="tv_19" class="t s1_19">
          Хүүхдүүдийн нийгэмшлийн хөгжилд унтдаг цаг, эцэг эхийн тусалдаг
          оролцоо,{" "}
        </span>
        <span id="tw_19" class="t s1_19">
          багшийн өгч буй гэрийн даалгавар, гэр бүлийн харилцаа, эцэг эхийн
          боловсролын{" "}
        </span>
        <span id="tx_19" class="t s1_19">
          түвшин, өрхийн орлого эерэг нөлөө үзүүлдэг байна. Жишээлбэл, эцэг эх,{" "}
        </span>
        <span id="ty_19" class="t s1_19">
          асран хамгаалагчид хүүхдүүдээ урамшуулж, сайн хандах тусам эцэг эх,
          хүүхэд{" "}
        </span>
        <span id="tz_19" class="t s1_19">
          хоорондын итгэлцэл нэмэгдэн, эцэг эх, хүүхэд хоорондоо нээлттэй
          харилцаатай{" "}
        </span>
        <span id="t10_19" class="t s1_19">
          болж улмаар хүүхдийн өөртөө итгэх итгэл, өөрийн үнэлэмж, бие хүний
          төлөвшилд{" "}
        </span>
        <span id="t11_19" class="t s1_19">
          эерэг нөлөө үзүүлдэг байна.{" "}
        </span>
        <span id="t12_19" class="t s3_19">
          Бие бялдрын хөгжлийн онцлог:{" "}
        </span>
        <span id="t13_19" class="t s2_19">
          •{" "}
        </span>
        <span id="t14_19" class="t s1_19">
          Хүүхдүүдийн биеийн ерөнхий хөгжил тухайлбал толгойн тойрог, биеийн
          жин,{" "}
        </span>
        <span id="t15_19" class="t s1_19">
          бүсэлхийн тойрог, цээжний тойрог, биеийн өндөр харьцангуй бусад насаа
          бодвол{" "}
        </span>
        <span id="t16_19" class="t s1_19">
          тогтвортой байдаг байна. Биеийн жин нас нэмэгдэх тусам охидын хувьд
          1.38- 1.35{" "}
        </span>
        <span id="t17_19" class="t s1_19">
          кг аар, хөвгүүдийн хувьд 2.29-2.1 кг аар нэмэгдсэн ба 15 насны
          хөвгүүдийн 8.4 хувь,{" "}
        </span>
        <span id="t18_19" class="t s1_19">
          16 насны хөвгүүдийн 15.4 хувь, 17 насны хөвгүүдийн 15.0 хувь нь
          илүүдэл болон{" "}
        </span>
        <span id="t19_19" class="t s1_19">
          таргалалтын 1 ба 2-р хэлбэрт байгаа нь биеийн жингийн илүүдэлд
          анхаарлаа{" "}
        </span>
        <span id="t1a_19" class="t s1_19">
          хандуулах хэрэгтэй байна.{" "}
        </span>
        <span id="t1b_19" class="t s2_19">
          •{" "}
        </span>
        <span id="t1c_19" class="t s1_19">
          15-17 насанд хөвгүүдийн баруун, зүүн гарын атгах хүч ялгаа ихтэй
          байгаа ба{" "}
        </span>
        <span id="t1d_19" class="t s1_19">
          охидын гарын атгах хүч тогтвортой байгаа ба хөвгүүдийн гарын атгах хүч
          2.67-1.58{" "}
        </span>
        <span id="t1e_19" class="t s1_19">
          кг -аар нэмэгддэг байна.{" "}
        </span>
        <span id="t1f_19" class="t s2_19">
          •{" "}
        </span>
        <span id="t1g_19" class="t s1_19">
          Хөвгүүдийн хувьд нас нэмэгдэх тусам хурдны чанар сайжирсан байна.
          Охидын{" "}
        </span>
        <span id="t1h_19" class="t s1_19">
          хувьд хөвгүүдээс хурд удаан байна.{" "}
        </span>
        <span id="t1i_19" class="t s2_19">
          •{" "}
        </span>
        <span id="t1j_19" class="t s1_19">
          Хүүхдүүдийн уян хатан чадварын хувьд охидоос хөвгүүд уян хатан бус
          байгаа{" "}
        </span>
        <span id="t1k_19" class="t s1_19">
          учир хөвгүүдийн энэ чадварыг дэмжих шаардлагатай байна.{" "}
        </span>
        <span id="t1l_19" class="t s2_19">
          •{" "}
        </span>
        <span id="t1m_19" class="t s1_19">
          Авхаалж самбаатай чадвар нь охидын хувьд тогтвортой байгаа ба
          хөвгүүдийн{" "}
        </span>
        <span id="t1n_19" class="t s1_19">
          хувьд хурд 16 насанд хурд нэмэгдсэн байна.{" "}
        </span>
        <span id="t1o_19" class="t s1_19">
          НЭГ. ГЭР БҮЛИЙН ХҮРЭЭНД ОЛГОХ ХҮМҮҮЖИЛ, ТӨЛӨВШЛИЙГ ДЭМЖИХ{" "}
        </span>
        <span id="t1p_19" class="t s1_19">
          1.1.1.Зорилго: Хүүхэд гэр бүлдээ хүмүүжих, хүүхдийн сонирхлыг дэмжих,
          хүүхдийг{" "}
        </span>
        <span id="t1q_19" class="t s1_19">
          хөгжүүлэх гол үндэс нь болох гэр бүл болон сургуулийн орчинд дүрэм
          журам баримтлах,{" "}
        </span>
        <span id="t1r_19" class="t s1_19">
          харилцан ойлголцох, өөрийн болон бусдын сэтгэлийн хөдөлгөөнийг таних,
          хянах, ойлгох,{" "}
        </span>
        <span id="t1s_19" class="t s1_19">
          буруу зүйлд уучлал гуйх зэрэг эерэг хандлагыг төлөвшүүлэхэд оршино.{" "}
        </span>
        <span id="t1t_19" class="t s1_19">
          1.1. Хүмүүжлийн үйл ажиллагаанд гэр бүлийн оролцоог нэмэгдүүлэх{" "}
        </span>
        <span id="t1u_19" class="t s1_19">
          1.2. Хүүхэд хүмүүжүүлэх гэр бүлийн уламжлалт соёл, ёс суртахууны үнэт
          зүйлд{" "}
        </span>
        <span id="t1v_19" class="t s1_19">
          тулгуурлан, гэр бүл дэх хүмүүжлийн чиглэлээрх ажилд дэмжлэг үзүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
