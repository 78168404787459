import React from "react";
;

export default function FiveEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_58{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_58{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_58{left:385px;bottom:1157px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t4_58{left:110px;bottom:1139px;letter-spacing:-0.16px;word-spacing:7.29px;}
#t5_58{left:110px;bottom:1121px;letter-spacing:-0.16px;word-spacing:5.71px;}
#t6_58{left:110px;bottom:1102px;letter-spacing:-0.17px;word-spacing:4.39px;}
#t7_58{left:110px;bottom:1084px;letter-spacing:-0.13px;word-spacing:-1.57px;}
#t8_58{left:110px;bottom:1066px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_58{left:153px;bottom:1048px;letter-spacing:-0.14px;word-spacing:4.45px;}
#ta_58{left:110px;bottom:1030px;letter-spacing:-0.12px;word-spacing:1.9px;}
#tb_58{left:110px;bottom:1012px;letter-spacing:-0.12px;word-spacing:3.17px;}
#tc_58{left:110px;bottom:994px;letter-spacing:-0.15px;word-spacing:0.05px;}
#td_58{left:110px;bottom:975px;letter-spacing:-0.16px;word-spacing:2.64px;}
#te_58{left:110px;bottom:957px;letter-spacing:-0.14px;word-spacing:1.46px;}
#tf_58{left:110px;bottom:939px;letter-spacing:-0.14px;word-spacing:-1.51px;}
#tg_58{left:110px;bottom:921px;letter-spacing:-0.1px;word-spacing:-0.93px;}
#th_58{left:110px;bottom:903px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ti_58{left:110px;bottom:867px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tj_58{left:153px;bottom:848px;letter-spacing:-0.14px;word-spacing:4.45px;}
#tk_58{left:179px;bottom:830px;letter-spacing:-0.06px;}
#tl_58{left:319px;bottom:830px;letter-spacing:-0.25px;word-spacing:0.1px;}
#tm_58{left:447px;bottom:830px;letter-spacing:-0.05px;}
#tn_58{left:559px;bottom:830px;letter-spacing:-0.18px;}
#to_58{left:591px;bottom:830px;letter-spacing:-0.05px;}
#tp_58{left:731px;bottom:830px;letter-spacing:-0.15px;}
#tq_58{left:275px;bottom:812px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tr_58{left:116px;bottom:771px;letter-spacing:0.03px;word-spacing:0.13px;}
#ts_58{left:116px;bottom:759px;letter-spacing:0.04px;word-spacing:0.11px;}
#tt_58{left:116px;bottom:734px;letter-spacing:0.11px;word-spacing:0.03px;}
#tu_58{left:116px;bottom:722px;letter-spacing:0.11px;word-spacing:0.03px;}
#tv_58{left:116px;bottom:707px;letter-spacing:0.1px;word-spacing:0.05px;}
#tw_58{left:116px;bottom:685px;letter-spacing:0.12px;word-spacing:0.03px;}
#tx_58{left:483px;bottom:771px;letter-spacing:0.12px;word-spacing:0.03px;}
#ty_58{left:483px;bottom:759px;letter-spacing:0.11px;word-spacing:0.05px;}
#tz_58{left:490px;bottom:734px;letter-spacing:0.08px;word-spacing:0.06px;}
#t10_58{left:490px;bottom:715px;letter-spacing:0.06px;word-spacing:0.07px;}
#t11_58{left:498px;bottom:695px;letter-spacing:0.08px;word-spacing:0.07px;}
#t12_58{left:490px;bottom:675px;letter-spacing:0.04px;word-spacing:0.1px;}
#t13_58{left:116px;bottom:638px;letter-spacing:0.07px;word-spacing:0.08px;}
#t14_58{left:116px;bottom:622px;letter-spacing:0.12px;word-spacing:10.44px;}
#t15_58{left:116px;bottom:610px;letter-spacing:0.11px;word-spacing:2.81px;}
#t16_58{left:116px;bottom:598px;letter-spacing:0.1px;word-spacing:-0.84px;}
#t17_58{left:116px;bottom:586px;letter-spacing:0.06px;}
#t18_58{left:167px;bottom:586px;letter-spacing:0.02px;}
#t19_58{left:221px;bottom:586px;letter-spacing:0.13px;}
#t1a_58{left:296px;bottom:586px;letter-spacing:0.13px;}
#t1b_58{left:364px;bottom:586px;letter-spacing:0.11px;}
#t1c_58{left:413px;bottom:586px;letter-spacing:0.13px;}
#t1d_58{left:116px;bottom:574px;letter-spacing:0.08px;word-spacing:8.14px;}
#t1e_58{left:116px;bottom:561px;letter-spacing:0.11px;word-spacing:1.14px;}
#t1f_58{left:116px;bottom:549px;letter-spacing:0.13px;}
#t1g_58{left:483px;bottom:638px;letter-spacing:0.14px;word-spacing:0.01px;}
#t1h_58{left:483px;bottom:619px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1i_58{left:483px;bottom:606px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1j_58{left:483px;bottom:592px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1k_58{left:483px;bottom:575px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1l_58{left:171px;bottom:516px;letter-spacing:0.04px;word-spacing:0.09px;}
#t1m_58{left:171px;bottom:498px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1n_58{left:171px;bottom:479px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1o_58{left:171px;bottom:461px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1p_58{left:171px;bottom:443px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1q_58{left:171px;bottom:424px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1r_58{left:171px;bottom:406px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1s_58{left:171px;bottom:388px;letter-spacing:0.15px;}
#t1t_58{left:171px;bottom:369px;letter-spacing:0.13px;word-spacing:0.01px;}

.s0_58{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_58{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_58{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_58{font-size:15px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_58{font-size:15px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>

        <style id="fonts58" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg58" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="58/58.svg"
          type="image/svg+xml"
          id="pdf58"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_58" class="t s0_58">
          56{" "}
        </span>
        <span id="t2_58" class="t s1_58">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_58" class="t s2_58">
          Хавсралт 2. ХГСА -ын төлөвлөлтийн загвар жишээнүүд{" "}
        </span>
        <span id="t4_58" class="t s1_58">
          Сургууль ХГСА-ын төлөвлөгөөтэй байна. Энэхүү төлөвлөгөө нь сургуулийн
          жилийн{" "}
        </span>
        <span id="t5_58" class="t s1_58">
          төлөвлөгөөний тодорхой хэсэг байна. Төлөвлөгөөг АУБ, судалгаа
          менежментийн баг{" "}
        </span>
        <span id="t6_58" class="t s1_58">
          (Захирал, Сургалтын менежер, Нийгмийн ажилтан, Дотуур байрны багш, Эмч
          г.м)-тай{" "}
        </span>
        <span id="t7_58" class="t s1_58">
          хамтран ажиллаж сурагчдын хэрэгцээ сонирхол, нөхцөл байдал, багш
          ажилтан, эцэг эхчүүд,{" "}
        </span>
        <span id="t8_58" class="t s1_58">
          олон нийтийн байгууллага, иргэдийн саналыг нэгтгэн харилцан уялдаатай
          боловсрууна.{" "}
        </span>
        <span id="t9_58" class="t s1_58">
          1. Арга зүй{" "}
        </span>
        <span id="ta_58" class="t s1_58">
          Төлөвлөлтийг сургуулийн, ангийн, секц дугуйлангийн, сурагчдын
          зөвлөлийн, сайн дурын{" "}
        </span>
        <span id="tb_58" class="t s1_58">
          бүлгүүдийн, эцэг эхийн зөвлөлийн гэх мэт түвшинд хөтөлбөрийн зорилгыг
          хэрэгжүүлэх{" "}
        </span>
        <span id="tc_58" class="t s1_58">
          зорилтуудын хүрээнд боловсруулж болно.{" "}
        </span>
        <span id="td_58" class="t s1_58">
          ХГСА-н төлөвлөлт гаргахдаа зорилтуудыг интеграцчилах байдлаар хийж
          болно. Жишээ{" "}
        </span>
        <span id="te_58" class="t s1_58">
          нь: Эрүүл аюулгүй амьдрах арга ухаанд сургах, Болзошгүй эрсдэл,
          зөрчлөөс урьдчилан{" "}
        </span>
        <span id="tf_58" class="t s1_58">
          сэргийлэх ур чадвар эзэмшүүлэх гэсэн 2 зорилтыг, Суралцагчдын нийгэм,
          олон нийтийн үйл{" "}
        </span>
        <span id="tg_58" class="t s1_58">
          ажиллагааг дэмжих, Эх оронч болон хөдөлмөрийн хүмүүжлийг дэмжих гэсэн
          2 зорилтуудыг{" "}
        </span>
        <span id="th_58" class="t s1_58">
          нэгтгэх байдлаар авч үзэж болно.{" "}
        </span>
        <span id="ti_58" class="t s1_58">
          Төлөвлөлтийн жишээ 1:{" "}
        </span>
        <span id="tj_58" class="t s1_58">
          1. Сургуулийн төлөвлөлт{" "}
        </span>
        <span id="tk_58" class="t s1_58">
          .............................{" "}
        </span>
        <span id="tl_58" class="t s1_58">
          аймаг, дүүргийн{" "}
        </span>
        <span id="tm_58" class="t s1_58">
          .......................{" "}
        </span>
        <span id="tn_58" class="t s1_58">
          сум{" "}
        </span>
        <span id="to_58" class="t s1_58">
          .............................{" "}
        </span>
        <span id="tp_58" class="t s1_58">
          сургуулийн{" "}
        </span>
        <span id="tq_58" class="t s1_58">
          хичээлээс гадуурх сургалтын ажлын төлөвлөгөө{" "}
        </span>
        <span id="tr_58" class="t s3_58">
          Монгол Улсын боловсролын зорилго,{" "}
        </span>
        <span id="ts_58" class="t s3_58">
          бодлого, чиглэл{" "}
        </span>
        <span id="tt_58" class="t s4_58">
          МУ-ын боловсролын тухай хууль, бодлогын{" "}
        </span>
        <span id="tu_58" class="t s4_58">
          баримт бичгүүд{" "}
        </span>
        <span id="tv_58" class="t s4_58">
          Сургалтын төлөвлөгөө{" "}
        </span>
        <span id="tw_58" class="t s4_58">
          Сургалтын хөтөлбөр{" "}
        </span>
        <span id="tx_58" class="t s3_58">
          Сургуулийн 2021-2022 оны хичээлийн{" "}
        </span>
        <span id="ty_58" class="t s3_58">
          жилийн зорилго{" "}
        </span>
        <span id="tz_58" class="t s4_58">
          • Ёс суртахуунтай хүүхэд{" "}
        </span>
        <span id="t10_58" class="t s4_58">
          • Бүтээлч санаачилгатай, сурах арга{" "}
        </span>
        <span id="t11_58" class="t s4_58">
          барилтай хүүхэд{" "}
        </span>
        <span id="t12_58" class="t s4_58">
          • Эрүүл чийрэг бие бялдартай хүүхэд г.м{" "}
        </span>
        <span id="t13_58" class="t s3_58">
          ХГСА-ын зорилго:{" "}
        </span>
        <span id="t14_58" class="t s4_58">
          Сурагч өөртөө итгэлтэй, бие бялдар, оюун{" "}
        </span>
        <span id="t15_58" class="t s4_58">
          сэтгэхүй, ёс суртахууны төлөвшилтэй, нийгмийн{" "}
        </span>
        <span id="t16_58" class="t s4_58">
          олон талт харилцаанд зохицон, бусдыг манлайлж,{" "}
        </span>
        <span id="t17_58" class="t s4_58">
          хамт{" "}
        </span>
        <span id="t18_58" class="t s4_58">
          олны{" "}
        </span>
        <span id="t19_58" class="t s4_58">
          хөгжилд{" "}
        </span>
        <span id="t1a_58" class="t s4_58">
          өөрийн{" "}
        </span>
        <span id="t1b_58" class="t s4_58">
          хувь{" "}
        </span>
        <span id="t1c_58" class="t s4_58">
          нэмрийг{" "}
        </span>
        <span id="t1d_58" class="t s4_58">
          оруулах, шийдвэр гаргах чадвартай, бүтээлч,{" "}
        </span>
        <span id="t1e_58" class="t s4_58">
          санаачилгатай хувь хүн болон хөгжихөд дэмжлэг{" "}
        </span>
        <span id="t1f_58" class="t s4_58">
          үзүүлнэ.{" "}
        </span>
        <span id="t1g_58" class="t s3_58">
          Сургууль орон нутгийн нөхцөл байдал:{" "}
        </span>
        <span id="t1h_58" class="t s4_58">
          Сурагчийн тоо –{" "}
        </span>
        <span id="t1i_58" class="t s4_58">
          Бүлгийн тоо –{" "}
        </span>
        <span id="t1j_58" class="t s4_58">
          Багш ажилтны тоо –{" "}
        </span>
        <span id="t1k_58" class="t s4_58">
          Эцэг эхийн клуб, сайн дурын бүлгийн тоо –{" "}
        </span>
        <span id="t1l_58" class="t s4_58">
          ХГСА-ын зорилт:{" "}
        </span>
        <span id="t1m_58" class="t s4_58">
          • Гэр бүлийн хүрээнд олгох хүмүүжил, төлөвшлийг дэмжих{" "}
        </span>
        <span id="t1n_58" class="t s4_58">
          • Эрүүл, аюулгүй амьдрах арга ухаанд сургах{" "}
        </span>
        <span id="t1o_58" class="t s4_58">
          • Болзошгүй эрсдэл, зөрчлөөс урьдчилан сэргийлэх ур чадвар эзэмшүүлэх{" "}
        </span>
        <span id="t1p_58" class="t s4_58">
          • Бие бялдар, авьяас чадварыг хөгжүүлэх{" "}
        </span>
        <span id="t1q_58" class="t s4_58">
          • Мэдээллийн технологийн зохистой хэрэглээг дэмжих{" "}
        </span>
        <span id="t1r_58" class="t s4_58">
          • Суралцагчдын нийгэм, олон нийтийн үйл ажиллагааг дэмжих{" "}
        </span>
        <span id="t1s_58" class="t s4_58">
          • Эх оронч болон хөдөлмөрийн хүмүүжлийг дэмжих{" "}
        </span>
        <span id="t1t_58" class="t s4_58">
          • Байгаль, экологийн хүмүүжлийг төлөвшүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
