/* eslint-disable jsx-a11y/iframe-has-title */
import { React, useState } from "react";

export default function ViewChange() {
  return (
    <iframe
      src={"hgua_pdf.pdf"}
      frameBorder="0"
      style={{ width: "100%", height: "100vh" }}
    ></iframe>
  );
}
