import React from "react";
;

export default function FiveThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_53{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_53{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_53{left:94px;bottom:1166px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t4_53{left:94px;bottom:1148px;letter-spacing:-0.17px;word-spacing:0.37px;}
#t5_53{left:94px;bottom:1129px;letter-spacing:-0.16px;word-spacing:3.26px;}
#t6_53{left:94px;bottom:1111px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t7_53{left:94px;bottom:1075px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t8_53{left:94px;bottom:1057px;letter-spacing:-0.17px;word-spacing:3.8px;}
#t9_53{left:94px;bottom:1039px;letter-spacing:-0.17px;word-spacing:0.08px;}
#ta_53{left:94px;bottom:1003px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tb_53{left:94px;bottom:984px;letter-spacing:-0.21px;word-spacing:7.92px;}
#tc_53{left:94px;bottom:966px;letter-spacing:-0.14px;word-spacing:3.18px;}
#td_53{left:94px;bottom:948px;letter-spacing:-0.15px;word-spacing:2.48px;}
#te_53{left:94px;bottom:930px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_53{left:94px;bottom:912px;letter-spacing:-0.18px;word-spacing:5.37px;}
#tg_53{left:94px;bottom:894px;letter-spacing:-0.14px;word-spacing:-0.73px;}
#th_53{left:94px;bottom:876px;letter-spacing:-0.18px;}
#ti_53{left:94px;bottom:839px;letter-spacing:-0.17px;word-spacing:-0.68px;}
#tj_53{left:94px;bottom:821px;letter-spacing:-0.12px;}
#tk_53{left:94px;bottom:454px;letter-spacing:-0.16px;word-spacing:-1.1px;}
#tl_53{left:94px;bottom:436px;letter-spacing:-0.14px;word-spacing:4.94px;}
#tm_53{left:94px;bottom:417px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_53{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_53{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_53{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_53{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>

        <style id="fonts53" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg53" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="53/53.svg"
          type="image/svg+xml"
          id="pdf53"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_53" class="t s0_53">
          51{" "}
        </span>
        <span id="t2_53" class="t s1_53">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_53" class="t s2_53">
          Бүрэн дунд боловсролд{" "}
        </span>
        <span id="t4_53" class="t s1_53">
          Тухайн орон нутагтаа мөхөх устах аюулд өртсөн, ховордсон амьтан
          ургамлын талаар эцэг{" "}
        </span>
        <span id="t5_53" class="t s1_53">
          эх болон байгууллагуудтай хамтран судалгаа хийж, төсөл хэрэгжүүлэх.
          Судалгааны үр{" "}
        </span>
        <span id="t6_53" class="t s1_53">
          дүнд ховордсон ан амьтдыг хамгаалах аян өрнүүлэх{" "}
        </span>
        <span id="t7_53" class="t s3_53">
          Загвар 3: Уур амьсгалын өөрчлөлт{" "}
        </span>
        <span id="t8_53" class="t s1_53">
          Орон нутагтаа мөхөх устах аюулд өртсөн, ховордсон амьтан ургамлын
          талаар хийсэн{" "}
        </span>
        <span id="t9_53" class="t s1_53">
          судалгааны үр дүнг танилцуулах ухуулга сурталчилгааг зохион байгуулах{" "}
        </span>
        <span id="ta_53" class="t s2_53">
          Ухуулга сурталчилгаа хийх арга зүй{" "}
        </span>
        <span id="tb_53" class="t s1_53">
          Сурталчилгааг мэдээлэл сургалт болон ухуулга гэж 2 хуваадаг. Мэдээлэл
          сургалт{" "}
        </span>
        <span id="tc_53" class="t s1_53">
          сурталчилгаа нь зориуд сонгосон зорилтот бүлгийхэнд харилцааны янз
          бүрийн сувгийг{" "}
        </span>
        <span id="td_53" class="t s1_53">
          ашиглан, богино хугацаанд тодорхой нэг асуудалд олон нийтийн анхаарлыг
          хандуулдаг{" "}
        </span>
        <span id="te_53" class="t s1_53">
          үйл ажиллагаа юм.{" "}
        </span>
        <span id="tf_53" class="t s1_53">
          Ухуулга сурталчилгаа нь бодлогын өөрчлөлт хийх таатай орчинг бүрдүүлж,
          шийдвэр{" "}
        </span>
        <span id="tg_53" class="t s1_53">
          гаргачдыг зөв шийдэлд чиглүүлэх, нөлөөлөх, тэдний дэмжлэгийг бий
          болгох үйл ажиллагаа{" "}
        </span>
        <span id="th_53" class="t s1_53">
          юм.{" "}
        </span>
        <span id="ti_53" class="t s2_53">
          Уур аьсгалын өөрчлөлт мэдээлэл сургалт болон ухуулга сурталчилгаа
          зохион байгуулах{" "}
        </span>
        <span id="tj_53" class="t s2_53">
          үе шат{" "}
        </span>
        <span id="tk_53" class="t s1_53">
          Мэдээлэл сургалт болон ухуулга сурталчилгааны ажлыг зохион
          байгуулахдаа олон нийтийн{" "}
        </span>
        <span id="tl_53" class="t s1_53">
          өөрсдийн оролцоог идэвхижүүлсэн, хүмүүсийн анхаарлыг татахуйц материал
          бэлтгэх,{" "}
        </span>
        <span id="tm_53" class="t s1_53">
          мэдээллийн хүртээмжийг нэмэгдүүлэхэд анхаарна.{" "}
        </span>
      </div>
    </div>
  );
}
