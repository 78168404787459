import React from "react";
;

export default function FiveSix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_56{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_56{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_56{left:117px;bottom:1138px;letter-spacing:-0.22px;word-spacing:0.09px;}
#t4_56{left:160px;bottom:1114px;}
#t5_56{left:188px;bottom:1114px;letter-spacing:-0.14px;word-spacing:7.97px;}
#t6_56{left:188px;bottom:1096px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t7_56{left:160px;bottom:1078px;}
#t8_56{left:188px;bottom:1078px;letter-spacing:-0.17px;word-spacing:6.82px;}
#t9_56{left:188px;bottom:1059px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ta_56{left:509px;bottom:1059px;letter-spacing:-0.18px;}
#tb_56{left:160px;bottom:1041px;}
#tc_56{left:188px;bottom:1041px;letter-spacing:-0.17px;word-spacing:-0.6px;}
#td_56{left:568px;bottom:1041px;letter-spacing:-0.11px;word-spacing:-0.65px;}
#te_56{left:188px;bottom:1023px;letter-spacing:-0.2px;word-spacing:0.11px;}
#tf_56{left:350px;bottom:1023px;letter-spacing:-0.4px;}
#tg_56{left:160px;bottom:1005px;}
#th_56{left:188px;bottom:1005px;letter-spacing:-0.17px;word-spacing:-0.09px;}
#ti_56{left:117px;bottom:981px;letter-spacing:-0.16px;word-spacing:3.46px;}
#tj_56{left:229px;bottom:981px;letter-spacing:-0.16px;word-spacing:3.48px;}
#tk_56{left:117px;bottom:963px;letter-spacing:-0.14px;word-spacing:0.06px;}
#tl_56{left:117px;bottom:944px;letter-spacing:-0.14px;word-spacing:-0.51px;}
#tm_56{left:117px;bottom:926px;letter-spacing:-0.15px;}
#tn_56{left:117px;bottom:908px;letter-spacing:-0.16px;word-spacing:-0.96px;}
#to_56{left:256px;bottom:908px;letter-spacing:-0.13px;word-spacing:-0.99px;}
#tp_56{left:117px;bottom:890px;letter-spacing:-0.18px;word-spacing:1.86px;}
#tq_56{left:117px;bottom:872px;letter-spacing:-0.16px;word-spacing:-0.91px;}
#tr_56{left:117px;bottom:854px;letter-spacing:-0.15px;word-spacing:2.16px;}
#ts_56{left:117px;bottom:836px;letter-spacing:-0.15px;word-spacing:0.49px;}
#tt_56{left:117px;bottom:818px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tu_56{left:117px;bottom:781px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tv_56{left:117px;bottom:763px;letter-spacing:-0.08px;}
#tw_56{left:139px;bottom:763px;letter-spacing:-0.14px;word-spacing:2.46px;}
#tx_56{left:613px;bottom:763px;letter-spacing:-0.19px;word-spacing:2.51px;}
#ty_56{left:117px;bottom:745px;letter-spacing:-0.19px;}
#tz_56{left:117px;bottom:727px;letter-spacing:-0.17px;word-spacing:1.41px;}
#t10_56{left:117px;bottom:709px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t11_56{left:117px;bottom:691px;letter-spacing:-0.16px;word-spacing:6.56px;}
#t12_56{left:117px;bottom:672px;letter-spacing:-0.17px;}
#t13_56{left:117px;bottom:654px;letter-spacing:-0.15px;word-spacing:3.16px;}
#t14_56{left:117px;bottom:636px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t15_56{left:117px;bottom:600px;letter-spacing:-0.19px;word-spacing:0.08px;}
#t16_56{left:117px;bottom:582px;letter-spacing:-0.17px;word-spacing:-0.12px;}
#t17_56{left:117px;bottom:564px;letter-spacing:-0.15px;word-spacing:-0.16px;}
#t18_56{left:117px;bottom:545px;letter-spacing:-0.15px;word-spacing:3.75px;}
#t19_56{left:117px;bottom:527px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1a_56{left:117px;bottom:509px;letter-spacing:-0.15px;word-spacing:0.44px;}
#t1b_56{left:117px;bottom:491px;letter-spacing:-0.14px;word-spacing:0.24px;}
#t1c_56{left:117px;bottom:473px;letter-spacing:-0.14px;word-spacing:2.92px;}
#t1d_56{left:117px;bottom:455px;letter-spacing:-0.18px;}

.s0_56{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_56{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_56{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s3_56{font-size:17px;font-family:Arial-BoldItalicMT_7b;color:#231F20;}`}
        </style>

        <style id="fonts56" type="text/css">
          {`@font-face {
	font-family: Arial-BoldItalicMT_7b;
	src: url("fonts/Arial-BoldItalicMT_7b.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg56" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="56/56.svg"
          type="image/svg+xml"
          id="pdf56"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_56" class="t s0_56">
          54{" "}
        </span>
        <span id="t2_56" class="t s1_56">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_56" class="t s2_56">
          ОНЛАЙН СУДАЛГААНЫ АШИГ ТУС{" "}
        </span>
        <span id="t4_56" class="t s1_56">
          -{" "}
        </span>
        <span id="t5_56" class="t s1_56">
          Богино хугацаанд багш, суралцагч, эцэг эхийн сэтгэл ханамжийн талаарх{" "}
        </span>
        <span id="t6_56" class="t s1_56">
          мэдээлэлтэй болно.{" "}
        </span>
        <span id="t7_56" class="t s1_56">
          -{" "}
        </span>
        <span id="t8_56" class="t s1_56">
          Таны судалгаа маш их ажлыг хөнгөвчлөн, цаг хугацааг хэмнэж хэд хэдэн{" "}
        </span>
        <span id="t9_56" class="t s1_56">
          хувилбараар мэдээлэл авах боломжтой{" "}
        </span>
        <span id="ta_56" class="t s1_56">
          болно.{" "}
        </span>
        <span id="tb_56" class="t s1_56">
          -{" "}
        </span>
        <span id="tc_56" class="t s1_56">
          Интернэт судалгаа хямд зардлаар бий болох ба{" "}
        </span>
        <span id="td_56" class="t s1_56">
          цаас хэмнэж эргэж нэгтгэх шивэх{" "}
        </span>
        <span id="te_56" class="t s1_56">
          гэх мэт зардал бага{" "}
        </span>
        <span id="tf_56" class="t s1_56">
          шаарддаг.{" "}
        </span>
        <span id="tg_56" class="t s1_56">
          -{" "}
        </span>
        <span id="th_56" class="t s1_56">
          Энэ нь янз бүрийн материал, илтгэл (видео, карт, фото) харуулах боломж
          олгоно.{" "}
        </span>
        <span id="ti_56" class="t s3_56">
          Хялбар хэл:{" "}
        </span>
        <span id="tj_56" class="t s1_56">
          Асуулга нь энгийн, тодорхой, ойлгомжтой байх ёстой. Ойлгоход төвөгтэй{" "}
        </span>
        <span id="tk_56" class="t s1_56">
          өгүүлбэр, хэт дэлгэрэнгүй урт өгүүлбэр хэрэглэхгүй байх. Энэ нь хэн
          нэг нь хэд хэдэн удаа{" "}
        </span>
        <span id="tl_56" class="t s1_56">
          дахин дахин уншихад хүргэх ба энэ нь асуулгын утгыг алдагдуулж болох
          магадлалтай учир{" "}
        </span>
        <span id="tm_56" class="t s1_56">
          сэтгэл ханамжийн үнэлгээг алдаатай болоход хүрнэ. Асуултуудаа энгийн
          олон сонголттой,{" "}
        </span>
        <span id="tn_56" class="t s1_56">
          эсвэл хариулт нь{" "}
        </span>
        <span id="to_56" class="t s1_56">
          “тийм” ,”үгүй” гэсэн хялбар хариулттай гэх мэтээр хувилбар сонгож
          болно.{" "}
        </span>
        <span id="tp_56" class="t s1_56">
          Таны судалгаанд авч буй асуултууд хэт их буюу 20- оос их байвал
          хариулахад төвөгтэй{" "}
        </span>
        <span id="tq_56" class="t s1_56">
          байна, хамгийн оновчтой асуулгын тоо 10 байвал зохимжтой гэж үзнэ.Таны
          асуух асуултууд{" "}
        </span>
        <span id="tr_56" class="t s1_56">
          шууд чиглэсэн, хөтөлсөн асуултууд байж болохгүй. Хариулагч өөрөө энэ
          нь судалгааны{" "}
        </span>
        <span id="ts_56" class="t s1_56">
          зорилго юу байсан, ямар асуултуудаар тодруулан асууж байна гэх зэргийг
          ойлгох боломж{" "}
        </span>
        <span id="tt_56" class="t s1_56">
          бүрдүүлэх нь зохимжтой байна.{" "}
        </span>
        <span id="tu_56" class="t s2_56">
          Судалгааны хэмжээсжүүлэх арга, аргачлал{" "}
        </span>
        <span id="tv_56" class="t s2_56">
          a.{" "}
        </span>
        <span id="tw_56" class="t s2_56">
          Хэмжээсжүүлэх арга, аргачлал – Өөр өөр хариулттай{" "}
        </span>
        <span id="tx_56" class="t s2_56">
          асуулгын хариулт, оноог{" "}
        </span>
        <span id="ty_56" class="t s2_56">
          хэмжээсжүүлэх{" "}
        </span>
        <span id="tz_56" class="t s1_56">
          Асуулгын өгөгдлийн хэмжээсжүүлэлт, өөр өөр зэргийг харгалзан статистик
          арга аргачлал{" "}
        </span>
        <span id="t10_56" class="t s1_56">
          ашиглан нарийн хэмжээсжүүлэлт хийх.{" "}
        </span>
        <span id="t11_56" class="t s2_56">
          Өгөгдлийн шинжилгээ – Судалгааны өгөгдөлд анализ хийх тохиромжтой
          арга,{" "}
        </span>
        <span id="t12_56" class="t s2_56">
          аргачлал{" "}
        </span>
        <span id="t13_56" class="t s1_56">
          Өгөгдөлтэй холбоотой статистик шинжилгээ хийх, шинжилгээний нарийн
          арга аргачлал{" "}
        </span>
        <span id="t14_56" class="t s1_56">
          ашигладаг, шинжилгээ хийх аргуудын тайлбарыг тайлбарлаж,
          баримтжуулдаг.{" "}
        </span>
        <span id="t15_56" class="t s3_56">
          Арга аргачлал{" "}
        </span>
        <span id="t16_56" class="t s1_56">
          Судалгааны асуулгад ихэвчлэн хаалттай асуулгуудыг (сонгох/олон
          сонголтод, /үнэн-худал,{" "}
        </span>
        <span id="t17_56" class="t s1_56">
          тийм-үгүй, ... гэх мэт) ашигладаг. Хаалттай асуулгад (1/0 сонголттой),
          нэрлэсэн, дараалсан{" "}
        </span>
        <span id="t18_56" class="t s1_56">
          төрлийн хувьсагчид ихэвчлэн хэрэглэгддэг бөгөөд тэдгээрийн арга
          аргачлалын талаар{" "}
        </span>
        <span id="t19_56" class="t s1_56">
          орууллаа. Хамгийн түгээмэл хэрэглэгддэг арга бол Лайкартын шкал арга
          юм.{" "}
        </span>
        <span id="t1a_56" class="t s1_56">
          Лайкартын шкал хэмжигдэхүүн нь асуулга, судалгаанд ихэвчлэн хэрэглэдэг
          психометрийн{" "}
        </span>
        <span id="t1b_56" class="t s1_56">
          хэмжүүр юм. Энэхүү аргачлалыг Ренсис Лайкерт (Америк-сэтгэл судлаач)
          үүсгэсэн бөгөөд{" "}
        </span>
        <span id="t1c_56" class="t s1_56">
          түүний нэрээр нэрлэжээ. Хүснэгтэд Лайкартын шкал нь хариултын
          төрлүүдийг харуулж{" "}
        </span>
        <span id="t1d_56" class="t s1_56">
          байна.{" "}
        </span>
      </div>
    </div>
  );
}
