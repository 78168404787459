import React from "react";
;

export default function TwentyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_23{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_23{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_23{left:110px;bottom:1157px;letter-spacing:-0.13px;word-spacing:0.51px;}
#t4_23{left:110px;bottom:1139px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t5_23{left:119px;bottom:1102px;letter-spacing:-0.27px;word-spacing:0.15px;}
#t6_23{left:135px;bottom:1059px;letter-spacing:-0.16px;word-spacing:0.2px;}
#t7_23{left:135px;bottom:1041px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_23{left:110px;bottom:1004px;letter-spacing:-0.14px;word-spacing:4.92px;}
#t9_23{left:110px;bottom:986px;letter-spacing:-0.15px;word-spacing:1.46px;}
#ta_23{left:110px;bottom:968px;letter-spacing:-0.16px;word-spacing:1.22px;}
#tb_23{left:110px;bottom:950px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tc_23{left:110px;bottom:914px;letter-spacing:-0.12px;word-spacing:0.03px;}
#td_23{left:153px;bottom:895px;letter-spacing:-0.05px;}
#te_23{left:180px;bottom:895px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tf_23{left:153px;bottom:877px;letter-spacing:-0.05px;}
#tg_23{left:180px;bottom:877px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_23{left:153px;bottom:859px;letter-spacing:-0.05px;}
#ti_23{left:180px;bottom:859px;letter-spacing:-0.13px;word-spacing:-0.79px;}
#tj_23{left:180px;bottom:841px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tk_23{left:153px;bottom:823px;letter-spacing:-0.05px;}
#tl_23{left:180px;bottom:823px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tm_23{left:153px;bottom:805px;letter-spacing:-0.05px;}
#tn_23{left:180px;bottom:805px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_23{left:153px;bottom:787px;letter-spacing:-0.15px;word-spacing:8.04px;}
#tp_23{left:180px;bottom:769px;letter-spacing:-0.15px;}
#tq_23{left:153px;bottom:750px;letter-spacing:-0.16px;word-spacing:4.05px;}
#tr_23{left:180px;bottom:732px;letter-spacing:-0.15px;word-spacing:6.28px;}
#ts_23{left:180px;bottom:714px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tt_23{left:153px;bottom:696px;letter-spacing:-0.05px;}
#tu_23{left:180px;bottom:696px;letter-spacing:-0.12px;word-spacing:1.35px;}
#tv_23{left:180px;bottom:678px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tw_23{left:153px;bottom:660px;letter-spacing:-0.15px;word-spacing:4.4px;}
#tx_23{left:180px;bottom:642px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ty_23{left:153px;bottom:623px;letter-spacing:-0.14px;word-spacing:1.02px;}
#tz_23{left:180px;bottom:605px;letter-spacing:-0.11px;word-spacing:-0.5px;}
#t10_23{left:180px;bottom:587px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t11_23{left:110px;bottom:551px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t12_23{left:110px;bottom:533px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t13_23{left:153px;bottom:509px;}
#t14_23{left:180px;bottom:509px;letter-spacing:-0.13px;word-spacing:-1.68px;}
#t15_23{left:180px;bottom:490px;letter-spacing:-0.12px;word-spacing:-1.28px;}
#t16_23{left:180px;bottom:472px;letter-spacing:-0.15px;word-spacing:2.37px;}
#t17_23{left:180px;bottom:454px;letter-spacing:-0.13px;}
#t18_23{left:153px;bottom:436px;}
#t19_23{left:180px;bottom:436px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1a_23{left:153px;bottom:418px;}
#t1b_23{left:180px;bottom:418px;letter-spacing:-0.16px;word-spacing:7.17px;}
#t1c_23{left:180px;bottom:400px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1d_23{left:153px;bottom:382px;}
#t1e_23{left:180px;bottom:382px;letter-spacing:-0.12px;word-spacing:0.69px;}
#t1f_23{left:180px;bottom:363px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1g_23{left:110px;bottom:339px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1h_23{left:153px;bottom:315px;}
#t1i_23{left:180px;bottom:315px;letter-spacing:-0.17px;word-spacing:4.84px;}
#t1j_23{left:180px;bottom:297px;letter-spacing:-0.16px;word-spacing:9.01px;}
#t1k_23{left:180px;bottom:279px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t1l_23{left:153px;bottom:261px;}
#t1m_23{left:180px;bottom:261px;letter-spacing:-0.15px;word-spacing:5.96px;}
#t1n_23{left:180px;bottom:242px;letter-spacing:-0.17px;word-spacing:5.18px;}
#t1o_23{left:180px;bottom:224px;letter-spacing:-0.14px;word-spacing:2.25px;}
#t1p_23{left:180px;bottom:206px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1q_23{left:153px;bottom:188px;}
#t1r_23{left:180px;bottom:188px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1s_23{left:110px;bottom:164px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1t_23{left:153px;bottom:139px;}
#t1u_23{left:180px;bottom:139px;letter-spacing:-0.16px;word-spacing:4.86px;}
#t1v_23{left:180px;bottom:121px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1w_23{left:153px;bottom:103px;}
#t1x_23{left:180px;bottom:103px;letter-spacing:-0.14px;word-spacing:5.52px;}
#t1y_23{left:180px;bottom:85px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1z_23{left:153px;bottom:67px;}
#t20_23{left:180px;bottom:67px;letter-spacing:-0.15px;word-spacing:8.03px;}
#t21_23{left:180px;bottom:49px;letter-spacing:-0.16px;word-spacing:0.07px;}

.s0_23{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_23{font-size:17px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts23" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg23" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="23/23.svg"
          type="image/svg+xml"
          id="pdf23"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_23" class="t s0_23">
          21{" "}
        </span>
        <span id="t2_23" class="t s1_23">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_23" class="t s1_23">
          Суурь болон бүрэн дунд боловсролын түвшинд ангийн багш нийгэм, түүхийн
          багш нартай{" "}
        </span>
        <span id="t4_23" class="t s1_23">
          хамтран санал болгосон аргуудаар хамтран зохион байгуулж болно.{" "}
        </span>
        <span id="t5_23" class="t s1_23">
          ХОЁР. ЭРҮҮЛ, АЮУЛГҮЙ АМЬДРАХ АРГА УХААНЫ БОЛОВСРОЛД ДЭМЖЛЭГ ҮЗҮҮЛЭХ{" "}
        </span>
        <span id="t6_23" class="t s1_23">
          2.1.Амьдралын аюулгүй байдлыг эрхэмлэдэг, хорт зуршлаас урьдчилан
          сэргийлэх{" "}
        </span>
        <span id="t7_23" class="t s1_23">
          соёлыг залуу үед төлөвшүүлэх үйл ажиллагааг эрчимжүүлэх{" "}
        </span>
        <span id="t8_23" class="t s1_23">
          2.1.1.Зорилго: Эрүүл амьдрах гэсэн ойлголтыг бий болгох, ямар орчныг
          аюултай гэх{" "}
        </span>
        <span id="t9_23" class="t s1_23">
          вэ?, ямар орчныг таатай орчин гэх вэ? гэсэн ойлголтыг бий болгох,
          биеийн тамир болон{" "}
        </span>
        <span id="ta_23" class="t s1_23">
          биологийн судлагдахуунтай хамтран мэдлэг бүтээлгэх, харьцуулах, зөв
          мэтгэлцэж сургах{" "}
        </span>
        <span id="tb_23" class="t s1_23">
          сонголт хийх боломжтой болгоход оршино.{" "}
        </span>
        <span id="tc_23" class="t s1_23">
          2.1.2.Үндсэн ойлголт:{" "}
        </span>
        <span id="td_23" class="t s1_23">
          1.{" "}
        </span>
        <span id="te_23" class="t s1_23">
          Эрүүл хүн гэж ямар хүнийг хэлэх вэ?{" "}
        </span>
        <span id="tf_23" class="t s1_23">
          2.{" "}
        </span>
        <span id="tg_23" class="t s1_23">
          Эрүүл монгол хүн нийгмийн баялаг гэдгийг ойлгуулах{" "}
        </span>
        <span id="th_23" class="t s1_23">
          3.{" "}
        </span>
        <span id="ti_23" class="t s1_23">
          Эрүүл амьдрах талаар дэлхийн улс орнуудын онцлог хооллох аргын тухай
          баримт{" "}
        </span>
        <span id="tj_23" class="t s1_23">
          цуглуулан бусдадаа мэдээлэл өгөх боломжуудыг хайх{" "}
        </span>
        <span id="tk_23" class="t s1_23">
          4.{" "}
        </span>
        <span id="tl_23" class="t s1_23">
          Эрүүл хоол буюу ногоон хүнсний тухай ойлголт бий болгох{" "}
        </span>
        <span id="tm_23" class="t s1_23">
          5.{" "}
        </span>
        <span id="tn_23" class="t s1_23">
          Өөртөө болон бусдад нийгэмд эрүүл байснаар гарах үр ашгийн тухай
          ярилцах{" "}
        </span>
        <span id="to_23" class="t s1_23">
          6. Таргалалтын талаар шүүмжлэлттэй хандаж өөрсдийн санал бодлыг бүрэн{" "}
        </span>
        <span id="tp_23" class="t s1_23">
          илэрхийлэх{" "}
        </span>
        <span id="tq_23" class="t s1_23">
          7. Сургуулийн орчин болон сургуулиас гэр хүртэлх замд хүүхдэд аюултай
          орчин{" "}
        </span>
        <span id="tr_23" class="t s1_23">
          байгаа талаар ахлах ангийн сурагчид судалгаа хийж танилцуулах, өөрчлөх{" "}
        </span>
        <span id="ts_23" class="t s1_23">
          боломжуудыг эрэлхийлэх, сайн үйлсийн аян зохиох{" "}
        </span>
        <span id="tt_23" class="t s1_23">
          8.{" "}
        </span>
        <span id="tu_23" class="t s1_23">
          Суралцагч, багш нарын хоорондын харилцан бие биеэ хүндэтгэсэн
          харилцааны{" "}
        </span>
        <span id="tv_23" class="t s1_23">
          талаар ярилцах{" "}
        </span>
        <span id="tw_23" class="t s1_23">
          9. Мэргэжил сонголт нь яагаад эрүүл аюулгүй аж төрөх ёстой холбоотой
          болох{" "}
        </span>
        <span id="tx_23" class="t s1_23">
          талаар мэтгэлцээн өрнүүлэх{" "}
        </span>
        <span id="ty_23" class="t s1_23">
          10. Суурь бүрэн дунд боловсролын түвшинд суралцаж байгаа хүүхдүүд бага
          ангийн{" "}
        </span>
        <span id="tz_23" class="t s1_23">
          хүүхдүүдийн аюулгүй эрүүл орчинд өөр өөрсдийнхөөрөө үнэлгээ хийж
          өөрсдөд нь{" "}
        </span>
        <span id="t10_23" class="t s1_23">
          мөн энэ эрсдэл таарч болох талаар санал солилцох{" "}
        </span>
        <span id="t11_23" class="t s1_23">
          2.1.3.Үйл ажиллагаа{" "}
        </span>
        <span id="t12_23" class="t s1_23">
          а.Бага боловсролд{" "}
        </span>
        <span id="t13_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t14_23" class="t s1_23">
          Биеийн тамирын хичээлийн анхны өдрөөс олгох дасгал хөдөлгөөн яагаад
          хэрэгтэй,{" "}
        </span>
        <span id="t15_23" class="t s1_23">
          яагаад биеийн тамирын хувцас сольж өмсөх шаардлагтай талаар биеийн
          тамирын{" "}
        </span>
        <span id="t16_23" class="t s1_23">
          багш, эцэг эхтэй хамтран энгийн анхан шатны ойлголт бий болгох,
          хэрэгжүүлж{" "}
        </span>
        <span id="t17_23" class="t s1_23">
          хэвшүүлэх{" "}
        </span>
        <span id="t18_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t19_23" class="t s1_23">
          Шүдээ, гараа яагаад угаах ёстой талаар хүүхдүүдтэй ярилцах{" "}
        </span>
        <span id="t1a_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1b_23" class="t s1_23">
          Дасгал хийх үед болон бусад үед хэрхэн хооллох талаар ярилцах жижиг{" "}
        </span>
        <span id="t1c_23" class="t s1_23">
          жүжигчилсэн тоглолт хийж үзүүлэх{" "}
        </span>
        <span id="t1d_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1e_23" class="t s1_23">
          Дасгал хөдөлгөөн хийж сурах үндсэн ойлголт өгөх, дасгал хөдөлгөөн
          хийхийн ач{" "}
        </span>
        <span id="t1f_23" class="t s1_23">
          холбогдлыг ойлгуулах{" "}
        </span>
        <span id="t1g_23" class="t s1_23">
          б.Суурь боловсролд{" "}
        </span>
        <span id="t1h_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1i_23" class="t s1_23">
          Улиралд тохируулан хувцаслах, хооллох, унтах амрах цаг яагаад
          баримтлах{" "}
        </span>
        <span id="t1j_23" class="t s1_23">
          талаар хүүхдүүдийг баг болгож мэдээлэл бэлдүүлэн шүүмжлүүлэх, санал{" "}
        </span>
        <span id="t1k_23" class="t s1_23">
          дэвшүүлэх, дүгнэх үйлүүд хийлгэх.{" "}
        </span>
        <span id="t1l_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1m_23" class="t s1_23">
          Биеийн тамир, биологийн багштай хамтран охид хөвгүүдийн биед гарч буй{" "}
        </span>
        <span id="t1n_23" class="t s1_23">
          өөрчлөлт нь физиологийн хувьд ямар өөрчлөлтүүд болох ачаалал, хоололт,{" "}
        </span>
        <span id="t1o_23" class="t s1_23">
          хувцаслалтад яагаад эцэг эх нь анхаарал тавьж шүүмжлээд байгааг
          ойлгуулах{" "}
        </span>
        <span id="t1p_23" class="t s1_23">
          чиглэлээр мэдээлэл өгөх, хамтран шийдвэр гаргах боломж хайх{" "}
        </span>
        <span id="t1q_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1r_23" class="t s1_23">
          Дасгал хөдөлгөөн тогтмол хийж туршиж ач холбогдлыг нь өөрөөр нь
          дүгнүүлэх.{" "}
        </span>
        <span id="t1s_23" class="t s1_23">
          в.Бүрэн дунд боловсролд{" "}
        </span>
        <span id="t1t_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1u_23" class="t s1_23">
          Эрүүл байхын ач холбогдол, дасгал хөдөлгөөн тогтмол заавал хийж хэвших{" "}
        </span>
        <span id="t1v_23" class="t s1_23">
          шалтгаан, өөрт тохируулан хооллох сонголт хийх чадвартай болох.{" "}
        </span>
        <span id="t1w_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t1x_23" class="t s1_23">
          Физиологийн хувьд насанд хүрэгчидтэй адил болох хөгжил явагдаж байгааг{" "}
        </span>
        <span id="t1y_23" class="t s1_23">
          биологи, биеийн тамирын багштай хамтран ойлгуулах.{" "}
        </span>
        <span id="t1z_23" class="t s1_23">
          -{" "}
        </span>
        <span id="t20_23" class="t s1_23">
          Биеийн онцлогт тохирсон дасгалыг сонгож ачааллаа тохируулан спортоор{" "}
        </span>
        <span id="t21_23" class="t s1_23">
          хичээллэх, сонгох чадвартай болох.{" "}
        </span>
      </div>
    </div>
  );
}
