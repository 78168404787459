import React from "react";
;

export default function ThirtyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p34" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_34{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_34{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_34{left:110px;bottom:1157px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t4_34{left:253px;bottom:1157px;}
#t5_34{left:153px;bottom:1133px;}
#t6_34{left:180px;bottom:1133px;letter-spacing:-0.11px;}
#t7_34{left:219px;bottom:1133px;letter-spacing:-0.16px;word-spacing:5.3px;}
#t8_34{left:180px;bottom:1114px;letter-spacing:-0.14px;word-spacing:3.07px;}
#t9_34{left:539px;bottom:1114px;letter-spacing:-0.11px;}
#ta_34{left:576px;bottom:1114px;letter-spacing:-0.13px;word-spacing:3.07px;}
#tb_34{left:180px;bottom:1096px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tc_34{left:153px;bottom:1078px;}
#td_34{left:180px;bottom:1078px;letter-spacing:-0.16px;word-spacing:0.09px;}
#te_34{left:180px;bottom:1060px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_34{left:153px;bottom:1042px;}
#tg_34{left:180px;bottom:1042px;letter-spacing:-0.13px;word-spacing:6.88px;}
#th_34{left:180px;bottom:1024px;letter-spacing:-0.16px;word-spacing:1px;}
#ti_34{left:180px;bottom:1006px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tj_34{left:153px;bottom:987px;}
#tk_34{left:180px;bottom:987px;letter-spacing:-0.14px;word-spacing:3.62px;}
#tl_34{left:180px;bottom:969px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tm_34{left:153px;bottom:951px;}
#tn_34{left:180px;bottom:951px;letter-spacing:-0.15px;word-spacing:2.6px;}
#to_34{left:180px;bottom:933px;letter-spacing:-0.12px;word-spacing:1.62px;}
#tp_34{left:180px;bottom:915px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tq_34{left:110px;bottom:873px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tr_34{left:110px;bottom:854px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ts_34{left:110px;bottom:836px;letter-spacing:-0.12px;word-spacing:-1.01px;}
#tt_34{left:110px;bottom:818px;letter-spacing:-0.12px;word-spacing:1.83px;}
#tu_34{left:110px;bottom:800px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tv_34{left:110px;bottom:764px;letter-spacing:-0.1px;}
#tw_34{left:110px;bottom:746px;letter-spacing:-0.14px;word-spacing:0.46px;}
#tx_34{left:110px;bottom:727px;letter-spacing:-0.14px;word-spacing:2.73px;}
#ty_34{left:110px;bottom:709px;letter-spacing:-0.15px;word-spacing:2.1px;}
#tz_34{left:110px;bottom:691px;letter-spacing:-0.15px;word-spacing:2.95px;}
#t10_34{left:110px;bottom:673px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t11_34{left:110px;bottom:655px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t12_34{left:110px;bottom:619px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t13_34{left:110px;bottom:600px;letter-spacing:-0.13px;word-spacing:4.64px;}
#t14_34{left:110px;bottom:582px;letter-spacing:-0.16px;word-spacing:2.18px;}
#t15_34{left:110px;bottom:564px;letter-spacing:-0.15px;word-spacing:-0.93px;}
#t16_34{left:110px;bottom:546px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t17_34{left:110px;bottom:510px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t18_34{left:110px;bottom:492px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t19_34{left:110px;bottom:474px;letter-spacing:-0.12px;word-spacing:3.06px;}
#t1a_34{left:110px;bottom:455px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1b_34{left:110px;bottom:419px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1c_34{left:110px;bottom:401px;letter-spacing:-0.14px;word-spacing:4.65px;}
#t1d_34{left:110px;bottom:383px;letter-spacing:-0.14px;word-spacing:4.04px;}
#t1e_34{left:110px;bottom:365px;letter-spacing:-0.14px;word-spacing:1.22px;}
#t1f_34{left:110px;bottom:347px;letter-spacing:-0.17px;word-spacing:1.79px;}
#t1g_34{left:110px;bottom:328px;letter-spacing:-0.1px;word-spacing:3.33px;}
#t1h_34{left:110px;bottom:310px;letter-spacing:-0.13px;word-spacing:-0.41px;}
#t1i_34{left:110px;bottom:292px;letter-spacing:-0.13px;word-spacing:1.02px;}
#t1j_34{left:110px;bottom:274px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1k_34{left:110px;bottom:238px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1l_34{left:110px;bottom:220px;letter-spacing:-0.12px;word-spacing:1.51px;}
#t1m_34{left:110px;bottom:202px;letter-spacing:-0.11px;word-spacing:3.73px;}
#t1n_34{left:110px;bottom:183px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1o_34{left:110px;bottom:147px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1p_34{left:110px;bottom:129px;letter-spacing:-0.13px;word-spacing:9.36px;}
#t1q_34{left:110px;bottom:111px;letter-spacing:-0.13px;word-spacing:1.02px;}
#t1r_34{left:110px;bottom:93px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_34{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_34{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_34{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_34{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}`}
        </style>
        <style id="fonts34" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg3" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="34/34.svg"
          type="image/svg+xml"
          id="pdf34"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_34" class="t s0_34">
          32{" "}
        </span>
        <span id="t2_34" class="t s1_34">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_34" class="t s2_34">
          Чиглүүлэх асуулт
        </span>
        <span id="t4_34" class="t s1_34">
          :{" "}
        </span>
        <span id="t5_34" class="t s1_34">
          -{" "}
        </span>
        <span id="t6_34" class="t s3_34">
          “А”{" "}
        </span>
        <span id="t7_34" class="t s1_34">
          хүснэгтэд хувь хүн болзошгүй эрсдэл, зөрчилд холбогдохоос урьдчилан{" "}
        </span>
        <span id="t8_34" class="t s1_34">
          сэргийлэх ямар аргууд байж болох талаар,{" "}
        </span>
        <span id="t9_34" class="t s3_34">
          “Б”{" "}
        </span>
        <span id="ta_34" class="t s1_34">
          хүснэгтэнд хохирогч болохоос{" "}
        </span>
        <span id="tb_34" class="t s1_34">
          хэрхэн урьдчилан сэргийлэх талаар гэсэн 2 ойлголтыг багуудад өгнө.{" "}
        </span>
        <span id="tc_34" class="t s1_34">
          -{" "}
        </span>
        <span id="td_34" class="t s1_34">
          Оролцогчдод энэ талаарх ойлголтыг гаргаж, багууд тус тусдаа ярилцаж
          хариулах{" "}
        </span>
        <span id="te_34" class="t s1_34">
          даалгавар өгнө.{" "}
        </span>
        <span id="tf_34" class="t s1_34">
          -{" "}
        </span>
        <span id="tg_34" class="t s1_34">
          Хэсэг хугацааны дараа баг тус бүрээс 2-3 оролцогчийн хариултыг сонсож{" "}
        </span>
        <span id="th_34" class="t s1_34">
          хэлэлцээд багш өгөгдсөн мэдээллийн дагуу оролцогчдод ойлголтыг тус
          тусад нь{" "}
        </span>
        <span id="ti_34" class="t s1_34">
          өгч, ялгаатай зүйл болон ижил төсөөтэй зүйлийг тайлбарлан хэлнэ.{" "}
        </span>
        <span id="tj_34" class="t s1_34">
          -{" "}
        </span>
        <span id="tk_34" class="t s1_34">
          Багуудын оролцогчид өөр өөрийн ажилласан сэдвийн хүрээнд эсрэг
          багаасаа{" "}
        </span>
        <span id="tl_34" class="t s1_34">
          асуулт тавина.{" "}
        </span>
        <span id="tm_34" class="t s1_34">
          -{" "}
        </span>
        <span id="tn_34" class="t s1_34">
          Болзошгүй эрсдэл, зөрчилд холбогдохгүй байх арга зам, хувь хүний
          хүмүүжил,{" "}
        </span>
        <span id="to_34" class="t s1_34">
          зан төлөв, бусдын нөлөө, хайхрамжгүй, бодлогогүй байдлын эцэст юунд
          хүргэж{" "}
        </span>
        <span id="tp_34" class="t s1_34">
          болохыг дүгнэж ярих.{" "}
        </span>
        <span id="tq_34" class="t s1_34">
          Нэмэлт мэдээлэл:{" "}
        </span>
        <span id="tr_34" class="t s1_34">
          Өсвөр насны хүүхдүүдэд зориулсан аюулгүйн дүрэм{" "}
        </span>
        <span id="ts_34" class="t s1_34">
          Хүүхдүүд өөрсдийгөө хүчирхийллээс хамгаалын тулд дараах аюулгүйн
          дүрмийг баримталж{" "}
        </span>
        <span id="tt_34" class="t s1_34">
          сурах хэрэгтэй. Аюулгүйн дүрмийг уншиж, хэвшүүлснийхээ дараа
          найзууддаа болон дүү{" "}
        </span>
        <span id="tu_34" class="t s1_34">
          нартаа зааж өгөөрэй гэж зөвлөөрэй.{" "}
        </span>
        <span id="tv_34" class="t s2_34">
          Оройн цагаар ганцаараа битгий яв{" "}
        </span>
        <span id="tw_34" class="t s1_34">
          Сургууль дугуйлан нь орой харанхуй болсон үед тарсан бол гэр ойр
          байдаг найзуудаараа{" "}
        </span>
        <span id="tx_34" class="t s1_34">
          хамт яваарай. Мөн аав, ээж, ах, эгчээрээ үргэлж тосуулж байгаарай.
          Гэртээ ганцаараа{" "}
        </span>
        <span id="ty_34" class="t s1_34">
          хонож байгаа тохиолдолд энэ талаар гэрийнхнээсээ өөр хүнд битгий
          хэлээрэй. Ганцаар{" "}
        </span>
        <span id="tz_34" class="t s1_34">
          оройн цагаар битгий гар. Танихгүй хүн гэртээ авч ирж хэзээ ч болохгүй.
          Оройн цагаар{" "}
        </span>
        <span id="t10_34" class="t s1_34">
          лифтэнд цуг суух гэж буй хүн чинь таагүй санагдсан бол хамт битгий суу
          гэх мэт зөвлөгөөг{" "}
        </span>
        <span id="t11_34" class="t s1_34">
          сануулж байх хэрэгтэй.{" "}
        </span>
        <span id="t12_34" class="t s2_34">
          Сайн танихгүй хүнтэй битгий яв. Хувийн мэдээллээ битгий өг{" "}
        </span>
        <span id="t13_34" class="t s1_34">
          Дөнгөж танилцсан шинэ найз эсвэл сайн танихгүй хүн чамд зугаалах санал
          тавьбал{" "}
        </span>
        <span id="t14_34" class="t s1_34">
          эелдгээр татгалз. Мөн тэдэнд нэр, гэрийн хаяг, нас, сургууль, анги,
          утасны дугаар зэрэг{" "}
        </span>
        <span id="t15_34" class="t s1_34">
          хувийн мэдээллээ өгч болохгүй. Ганцаараа мөн найзуудтайгаа явж байсан
          ч танихгүй хүний{" "}
        </span>
        <span id="t16_34" class="t s1_34">
          машинд хэзээ ч битгий суу.{" "}
        </span>
        <span id="t17_34" class="t s2_34">
          Үе тэнгийнхний дарамт дээрэлхэлтээс өөрийгөө хамгаал{" "}
        </span>
        <span id="t18_34" class="t s1_34">
          Хүүхэд бүр аюулгүй орчинд амьдрах эрхтэй учир хэнийг ч битгий
          дарамтал, бүү дээрэлх.{" "}
        </span>
        <span id="t19_34" class="t s1_34">
          Үе тэнгийнхэн чинь чамайг дарамталж дээрэлхвэл өөрийгөө хамгаал,
          тусламж ав. Хэн{" "}
        </span>
        <span id="t1a_34" class="t s1_34">
          нэгэн хүүхэд дарамтлуулж, дээрэлхүүлж байхыг харвал тусламж дууд{" "}
        </span>
        <span id="t1b_34" class="t s2_34">
          Аюултай нөхцөлд орвол тусламж дууд{" "}
        </span>
        <span id="t1c_34" class="t s1_34">
          Шаардлагатай нөхцөлд бусдаас тусламж гуйхад ичих зүйл байхгүй гэдгийг
          санаарай.{" "}
        </span>
        <span id="t1d_34" class="t s1_34">
          Өөрийгөө хүнд нөхцөлөөс аврах энгийн арга бол хүмүүсийн анхаарлыг
          өөртөө татаж,{" "}
        </span>
        <span id="t1e_34" class="t s1_34">
          чанга орилох явдал юм. Чанга орилоход хүмүүс чамайг аюулд орсон
          гэдгийг шууд мэдэх{" "}
        </span>
        <span id="t1f_34" class="t s1_34">
          болно. Гадаа гудамжинд явж байгаад аюулд учирвал, ойр орчимд байгаа
          том хүмүүсээс{" "}
        </span>
        <span id="t1g_34" class="t s1_34">
          тэр дундаа хүүхэдтэйгээ яваа хүмүүсээс тусламж хүсээрэй. Хүүхдийн
          тусламжийн 108{" "}
        </span>
        <span id="t1h_34" class="t s1_34">
          утас нь зөвхөн хүүхдэд зөвлөгөө мэдээлэл, тусламж үзүүлэх зорилгоор 24
          цагийн турш үнэ{" "}
        </span>
        <span id="t1i_34" class="t s1_34">
          төлбөргүй ажилладаг утас юм. Тиймээс хүүхдүүд та нар өөрт чинь, найз
          нөхөд эсвэл хэн{" "}
        </span>
        <span id="t1j_34" class="t s1_34">
          нэгэн хүүхдэд тусламж шаардлагатай байгааг мэдвэл 108 утсанд
          хандаарай.{" "}
        </span>
        <span id="t1k_34" class="t s2_34">
          Аав ээжээсээ нуух нууц гэж байхгүй.{" "}
        </span>
        <span id="t1l_34" class="t s1_34">
          Чамд ямар нэгэн асуудал тулгарвал аав, ээж хоёроосоо нууж хэзээ ч
          болохгүй. Аав ээж{" "}
        </span>
        <span id="t1m_34" class="t s1_34">
          чинь чамайг хэнээс ч, юунаас ч хамгаалах үүрэгтэй гэдгийг санаарай. Чи
          аав ээждээ{" "}
        </span>
        <span id="t1n_34" class="t s1_34">
          хэлэхгүй бол тэд чамд тусламж хэрэгтэй байгааг мэдэхүй.{" "}
        </span>
        <span id="t1o_34" class="t s2_34">
          Өсвөр насны хүүхдүүдэд зориулсан аюулгүйн дүрэм{" "}
        </span>
        <span id="t1p_34" class="t s1_34">
          Хүүхдүүд өөрсдийгөө хүчирхийллээс хамгаалахын тулд дараах аюулгүйн
          дүрмийг{" "}
        </span>
        <span id="t1q_34" class="t s1_34">
          баримталж сурах хэрэгтэй. Аюулгүйн дүрмийг уншиж, хэвшүүлснийхээ дараа
          найзууддаа{" "}
        </span>
        <span id="t1r_34" class="t s1_34">
          болон дүү нартаа зааж өгөөрэй гэж зөвлөөрэй.{" "}
        </span>
      </div>
    </div>
  );
}
