import React from "react";
;

export default function FourNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_49{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_49{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_49{left:353px;bottom:1057px;letter-spacing:-0.02px;word-spacing:0.15px;}
#t4_49{left:730px;bottom:1057px;letter-spacing:0.14px;}
#t5_49{left:393px;bottom:1029px;letter-spacing:0.09px;word-spacing:0.05px;}
#t6_49{left:516px;bottom:1029px;}
#t7_49{left:118px;bottom:1003px;letter-spacing:0.1px;word-spacing:0.03px;}
#t8_49{left:118px;bottom:991px;letter-spacing:0.13px;}
#t9_49{left:118px;bottom:979px;letter-spacing:0.08px;word-spacing:0.05px;}
#ta_49{left:118px;bottom:966px;letter-spacing:0.07px;word-spacing:0.06px;}
#tb_49{left:118px;bottom:954px;letter-spacing:0.13px;}
#tc_49{left:118px;bottom:942px;letter-spacing:0.11px;word-spacing:0.02px;}
#td_49{left:118px;bottom:930px;letter-spacing:0.1px;word-spacing:0.04px;}
#te_49{left:118px;bottom:917px;letter-spacing:0.1px;word-spacing:0.04px;}
#tf_49{left:118px;bottom:905px;letter-spacing:0.11px;word-spacing:0.04px;}
#tg_49{left:305px;bottom:893px;letter-spacing:0.12px;word-spacing:0.03px;}
#th_49{left:118px;bottom:881px;letter-spacing:0.11px;word-spacing:0.03px;}
#ti_49{left:118px;bottom:868px;letter-spacing:0.09px;word-spacing:0.04px;}
#tj_49{left:118px;bottom:856px;letter-spacing:0.09px;word-spacing:0.04px;}
#tk_49{left:118px;bottom:844px;letter-spacing:0.01px;word-spacing:0.12px;}
#tl_49{left:118px;bottom:832px;letter-spacing:0.03px;word-spacing:0.11px;}
#tm_49{left:118px;bottom:819px;letter-spacing:0.06px;word-spacing:0.07px;}
#tn_49{left:360px;bottom:807px;letter-spacing:0.17px;}
#to_49{left:118px;bottom:795px;letter-spacing:0.07px;word-spacing:0.06px;}
#tp_49{left:118px;bottom:783px;letter-spacing:0.12px;word-spacing:0.01px;}
#tq_49{left:118px;bottom:770px;letter-spacing:0.09px;word-spacing:0.04px;}
#tr_49{left:118px;bottom:758px;letter-spacing:0.12px;word-spacing:0.02px;}
#ts_49{left:118px;bottom:746px;letter-spacing:0.08px;word-spacing:0.06px;}
#tt_49{left:118px;bottom:734px;letter-spacing:0.09px;word-spacing:0.05px;}
#tu_49{left:118px;bottom:722px;letter-spacing:0.12px;word-spacing:0.02px;}
#tv_49{left:118px;bottom:709px;letter-spacing:0.01px;word-spacing:0.11px;}
#tw_49{left:296px;bottom:697px;letter-spacing:0.13px;}
#tx_49{left:118px;bottom:685px;word-spacing:0.13px;}
#ty_49{left:118px;bottom:673px;letter-spacing:0.11px;word-spacing:0.02px;}
#tz_49{left:118px;bottom:660px;letter-spacing:0.12px;word-spacing:0.02px;}
#t10_49{left:118px;bottom:648px;letter-spacing:0.09px;word-spacing:0.05px;}
#t11_49{left:118px;bottom:636px;letter-spacing:0.12px;word-spacing:0.02px;}
#t12_49{left:118px;bottom:624px;letter-spacing:0.07px;word-spacing:0.06px;}
#t13_49{left:118px;bottom:611px;letter-spacing:0.1px;word-spacing:0.04px;}
#t14_49{left:366px;bottom:599px;letter-spacing:0.12px;}
#t15_49{left:118px;bottom:587px;letter-spacing:0.08px;word-spacing:0.05px;}
#t16_49{left:118px;bottom:575px;letter-spacing:0.13px;}
#t17_49{left:188px;bottom:574px;letter-spacing:-0.14px;word-spacing:0.06px;}
#t18_49{left:353px;bottom:548px;letter-spacing:-0.03px;word-spacing:0.17px;}
#t19_49{left:698px;bottom:548px;letter-spacing:0.17px;}
#t1a_49{left:118px;bottom:522px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1b_49{left:118px;bottom:510px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1c_49{left:118px;bottom:497px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1d_49{left:334px;bottom:485px;letter-spacing:-0.01px;word-spacing:0.15px;}
#t1e_49{left:118px;bottom:473px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1f_49{left:118px;bottom:461px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1g_49{left:118px;bottom:448px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1h_49{left:118px;bottom:436px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1i_49{left:118px;bottom:424px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1j_49{left:118px;bottom:412px;letter-spacing:0.03px;word-spacing:0.1px;}
#t1k_49{left:349px;bottom:384px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1l_49{left:734px;bottom:385px;letter-spacing:0.15px;}
#t1m_49{left:118px;bottom:357px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1n_49{left:118px;bottom:345px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1o_49{left:118px;bottom:333px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1p_49{left:118px;bottom:321px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1q_49{left:118px;bottom:308px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1r_49{left:251px;bottom:296px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1s_49{left:118px;bottom:284px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1t_49{left:118px;bottom:272px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1u_49{left:118px;bottom:260px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1v_49{left:118px;bottom:247px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1w_49{left:118px;bottom:235px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1x_49{left:118px;bottom:223px;letter-spacing:0.03px;word-spacing:0.11px;}
#t1y_49{left:118px;bottom:211px;letter-spacing:0.05px;word-spacing:0.09px;}
#t1z_49{left:118px;bottom:198px;letter-spacing:0.14px;}
#t20_49{left:118px;bottom:186px;letter-spacing:0.08px;word-spacing:0.05px;}
#t21_49{left:118px;bottom:174px;letter-spacing:0.04px;word-spacing:0.1px;}
#t22_49{left:342px;bottom:146px;letter-spacing:0.13px;word-spacing:0.01px;}
#t23_49{left:732px;bottom:147px;letter-spacing:0.15px;}
#t24_49{left:118px;bottom:120px;letter-spacing:0.1px;word-spacing:0.03px;}
#t25_49{left:118px;bottom:107px;letter-spacing:0.05px;word-spacing:0.08px;}
#t26_49{left:118px;bottom:95px;letter-spacing:0.05px;word-spacing:0.08px;}
#t27_49{left:118px;bottom:83px;letter-spacing:0.15px;}
#t28_49{left:118px;bottom:71px;letter-spacing:0.09px;word-spacing:0.04px;}
#t29_49{left:118px;bottom:58px;letter-spacing:0.07px;word-spacing:0.06px;}
#t2a_49{left:110px;bottom:1160px;letter-spacing:-0.18px;}
#t2b_49{left:110px;bottom:1142px;letter-spacing:-0.13px;word-spacing:-0.51px;}
#t2c_49{left:110px;bottom:1124px;letter-spacing:-0.17px;word-spacing:3.75px;}
#t2d_49{left:110px;bottom:1105px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t2e_49{left:110px;bottom:1087px;letter-spacing:-0.09px;}

.s0_49{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_49{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_49{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s3_49{font-size:17px;font-family:Calibri_4n;color:#231F20;}
.s4_49{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>
        <style id="fonts49" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: Calibri_4n;
	src: url("fonts/Calibri_4n.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg49" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="49/49.svg"
          type="image/svg+xml"
          id="pdf49"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_49" class="t s0_49">
          47{" "}
        </span>
        <span id="t2_49" class="t s1_49">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_49" class="t s2_49">
          Ур чадварууд{" "}
        </span>
        <span id="t4_49" class="t s2_49">
          Оноо{" "}
        </span>
        <span id="t5_49" class="t s2_49">
          Нийтлэг ур чадва
        </span>
        <span id="t6_49" class="t s3_49">
          р{" "}
        </span>
        <span id="t7_49" class="t s2_49">
          1 Хүмүүстэй хамт байх{" "}
        </span>
        <span id="t8_49" class="t s2_49">
          2 Сонсох{" "}
        </span>
        <span id="t9_49" class="t s2_49">
          3 Хүнийг ойлгож нөхөрлөх{" "}
        </span>
        <span id="ta_49" class="t s2_49">
          4 Бусдыг хүндэтгэх{" "}
        </span>
        <span id="tb_49" class="t s2_49">
          5 Хүмүүст туслах{" "}
        </span>
        <span id="tc_49" class="t s2_49">
          6 Бусдыг хүлээн зөвшөөрөх{" "}
        </span>
        <span id="td_49" class="t s2_49">
          7 Бусадтай хамтран ажиллах{" "}
        </span>
        <span id="te_49" class="t s2_49">
          8 Бусдад нийцэмжтэй байх{" "}
        </span>
        <span id="tf_49" class="t s2_49">
          9 Хошигнох{" "}
        </span>
        <span id="tg_49" class="t s2_49">
          Аман ба бичгийн харилцаа{" "}
        </span>
        <span id="th_49" class="t s2_49">
          1 Өөрийн үзэл бодлоо илэрхийлэх{" "}
        </span>
        <span id="ti_49" class="t s2_49">
          2 Ойлгомжтой, цэгцтэй байх{" "}
        </span>
        <span id="tj_49" class="t s2_49">
          3 Мэдээллийг бусдад танилцуулах, илтгэх{" "}
        </span>
        <span id="tk_49" class="t s2_49">
          4 Тод, уран яруу ярих{" "}
        </span>
        <span id="tl_49" class="t s2_49">
          5 Тайлбарлах, тодорхойлох{" "}
        </span>
        <span id="tm_49" class="t s2_49">
          6 Гадаад хэлээр ярих{" "}
        </span>
        <span id="tn_49" class="t s2_49">
          Манлайлах{" "}
        </span>
        <span id="to_49" class="t s2_49">
          1 Бусдыг урамшуулах, сэдэл тэмүүлэл бий болгох{" "}
        </span>
        <span id="tp_49" class="t s2_49">
          2 Өөрөө үлгэр дууриал үзүүлэх{" "}
        </span>
        <span id="tq_49" class="t s2_49">
          3 Дарга, удирдагчгүйгээр ажиллах{" "}
        </span>
        <span id="tr_49" class="t s2_49">
          4 Шинэ санаа санаачлах{" "}
        </span>
        <span id="ts_49" class="t s2_49">
          5 Яаралтай үед самбаачлах{" "}
        </span>
        <span id="tt_49" class="t s2_49">
          6 Асуудлыг олж илрүүлэн, шийдвэрлэх{" "}
        </span>
        <span id="tu_49" class="t s2_49">
          7 Шинэ нөхцөл байдалд дасах{" "}
        </span>
        <span id="tv_49" class="t s2_49">
          8 Үүрэг, хариуцлага хүлээх{" "}
        </span>
        <span id="tw_49" class="t s2_49">
          Бүтээн туурвих, зөн билэгийн{" "}
        </span>
        <span id="tx_49" class="t s2_49">
          1 Гярхай ажигч{" "}
        </span>
        <span id="ty_49" class="t s2_49">
          2 Шинийг санаачлах{" "}
        </span>
        <span id="tz_49" class="t s2_49">
          3 Шүүмжлэх{" "}
        </span>
        <span id="t10_49" class="t s2_49">
          4 Аливаа юмс үзэгдлийн учир шалтгааныг олох{" "}
        </span>
        <span id="t11_49" class="t s2_49">
          5 Алсын хараа{" "}
        </span>
        <span id="t12_49" class="t s2_49">
          6 Бүтээлчээр сэтгэх{" "}
        </span>
        <span id="t13_49" class="t s2_49">
          7 Төсөөлөн бодох{" "}
        </span>
        <span id="t14_49" class="t s2_49">
          Суралцах{" "}
        </span>
        <span id="t15_49" class="t s2_49">
          1 Тэмдэглэх, ажиглах судлах{" "}
        </span>
        <span id="t16_49" class="t s2_49">
          2 Нэгтгэн{" "}
        </span>
        <span id="t17_49" class="t s3_49">
          дүгнэх ¹{" "}
        </span>
        <span id="t18_49" class="t s2_49">
          Тоо, тооллын{" "}
        </span>
        <span id="t19_49" class="t s2_49">
          Оноо{" "}
        </span>
        <span id="t1a_49" class="t s2_49">
          1 Цээжлэх{" "}
        </span>
        <span id="t1b_49" class="t s2_49">
          2 Унших, бичих{" "}
        </span>
        <span id="t1c_49" class="t s2_49">
          3 Асуулт дэвшүүлэн үнэлж дүгнэх{" "}
        </span>
        <span id="t1d_49" class="t s2_49">
          Тоолох, тооцоолох{" "}
        </span>
        <span id="t1e_49" class="t s2_49">
          1 Статистик тоо баримт хэрэглэх{" "}
        </span>
        <span id="t1f_49" class="t s2_49">
          2 Мөнгийг зөв зарцуулах{" "}
        </span>
        <span id="t1g_49" class="t s2_49">
          3 Нягтлан тооцоолох, баримт бичиг хадгалах{" "}
        </span>
        <span id="t1h_49" class="t s2_49">
          4 Төсөв зохиож, хэрэгжүүлэх{" "}
        </span>
        <span id="t1i_49" class="t s2_49">
          5 Урьдчилан тооцоолж, дүгнэх{" "}
        </span>
        <span id="t1j_49" class="t s2_49">
          6 Гарын ур дүй{" "}
        </span>
        <span id="t1k_49" class="t s2_49">
          Мужаан урлал{" "}
        </span>
        <span id="t1l_49" class="t s2_49">
          оноо{" "}
        </span>
        <span id="t1m_49" class="t s2_49">
          1 Багаж, хэрэгслэлийн хэрэглээ{" "}
        </span>
        <span id="t1n_49" class="t s2_49">
          2 Хоол хийх{" "}
        </span>
        <span id="t1o_49" class="t s2_49">
          3 Цэцэрлэгжүүлэх{" "}
        </span>
        <span id="t1p_49" class="t s2_49">
          4 Засах, сэлбэх{" "}
        </span>
        <span id="t1q_49" class="t s2_49">
          5 Нягт нямбай{" "}
        </span>
        <span id="t1r_49" class="t s2_49">
          Заавар, дүрэм журмыг сайн дагаж мөрдөх{" "}
        </span>
        <span id="t1s_49" class="t s2_49">
          1 Цагаа зөв хуваарилан баримтлах{" "}
        </span>
        <span id="t1t_49" class="t s2_49">
          2 Эцсийн хугацааг хүлээх{" "}
        </span>
        <span id="t1u_49" class="t s2_49">
          3 Мэдээллийг тэмдэглэх{" "}
        </span>
        <span id="t1v_49" class="t s2_49">
          4 Үүрэг даалгаврыг биелүүлэх{" "}
        </span>
        <span id="t1w_49" class="t s2_49">
          5 Ажлыг зохион байгуулах{" "}
        </span>
        <span id="t1x_49" class="t s2_49">
          6 Уншиж ойлгох{" "}
        </span>
        <span id="t1y_49" class="t s2_49">
          7 Өгөгдөл тоо мэдээлэл оруулах{" "}
        </span>
        <span id="t1z_49" class="t s2_49">
          8 Ачаалал дааж ажиллах{" "}
        </span>
        <span id="t20_49" class="t s2_49">
          9 Тэмдэглэл хөтлөх{" "}
        </span>
        <span id="t21_49" class="t s2_49">
          10 Урлаг уран сайхан{" "}
        </span>
        <span id="t22_49" class="t s2_49">
          Дүрслэх туурвих{" "}
        </span>
        <span id="t23_49" class="t s2_49">
          оноо{" "}
        </span>
        <span id="t24_49" class="t s2_49">
          1 Дизайн, зохион бүтээх{" "}
        </span>
        <span id="t25_49" class="t s2_49">
          2 Фото зураг авах{" "}
        </span>
        <span id="t26_49" class="t s2_49">
          3 Уран сэтгэмж{" "}
        </span>
        <span id="t27_49" class="t s2_49">
          4 Хөгжөөх{" "}
        </span>
        <span id="t28_49" class="t s2_49">
          5 Зурах, дүрслэх, бодох{" "}
        </span>
        <span id="t29_49" class="t s2_49">
          6 Зурах, дүрслэх, будах{" "}
        </span>
        <span id="t2a_49" class="t s4_49">
          Дасгал{" "}
        </span>
        <span id="t2b_49" class="t s1_49">
          Суралцагчид өөрсдийн ур чадвараа үнэлж, сонирхсон ажил мэргэжлээ
          сонгоход баримжаа{" "}
        </span>
        <span id="t2c_49" class="t s1_49">
          болгож болно. Энэхүү дасгал нь таны ур чадварууд, хийх дуртай ажлыг
          тодорхойлон,{" "}
        </span>
        <span id="t2d_49" class="t s1_49">
          зорилго мөрөөдлийг ажил хөдөлмөртэй уялдуулах нэгэн алхам болох юм.{" "}
        </span>
        <span id="t2e_49" class="t s1_49">
          1- Муу 2- Багавтар бий 3- Дундаж 4- Сайн 5- Маш сайн ¹{" "}
        </span>
      </div>
    </div>
  );
}
