import React from "react";
;

export default function Ten() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_10{left:34px;bottom:19px;}
#t2_10{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_10{left:110px;bottom:1157px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t4_10{left:153px;bottom:1133px;}
#t5_10{left:180px;bottom:1133px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t6_10{left:153px;bottom:1114px;}
#t7_10{left:180px;bottom:1114px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_10{left:153px;bottom:1096px;}
#t9_10{left:180px;bottom:1096px;letter-spacing:-0.17px;word-spacing:4.66px;}
#ta_10{left:180px;bottom:1078px;letter-spacing:-0.14px;}
#tb_10{left:153px;bottom:1060px;}
#tc_10{left:180px;bottom:1060px;letter-spacing:-0.11px;word-spacing:0.01px;}
#td_10{left:153px;bottom:1042px;}
#te_10{left:180px;bottom:1042px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tf_10{left:153px;bottom:1024px;}
#tg_10{left:180px;bottom:1024px;letter-spacing:-0.14px;word-spacing:0.15px;}
#th_10{left:180px;bottom:1006px;letter-spacing:-0.19px;}
#ti_10{left:153px;bottom:987px;}
#tj_10{left:180px;bottom:987px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tk_10{left:153px;bottom:969px;}
#tl_10{left:180px;bottom:969px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tm_10{left:110px;bottom:945px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tn_10{left:153px;bottom:921px;}
#to_10{left:180px;bottom:921px;letter-spacing:-0.14px;word-spacing:7.17px;}
#tp_10{left:180px;bottom:903px;letter-spacing:-0.15px;word-spacing:1.36px;}
#tq_10{left:180px;bottom:885px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tr_10{left:110px;bottom:848px;letter-spacing:-0.15px;word-spacing:3.07px;}
#ts_10{left:110px;bottom:830px;letter-spacing:-0.14px;word-spacing:-0.94px;}
#tt_10{left:110px;bottom:812px;letter-spacing:-0.15px;word-spacing:-0.64px;}
#tu_10{left:110px;bottom:794px;letter-spacing:-0.13px;word-spacing:1.97px;}
#tv_10{left:110px;bottom:776px;letter-spacing:-0.15px;word-spacing:1.2px;}
#tw_10{left:110px;bottom:758px;letter-spacing:-0.15px;word-spacing:1.12px;}
#tx_10{left:110px;bottom:739px;letter-spacing:-0.16px;word-spacing:0.03px;}
#ty_10{left:110px;bottom:721px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tz_10{left:110px;bottom:703px;letter-spacing:-0.14px;word-spacing:3.2px;}
#t10_10{left:110px;bottom:685px;letter-spacing:-0.18px;word-spacing:1.17px;}
#t11_10{left:110px;bottom:667px;letter-spacing:-0.13px;word-spacing:7.89px;}
#t12_10{left:110px;bottom:649px;letter-spacing:-0.15px;word-spacing:3.72px;}
#t13_10{left:110px;bottom:631px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_10{left:110px;bottom:613px;letter-spacing:-0.16px;word-spacing:7.2px;}
#t15_10{left:110px;bottom:594px;letter-spacing:-0.14px;word-spacing:-0.11px;}
#t16_10{left:110px;bottom:576px;letter-spacing:-0.13px;word-spacing:1.88px;}
#t17_10{left:110px;bottom:558px;letter-spacing:-0.14px;word-spacing:3.55px;}
#t18_10{left:110px;bottom:540px;letter-spacing:-0.14px;word-spacing:-0.29px;}
#t19_10{left:110px;bottom:522px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1a_10{left:110px;bottom:479px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1b_10{left:110px;bottom:461px;letter-spacing:-0.13px;word-spacing:4.89px;}
#t1c_10{left:110px;bottom:443px;letter-spacing:-0.15px;word-spacing:6.83px;}
#t1d_10{left:110px;bottom:425px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1e_10{left:110px;bottom:407px;letter-spacing:-0.16px;word-spacing:3.54px;}
#t1f_10{left:110px;bottom:389px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1g_10{left:110px;bottom:371px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1h_10{left:153px;bottom:346px;}
#t1i_10{left:180px;bottom:346px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1j_10{left:153px;bottom:328px;}
#t1k_10{left:180px;bottom:328px;letter-spacing:-0.21px;word-spacing:0.14px;}
#t1l_10{left:180px;bottom:310px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1m_10{left:110px;bottom:286px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t1n_10{left:153px;bottom:262px;}
#t1o_10{left:180px;bottom:262px;letter-spacing:-0.16px;word-spacing:3.81px;}
#t1p_10{left:180px;bottom:243px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1q_10{left:153px;bottom:225px;}
#t1r_10{left:180px;bottom:225px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1s_10{left:153px;bottom:207px;}
#t1t_10{left:180px;bottom:207px;letter-spacing:-0.12px;word-spacing:0.88px;}
#t1u_10{left:180px;bottom:189px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1v_10{left:153px;bottom:171px;}
#t1w_10{left:180px;bottom:171px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1x_10{left:153px;bottom:153px;}
#t1y_10{left:180px;bottom:153px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1z_10{left:153px;bottom:135px;}
#t20_10{left:180px;bottom:135px;letter-spacing:-0.14px;word-spacing:6.25px;}
#t21_10{left:180px;bottom:117px;letter-spacing:-0.18px;word-spacing:0.08px;}

.s0_10{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_10{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_10{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}`}
        </style>
        <style id="fonts10" type="text/css">
          {`@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg2" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="10/10.svg"
          type="image/svg+xml"
          id="pdf9"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_10" class="t s0_10">
          8{" "}
        </span>
        <span id="t2_10" class="t s1_10">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_10" class="t s1_10">
          Сургуулийн үйл ажиллагаа{" "}
        </span>
        <span id="t4_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t5_10" class="t s1_10">
          Тэмдэглэлт болон уламжлалт үйл ажиллагаа{" "}
        </span>
        <span id="t6_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t7_10" class="t s1_10">
          Амжилтаа тайлагнах үйл ажиллагаа{" "}
        </span>
        <span id="t8_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t9_10" class="t s1_10">
          Сургуулийн өдөр тутмын дэг, соёл, эрүүл аюулгүй байдалтай холбоотой
          үйл{" "}
        </span>
        <span id="ta_10" class="t s1_10">
          ажиллагаа{" "}
        </span>
        <span id="tb_10" class="t s1_10">
          -{" "}
        </span>
        <span id="tc_10" class="t s1_10">
          Ажил хөдөлмөр, нийгмийн сайн сайхны төлөө хийх үйл ажиллагаа{" "}
        </span>
        <span id="td_10" class="t s1_10">
          -{" "}
        </span>
        <span id="te_10" class="t s1_10">
          Сургууль доторх ургамал хамгаалал{" "}
        </span>
        <span id="tf_10" class="t s1_10">
          -{" "}
        </span>
        <span id="tg_10" class="t s1_10">
          Сургуулийн цайны газар, сургуулийн цайны газар дахь үүрэг хариуцлагын
          зохион{" "}
        </span>
        <span id="th_10" class="t s1_10">
          байгуулалт{" "}
        </span>
        <span id="ti_10" class="t s1_10">
          -{" "}
        </span>
        <span id="tj_10" class="t s1_10">
          Сургуулийн номын санчдад туслах{" "}
        </span>
        <span id="tk_10" class="t s1_10">
          -{" "}
        </span>
        <span id="tl_10" class="t s1_10">
          Аялал, зугаалга, эх оронтойгоо танилцах тойрон аялал{" "}
        </span>
        <span id="tm_10" class="t s1_10">
          Сурагчдын зөвлөлийн үйл ажиллагаа{" "}
        </span>
        <span id="tn_10" class="t s1_10">
          -{" "}
        </span>
        <span id="to_10" class="t s1_10">
          Сургууль, орон нутгийн сайн сайхан байдал, хөгжлийг дэмжихэд тодорхой{" "}
        </span>
        <span id="tp_10" class="t s1_10">
          чиглэлийн дагуу хувь нэмрээ оруулахтай холбоотой үйл ажиллагаа (сайн
          дурын{" "}
        </span>
        <span id="tq_10" class="t s1_10">
          үйл ажиллагаа, эко клуб г.м{" "}
        </span>
        <span id="tr_10" class="t s1_10">
          Хичээлээс гадуурх сургалтын ажлыг сургуулийн захирал, нийгмийн ажилтны
          удирдлага{" "}
        </span>
        <span id="ts_10" class="t s1_10">
          чиглүүлгээр анги удирдсан эсхүл мэргэжлийн болон сайн дурын багш нар
          сургуулийн бодит{" "}
        </span>
        <span id="tt_10" class="t s1_10">
          байдал, сурагчдын бүтээлч санаачилга, онцлог, хөгжлийг харгалзан гэр
          бүл, нутгийн оршин{" "}
        </span>
        <span id="tu_10" class="t s1_10">
          суугчид, орон нутгийн байгууллагуудтай хамтран бэлтгэл сайтай, үр
          дүнтэй хэрэгжихээр{" "}
        </span>
        <span id="tv_10" class="t s1_10">
          зохион байгуулна. Үүнээс гадна хичээлээс гадуурх сургалтын хөтөлбөрийг
          боловсруулах,{" "}
        </span>
        <span id="tw_10" class="t s1_10">
          хэлэлцүүлэх, сонгуулах, үйл ажиллагааг зохион байгуулах, үр дүнг
          үнэлэх, сайжруулахад{" "}
        </span>
        <span id="tx_10" class="t s1_10">
          сурагч, эцэг эх, асран хамгаалагч, орон нутгийн удирдлага, боловсролын
          болон олон нийт,{" "}
        </span>
        <span id="ty_10" class="t s1_10">
          бусад байгууллага, сонирхлын бүлгүүдийн оролцоог хангаж, хамтран
          ажиллана.{" "}
        </span>
        <span id="tz_10" class="t s1_10">
          Багш хичээлээс гадуурх сургалтын ажлын явцад суралцагчийн эрүүл
          аюулгүй байдлыг{" "}
        </span>
        <span id="t10_10" class="t s1_10">
          ханган ажиллахад эцэг, эх, асран хамгаалагч, орон нутгийн иргэд
          холбогдох байгууллага,{" "}
        </span>
        <span id="t11_10" class="t s1_10">
          мэргэжлийн хүмүүстэй хамтран ажиллахаас гадна тэднийг хараа хяналтгүй
          орхих,{" "}
        </span>
        <span id="t12_10" class="t s1_10">
          хүчирхийлэл, хорт зуршил, авто замын болон болзошгүй эрсдэлд өртөхөөс
          сэргийлэх,{" "}
        </span>
        <span id="t13_10" class="t s1_10">
          нийгмийн сөрөг үзэгдлээс урьдчилан сэргийлэх, хамгаалах бэлтгэлийг
          сайтар хангана.{" "}
        </span>
        <span id="t14_10" class="t s1_10">
          Хичээлээс гадуурх сургалтын ажил нь суралцагчдад судлан шинжлэх арга
          барилд{" "}
        </span>
        <span id="t15_10" class="t s1_10">
          суралцах, бусадтай хамтран асуудлыг эргэцүүлэн бодож, шийдвэрлэх,
          илэрхийлэх явцдаа{" "}
        </span>
        <span id="t16_10" class="t s1_10">
          авьяасаа хөгжүүлэх, хамтран ажиллах, харилцан суралцах боломжийг
          олгохын зэрэгцээ{" "}
        </span>
        <span id="t17_10" class="t s1_10">
          ажил мэргэжлийн чиглэлээр судалгаа хийх, биечлэн танилцах, ажиглах,
          дадлага хийх,{" "}
        </span>
        <span id="t18_10" class="t s1_10">
          бусдад туслах сайн дурын үйл ажиллагаа зохион байгуулах зэргээр анги
          танхимаас гадуур{" "}
        </span>
        <span id="t19_10" class="t s1_10">
          хичээллэх боломжийг бүрдүүлнэ.{" "}
        </span>
        <span id="t1a_10" class="t s2_10">
          6.3. Орчин, хэрэглэгдэхүүн{" "}
        </span>
        <span id="t1b_10" class="t s1_10">
          Сургуулиуд хүүхдийн бие бялдар, оюун ухаан, нийгэмшихүйн хөгжлийг
          хангах, өсвөр{" "}
        </span>
        <span id="t1c_10" class="t s1_10">
          үеийнхний оролцоо, хамгааллын асуудлыг цогц байдлаар шийдсэн, хүүхдэд
          ээлтэй{" "}
        </span>
        <span id="t1d_10" class="t s1_10">
          сургалтын орчин бүрдүүлсэн байна.{" "}
        </span>
        <span id="t1e_10" class="t s1_10">
          Сургалтыг танхимын ба танхимын бус хэлбэрээр хичээл, хичээлээс гадуурх
          сургалтын{" "}
        </span>
        <span id="t1f_10" class="t s1_10">
          ажил хоорондын интеграцыг ханган зохион байгуулна.{" "}
        </span>
        <span id="t1g_10" class="t s1_10">
          Танхимын орчинд:{" "}
        </span>
        <span id="t1h_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1i_10" class="t s1_10">
          Багаар, бүлгээр уулзалт, ярилцлага, хэлэлцүүлэг зохион байгуулах{" "}
        </span>
        <span id="t1j_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1k_10" class="t s1_10">
          Урлаг, спорт, хурлын заал, анги танхим дахь үйл ажиллагаа, илтгэл
          тавих, тайлан{" "}
        </span>
        <span id="t1l_10" class="t s1_10">
          бичих, хамгаалах{" "}
        </span>
        <span id="t1m_10" class="t s1_10">
          Танхимын бус орчинд:{" "}
        </span>
        <span id="t1n_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1o_10" class="t s1_10">
          Орон нутгийн төрийн болон төрийн бус байгууллага, олон улсын
          байгууллага{" "}
        </span>
        <span id="t1p_10" class="t s1_10">
          сургалтын төвийн үйл ажиллагаатай танилцах аялал экскурс хийх{" "}
        </span>
        <span id="t1q_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1r_10" class="t s1_10">
          Сургууль, эцэг эхийн хамтын ажиллагаанд тулгуурласан үйл ажиллагаа{" "}
        </span>
        <span id="t1s_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1t_10" class="t s1_10">
          Хувиараа хөдөлмөр эрхлэгч, жижиг дунд бизнес эрхлэгчидтэй хамтарсан
          төсөлт{" "}
        </span>
        <span id="t1u_10" class="t s1_10">
          ажил, судалгаа хийх{" "}
        </span>
        <span id="t1v_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1w_10" class="t s1_10">
          Бүтээлч санал санаачилга бүхий сайн үйлсийн аян өрнүүлэх{" "}
        </span>
        <span id="t1x_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t1y_10" class="t s1_10">
          Зайн сургалтын хэлбэрүүдийг хөгжүүлэх{" "}
        </span>
        <span id="t1z_10" class="t s1_10">
          -{" "}
        </span>
        <span id="t20_10" class="t s1_10">
          Орон нутгийн мэргэжлийн байгууллагын байр, танхим, олон нийтийн газар,{" "}
        </span>
        <span id="t21_10" class="t s1_10">
          байгаль орчныг түшиглэн явуулна.{" "}
        </span>
      </div>
    </div>
  );
}
