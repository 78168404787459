import {
  Box,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SideBar from "./DrawerMenu";
import One from "./pages/one";
import Two from "./pages/two";
import Three from "./pages/three";
import Four from "./pages/4";
import Five from "./pages/5";
import Six from "./pages/6";
import Seven from "./pages/7";
import Naim from "./pages/8";
import Yus from "./pages/9";
import Ten from "./pages/10";
import OneTen from "./pages/11";
import TwoTen from "./pages/12";
import ThreeTen from "./pages/13";
import FourTen from "./pages/14";
import SixOne from "./pages/61";
import SixTwo from "./pages/62";
import SixZero from "./pages/60";
import FiveNine from "./pages/59";
import FiveEight from "./pages/58";
import FiveSeven from "./pages/57";
import FiveSix from "./pages/56";
import FiveFive from "./pages/55";
import FiveFour from "./pages/54";
import FiveThree from "./pages/53";
import FiveTwo from "./pages/52";
import FiveOne from "./pages/51";
import FiveZero from "./pages/50";
import FourNine from "./pages/49";
import FourEight from "./pages/48";
import FourSeven from "./pages/47";
import FourSix from "./pages/46";
import FourFive from "./pages/45";
import FourFour from "./pages/44";
import FourThree from "./pages/43";
import FourTwo from "./pages/42";
import FourOne from "./pages/41";
import FourZero from "./pages/40";
import ThreeNine from "./pages/39";
import ThirtyEight from "./pages/38";
import ThirtySeven from "./pages/37";
import ThirtySix from "./pages/36";
import ThirtyFive from "./pages/35";
import ThirtyFour from "./pages/34";
import ThirtyThree from "./pages/33";
import ThirtyTwo from "./pages/32";
import ThirtyOne from "./pages/31";
import Thirty from "./pages/30";
import TwentyNine from "./pages/29";
import TwentyEight from "./pages/28";
import TwentySeven from "./pages/27";
import TwentySix from "./pages/26";
import TwentyFive from "./pages/25";
import TwentyFour from "./pages/24";
import TwentyThree from "./pages/23";
import TwentyTwo from "./pages/22";
import TwentyOne from "./pages/21";
import Twenty from "./pages/20";
import Nineteen from "./pages/19";
import Eighteen from "./pages/18";
import SevenTen from "./pages/17";
import FiveTen from "./pages/15";
import SixTen from "./pages/16";
import { useEffect, useState } from "react";
// import { useRouter } from "next/router";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ViewChange from "./pdf";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useParams } from "react-router-dom";

const defaultDrawerWidth = 340;
const menu = [
  {
    name: 1,
    value: 1,
  },
  {
    name: 2,
    value: 2,
  },
  {
    name: 3,
    value: 3,
  },
  {
    name: 4,
    value: 4,
  },
  {
    name: 5,
    value: 5,
  },
  {
    name: 6,
    value: 6,
  },
  {
    name: 7,
    value: 7,
  },
  {
    name: 8,
    value: 8,
  },
  {
    name: 9,
    value: 9,
  },
  {
    name: 10,
    value: 10,
  },
  {
    name: 11,
    value: 11,
  },
  {
    name: 12,
    value: 12,
  },
  {
    name: 13,
    value: 13,
  },
  {
    name: 14,
    value: 14,
  },
  {
    name: 15,
    value: 15,
  },
  {
    name: 16,
    value: 16,
  },
  {
    name: 17,
    value: 17,
  },
  {
    name: 18,
    value: 18,
  },
  {
    name: 19,
    value: 19,
  },
  {
    name: 20,
    value: 20,
  },
  {
    name: 21,
    value: 21,
  },
  {
    name: 22,
    value: 22,
  },
  {
    name: 23,
    value: 23,
  },
  {
    name: 24,
    value: 24,
  },
  {
    name: 25,
    value: 25,
  },
  {
    name: 26,
    value: 26,
  },
  {
    name: 27,
    value: 27,
  },
  {
    name: 28,
    value: 28,
  },
  {
    name: 29,
    value: 29,
  },
  {
    name: 30,
    value: 30,
  },
  {
    name: 31,
    value: 31,
  },
  {
    name: 32,
    value: 32,
  },
  {
    name: 33,
    value: 33,
  },
  {
    name: 34,
    value: 34,
  },
  {
    name: 35,
    value: 35,
  },
  {
    name: 36,
    value: 36,
  },
  {
    name: 37,
    value: 37,
  },
  {
    name: 38,
    value: 38,
  },
  {
    name: 39,
    value: 39,
  },
  {
    name: 40,
    value: 40,
  },
  {
    name: 41,
    value: 41,
  },
  {
    name: 42,
    value: 42,
  },
  {
    name: 43,
    value: 43,
  },
  {
    name: 44,
    value: 44,
  },
  {
    name: 45,
    value: 45,
  },
  {
    name: 46,
    value: 46,
  },
  {
    name: 47,
    value: 47,
  },
  {
    name: 48,
    value: 48,
  },
  {
    name: 49,
    value: 49,
  },
  {
    name: 50,
    value: 50,
  },
  {
    name: 51,
    value: 51,
  },
  {
    name: 52,
    value: 52,
  },
  {
    name: 53,
    value: 53,
  },
  {
    name: 54,
    value: 54,
  },
  {
    name: 55,
    value: 55,
  },
  {
    name: 56,
    value: 56,
  },
  {
    name: 57,
    value: 57,
  },
  {
    name: 58,
    value: 58,
  },
  {
    name: 59,
    value: 59,
  },
  {
    name: 60,
    value: 60,
  },
];

export default function Home() {
  const [paperIndex, setPaperIndex] = useState(2);
  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState(true);

  const RR = (e) => {
    if (e) {
      const newIndex = e.value;
      setPaperIndex(newIndex);
      window.location.href = `/#${newIndex}`;
    } else window.location.href = `/#0`;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          maxWidth: "100%",
        }}
      >
        <Drawer
          anchor="left"
          sx={{
            width: defaultDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: defaultDrawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
        >
          <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 500,
                fontSize: "16px",
                color: "rgb(59, 130, 246)",
              }}
              primary={
                "Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан хувилбар)"
              }
            />
          </ListItemButton>
          <Box sx={{ ml: 3 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container>
                <Grid item xs={10}>
                  <Autocomplete
                    id="multiple-limit-tags"
                    options={menu}
                    disableCloseOnSelect
                    onChange={(e, a) => {
                      RR(a);
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Хуудсаар хайх:" />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    sx={{ mx: 1 }}
                    color="inherit"
                    onClick={() => {
                      setPdf(!pdf);
                    }}
                  >
                    {pdf ? <PictureAsPdfIcon /> : <MenuBookIcon />}
                  </IconButton>
                </Grid>
              </Grid>
              <SideBar setPaperIndex={setPaperIndex} paperIndex={paperIndex} />
            </Collapse>
          </Box>
        </Drawer>
        <Box
          sx={{
            width: "180vh",
            mt: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {pdf ? (
              <Box>
                <div id="0">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <One />
                  </Box>
                </div>
                <div id="1">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Two />
                  </Box>
                </div>
                <div id="1">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Three />
                  </Box>
                </div>
                <div id="2">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Four />
                  </Box>
                </div>
                <div id="3">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Five />
                  </Box>
                </div>
                <div id="4">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Six />
                  </Box>
                </div>
                <div id="5">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Seven />
                  </Box>
                </div>
                <div id="6">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Naim />
                  </Box>
                </div>
                <div id="7">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Yus />
                  </Box>
                </div>
                <div id="8">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Ten />
                  </Box>
                </div>
                <div id="9">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <OneTen />
                  </Box>
                </div>
                <div id="10">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwoTen />
                  </Box>
                </div>
                <div id="11">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThreeTen />
                  </Box>
                </div>
                <div id="12">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourTen />
                  </Box>
                </div>
                <div id="13">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveTen />
                  </Box>
                </div>
                <div id="14">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixTen />
                  </Box>
                </div>
                <div id="15">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SevenTen />
                  </Box>
                </div>
                <div id="16">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Eighteen />
                  </Box>
                </div>
                <div id="17">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Nineteen />
                  </Box>
                </div>
                <div id="18">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Twenty />
                  </Box>
                </div>
                <div id="19">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyOne />
                  </Box>
                </div>
                <div id="20">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyTwo />
                  </Box>
                </div>
                <div id="21">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyThree />
                  </Box>
                </div>
                <div id="22">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyFour />
                  </Box>
                </div>
                <div id="23">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyFive />
                  </Box>
                </div>
                <div id="24">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentySix />
                  </Box>
                </div>
                <div id="25">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentySeven />
                  </Box>
                </div>
                <div id="26">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyEight />
                  </Box>
                </div>
                <div id="27">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyNine />
                  </Box>
                </div>
                <div id="28">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Thirty />
                  </Box>
                </div>
                <div id="29">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyOne />
                  </Box>
                </div>
                <div id="30">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyTwo />
                  </Box>
                </div>
                <div id="31">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyThree />
                  </Box>
                </div>
                <div id="32">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyFour />
                  </Box>
                </div>
                <div id="33">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyFive />
                  </Box>
                </div>
                <div id="34">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtySix />
                  </Box>
                </div>
                <div id="35">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtySeven />
                  </Box>
                </div>
                <div id="36">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyEight />
                  </Box>
                </div>
                <div id="37">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThreeNine />
                  </Box>
                </div>
                <div id="38">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourZero />
                  </Box>
                </div>
                <div id="39">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourOne />
                  </Box>
                </div>
                <div id="40">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourTwo />
                  </Box>
                </div>
                <div id="41">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourThree />
                  </Box>
                </div>
                <div id="42">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourFour />
                  </Box>
                </div>
                <div id="43">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourFive />
                  </Box>
                </div>
                <div id="44">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourSix />
                  </Box>
                </div>
                <div id="45">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourSeven />
                  </Box>
                </div>
                <div id="46">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourEight />
                  </Box>
                </div>
                <div id="47">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourNine />
                  </Box>
                </div>
                <div id="48">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveZero />
                  </Box>
                </div>
                <div id="49">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveOne />
                  </Box>
                </div>
                <div id="50">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveTwo />
                  </Box>
                </div>
                <div id="51">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveThree />
                  </Box>
                </div>
                <div id="52">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveFour />
                  </Box>
                </div>
                <div id="53">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveFive />
                  </Box>
                </div>
                <div id="54">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveSix />
                  </Box>
                </div>
                <div id="55">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveSeven />
                  </Box>
                </div>
                <div id="56">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveEight />
                  </Box>
                </div>
                <div id="57">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveNine />
                  </Box>
                </div>
                <div id="58">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixZero />
                  </Box>
                </div>
                <div id="59">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixOne />
                  </Box>
                </div>
                <div id="60">
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixTwo />
                  </Box>
                </div>
              </Box>
            ) : (
              <Box sx={{ width: "80%" }}>
                <ViewChange />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
