import React from "react";
;

export default function FiveSeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_57{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_57{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_57{left:149px;bottom:1151px;letter-spacing:0.12px;word-spacing:0.03px;}
#t4_57{left:279px;bottom:1151px;letter-spacing:0.1px;word-spacing:0.05px;}
#t5_57{left:641px;bottom:1151px;letter-spacing:0.11px;word-spacing:0.04px;}
#t6_57{left:117px;bottom:1128px;}
#t7_57{left:149px;bottom:1128px;letter-spacing:0.13px;}
#t8_57{left:149px;bottom:1109px;letter-spacing:0.16px;}
#t9_57{left:279px;bottom:1128px;letter-spacing:0.04px;word-spacing:0.09px;}
#ta_57{left:279px;bottom:1109px;letter-spacing:0.1px;word-spacing:0.04px;}
#tb_57{left:279px;bottom:1091px;letter-spacing:0.1px;word-spacing:0.04px;}
#tc_57{left:641px;bottom:1046px;letter-spacing:0.08px;word-spacing:5.32px;}
#td_57{left:641px;bottom:1028px;letter-spacing:0.01px;word-spacing:10.65px;}
#te_57{left:641px;bottom:1009px;letter-spacing:0.08px;}
#tf_57{left:668px;bottom:1009px;letter-spacing:0.06px;word-spacing:0.07px;}
#tg_57{left:641px;bottom:991px;letter-spacing:0.15px;word-spacing:5.26px;}
#th_57{left:641px;bottom:973px;letter-spacing:0.08px;}
#ti_57{left:668px;bottom:973px;letter-spacing:0.1px;word-spacing:0.02px;}
#tj_57{left:668px;bottom:954px;letter-spacing:0.16px;}
#tk_57{left:641px;bottom:936px;letter-spacing:0.15px;word-spacing:5.26px;}
#tl_57{left:641px;bottom:918px;letter-spacing:0.14px;word-spacing:5.26px;}
#tm_57{left:668px;bottom:899px;letter-spacing:0.16px;}
#tn_57{left:641px;bottom:863px;letter-spacing:0.11px;word-spacing:0.03px;}
#to_57{left:641px;bottom:844px;letter-spacing:0.09px;word-spacing:0.06px;}
#tp_57{left:641px;bottom:826px;letter-spacing:0.1px;word-spacing:0.04px;}
#tq_57{left:641px;bottom:808px;letter-spacing:0.12px;word-spacing:0.01px;}
#tr_57{left:641px;bottom:789px;letter-spacing:0.07px;word-spacing:0.07px;}
#ts_57{left:641px;bottom:771px;letter-spacing:0.14px;}
#tt_57{left:641px;bottom:753px;letter-spacing:0.1px;word-spacing:0.07px;}
#tu_57{left:117px;bottom:1068px;}
#tv_57{left:149px;bottom:1068px;letter-spacing:-0.01px;}
#tw_57{left:149px;bottom:1049px;letter-spacing:0.09px;}
#tx_57{left:279px;bottom:1068px;letter-spacing:0.04px;word-spacing:0.09px;}
#ty_57{left:279px;bottom:1049px;letter-spacing:0.08px;word-spacing:0.06px;}
#tz_57{left:279px;bottom:1031px;letter-spacing:0.11px;word-spacing:0.04px;}
#t10_57{left:117px;bottom:1008px;}
#t11_57{left:149px;bottom:1008px;word-spacing:-0.85px;}
#t12_57{left:149px;bottom:989px;letter-spacing:0.09px;}
#t13_57{left:279px;bottom:1008px;letter-spacing:0.04px;word-spacing:0.09px;}
#t14_57{left:279px;bottom:989px;letter-spacing:0.08px;word-spacing:0.05px;}
#t15_57{left:279px;bottom:971px;letter-spacing:0.15px;}
#t16_57{left:117px;bottom:948px;}
#t17_57{left:149px;bottom:948px;letter-spacing:0.15px;}
#t18_57{left:279px;bottom:948px;letter-spacing:0.04px;word-spacing:0.09px;}
#t19_57{left:279px;bottom:929px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1a_57{left:279px;bottom:911px;letter-spacing:0.08px;word-spacing:0.07px;}
#t1b_57{left:117px;bottom:888px;}
#t1c_57{left:149px;bottom:888px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1d_57{left:149px;bottom:869px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1e_57{left:149px;bottom:851px;letter-spacing:0.11px;}
#t1f_57{left:279px;bottom:888px;letter-spacing:0.04px;word-spacing:0.09px;}
#t1g_57{left:279px;bottom:869px;letter-spacing:0.03px;word-spacing:0.11px;}
#t1h_57{left:117px;bottom:828px;}
#t1i_57{left:149px;bottom:828px;letter-spacing:0.14px;}
#t1j_57{left:149px;bottom:809px;letter-spacing:0.02px;}
#t1k_57{left:149px;bottom:791px;letter-spacing:0.04px;}
#t1l_57{left:149px;bottom:773px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1m_57{left:279px;bottom:828px;letter-spacing:0.04px;word-spacing:0.09px;}
#t1n_57{left:279px;bottom:809px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1o_57{left:279px;bottom:791px;letter-spacing:-0.18px;}
#t1p_57{left:117px;bottom:749px;}
#t1q_57{left:149px;bottom:749px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1r_57{left:279px;bottom:749px;letter-spacing:0.04px;word-spacing:0.09px;}
#t1s_57{left:279px;bottom:731px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1t_57{left:279px;bottom:713px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1u_57{left:149px;bottom:689px;letter-spacing:0.11px;}
#t1v_57{left:149px;bottom:671px;letter-spacing:0.04px;word-spacing:0.08px;}
#t1w_57{left:149px;bottom:653px;letter-spacing:0.16px;}
#t1x_57{left:279px;bottom:689px;letter-spacing:0.04px;word-spacing:0.09px;}
#t1y_57{left:279px;bottom:671px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1z_57{left:110px;bottom:615px;letter-spacing:-0.21px;word-spacing:0.1px;}
#t20_57{left:110px;bottom:597px;letter-spacing:-0.18px;word-spacing:-1.12px;}
#t21_57{left:110px;bottom:579px;letter-spacing:-0.15px;word-spacing:4.62px;}
#t22_57{left:110px;bottom:561px;letter-spacing:-0.15px;word-spacing:0.68px;}
#t23_57{left:110px;bottom:542px;letter-spacing:-0.12px;word-spacing:1.21px;}
#t24_57{left:110px;bottom:524px;letter-spacing:-0.16px;word-spacing:-0.41px;}
#t25_57{left:110px;bottom:488px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t26_57{left:110px;bottom:470px;letter-spacing:-0.18px;word-spacing:3.16px;}
#t27_57{left:110px;bottom:452px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t28_57{left:110px;bottom:434px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t29_57{left:165px;bottom:415px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t2a_57{left:347px;bottom:415px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t2b_57{left:110px;bottom:397px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t2c_57{left:165px;bottom:379px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t2d_57{left:551px;bottom:379px;letter-spacing:-0.19px;word-spacing:0.1px;}
#t2e_57{left:165px;bottom:361px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t2f_57{left:555px;bottom:361px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t2g_57{left:110px;bottom:343px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t2h_57{left:165px;bottom:325px;letter-spacing:-0.07px;word-spacing:-0.02px;}
#t2i_57{left:276px;bottom:325px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t2j_57{left:397px;bottom:325px;letter-spacing:-0.04px;word-spacing:-0.05px;}
#t2k_57{left:490px;bottom:325px;letter-spacing:-0.12px;}
#t2l_57{left:601px;bottom:325px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t2m_57{left:110px;bottom:307px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t2n_57{left:365px;bottom:307px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t2o_57{left:525px;bottom:307px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t2p_57{left:165px;bottom:289px;letter-spacing:-0.08px;word-spacing:-0.01px;}
#t2q_57{left:346px;bottom:289px;letter-spacing:-0.08px;word-spacing:-0.02px;}
#t2r_57{left:467px;bottom:289px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t2s_57{left:682px;bottom:289px;letter-spacing:-0.08px;word-spacing:-0.02px;}
#t2t_57{left:110px;bottom:270px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t2u_57{left:110px;bottom:252px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t2v_57{left:165px;bottom:234px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t2w_57{left:500px;bottom:234px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t2x_57{left:165px;bottom:216px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t2y_57{left:440px;bottom:216px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t2z_57{left:110px;bottom:198px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t30_57{left:110px;bottom:180px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t31_57{left:220px;bottom:162px;letter-spacing:-0.05px;word-spacing:-0.04px;}
#t32_57{left:328px;bottom:162px;letter-spacing:-0.17px;word-spacing:0.07px;}

.s0_57{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_57{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_57{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s3_57{font-size:15px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_57{font-size:15px;font-family:ArialMT_5q;color:#231F20;}
.s5_57{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}`}
        </style>

        <style id="fonts57" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg57" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="57/57.svg"
          type="image/svg+xml"
          id="pdf57"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_57" class="t s0_57">
          55{" "}
        </span>
        <span id="t2_57" class="t s1_57">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_57" class="t s2_57">
          Асуултын төрөл{" "}
        </span>
        <span id="t4_57" class="t s2_57">
          Асуултын хувилбар{" "}
        </span>
        <span id="t5_57" class="t s2_57">
          Хариултын хувилбар{" "}
        </span>
        <span id="t6_57" class="t s2_57">
          1{" "}
        </span>
        <span id="t7_57" class="t s2_57">
          Хүлээн{" "}
        </span>
        <span id="t8_57" class="t s2_57">
          зөвшөөрөх{" "}
        </span>
        <span id="t9_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="ta_57" class="t s2_57">
          асран хамгаалагчийн шууд оролцоог хүлээн{" "}
        </span>
        <span id="tb_57" class="t s2_57">
          зөвшөөрч байна уу?{" "}
        </span>
        <span id="tc_57" class="t s2_57">
          1. Эрс татгалзах{" "}
        </span>
        <span id="td_57" class="t s2_57">
          2. Татгалзах{" "}
        </span>
        <span id="te_57" class="t s2_57">
          3.{" "}
        </span>
        <span id="tf_57" class="t s2_57">
          Бага зэрэг татгалзах{" "}
        </span>
        <span id="tg_57" class="t s2_57">
          4. Төвийг сахих{" "}
        </span>
        <span id="th_57" class="t s2_57">
          5.{" "}
        </span>
        <span id="ti_57" class="t s2_57">
          Бага зэрэг хүлээн{" "}
        </span>
        <span id="tj_57" class="t s2_57">
          зөвшөөрөх{" "}
        </span>
        <span id="tk_57" class="t s2_57">
          6. Хүлээн зөвшөөрөх{" "}
        </span>
        <span id="tl_57" class="t s2_57">
          7. Бүрэн хүлээн{" "}
        </span>
        <span id="tm_57" class="t s2_57">
          зөвшөөрөх{" "}
        </span>
        <span id="tn_57" class="t s3_57">
          7 үзүүлэлтээр{" "}
        </span>
        <span id="to_57" class="t s3_57">
          хэмжээсжүүлэлт хийх{" "}
        </span>
        <span id="tp_57" class="t s3_57">
          ба 1 бол 0 хувь, 4 бол 50{" "}
        </span>
        <span id="tq_57" class="t s3_57">
          хувь, 7 бол 100 хувь гэж{" "}
        </span>
        <span id="tr_57" class="t s3_57">
          үзэж болох ба 7 түвшин{" "}
        </span>
        <span id="ts_57" class="t s3_57">
          нь 5 ба 3 түвшинд{" "}
        </span>
        <span id="tt_57" class="t s3_57">
          шилжих боломжтой.{" "}
        </span>
        <span id="tu_57" class="t s4_57">
          2{" "}
        </span>
        <span id="tv_57" class="t s2_57">
          Тохиромжтой{" "}
        </span>
        <span id="tw_57" class="t s2_57">
          байдал{" "}
        </span>
        <span id="tx_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг,{" "}
        </span>
        <span id="ty_57" class="t s2_57">
          эх, асран хамгаалагчид шууд оролцох нь{" "}
        </span>
        <span id="tz_57" class="t s2_57">
          тохиромжтой юу?{" "}
        </span>
        <span id="t10_57" class="t s2_57">
          3{" "}
        </span>
        <span id="t11_57" class="t s2_57">
          Ач холбогдолтой{" "}
        </span>
        <span id="t12_57" class="t s2_57">
          байдал{" "}
        </span>
        <span id="t13_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="t14_57" class="t s2_57">
          асран хамгаалагчид шууд оролцох нь чухал{" "}
        </span>
        <span id="t15_57" class="t s2_57">
          эсэх?{" "}
        </span>
        <span id="t16_57" class="t s4_57">
          4{" "}
        </span>
        <span id="t17_57" class="t s2_57">
          Санал нийлэх{" "}
        </span>
        <span id="t18_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="t19_57" class="t s2_57">
          асран хамгаалагчид шууд оролцож байгааг та юу{" "}
        </span>
        <span id="t1a_57" class="t s2_57">
          гэж бодож байна?{" "}
        </span>
        <span id="t1b_57" class="t s4_57">
          5{" "}
        </span>
        <span id="t1c_57" class="t s2_57">
          Үйл ажиллагаа,{" "}
        </span>
        <span id="t1d_57" class="t s2_57">
          бодит байдлыг{" "}
        </span>
        <span id="t1e_57" class="t s2_57">
          дүгнэх{" "}
        </span>
        <span id="t1f_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="t1g_57" class="t s2_57">
          асран хамгаалагчид шууд оролцдог.{" "}
        </span>
        <span id="t1h_57" class="t s4_57">
          6{" "}
        </span>
        <span id="t1i_57" class="t s2_57">
          Өөрийн{" "}
        </span>
        <span id="t1j_57" class="t s2_57">
          мэдрэгдэх,{" "}
        </span>
        <span id="t1k_57" class="t s2_57">
          санагдах,{" "}
        </span>
        <span id="t1l_57" class="t s2_57">
          тунгаан бодох{" "}
        </span>
        <span id="t1m_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="t1n_57" class="t s2_57">
          асран хамгаалагчид шууд оролцоно гэдэгт{" "}
        </span>
        <span id="t1o_57" class="t s2_57">
          итгэдэг.{" "}
        </span>
        <span id="t1p_57" class="t s4_57">
          7{" "}
        </span>
        <span id="t1q_57" class="t s2_57">
          Итгэл үнэмшил{" "}
        </span>
        <span id="t1r_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="t1s_57" class="t s2_57">
          асран хамгаалагчид шууд оролцох нь тэргүүлэх{" "}
        </span>
        <span id="t1t_57" class="t s2_57">
          асуудал мөн үү?{" "}
        </span>
        <span id="t1u_57" class="t s2_57">
          Тэргүүлэх{" "}
        </span>
        <span id="t1v_57" class="t s2_57">
          чиглэл, асуудал,{" "}
        </span>
        <span id="t1w_57" class="t s2_57">
          дараалал{" "}
        </span>
        <span id="t1x_57" class="t s2_57">
          Хичээлээс гадуурх үйл ажиллагаанд эцэг, эх,{" "}
        </span>
        <span id="t1y_57" class="t s2_57">
          асран хамгаалагчид шууд оролцож байгаа нь...{" "}
        </span>
        <span id="t1z_57" class="t s5_57">
          Өгөгдөл цуглуулах{" "}
        </span>
        <span id="t20_57" class="t s1_57">
          Судалгааны өгөгдөл цуглуулах та бүхний мэддэг, хэрэглэдэг олон
          боломжууд байна. Google{" "}
        </span>
        <span id="t21_57" class="t s1_57" data-mappings='[[31,"ffi"]]'>
          form дээр асуулга авах болон, Oﬃce-form,цаасан хэлбэрээр авч шивж
          оруулах, OMR{" "}
        </span>
        <span id="t22_57" class="t s1_57">
          технологи ашиглан /уншигч машин, аппликейшн/ мэдээлэл өгөгдөл
          цуглуулах боломжтой.{" "}
        </span>
        <span id="t23_57" class="t s1_57">
          Ж нь: цаасан, OMR -Optical mark reading технологи, гар утасны
          аппликейшн, компьютерт{" "}
        </span>
        <span id="t24_57" class="t s1_57">
          суурилсан онлайн, компьютерт суурилсан онлайн биш гэх аргачлалуудыг
          өргөн ашигладаг.{" "}
        </span>
        <span id="t25_57" class="t s5_57">
          Жишээ 1: Эцэг эхийн сэтгэл ханамжийн судалгаа{" "}
        </span>
        <span id="t26_57" class="t s1_57">
          Уг судалгааны үр дүнд тулгуурлан бид үйл ажиллагаандаа шинжилгээ хийж,
          өөрчлөлт,{" "}
        </span>
        <span id="t27_57" class="t s1_57">
          шинэчлэлт хийн ажиллах тул та бүхэн дараах асуултуудад үнэн зөвөөр
          хариулна уу?{" "}
        </span>
        <span id="t28_57" class="t s1_57">
          1. Таны хүүхэд ХГҮА -нд өөрийн саналаар хамрагдаж байгаа юу?{" "}
        </span>
        <span id="t29_57" class="t s1_57">
          а/ Тийм өөрөө сонгож{" "}
        </span>
        <span id="t2a_57" class="t s1_57">
          б/ Үгүй миний зөвлөснөөр{" "}
        </span>
        <span id="t2b_57" class="t s1_57">
          2. Таны хүүхдэд ямар өөрчлөлт ороосой гэж Та хүсэж байна вэ?{" "}
        </span>
        <span id="t2c_57" class="t s1_57">
          а/ Бие дааж суралцах чадвартай болох{" "}
        </span>
        <span id="t2d_57" class="t s1_57">
          б/ Бүтээлч сэтгэлгээтэй болгох{" "}
        </span>
        <span id="t2e_57" class="t s1_57">
          в/ Ямар нэг зүйлийг тууштай хийдэг болгох{" "}
        </span>
        <span id="t2f_57" class="t s1_57">
          г/ Нийтэч болгох{" "}
        </span>
        <span id="t2g_57" class="t s1_57">
          3. Та хүүхдийнхээ сургалтын үйл ажиллагааг үнэлнэ үү?{" "}
        </span>
        <span id="t2h_57" class="t s1_57">
          а/ Маш сайн{" "}
        </span>
        <span id="t2i_57" class="t s1_57">
          б/ Хангалттай{" "}
        </span>
        <span id="t2j_57" class="t s1_57">
          в/ Дундаж{" "}
        </span>
        <span id="t2k_57" class="t s1_57">
          г/Хангалтгүй{" "}
        </span>
        <span id="t2l_57" class="t s1_57">
          д/ Маш Хангалтгүй{" "}
        </span>
        <span id="t2m_57" class="t s1_57">
          4. Таны бодлоор хүүхдийн тань{" "}
        </span>
        <span id="t2n_57" class="t s1_57">
          сонирхол нэмэгдэж{" "}
        </span>
        <span id="t2o_57" class="t s1_57">
          сайжирч байна уу{" "}
        </span>
        <span id="t2p_57" class="t s1_57">
          1/ Маш их сайжирсан{" "}
        </span>
        <span id="t2q_57" class="t s1_57">
          2/ Сайжирсан{" "}
        </span>
        <span id="t2r_57" class="t s1_57">
          3/ Хэвийн урьдынх шигээ{" "}
        </span>
        <span id="t2s_57" class="t s1_57">
          4/ Сайжраагүй{" "}
        </span>
        <span id="t2t_57" class="t s1_57">
          5. Энэ үйл ажиллагаанд (сургалтад) хүүхдээ хамаруулахад хүндрэл
          бэрхшээлүүд{" "}
        </span>
        <span id="t2u_57" class="t s1_57">
          юу байна вэ?{" "}
        </span>
        <span id="t2v_57" class="t s1_57">
          а/ Орж байгаа хэрэглэгдэхүүн үнэ ихтэй{" "}
        </span>
        <span id="t2w_57" class="t s1_57">
          б/ Ном гарын авлага ховор{" "}
        </span>
        <span id="t2x_57" class="t s1_57">
          в/ Маш их ачаалалтай{" "}
        </span>
        <span id="t2y_57" class="t s1_57">
          г/ Мэдэхгүй{" "}
        </span>
        <span id="t2z_57" class="t s1_57">
          6. Сургалт болон үйл ажиллагааны жилийн тайлагналтай холбоотой
          мэдээллийг{" "}
        </span>
        <span id="t30_57" class="t s1_57">
          ямар хэлбэрээр авбал илүү үр дүнтэй гэж Та үзэж байна вэ?{" "}
        </span>
        <span id="t31_57" class="t s1_57">
          а/ Цахимаар{" "}
        </span>
        <span id="t32_57" class="t s1_57">
          б/ Танхимын тайлангаар{" "}
        </span>
      </div>
    </div>
  );
}
