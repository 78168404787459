import React from "react";
;

export default function FiveOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_51{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_51{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_51{left:153px;bottom:1156px;}
#t4_51{left:180px;bottom:1156px;letter-spacing:-0.17px;word-spacing:7.08px;}
#t5_51{left:180px;bottom:1138px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t6_51{left:153px;bottom:1102px;}
#t7_51{left:180px;bottom:1102px;letter-spacing:-0.14px;word-spacing:7.17px;}
#t8_51{left:180px;bottom:1084px;letter-spacing:-0.17px;word-spacing:7.24px;}
#t9_51{left:180px;bottom:1066px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ta_51{left:153px;bottom:1047px;}
#tb_51{left:180px;bottom:1047px;letter-spacing:-0.15px;word-spacing:-2.04px;}
#tc_51{left:180px;bottom:1029px;letter-spacing:-0.14px;word-spacing:0.04px;}
#td_51{left:153px;bottom:1011px;}
#te_51{left:180px;bottom:1011px;letter-spacing:-0.21px;word-spacing:3.03px;}
#tf_51{left:180px;bottom:993px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tg_51{left:153px;bottom:975px;}
#th_51{left:180px;bottom:975px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ti_51{left:153px;bottom:957px;}
#tj_51{left:180px;bottom:957px;letter-spacing:-0.13px;word-spacing:6.52px;}
#tk_51{left:180px;bottom:939px;letter-spacing:-0.16px;word-spacing:1.33px;}
#tl_51{left:180px;bottom:920px;letter-spacing:-0.1px;word-spacing:0.01px;}
#tm_51{left:110px;bottom:878px;letter-spacing:-0.11px;word-spacing:0.03px;}
#tn_51{left:110px;bottom:860px;letter-spacing:-0.14px;}
#to_51{left:196px;bottom:860px;letter-spacing:-0.09px;}
#tp_51{left:292px;bottom:860px;letter-spacing:-0.1px;}
#tq_51{left:401px;bottom:860px;letter-spacing:-0.11px;}
#tr_51{left:521px;bottom:860px;letter-spacing:-0.09px;}
#ts_51{left:588px;bottom:860px;letter-spacing:-0.14px;}
#tt_51{left:663px;bottom:860px;letter-spacing:-0.08px;}
#tu_51{left:709px;bottom:860px;letter-spacing:-0.18px;}
#tv_51{left:110px;bottom:842px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tw_51{left:110px;bottom:806px;letter-spacing:-0.14px;word-spacing:1.8px;}
#tx_51{left:110px;bottom:787px;letter-spacing:-0.17px;word-spacing:0.07px;}
#ty_51{left:110px;bottom:769px;letter-spacing:-0.15px;word-spacing:5.05px;}
#tz_51{left:110px;bottom:751px;letter-spacing:-0.15px;word-spacing:1.21px;}
#t10_51{left:110px;bottom:733px;letter-spacing:-0.21px;word-spacing:0.12px;}
#t11_51{left:110px;bottom:715px;letter-spacing:-0.16px;word-spacing:4.75px;}
#t12_51{left:110px;bottom:697px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t13_51{left:110px;bottom:679px;letter-spacing:-0.12px;word-spacing:0.88px;}
#t14_51{left:110px;bottom:660px;letter-spacing:-0.16px;word-spacing:4.9px;}
#t15_51{left:110px;bottom:642px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t16_51{left:110px;bottom:606px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t17_51{left:153px;bottom:582px;}
#t18_51{left:180px;bottom:582px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t19_51{left:153px;bottom:564px;}
#t1a_51{left:180px;bottom:564px;letter-spacing:-0.19px;word-spacing:0.1px;}
#t1b_51{left:153px;bottom:545px;}
#t1c_51{left:180px;bottom:545px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1d_51{left:153px;bottom:527px;}
#t1e_51{left:180px;bottom:527px;letter-spacing:-0.14px;word-spacing:-0.36px;}
#t1f_51{left:180px;bottom:509px;letter-spacing:-0.22px;}
#t1g_51{left:153px;bottom:491px;}
#t1h_51{left:180px;bottom:491px;letter-spacing:-0.13px;word-spacing:-0.14px;}
#t1i_51{left:180px;bottom:473px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t1j_51{left:153px;bottom:455px;}
#t1k_51{left:180px;bottom:455px;letter-spacing:-0.13px;word-spacing:3.25px;}
#t1l_51{left:180px;bottom:437px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t1m_51{left:419px;bottom:437px;letter-spacing:-0.22px;}
#t1n_51{left:153px;bottom:419px;}
#t1o_51{left:180px;bottom:419px;letter-spacing:-0.13px;word-spacing:-0.14px;}
#t1p_51{left:180px;bottom:400px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t1q_51{left:153px;bottom:382px;}
#t1r_51{left:180px;bottom:382px;letter-spacing:-0.13px;word-spacing:3.25px;}
#t1s_51{left:180px;bottom:364px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1t_51{left:110px;bottom:322px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1u_51{left:153px;bottom:298px;}
#t1v_51{left:180px;bottom:298px;letter-spacing:-0.14px;word-spacing:5.62px;}
#t1w_51{left:180px;bottom:279px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1x_51{left:153px;bottom:261px;}
#t1y_51{left:180px;bottom:261px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1z_51{left:153px;bottom:243px;}
#t20_51{left:180px;bottom:243px;letter-spacing:-0.14px;word-spacing:-1.5px;}
#t21_51{left:180px;bottom:225px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t22_51{left:153px;bottom:207px;}
#t23_51{left:180px;bottom:207px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t24_51{left:153px;bottom:189px;}
#t25_51{left:180px;bottom:189px;letter-spacing:-0.15px;word-spacing:3.25px;}
#t26_51{left:180px;bottom:171px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t27_51{left:153px;bottom:152px;}
#t28_51{left:180px;bottom:152px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t29_51{left:153px;bottom:134px;}
#t2a_51{left:180px;bottom:134px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t2b_51{left:153px;bottom:116px;}
#t2c_51{left:180px;bottom:116px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t2d_51{left:153px;bottom:98px;}
#t2e_51{left:180px;bottom:98px;letter-spacing:-0.15px;word-spacing:4.4px;}
#t2f_51{left:180px;bottom:80px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_51{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_51{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_51{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>

        <style id="fonts51" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg51" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="51/51.svg"
          type="image/svg+xml"
          id="pdf51"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_51" class="t s0_51">
          49{" "}
        </span>
        <span id="t2_51" class="t s1_51">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t4_51" class="t s1_51">
          Тогтвортой хөгжлийн 17 зорилго, тэдгээрийн зорилтуудыг хэрэгжүүлэх
          арга{" "}
        </span>
        <span id="t5_51" class="t s1_51">
          аргачлал, менежмент{" "}
        </span>
        <span id="t6_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t7_51" class="t s1_51">
          Орон нутгийн уур амьсгалын өөрчлөлт хүний зохисгүй үйл ажиллагаанаас{" "}
        </span>
        <span id="t8_51" class="t s1_51">
          шалтгаалдаг талаарх ойлголтыг өгч, суралцагч байгальтай зөв харилцахад{" "}
        </span>
        <span id="t9_51" class="t s1_51">
          чиглэсэн үйл ажиллагааг төлөвлөж, хэрэгжүүлнэ.{" "}
        </span>
        <span id="ta_51" class="t s1_51">
          -{" "}
        </span>
        <span id="tb_51" class="t s1_51">
          Уур амьсгалын үндсэн ойлголт, түүнд нөлөөлөх хүчин зүйлсийн талаар
          хэлэлцүүлэг{" "}
        </span>
        <span id="tc_51" class="t s1_51">
          хийж болно.{" "}
        </span>
        <span id="td_51" class="t s1_51">
          -{" "}
        </span>
        <span id="te_51" class="t s1_51">
          Хог хаягдлын талаарх мэдлэг, ойлголт, эрүүл цэвэр орчинд ажиллаж,
          амьдрах{" "}
        </span>
        <span id="tf_51" class="t s1_51">
          орчныг бүрдүүлэхэд чиглэсэн үйл ажиллагааг зохион байгуулна.{" "}
        </span>
        <span id="tg_51" class="t s1_51">
          -{" "}
        </span>
        <span id="th_51" class="t s1_51">
          Байгальтай харилцан зохицсон аж амьдралын уламжлал{" "}
        </span>
        <span id="ti_51" class="t s1_51">
          -{" "}
        </span>
        <span id="tj_51" class="t s1_51">
          Байгаль, экологийн хүмүүжлийг төлөвшүүлэх ажлын хүрээнд ургацын баяр,{" "}
        </span>
        <span id="tk_51" class="t s1_51">
          цэцгийн баяр, мод тарих, цагаан цэцэг, шувуу тэжээх, миний нүдээр гар
          зургийн{" "}
        </span>
        <span id="tl_51" class="t s1_51">
          уралдаан, эко клуб, бүлгийг ажиллуулж болно.{" "}
        </span>
        <span id="tm_51" class="t s1_51">
          8.1.4.Арга зүй:{" "}
        </span>
        <span id="tn_51" class="t s1_51">
          Байгаль,{" "}
        </span>
        <span id="to_51" class="t s1_51">
          экологийн{" "}
        </span>
        <span id="tp_51" class="t s1_51">
          хүмүүжлийг{" "}
        </span>
        <span id="tq_51" class="t s1_51">
          төлөвшүүлэх{" "}
        </span>
        <span id="tr_51" class="t s1_51">
          ажлыг{" "}
        </span>
        <span id="ts_51" class="t s1_51">
          хичээл,{" "}
        </span>
        <span id="tt_51" class="t s1_51">
          үйл{" "}
        </span>
        <span id="tu_51" class="t s1_51">
          ажиллагаатай{" "}
        </span>
        <span id="tv_51" class="t s1_51">
          интеграцчилан, сургалтын орчин, багшийн чадавхид илүүтэй анхаарч
          ажиллах хэрэгтэй.{" "}
        </span>
        <span id="tw_51" class="t s2_51">
          Загвар 1. Эх дэлхийн байгаль цаг уурын өөрчлөлтөд чиний оруулах хувь
          нэмэр юу вэ?{" "}
        </span>
        <span id="tx_51" class="t s2_51">
          хэлэлцүүлэг зохион байгуулах{" "}
        </span>
        <span id="ty_51" class="t s1_51">
          Хэлэлцүүлэг нь маргаантай асуудлыг шийдвэрлэх, хичээлийг сонирхолтой,
          үр дүнтэй{" "}
        </span>
        <span id="tz_51" class="t s1_51">
          болгоход чухал нөлөөтэй байдаг ч зарим асуудлыг анхаарахгүй бол эмх
          замбараагүй, үр{" "}
        </span>
        <span id="t10_51" class="t s1_51">
          дүнгүй байдалд хүргэдэг.{" "}
        </span>
        <span id="t11_51" class="t s1_51">
          Хэлэлцүүлэг хийхэд хувь хүнд анхаарахаас илүү үзэл бодол, байр суурийг
          хэлэлцэж{" "}
        </span>
        <span id="t12_51" class="t s1_51">
          шийдвэрлэх хэрэгтэй.{" "}
        </span>
        <span id="t13_51" class="t s1_51">
          Хүмүүсийн эсрэг бус учир шалтгаан, санаа бодлын эсрэг маргалдах
          хэрэгтэй.Багш санал{" "}
        </span>
        <span id="t14_51" class="t s1_51">
          нэгдэх, эсвэл санал зөрж байгаа үндэслэл, хэзээ хаана харилцан буулт
          хийх, болох{" "}
        </span>
        <span id="t15_51" class="t s1_51">
          болохгүйг ялган танихад сурагчдад туслах үүрэгтэй.{" "}
        </span>
        <span id="t16_51" class="t s1_51">
          Багийн хэлэлцүүлгийн дүрэм{" "}
        </span>
        <span id="t17_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t18_51" class="t s1_51">
          Үзэл бодлоо илэрхийлэх үедээ засч сайжруулахад бэлэн байх{" "}
        </span>
        <span id="t19_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1a_51" class="t s1_51">
          Бусдын үзэл бодлыг эелдэг, хүндэтгэлтэй сонсох{" "}
        </span>
        <span id="t1b_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1c_51" class="t s1_51">
          Бүх хүнд ярих боломж өгөх{" "}
        </span>
        <span id="t1d_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1e_51" class="t s1_51">
          Хэрэв байр суурь чинь өөр байгаа бол хүмүүсийн эсрэг бус учир
          шалтгаантайгаар{" "}
        </span>
        <span id="t1f_51" class="t s1_51">
          мэтгэлцэх{" "}
        </span>
        <span id="t1g_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1h_51" class="t s1_51">
          Өөрийн үзэл бодлоо хэзээ ч өөрчилж болно. Түүнийг өөрчилсөн учир
          шалтгаанаа{" "}
        </span>
        <span id="t1i_51" class="t s1_51">
          тайлбарлахад бэлэн байх{" "}
        </span>
        <span id="t1j_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1k_51" class="t s1_51">
          Санал хураалтаар шийдвэр гаргах тохиолдолд цөөнхийн үгийг заавал
          сонсох,{" "}
        </span>
        <span id="t1l_51" class="t s1_51">
          дараа нь дахин санал хураах{" "}
        </span>
        <span id="t1m_51" class="t s1_51">
          мэтгэлцэх{" "}
        </span>
        <span id="t1n_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1o_51" class="t s1_51">
          Өөрийн үзэл бодлоо хэзээ ч өөрчилж болно. Түүнийг өөрчилсөн учир
          шалтгаанаа{" "}
        </span>
        <span id="t1p_51" class="t s1_51">
          тайлбарлахад бэлэн байх{" "}
        </span>
        <span id="t1q_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1r_51" class="t s1_51">
          Санал хураалтаар шийдвэр гаргах тохиолдолд цөөнхийн үгийг заавал
          сонсох,{" "}
        </span>
        <span id="t1s_51" class="t s1_51">
          дараа нь дахин санал хураах{" "}
        </span>
        <span id="t1t_51" class="t s1_51">
          Хэлэлцүүлэг хийхэд чиглүүлэгч юуг анхаарах хэрэгтэй вэ? Үүнд:{" "}
        </span>
        <span id="t1u_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1v_51" class="t s1_51">
          Зааварчилгаа нь сайн ойлгогдсон эсэхийг хянаж, үйл ажиллагааны заавраа{" "}
        </span>
        <span id="t1w_51" class="t s1_51">
          хүүхдүүдэд ойлгомжтой хэлбэрээр өгөх{" "}
        </span>
        <span id="t1x_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t1y_51" class="t s1_51">
          Бүлэгт тэгш хуваах{" "}
        </span>
        <span id="t1z_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t20_51" class="t s1_51">
          Бусад бүлэгт (өөр бүлгүүд байгаа бол бүлгийн ахлагч нартай ярилцан)
          хэлэлцүүлэг{" "}
        </span>
        <span id="t21_51" class="t s1_51">
          яаж явагдаж байгааг шалгах{" "}
        </span>
        <span id="t22_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t23_51" class="t s1_51">
          Уйдаж байгаа, анхаарахгүй байгаа хэн нэгэнд нь анхаарал тавих{" "}
        </span>
        <span id="t24_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t25_51" class="t s1_51">
          Бусадтайгаа хуваалцаж санал авах боломжийг хамгийн тааламжтай байдлаар{" "}
        </span>
        <span id="t26_51" class="t s1_51">
          зохион байгуулах{" "}
        </span>
        <span id="t27_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t28_51" class="t s1_51">
          Хүүхдэд бодох, хоорондоо ярилцах цаг, орон зайг өгөх{" "}
        </span>
        <span id="t29_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t2a_51" class="t s1_51">
          Тэвчээртэй хариуцлагатай, зэрэгцэн ярихгүй байх{" "}
        </span>
        <span id="t2b_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t2c_51" class="t s1_51">
          Хэн нэгэн голлож, бусдадаа ярих боломж өгөхгүй байгааг зохицуулах{" "}
        </span>
        <span id="t2d_51" class="t s1_51">
          -{" "}
        </span>
        <span id="t2e_51" class="t s1_51">
          Хэрэв хэлэлцүүлэг цагаасаа эрт эсвэл оройтож дуусвал яах тухай
          хэлэлцэн,{" "}
        </span>
        <span id="t2f_51" class="t s1_51">
          тохирсон арга хэмжээг авах{" "}
        </span>
      </div>
    </div>
  );
}
