import React from "react";
;

export default function ThirtyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p31" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_31{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_31{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_31{left:110px;bottom:1151px;letter-spacing:-0.16px;word-spacing:2.17px;}
#t4_31{left:110px;bottom:1133px;letter-spacing:-0.15px;}
#t5_31{left:142px;bottom:1103px;letter-spacing:0.16px;}
#t6_31{left:357px;bottom:1103px;letter-spacing:0.11px;word-spacing:0.03px;}
#t7_31{left:135px;bottom:1057px;letter-spacing:0.13px;}
#t8_31{left:224px;bottom:1080px;letter-spacing:0.12px;word-spacing:0.03px;}
#t9_31{left:224px;bottom:1064px;letter-spacing:0.07px;word-spacing:0.07px;}
#ta_31{left:224px;bottom:1047px;letter-spacing:0.14px;word-spacing:0.01px;}
#tb_31{left:135px;bottom:1001px;letter-spacing:0.14px;}
#tc_31{left:224px;bottom:1024px;letter-spacing:0.09px;word-spacing:0.05px;}
#td_31{left:224px;bottom:1007px;letter-spacing:0.1px;word-spacing:0.04px;}
#te_31{left:224px;bottom:991px;letter-spacing:0.05px;word-spacing:0.09px;}
#tf_31{left:133px;bottom:946px;letter-spacing:0.14px;}
#tg_31{left:224px;bottom:967px;letter-spacing:0.1px;word-spacing:2.8px;}
#th_31{left:224px;bottom:951px;letter-spacing:0.12px;}
#ti_31{left:224px;bottom:934px;letter-spacing:0.11px;word-spacing:0.33px;}
#tj_31{left:224px;bottom:918px;letter-spacing:0.08px;}
#tk_31{left:224px;bottom:901px;letter-spacing:0.11px;word-spacing:0.03px;}
#tl_31{left:224px;bottom:885px;letter-spacing:0.12px;word-spacing:0.02px;}
#tm_31{left:133px;bottom:841px;letter-spacing:0.14px;}
#tn_31{left:224px;bottom:862px;letter-spacing:0.12px;word-spacing:0.02px;}
#to_31{left:224px;bottom:845px;letter-spacing:0.06px;word-spacing:0.08px;}
#tp_31{left:224px;bottom:829px;letter-spacing:0.06px;word-spacing:-2.52px;}
#tq_31{left:224px;bottom:812px;letter-spacing:0.12px;}
#tr_31{left:224px;bottom:796px;letter-spacing:0.11px;word-spacing:0.03px;}
#ts_31{left:136px;bottom:742px;word-spacing:0.12px;}
#tt_31{left:128px;bottom:726px;letter-spacing:0.12px;}
#tu_31{left:137px;bottom:709px;letter-spacing:0.23px;}
#tv_31{left:224px;bottom:772px;letter-spacing:0.12px;word-spacing:0.02px;}
#tw_31{left:224px;bottom:756px;letter-spacing:0.11px;word-spacing:0.04px;}
#tx_31{left:224px;bottom:739px;letter-spacing:0.01px;}
#ty_31{left:224px;bottom:723px;letter-spacing:0.14px;word-spacing:0.01px;}
#tz_31{left:224px;bottom:706px;letter-spacing:0.12px;word-spacing:0.04px;}
#t10_31{left:110px;bottom:564px;letter-spacing:-0.11px;}
#t11_31{left:229px;bottom:564px;letter-spacing:-0.15px;word-spacing:1.9px;}
#t12_31{left:110px;bottom:546px;letter-spacing:-0.15px;word-spacing:2.89px;}
#t13_31{left:110px;bottom:528px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t14_31{left:110px;bottom:492px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t15_31{left:110px;bottom:474px;letter-spacing:-0.13px;word-spacing:-0.77px;}
#t16_31{left:110px;bottom:456px;letter-spacing:-0.15px;}
#t17_31{left:110px;bottom:437px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t18_31{left:110px;bottom:419px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t19_31{left:110px;bottom:401px;letter-spacing:-0.15px;word-spacing:2.32px;}
#t1a_31{left:110px;bottom:383px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1b_31{left:110px;bottom:365px;letter-spacing:-0.13px;word-spacing:1.99px;}
#t1c_31{left:110px;bottom:347px;letter-spacing:-0.2px;}
#t1d_31{left:110px;bottom:298px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1e_31{left:110px;bottom:274px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1f_31{left:110px;bottom:250px;letter-spacing:-0.16px;}
#t1g_31{left:153px;bottom:225px;}
#t1h_31{left:180px;bottom:225px;letter-spacing:-0.15px;word-spacing:4.41px;}
#t1i_31{left:180px;bottom:207px;letter-spacing:-0.13px;word-spacing:1.39px;}
#t1j_31{left:180px;bottom:189px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1k_31{left:153px;bottom:171px;}
#t1l_31{left:180px;bottom:171px;letter-spacing:-0.13px;word-spacing:1.89px;}
#t1m_31{left:180px;bottom:153px;letter-spacing:-0.13px;word-spacing:-1.01px;}
#t1n_31{left:180px;bottom:135px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1o_31{left:142px;bottom:642px;letter-spacing:-0.17px;word-spacing:-1.89px;}
#t1p_31{left:142px;bottom:624px;letter-spacing:-0.13px;word-spacing:2.42px;}
#t1q_31{left:142px;bottom:606px;letter-spacing:-0.11px;word-spacing:0.01px;}

.s0_31{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_31{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_31{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_31{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s4_31{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>
        <style id="fonts31" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg31" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="31/31.svg"
          type="image/svg+xml"
          id="pdf31"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_31" class="t s0_31">
          29{" "}
        </span>
        <span id="t2_31" class="t s1_31">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_31" class="t s2_31">
          Хүснэгт: Иргэд хэдэн насандаа юуг хийж болох эсвэл болохгүйг хуульчлан
          зааж өгсөн{" "}
        </span>
        <span id="t4_31" class="t s2_31">
          байдал{" "}
        </span>
        <span id="t5_31" class="t s3_31">
          Нас{" "}
        </span>
        <span id="t6_31" class="t s3_31">
          Хийж болох үйлдлүүд, эдлэх эрх хүлээх үүрэг{" "}
        </span>
        <span id="t7_31" class="t s3_31">
          0-6{" "}
        </span>
        <span id="t8_31" class="t s3_31">
          Нэр авах{" "}
        </span>
        <span id="t9_31" class="t s3_31">
          Иргэний бүртгэлд бүртгэгдэх{" "}
        </span>
        <span id="ta_31" class="t s3_31">
          Эрүүл мэндийн туслалцаа авах{" "}
        </span>
        <span id="tb_31" class="t s3_31">
          7-13{" "}
        </span>
        <span id="tc_31" class="t s3_31">
          Сургуульд орох{" "}
        </span>
        <span id="td_31" class="t s3_31">
          Талх авах зэрэг ахуйн жижиг худалдаа хийх{" "}
        </span>
        <span id="te_31" class="t s3_31">
          Эцэг,эхийн зөвшөөрлөөр эд зүйлээ бусадтай солих{" "}
        </span>
        <span id="tf_31" class="t s3_31">
          14-15{" "}
        </span>
        <span id="tg_31" class="t s3_31">
          Бусдад хохирол учруулбал өөрөө төлөх, өөрөө төлж чадахгүй бол эцэг
          эхээрээ{" "}
        </span>
        <span id="th_31" class="t s3_31">
          төлүүлэх{" "}
        </span>
        <span id="ti_31" class="t s3_31">
          Эцэг эх, асран хамгаалагчийн зөвшөөрлөөр мэргэжлийн баримжаа олох
          хөдөлмөр{" "}
        </span>
        <span id="tj_31" class="t s3_31">
          эрхлэх{" "}
        </span>
        <span id="tk_31" class="t s3_31">
          Гэмт хэргийн төрлөөс хамааран эрүүгийн хариуцлага хүлээх{" "}
        </span>
        <span id="tl_31" class="t s3_31">
          Эцэг эхийн хяналтын дор өөрийн мэдлийн эд хөрөнгийг зарцуулах{" "}
        </span>
        <span id="tm_31" class="t s3_31">
          16-17{" "}
        </span>
        <span id="tn_31" class="t s3_31">
          Иргэний үнэмлэх авах{" "}
        </span>
        <span id="to_31" class="t s3_31">
          Эцэг, эхийн зөвшөөрлөөр хөдөлмөр эрхлэх{" "}
        </span>
        <span id="tp_31" class="t s3_31">
          Бусдад хохирол учруулбал өөрөө төлөх, чадахгүй бол эцэг, эх, асран
          хамгаалагчаараа{" "}
        </span>
        <span id="tq_31" class="t s3_31">
          төлүүлэх{" "}
        </span>
        <span id="tr_31" class="t s3_31">
          Эрүүгийн хариуцлага хүлээх{" "}
        </span>
        <span id="ts_31" class="t s3_31">
          18 ба{" "}
        </span>
        <span id="tt_31" class="t s3_31">
          түүнээс{" "}
        </span>
        <span id="tu_31" class="t s3_31">
          дээш{" "}
        </span>
        <span id="tv_31" class="t s3_31">
          Сонгуульд саналаа өгөх{" "}
        </span>
        <span id="tw_31" class="t s3_31">
          Цэргийн алба хаах{" "}
        </span>
        <span id="tx_31" class="t s3_31">
          Гэрлэх{" "}
        </span>
        <span id="ty_31" class="t s3_31">
          Бие дааж хөдөлмөр эрхлэх,арилжаа бизнес хийх{" "}
        </span>
        <span id="tz_31" class="t s3_31">
          Машин жолоодох{" "}
        </span>
        <span id="t10_31" class="t s2_31">
          3.2.1.Зорилго.{" "}
        </span>
        <span id="t11_31" class="t s1_31">
          Оролцогч хүүхэд өөрт ямар нэг гэнэтийн болон таагүй асуудал тулгарсан{" "}
        </span>
        <span id="t12_31" class="t s1_31">
          үед ухаалгаар шийдэж, биеэ авч гарч чаддаг болох, өөрийгөө болон
          бусдыг хүндэтгэх,{" "}
        </span>
        <span id="t13_31" class="t s1_31">
          дадал, чадвартай болно.{" "}
        </span>
        <span id="t14_31" class="t s2_31">
          3.2.2.Үйл ажиллагаа{" "}
        </span>
        <span id="t15_31" class="t s1_31">
          Хүн ёс суртахууныг эрхэмлэж, хууль дүрэм журмыг мөрдөж, дагахын утга
          учрыг хүүхдүүдэд{" "}
        </span>
        <span id="t16_31" class="t s1_31">
          ойлгуулах{" "}
        </span>
        <span id="t17_31" class="t s1_31">
          Монгол ардын уламжлалт сурган хүмүүжүүлэх арга зүйгээс суралцах{" "}
        </span>
        <span id="t18_31" class="t s1_31">
          Монгол ардын зүйр цэцэн үгсийг ярьж, тайлбарлаж ойлгуулах, хэлэлцүүлэх{" "}
        </span>
        <span id="t19_31" class="t s1_31">
          Хүүхэд өөрт ямар нэг гэнэтийн болон таагүй асуудал тулгарсан үед
          ухаалгаар шийдэж,{" "}
        </span>
        <span id="t1a_31" class="t s1_31">
          биеэ авч гарч чаддаг болох,{" "}
        </span>
        <span id="t1b_31" class="t s1_31">
          Гэмт хэргээс өөрийгөө болон бусдыг хамгаалах, урьдчилан сэргийлэх
          дадал, чадвартай{" "}
        </span>
        <span id="t1c_31" class="t s1_31">
          болох{" "}
        </span>
        <span id="t1d_31" class="t s2_31">
          3.2.3.Арга зүй{" "}
        </span>
        <span id="t1e_31" class="t s4_31">
          Монгол ардын зүйр цэцэн үгсийн утгыг хэлэлцэх{" "}
        </span>
        <span id="t1f_31" class="t s2_31">
          Заавар:{" "}
        </span>
        <span id="t1g_31" class="t s1_31">
          -{" "}
        </span>
        <span id="t1h_31" class="t s1_31">
          Оролцогчдод Монгол ардын зүйр цэцэн үгсийг дэлгэцэн дээр бичиж
          гаргана.{" "}
        </span>
        <span id="t1i_31" class="t s1_31">
          Гэхдээ эхний удаад зөвхөн зүйр цэцэн үгсийг бичиж гарган оролцогчдоос
          энэ үг{" "}
        </span>
        <span id="t1j_31" class="t s1_31">
          ямар учир утгыг агуулсан, ямар ач холбогдолтой үг болохыг асууж
          хэлэлцэнэ.{" "}
        </span>
        <span id="t1k_31" class="t s1_31">
          -{" "}
        </span>
        <span id="t1l_31" class="t s1_31">
          Дараа нь багш зүйр цэцэн үгсийг тайлбарын хамт бичсэн дэлгэцийг гарган
          ийм{" "}
        </span>
        <span id="t1m_31" class="t s1_31">
          учир утгатай, утга санааг агуулсан, бидний өвөг дээдсээс уламжилж
          ирсэн сургаал{" "}
        </span>
        <span id="t1n_31" class="t s1_31">
          үгс учраас байнга санаж явахыг хэлж тайлбарлана.{" "}
        </span>
        <span id="t1o_31" class="t s1_31">
          3.2. Хүүхдийг шударга ёс, шударга байдал, нинжин сэтгэлийг эрхэмлэдэг,
          эцэг эх, багш,{" "}
        </span>
        <span id="t1p_31" class="t s1_31">
          ахмад үе, үе тэнгийнхнээ болон бусад хүмүүсийг хүндэтгэх үзлийг
          төлөвшүүлэхэд{" "}
        </span>
        <span id="t1q_31" class="t s1_31">
          чиглэсэн үйл ажиллагаанд дэмжлэг үзүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
