import React from "react";
export default function ThreeTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_13{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_13{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_13{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_13{font-size:17px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts13" type="text/css">
          {`@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg13" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="13/13.svg"
          type="image/svg+xml"
          id="pdf13"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_13" class="t s0_13">
          11{" "}
        </span>
        {/* <span id="t2_13" class="t s1_13">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span> */}
      </div>
    </div>
  );
}
