import React from "react";
;

export default function Yus() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_9{left:844px;bottom:21px;}
#t2_9{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_9{left:385px;bottom:684px;letter-spacing:-0.14px;word-spacing:-1.5px;}
#t4_9{left:110px;bottom:630px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t5_9{left:110px;bottom:612px;letter-spacing:-0.16px;word-spacing:2.63px;}
#t6_9{left:110px;bottom:594px;letter-spacing:-0.19px;word-spacing:5.58px;}
#t7_9{left:110px;bottom:576px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t8_9{left:110px;bottom:558px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t9_9{left:153px;bottom:533px;}
#ta_9{left:180px;bottom:533px;letter-spacing:-0.21px;word-spacing:3.68px;}
#tb_9{left:180px;bottom:515px;letter-spacing:-0.21px;word-spacing:-0.61px;}
#tc_9{left:180px;bottom:497px;letter-spacing:-0.12px;word-spacing:0.02px;}
#td_9{left:153px;bottom:479px;}
#te_9{left:180px;bottom:479px;letter-spacing:-0.14px;word-spacing:-1.69px;}
#tf_9{left:180px;bottom:461px;letter-spacing:-0.13px;word-spacing:-0.29px;}
#tg_9{left:180px;bottom:443px;letter-spacing:-0.11px;word-spacing:0.02px;}
#th_9{left:110px;bottom:418px;letter-spacing:-0.18px;word-spacing:-0.53px;}
#ti_9{left:110px;bottom:400px;letter-spacing:-0.14px;word-spacing:0.64px;}
#tj_9{left:110px;bottom:382px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_9{left:110px;bottom:364px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tl_9{left:153px;bottom:340px;}
#tm_9{left:180px;bottom:340px;letter-spacing:-0.18px;word-spacing:3.87px;}
#tn_9{left:180px;bottom:322px;letter-spacing:-0.17px;word-spacing:0.07px;}
#to_9{left:153px;bottom:303px;}
#tp_9{left:180px;bottom:303px;letter-spacing:-0.14px;word-spacing:-1.92px;}
#tq_9{left:110px;bottom:279px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tr_9{left:153px;bottom:255px;}
#ts_9{left:180px;bottom:255px;letter-spacing:-0.17px;word-spacing:7.05px;}
#tt_9{left:180px;bottom:237px;letter-spacing:-0.1px;word-spacing:0.01px;}
#tu_9{left:153px;bottom:219px;}
#tv_9{left:180px;bottom:219px;letter-spacing:-0.14px;word-spacing:2.45px;}
#tw_9{left:180px;bottom:201px;letter-spacing:-0.2px;word-spacing:-1.53px;}
#tx_9{left:180px;bottom:182px;letter-spacing:-0.17px;word-spacing:0.95px;}
#ty_9{left:180px;bottom:164px;letter-spacing:-0.19px;word-spacing:3.42px;}
#tz_9{left:180px;bottom:146px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_9{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_9{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_9{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_9{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}`}
        </style>
        <style id="fonts9" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg9" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="9/9.svg"
          type="image/svg+xml"
          id="pdf9"
          style={pdfStyle}></object>
      </div>

      <div class="text-container">
        <span id="t1_9" class="t s0_9">
          7{" "}
        </span>
        <span id="t2_9" class="t s1_9">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_9" class="t s2_9">
          Зураг 1. ХГСА ба сурагчдын эзэмших чадварын матриц{" "}
        </span>
        <span id="t4_9" class="t s3_9">
          6.2. ХГСА-ыг зохион байгуулах хэлбэр{" "}
        </span>
        <span id="t5_9" class="t s1_9">
          Хичээлээс гадуурх сургалтын ажлыг орон нутгийн онцлог, тухайн
          сургуулийн сурагчдын{" "}
        </span>
        <span id="t6_9" class="t s1_9">
          эрэлт хэрэгцээнд тулгуурлан хичээлээр эзэмшсэн мэдлэг, чадварыг
          бататгах, тэдний{" "}
        </span>
        <span id="t7_9" class="t s1_9">
          авьяас, сонирхлыг дэмжин хөгжүүлэх чиглэлээр төсөлт ажил, туршилт,
          дадлага, дугуйлан,{" "}
        </span>
        <span id="t8_9" class="t s1_9">
          клуб хичээллүүлэх зэрэг олон хэлбэрээр зохион байгуулж болно.
          Тухайлбал:{" "}
        </span>
        <span id="t9_9" class="t s1_9">
          -{" "}
        </span>
        <span id="ta_9" class="t s1_9">
          Дугуйлан, секц нь бүжиг, дуу, хөгжим, баримал, уран зураг болон гар
          бөмбөг,{" "}
        </span>
        <span id="tb_9" class="t s1_9">
          сагсан бөмбөг, бөх, байт сур, сур харваа, хөнгөн атлетик, усанд сэлэлт
          зэрэг урлаг,{" "}
        </span>
        <span id="tc_9" class="t s1_9">
          спортын авьяасыг нээж, хөгжүүлэх;{" "}
        </span>
        <span id="td_9" class="t s1_9">
          -{" "}
        </span>
        <span id="te_9" class="t s1_9">
          Клуб нь хувцас, хоолны технологи, шатар, механик элетроникийн авьяас
          хөгжүүлэх{" "}
        </span>
        <span id="tf_9" class="t s1_9">
          болон мэтгэлцээн, байгаль экологи, хүмүүнлэгийн сайн дурын үйл
          ажиллагаагаар{" "}
        </span>
        <span id="tg_9" class="t s1_9">
          хувь хүний нийгэмшихүйн хөгжлийг хангах{" "}
        </span>
        <span id="th_9" class="t s1_9">
          Тодорхой төрлийн үйл ажиллагааг зохион байгуулахад өөр өөрийн зорилго,
          чиглэлтэй байх{" "}
        </span>
        <span id="ti_9" class="t s1_9">
          учраас арга зүйн хувьд онцлог ялгаатай байна. Иймд хичээлээс гадуурх
          сургалтын ажлыг{" "}
        </span>
        <span id="tj_9" class="t s1_9">
          боловсролын түвшин бүрт дараах байдлаар хэлбэржүүлэн хэрэгжүүлэх
          боломжтой юм.{" "}
        </span>
        <span id="tk_9" class="t s1_9">
          Ангийн үйл ажиллагаа{" "}
        </span>
        <span id="tl_9" class="t s1_9">
          -{" "}
        </span>
        <span id="tm_9" class="t s1_9">
          Ангийн зорилго, зохион байгуулалт, анги хамт олны уур амьсгал, эв
          нэгдлийг{" "}
        </span>
        <span id="tn_9" class="t s1_9">
          бэхжүүлэхтэй холбоотой үйл ажиллагаа{" "}
        </span>
        <span id="to_9" class="t s1_9">
          -{" "}
        </span>
        <span id="tp_9" class="t s1_9">
          Хувь сурагчийн нэр хүнд, эрүүл мэнд, аюулгүй байдалтай холбоотой үйл
          ажиллагаа{" "}
        </span>
        <span id="tq_9" class="t s1_9">
          Секц, дугуйлангийн үйл ажиллагаа{" "}
        </span>
        <span id="tr_9" class="t s1_9">
          -{" "}
        </span>
        <span id="ts_9" class="t s1_9">
          Суралцах арга барилыг сайжруулахтай холбоотой үйл ажиллагаа (сэтгэхүй{" "}
        </span>
        <span id="tt_9" class="t s1_9">
          хөгжүүлэх дугуйлан){" "}
        </span>
        <span id="tu_9" class="t s1_9">
          -{" "}
        </span>
        <span id="tv_9" class="t s1_9">
          Өөрийн авьяас чадварыг нээж, хөгжүүлэхтэй холбоотой үйл ажиллагаа
          (ардын{" "}
        </span>
        <span id="tw_9" class="t s1_9">
          урлаг, тайз дэлгэц, бүжиг, найрал дуу, үндэсний болон орчин үеийн
          хөгжим, дүрслэх{" "}
        </span>
        <span id="tx_9" class="t s1_9">
          урлаг, дизайн, техникийн загвар, гар урлал, загвар зохион бүтээх,
          загвар өмсөгч,{" "}
        </span>
        <span id="ty_9" class="t s1_9">
          шатар, даам, хөнгөн атлетик, спорт тоглоом, өвлийн спорт, бөх,
          мэтгэлцээний{" "}
        </span>
        <span id="tz_9" class="t s1_9">
          клуб, мини парламент гэх мэт){" "}
        </span>
      </div>
    </div>
  );
}
