import React from "react";
;

export default function ThirtySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p3" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_37{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_37{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_37{left:110px;bottom:1157px;letter-spacing:-0.19px;word-spacing:1.05px;}
#t4_37{left:110px;bottom:1139px;letter-spacing:-0.13px;word-spacing:1.34px;}
#t5_37{left:110px;bottom:1121px;letter-spacing:-0.18px;}
#t6_37{left:110px;bottom:1084px;letter-spacing:-0.18px;word-spacing:3.04px;}
#t7_37{left:110px;bottom:1066px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t8_37{left:110px;bottom:1030px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t9_37{left:110px;bottom:1012px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ta_37{left:179px;bottom:1012px;}
#tb_37{left:188px;bottom:1012px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tc_37{left:534px;bottom:1012px;letter-spacing:-0.09px;}
#td_37{left:110px;bottom:994px;letter-spacing:-0.13px;}
#te_37{left:185px;bottom:994px;letter-spacing:-0.19px;word-spacing:0.49px;}
#tf_37{left:110px;bottom:975px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tg_37{left:110px;bottom:957px;letter-spacing:-0.11px;word-spacing:0.01px;}
#th_37{left:110px;bottom:812px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_37{left:110px;bottom:794px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tj_37{left:110px;bottom:776px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tk_37{left:110px;bottom:517px;letter-spacing:-0.15px;word-spacing:-1.43px;}
#tl_37{left:110px;bottom:481px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tm_37{left:153px;bottom:456px;}
#tn_37{left:180px;bottom:456px;letter-spacing:-0.15px;word-spacing:4.67px;}
#to_37{left:180px;bottom:438px;letter-spacing:-0.19px;word-spacing:0.1px;}
#tp_37{left:153px;bottom:420px;}
#tq_37{left:180px;bottom:420px;letter-spacing:-0.15px;word-spacing:1.26px;}
#tr_37{left:180px;bottom:402px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ts_37{left:110px;bottom:378px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tt_37{left:110px;bottom:360px;letter-spacing:-0.15px;word-spacing:2.56px;}
#tu_37{left:110px;bottom:342px;letter-spacing:-0.14px;word-spacing:-0.44px;}
#tv_37{left:110px;bottom:323px;letter-spacing:-0.15px;word-spacing:1.22px;}
#tw_37{left:110px;bottom:305px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tx_37{left:491px;bottom:269px;letter-spacing:-0.15px;word-spacing:5.19px;}
#ty_37{left:491px;bottom:251px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tz_37{left:491px;bottom:233px;letter-spacing:-0.21px;word-spacing:4.77px;}
#t10_37{left:491px;bottom:215px;letter-spacing:-0.09px;}
#t11_37{left:569px;bottom:215px;letter-spacing:-0.18px;}
#t12_37{left:700px;bottom:215px;letter-spacing:-0.18px;}
#t13_37{left:769px;bottom:215px;letter-spacing:-0.18px;}
#t14_37{left:491px;bottom:196px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t15_37{left:289px;bottom:909px;letter-spacing:-0.2px;}
#t16_37{left:289px;bottom:889px;letter-spacing:-0.17px;}
#t17_37{left:289px;bottom:868px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t18_37{left:437px;bottom:919px;letter-spacing:-0.1px;}
#t19_37{left:437px;bottom:899px;letter-spacing:-0.1px;}
#t1a_37{left:437px;bottom:879px;letter-spacing:-0.14px;}
#t1b_37{left:437px;bottom:858px;letter-spacing:-0.24px;}
#t1c_37{left:570px;bottom:909px;letter-spacing:-0.11px;}
#t1d_37{left:570px;bottom:889px;letter-spacing:-0.09px;}
#t1e_37{left:570px;bottom:868px;letter-spacing:-0.16px;}
#t1f_37{left:711px;bottom:909px;letter-spacing:-0.17px;}
#t1g_37{left:711px;bottom:889px;letter-spacing:-0.08px;}
#t1h_37{left:711px;bottom:868px;letter-spacing:-0.16px;}
#t1i_37{left:117px;bottom:919px;letter-spacing:-0.1px;}
#t1j_37{left:117px;bottom:899px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1k_37{left:117px;bottom:879px;letter-spacing:-0.12px;}
#t1l_37{left:117px;bottom:858px;letter-spacing:-0.19px;}

.s0_37{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_37{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_37{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_37{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}`}
        </style>
        <style id="fonts37" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg37" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="37/37.svg"
          type="image/svg+xml"
          id="pdf37"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_37" class="t s0_37">
          35{" "}
        </span>
        <span id="t2_37" class="t s1_37">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_37" class="t s1_37">
          хөгжүүлэх, урлаг, соёлын арга хэмжээ, дүрслэх урлаг, дизайн, зохион
          бүтээх болон төрөл{" "}
        </span>
        <span id="t4_37" class="t s1_37">
          бүрийн спортын уралдаан, тэмцээн, секц, дугуйлангийн үйл ажиллагааг
          зохион байгуулж{" "}
        </span>
        <span id="t5_37" class="t s1_37">
          байна.{" "}
        </span>
        <span id="t6_37" class="t s1_37">
          Бүтээлч төсөлт ажил хийх нь сурагчдын танхимд эзэмшсэн мэдлэг,
          чадварыг бататгах,{" "}
        </span>
        <span id="t7_37" class="t s1_37">
          баяжуулах, авьяасаа нээн хөгжүүлэх үр дүнтэй үйл ажиллагаа юм.{" "}
        </span>
        <span id="t8_37" class="t s2_37">
          Бүрэн дунд боловсролын түвшинд:{" "}
        </span>
        <span id="t9_37" class="t s2_37">
          Загвар 1
        </span>
        <span id="ta_37" class="t s1_37">
          .{" "}
        </span>
        <span id="tb_37" class="t s2_37">
          “Сургуулийн гадна орчны дизайны шийдэл”{" "}
        </span>
        <span id="tc_37" class="t s1_37">
          төсөлт ажил{" "}
        </span>
        <span id="td_37" class="t s2_37">
          Зорилго:{" "}
        </span>
        <span id="te_37" class="t s1_37">
          Сурагчдын танхимд эзэмшсэн мэдлэг, чадварыг бататгах зохион бүтээх,
          бүтээлч{" "}
        </span>
        <span id="tf_37" class="t s1_37">
          үйл ажиллагааг дэмжин хөгжүүлэхэд чиглэнэ.{" "}
        </span>
        <span id="tg_37" class="t s1_37">
          Төсөлт ажлыг дараах үе шатаар хэрэгжүүлнэ.{" "}
        </span>
        <span id="th_37" class="t s2_37">
          Арга зүй{" "}
        </span>
        <span id="ti_37" class="t s1_37">
          Сурагчдын хийх үйл ажиллагаа{" "}
        </span>
        <span id="tj_37" class="t s1_37">
          Алхам 1. Төслийн ажлын хэрэгцээ, зорилгыг тодорхойлох{" "}
        </span>
        <span id="tk_37" class="t s1_37">
          Аливаа асуудлыг шийдвэрлэхдээ байгаль орчинд аюулгүйн талаас нь авч
          үзэхийг анхаарна.{" "}
        </span>
        <span id="tl_37" class="t s1_37">
          Алхам 2. Мэдээлэл цуглуулах, судалгаа хийх{" "}
        </span>
        <span id="tm_37" class="t s1_37">
          -{" "}
        </span>
        <span id="tn_37" class="t s1_37">
          “Сургуулийн гадна орчны тохижилт” сэдвээр ярилцлага хийх, багш,
          ажилтан,{" "}
        </span>
        <span id="to_37" class="t s1_37">
          сурагч, эцэг, эхчүүдээс санал авах{" "}
        </span>
        <span id="tp_37" class="t s1_37">
          -{" "}
        </span>
        <span id="tq_37" class="t s1_37">
          Сургуулийн гадна орчны тохижилтын жишиг загваруудын судалгаа хийх
          (өөрийн{" "}
        </span>
        <span id="tr_37" class="t s1_37">
          болон бусад улс орны сургуулиудын жишээн дээр){" "}
        </span>
        <span id="ts_37" class="t s1_37">
          Судалгааг хийхэд анхаарах зүйл:{" "}
        </span>
        <span id="tt_37" class="t s1_37">
          Эдийн засгийн шаардлагын хүрээнд үнэ өртөг бага шаардсан эд зүйлсээр
          тохижуулсан{" "}
        </span>
        <span id="tu_37" class="t s1_37">
          байдал, гоо зүйн шаардлагын хүрээнд, гадна орчны тохижилтын хийсэн
          зүйлсийн харагдах{" "}
        </span>
        <span id="tv_37" class="t s1_37">
          байдал, хэлбэр, өнгө дизайны шийдэл болон осол аюулгүйн шаардлага
          хангасан байдал{" "}
        </span>
        <span id="tw_37" class="t s1_37">
          зэргийг харгалзан үзнэ.{" "}
        </span>
        <span id="tx_37" class="t s1_37">
          - Сургуулийнхаа гадна орчны хэрэгцээ,{" "}
        </span>
        <span id="ty_37" class="t s1_37">
          үндэслэлийг тодорхойлох{" "}
        </span>
        <span id="tz_37" class="t s1_37">
          - Юуг, яаж хэрхэн шийдвэрлэх талаарх{" "}
        </span>
        <span id="t10_37" class="t s1_37">
          санааг{" "}
        </span>
        <span id="t11_37" class="t s1_37">
          тодорхойлох,{" "}
        </span>
        <span id="t12_37" class="t s1_37">
          гадна{" "}
        </span>
        <span id="t13_37" class="t s1_37">
          орчны{" "}
        </span>
        <span id="t14_37" class="t s1_37">
          төлөвлөлтийн тойм зураг зурах{" "}
        </span>
        <span id="t15_37" class="t s3_37">
          Мэдээлэл{" "}
        </span>
        <span id="t16_37" class="t s3_37">
          цуглуулах,{" "}
        </span>
        <span id="t17_37" class="t s3_37">
          судалгаа хийх{" "}
        </span>
        <span id="t18_37" class="t s3_37">
          Дизайны{" "}
        </span>
        <span id="t19_37" class="t s3_37">
          шинэ санаа{" "}
        </span>
        <span id="t1a_37" class="t s3_37">
          шийдэл{" "}
        </span>
        <span id="t1b_37" class="t s3_37">
          гаргах{" "}
        </span>
        <span id="t1c_37" class="t s3_37">
          Бүтээх,{" "}
        </span>
        <span id="t1d_37" class="t s3_37">
          турших,{" "}
        </span>
        <span id="t1e_37" class="t s3_37">
          гүйцэтгэх{" "}
        </span>
        <span id="t1f_37" class="t s3_37">
          Үнэлэх,{" "}
        </span>
        <span id="t1g_37" class="t s3_37">
          үр дүнгээ{" "}
        </span>
        <span id="t1h_37" class="t s3_37">
          танилцуулах{" "}
        </span>
        <span id="t1i_37" class="t s3_37">
          Төслийн ажлын{" "}
        </span>
        <span id="t1j_37" class="t s3_37">
          хэрэгцээ болон{" "}
        </span>
        <span id="t1k_37" class="t s3_37">
          зорилгыг{" "}
        </span>
        <span id="t1l_37" class="t s3_37">
          тодорхойлох{" "}
        </span>
      </div>
    </div>
  );
}
