import React from "react";
;

export default function TwentyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p21" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_21{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_21{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_21{left:110px;bottom:1139px;letter-spacing:-0.15px;word-spacing:2.78px;}
#t4_21{left:110px;bottom:1121px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t5_21{left:110px;bottom:1102px;letter-spacing:-0.12px;word-spacing:2.29px;}
#t6_21{left:110px;bottom:1084px;letter-spacing:-0.15px;word-spacing:1.58px;}
#t7_21{left:110px;bottom:1066px;letter-spacing:-0.12px;word-spacing:2.93px;}
#t8_21{left:110px;bottom:1048px;letter-spacing:-0.11px;word-spacing:1.31px;}
#t9_21{left:110px;bottom:1030px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ta_21{left:110px;bottom:1012px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tb_21{left:110px;bottom:994px;letter-spacing:-0.12px;word-spacing:1.78px;}
#tc_21{left:110px;bottom:975px;letter-spacing:-0.12px;word-spacing:2.62px;}
#td_21{left:110px;bottom:957px;letter-spacing:-0.14px;word-spacing:0.14px;}
#te_21{left:110px;bottom:939px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tf_21{left:110px;bottom:921px;letter-spacing:-0.12px;word-spacing:-0.8px;}
#tg_21{left:110px;bottom:903px;letter-spacing:-0.16px;word-spacing:0.07px;}
#th_21{left:110px;bottom:885px;letter-spacing:-0.13px;word-spacing:1.71px;}
#ti_21{left:110px;bottom:867px;letter-spacing:-0.12px;word-spacing:0.4px;}
#tj_21{left:110px;bottom:848px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tk_21{left:110px;bottom:812px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tl_21{left:202px;bottom:812px;letter-spacing:-0.1px;}
#tm_21{left:110px;bottom:794px;letter-spacing:-0.16px;word-spacing:5.08px;}
#tn_21{left:110px;bottom:776px;letter-spacing:-0.14px;word-spacing:3.22px;}
#to_21{left:110px;bottom:758px;letter-spacing:-0.19px;word-spacing:5.57px;}
#tp_21{left:110px;bottom:740px;letter-spacing:-0.19px;word-spacing:2.11px;}
#tq_21{left:110px;bottom:722px;letter-spacing:-0.12px;word-spacing:1.9px;}
#tr_21{left:110px;bottom:703px;letter-spacing:-0.17px;word-spacing:2.64px;}
#ts_21{left:110px;bottom:685px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tt_21{left:110px;bottom:667px;letter-spacing:-0.15px;word-spacing:-1.23px;}
#tu_21{left:110px;bottom:649px;letter-spacing:-0.13px;word-spacing:2.38px;}
#tv_21{left:110px;bottom:631px;letter-spacing:-0.15px;word-spacing:2.98px;}
#tw_21{left:110px;bottom:613px;letter-spacing:-0.18px;}
#tx_21{left:110px;bottom:595px;letter-spacing:-0.15px;word-spacing:4.15px;}
#ty_21{left:110px;bottom:576px;letter-spacing:-0.14px;word-spacing:7.21px;}
#tz_21{left:110px;bottom:558px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t10_21{left:110px;bottom:540px;letter-spacing:-0.15px;word-spacing:2.48px;}
#t11_21{left:110px;bottom:522px;letter-spacing:-0.17px;word-spacing:-0.73px;}
#t12_21{left:110px;bottom:504px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t13_21{left:110px;bottom:486px;letter-spacing:-0.14px;word-spacing:1.44px;}
#t14_21{left:110px;bottom:468px;letter-spacing:-0.21px;word-spacing:0.1px;}
#t15_21{left:110px;bottom:449px;letter-spacing:-0.14px;word-spacing:-0.11px;}
#t16_21{left:110px;bottom:431px;letter-spacing:-0.14px;word-spacing:2.51px;}
#t17_21{left:110px;bottom:413px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t18_21{left:110px;bottom:377px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t19_21{left:110px;bottom:359px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1a_21{left:153px;bottom:335px;}
#t1b_21{left:180px;bottom:335px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1c_21{left:153px;bottom:316px;}
#t1d_21{left:180px;bottom:316px;letter-spacing:-0.16px;word-spacing:4.53px;}
#t1e_21{left:180px;bottom:298px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1f_21{left:153px;bottom:280px;}
#t1g_21{left:180px;bottom:280px;letter-spacing:-0.18px;word-spacing:5.77px;}
#t1h_21{left:180px;bottom:262px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1i_21{left:153px;bottom:244px;}
#t1j_21{left:180px;bottom:244px;letter-spacing:-0.19px;word-spacing:-0.25px;}
#t1k_21{left:153px;bottom:226px;}
#t1l_21{left:180px;bottom:226px;letter-spacing:-0.19px;word-spacing:0.47px;}
#t1m_21{left:180px;bottom:208px;letter-spacing:-0.17px;word-spacing:5px;}
#t1n_21{left:180px;bottom:189px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1o_21{left:110px;bottom:165px;letter-spacing:-0.18px;word-spacing:5.42px;}
#t1p_21{left:110px;bottom:147px;letter-spacing:-0.13px;word-spacing:0.94px;}
#t1q_21{left:110px;bottom:129px;letter-spacing:-0.15px;word-spacing:1.02px;}
#t1r_21{left:110px;bottom:111px;letter-spacing:-0.16px;word-spacing:-1.24px;}
#t1s_21{left:110px;bottom:93px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_21{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_21{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_21{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_21{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}`}
        </style>
        <style id="fonts21" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg21" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="21/21.svg"
          type="image/svg+xml"
          id="pdf21"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_21" class="t s0_21">
          19{" "}
        </span>
        <span id="t2_21" class="t s1_21">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_21" class="t s1_21">
          Алхам 2: Хэрэв сурагчид энэ аргыг хараахан эзэмшээгүй бол багш эхлээд
          тайлбарлаж{" "}
        </span>
        <span id="t4_21" class="t s1_21">
          үзүүлнэ. Үүний тулд:{" "}
        </span>
        <span id="t5_21" class="t s1_21">
          a) Дасгалын эхний хэсэгт: Багш текстийн эхний хэсгийг чанга дуугаар
          сурагчдад уншиж{" "}
        </span>
        <span id="t6_21" class="t s1_21">
          өгсний дараа гол утгыг нь ярьж өгнө. Гол утгыг тайлбарлахдаа уншсан
          хэсгийнхээ чухал{" "}
        </span>
        <span id="t7_21" class="t s1_21">
          мэдээллийг нь дамжуулахад анхаарахын зэрэгцээ эх текстээс арай богино,
          цөөн үгээр{" "}
        </span>
        <span id="t8_21" class="t s1_21">
          илэрхийлнэ гэдгийг сурагчдад хэлж өгнө. b) Дасгалын 2 дахь хэсэгт:
          Үлгэрийн (текстийн){" "}
        </span>
        <span id="t9_21" class="t s1_21">
          энэ хэсгийн талаар асуулт асуугаарай багш нь хариулъя гэнэ. Багш
          сурагчдын асуултад{" "}
        </span>
        <span id="ta_21" class="t s1_21">
          хариулт өгнө. Дараа нь багш сурагчид үүргээ солино.{" "}
        </span>
        <span id="tb_21" class="t s1_21">
          Алхам 3: Сурагчдыг хос хосоор хамтран ажиллуулна. Нэг сурагч текстийн
          эхний хэсгийг{" "}
        </span>
        <span id="tc_21" class="t s1_21">
          нөгөө сурагчдаа уншиж өгөхийн зэрэгцээ гол утгыг нь ярьж өгнө. Энэ
          хэсгийг тодорхой{" "}
        </span>
        <span id="td_21" class="t s1_21">
          хугацаанд гүйцэтгэх ба багш сурагчид дасгалыг хэрхэн гүйцэтгэж буйг
          хянахын тулд хэдэн{" "}
        </span>
        <span id="te_21" class="t s1_21">
          сурагчдаар гол утгыг нь яриулна.{" "}
        </span>
        <span id="tf_21" class="t s1_21">
          Мөн сурагчид бие биендээ асуулт асууж хариулт авна. 2-3 сурагчийг
          асуултаа хуваалцахыг{" "}
        </span>
        <span id="tg_21" class="t s1_21">
          хүсэх ба хэрэв асуулт оновчтой бус байвал багш нэмэлт тайлбар өгч
          тусална.{" "}
        </span>
        <span id="th_21" class="t s1_21">
          Алхам 4: Ийнхүү дасгалыг бие даан гүйцэтгэх дадал, чадвартай болсны
          дараа сурагчид{" "}
        </span>
        <span id="ti_21" class="t s1_21">
          хосоор бие даан ажиллана. Текстийн догол мөр буюу хэсэг бүрийн дараа
          сурагчид үүргээ{" "}
        </span>
        <span id="tj_21" class="t s1_21">
          сольж байхыг сануулна.{" "}
        </span>
        <span id="tk_21" class="t s2_21">
          Кейс арга:{" "}
        </span>
        <span id="tl_21" class="t s2_21">
          (Case method){" "}
        </span>
        <span id="tm_21" class="t s1_21">
          Тохиолдол буюу кейс шинжлэх арга нь амьдралын тодорхой жишээн дээр
          үндэслэж{" "}
        </span>
        <span id="tn_21" class="t s1_21">
          задлан шинжилгээнд мэдээ баримтыг ашигладаг арга. Кейс судалгааг
          нөхцөл байдлыг{" "}
        </span>
        <span id="to_21" class="t s1_21">
          тайлбарлах, шүүмжлэх, эрэл хайгуул хийх зэрэгт ашигладаг. Кейс
          судалгааны давуу{" "}
        </span>
        <span id="tp_21" class="t s1_21">
          талууд бол амьдралтай шууд холбогддог, хүүхдүүдэд ойлгомжтой нээлттэй
          байдаг зэрэг{" "}
        </span>
        <span id="tq_21" class="t s1_21">
          юм. Кейс арга нь өдөр тутмын амьдралын туршлага, бодит амьдралын
          нөхцөл байдлыг{" "}
        </span>
        <span id="tr_21" class="t s1_21">
          хэрхэн ойлгож байгаатай нь шууд холбогддог үр дүнтэй чанарын арга
          бөгөөд асуултын{" "}
        </span>
        <span id="ts_21" class="t s1_21">
          аргад тулгуурласан хэлбэр юм.{" "}
        </span>
        <span id="tt_21" class="t s1_21">
          Багш асуултаар болон тодорхой жишээгээр чиглүүлэх үед цээжлэн тогтоох
          эхний хэлбэрээс{" "}
        </span>
        <span id="tu_21" class="t s1_21">
          эхлээд бодит баримтыг үнэлэх дүгнэх сэтгэхүйн үйлдлүүдийг хийж сурах
          учир хүүхдүүд{" "}
        </span>
        <span id="tv_21" class="t s1_21">
          тухайн сэдвийг сонирхох, мэдээлэл сонирхон судлах, идэвхийг
          нэмэгдүүлэхэд энэ арга{" "}
        </span>
        <span id="tw_21" class="t s1_21">
          чиглэгдэнэ.{" "}
        </span>
        <span id="tx_21" class="t s1_21">
          Бага боловсролын түвшинд багш асуултаар чиглүүлэн үлгэрийн үйл явдлыг
          бодуулах{" "}
        </span>
        <span id="ty_21" class="t s1_21">
          дүгнүүлэх, санаагаа илэрхийлэх боломж олгосноор сэтгэхүйн үйлдүүдийг
          хөгжүүлэх{" "}
        </span>
        <span id="tz_21" class="t s1_21">
          боломж бүрдүүлэх юм.{" "}
        </span>
        <span id="t10_21" class="t s1_21">
          Суурь болон бүрэн дунд боловсролын түвшинд ихэвчлэн эх бичвэрийн
          хэлбэртэй байж{" "}
        </span>
        <span id="t11_21" class="t s1_21">
          болох боловч кино дүрс, дуу бичлэг, маргаан зохиох арга ашиглах, дүр
          бүтээж тоглох болон{" "}
        </span>
        <span id="t12_21" class="t s1_21">
          бусад хэлбэрээр байж болно.{" "}
        </span>
        <span id="t13_21" class="t s1_21">
          Багш нь энэ аргаар хичээл явуулахад ярилцлагыг хөтлөгчийн дүрд тоглох
          учир энэ дүрд{" "}
        </span>
        <span id="t14_21" class="t s1_21">
          бэлэн байна{" "}
        </span>
        <span id="t15_21" class="t s1_21">
          Энэ аргыг хэрэглэхдээ багш 25 хүртэл хүүхэдтэй ажиллах бөгөөд хэтэрхий
          олон хүүхэдтэй{" "}
        </span>
        <span id="t16_21" class="t s1_21">
          багш нэгэн зэрэг ажилласан тохиолдолд хичээлийн зорилго, үр дүнд
          муугаар нөлөөлөх{" "}
        </span>
        <span id="t17_21" class="t s1_21">
          сөрөг талтай.{" "}
        </span>
        <span id="t18_21" class="t s3_21">
          Кейс бэлтгэх{" "}
        </span>
        <span id="t19_21" class="t s1_21">
          Кейс бэлтгэх сонгож авах болон боловсруулахдаа дараах зүйлүүдийг
          анхаарна. Үүнд:{" "}
        </span>
        <span id="t1a_21" class="t s1_21">
          -{" "}
        </span>
        <span id="t1b_21" class="t s1_21">
          Тухайн хичээлийн агуулга, арга, үр дүнд тохирсон байдал{" "}
        </span>
        <span id="t1c_21" class="t s1_21">
          -{" "}
        </span>
        <span id="t1d_21" class="t s1_21">
          Гэр бүлийн амьдралд тохиолддог асуудлуудыг тусгасан буюу бодит байдалд{" "}
        </span>
        <span id="t1e_21" class="t s1_21">
          бүрэн нийцсэн байдал{" "}
        </span>
        <span id="t1f_21" class="t s1_21">
          -{" "}
        </span>
        <span id="t1g_21" class="t s1_21">
          Тохиолдол нь хүүхдүүдэд анализ хийх, шийдэл гаргах, бодож боловсруулах{" "}
        </span>
        <span id="t1h_21" class="t s1_21">
          боломж олгохуйц байгаа байдал{" "}
        </span>
        <span id="t1i_21" class="t s1_21">
          -{" "}
        </span>
        <span id="t1j_21" class="t s1_21">
          Тохиолдлын талаар эерэг, сөрөг нотолгооны хувилбар, шийдэлд хүрэхүйц
          байдал{" "}
        </span>
        <span id="t1k_21" class="t s1_21">
          -{" "}
        </span>
        <span id="t1l_21" class="t s1_21">
          Тухайн тохиолдлыг шинжилж, шийдвэр гаргахад шаардлагатай баримт
          нотолгоог{" "}
        </span>
        <span id="t1m_21" class="t s1_21">
          бүрдүүлж, холбогдох мэдээллийг өгөх нь мэдээллийг боловсруулж,
          үндэслэл{" "}
        </span>
        <span id="t1n_21" class="t s1_21">
          бүхий шийдвэр гаргахад чухал нөлөөтэй юм.{" "}
        </span>
        <span id="t1o_21" class="t s1_21">
          Тохиолдлыг уншуулж танилцуулахдаа тохиолдлын эцсийн шийдийг
          мэдээлэхгүй байх{" "}
        </span>
        <span id="t1p_21" class="t s1_21">
          хэрэгтэй бөгөөд уг асуудлын бодит шийдлийг хичээлийн төгсгөлд авч үзэж
          болно. Гэхдээ{" "}
        </span>
        <span id="t1q_21" class="t s1_21">
          зарим тохиолдлоос хамаарч тухайн өгөгдлийн шийдлийг хэлээд зөв, эсвэл
          буруу шийдэл{" "}
        </span>
        <span id="t1r_21" class="t s1_21">
          болох талаар хэлэлцүүлж болох юм. Хүүхдүүдийн анхаарлыг бодит баримтын
          учрыг олоход{" "}
        </span>
        <span id="t1s_21" class="t s1_21">
          чиглүүлэх хэрэгтэй.{" "}
        </span>
      </div>
    </div>
  );
}
