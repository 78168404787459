import React from "react";
;

export default function SixOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`
#t1_61{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_61{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_61{left:110px;bottom:1139px;letter-spacing:-0.22px;word-spacing:0.12px;}
#t4_61{left:118px;bottom:1114px;letter-spacing:0.12px;word-spacing:0.01px;}
#t5_61{left:118px;bottom:1084px;letter-spacing:0.1px;word-spacing:0.04px;}
#t6_61{left:118px;bottom:1054px;letter-spacing:0.08px;word-spacing:0.05px;}
#t7_61{left:118px;bottom:1024px;letter-spacing:0.13px;word-spacing:0.01px;}
#t8_61{left:118px;bottom:1007px;letter-spacing:0.05px;word-spacing:0.08px;}
#t9_61{left:118px;bottom:977px;letter-spacing:0.08px;word-spacing:0.05px;}
#ta_61{left:118px;bottom:947px;letter-spacing:0.09px;word-spacing:0.05px;}
#tb_61{left:118px;bottom:930px;letter-spacing:0.13px;}
#tc_61{left:118px;bottom:900px;letter-spacing:0.09px;word-spacing:0.05px;}
#td_61{left:118px;bottom:870px;letter-spacing:0.11px;word-spacing:0.03px;}
#te_61{left:118px;bottom:852px;letter-spacing:0.05px;word-spacing:0.08px;}
#tf_61{left:110px;bottom:813px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tg_61{left:110px;bottom:795px;letter-spacing:-0.16px;word-spacing:0.06px;}
#th_61{left:110px;bottom:777px;letter-spacing:-0.14px;word-spacing:3.93px;}
#ti_61{left:586px;bottom:777px;letter-spacing:-0.15px;word-spacing:3.93px;}
#tj_61{left:110px;bottom:759px;letter-spacing:-0.15px;word-spacing:-0.41px;}
#tk_61{left:599px;bottom:759px;letter-spacing:-0.11px;word-spacing:-0.46px;}
#tl_61{left:110px;bottom:741px;letter-spacing:-0.1px;word-spacing:0.6px;}
#tm_61{left:110px;bottom:723px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tn_61{left:110px;bottom:686px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_61{left:118px;bottom:661px;}
#tp_61{left:172px;bottom:661px;letter-spacing:0.12px;word-spacing:0.03px;}
#tq_61{left:426px;bottom:661px;letter-spacing:0.04px;}
#tr_61{left:572px;bottom:661px;letter-spacing:0.13px;word-spacing:0.02px;}
#ts_61{left:118px;bottom:635px;}
#tt_61{left:118px;bottom:606px;}
#tu_61{left:118px;bottom:578px;}
#tv_61{left:110px;bottom:536px;letter-spacing:-0.15px;word-spacing:0.03px;}

.s0_61{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_61{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_61{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s3_61{font-size:15px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts61" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg61" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="61/61.svg"
          type="image/svg+xml"
          id="pdf61"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_61" class="t s0_61">
          59{" "}
        </span>
        <span id="t2_61" class="t s1_61">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_61" class="t s1_61">
          Жишээ 5: Удирдамж{" "}
        </span>
        <span id="t4_61" class="t s2_61">
          Төсөлт хичээл 1. (дугуйлан, секцийн нэр){" "}
        </span>
        <span id="t5_61" class="t s2_61">
          Төслийн зорилго: Тухайн секц, дугуйлангийн зорилгыг тодорхойлж бичих{" "}
        </span>
        <span id="t6_61" class="t s2_61">
          Хугацаа: Хичээллэх нийт хугацааг бичнэ. Жич: 2022 оны 10 сараас 2023
          оны 05 сар хүртэл г.м{" "}
        </span>
        <span id="t7_61" class="t s2_61">
          Хамрах хүрээ: Секц дугуйланд хамрах сурагчдыг анги, болон насаар нь
          ангилж болно. Жич: 7-9{" "}
        </span>
        <span id="t8_61" class="t s2_61">
          дүгээр анги, 12-14 нас г.м …{" "}
        </span>
        <span id="t9_61" class="t s2_61">
          Хичээллэх өдөр цаг: Даваа гараг бүрийн 16:00-17:00 цагт ….. тоот өрөө,
          танхим г.м{" "}
        </span>
        <span id="ta_61" class="t s2_61">
          Тавигдах шаардлага: секц дугуйланд хамрагдах суралцагчдад тавих
          ерөнхий шаардлагыг{" "}
        </span>
        <span id="tb_61" class="t s2_61">
          бичнэ. /секц, дугуйлан тус бүрийн онцлогт нийцсэн байх/{" "}
        </span>
        <span id="tc_61" class="t s2_61">
          Сурагчдад өгөх чадварууд: Суралцагчдад олгох ерөнхий болон тусгай ур
          чадваруудыг бичнэ.{" "}
        </span>
        <span id="td_61" class="t s2_61">
          Хүлээгдэж буй үр дүн: Суралцагчдын эзэмшсэн мэдлэг чадварын үр дүнд
          бий болох{" "}
        </span>
        <span id="te_61" class="t s2_61">
          бүтээгдэхүүн, ур чадварын үзүүлбэр г.м …..{" "}
        </span>
        <span id="tf_61" class="t s1_61">
          Зорилтуудыг хэрэгжүүлэх үйл ажиллагаагаар ХГСА-н төлөвлөлтийг хийх нь
          зохимжтой.{" "}
        </span>
        <span id="tg_61" class="t s1_61">
          ХГСА-н төлөвлөлт гаргахдаа зорилтуудыг интеграцчилах байдлаар хийж
          болно.{" "}
        </span>
        <span id="th_61" class="t s1_61">
          Жишээ нь: Эрүүл аюулгүй амьдрах арга ухаанд сургах,{" "}
        </span>
        <span id="ti_61" class="t s1_61">
          Болзошгүй эрсдэл, зөрчлөөс{" "}
        </span>
        <span id="tj_61" class="t s1_61">
          урьдчилан сэргийлэх ур чадвар эзэмшүүлэх гэсэн 2 зорилтыг,{" "}
        </span>
        <span id="tk_61" class="t s1_61">
          Суралцагчдын нийгэм, олон{" "}
        </span>
        <span id="tl_61" class="t s1_61">
          нийтийн үйл ажиллагааг дэмжих, Эх оронч болон хөдөлмөрийн хүмүүжлийг
          дэмжих гэсэн{" "}
        </span>
        <span id="tm_61" class="t s1_61">
          2 зорилтуудыг нэгтгэх байдлаар авч үзэж болно.{" "}
        </span>
        <span id="tn_61" class="t s1_61">
          Үйл ажиллагааны төлөвлөгөө /хийгдэх ажлуудыг өдөр тус бүрээр нь
          дэлгэрэнгүй бичих/{" "}
        </span>
        <span id="to_61" class="t s2_61">
          №{" "}
        </span>
        <span id="tp_61" class="t s2_61">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tq_61" class="t s2_61">
          Хугацаа{" "}
        </span>
        <span id="tr_61" class="t s2_61">
          Хариуцах эзэн{" "}
        </span>
        <span id="ts_61" class="t s2_61">
          1{" "}
        </span>
        <span id="tt_61" class="t s3_61">
          2{" "}
        </span>
        <span id="tu_61" class="t s2_61">
          3{" "}
        </span>
        <span id="tv_61" class="t s1_61">
          Төсөл хариуцсан багш: ……………{" "}
        </span>
      </div>
    </div>
  );
}
