import React from "react";
;

export default function FourEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_48{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_48{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_48{left:110px;bottom:1077px;letter-spacing:-0.11px;}
#t4_48{left:233px;bottom:1077px;letter-spacing:-0.14px;word-spacing:6.43px;}
#t5_48{left:110px;bottom:1059px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t6_48{left:110px;bottom:1022px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t7_48{left:145px;bottom:1004px;}
#t8_48{left:173px;bottom:1004px;letter-spacing:-0.1px;}
#t9_48{left:145px;bottom:986px;}
#ta_48{left:173px;bottom:986px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tb_48{left:145px;bottom:968px;}
#tc_48{left:173px;bottom:968px;letter-spacing:-0.14px;word-spacing:0.04px;}
#td_48{left:145px;bottom:950px;}
#te_48{left:173px;bottom:950px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tf_48{left:173px;bottom:932px;letter-spacing:-0.23px;word-spacing:0.13px;}
#tg_48{left:145px;bottom:914px;}
#th_48{left:173px;bottom:914px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ti_48{left:145px;bottom:895px;}
#tj_48{left:173px;bottom:895px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tk_48{left:145px;bottom:877px;}
#tl_48{left:173px;bottom:877px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tm_48{left:145px;bottom:859px;}
#tn_48{left:173px;bottom:859px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_48{left:110px;bottom:823px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tp_48{left:153px;bottom:799px;}
#tq_48{left:180px;bottom:799px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tr_48{left:153px;bottom:781px;}
#ts_48{left:180px;bottom:781px;letter-spacing:-0.15px;word-spacing:-1.68px;}
#tt_48{left:153px;bottom:762px;}
#tu_48{left:180px;bottom:762px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tv_48{left:153px;bottom:744px;}
#tw_48{left:180px;bottom:744px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tx_48{left:153px;bottom:726px;}
#ty_48{left:180px;bottom:726px;letter-spacing:-0.14px;word-spacing:4.09px;}
#tz_48{left:180px;bottom:708px;letter-spacing:-0.1px;}
#t10_48{left:153px;bottom:690px;}
#t11_48{left:180px;bottom:690px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t12_48{left:153px;bottom:672px;}
#t13_48{left:180px;bottom:672px;letter-spacing:-0.15px;word-spacing:2.71px;}
#t14_48{left:180px;bottom:654px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t15_48{left:110px;bottom:629px;letter-spacing:-0.12px;word-spacing:-2.74px;}
#t16_48{left:110px;bottom:611px;letter-spacing:-0.12px;word-spacing:4.79px;}
#t17_48{left:110px;bottom:593px;letter-spacing:-0.14px;word-spacing:-0.13px;}
#t18_48{left:110px;bottom:575px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t19_48{left:110px;bottom:539px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1a_48{left:220px;bottom:539px;}
#t1b_48{left:110px;bottom:521px;letter-spacing:-0.12px;word-spacing:4.56px;}
#t1c_48{left:110px;bottom:502px;letter-spacing:-0.15px;word-spacing:3.99px;}
#t1d_48{left:110px;bottom:484px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1e_48{left:110px;bottom:448px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1f_48{left:110px;bottom:430px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1g_48{left:110px;bottom:394px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1h_48{left:153px;bottom:369px;}
#t1i_48{left:180px;bottom:369px;letter-spacing:-0.16px;word-spacing:1.78px;}
#t1j_48{left:180px;bottom:351px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t1k_48{left:153px;bottom:333px;}
#t1l_48{left:180px;bottom:333px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1m_48{left:153px;bottom:315px;}
#t1n_48{left:180px;bottom:315px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1o_48{left:153px;bottom:297px;}
#t1p_48{left:180px;bottom:297px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1q_48{left:153px;bottom:279px;}
#t1r_48{left:180px;bottom:279px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1s_48{left:131px;bottom:1155px;letter-spacing:-0.15px;word-spacing:1.12px;}
#t1t_48{left:131px;bottom:1137px;letter-spacing:-0.1px;}
#t1u_48{left:131px;bottom:1113px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_48{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_48{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_48{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_48{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s4_48{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}`}
        </style>

        <style id="fonts48" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg48" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="48/48.svg"
          type="image/svg+xml"
          id="pdf48"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_48" class="t s0_48">
          46{" "}
        </span>
        <span id="t2_48" class="t s1_48">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_48" class="t s2_48">
          7.2.1.Зорилго:{" "}
        </span>
        <span id="t4_48" class="t s1_48">
          Сурагчдыг ирээдүйн ажил мэргэжлээ төлөвлөх, шийдвэр гаргахад нь{" "}
        </span>
        <span id="t5_48" class="t s1_48">
          дэмжлэг үзүүлэхэд чиглэгдэнэ.{" "}
        </span>
        <span id="t6_48" class="t s1_48">
          7.2.2.Үндсэн ойлголт:{" "}
        </span>
        <span id="t7_48" class="t s3_48">
          •{" "}
        </span>
        <span id="t8_48" class="t s1_48">
          Ажил мэргэжил гэж юу вэ?{" "}
        </span>
        <span id="t9_48" class="t s3_48">
          •{" "}
        </span>
        <span id="ta_48" class="t s1_48">
          Ажил мэргэжлээ хэрхэн төлөвлөх вэ?{" "}
        </span>
        <span id="tb_48" class="t s3_48">
          •{" "}
        </span>
        <span id="tc_48" class="t s1_48">
          Ажил мэргэжлийн чиг баримжаа олгох гэж юу вэ?{" "}
        </span>
        <span id="td_48" class="t s3_48">
          •{" "}
        </span>
        <span id="te_48" class="t s1_48">
          Суралцах чиглэл, ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааны ач{" "}
        </span>
        <span id="tf_48" class="t s1_48">
          холбогдол юу вэ?{" "}
        </span>
        <span id="tg_48" class="t s3_48">
          •{" "}
        </span>
        <span id="th_48" class="t s1_48">
          Эцэг эх асран хамгаалагч ямар үүрэгтэй вэ?{" "}
        </span>
        <span id="ti_48" class="t s3_48">
          •{" "}
        </span>
        <span id="tj_48" class="t s1_48">
          Сургууль ямар үүрэг хүлээж, дэмжлэг үзүүлэх вэ?{" "}
        </span>
        <span id="tk_48" class="t s3_48">
          •{" "}
        </span>
        <span id="tl_48" class="t s1_48">
          Ажил олгогч гэж хэнийг хэлэх вэ?{" "}
        </span>
        <span id="tm_48" class="t s3_48">
          •{" "}
        </span>
        <span id="tn_48" class="t s1_48">
          Ажил олгогч сургууль, сурагчдад ямар дэмжлэг үзүүлэх вэ?{" "}
        </span>
        <span id="to_48" class="t s1_48">
          7.2.3.Үйл ажиллагаа:{" "}
        </span>
        <span id="tp_48" class="t s1_48">
          -{" "}
        </span>
        <span id="tq_48" class="t s1_48">
          Монгол улс дахь ажил мэргэжлийн өнөөгийн нөхцөл байдлын судалгаа{" "}
        </span>
        <span id="tr_48" class="t s1_48">
          -{" "}
        </span>
        <span id="ts_48" class="t s1_48">
          ЕБС-ийн ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааны талаарх
          судалгаа{" "}
        </span>
        <span id="tt_48" class="t s1_48">
          -{" "}
        </span>
        <span id="tu_48" class="t s1_48">
          Ажил мэргэжлийн баримжаа олгох ажлын зохион байгуулалт{" "}
        </span>
        <span id="tv_48" class="t s1_48">
          -{" "}
        </span>
        <span id="tw_48" class="t s1_48">
          Ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааны төрөл, агуулга{" "}
        </span>
        <span id="tx_48" class="t s1_48">
          -{" "}
        </span>
        <span id="ty_48" class="t s1_48">
          Сургуулийн түвшинд ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааны{" "}
        </span>
        <span id="tz_48" class="t s1_48">
          хяналт шинжилгээ{" "}
        </span>
        <span id="t10_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t11_48" class="t s1_48">
          Ажил мэргэжлийн сонголт хийхэд сурагч, багш, эцэг эхэд үзүүлэх дэмжлэг{" "}
        </span>
        <span id="t12_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t13_48" class="t s1_48">
          Ажил мэргэжлийн баримжаа олгох чиглэлээр хамтарч ажилладаг орон
          нутгийн{" "}
        </span>
        <span id="t14_48" class="t s1_48">
          байгууллагын үйл ажиллагаа, дэмжлэг{" "}
        </span>
        <span id="t15_48" class="t s1_48">
          Энэхүү үйл ажиллагааг хэрэгжүүлэхдээ БСШУЯ болон АХБ-ны хөдөлмөр
          эрхлэх, ур чадварыг{" "}
        </span>
        <span id="t16_48" class="t s1_48">
          дээшлүүлэх төслийн хүрээнд гаргасан ЕБС-ийн 8, 9, 10 дугаар ангийн
          суралцагчдад{" "}
        </span>
        <span id="t17_48" class="t s1_48">
          суралцах чиглэл, ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааг
          хэрэгжүүлэх багш,{" "}
        </span>
        <span id="t18_48" class="t s1_48">
          сурагч, эцэг эхийн гарын авлагыг хэрэглэнэ.{" "}
        </span>
        <span id="t19_48" class="t s2_48">
          7.2.4.Арга зүй
        </span>
        <span id="t1a_48" class="t s1_48">
          :{" "}
        </span>
        <span id="t1b_48" class="t s1_48">
          Ажил мэргэжлийн сонголт хийхэд нь сурагчдад дэмжлэг үзүүлэхийн тулд
          янз бүрийн{" "}
        </span>
        <span id="t1c_48" class="t s1_48">
          мэргэжилтэй хүмүүстэй санал бодлоо солилцох, тулгамдаж байгаа асуудлыг
          хэлэлцэх{" "}
        </span>
        <span id="t1d_48" class="t s1_48">
          зорилгоор уулзалтыг зохион байгуулж болно.{" "}
        </span>
        <span id="t1e_48" class="t s4_48">
          Загвар 1. Уулзалт ярилцлага зохион байгуулах арга зүй{" "}
        </span>
        <span id="t1f_48" class="t s1_48">
          Уулзалтыг амжилттай явуулахын тулд төлөвлөлт болон бэлтгэл ажил чухал
          юм.{" "}
        </span>
        <span id="t1g_48" class="t s1_48">
          Уулзалт зохион байгуулахад дараах зүйлсийг анхаарч ажиллана. Үүнд:{" "}
        </span>
        <span id="t1h_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t1i_48" class="t s1_48">
          Уулзалтад оролцож буй оролцогчдод өөрийн үзэл бодлоо чөлөөтэй
          илэрхийлэх{" "}
        </span>
        <span id="t1j_48" class="t s1_48">
          боломж олгох{" "}
        </span>
        <span id="t1k_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t1l_48" class="t s1_48">
          Эерэг харилцааг мэдэрч, сонирхолтой, хөгжилтэй болгох орчинг бүрдүүлэх{" "}
        </span>
        <span id="t1m_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t1n_48" class="t s1_48">
          Хүн болгоныг оролцуулах гэж нурших хэрэггүй{" "}
        </span>
        <span id="t1o_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t1p_48" class="t s1_48">
          Уулзалтад хамааралгүй зүйлсийн талаар ярихгүй байх{" "}
        </span>
        <span id="t1q_48" class="t s1_48">
          -{" "}
        </span>
        <span id="t1r_48" class="t s1_48">
          Уулзалт 1 цагаас илүү үргэлжлэх бол завсарлагааны цагийг төлөвлөх{" "}
        </span>
        <span id="t1s_48" class="t s1_48">
          7.2. Суралцагчдад суралцах чиглэл, мэргэжлээ тодорхойлж, ухамсартай
          сонгоход{" "}
        </span>
        <span id="t1t_48" class="t s1_48">
          дэмжлэг үзүүлэх{" "}
        </span>
        <span id="t1u_48" class="t s1_48">
          7.3.Ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааг хэрэгжүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
