import React from "react";

export default function One() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, 0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };

  return (
    <div id="p1" style={containerStyle}>
      <div id="pg1Overlay" style={overlayStyle}></div>
      <div id="pg1" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style={pdfStyle}></object>
      </div>
    </div>
  );
}
