import React from "react";
;

export default function ThreeNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_39{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_39{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_39{left:110px;bottom:1157px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t4_39{left:153px;bottom:1133px;}
#t5_39{left:180px;bottom:1133px;letter-spacing:-0.14px;}
#t6_39{left:180px;bottom:1114px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t7_39{left:153px;bottom:1096px;}
#t8_39{left:180px;bottom:1096px;letter-spacing:-0.13px;word-spacing:3.53px;}
#t9_39{left:180px;bottom:1078px;letter-spacing:-0.1px;}
#ta_39{left:153px;bottom:1060px;}
#tb_39{left:180px;bottom:1060px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tc_39{left:110px;bottom:901px;letter-spacing:-0.14px;word-spacing:0.03px;}
#td_39{left:286px;bottom:901px;}
#te_39{left:153px;bottom:877px;}
#tf_39{left:180px;bottom:877px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tg_39{left:153px;bottom:859px;}
#th_39{left:180px;bottom:859px;letter-spacing:-0.13px;}
#ti_39{left:153px;bottom:841px;}
#tj_39{left:180px;bottom:841px;letter-spacing:-0.12px;}
#tk_39{left:118px;bottom:808px;letter-spacing:-0.13px;word-spacing:-1.81px;}
#tl_39{left:118px;bottom:790px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tm_39{left:118px;bottom:759px;letter-spacing:-0.19px;word-spacing:8.23px;}
#tn_39{left:118px;bottom:741px;letter-spacing:-0.17px;word-spacing:2.25px;}
#to_39{left:118px;bottom:723px;letter-spacing:-0.1px;}
#tp_39{left:110px;bottom:685px;letter-spacing:-0.13px;}
#tq_39{left:191px;bottom:685px;letter-spacing:-0.14px;word-spacing:6.27px;}
#tr_39{left:110px;bottom:666px;letter-spacing:-0.14px;word-spacing:4.25px;}
#ts_39{left:110px;bottom:648px;letter-spacing:-0.13px;word-spacing:-1.71px;}
#tt_39{left:110px;bottom:612px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tu_39{left:153px;bottom:588px;}
#tv_39{left:180px;bottom:588px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tw_39{left:153px;bottom:570px;}
#tx_39{left:180px;bottom:570px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ty_39{left:153px;bottom:552px;}
#tz_39{left:180px;bottom:552px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t10_39{left:153px;bottom:533px;}
#t11_39{left:180px;bottom:533px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t12_39{left:110px;bottom:509px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t13_39{left:110px;bottom:491px;letter-spacing:-0.17px;word-spacing:6.67px;}
#t14_39{left:110px;bottom:473px;letter-spacing:-0.17px;word-spacing:2.16px;}
#t15_39{left:110px;bottom:455px;letter-spacing:-0.17px;word-spacing:0.56px;}
#t16_39{left:110px;bottom:437px;letter-spacing:-0.18px;word-spacing:0.26px;}
#t17_39{left:110px;bottom:418px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t18_39{left:139px;bottom:400px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t19_39{left:139px;bottom:382px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1a_39{left:139px;bottom:364px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1b_39{left:702px;bottom:364px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1c_39{left:139px;bottom:346px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1d_39{left:139px;bottom:328px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1e_39{left:139px;bottom:310px;letter-spacing:-0.25px;word-spacing:0.16px;}
#t1f_39{left:372px;bottom:310px;letter-spacing:-0.14px;}
#t1g_39{left:472px;bottom:310px;letter-spacing:-0.2px;}
#t1h_39{left:515px;bottom:310px;letter-spacing:-0.21px;}
#t1i_39{left:139px;bottom:292px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1j_39{left:617px;bottom:292px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1k_39{left:139px;bottom:273px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1l_39{left:139px;bottom:255px;letter-spacing:-0.12px;word-spacing:-1.76px;}
#t1m_39{left:634px;bottom:255px;letter-spacing:-0.15px;word-spacing:-1.74px;}
#t1n_39{left:139px;bottom:237px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1o_39{left:139px;bottom:219px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1p_39{left:139px;bottom:201px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1q_39{left:139px;bottom:183px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1r_39{left:139px;bottom:165px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1s_39{left:110px;bottom:146px;letter-spacing:-0.14px;word-spacing:1.02px;}
#t1t_39{left:110px;bottom:128px;letter-spacing:-0.15px;word-spacing:3.92px;}
#t1u_39{left:110px;bottom:110px;letter-spacing:-0.16px;word-spacing:0.07px;}

.s0_39{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_39{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_39{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}`}
        </style>

        <style id="fonts39" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg39" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="39/39.svg"
          type="image/svg+xml"
          id="pdf39"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_39" class="t s0_39">
          37{" "}
        </span>
        <span id="t2_39" class="t s1_39">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_39" class="t s1_39">
          Алхам 5. Үнэлэх, үр дүнгээ танилцуулах{" "}
        </span>
        <span id="t4_39" class="t s1_39">
          -{" "}
        </span>
        <span id="t5_39" class="t s1_39">
          Сургуулийн орчны төлөвлөлт төсөлт ажлын үр дүнг сургуулийн удирдах
          ажилтан,{" "}
        </span>
        <span id="t6_39" class="t s1_39">
          багш, сурагч, эцэг эхчүүдэд танилцуулах, санал авах{" "}
        </span>
        <span id="t7_39" class="t s1_39">
          -{" "}
        </span>
        <span id="t8_39" class="t s1_39">
          Төслийг хэрэгжүүлэх санхүүгийн болон бусад нөөц боломжийг ашиглах
          санал{" "}
        </span>
        <span id="t9_39" class="t s1_39">
          дэвшүүлэх{" "}
        </span>
        <span id="ta_39" class="t s1_39">
          -{" "}
        </span>
        <span id="tb_39" class="t s1_39">
          Цаашид хийх үйл ажиллагааны чиглэлийг тодорхойлох{" "}
        </span>
        <span id="tc_39" class="t s2_39">
          Цахим хэрэглэгдэхүүн
        </span>
        <span id="td_39" class="t s1_39">
          :{" "}
        </span>
        <span id="te_39" class="t s1_39">
          -{" "}
        </span>
        <span id="tf_39" class="t s1_39">
          Gservis projekty a domy, “Projekty rodinnych domu” цахим сэтгүүл, CZ
          2018.{" "}
        </span>
        <span id="tg_39" class="t s1_39">
          -{" "}
        </span>
        <span id="th_39" class="t s1_39">
          https://www.pinterest.com{" "}
        </span>
        <span id="ti_39" class="t s1_39">
          -{" "}
        </span>
        <span id="tj_39" class="t s1_39">
          www.gservis.czen.wikipedia.org{" "}
        </span>
        <span id="tk_39" class="t s1_39">
          4.3.Уламжлалт өв соёл, түүн дотроо соёлын биет бус өвийг өвлүүлэх,
          түгээн дэлгэрүүлэх,{" "}
        </span>
        <span id="tl_39" class="t s1_39">
          сурталчлах үйл ажиллагааг тогтмол хэрэгжүүлэх{" "}
        </span>
        <span id="tm_39" class="t s1_39">
          4.4.Орчин үеийн цаашилбал дэлхийн урлаг, уран сайхны бүтээлийг
          сурталчлах{" "}
        </span>
        <span id="tn_39" class="t s1_39">
          зорилготой хүүхдийн урлаг, соёлын үйл ажиллагааг нэмэгдүүлэх, түгээн
          дэлгэрүүлэх,{" "}
        </span>
        <span id="to_39" class="t s1_39">
          дэмжин хөгжүүлэх{" "}
        </span>
        <span id="tp_39" class="t s2_39">
          Зорилго.{" "}
        </span>
        <span id="tq_39" class="t s1_39">
          ЕБС-ийн суралцагчдад болзошгүй эрсдэл, зөрчил гэж юуг ойлгох, зөрчил,{" "}
        </span>
        <span id="tr_39" class="t s1_39">
          хүчирхийлэл үйлдэгдэн гарахад нөлөөлж байгаа шалтгаан нөхцөл, түүнээс
          урьдчилан{" "}
        </span>
        <span id="ts_39" class="t s1_39">
          сэргийлэх арга, хувь хүний хариуцлагын талаар анхан шатны мэдлэг
          эзэмшүүлэхэд оршино.{" "}
        </span>
        <span id="tt_39" class="t s2_39">
          Үндсэн ойлголт{" "}
        </span>
        <span id="tu_39" class="t s1_39">
          -{" "}
        </span>
        <span id="tv_39" class="t s1_39">
          Соёлын биет бус өвийн тухай{" "}
        </span>
        <span id="tw_39" class="t s1_39">
          -{" "}
        </span>
        <span id="tx_39" class="t s1_39">
          Соёлын биет өвийн тухай{" "}
        </span>
        <span id="ty_39" class="t s1_39">
          -{" "}
        </span>
        <span id="tz_39" class="t s1_39">
          ЮНЕСКО-д бүртгэгдсэн Монголын өв{" "}
        </span>
        <span id="t10_39" class="t s1_39">
          -{" "}
        </span>
        <span id="t11_39" class="t s1_39">
          Соёлын биет бус өвийг өвлөн уламжлагч гэж хэн бэ?{" "}
        </span>
        <span id="t12_39" class="t s2_39">
          Үйл ажиллагааны чиглэл:{" "}
        </span>
        <span id="t13_39" class="t s1_39">
          Соёлын өвийг хамгаалах тухай хуулинд заахдаа:Ард түмний билэг авъяас,
          мэдлэг,{" "}
        </span>
        <span id="t14_39" class="t s1_39">
          туршлага, эрдэм ухаан, ур чадварын тодорхой илэрхийлэл болон биет
          бусаар өвлөгдөн{" "}
        </span>
        <span id="t15_39" class="t s1_39">
          уламжлагдаж ирсэн түүх, угсаатны судлал, ардын билэг, зан үйл, ур
          хийц, арга барил, дэг{" "}
        </span>
        <span id="t16_39" class="t s1_39">
          сургууль, урлаг, шинжлэх ухааны ач холбогдол бүхий дараахь оюуны
          соёлын үнэт зүйлийг{" "}
        </span>
        <span id="t17_39" class="t s1_39">
          соёлын биет бус өвд хамааруулна гэж заасан байдаг.{" "}
        </span>
        <span id="t18_39" class="t s1_39">
          4.1.1. эх хэл, бичиг, түүний хүрээний соёл{" "}
        </span>
        <span id="t19_39" class="t s1_39">
          4.1.2. аман зохиол{" "}
        </span>
        <span id="t1a_39" class="t s1_39">
          4.1.3. ардын уртын болон богино дуу, тууль, тэдгээрийг дуулах, хайлах{" "}
        </span>
        <span id="t1b_39" class="t s1_39">
          арга барил{" "}
        </span>
        <span id="t1c_39" class="t s1_39">
          4.1.4. хөдөлмөр, зан үйлийн холбогдолтой уриа дуудлага{" "}
        </span>
        <span id="t1d_39" class="t s1_39">
          4.1.5. хөөмий, исгэрээ, аман болон тагнай ташилт зэрэг өгүүлэхийн
          эрхтний{" "}
        </span>
        <span id="t1e_39" class="t s1_39">
          шүтэлцээт урлаг, тэдгээрийг{" "}
        </span>
        <span id="t1f_39" class="t s1_39">
          илэрхийлэх{" "}
        </span>
        <span id="t1g_39" class="t s1_39">
          арга{" "}
        </span>
        <span id="t1h_39" class="t s1_39">
          барил{" "}
        </span>
        <span id="t1i_39" class="t s1_39">
          4.1.6. үндэсний хөгжмийн зэмсгийг урлах,хөгжимдөх, аялгуу{" "}
        </span>
        <span id="t1j_39" class="t s1_39">
          тэмдэглэх арга барил{" "}
        </span>
        <span id="t1k_39" class="t s1_39">
          4.1.7. ардын уламжлалт бүжиг, бий, биелгээ{" "}
        </span>
        <span id="t1l_39" class="t s1_39">
          4.1.8. уран нугаралт болон циркийн үзүүлбэрийг илэрхийлэх гоц{" "}
        </span>
        <span id="t1m_39" class="t s1_39">
          авъяастны ур чадварын{" "}
        </span>
        <span id="t1n_39" class="t s1_39">
          арга барил{" "}
        </span>
        <span id="t1o_39" class="t s1_39">
          4.1.9. ардын гар урлалын дэг сургууль{" "}
        </span>
        <span id="t1p_39" class="t s1_39">
          4.1.10. ардын ёс заншил, зан үйлийн уламжлал{" "}
        </span>
        <span id="t1q_39" class="t s1_39">
          4.1.11. эрдэм ухааны уламжлал{" "}
        </span>
        <span id="t1r_39" class="t s1_39">
          4.1.12. ардын бэлгэдэх ёсны уламжлал{" "}
        </span>
        <span id="t1s_39" class="t s1_39">
          Тэгэхлээр эдгээр биет бус өвийг өвлүүлэх, суратчилах, түгээн
          дэлгэрүүлэх олон талт үйл{" "}
        </span>
        <span id="t1t_39" class="t s1_39">
          ажиллагааг тухайн орон нутгийн онцлог, багшлах боловсон хүчний нөөцөд
          тулгуурлан{" "}
        </span>
        <span id="t1u_39" class="t s1_39">
          зохион байгуулах боломжтой юм.{" "}
        </span>
      </div>
    </div>
  );
}
