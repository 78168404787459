import React from "react";
;

export default function TwentyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_25{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_25{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_25{left:110px;bottom:1157px;letter-spacing:-0.1px;word-spacing:0.02px;}
#t4_25{left:110px;bottom:1121px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t5_25{left:110px;bottom:1102px;letter-spacing:-0.16px;word-spacing:-0.15px;}
#t6_25{left:705px;bottom:1102px;letter-spacing:-0.13px;}
#t7_25{left:110px;bottom:1084px;letter-spacing:-0.11px;}
#t8_25{left:220px;bottom:1084px;letter-spacing:-0.16px;word-spacing:5.19px;}
#t9_25{left:110px;bottom:1066px;letter-spacing:-0.14px;word-spacing:4.92px;}
#ta_25{left:705px;bottom:1066px;letter-spacing:-0.13px;}
#tb_25{left:110px;bottom:1048px;letter-spacing:-0.14px;}
#tc_25{left:183px;bottom:1048px;letter-spacing:-0.13px;word-spacing:0.62px;}
#td_25{left:110px;bottom:1030px;letter-spacing:-0.14px;word-spacing:0.04px;}
#te_25{left:110px;bottom:1012px;letter-spacing:-0.16px;}
#tf_25{left:243px;bottom:1012px;letter-spacing:-0.16px;word-spacing:1.18px;}
#tg_25{left:110px;bottom:994px;letter-spacing:-0.13px;word-spacing:0.04px;}
#th_25{left:110px;bottom:975px;letter-spacing:-0.15px;}
#ti_25{left:185px;bottom:975px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tj_25{left:110px;bottom:957px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tk_25{left:110px;bottom:939px;letter-spacing:-0.13px;word-spacing:-0.58px;}
#tl_25{left:182px;bottom:939px;letter-spacing:-0.12px;word-spacing:-0.59px;}
#tm_25{left:110px;bottom:921px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tn_25{left:110px;bottom:903px;letter-spacing:-0.15px;word-spacing:3.64px;}
#to_25{left:177px;bottom:903px;letter-spacing:-0.15px;word-spacing:3.66px;}
#tp_25{left:110px;bottom:885px;letter-spacing:-0.15px;word-spacing:0.75px;}
#tq_25{left:110px;bottom:867px;letter-spacing:-0.13px;word-spacing:3.29px;}
#tr_25{left:110px;bottom:848px;letter-spacing:-0.12px;word-spacing:3.28px;}
#ts_25{left:110px;bottom:830px;letter-spacing:-0.1px;}
#tt_25{left:110px;bottom:812px;letter-spacing:-0.14px;word-spacing:4.18px;}
#tu_25{left:191px;bottom:812px;letter-spacing:-0.18px;word-spacing:4.22px;}
#tv_25{left:110px;bottom:794px;letter-spacing:-0.14px;word-spacing:2.09px;}
#tw_25{left:110px;bottom:776px;letter-spacing:-0.11px;word-spacing:-0.23px;}
#tx_25{left:110px;bottom:758px;letter-spacing:-0.14px;word-spacing:-0.17px;}
#ty_25{left:110px;bottom:740px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tz_25{left:110px;bottom:722px;letter-spacing:-0.14px;word-spacing:3.27px;}
#t10_25{left:190px;bottom:722px;letter-spacing:-0.12px;word-spacing:3.26px;}
#t11_25{left:110px;bottom:703px;letter-spacing:-0.13px;word-spacing:3.01px;}
#t12_25{left:110px;bottom:685px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t13_25{left:110px;bottom:667px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t14_25{left:183px;bottom:667px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t15_25{left:110px;bottom:649px;letter-spacing:-0.13px;word-spacing:4.01px;}
#t16_25{left:110px;bottom:631px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t17_25{left:110px;bottom:613px;letter-spacing:-0.13px;word-spacing:-0.24px;}
#t18_25{left:183px;bottom:613px;letter-spacing:-0.13px;word-spacing:-0.23px;}
#t19_25{left:110px;bottom:595px;letter-spacing:-0.11px;word-spacing:-0.55px;}
#t1a_25{left:110px;bottom:576px;letter-spacing:-0.13px;word-spacing:-0.11px;}
#t1b_25{left:110px;bottom:558px;letter-spacing:-0.13px;word-spacing:4.39px;}
#t1c_25{left:110px;bottom:540px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1d_25{left:110px;bottom:522px;letter-spacing:-0.13px;word-spacing:1.41px;}
#t1e_25{left:186px;bottom:522px;letter-spacing:-0.13px;word-spacing:1.42px;}
#t1f_25{left:110px;bottom:504px;letter-spacing:-0.14px;word-spacing:1.25px;}
#t1g_25{left:110px;bottom:486px;letter-spacing:-0.09px;}
#t1h_25{left:110px;bottom:468px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1i_25{left:303px;bottom:438px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1j_25{left:542px;bottom:438px;letter-spacing:0.1px;}
#t1k_25{left:632px;bottom:438px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1l_25{left:116px;bottom:407px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1m_25{left:116px;bottom:395px;letter-spacing:0.16px;}
#t1n_25{left:116px;bottom:371px;letter-spacing:0.14px;}
#t1o_25{left:116px;bottom:359px;letter-spacing:0.16px;}
#t1p_25{left:116px;bottom:336px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1q_25{left:116px;bottom:324px;letter-spacing:0.16px;}
#t1r_25{left:110px;bottom:217px;letter-spacing:-0.13px;word-spacing:-0.06px;}
#t1s_25{left:110px;bottom:198px;letter-spacing:-0.13px;word-spacing:2.51px;}
#t1t_25{left:110px;bottom:180px;letter-spacing:-0.13px;word-spacing:1.13px;}
#t1u_25{left:110px;bottom:162px;letter-spacing:-0.14px;word-spacing:1.16px;}
#t1v_25{left:110px;bottom:144px;letter-spacing:-0.13px;word-spacing:4.44px;}
#t1w_25{left:110px;bottom:126px;letter-spacing:-0.14px;word-spacing:1.58px;}
#t1x_25{left:110px;bottom:108px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1y_25{left:144px;bottom:272px;letter-spacing:-0.15px;word-spacing:11.73px;}
#t1z_25{left:144px;bottom:254px;letter-spacing:-0.11px;word-spacing:0.01px;}

.s0_25{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_25{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_25{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_25{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s4_25{font-size:15px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts25" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg25" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="25/25.svg"
          type="image/svg+xml"
          id="pdf25"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_25" class="t s0_25">
          23{" "}
        </span>
        <span id="t2_25" class="t s1_25">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_25" class="t s2_25">
          2.2.2.Арга зүй{" "}
        </span>
        <span id="t4_25" class="t s3_25">
          Чиглүүлэгтэй уншлага – Сэтгэн бодохуйн дасгал{" "}
        </span>
        <span id="t5_25" class="t s1_25">
          Энэ нь эх зохиол уншиж ойлгоход хүүхдүүдэд их тустай арга юм. Уг арга
          нь “
        </span>
        <span id="t6_25" class="t s2_25">
          Чиглүүлэгтэй{" "}
        </span>
        <span id="t7_25" class="t s2_25">
          уншлага”-ын{" "}
        </span>
        <span id="t8_25" class="t s1_25">
          аргатай төстэй боловч чиглүүлэх асуултууд илүү чөлөөтэй байх бөгөөд{" "}
        </span>
        <span id="t9_25" class="t s1_25">
          текстийг бүрэн ойлгохыг чухалчилдаггүйгээрээ ялгаатай юм. Сурагчид “
        </span>
        <span id="ta_25" class="t s2_25">
          Чиглүүлэгтэй{" "}
        </span>
        <span id="tb_25" class="t s2_25">
          уншлага{" "}
        </span>
        <span id="tc_25" class="t s1_25">
          “-ын аргыг сайн эзэмшсэний дараа уг аргыг хэрэглэхэд илүү хялбар байх
          ба бие{" "}
        </span>
        <span id="td_25" class="t s1_25">
          даан таамаглал дэвшүүлэх боломжийг сурагчдад олгодог онцлогтой арга
          юм.{" "}
        </span>
        <span id="te_25" class="t s2_25">
          Хэрэглэгдэхүүн:{" "}
        </span>
        <span id="tf_25" class="t s1_25">
          Текстийг сурагчдын тоогоор олшруулах ба тэмдэглэл хийх цаас ба үзгээ{" "}
        </span>
        <span id="tg_25" class="t s1_25">
          сурагчид бэлтгэсэн байна.{" "}
        </span>
        <span id="th_25" class="t s2_25">
          Хугацаа:{" "}
        </span>
        <span id="ti_25" class="t s1_25">
          Дасгалыг 15-30 минутад гүйцэтгэнэ.{" "}
        </span>
        <span id="tj_25" class="t s2_25">
          Сурагчдын хийх үйл ажиллагаа{" "}
        </span>
        <span id="tk_25" class="t s2_25">
          Алхам 1:{" "}
        </span>
        <span id="tl_25" class="t s1_25">
          Текстийг дөрвөөс таван хэсэгт хувааж тэмдэглэнэ. Зогсолт хийх хэсэг нь
          текстийн{" "}
        </span>
        <span id="tm_25" class="t s1_25">
          гол утга санааг илэрхийлэх хэсгүүдтэй давхцахаар тааруулна.{" "}
        </span>
        <span id="tn_25" class="t s2_25">
          Алхам 2
        </span>
        <span id="to_25" class="t s1_25">
          : Самбарт эсвэл самбарын цаасан дээр доор өгсөн хүснэгтийг зурж
          бэлтгэнэ.{" "}
        </span>
        <span id="tp_25" class="t s1_25">
          Сурагчдад текстийг зогсолттойгоор хэсэгчлэн уншина гэдгийг тайлбарлаж
          хэлнэ.Текстийн{" "}
        </span>
        <span id="tq_25" class="t s1_25">
          дараагийн хэсгийг уншихгүй байхыг тэдэнд анхааруулна. Текстийн хэсэг
          бүрийн дараа{" "}
        </span>
        <span id="tr_25" class="t s1_25">
          цааш ямар үйл явдал өрнөх талаар сурагч бүр өөртөө таамаглал
          дэвшүүлсний дараа{" "}
        </span>
        <span id="ts_25" class="t s1_25">
          дараагийн хэсгийг уншина.{" "}
        </span>
        <span id="tt_25" class="t s2_25">
          Алхам 3:{" "}
        </span>
        <span id="tu_25" class="t s1_25">
          Сурагчид зохиолын нэрийг буюу сэдвийн гарчиг, зохиогчийн нэрийг
          уншина.{" "}
        </span>
        <span id="tv_25" class="t s1_25">
          Номын хавтасны зургийг үзэх ба номын нэрийг уншина. Үүний дараа энэ
          зохиолд юуны{" "}
        </span>
        <span id="tw_25" class="t s1_25">
          тухай өгүүлэх саналыг нь багш сурагчдаас асууна. Сурагчдын санааг
          “Юуны тухай өгүүлэх{" "}
        </span>
        <span id="tx_25" class="t s1_25">
          вэ?” хүснэгтэд багш тэмдэглэнэ. Сурагчдаас яагаад ингэж бодож байгаа
          талаар нь лавлаж{" "}
        </span>
        <span id="ty_25" class="t s1_25">
          хариултуудыг “Яагаад?” хүснэгтэд бичнэ.{" "}
        </span>
        <span id="tz_25" class="t s2_25">
          Алхам 4:{" "}
        </span>
        <span id="t10_25" class="t s1_25">
          Одоо сурагчдаас текстийн эхний хэсгийг уншихыг хүснэ. Зогсолт хийх
          хэсэгт{" "}
        </span>
        <span id="t11_25" class="t s1_25">
          хүрмэгцээ өөрсдийн дэвшүүлсэн таамаглалыг зохиолын хэсэгтэй
          харьцуулна. “Зохиолд{" "}
        </span>
        <span id="t12_25" class="t s1_25">
          өгүүлсэн зүйл?” хүснэгтэд сурагчдын хариултыг багш тэмдэглэнэ.{" "}
        </span>
        <span id="t13_25" class="t s2_25">
          Алхам 5:{" "}
        </span>
        <span id="t14_25" class="t s1_25">
          Багш сурагчдын дэвшүүлсэн таамаглалыг уншиж өгөх ба аль нь зохиолын
          үйл{" "}
        </span>
        <span id="t15_25" class="t s1_25">
          явдалтай тохирсон болохыг ярилцана. Түүнчлэн сурагчдаас таамаглалыг
          зөв болохыг{" "}
        </span>
        <span id="t16_25" class="t s1_25">
          харуулсан зохиолын хэсгийг уншиж өгөхийг хүснэ.{" "}
        </span>
        <span id="t17_25" class="t s2_25">
          Алхам 6:{" "}
        </span>
        <span id="t18_25" class="t s1_25">
          Одоо зохиолын дараагийн хэсэгт юуны тухай өгүүлэх талаар сурагчид
          таамаглал{" "}
        </span>
        <span id="t19_25" class="t s1_25">
          дэвшүүлж “Юуны тухай өгүүлэх вэ?” хүснэгтэд бичнэ. Харин “Яагаад “
          хүснэгтэд сурагчдын{" "}
        </span>
        <span id="t1a_25" class="t s1_25">
          нэрлэж буй шалтгаануудыг тэмдэглэнэ. Сурагчид текстийн дараагийн
          хэсгүүдийг чимээгүй{" "}
        </span>
        <span id="t1b_25" class="t s1_25">
          бие даан унших ба хэсэг бүрийн дараа өөрийн таамаглалаа зохиолын үйл
          явдалтай{" "}
        </span>
        <span id="t1c_25" class="t s1_25">
          харьцуулж үзнэ. Энэ мэтээр зохиолыг сурагчид бие даан уншиж дуусгана.{" "}
        </span>
        <span id="t1d_25" class="t s2_25">
          Алхам 7:{" "}
        </span>
        <span id="t1e_25" class="t s1_25">
          Төгсгөлд нь “Зохиолд өгүүлсэн зүйл?” хүснэгтэд сурагчдын хариултыг
          бичих ба{" "}
        </span>
        <span id="t1f_25" class="t s1_25">
          хичээлийн эхэнд дэвшүүлсэн таамаглалуудыг зохиолд өгүүлсэн үйл
          явдалтай харьцуулж{" "}
        </span>
        <span id="t1g_25" class="t s1_25">
          үзнэ.{" "}
        </span>
        <span id="t1h_25" class="t s1_25">
          Чиглүүлэгтэй уншлага ба сэтгэн бодохуйн дасгал арга{" "}
        </span>
        <span id="t1i_25" class="t s4_25">
          Юуны тухай өгүүлэх бол?{" "}
        </span>
        <span id="t1j_25" class="t s4_25">
          Яагаад?{" "}
        </span>
        <span id="t1k_25" class="t s4_25">
          Зохиолд өгүүлсэн нь?{" "}
        </span>
        <span id="t1l_25" class="t s4_25">
          Зохиолын нэр, гарчгийг{" "}
        </span>
        <span id="t1m_25" class="t s4_25">
          уншсаны дараа{" "}
        </span>
        <span id="t1n_25" class="t s4_25">
          Эхний хэсгийг уншсаны{" "}
        </span>
        <span id="t1o_25" class="t s4_25">
          дараа{" "}
        </span>
        <span id="t1p_25" class="t s4_25">
          Хоёр дугаар хэсгийг{" "}
        </span>
        <span id="t1q_25" class="t s4_25">
          уншсаны дараа{" "}
        </span>
        <span id="t1r_25" class="t s1_25">
          Хичээлээс гадуурх сургалтын энэхүү агуулгын хүрээнд БСШУС-ын сайдын
          2018 оны А/181{" "}
        </span>
        <span id="t1s_25" class="t s1_25">
          тоот тушаалаар баталсан “Аюулгүй амьдрах ухаан” сургалтын хөтөлбөр,
          тус оны А/467{" "}
        </span>
        <span id="t1t_25" class="t s1_25">
          тоот тушаалаар баталсан “Эрүүл мэнд” хичээлийн хөтөлбөр зэргийг
          харгалзан танхимын{" "}
        </span>
        <span id="t1u_25" class="t s1_25">
          болон танхимаас гадуур хэлбэрээр сургуулийнхаа онцлогтой уялдуулсан
          үйл ажиллагааг{" "}
        </span>
        <span id="t1v_25" class="t s1_25">
          төлөвлөн хэрэгжүүлж болно. “Эрүүл мэнд” хичээлийн хөтөлбөрийг
          хэрэгжүүлэх албан{" "}
        </span>
        <span id="t1w_25" class="t s1_25">
          ёсны цаг сургалтын төлөвлөгөөнд тусгагдсан байдаг учир тухайн
          хөтөлбөрийн агуулгаар{" "}
        </span>
        <span id="t1x_25" class="t s1_25">
          эзэмшсэн мэдлэгээ амьдралд хэрэгжүүлэхэд чиглэсэн үйл ажиллагааг
          танхимын бус{" "}
        </span>
        <span id="t1y_25" class="t s1_25">
          2.3. Хүүхдийн эрүүл мэнд, аюулгүй амьдрах ухааны боловсрол олгох{" "}
        </span>
        <span id="t1z_25" class="t s1_25">
          хөтөлбөрүүдийг хэрэгжүүлэх{" "}
        </span>
      </div>
    </div>
  );
}
