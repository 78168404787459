import React from "react";
;

export default function FourTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_14{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_14{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_14{left:118px;bottom:1132px;letter-spacing:0.16px;word-spacing:0.06px;}
#t4_14{left:345px;bottom:1112px;letter-spacing:0.15px;word-spacing:0.08px;}
#t5_14{left:110px;bottom:1064px;letter-spacing:-0.15px;word-spacing:5.72px;}
#t6_14{left:110px;bottom:1046px;letter-spacing:-0.13px;word-spacing:1.56px;}
#t7_14{left:110px;bottom:1028px;letter-spacing:-0.15px;word-spacing:0.64px;}
#t8_14{left:110px;bottom:1010px;letter-spacing:-0.17px;word-spacing:0.33px;}
#t9_14{left:110px;bottom:992px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ta_14{left:110px;bottom:967px;letter-spacing:-0.13px;word-spacing:0.96px;}
#tb_14{left:110px;bottom:949px;letter-spacing:-0.14px;word-spacing:5.42px;}
#tc_14{left:110px;bottom:931px;letter-spacing:-0.15px;word-spacing:1.66px;}
#td_14{left:110px;bottom:913px;letter-spacing:-0.14px;word-spacing:0.05px;}
#te_14{left:110px;bottom:889px;letter-spacing:-0.13px;word-spacing:2.89px;}
#tf_14{left:110px;bottom:871px;letter-spacing:-0.07px;word-spacing:-0.02px;}
#tg_14{left:142px;bottom:846px;}
#th_14{left:170px;bottom:846px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ti_14{left:142px;bottom:828px;}
#tj_14{left:170px;bottom:828px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tk_14{left:170px;bottom:810px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_14{left:171px;bottom:792px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tm_14{left:110px;bottom:768px;letter-spacing:-0.13px;word-spacing:6.25px;}
#tn_14{left:110px;bottom:750px;letter-spacing:-0.13px;word-spacing:3.54px;}
#to_14{left:110px;bottom:731px;letter-spacing:-0.13px;word-spacing:3px;}
#tp_14{left:110px;bottom:713px;letter-spacing:-0.15px;word-spacing:2.85px;}
#tq_14{left:110px;bottom:695px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tr_14{left:165px;bottom:647px;letter-spacing:-0.11px;word-spacing:-0.27px;}
#ts_14{left:150px;bottom:617px;letter-spacing:0.11px;word-spacing:0.04px;}
#tt_14{left:418px;bottom:617px;letter-spacing:0.05px;word-spacing:0.09px;}
#tu_14{left:627px;bottom:617px;letter-spacing:0.17px;}
#tv_14{left:116px;bottom:594px;letter-spacing:0.13px;word-spacing:0.01px;}
#tw_14{left:116px;bottom:582px;letter-spacing:0.05px;word-spacing:-0.2px;}
#tx_14{left:116px;bottom:570px;letter-spacing:0.13px;word-spacing:0.01px;}
#ty_14{left:116px;bottom:557px;letter-spacing:0.03px;word-spacing:0.11px;}
#tz_14{left:356px;bottom:594px;letter-spacing:0.1px;word-spacing:0.04px;}
#t10_14{left:356px;bottom:582px;letter-spacing:0.08px;word-spacing:-2px;}
#t11_14{left:606px;bottom:594px;letter-spacing:0.07px;word-spacing:0.05px;}
#t12_14{left:606px;bottom:582px;letter-spacing:0.12px;word-spacing:0.02px;}
#t13_14{left:606px;bottom:570px;letter-spacing:-0.23px;}
#t14_14{left:116px;bottom:527px;letter-spacing:0.13px;word-spacing:0.01px;}
#t15_14{left:116px;bottom:515px;letter-spacing:0.14px;}
#t16_14{left:116px;bottom:503px;letter-spacing:0.12px;word-spacing:0.01px;}
#t17_14{left:116px;bottom:491px;letter-spacing:0.09px;word-spacing:0.06px;}
#t18_14{left:356px;bottom:527px;letter-spacing:0.1px;word-spacing:0.04px;}
#t19_14{left:356px;bottom:515px;letter-spacing:0.06px;word-spacing:0.08px;}
#t1a_14{left:356px;bottom:503px;letter-spacing:0.12px;word-spacing:0.01px;}
#t1b_14{left:356px;bottom:491px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1c_14{left:606px;bottom:527px;letter-spacing:0.01px;word-spacing:0.12px;}
#t1d_14{left:606px;bottom:515px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1e_14{left:606px;bottom:503px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1f_14{left:606px;bottom:491px;letter-spacing:0.07px;}
#t1g_14{left:116px;bottom:467px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1h_14{left:116px;bottom:455px;letter-spacing:0.14px;}
#t1i_14{left:116px;bottom:443px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1j_14{left:356px;bottom:467px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1k_14{left:356px;bottom:455px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1l_14{left:356px;bottom:443px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1m_14{left:356px;bottom:430px;letter-spacing:0.06px;word-spacing:0.07px;}
#t1n_14{left:606px;bottom:467px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1o_14{left:606px;bottom:455px;letter-spacing:0.14px;}
#t1p_14{left:606px;bottom:443px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1q_14{left:606px;bottom:430px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1r_14{left:116px;bottom:396px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1s_14{left:116px;bottom:383px;letter-spacing:0.11px;word-spacing:0.04px;}
#t1t_14{left:116px;bottom:371px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1u_14{left:116px;bottom:359px;letter-spacing:0.13px;}
#t1v_14{left:116px;bottom:347px;letter-spacing:0.13px;}
#t1w_14{left:116px;bottom:335px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1x_14{left:116px;bottom:322px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1y_14{left:116px;bottom:310px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1z_14{left:356px;bottom:396px;letter-spacing:0.01px;word-spacing:0.12px;}
#t20_14{left:356px;bottom:383px;letter-spacing:0.12px;word-spacing:0.02px;}
#t21_14{left:356px;bottom:371px;letter-spacing:0.03px;word-spacing:0.1px;}
#t22_14{left:356px;bottom:359px;letter-spacing:0.08px;word-spacing:0.05px;}
#t23_14{left:356px;bottom:347px;letter-spacing:0.1px;word-spacing:0.05px;}
#t24_14{left:606px;bottom:396px;letter-spacing:0.13px;}
#t25_14{left:606px;bottom:383px;letter-spacing:0.09px;word-spacing:0.04px;}
#t26_14{left:606px;bottom:371px;letter-spacing:0.1px;word-spacing:0.03px;}
#t27_14{left:116px;bottom:287px;letter-spacing:0.14px;}
#t28_14{left:116px;bottom:274px;letter-spacing:0.11px;word-spacing:0.02px;}
#t29_14{left:356px;bottom:287px;letter-spacing:0.13px;word-spacing:0.01px;}
#t2a_14{left:356px;bottom:274px;letter-spacing:0.02px;word-spacing:0.1px;}
#t2b_14{left:356px;bottom:262px;letter-spacing:0.07px;word-spacing:0.06px;}
#t2c_14{left:356px;bottom:250px;letter-spacing:0.1px;word-spacing:0.04px;}
#t2d_14{left:356px;bottom:238px;letter-spacing:0.13px;word-spacing:0.01px;}
#t2e_14{left:356px;bottom:226px;letter-spacing:0.06px;word-spacing:0.07px;}
#t2f_14{left:606px;bottom:287px;letter-spacing:0.14px;}
#t2g_14{left:606px;bottom:274px;letter-spacing:0.07px;}

.s0_14{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_14{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_14{font-size:18px;font-family:Arial-BoldMT_67;color:#231F20;}
.s3_14{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s4_14{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s5_14{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s6_14{font-size:15px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts14" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg14" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="14/14.svg"
          type="image/svg+xml"
          id="pdf14"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_14" class="t s0_14">
          12{" "}
        </span>
        <span id="t2_14" class="t s1_14">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_14" class="t s2_14">
          ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫН ҮЛГЭРЧИЛСЭН ХӨТӨЛБӨРИЙГ{" "}
        </span>
        <span id="t4_14" class="t s2_14">
          ХЭРЭГЖҮҮЛЭХ ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t5_14" class="t s1_14">
          Энэхүү зөвлөмж сурагчдын дунд зохион байгуулагдах үйл ажиллагааг
          өөрсдийнх нь{" "}
        </span>
        <span id="t6_14" class="t s1_14">
          оролцоонд тулгуурлан нас сэтгэхүйн, хөгжил төлөвшил, хэрэгцээ
          шаардлагад нийцүүлэн{" "}
        </span>
        <span id="t7_14" class="t s1_14">
          хэрхэн зохион байгуулж, чиглүүлж болох талаар санаа өгөх, ерөнхий
          чиглүүлэг удирдамж{" "}
        </span>
        <span id="t8_14" class="t s1_14">
          болгох зорилготой тул өөрийн орон нутаг, сургууль, сурагчдын нөхцөл
          байдал, хувь шинж,{" "}
        </span>
        <span id="t9_14" class="t s1_14">
          онцлог сонирхолтой уялдуулан бүтээлчээр ашиглана гэдэгт найдаж байна.{" "}
        </span>
        <span id="ta_14" class="t s1_14">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөрийг
          хэрэгжүүлснээр сурагчдад{" "}
        </span>
        <span id="tb_14" class="t s1_14">
          өөрийгөө удирдан хөгжүүлэх, манлайлах, харилцах, сурах арга барилыг
          сайжруулах,{" "}
        </span>
        <span id="tc_14" class="t s1_14">
          тодорхой асуудлыг шийдвэрлэх, нийгмийн олон талт харилцаанд оролцох,
          түүнд өөрийн{" "}
        </span>
        <span id="td_14" class="t s1_14">
          байр сууриа илэрхийлэх, бие хүний чадварууд хөгжих боломжтой болно.{" "}
        </span>
        <span id="te_14" class="t s1_14">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөрийг хэрэгжүүлэх
          зөвлөмж нь{" "}
        </span>
        <span id="tf_14" class="t s1_14">
          дараах 2 үндсэн хэсэгтэй.{" "}
        </span>
        <span id="tg_14" class="t s3_14">
          •{" "}
        </span>
        <span id="th_14" class="t s1_14">
          Сурагчдын бие бялдар, танин мэдэхүй, нийгэмшлийн ерөнхий онцлог{" "}
        </span>
        <span id="ti_14" class="t s3_14">
          •{" "}
        </span>
        <span id="tj_14" class="t s1_14">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөрийн зорилтыг{" "}
        </span>
        <span id="tk_14" class="t s1_14">
          хэрэгжүүлэх зөвлөмж{" "}
        </span>
        <span id="tl_14" class="t s4_14">
          6-17 насны сурагчдын танин мэдэхүй, нийгэмшлийн хөгжлийн онцлог{" "}
        </span>
        <span id="tm_14" class="t s1_14">
          2014-2017 онд Боловсролын хүрээлэнгээс Монголын 6-17 насны хүүхдүүдийн
          танин{" "}
        </span>
        <span id="tn_14" class="t s1_14">
          мэдэхүй, нийгэмшил, бие бялдрын хөгжлийн онцлогийг цогц байдлаар
          судлан тогтоож{" "}
        </span>
        <span id="to_14" class="t s1_14">
          түүнд нөлөөлж буй хүчин зүйлийг авч үзжээ. Судалгааны үр дүнг 6-17 нас
          тус бүрээр,{" "}
        </span>
        <span id="tp_14" class="t s1_14">
          бага боловсрол, суурь боловсрол, бүрэн дунд боловсролын түвшин тус
          бүрээр дундаж{" "}
        </span>
        <span id="tq_14" class="t s1_14">
          үзүүлэлтүүдийг гаргасан.{" "}
        </span>
        <span id="tr_14" class="t s5_14">
          6-17 насны хүүхдүүдийн бие бялдар, танин мэдэхүй, нийгэмшлийн ерөнхий
          онцлог{" "}
        </span>
        <span id="ts_14" class="t s6_14">
          Бие бялдар{" "}
        </span>
        <span id="tt_14" class="t s6_14">
          Танин мэдэхүй{" "}
        </span>
        <span id="tu_14" class="t s6_14">
          Нийгэмшил{" "}
        </span>
        <span id="tv_14" class="t s6_14">
          10-12 насанд охидын биеийн{" "}
        </span>
        <span id="tw_14" class="t s6_14">
          өндрийн үзүүлэлт, 14 наснаас{" "}
        </span>
        <span id="tx_14" class="t s6_14">
          эхлэн хөвгүүдийн биеийн{" "}
        </span>
        <span id="ty_14" class="t s6_14">
          өндрийн үзүүлэлт нэмэгддэг.{" "}
        </span>
        <span id="tz_14" class="t s6_14">
          Мэдээллийг хүртэхдээ{" "}
        </span>
        <span id="t10_14" class="t s6_14">
          ерөнхийд нь хүртэх хандлагатай.{" "}
        </span>
        <span id="t11_14" class="t s6_14">
          Өөртөө итгэх итгэл,{" "}
        </span>
        <span id="t12_14" class="t s6_14">
          өөрийгөө үнэлэх нь эерэг{" "}
        </span>
        <span id="t13_14" class="t s6_14">
          болдог.{" "}
        </span>
        <span id="t14_14" class="t s6_14">
          Охидын биеийн өндөр 14{" "}
        </span>
        <span id="t15_14" class="t s6_14">
          наснаас, хөвгүүдийн биеийн{" "}
        </span>
        <span id="t16_14" class="t s6_14">
          өндрийн үзүүлэлт 16 наснаас{" "}
        </span>
        <span id="t17_14" class="t s6_14">
          удааширсан байна.{" "}
        </span>
        <span id="t18_14" class="t s6_14">
          Юмсыг гадаад байдлаар нь{" "}
        </span>
        <span id="t19_14" class="t s6_14">
          харьцуулах чадвартай, дотоод{" "}
        </span>
        <span id="t1a_14" class="t s6_14">
          мөн чанар, учир шалтгаанд нь{" "}
        </span>
        <span id="t1b_14" class="t s6_14">
          харьцуулах нь сул байна.{" "}
        </span>
        <span id="t1c_14" class="t s6_14">
          Нийцтэй, нөхөрсөг,{" "}
        </span>
        <span id="t1d_14" class="t s6_14">
          бусадтай харилцах,{" "}
        </span>
        <span id="t1e_14" class="t s6_14">
          хамтрах сонирхол сайтай{" "}
        </span>
        <span id="t1f_14" class="t s6_14">
          болно.{" "}
        </span>
        <span id="t1g_14" class="t s6_14">
          Охидын биеийн жин 11-14{" "}
        </span>
        <span id="t1h_14" class="t s6_14">
          насанд, хөвгүүдийн биеийн{" "}
        </span>
        <span id="t1i_14" class="t s6_14">
          жин 13-15 насанд нэмэгдсэн.{" "}
        </span>
        <span id="t1j_14" class="t s6_14">
          Нарийн мэдээлэл агуулсан{" "}
        </span>
        <span id="t1k_14" class="t s6_14">
          байнга хэрэглэдэггүй үг{" "}
        </span>
        <span id="t1l_14" class="t s6_14">
          хэллэгтэй мэдээллийг утгачлан{" "}
        </span>
        <span id="t1m_14" class="t s6_14">
          тогтоох нь сул байна.{" "}
        </span>
        <span id="t1n_14" class="t s6_14">
          Өсвөр насанд бусдыг{" "}
        </span>
        <span id="t1o_14" class="t s6_14">
          хүлээн зөвшөөрөх нь{" "}
        </span>
        <span id="t1p_14" class="t s6_14">
          буурч, шүүмжлэлтэй{" "}
        </span>
        <span id="t1q_14" class="t s6_14">
          хандлага бий болно.{" "}
        </span>
        <span id="t1r_14" class="t s6_14">
          12 наснаас эхлэн суурь{" "}
        </span>
        <span id="t1s_14" class="t s6_14">
          боловсролын түвшинд{" "}
        </span>
        <span id="t1t_14" class="t s6_14">
          хүүхдүүдийн хурдны чанар{" "}
        </span>
        <span id="t1u_14" class="t s6_14">
          саарсан{" "}
        </span>
        <span id="t1v_14" class="t s6_14">
          15-17 насанд{" "}
        </span>
        <span id="t1w_14" class="t s6_14">
          хөвгүүдийн хурд нэмэгдсэн.{" "}
        </span>
        <span id="t1x_14" class="t s6_14">
          Нийт насанд хөвгүүдийн хурд{" "}
        </span>
        <span id="t1y_14" class="t s6_14">
          илүү байна.{" "}
        </span>
        <span id="t1z_14" class="t s6_14">
          Өдөр тутам байнга хэрэглэдэг,{" "}
        </span>
        <span id="t20_14" class="t s6_14">
          бодит юмсыг заасан үгийн нөөц{" "}
        </span>
        <span id="t21_14" class="t s6_14">
          давамгайлдаг. Тэмдэг нэр,{" "}
        </span>
        <span id="t22_14" class="t s6_14">
          салаа утгатай үгийн нөөц сул{" "}
        </span>
        <span id="t23_14" class="t s6_14">
          болсон байна.{" "}
        </span>
        <span id="t24_14" class="t s6_14">
          Өсвөр насанд эцэг{" "}
        </span>
        <span id="t25_14" class="t s6_14">
          эхтэйгээ дотно харилцах{" "}
        </span>
        <span id="t26_14" class="t s6_14">
          нь суларсан.{" "}
        </span>
        <span id="t27_14" class="t s6_14">
          Хөвгүүдийн хүчний чанар{" "}
        </span>
        <span id="t28_14" class="t s6_14">
          анги ахих тусам нэмэгдсэн.{" "}
        </span>
        <span id="t29_14" class="t s6_14">
          Энгийн богино өгүүлбэрээр{" "}
        </span>
        <span id="t2a_14" class="t s6_14">
          ярьдаг. “бөгөөд, гэвч, учраас,{" "}
        </span>
        <span id="t2b_14" class="t s6_14">
          боловч, миний бодлоор” гэх мэт{" "}
        </span>
        <span id="t2c_14" class="t s6_14">
          холбоос хэрэглэсэн нийлмэл{" "}
        </span>
        <span id="t2d_14" class="t s6_14">
          өгүүлбэрийг нийт хүүхдүүд{" "}
        </span>
        <span id="t2e_14" class="t s6_14">
          хэрэглэх нь маш бага байна.{" "}
        </span>
        <span id="t2f_14" class="t s6_14">
          Өсвөр насанд айдас{" "}
        </span>
        <span id="t2g_14" class="t s6_14">
          нэмэгдсэн.{" "}
        </span>
      </div>
    </div>
  );
}
