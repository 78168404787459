import React from "react";
;

export default function ThirtyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p32" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_32{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_32{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_32{left:110px;bottom:1157px;letter-spacing:-0.17px;}
#t4_32{left:110px;bottom:1139px;letter-spacing:-0.15px;word-spacing:3.36px;}
#t5_32{left:508px;bottom:1139px;letter-spacing:-0.13px;word-spacing:3.34px;}
#t6_32{left:110px;bottom:1121px;letter-spacing:-0.12px;word-spacing:-0.31px;}
#t7_32{left:110px;bottom:1102px;letter-spacing:-0.13px;word-spacing:1.83px;}
#t8_32{left:110px;bottom:1084px;letter-spacing:-0.39px;}
#t9_32{left:110px;bottom:1066px;letter-spacing:-0.11px;word-spacing:3.65px;}
#ta_32{left:390px;bottom:1066px;letter-spacing:-0.1px;word-spacing:3.65px;}
#tb_32{left:603px;bottom:1066px;letter-spacing:-0.1px;word-spacing:3.64px;}
#tc_32{left:110px;bottom:1048px;letter-spacing:-0.12px;}
#td_32{left:175px;bottom:1048px;letter-spacing:-0.14px;word-spacing:0.14px;}
#te_32{left:110px;bottom:1030px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tf_32{left:110px;bottom:1012px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_32{left:312px;bottom:1012px;letter-spacing:-0.11px;word-spacing:0.02px;}
#th_32{left:110px;bottom:994px;letter-spacing:-0.16px;word-spacing:-0.78px;}
#ti_32{left:110px;bottom:975px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tj_32{left:618px;bottom:975px;}
#tk_32{left:110px;bottom:957px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tl_32{left:320px;bottom:957px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tm_32{left:110px;bottom:939px;letter-spacing:-0.17px;word-spacing:2.26px;}
#tn_32{left:364px;bottom:939px;letter-spacing:-0.17px;word-spacing:2.27px;}
#to_32{left:110px;bottom:921px;letter-spacing:-0.17px;word-spacing:4.33px;}
#tp_32{left:110px;bottom:903px;letter-spacing:-0.22px;word-spacing:0.13px;}
#tq_32{left:110px;bottom:885px;letter-spacing:-0.15px;word-spacing:-0.69px;}
#tr_32{left:662px;bottom:885px;letter-spacing:-0.11px;word-spacing:-0.71px;}
#ts_32{left:110px;bottom:867px;letter-spacing:-0.12px;word-spacing:-0.07px;}
#tt_32{left:110px;bottom:848px;letter-spacing:-0.13px;word-spacing:0.95px;}
#tu_32{left:110px;bottom:830px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tv_32{left:110px;bottom:812px;letter-spacing:-0.16px;word-spacing:-1.46px;}
#tw_32{left:578px;bottom:812px;letter-spacing:-0.09px;word-spacing:-1.53px;}
#tx_32{left:110px;bottom:794px;letter-spacing:-0.16px;word-spacing:-0.63px;}
#ty_32{left:110px;bottom:776px;letter-spacing:-0.13px;word-spacing:-0.39px;}
#tz_32{left:110px;bottom:758px;letter-spacing:-0.23px;word-spacing:0.14px;}
#t10_32{left:110px;bottom:740px;letter-spacing:-0.14px;word-spacing:0.64px;}
#t11_32{left:110px;bottom:722px;letter-spacing:-0.14px;word-spacing:-0.17px;}
#t12_32{left:110px;bottom:703px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t13_32{left:110px;bottom:685px;letter-spacing:-0.17px;word-spacing:0.37px;}
#t14_32{left:110px;bottom:667px;letter-spacing:-0.11px;word-spacing:-0.26px;}
#t15_32{left:110px;bottom:649px;letter-spacing:-0.19px;word-spacing:1.49px;}
#t16_32{left:110px;bottom:631px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t17_32{left:110px;bottom:522px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t18_32{left:153px;bottom:498px;}
#t19_32{left:180px;bottom:498px;letter-spacing:-0.15px;word-spacing:7.12px;}
#t1a_32{left:180px;bottom:480px;letter-spacing:-0.17px;}
#t1b_32{left:153px;bottom:462px;}
#t1c_32{left:180px;bottom:462px;letter-spacing:-0.13px;word-spacing:4.92px;}
#t1d_32{left:180px;bottom:443px;letter-spacing:-0.15px;word-spacing:0.4px;}
#t1e_32{left:180px;bottom:425px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1f_32{left:153px;bottom:407px;}
#t1g_32{left:180px;bottom:407px;letter-spacing:-0.15px;word-spacing:3.01px;}
#t1h_32{left:180px;bottom:389px;letter-spacing:-0.12px;word-spacing:5.99px;}
#t1i_32{left:180px;bottom:371px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1j_32{left:153px;bottom:353px;}
#t1k_32{left:180px;bottom:353px;letter-spacing:-0.14px;word-spacing:0.52px;}
#t1l_32{left:180px;bottom:335px;letter-spacing:-0.13px;word-spacing:4.02px;}
#t1m_32{left:180px;bottom:316px;letter-spacing:-0.16px;word-spacing:1.83px;}
#t1n_32{left:180px;bottom:298px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1o_32{left:153px;bottom:280px;}
#t1p_32{left:180px;bottom:280px;letter-spacing:-0.14px;word-spacing:0.9px;}
#t1q_32{left:180px;bottom:262px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1r_32{left:153px;bottom:244px;}
#t1s_32{left:180px;bottom:244px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#t1t_32{left:180px;bottom:226px;letter-spacing:-0.16px;word-spacing:0.54px;}
#t1u_32{left:180px;bottom:208px;letter-spacing:-0.12px;}
#t1v_32{left:136px;bottom:589px;letter-spacing:0.1px;word-spacing:2.63px;}
#t1w_32{left:136px;bottom:572px;letter-spacing:0.09px;word-spacing:0.05px;}

.s0_32{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_32{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_32{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_32{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_32{font-size:15px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts32" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg32" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="32/32.svg"
          type="image/svg+xml"
          id="pdf32"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_32" class="t s0_32">
          30{" "}
        </span>
        <span id="t2_32" class="t s1_32">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_32" class="t s2_32">
          Хэрэглэгдэхүүн:{" "}
        </span>
        <span id="t4_32" class="t s2_32">
          “Хүн болох багаасаа, хүлэг болох унаганаасаа”{" "}
        </span>
        <span id="t5_32" class="t s1_32">
          гэдэг үгийн цаана хүүхэд нас бол хүн{" "}
        </span>
        <span id="t6_32" class="t s1_32">
          болохын гол түлхүүр мөн. Мөн хүүхдийн төлөө эрт анхаар, түүнд
          анхаарал, хөрөнгө хүчийг{" "}
        </span>
        <span id="t7_32" class="t s1_32">
          төвлөрүүлэх нь чухал, эс тэгвэл хүн болох, хүн байх явдал алдагдалд
          орно гэсэн санаа{" "}
        </span>
        <span id="t8_32" class="t s1_32">
          агуулдаг.{" "}
        </span>
        <span id="t9_32" class="t s2_32">
          “Өөрөө унасан хүүхэд уйлдаггүй”{" "}
        </span>
        <span id="ta_32" class="t s1_32">
          гэсэн үгийн цаана, эсвэл{" "}
        </span>
        <span id="tb_32" class="t s2_32">
          “Гэм нь урдаа, гэмшил нь{" "}
        </span>
        <span id="tc_32" class="t s2_32">
          хойноо”{" "}
        </span>
        <span id="td_32" class="t s1_32">
          гэдэг үгийн цаана өөрийг нь урдаар мэд, тань, зөв бурууг эрт ялга,
          мэд, эс тэгвээс{" "}
        </span>
        <span id="te_32" class="t s1_32">
          өөрөө л хохирно гэсэн утга оршдог.{" "}
        </span>
        <span id="tf_32" class="t s2_32">
          “Хүн нэрээ, тогос өөдөө”{" "}
        </span>
        <span id="tg_32" class="t s1_32">
          гэдэг үгийн цаана нэр, төр шиг хүндтэй, юугаар ч сольж болшгүй{" "}
        </span>
        <span id="th_32" class="t s1_32">
          ариун нандин чанар, хадгалж, хайрлаж явах үнэт зүйл байхгүйг, бусдыг ч
          энэ үүднээс үнэлж{" "}
        </span>
        <span id="ti_32" class="t s1_32">
          хүн чанар гаргаж явахыг сануулсан эрхэм сургаал санаа илэрдэг
        </span>
        <span id="tj_32" class="t s3_32">
          .{" "}
        </span>
        <span id="tk_32" class="t s2_32">
          “Нэр хугарахаар яс хугар”{" "}
        </span>
        <span id="tl_32" class="t s1_32">
          гэсэн өөр үг ч дээрх санааг бас өгүүлдэг.{" "}
        </span>
        <span id="tm_32" class="t s2_32">
          ”Эрхийг сурахаар бэрхийг сур”{" "}
        </span>
        <span id="tn_32" class="t s1_32">
          гэсэн үг бол бэлэн бүхэн хүнийг эвддэг, бэрхшээл бүхэн{" "}
        </span>
        <span id="to_32" class="t s1_32">
          нэгийг сургадаг учир мэдэхгүйг мэддэг, чадахгүйг чаддаг болгодог жам
          хуулийг танин{" "}
        </span>
        <span id="tp_32" class="t s1_32">
          мэдэхийн чухлыг зөвлөдөг.{" "}
        </span>
        <span id="tq_32" class="t s2_32">
          “Ажил хийвэл ам тосдоно. Ажил хийвэл дуустал, давс хийвэл уустал”{" "}
        </span>
        <span id="tr_32" class="t s1_32">
          гэсэн сургамж үгийн{" "}
        </span>
        <span id="ts_32" class="t s1_32">
          утгыг хөдөлмөр л хүнийг хүн болгоно, ажиллаж байж л амьдрал залгана,
          юмыг хийсэн шиг{" "}
        </span>
        <span id="tt_32" class="t s1_32">
          хийж бай, замын хагаст зогсож болохгүй, үл хөдөлбөл, залхууран хойш
          суувал хүн өөрөө{" "}
        </span>
        <span id="tu_32" class="t s1_32">
          хохирно гэсэн санаагаар тайлбарлаж болно.{" "}
        </span>
        <span id="tv_32" class="t s2_32">
          “Сайныг дагавал сарны гэрэл, мууг дагавал могойн хорлол”{" "}
        </span>
        <span id="tw_32" class="t s1_32">
          гэсэн үг бий. Үлгэр дуурайллын{" "}
        </span>
        <span id="tx_32" class="t s1_32">
          гүн утга санаа, ач холбогдлыг тодорхойлжээ. Өөрөөр хэлбэл сонголтоо
          зөв хий гэсэн үг юм.{" "}
        </span>
        <span id="ty_32" class="t s1_32">
          Анхааруулгын шинжтэй үгийг шог хошин, ёгт байдлаар илэрхийлэх нь
          сурагчдад мартахын{" "}
        </span>
        <span id="tz_32" class="t s1_32">
          аргагүй үлдсэн байдаг.{" "}
        </span>
        <span id="t10_32" class="t s1_32">
          Энэ мэтээр амьдралын түг түмэн үзэгдлийн үүд хаалга бүхэнд нэвтрэн орж
          удирдамж чиг{" "}
        </span>
        <span id="t11_32" class="t s1_32">
          болдоггүй олон сонгомол үг байдгийг оновчтой олж ажил, амьдралаа
          хөтлөх нь хүн бүхэнд{" "}
        </span>
        <span id="t12_32" class="t s1_32">
          байгууштай шинж, зөв мэргэн залуур болно.{" "}
        </span>
        <span id="t13_32" class="t s1_32">
          Гэр орон тохьтой, нэр бүтэн, үг хэл буурьтай, үзэл зөв, үйл бүтээл
          өгөөжтэй, мэлмий хурц,{" "}
        </span>
        <span id="t14_32" class="t s1_32">
          мэргэжил сайн, чадвар өндөр, сэтгэл цагаан, нэгэндээ ээлтэй, нөгөөд
          тустай, зөвд дэмтэй,{" "}
        </span>
        <span id="t15_32" class="t s1_32">
          бурууд хатуу, бүгдээр эвтэй л байвал болох нь тэр буюу гэсэн Монгол
          ардын уламжлалт{" "}
        </span>
        <span id="t16_32" class="t s1_32">
          зүйр цэцэн үгсийг байнга санаж явахыг хүүхдүүдэд ойлгуулах нь чухал
          юм.{" "}
        </span>
        <span id="t17_32" class="t s2_32">
          3.3.1.Үйл ажиллагаа:{" "}
        </span>
        <span id="t18_32" class="t s1_32">
          -{" "}
        </span>
        <span id="t19_32" class="t s1_32">
          Сургууль түүний орчныг бүрэн камержуулах, камерийн хяналт, ашиглалтыг{" "}
        </span>
        <span id="t1a_32" class="t s1_32">
          сайжруулах{" "}
        </span>
        <span id="t1b_32" class="t s1_32">
          -{" "}
        </span>
        <span id="t1c_32" class="t s1_32">
          Анхаарал татсан хүүхдүүдэд хяналт тавьж, эцэг эх харьяа нутаг
          дэвсгэрийн{" "}
        </span>
        <span id="t1d_32" class="t s1_32">
          цагдаагийн байгууллага, хэсгийн байцаагчид нэн даруй мэдэгдэх /102,108
          дугаар{" "}
        </span>
        <span id="t1e_32" class="t s1_32">
          утсаар, бичгээр/{" "}
        </span>
        <span id="t1f_32" class="t s1_32">
          -{" "}
        </span>
        <span id="t1g_32" class="t s1_32">
          Сургуулийн удирдлагаас эрдмийн баярыг тэмдэглэх, хөтөлбөрийн
          хэрэгжилтэд{" "}
        </span>
        <span id="t1h_32" class="t s1_32">
          хяналт тавих, гэмт хэрэг зөрчлөөс урьдчилан сэргийлэх талаар эцэг
          эхийн{" "}
        </span>
        <span id="t1i_32" class="t s1_32">
          туслалцааг авч тодорхой шинэлэг ажлуудыг эхлүүлэх{" "}
        </span>
        <span id="t1j_32" class="t s1_32">
          -{" "}
        </span>
        <span id="t1k_32" class="t s1_32">
          Цаг үеийн нөхцөл байдалтай холбогдуулан хүүхдийг гэмт халдлагаас
          хамгаалах,{" "}
        </span>
        <span id="t1l_32" class="t s1_32">
          шалтгаан нөхцөлийг арилгах, урьдчилан сэргийлэх, төрийн болон төрийн
          бус{" "}
        </span>
        <span id="t1m_32" class="t s1_32">
          байгууллага, эцэг эх иргэдийн хамтын ажиллагааг сайжруулах зорилгоор
          улсын{" "}
        </span>
        <span id="t1n_32" class="t s1_32">
          хэмжээнд хийгдэж буй үйл ажиллагаанд суралцагчдыг хамруулах{" "}
        </span>
        <span id="t1o_32" class="t s1_32">
          -{" "}
        </span>
        <span id="t1p_32" class="t s1_32">
          Ерөнхий боловсролын сургуулийн нийгмийн ажилтан, багш нартай оройн
          цагаар{" "}
        </span>
        <span id="t1q_32" class="t s1_32">
          компьютер тоглоомын газруудад хяналт шалгалтыг зохион байгуулах,{" "}
        </span>
        <span id="t1r_32" class="t s1_32">
          -{" "}
        </span>
        <span id="t1s_32" class="t s1_32">
          Компьютер тоглоомын газруудыг ажиллуулж байгаа иргэд аж ахуйн нэгжид
          оройн{" "}
        </span>
        <span id="t1t_32" class="t s1_32">
          цагаар бага насны болон ЕБС-ийн сурагчдыг тоглуулахгүй байх талаар
          мэдэгдэл{" "}
        </span>
        <span id="t1u_32" class="t s1_32">
          хүргүүлэх{" "}
        </span>
        <span id="t1v_32" class="t s4_32">
          3.3. Өсвөр насныхны дундах дарамт дээрэлхэлтээс урьдчилан сэргийлэх
          сургалт, орчин{" "}
        </span>
        <span id="t1w_32" class="t s4_32">
          нөхцөл бүрдүүлэх, зохион байгуулах{" "}
        </span>
      </div>
    </div>
  );
}
