import React from "react";
;

export default function ThirtyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p33" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_33{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_33{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_33{left:110px;bottom:1157px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t4_33{left:110px;bottom:1139px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t5_33{left:110px;bottom:1121px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t6_33{left:226px;bottom:1121px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t7_33{left:110px;bottom:1102px;letter-spacing:-0.13px;}
#t8_33{left:185px;bottom:1102px;letter-spacing:-0.15px;word-spacing:0.39px;}
#t9_33{left:110px;bottom:1084px;letter-spacing:-0.12px;word-spacing:-0.83px;}
#ta_33{left:110px;bottom:1066px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tb_33{left:110px;bottom:1048px;letter-spacing:-0.12px;}
#tc_33{left:153px;bottom:1024px;}
#td_33{left:180px;bottom:1024px;letter-spacing:-0.14px;word-spacing:1.24px;}
#te_33{left:180px;bottom:1006px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tf_33{left:153px;bottom:987px;}
#tg_33{left:180px;bottom:987px;letter-spacing:-0.15px;word-spacing:0.91px;}
#th_33{left:180px;bottom:969px;letter-spacing:-0.18px;word-spacing:0.08px;}
#ti_33{left:153px;bottom:951px;}
#tj_33{left:180px;bottom:951px;letter-spacing:-0.15px;word-spacing:4.61px;}
#tk_33{left:180px;bottom:933px;letter-spacing:-0.14px;word-spacing:0.53px;}
#tl_33{left:180px;bottom:915px;letter-spacing:-0.22px;}
#tm_33{left:153px;bottom:897px;}
#tn_33{left:180px;bottom:897px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_33{left:110px;bottom:873px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tp_33{left:153px;bottom:848px;}
#tq_33{left:180px;bottom:848px;letter-spacing:-0.13px;word-spacing:1.21px;}
#tr_33{left:180px;bottom:830px;letter-spacing:-0.16px;word-spacing:0.7px;}
#ts_33{left:180px;bottom:812px;letter-spacing:-0.12px;}
#tt_33{left:153px;bottom:794px;}
#tu_33{left:180px;bottom:794px;letter-spacing:-0.14px;word-spacing:-0.3px;}
#tv_33{left:180px;bottom:776px;letter-spacing:-0.14px;word-spacing:0.45px;}
#tw_33{left:180px;bottom:758px;letter-spacing:-0.12px;word-spacing:2.86px;}
#tx_33{left:180px;bottom:739px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ty_33{left:153px;bottom:721px;}
#tz_33{left:180px;bottom:721px;letter-spacing:-0.12px;word-spacing:1.38px;}
#t10_33{left:180px;bottom:703px;letter-spacing:-0.15px;word-spacing:0.39px;}
#t11_33{left:180px;bottom:685px;letter-spacing:-0.15px;word-spacing:0.75px;}
#t12_33{left:180px;bottom:667px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t13_33{left:153px;bottom:649px;}
#t14_33{left:180px;bottom:649px;letter-spacing:-0.13px;word-spacing:0.31px;}
#t15_33{left:180px;bottom:631px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t16_33{left:153px;bottom:613px;}
#t17_33{left:180px;bottom:613px;letter-spacing:-0.13px;word-spacing:-0.7px;}
#t18_33{left:180px;bottom:594px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t19_33{left:153px;bottom:576px;}
#t1a_33{left:180px;bottom:576px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1b_33{left:110px;bottom:534px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1c_33{left:110px;bottom:516px;letter-spacing:-0.11px;}
#t1d_33{left:157px;bottom:516px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1e_33{left:110px;bottom:498px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1f_33{left:110px;bottom:479px;letter-spacing:-0.17px;}
#t1g_33{left:110px;bottom:461px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1h_33{left:110px;bottom:443px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1i_33{left:110px;bottom:425px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1j_33{left:110px;bottom:407px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1k_33{left:110px;bottom:389px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1l_33{left:110px;bottom:371px;letter-spacing:-0.12px;word-spacing:-2.16px;}
#t1m_33{left:183px;bottom:371px;letter-spacing:-0.14px;word-spacing:-2.14px;}
#t1n_33{left:110px;bottom:352px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1o_33{left:110px;bottom:316px;letter-spacing:-0.12px;word-spacing:0.01px;}
#t1p_33{left:110px;bottom:298px;letter-spacing:-0.1px;word-spacing:0.01px;}
#t1q_33{left:257px;bottom:275px;letter-spacing:0.12px;}
#t1r_33{left:545px;bottom:275px;letter-spacing:0.16px;}
#t1s_33{left:117px;bottom:251px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1t_33{left:117px;bottom:239px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1u_33{left:117px;bottom:227px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1v_33{left:435px;bottom:251px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1w_33{left:435px;bottom:239px;letter-spacing:0.13px;word-spacing:0.01px;}

.s0_33{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_33{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_33{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_33{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s4_33{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s5_33{font-size:15px;font-family:Arial-BoldMT_67;color:#231F20;}
.s6_33{font-size:15px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts33" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg33" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="33/33.svg"
          type="image/svg+xml"
          id="pdf33"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_33" class="t s0_33">
          31{" "}
        </span>
        <span id="t2_33" class="t s1_33">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_33" class="t s2_33">
          Загвар хичээл 1{" "}
        </span>
        <span id="t4_33" class="t s1_33">
          Ээлжит хичээлийн цаг төлөвлөлт-40 минут{" "}
        </span>
        <span id="t5_33" class="t s3_33">
          Хамрах хүрээ:{" "}
        </span>
        <span id="t6_33" class="t s1_33">
          Ерөнхий боловсролын сургуулийн 10-12 дугаар ангид суралцагч{" "}
        </span>
        <span id="t7_33" class="t s3_33">
          Зорилго:{" "}
        </span>
        <span id="t8_33" class="t s1_33">
          Болзошгүй эрсдэл, зөрчил үйлдэгдэн гарахад нөлөөлж байгаа шалтгаан
          нөхцөл,{" "}
        </span>
        <span id="t9_33" class="t s1_33">
          түүнээс урьдчилан сэргийлэх арга, хувь хүний хариуцлагын талаар
          сурагчдад анхан шатны{" "}
        </span>
        <span id="ta_33" class="t s1_33">
          мэдлэг эзэмшүүлэхэд оршино.{" "}
        </span>
        <span id="tb_33" class="t s3_33">
          Зорилт{" "}
        </span>
        <span id="tc_33" class="t s1_33">
          -{" "}
        </span>
        <span id="td_33" class="t s1_33">
          Хүний эрх, эрх чөлөө, бусдын эд хөрөнгөд халдсан тохиололд хуулин дахь
          зүйл{" "}
        </span>
        <span id="te_33" class="t s1_33">
          заалт, учрах гэм хор, хүлээх хариуцлагын талаар зохих мэдэгдэхүүнтэй
          болох{" "}
        </span>
        <span id="tf_33" class="t s1_33">
          -{" "}
        </span>
        <span id="tg_33" class="t s1_33">
          Болзошгүй эрсдэл, зөрчилд холбогдож байгаа нь хувь хүний ухамсар,
          хүмүүжил,{" "}
        </span>
        <span id="th_33" class="t s1_33">
          хариуцлагатай салшгүй холбоотой талаарх ойлголт{" "}
        </span>
        <span id="ti_33" class="t s1_33">
          -{" "}
        </span>
        <span id="tj_33" class="t s1_33">
          Өөрийгөө болон бусдыг болзошгүй эрсдэл, зөрчлөөс сэргийлэхэд
          цагдаагийн{" "}
        </span>
        <span id="tk_33" class="t s1_33">
          байгууллагад мэдээ, мэдээлэл өгөх нь урьдчилан сэргийлэх ажил болох
          талаарх{" "}
        </span>
        <span id="tl_33" class="t s1_33">
          ойлголт{" "}
        </span>
        <span id="tm_33" class="t s1_33">
          -{" "}
        </span>
        <span id="tn_33" class="t s1_33">
          Хуулийг дээдлэн хүндэтгэж, сахин биелүүлдэг хандлагыг төлөвшүүлэх{" "}
        </span>
        <span id="to_33" class="t s3_33">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tp_33" class="t s1_33">
          -{" "}
        </span>
        <span id="tq_33" class="t s1_33">
          Багш оролцогчдод хандан насанд хүрээгүй хүмүүсийг зөрчил үйлдэхэд
          нөлөөлж{" "}
        </span>
        <span id="tr_33" class="t s1_33">
          байгаа шалтгаан, нөхцөлийг бууруулах ямар арга зам байж болох талаар
          асуулт{" "}
        </span>
        <span id="ts_33" class="t s1_33">
          тавина.{" "}
        </span>
        <span id="tt_33" class="t s1_33">
          -{" "}
        </span>
        <span id="tu_33" class="t s1_33">
          Багш ямар нэгэн ургамал буюу мод, цэцгийн аль нэгийг самбарт зурна.
          Загвар нь:{" "}
        </span>
        <span id="tv_33" class="t s1_33">
          сарнай цэцэг зурсан байна гэж бодъё. Тэгвэл сарнай цэцгийг ургуулах,
          арчлахад{" "}
        </span>
        <span id="tw_33" class="t s1_33">
          юу юу хамгийн түрүүнд хэрэгтэй талаар оролцогчдоос асууна. Зурсан
          сарнайг{" "}
        </span>
        <span id="tx_33" class="t s1_33">
          тойруулан нар, ус, агаар, шороо, хөрс, бордоо гэж бичиж таарна.{" "}
        </span>
        <span id="ty_33" class="t s1_33">
          -{" "}
        </span>
        <span id="tz_33" class="t s1_33">
          Үүнтэй нэгэн адил “Болзошгүй эрсдэл, зөрчил” гэдэг манай нийгэмд бий
          болсон{" "}
        </span>
        <span id="t10_33" class="t s1_33">
          энэ асуудлыг самбар дээр тойрог хэлбэртэй, тодорхой орон зай болгон
          төсөөлөн{" "}
        </span>
        <span id="t11_33" class="t s1_33">
          зурж, үүнийг бууруулахад ямар, ямар арга зам байж болох талаар
          оролцогчдоос{" "}
        </span>
        <span id="t12_33" class="t s1_33">
          асууна. Мэдээж оролцогчид олон зүйлсийг тоочин хэлнэ.{" "}
        </span>
        <span id="t13_33" class="t s1_33">
          -{" "}
        </span>
        <span id="t14_33" class="t s1_33">
          Хэлсэн зүйлсийг багш “Болзошгүй эрсдэл, зөрчил” гэсэн энэ тойргийн
          гадна талд{" "}
        </span>
        <span id="t15_33" class="t s1_33">
          байрлуулан сумаар заан бичиж тэмдэглэнэ.{" "}
        </span>
        <span id="t16_33" class="t s1_33">
          -{" "}
        </span>
        <span id="t17_33" class="t s1_33">
          Энэ дасгалын хүрээнд тойргийн цөм болсон гэмт хэргийг бууруулах ямар
          арга зам{" "}
        </span>
        <span id="t18_33" class="t s1_33">
          байж болохыг хэлэлцэж ойлголтоо бататгана.{" "}
        </span>
        <span id="t19_33" class="t s1_33">
          -{" "}
        </span>
        <span id="t1a_33" class="t s1_33">
          Энэ дасгалын хүрээнд харилцан ярилцан үнэлэлт дүгнэлт өгнө.{" "}
        </span>
        <span id="t1b_33" class="t s4_33">
          Загвар хичээл 2{" "}
        </span>
        <span id="t1c_33" class="t s3_33">
          Сэдэв
        </span>
        <span id="t1d_33" class="t s1_33">
          : Болзошгүй эрсдэл, зөрчлөөс урьдчилан сэргийлэх ажилд хувь хүний
          оролцоо{" "}
        </span>
        <span id="t1e_33" class="t s1_33">
          Ээлжит хичээлийн цаг төлөвлөлт-40 минут{" "}
        </span>
        <span id="t1f_33" class="t s3_33">
          Хэрэглэгдэхүүн:{" "}
        </span>
        <span id="t1g_33" class="t s1_33">
          1. Сургалтын хөтөлбөр{" "}
        </span>
        <span id="t1h_33" class="t s1_33">
          2. Мэдээллийг 2-3 хувь хувилж бэлтгэнэ.{" "}
        </span>
        <span id="t1i_33" class="t s1_33">
          3. Проектор, дэлгэц, самбар{" "}
        </span>
        <span id="t1j_33" class="t s1_33">
          4. Хүснэгт- оролцогчдын тоогоор{" "}
        </span>
        <span id="t1k_33" class="t s1_33">
          5. А4 цаас оролцогчдын тоогоор{" "}
        </span>
        <span id="t1l_33" class="t s3_33">
          Арга зүй:{" "}
        </span>
        <span id="t1m_33" class="t s1_33">
          Оролцогчдыг “А”, “Б” гэсэн 2 баг болгон хуваана. Баг болгон хуваахдаа
          оролцогчдыг{" "}
        </span>
        <span id="t1n_33" class="t s1_33">
          1, 2-оор тоолуулан хуваахаас гадна өөрсдийнх нь саналаар хувааж болно.{" "}
        </span>
        <span id="t1o_33" class="t s3_33">
          Үйл ажиллагаа:{" "}
        </span>
        <span id="t1p_33" class="t s1_33">
          Дараах хүснэгтийг дэлгэцээр үзүүлж асуулт дэвшүүлэн ярилцана.{" "}
        </span>
        <span id="t1q_33" class="t s5_33">
          “А”{" "}
        </span>
        <span id="t1r_33" class="t s5_33">
          “Б”{" "}
        </span>
        <span id="t1s_33" class="t s6_33">
          Хувь хүн болзошгүй эрсдэл, зөрчилд{" "}
        </span>
        <span id="t1t_33" class="t s6_33">
          холбогдохоос урьдчилан сэргийлэх ямар{" "}
        </span>
        <span id="t1u_33" class="t s6_33">
          аргууд байж болох талаар{" "}
        </span>
        <span id="t1v_33" class="t s6_33">
          Хохирогч болохоос хэрхэн{" "}
        </span>
        <span id="t1w_33" class="t s6_33">
          урьдчилан сэргийлэх талаар{" "}
        </span>
      </div>
    </div>
  );
}
