import React from "react";
;

export default function Twenty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_20{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_20{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_20{left:110px;bottom:1138px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t4_20{left:153px;bottom:1114px;}
#t5_20{left:180px;bottom:1114px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t6_20{left:153px;bottom:1096px;}
#t7_20{left:180px;bottom:1096px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t8_20{left:153px;bottom:1078px;}
#t9_20{left:180px;bottom:1078px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ta_20{left:153px;bottom:1060px;}
#tb_20{left:180px;bottom:1060px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tc_20{left:153px;bottom:1042px;}
#td_20{left:180px;bottom:1042px;letter-spacing:-0.13px;word-spacing:-2.22px;}
#te_20{left:153px;bottom:1024px;}
#tf_20{left:180px;bottom:1024px;letter-spacing:-0.17px;word-spacing:9.39px;}
#tg_20{left:180px;bottom:1005px;letter-spacing:-0.15px;}
#th_20{left:153px;bottom:987px;}
#ti_20{left:180px;bottom:987px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tj_20{left:153px;bottom:969px;}
#tk_20{left:180px;bottom:969px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tl_20{left:153px;bottom:951px;}
#tm_20{left:180px;bottom:951px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tn_20{left:153px;bottom:933px;}
#to_20{left:180px;bottom:933px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tp_20{left:153px;bottom:915px;}
#tq_20{left:180px;bottom:915px;letter-spacing:-0.16px;word-spacing:0.34px;}
#tr_20{left:180px;bottom:897px;letter-spacing:-0.14px;word-spacing:0.05px;}
#ts_20{left:110px;bottom:854px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tt_20{left:110px;bottom:836px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tu_20{left:153px;bottom:812px;}
#tv_20{left:180px;bottom:812px;letter-spacing:-0.11px;word-spacing:-1.08px;}
#tw_20{left:180px;bottom:794px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tx_20{left:153px;bottom:776px;}
#ty_20{left:180px;bottom:776px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tz_20{left:189px;bottom:751px;}
#t10_20{left:217px;bottom:751px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t11_20{left:189px;bottom:733px;}
#t12_20{left:217px;bottom:733px;letter-spacing:-0.12px;word-spacing:-0.9px;}
#t13_20{left:217px;bottom:715px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t14_20{left:153px;bottom:691px;}
#t15_20{left:180px;bottom:691px;letter-spacing:-0.13px;word-spacing:3.02px;}
#t16_20{left:180px;bottom:673px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t17_20{left:153px;bottom:655px;}
#t18_20{left:180px;bottom:655px;letter-spacing:-0.13px;word-spacing:1.3px;}
#t19_20{left:180px;bottom:636px;letter-spacing:-0.14px;word-spacing:2.26px;}
#t1a_20{left:180px;bottom:618px;letter-spacing:-0.12px;word-spacing:4.71px;}
#t1b_20{left:180px;bottom:600px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1c_20{left:110px;bottom:576px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1d_20{left:153px;bottom:552px;}
#t1e_20{left:180px;bottom:552px;letter-spacing:-0.14px;word-spacing:3.61px;}
#t1f_20{left:180px;bottom:533px;letter-spacing:-0.14px;word-spacing:3.31px;}
#t1g_20{left:180px;bottom:515px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1h_20{left:153px;bottom:497px;}
#t1i_20{left:180px;bottom:497px;letter-spacing:-0.16px;word-spacing:0.9px;}
#t1j_20{left:180px;bottom:479px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1k_20{left:110px;bottom:455px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1l_20{left:153px;bottom:431px;}
#t1m_20{left:180px;bottom:431px;letter-spacing:-0.12px;word-spacing:4.6px;}
#t1n_20{left:180px;bottom:412px;letter-spacing:-0.14px;word-spacing:6.98px;}
#t1o_20{left:180px;bottom:394px;letter-spacing:-0.16px;word-spacing:4.86px;}
#t1p_20{left:180px;bottom:376px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1q_20{left:153px;bottom:358px;}
#t1r_20{left:180px;bottom:358px;letter-spacing:-0.14px;word-spacing:2.45px;}
#t1s_20{left:180px;bottom:340px;letter-spacing:-0.15px;word-spacing:3.51px;}
#t1t_20{left:180px;bottom:322px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1u_20{left:110px;bottom:273px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1v_20{left:110px;bottom:249px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t1w_20{left:110px;bottom:231px;letter-spacing:-0.12px;word-spacing:-1.14px;}
#t1x_20{left:110px;bottom:213px;letter-spacing:-0.11px;word-spacing:-0.6px;}
#t1y_20{left:110px;bottom:195px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1z_20{left:110px;bottom:176px;letter-spacing:-0.11px;word-spacing:0.06px;}
#t20_20{left:110px;bottom:158px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t21_20{left:110px;bottom:140px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t22_20{left:110px;bottom:122px;letter-spacing:-0.17px;word-spacing:5.74px;}
#t23_20{left:110px;bottom:104px;letter-spacing:-0.13px;word-spacing:0.39px;}
#t24_20{left:110px;bottom:86px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_20{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_20{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_20{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s3_20{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s4_20{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}`}
        </style>
        <style id="fonts20" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg20" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="20/20.svg"
          type="image/svg+xml"
          id="pdf20"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_20" class="t s0_20">
          18{" "}
        </span>
        <span id="t2_20" class="t s1_20">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_20" class="t s1_20">
          1.1.2.Үндсэн ойлголт:{" "}
        </span>
        <span id="t4_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t5_20" class="t s1_20">
          Гэр бүлийн тухай ойлголт{" "}
        </span>
        <span id="t6_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t7_20" class="t s1_20">
          Энгийн дэг, дүрэм журам баримтлах талаар ярилцах, өөрсдөө зохиох{" "}
        </span>
        <span id="t8_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t9_20" class="t s1_20">
          Үлгэр эх уншиж асуудлыг тайлбарлан өөрсдөө дүгнэж ярилцах{" "}
        </span>
        <span id="ta_20" class="t s1_20">
          -{" "}
        </span>
        <span id="tb_20" class="t s1_20">
          Эцэг эх, багш сурагчид, хоорондоо ээнэгшин дасах, харилцан ойлголцох{" "}
        </span>
        <span id="tc_20" class="t s1_20">
          -{" "}
        </span>
        <span id="td_20" class="t s1_20">
          Сэтгэлийн хөдөлгөөнийг таних, гомдсон, ууралсан үеийн байдлаа хэрхэн
          ойлгуулах{" "}
        </span>
        <span id="te_20" class="t s1_20">
          -{" "}
        </span>
        <span id="tf_20" class="t s1_20">
          Тодорхой асуудалд шүүмжлэлттэй хандаж өөрсдийн санал бодлыг бүрэн{" "}
        </span>
        <span id="tg_20" class="t s1_20">
          илэрхийлэх{" "}
        </span>
        <span id="th_20" class="t s1_20">
          -{" "}
        </span>
        <span id="ti_20" class="t s1_20">
          Багш сурагчдын харилцааны талаар тохиолдож болох кейс ашиглах{" "}
        </span>
        <span id="tj_20" class="t s1_20">
          -{" "}
        </span>
        <span id="tk_20" class="t s1_20">
          Энэрэнгүй үйлсийн аян зохион байгуулах, бусдад туслах{" "}
        </span>
        <span id="tl_20" class="t s1_20">
          -{" "}
        </span>
        <span id="tm_20" class="t s1_20">
          Монголчуудын хүүхдээ хүмүүжүүлж байсан онцлог{" "}
        </span>
        <span id="tn_20" class="t s1_20">
          -{" "}
        </span>
        <span id="to_20" class="t s1_20">
          Монгол хүний үнэт зүйлийн талаар санал бодлоо илэрхийлэх{" "}
        </span>
        <span id="tp_20" class="t s1_20">
          -{" "}
        </span>
        <span id="tq_20" class="t s1_20">
          Монгол гэрийн дэг, аав хүний эрдэм, хатан хүний ухаан, охин, хүүгийн
          баримтлах{" "}
        </span>
        <span id="tr_20" class="t s1_20">
          дэг гэх мэт{" "}
        </span>
        <span id="ts_20" class="t s1_20">
          1.1.3.Үйл ажиллагаа:{" "}
        </span>
        <span id="tt_20" class="t s1_20">
          а. Бага боловсролд{" "}
        </span>
        <span id="tu_20" class="t s1_20">
          -{" "}
        </span>
        <span id="tv_20" class="t s1_20">
          Хэн нэгэнд туслах, санамсаргүй буруу зүйл хийсэн бол уучлал гуйх,
          дүрэм журмын{" "}
        </span>
        <span id="tw_20" class="t s1_20">
          тухай ойлголттой болгох, сэтгэлийн хөдөлгөөнийг ялгаж, таних{" "}
        </span>
        <span id="tx_20" class="t s1_20">
          -{" "}
        </span>
        <span id="ty_20" class="t s1_20">
          Эцэг эхээ хайрлах санаа авах үлгэр “ Хөхөө шувууны”{" "}
        </span>
        <span id="tz_20" class="t s2_20">
          •{" "}
        </span>
        <span id="t10_20" class="t s1_20">
          Яагаад хөхөө шувуу болоод нисээд явсан тухай асуултууд бэлдэж асууна.{" "}
        </span>
        <span id="t11_20" class="t s2_20">
          •{" "}
        </span>
        <span id="t12_20" class="t s1_20">
          Яагаад эцэг эх, гэр бүл, найз нөхдөө, эргэн тойронд байгаа хүмүүсээ
          хайрлах,{" "}
        </span>
        <span id="t13_20" class="t s1_20">
          туслах талаар асуултаар чиглүүлэн ойлголт өгөх{" "}
        </span>
        <span id="t14_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t15_20" class="t s1_20">
          Хүүхдүүдийг харьцуулах дүгнүүлэх, өөрсдийн санаа бодлыг яриулах,
          асуултад{" "}
        </span>
        <span id="t16_20" class="t s1_20">
          хариулах байдлаар сэтгэхүйн үйлүүдийг хөгжүүлэх{" "}
        </span>
        <span id="t17_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t18_20" class="t s1_20">
          Эцэг эхтэйгээ харилцах, ойлголцох талаар ахмад багш, эцэг эхийн
          төлөөлөлтэй{" "}
        </span>
        <span id="t19_20" class="t s1_20">
          уулзалт хийлгэх, санаа авах загвар олж уншуулан дүгнүүлэх, тайлбарлан
          ярьж{" "}
        </span>
        <span id="t1a_20" class="t s1_20">
          өөрсдөөр нь харьцуулах, дүгнүүлэх үйл хийлгэх өөрийн санаа бодлоо
          бүтэн{" "}
        </span>
        <span id="t1b_20" class="t s1_20">
          өгүүлбэрээр илэрхийлэх боломж олгох{" "}
        </span>
        <span id="t1c_20" class="t s1_20">
          б.Суурь боловсролд{" "}
        </span>
        <span id="t1d_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t1e_20" class="t s1_20">
          Сургуулийн орчин, нийгмийн орчин, гэр бүлийн орчинд дэг журам
          баримтлах,{" "}
        </span>
        <span id="t1f_20" class="t s1_20">
          бусдыг хүлээн зөвшөөрөх, шүүмжлэлтэй хандаж сурах, өөрийн буруу
          үйлдэлд{" "}
        </span>
        <span id="t1g_20" class="t s1_20">
          уучлал гуйх, бусдын үйлдлийн талаар өөрийн байр суурийг илэрхийлэх{" "}
        </span>
        <span id="t1h_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t1i_20" class="t s1_20">
          Гэр бүлийн гишүүдийн оролцоотойгоор тодорхой даалгавруудыг гүйцэтгэх
          болон{" "}
        </span>
        <span id="t1j_20" class="t s1_20">
          гэр бүлийнхэн болон бусад хүмүүст туслах аян өрнүүлэх{" "}
        </span>
        <span id="t1k_20" class="t s1_20">
          в.Бүрэн дунд боловсролд{" "}
        </span>
        <span id="t1l_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t1m_20" class="t s1_20">
          Ахмад настан болон бусад хүмүүсийг хүндэтгэн туслах, хүмүүнлэгийн
          ажилд{" "}
        </span>
        <span id="t1n_20" class="t s1_20">
          идэвхтэй оролцох, дүрэм журам баримтлах, өөрийн санаа бодлоо ямар ч{" "}
        </span>
        <span id="t1o_20" class="t s1_20">
          үед илэрхийлэх чадвартай болох дүгнэх харьцуулах боломжтой санаануудыг{" "}
        </span>
        <span id="t1p_20" class="t s1_20">
          жишээгээр бэлдэх{" "}
        </span>
        <span id="t1q_20" class="t s1_20">
          -{" "}
        </span>
        <span id="t1r_20" class="t s1_20">
          Гэр бүлийн харилцаа олон хэлбэрүүдийг таниулах гэр бүл болоход ямар
          ямар{" "}
        </span>
        <span id="t1s_20" class="t s1_20">
          зүйлд анхаарах талаар бусад хичээлтэй холбож багш нартай хамтран
          чөлөөт{" "}
        </span>
        <span id="t1t_20" class="t s1_20">
          ярилцлага зохион байгуулах{" "}
        </span>
        <span id="t1u_20" class="t s1_20">
          1.1.4 Арга зүй{" "}
        </span>
        <span id="t1v_20" class="t s3_20">
          Хамтын уншлага – хамтын ярилцлага арга{" "}
        </span>
        <span id="t1w_20" class="t s1_20">
          Хамтын уншлага ба ярилцлага нь (Vaughn and Estes, 1986; Steele and
          Meredith,1997) “Хөхөө{" "}
        </span>
        <span id="t1x_20" class="t s1_20">
          шувуу” үлгэрийг хоёр сурагч мөрөөр буюу хэсэг хэсгээр нь ээлжлэн уншиж
          нөгөө сурагчдаа{" "}
        </span>
        <span id="t1y_20" class="t s1_20">
          гол утгыг нь ярьж өгөх арга юм.{" "}
        </span>
        <span id="t1z_20" class="t s1_20">
          Хамтран сурахуйн энэ арга нь сурагчид бие биеийн сурахуйд идэвхтэй
          хандахын зэрэгцээ{" "}
        </span>
        <span id="t20_20" class="t s1_20">
          хичээлийн сэдвийн гол агуулгыг ойлгоход нь дэмжлэг үзүүлэх явдал юм.{" "}
        </span>
        <span id="t21_20" class="t s4_20">
          Сурагчдын хийх үйл ажиллагаа{" "}
        </span>
        <span id="t22_20" class="t s1_20">
          Алхам 1: Ач холбогдол бүхий мэдээлэлтэй текст, бичвэрийг сонгоно.
          3-аас дээшгүй{" "}
        </span>
        <span id="t23_20" class="t s1_20">
          өгүүлбэрээс бүтсэн догол мөр бүхий байвал сайн ба эсвэл багш өөрөө
          текстийг хэсгүүдэд{" "}
        </span>
        <span id="t24_20" class="t s1_20">
          хувааж тэмдэглэж болно.{" "}
        </span>
      </div>
    </div>
  );
}
