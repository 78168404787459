import React from "react";
;

export default function Two() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };

  return (
    <div id="p2" style={containerStyle}>
      <head>
        <style className="shared-css" type="text/css">
          {`.t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }`}
        </style>
        <style type="text/css">
          {`
						#t1_2{left:165px;bottom:1084px;letter-spacing:-0.21px;word-spacing:0.09px;}
						#t2_2{left:275px;bottom:1066px;letter-spacing:-0.21px;}
						#t3_2{left:443px;bottom:1066px;letter-spacing:-0.15px;word-spacing:0.03px;}
						#t4_2{left:275px;bottom:1048px;letter-spacing:-0.22px;}
						#t5_2{left:444px;bottom:1048px;letter-spacing:-0.14px;word-spacing:0.02px;}
						#t6_2{left:275px;bottom:1030px;letter-spacing:-0.18px;}
						#t7_2{left:446px;bottom:1030px;letter-spacing:-0.15px;word-spacing:0.03px;}
						#t8_2{left:275px;bottom:1012px;letter-spacing:-0.2px;}
						#t9_2{left:444px;bottom:1012px;letter-spacing:-0.15px;word-spacing:0.03px;}
						#ta_2{left:165px;bottom:975px;letter-spacing:-0.15px;}
						#tb_2{left:275px;bottom:957px;letter-spacing:-0.19px;}
						#tc_2{left:457px;bottom:957px;letter-spacing:-0.18px;word-spacing:0.06px;}
						#td_2{left:455px;bottom:939px;letter-spacing:-0.16px;word-spacing:0.04px;}
						#te_2{left:275px;bottom:921px;letter-spacing:-0.17px;}
						#tf_2{left:456px;bottom:921px;letter-spacing:-0.41px;word-spacing:0.27px;}
						#tg_2{left:162px;bottom:100px;letter-spacing:0.15px;word-spacing:0.02px;}

						.s0_2{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
						.s1_2{font-size:18px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts2" type="text/css">
          {`
					@font-face {
						font-family: Arial-BoldMT_67;
						src: url("fonts/Arial-BoldMT_67.woff") format("woff");
					}

					@font-face {
						font-family: ArialMT_5-;
						src: url("fonts/ArialMT_5-.woff") format("woff");
				}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg2" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_2" class="t s0_2">
          АЖЛЫН ХЭСЭГ:{" "}
        </span>
        <span id="t2_2" class="t s0_2">
          Б.АЛТАНСҮХ{" "}
        </span>
        <span id="t3_2" class="t s0_2">
          БСҮХ-ИЙН ЭША{" "}
        </span>
        <span id="t4_2" class="t s0_2">
          Ц.ЛХАГВАСҮРЭН{" "}
        </span>
        <span id="t5_2" class="t s0_2">
          БСҮХ-ИЙН ЭША{" "}
        </span>
        <span id="t6_2" class="t s0_2">
          С.ОТГОНТУЯА{" "}
        </span>
        <span id="t7_2" class="t s0_2">
          БСҮХ-ИЙН ЭША{" "}
        </span>
        <span id="t8_2" class="t s0_2">
          Х.ЭНХЖАРГАЛ{" "}
        </span>
        <span id="t9_2" class="t s0_2">
          БСҮХ-ИЙН ЭША{" "}
        </span>
        <span id="ta_2" class="t s0_2">
          РЕДАКТОР:{" "}
        </span>
        <span id="tb_2" class="t s0_2">
          А.БҮРЭНЖАРГАЛ{" "}
        </span>
        <span id="tc_2" class="t s0_2">
          ГҮҮД НЭЙБОРС ОУБ-ЫН МОНГОЛ ДАХЬ{" "}
        </span>
        <span id="td_2" class="t s0_2">
          ТӨЛӨӨЛӨГЧИЙН ГАЗРЫН ЕРӨНХИЙ МЕНЕЖЕР{" "}
        </span>
        <span id="te_2" class="t s0_2">
          Б.БАЛЖИННЯМ{" "}
        </span>
        <span id="tf_2" class="t s0_2">
          БОЛОВСРОЛ СУДЛААЧ{" "}
        </span>
        <span id="tg_2" class="t s1_2">
          НҮБ-ын Хүүхдийн Сангийн дэмжлэгтэйгээр боловсруулж, хэвлүүлэв.{" "}
        </span>
      </div>
    </div>
  );
}
