import React from "react";
;

export default function FourThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_43{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_43{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_43{left:110px;bottom:1157px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t4_43{left:110px;bottom:1139px;letter-spacing:-0.1px;word-spacing:4.24px;}
#t5_43{left:289px;bottom:1139px;}
#t6_43{left:304px;bottom:1139px;letter-spacing:-0.16px;word-spacing:4.29px;}
#t7_43{left:110px;bottom:1121px;letter-spacing:-0.15px;word-spacing:-1.62px;}
#t8_43{left:621px;bottom:1121px;letter-spacing:-0.11px;word-spacing:-1.65px;}
#t9_43{left:110px;bottom:1102px;letter-spacing:-0.13px;word-spacing:-0.67px;}
#ta_43{left:110px;bottom:1084px;letter-spacing:-0.16px;word-spacing:3.84px;}
#tb_43{left:110px;bottom:1066px;letter-spacing:-0.15px;word-spacing:7.1px;}
#tc_43{left:110px;bottom:1048px;letter-spacing:-0.15px;word-spacing:5.3px;}
#td_43{left:110px;bottom:1030px;letter-spacing:-0.19px;word-spacing:0.1px;}
#te_43{left:110px;bottom:994px;letter-spacing:-0.12px;word-spacing:7.32px;}
#tf_43{left:376px;bottom:994px;}
#tg_43{left:393px;bottom:994px;letter-spacing:-0.13px;word-spacing:7.34px;}
#th_43{left:110px;bottom:975px;letter-spacing:-0.12px;word-spacing:0.85px;}
#ti_43{left:110px;bottom:957px;letter-spacing:-0.16px;word-spacing:0.9px;}
#tj_43{left:110px;bottom:939px;letter-spacing:-0.14px;word-spacing:1.24px;}
#tk_43{left:110px;bottom:921px;letter-spacing:-0.12px;word-spacing:1.01px;}
#tl_43{left:110px;bottom:903px;letter-spacing:-0.17px;word-spacing:4.6px;}
#tm_43{left:110px;bottom:885px;letter-spacing:-0.18px;word-spacing:2.27px;}
#tn_43{left:110px;bottom:867px;letter-spacing:-0.17px;word-spacing:0.07px;}
#to_43{left:202px;bottom:830px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tp_43{left:130px;bottom:783px;letter-spacing:-0.15px;word-spacing:3.38px;}
#tq_43{left:130px;bottom:765px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tr_43{left:130px;bottom:741px;letter-spacing:-0.15px;word-spacing:4.78px;}
#ts_43{left:740px;bottom:741px;letter-spacing:-0.06px;}
#tt_43{left:130px;bottom:723px;letter-spacing:-0.13px;}
#tu_43{left:130px;bottom:698px;letter-spacing:-0.14px;word-spacing:1.93px;}
#tv_43{left:130px;bottom:680px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tw_43{left:110px;bottom:628px;letter-spacing:-0.13px;word-spacing:0.81px;}
#tx_43{left:110px;bottom:610px;letter-spacing:-0.13px;word-spacing:-1.01px;}
#ty_43{left:110px;bottom:592px;letter-spacing:-0.12px;word-spacing:1.81px;}
#tz_43{left:110px;bottom:574px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t10_43{left:110px;bottom:538px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t11_43{left:110px;bottom:520px;letter-spacing:-0.17px;word-spacing:3.01px;}
#t12_43{left:110px;bottom:502px;letter-spacing:-0.05px;}
#t13_43{left:138px;bottom:502px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t14_43{left:110px;bottom:483px;letter-spacing:-0.15px;word-spacing:2.99px;}
#t15_43{left:110px;bottom:465px;letter-spacing:-0.05px;}
#t16_43{left:138px;bottom:465px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t17_43{left:110px;bottom:447px;letter-spacing:-0.13px;word-spacing:2.96px;}
#t18_43{left:110px;bottom:429px;letter-spacing:-0.16px;word-spacing:4.46px;}
#t19_43{left:110px;bottom:411px;letter-spacing:-0.13px;word-spacing:2.96px;}
#t1a_43{left:110px;bottom:393px;letter-spacing:-0.05px;}
#t1b_43{left:138px;bottom:393px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1c_43{left:110px;bottom:375px;letter-spacing:-0.05px;}
#t1d_43{left:138px;bottom:375px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1e_43{left:110px;bottom:338px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1f_43{left:110px;bottom:320px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1g_43{left:153px;bottom:296px;}
#t1h_43{left:180px;bottom:296px;letter-spacing:-0.13px;word-spacing:5.14px;}
#t1i_43{left:180px;bottom:278px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1j_43{left:153px;bottom:260px;}
#t1k_43{left:180px;bottom:260px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1l_43{left:153px;bottom:242px;}
#t1m_43{left:180px;bottom:242px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1n_43{left:110px;bottom:217px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1o_43{left:153px;bottom:193px;}
#t1p_43{left:180px;bottom:193px;letter-spacing:-0.17px;word-spacing:3.5px;}
#t1q_43{left:180px;bottom:175px;letter-spacing:-0.16px;word-spacing:6.26px;}
#t1r_43{left:180px;bottom:157px;letter-spacing:-0.1px;}
#t1s_43{left:153px;bottom:139px;}
#t1t_43{left:180px;bottom:139px;letter-spacing:-0.15px;word-spacing:3.02px;}
#t1u_43{left:180px;bottom:120px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_43{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_43{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_43{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s3_43{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s4_43{font-size:17px;font-family:ArialMT_5-;color:#375FB2;}`}
        </style>

        <style id="fonts43" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg43" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="43/43.svg"
          type="image/svg+xml"
          id="pdf43"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_43" class="t s0_43">
          41{" "}
        </span>
        <span id="t2_43" class="t s1_43">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_43" class="t s1_43">
          хэрэгтэй хамгийн залуу хэрэглэгчдийн бүлэг юм.{" "}
        </span>
        <span id="t4_43" class="t s1_43">
          10-14 насны хүүхдүүд
        </span>
        <span id="t5_43" class="t s2_43">
          :{" "}
        </span>
        <span id="t6_43" class="t s1_43">
          Энэ үеийнхэн шилжилтийн насны хүүхдүүд байдаг. Тэд цахим{" "}
        </span>
        <span id="t7_43" class="t s1_43">
          мэдээллийн орчны талаар анхны ойлголт туршлагатай болсон үе.{" "}
        </span>
        <span id="t8_43" class="t s1_43">
          Залуу хүн болж төлөвших{" "}
        </span>
        <span id="t9_43" class="t s1_43">
          насныхан тул тэдэнд ойлгож сонирхсон маш олон асуудлууд байдаг бөгөөд
          тэрэндээ хариу{" "}
        </span>
        <span id="ta_43" class="t s1_43">
          авах сонирхолдоо хөтлөгдөн одоо байгаа хязгаараасаа давж болзошгүй.
          Шүүлтүүрийн{" "}
        </span>
        <span id="tb_43" class="t s1_43">
          программ болон өөр бусад техник хэрэгслийг хэрэглэх нь ашигтай. Энэ
          насныхан{" "}
        </span>
        <span id="tc_43" class="t s1_43">
          мэдээллийн орчин дахь зохисгүй агуулга болон зохимжгүй харилцаанд
          шүүмжлэлтэй{" "}
        </span>
        <span id="td_43" class="t s1_43">
          ханддаггүй нь элдэв асуудалд татагдах магадлалыг нэмэгдүүлдэг.{" "}
        </span>
        <span id="te_43" class="t s3_43">
          15 ба түүнээс дээш насныхан
        </span>
        <span id="tf_43" class="t s2_43">
          :{" "}
        </span>
        <span id="tg_43" class="t s1_43">
          Энэ насны бүлгийнхэн мэдээллийн орчинд цахим{" "}
        </span>
        <span id="th_43" class="t s1_43">
          технологийг хэн нэгний хараа хяналтгүйгээр ашигладаг залуу үеийнхэн
          юм. Шүүлтүүрийн{" "}
        </span>
        <span id="ti_43" class="t s1_43">
          программ ач холбогдол багатай ч нэмэлт үүргээ гүйцэтгэсээр байх болно.
          Өөрийн хувийн{" "}
        </span>
        <span id="tj_43" class="t s1_43">
          амьдралд бусдын хараа хяналтыг оролцуулахгүй байх, цахим мэдээллийн
          орчноос шинэ{" "}
        </span>
        <span id="tk_43" class="t s1_43">
          найз нөхөд хайж олох, үе тэнгийнхэнтэйгээ харилцаа холбоо тогтоох
          гэсэн асар их хүсэл{" "}
        </span>
        <span id="tl_43" class="t s1_43">
          эрмэлзлэлтэй. Мөн сониуч хэрэгт дурламтгай байдалдаа хөтлөгдөн аюултай
          байдалд{" "}
        </span>
        <span id="tm_43" class="t s1_43">
          өөрсдийгээ оруулж болзошгүй байдаг. Энэ тохиолдолд тэднийг өөрсдийгээ
          яаж аюулгүй{" "}
        </span>
        <span id="tn_43" class="t s1_43">
          байлгах вэ? гэдгийг ойлгуулах шаардлагатай.{" "}
        </span>
        <span id="to_43" class="t s4_43">
          ЗУРГАА. НИЙГЭМ, ОЛОН НИЙТИЙН ҮЙЛ АЖИЛЛАГААГ ДЭМЖИХ{" "}
        </span>
        <span id="tp_43" class="t s1_43">
          6.1. Хүүхдийн эрх зүйн мэдлэг, соёлыг дээшлүүлэх, тэдний эрх, ашиг
          сонирхолтой{" "}
        </span>
        <span id="tq_43" class="t s1_43">
          холбоотой асуудлаар шийдвэр гаргахад чиглэсэн ажлыг эрчимжүүлэх{" "}
        </span>
        <span id="tr_43" class="t s1_43">
          6.2. Сурагчдыг нийгэмд тустай үйл ажиллагаанд бүтээлчээр оролцоход{" "}
        </span>
        <span id="ts_43" class="t s1_43">
          дэмжлэг{" "}
        </span>
        <span id="tt_43" class="t s1_43">
          үзүүлэх{" "}
        </span>
        <span id="tu_43" class="t s1_43">
          6.3. Боловсролын байгууллагуудтай хүмүүжлийн асуудлаар хамтран
          ажилладаг эцэг{" "}
        </span>
        <span id="tv_43" class="t s1_43">
          эхийн болон олон нийтийн бусад байгууллагын ажиллагааг хөхүүлэн дэмжих{" "}
        </span>
        <span id="tw_43" class="t s1_43">
          6.1.1. Зорилго: Хүүхдийн оролцооны байгууллагуудын үйл ажиллагаанд
          дэмжлэг үзүүлэх,{" "}
        </span>
        <span id="tx_43" class="t s1_43">
          төрийн болон төрийн бус байгууллага, эцэг эх, гэр бүлтэй хүүхдийн эрх,
          хөгжил хамгааллын{" "}
        </span>
        <span id="ty_43" class="t s1_43">
          чиглэлээр нийгэм олон нийтийн үйл ажиллагаанд үр дүнтэй хамтран
          ажиллах, хүүхдийн{" "}
        </span>
        <span id="tz_43" class="t s1_43">
          оролцоонд дэмжлэг үзүүлэхэд оршино.{" "}
        </span>
        <span id="t10_43" class="t s1_43">
          6.1.2. Үндсэн ойлголт{" "}
        </span>
        <span id="t11_43" class="t s1_43">
          1. Оролцоо хэмээх ойлголт{" "}
        </span>
        <span id="t12_43" class="t s1_43">
          2.{" "}
        </span>
        <span id="t13_43" class="t s1_43">
          Хүүхдийн оролцооны тухай ойлголт{" "}
        </span>
        <span id="t14_43" class="t s1_43">
          3. Хүүхдийн оролцооны зарчмууд{" "}
        </span>
        <span id="t15_43" class="t s1_43">
          4.{" "}
        </span>
        <span id="t16_43" class="t s1_43">
          Хүүхдийн оролцооны байгууллагуудын үйл ажиллагаа{" "}
        </span>
        <span id="t17_43" class="t s1_43">
          5. Хүүхдийн оролцооны түвшин{" "}
        </span>
        <span id="t18_43" class="t s1_43">
          6. Оролцооны хэлбэрүүд{" "}
        </span>
        <span id="t19_43" class="t s1_43">
          7. Оролцоог дэмжих нь{" "}
        </span>
        <span id="t1a_43" class="t s1_43">
          8.{" "}
        </span>
        <span id="t1b_43" class="t s1_43">
          Хүүхдийн оролцоонд тулгарах саад бэрхшээл{" "}
        </span>
        <span id="t1c_43" class="t s1_43">
          9.{" "}
        </span>
        <span id="t1d_43" class="t s1_43">
          Хүүхдийн оролцох эрхийн хэрэгжилтэд гарсан ахиц дэвшилд үнэлгээ хийх{" "}
        </span>
        <span id="t1e_43" class="t s1_43">
          6.1.3. Үйл ажиллагаа{" "}
        </span>
        <span id="t1f_43" class="t s3_43">
          а.Бага боловсролд{" "}
        </span>
        <span id="t1g_43" class="t s1_43">
          -{" "}
        </span>
        <span id="t1h_43" class="t s1_43">
          Өөрийгөө тодорхойлох, бусдыг сонсох, өөрийн хүсэл сонирхлоо
          илэрхийлэх,{" "}
        </span>
        <span id="t1i_43" class="t s1_43">
          бусдад хүндэтгэлтэй хандах{" "}
        </span>
        <span id="t1j_43" class="t s1_43">
          -{" "}
        </span>
        <span id="t1k_43" class="t s1_43">
          Өөртөө итгэлтэй байх, өөрийгөө үнэлэх{" "}
        </span>
        <span id="t1l_43" class="t s1_43">
          -{" "}
        </span>
        <span id="t1m_43" class="t s1_43">
          Өөрийн үзэл бодлоо чөлөөтэй илэрхийлэх, эрх үүрэг хэрэгцээгээ мэддэг
          байх{" "}
        </span>
        <span id="t1n_43" class="t s1_43">
          б.Суурь боловсролд{" "}
        </span>
        <span id="t1o_43" class="t s1_43">
          -{" "}
        </span>
        <span id="t1p_43" class="t s1_43">
          Өөрийн нөөц боломжийг тодорхойлох, бусдыг сонсож ойлгохын ач
          холбогдол,{" "}
        </span>
        <span id="t1q_43" class="t s1_43">
          өөрийн ирээдүйн зорилгоо тодорхойлох, өөрийгөө удирдах болон сэтгэлийн{" "}
        </span>
        <span id="t1r_43" class="t s1_43">
          хөдөлгөөнөө хянах{" "}
        </span>
        <span id="t1s_43" class="t s1_43">
          -{" "}
        </span>
        <span id="t1t_43" class="t s1_43">
          Бусадтай хариуцлагатай мэдрэмжтэй нөхөрсөг харилцах, хүндэтгэлтэй
          хандах,{" "}
        </span>
        <span id="t1u_43" class="t s1_43">
          зөрчилдөөнтэй үед асуудлыг хүчирхийлэлгүй эерэг аргаар шийдэх{" "}
        </span>
      </div>
    </div>
  );
}
