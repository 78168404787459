import React from "react";
;

export default function Thirty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p30" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_30{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_30{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_30{left:110px;bottom:1157px;letter-spacing:-0.14px;word-spacing:0.06px;}
#t4_30{left:153px;bottom:1133px;}
#t5_30{left:180px;bottom:1133px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t6_30{left:153px;bottom:1114px;}
#t7_30{left:180px;bottom:1114px;letter-spacing:-0.12px;word-spacing:3.75px;}
#t8_30{left:180px;bottom:1096px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t9_30{left:153px;bottom:1078px;}
#ta_30{left:180px;bottom:1078px;letter-spacing:-0.14px;word-spacing:0.07px;}
#tb_30{left:180px;bottom:1060px;letter-spacing:-0.11px;word-spacing:0.11px;}
#tc_30{left:180px;bottom:1042px;letter-spacing:-0.14px;word-spacing:0.05px;}
#td_30{left:110px;bottom:999px;letter-spacing:-0.21px;word-spacing:0.11px;}
#te_30{left:110px;bottom:981px;letter-spacing:-0.17px;word-spacing:-0.01px;}
#tf_30{left:110px;bottom:963px;letter-spacing:-0.13px;word-spacing:-0.54px;}
#tg_30{left:110px;bottom:945px;letter-spacing:-0.14px;word-spacing:5.45px;}
#th_30{left:110px;bottom:927px;letter-spacing:-0.23px;word-spacing:0.14px;}
#ti_30{left:110px;bottom:878px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_30{left:188px;bottom:878px;letter-spacing:-0.23px;word-spacing:0.12px;}
#tk_30{left:110px;bottom:854px;letter-spacing:-0.12px;word-spacing:0.01px;}
#tl_30{left:153px;bottom:830px;}
#tm_30{left:180px;bottom:830px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tn_30{left:153px;bottom:812px;}
#to_30{left:180px;bottom:812px;letter-spacing:-0.16px;word-spacing:4.82px;}
#tp_30{left:180px;bottom:794px;letter-spacing:-0.2px;}
#tq_30{left:153px;bottom:776px;}
#tr_30{left:180px;bottom:776px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ts_30{left:153px;bottom:757px;}
#tt_30{left:180px;bottom:757px;letter-spacing:-0.17px;word-spacing:0.97px;}
#tu_30{left:180px;bottom:739px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tv_30{left:153px;bottom:721px;}
#tw_30{left:180px;bottom:721px;letter-spacing:-0.14px;word-spacing:-0.41px;}
#tx_30{left:180px;bottom:703px;letter-spacing:-0.11px;word-spacing:0.02px;}
#ty_30{left:153px;bottom:685px;}
#tz_30{left:180px;bottom:685px;letter-spacing:-0.16px;word-spacing:2.68px;}
#t10_30{left:180px;bottom:667px;letter-spacing:-0.14px;word-spacing:0.06px;}
#t11_30{left:153px;bottom:649px;}
#t12_30{left:180px;bottom:649px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t13_30{left:153px;bottom:630px;}
#t14_30{left:180px;bottom:630px;letter-spacing:-0.16px;word-spacing:2.7px;}
#t15_30{left:180px;bottom:612px;letter-spacing:-0.09px;}
#t16_30{left:153px;bottom:594px;}
#t17_30{left:180px;bottom:594px;letter-spacing:-0.13px;word-spacing:0.57px;}
#t18_30{left:180px;bottom:576px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t19_30{left:110px;bottom:552px;letter-spacing:-0.23px;word-spacing:0.12px;}
#t1a_30{left:110px;bottom:534px;letter-spacing:-0.13px;word-spacing:3.68px;}
#t1b_30{left:110px;bottom:516px;letter-spacing:-0.14px;word-spacing:3.42px;}
#t1c_30{left:110px;bottom:497px;letter-spacing:-0.23px;word-spacing:2.68px;}
#t1d_30{left:110px;bottom:479px;letter-spacing:-0.15px;word-spacing:0.48px;}
#t1e_30{left:110px;bottom:461px;letter-spacing:-0.17px;word-spacing:-1.17px;}
#t1f_30{left:110px;bottom:443px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1g_30{left:153px;bottom:425px;}
#t1h_30{left:180px;bottom:425px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1i_30{left:153px;bottom:407px;}
#t1j_30{left:180px;bottom:407px;letter-spacing:-0.26px;word-spacing:0.16px;}
#t1k_30{left:153px;bottom:389px;}
#t1l_30{left:180px;bottom:389px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t1m_30{left:153px;bottom:370px;}
#t1n_30{left:180px;bottom:370px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1o_30{left:153px;bottom:352px;}
#t1p_30{left:180px;bottom:352px;letter-spacing:-0.18px;word-spacing:-1.16px;}
#t1q_30{left:180px;bottom:334px;letter-spacing:-0.26px;word-spacing:0.16px;}
#t1r_30{left:110px;bottom:316px;letter-spacing:-0.17px;word-spacing:-0.13px;}
#t1s_30{left:110px;bottom:298px;letter-spacing:-0.14px;word-spacing:0.37px;}
#t1t_30{left:110px;bottom:280px;letter-spacing:-0.14px;word-spacing:0.13px;}
#t1u_30{left:110px;bottom:262px;letter-spacing:-0.17px;word-spacing:-1.24px;}
#t1v_30{left:110px;bottom:243px;letter-spacing:-0.14px;word-spacing:-0.51px;}
#t1w_30{left:110px;bottom:225px;letter-spacing:-0.14px;word-spacing:0.43px;}
#t1x_30{left:110px;bottom:207px;letter-spacing:-0.25px;word-spacing:0.16px;}

.s0_30{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_30{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_30{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_30{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s4_30{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s5_30{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>
        <style id="fonts30" type="text/css">
          {`
@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg30" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="30/30.svg"
          type="image/svg+xml"
          id="pdf30"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_30" class="t s0_30">
          28{" "}
        </span>
        <span id="t2_30" class="t s1_30">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_30" class="t s1_30">
          Асуулт :{" "}
        </span>
        <span id="t4_30" class="t s1_30">
          -{" "}
        </span>
        <span id="t5_30" class="t s1_30">
          А, В, С хүснэгтэд байгаа тохиолдлууд ямар ялгаатай вэ ?{" "}
        </span>
        <span id="t6_30" class="t s1_30">
          -{" "}
        </span>
        <span id="t7_30" class="t s1_30">
          Ижил төсөөтэй зүйл байна уу? гэсэн асуултад ширээний найзтайгаа
          ярилцан{" "}
        </span>
        <span id="t8_30" class="t s1_30">
          хариулах даалгавар өгнө.{" "}
        </span>
        <span id="t9_30" class="t s1_30">
          -{" "}
        </span>
        <span id="ta_30" class="t s1_30">
          Хэсэг хугацааны дараа 2–3 хосын хариултыг сонсож хэлэлцээд гурвуулаа
          зөрчил{" "}
        </span>
        <span id="tb_30" class="t s1_30">
          мөн үүгээрээ төсөөтэй. Харин ялгаа нь: А– ёс зүйг зөрчсөн, В–сурагчийн
          дүрмийг{" "}
        </span>
        <span id="tc_30" class="t s1_30">
          зөрчсөн, С–хуулийг зөрчсөн гэсэн нэгдмэл шийдэлд хүрнэ.{" "}
        </span>
        <span id="td_30" class="t s2_30">
          Нэмэлт мэдээлэл:{" "}
        </span>
        <span id="te_30" class="t s1_30">
          Аль ч улс оронд нийгмийн харилцааг бичигдсэн ба бичигдээгүй хуулиар
          зохицуулж байдаг.{" "}
        </span>
        <span id="tf_30" class="t s1_30">
          Бичигдээгүй хууль гэдэг нь эрт үеэс уламжлан ирсэн зан заншил, ёс зүйн
          үйлтэй холбогдон{" "}
        </span>
        <span id="tg_30" class="t s1_30">
          бий болсон хэм хэмжээнүүд байдаг бол бичигдсэн хууль нь дүрэм, журам,
          хуулиар{" "}
        </span>
        <span id="th_30" class="t s1_30">
          боловсрогдон хэрэгждэг.{" "}
        </span>
        <span id="ti_30" class="t s3_30">
          Загвар 2.{" "}
        </span>
        <span id="tj_30" class="t s4_30">
          Хуулийн мод{" "}
        </span>
        <span id="tk_30" class="t s2_30">
          Үйл ажиллагаа:{" "}
        </span>
        <span id="tl_30" class="t s1_30">
          -{" "}
        </span>
        <span id="tm_30" class="t s1_30">
          Багш одоо бүгдээрээ хуулийн мод зурцгаая.{" "}
        </span>
        <span id="tn_30" class="t s1_30">
          -{" "}
        </span>
        <span id="to_30" class="t s1_30">
          Та нар хуулийг мод гэж төсөөлөөд өөрийн мэддэг хуулиа модныхоо мөчирт{" "}
        </span>
        <span id="tp_30" class="t s1_30">
          байрлуулаарай.{" "}
        </span>
        <span id="tq_30" class="t s1_30">
          -{" "}
        </span>
        <span id="tr_30" class="t s1_30">
          Ямар хууль хаана хаана байрлах вэ ? Яагаад?{" "}
        </span>
        <span id="ts_30" class="t s1_30">
          -{" "}
        </span>
        <span id="tt_30" class="t s1_30">
          Оролцогч бүр хуулийн мод зурж, бие биедээ тайлбарласны дараа багш
          самбарт{" "}
        </span>
        <span id="tu_30" class="t s1_30">
          томоор зурсан хуулийн модыг нааж үзүүлнэ.{" "}
        </span>
        <span id="tv_30" class="t s1_30">
          -{" "}
        </span>
        <span id="tw_30" class="t s1_30">
          Энэ мод та нарынхаас ямар ялгаатай байна, Үндсэн хууль яагаад модны
          үндэс нь{" "}
        </span>
        <span id="tx_30" class="t s1_30">
          болдог вэ ? гэж асууна.{" "}
        </span>
        <span id="ty_30" class="t s1_30">
          -{" "}
        </span>
        <span id="tz_30" class="t s1_30">
          Аливаа улсын гол хууль нь үндсэн хууль байдаг ба бусад хууль түүнд
          нийцэж{" "}
        </span>
        <span id="t10_30" class="t s1_30">
          гарах ёстой.{" "}
        </span>
        <span id="t11_30" class="t s1_30">
          -{" "}
        </span>
        <span id="t12_30" class="t s1_30">
          Хуулийг яагаад заавал дагаж мөрдөх ёстой вэ?{" "}
        </span>
        <span id="t13_30" class="t s1_30">
          -{" "}
        </span>
        <span id="t14_30" class="t s1_30">
          Түүний ач холбогдлыг энэ алимны дүрсэн дээр бичиж самбарт байгаа
          модонд{" "}
        </span>
        <span id="t15_30" class="t s1_30">
          наахыг хүснэ.{" "}
        </span>
        <span id="t16_30" class="t s1_30">
          -{" "}
        </span>
        <span id="t17_30" class="t s1_30">
          Алимны дүрсүүд дэх бичвэрийг уншиж танилцуулаад дүгнэлтийг мэдээллээс
          авч{" "}
        </span>
        <span id="t18_30" class="t s1_30">
          сурагчдын нас, сэтгэхүйн онцлогт тохируулан тайлбарлана.{" "}
        </span>
        <span id="t19_30" class="t s2_30">
          Нэмэлт мэдээлэл{" "}
        </span>
        <span id="t1a_30" class="t s1_30">
          Хүн бүрийн дагаж мөрдөх ёстой дүрэм, журмыг багтаасан баримт бичгийг
          хууль гэнэ.{" "}
        </span>
        <span id="t1b_30" class="t s1_30">
          Хүүхэд та нар төрмөгцөө тухайн улсын иргэнд тооцогдох, овог нэр,
          төрсний гэрчилгээ{" "}
        </span>
        <span id="t1c_30" class="t s1_30">
          авч иргэний бүртгэлд бүртгэгдсэнээр хуультай холбогдож хуулиар
          хамгаалагддаг. Хүний{" "}
        </span>
        <span id="t1d_30" class="t s1_30">
          эрхийг хамгаалах, шударга ёс, дэг журмыг тогтоох, аливаа зөрчил
          маргааныг шийдвэрлэх{" "}
        </span>
        <span id="t1e_30" class="t s1_30">
          шаардлагатай олон асуудлыг зохицуулах зорилгоор хууль боловсруулж улс
          орны амьдралд{" "}
        </span>
        <span id="t1f_30" class="t s1_30">
          эмх замбраагүй байдал үүсэхээс хамгаалж байдаг. Хуулийг
          боловсруулахдаа:{" "}
        </span>
        <span id="t1g_30" class="t s5_30">
          •{" "}
        </span>
        <span id="t1h_30" class="t s1_30">
          Үг өгүүлбэр нь алдаагүй, зөв бичигдсэн байх{" "}
        </span>
        <span id="t1i_30" class="t s5_30">
          •{" "}
        </span>
        <span id="t1j_30" class="t s1_30">
          Товч тодорхой ойлгомжтой байх{" "}
        </span>
        <span id="t1k_30" class="t s5_30">
          •{" "}
        </span>
        <span id="t1l_30" class="t s1_30">
          Бусад хуультай зөрчилдөхгүй байх{" "}
        </span>
        <span id="t1m_30" class="t s5_30">
          •{" "}
        </span>
        <span id="t1n_30" class="t s1_30">
          Хэрэгжүүлэх боломжтой байх{" "}
        </span>
        <span id="t1o_30" class="t s5_30">
          •{" "}
        </span>
        <span id="t1p_30" class="t s1_30">
          Зөрчсөн тохиолдолд хүлээлгэх хариуцлага нь тодорхой, тохирсон байх г.м
          дүрмийг{" "}
        </span>
        <span id="t1q_30" class="t s1_30">
          сахихыг шаарддаг.{" "}
        </span>
        <span id="t1r_30" class="t s1_30">
          Хуулийг үндсэн хууль, ердийн хууль буюу бусад хууль гэж ангилдаг.
          Үндсэн хууль нь бусад{" "}
        </span>
        <span id="t1s_30" class="t s1_30">
          бүх хуулийн эх сурвалж, үндэс нь болж төрийн дээд байгууллагууд,
          тэдгээрийн бүрэн эрх,{" "}
        </span>
        <span id="t1t_30" class="t s1_30">
          хувь хүн, төр хоёрын хоорондын харилцааг тодорхойлж байдаг бол бусад
          хууль нь үндсэн{" "}
        </span>
        <span id="t1u_30" class="t s1_30">
          хуулийн үзэл санаанд нийцэн боловсрогдож тухайн салбар эрх зүйн
          харилцааг зохицуулдаг.{" "}
        </span>
        <span id="t1v_30" class="t s1_30">
          Харин хүүхдүүд та нар хүмүүсийн хийж буй ямар үйлдэл түүнд тохиолдож
          болох асуудлууд{" "}
        </span>
        <span id="t1w_30" class="t s1_30">
          маргаантай зүйлийг ямар хуулиар хэрхэн шийдвэрлэж болохыг сайн мэдэж
          байх нь чухал{" "}
        </span>
        <span id="t1x_30" class="t s1_30">
          ач холбогдолтой.{" "}
        </span>
      </div>
    </div>
  );
}
