import React from "react";
;

export default function ThirtyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p35" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_35{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_35{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_35{left:110px;bottom:1084px;letter-spacing:-0.11px;}
#t4_35{left:226px;bottom:1084px;letter-spacing:-0.14px;word-spacing:-0.75px;}
#t5_35{left:110px;bottom:1066px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t6_35{left:110px;bottom:1030px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t7_35{left:153px;bottom:1006px;}
#t8_35{left:180px;bottom:1006px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t9_35{left:153px;bottom:987px;}
#ta_35{left:180px;bottom:987px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_35{left:110px;bottom:945px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tc_35{left:153px;bottom:921px;}
#td_35{left:180px;bottom:921px;letter-spacing:-0.15px;word-spacing:0.05px;}
#te_35{left:153px;bottom:903px;}
#tf_35{left:180px;bottom:903px;letter-spacing:-0.12px;word-spacing:1.78px;}
#tg_35{left:633px;bottom:903px;letter-spacing:-0.22px;word-spacing:1.88px;}
#th_35{left:180px;bottom:885px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ti_35{left:153px;bottom:866px;}
#tj_35{left:180px;bottom:866px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_35{left:153px;bottom:848px;}
#tl_35{left:180px;bottom:848px;letter-spacing:-0.12px;word-spacing:-0.22px;}
#tm_35{left:180px;bottom:830px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tn_35{left:153px;bottom:812px;}
#to_35{left:180px;bottom:812px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tp_35{left:110px;bottom:770px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tq_35{left:110px;bottom:733px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tr_35{left:178px;bottom:733px;}
#ts_35{left:187px;bottom:733px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tt_35{left:110px;bottom:715px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tu_35{left:468px;bottom:724px;}
#tv_35{left:474px;bottom:715px;}
#tw_35{left:153px;bottom:691px;}
#tx_35{left:180px;bottom:691px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ty_35{left:153px;bottom:673px;}
#tz_35{left:180px;bottom:673px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t10_35{left:153px;bottom:655px;}
#t11_35{left:180px;bottom:655px;letter-spacing:-0.14px;word-spacing:8.86px;}
#t12_35{left:180px;bottom:637px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t13_35{left:153px;bottom:618px;}
#t14_35{left:180px;bottom:618px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t15_35{left:153px;bottom:600px;}
#t16_35{left:180px;bottom:600px;letter-spacing:-0.1px;word-spacing:-1.52px;}
#t17_35{left:180px;bottom:582px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t18_35{left:110px;bottom:558px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t19_35{left:153px;bottom:534px;}
#t1a_35{left:180px;bottom:534px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1b_35{left:153px;bottom:516px;}
#t1c_35{left:180px;bottom:516px;letter-spacing:-0.18px;word-spacing:1.04px;}
#t1d_35{left:180px;bottom:497px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1e_35{left:153px;bottom:479px;}
#t1f_35{left:180px;bottom:479px;letter-spacing:-0.11px;word-spacing:5px;}
#t1g_35{left:180px;bottom:461px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1h_35{left:153px;bottom:443px;}
#t1i_35{left:180px;bottom:443px;letter-spacing:-0.13px;word-spacing:5.75px;}
#t1j_35{left:180px;bottom:425px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1k_35{left:153px;bottom:407px;}
#t1l_35{left:180px;bottom:407px;letter-spacing:-0.15px;word-spacing:2.42px;}
#t1m_35{left:180px;bottom:389px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1n_35{left:153px;bottom:370px;}
#t1o_35{left:180px;bottom:370px;letter-spacing:-0.15px;word-spacing:-0.66px;}
#t1p_35{left:180px;bottom:352px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1q_35{left:153px;bottom:334px;}
#t1r_35{left:180px;bottom:334px;letter-spacing:-0.13px;word-spacing:5.26px;}
#t1s_35{left:180px;bottom:316px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1t_35{left:153px;bottom:298px;}
#t1u_35{left:180px;bottom:298px;letter-spacing:-0.16px;word-spacing:1.33px;}
#t1v_35{left:180px;bottom:280px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1w_35{left:203px;bottom:1135px;letter-spacing:0.11px;word-spacing:0.03px;}

.s0_35{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_35{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_35{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_35{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s4_35{font-size:17px;font-family:ArialMT_5-;color:#D9D9D9;}
.s5_35{font-size:10px;font-family:Arial-BoldMT_5u;color:#231F20;}
.s6_35{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s7_35{font-size:15px;font-family:ArialMT_5-;color:#231F20;}`}
        </style>
        <style id="fonts35" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_5u;
	src: url("fonts/Arial-BoldMT_5u.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg35" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="35/35.svg"
          type="image/svg+xml"
          id="pdf35"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_35" class="t s0_35">
          33{" "}
        </span>
        <span id="t2_35" class="t s1_35">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_35" class="t s2_35">
          3.4.1.Зорилго:{" "}
        </span>
        <span id="t4_35" class="t s1_35">
          Суралцагчдад замын хөдөлгөөний дүрмийн анхан шатны мэдлэг олгох, авто{" "}
        </span>
        <span id="t5_35" class="t s1_35">
          тээврийн осол гэмтлээс урьдчилан сэргийлэх анхан шатны ойлголттой
          болох{" "}
        </span>
        <span id="t6_35" class="t s2_35">
          3.4.2.Үндсэн ойлголт{" "}
        </span>
        <span id="t7_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t8_35" class="t s1_35">
          Хөдөлгөөний аюулгүй байдлын талаарх ойлголт{" "}
        </span>
        <span id="t9_35" class="t s1_35">
          -{" "}
        </span>
        <span id="ta_35" class="t s1_35">
          Хүүхдүүдэд замын хөдөлгөөний аюулгүй байдлын дүрмийг сурталчилах{" "}
        </span>
        <span id="tb_35" class="t s1_35">
          3.4.3.Үйл ажиллагаа:{" "}
        </span>
        <span id="tc_35" class="t s1_35">
          -{" "}
        </span>
        <span id="td_35" class="t s1_35">
          Сургуулиудад хөдөлгөөний аюулгүй байдлын кабинет байгуулах{" "}
        </span>
        <span id="te_35" class="t s1_35">
          -{" "}
        </span>
        <span id="tf_35" class="t s1_35">
          Жолооны курс, сургалтын төвүүдтэй хамтран ажиллах,{" "}
        </span>
        <span id="tg_35" class="t s1_35">
          мэдээлэл сурталчилгаа{" "}
        </span>
        <span id="th_35" class="t s1_35">
          хийлгэх зэрэг үйл ажиллагааг зохион байгуулах{" "}
        </span>
        <span id="ti_35" class="t s1_35">
          -{" "}
        </span>
        <span id="tj_35" class="t s1_35">
          Байгууллага хоорондын хамтын ажиллагааг өргөжүүлэх{" "}
        </span>
        <span id="tk_35" class="t s1_35">
          -{" "}
        </span>
        <span id="tl_35" class="t s1_35">
          Суралцагчдад замын хөдөлгөөний аюулгүй байдлын дүрмийг илэрхийлсэн
          зурагт{" "}
        </span>
        <span id="tm_35" class="t s1_35">
          үзүүлэн, санамжийг харуулж, ярилцах{" "}
        </span>
        <span id="tn_35" class="t s1_35">
          -{" "}
        </span>
        <span id="to_35" class="t s1_35">
          Зөв буруу үйлдлийг хэлэлцүүлэх дасгал ажлыг хийлгэх{" "}
        </span>
        <span id="tp_35" class="t s2_35">
          3.4.4.Арга зүй{" "}
        </span>
        <span id="tq_35" class="t s3_35">
          Дасгал 1
        </span>
        <span id="tr_35" class="t s4_35">
          .{" "}
        </span>
        <span id="ts_35" class="t s1_35">
          Дараах дүрмийг танилцуулж, заалт бүрийг нь сурагчидтай ярилцана.{" "}
        </span>
        <span id="tt_35" class="t s2_35">
          Явган зорчигч нь зорчих хэсгээр явж байхдаа{" "}
        </span>
        <span id="tu_35" class="t s5_35">
          2{" "}
        </span>
        <span id="tv_35" class="t s6_35">
          :{" "}
        </span>
        <span id="tw_35" class="t s1_35">
          -{" "}
        </span>
        <span id="tx_35" class="t s1_35">
          Утсаар ярих,{" "}
        </span>
        <span id="ty_35" class="t s1_35">
          -{" "}
        </span>
        <span id="tz_35" class="t s1_35">
          Гар утас болон түүнтэй ижил төстэй тоглоом, хэрэгсэл оролдож ширтэх,{" "}
        </span>
        <span id="t10_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t11_35" class="t s1_35">
          Зохицуулагчийн шүглийн чимээ, бусдын дуут дохио, анхааруулгыг сонсох{" "}
        </span>
        <span id="t12_35" class="t s1_35">
          боломжийг хязгаарласан чихэвч зүүх,{" "}
        </span>
        <span id="t13_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t14_35" class="t s1_35">
          Ном, сонин гэх мэт зүйл унших,{" "}
        </span>
        <span id="t15_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t16_35" class="t s1_35">
          Зорчих хэсэг дээр дугуйт тэшүүр, дугуйт хавтан болон түүнтэй ижил
          төстэй, биеийн{" "}
        </span>
        <span id="t17_35" class="t s1_35">
          тамир, спорт, тоглоомын зориулалттай хэрэгслээр явахыг хориглосон
          байна.{" "}
        </span>
        <span id="t18_35" class="t s1_35">
          Жолооч, зорчигчтой холбоотой:{" "}
        </span>
        <span id="t19_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1a_35" class="t s1_35">
          Хороолол дотор гүйцэж түрүүлэх үйлдэл хийхийг жолоочид хориглолоо.{" "}
        </span>
        <span id="t1b_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1c_35" class="t s1_35">
          Жолооч явган хүний гарцаар гарч байгаагаас гадна мөн гарахаар завдаж
          байгаа{" "}
        </span>
        <span id="t1d_35" class="t s1_35">
          явган зорчигчид зогсож зам тавьж өгөх үүрэг хүлээнэ.{" "}
        </span>
        <span id="t1e_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1f_35" class="t s1_35">
          Долоон нас хүрээгүй хүүхдийг насанд хүрсэн харгалзах хүнгүйгээр
          тээврийн{" "}
        </span>
        <span id="t1g_35" class="t s1_35">
          хэрэгсэлд үлдээхийг хоригложээ.{" "}
        </span>
        <span id="t1h_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1i_35" class="t s1_35">
          10 хүртэлх насны хүүхдийг тээвэрлэхдээ биеийн жин, өсөлтөд нь тохирсон{" "}
        </span>
        <span id="t1j_35" class="t s1_35">
          зориулалтын нэмэгдэл суудал буюу хамгаалах хэрэгсэл ашиглана.{" "}
        </span>
        <span id="t1k_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1l_35" class="t s1_35">
          Харааны бэрхшээлтэй хүний цагаан таяг нь харанхуй үед гэрэл ойлгодог
          буюу{" "}
        </span>
        <span id="t1m_35" class="t s1_35">
          гэрэлтдэг гадаргуутай байх шаардлагыг тусгажээ.{" "}
        </span>
        <span id="t1n_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1o_35" class="t s1_35">
          Явган зорчигчийн гэрлэн дохио харааны бэрхшээлтэй хүмүүст явган хүний
          гарцын{" "}
        </span>
        <span id="t1p_35" class="t s1_35">
          байршлыг мэдэгдэх нэмэгдэл дуут дохиотой байж болно.{" "}
        </span>
        <span id="t1q_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1r_35" class="t s1_35">
          Өөрийн үйлдлийг хянах чадваргүй хөгжлийн бэрхшээлтэй /сэтгэцийн
          өвчтэй/{" "}
        </span>
        <span id="t1s_35" class="t s1_35">
          хүнийг харгалзах хүнгүйгээр замын хөдөлгөөнд явганаар оролцуулахгүй.{" "}
        </span>
        <span id="t1t_35" class="t s1_35">
          -{" "}
        </span>
        <span id="t1u_35" class="t s1_35">
          Автобусаар зорчигчийн аюулгүй байдлыг хамгаалах үүднээс хаалгыг
          жолоочийн{" "}
        </span>
        <span id="t1v_35" class="t s1_35">
          суудлаас удирддаг бол гүйцэд зогсоогүй байхдаа хаалга нээхийг
          хориглолоо.{" "}
        </span>
        <span id="t1w_35" class="t s7_35">
          3.4. Замын хөдөлгөөний дүрмийг сурталчлах, таниулах{" "}
        </span>
      </div>
    </div>
  );
}
