import React from "react";
;

export default function Naim() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_8{left:34px;bottom:19px;}
#t2_8{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_8{left:117px;bottom:1141px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t4_8{left:117px;bottom:1123px;letter-spacing:-0.14px;word-spacing:-0.92px;}
#t5_8{left:117px;bottom:1105px;letter-spacing:-0.12px;word-spacing:-0.91px;}
#t6_8{left:117px;bottom:1087px;letter-spacing:-0.12px;word-spacing:-1.9px;}
#t7_8{left:117px;bottom:1068px;letter-spacing:-0.14px;word-spacing:0.54px;}
#t8_8{left:117px;bottom:1050px;letter-spacing:-0.13px;word-spacing:1.41px;}
#t9_8{left:117px;bottom:1032px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ta_8{left:117px;bottom:1014px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_8{left:160px;bottom:990px;}
#tc_8{left:187px;bottom:990px;letter-spacing:-0.18px;word-spacing:0.07px;}
#td_8{left:160px;bottom:972px;}
#te_8{left:187px;bottom:972px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tf_8{left:160px;bottom:953px;}
#tg_8{left:187px;bottom:953px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_8{left:160px;bottom:935px;}
#ti_8{left:187px;bottom:935px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_8{left:117px;bottom:911px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tk_8{left:160px;bottom:887px;}
#tl_8{left:187px;bottom:887px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tm_8{left:160px;bottom:869px;}
#tn_8{left:187px;bottom:869px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_8{left:160px;bottom:851px;}
#tp_8{left:187px;bottom:851px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tq_8{left:160px;bottom:832px;}
#tr_8{left:187px;bottom:832px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ts_8{left:160px;bottom:814px;}
#tt_8{left:187px;bottom:814px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tu_8{left:160px;bottom:796px;}
#tv_8{left:187px;bottom:796px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tw_8{left:117px;bottom:772px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tx_8{left:160px;bottom:748px;}
#ty_8{left:187px;bottom:748px;letter-spacing:-0.19px;word-spacing:0.1px;}
#tz_8{left:160px;bottom:730px;}
#t10_8{left:187px;bottom:730px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t11_8{left:160px;bottom:711px;}
#t12_8{left:187px;bottom:711px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t13_8{left:160px;bottom:693px;}
#t14_8{left:187px;bottom:693px;letter-spacing:-0.12px;word-spacing:0.01px;}
#t15_8{left:160px;bottom:675px;}
#t16_8{left:187px;bottom:675px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t17_8{left:160px;bottom:657px;}
#t18_8{left:187px;bottom:657px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t19_8{left:117px;bottom:633px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1a_8{left:160px;bottom:608px;}
#t1b_8{left:187px;bottom:608px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1c_8{left:160px;bottom:590px;}
#t1d_8{left:187px;bottom:590px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1e_8{left:160px;bottom:572px;}
#t1f_8{left:187px;bottom:572px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1g_8{left:160px;bottom:554px;}
#t1h_8{left:187px;bottom:554px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1i_8{left:160px;bottom:536px;}
#t1j_8{left:187px;bottom:536px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1k_8{left:160px;bottom:518px;}
#t1l_8{left:187px;bottom:518px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1m_8{left:160px;bottom:500px;}
#t1n_8{left:187px;bottom:500px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1o_8{left:160px;bottom:482px;}
#t1p_8{left:187px;bottom:482px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t1q_8{left:160px;bottom:463px;}
#t1r_8{left:187px;bottom:463px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1s_8{left:160px;bottom:445px;}
#t1t_8{left:187px;bottom:445px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1u_8{left:160px;bottom:427px;}
#t1v_8{left:187px;bottom:427px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1w_8{left:117px;bottom:403px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1x_8{left:160px;bottom:379px;}
#t1y_8{left:187px;bottom:379px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1z_8{left:160px;bottom:361px;}
#t20_8{left:187px;bottom:361px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t21_8{left:160px;bottom:342px;}
#t22_8{left:187px;bottom:342px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t23_8{left:117px;bottom:318px;letter-spacing:-0.15px;word-spacing:3.96px;}
#t24_8{left:117px;bottom:300px;letter-spacing:-0.15px;word-spacing:6.25px;}
#t25_8{left:117px;bottom:282px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_8{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_8{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_8{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}`}
        </style>
        <style id="fonts8" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg8" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="8/8.svg"
          type="image/svg+xml"
          id="pdf8"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_8" class="t s0_8">
          6{" "}
        </span>
        <span id="t2_8" class="t s1_8">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_8" class="t s2_8">
          6.1. Хичээлээс гадуурх сургалтаар хөгжүүлэх чадвар:{" "}
        </span>
        <span id="t4_8" class="t s1_8">
          Хичээлээс гадуурх сургалтын ажил нь өнөөдрийн сурагчид ба ирээдүйн
          иргэдийн эзэмшвэл{" "}
        </span>
        <span id="t5_8" class="t s1_8">
          зохих 1) шүүмжлэлт болон шинэлэг сэтгэлгээ, 2) хүмүүс хоорондын
          харилцаа, 3) хувь хүний{" "}
        </span>
        <span id="t6_8" class="t s1_8">
          чадвар, 4) дэлхийн иргэн, 5) мэдээллийн технологийн хэрэглээ гэсэн 5
          хүрээ түүнд харгалзах{" "}
        </span>
        <span id="t7_8" class="t s1_8">
          чадваруудын хөгжлийг дэмжихэд чиглэнэ. (Уг чадамж, харгалзах чадварыг
          ЮНЕСКО-гийн{" "}
        </span>
        <span id="t8_8" class="t s1_8">
          ERI-Net судалгааны төвөөс 2016 онд танилцуулсан байна). Эдгээрийг
          чадвар тус бүрээр{" "}
        </span>
        <span id="t9_8" class="t s1_8">
          дэлгэрүүлэн үзүүлбэл:{" "}
        </span>
        <span id="ta_8" class="t s1_8">
          Шүүмжлэлт болон шинэлэг сэтгэлгээний чадвар{" "}
        </span>
        <span id="tb_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tc_8" class="t s1_8">
          Бүтээлч чадвар{" "}
        </span>
        <span id="td_8" class="t s1_8">
          -{" "}
        </span>
        <span id="te_8" class="t s1_8">
          Зохион бүтээх чадвар{" "}
        </span>
        <span id="tf_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tg_8" class="t s1_8">
          Эргэцүүлэн бодох чадвар{" "}
        </span>
        <span id="th_8" class="t s1_8">
          -{" "}
        </span>
        <span id="ti_8" class="t s1_8">
          Шийдвэр гаргах чадвар{" "}
        </span>
        <span id="tj_8" class="t s1_8">
          Хүмүүс хоорондын харилцааны чадвар{" "}
        </span>
        <span id="tk_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tl_8" class="t s1_8">
          Харилцааны чадвар{" "}
        </span>
        <span id="tm_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tn_8" class="t s1_8">
          Зохион байгуулах чадвар{" "}
        </span>
        <span id="to_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tp_8" class="t s1_8">
          Багаар ажиллах чадвар{" "}
        </span>
        <span id="tq_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tr_8" class="t s1_8">
          Хамтарч ажиллах чадвар{" "}
        </span>
        <span id="ts_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tt_8" class="t s1_8">
          Нийтэч байх чадвар{" "}
        </span>
        <span id="tu_8" class="t s1_8">
          -{" "}
        </span>
        <span id="tv_8" class="t s1_8">
          Өөрийгөө бусдын оронд тавьдаг байх, бусдыг өрөвдөх чадвар{" "}
        </span>
        <span id="tw_8" class="t s1_8">
          Хувь хүний чадвар{" "}
        </span>
        <span id="tx_8" class="t s1_8">
          -{" "}
        </span>
        <span id="ty_8" class="t s1_8">
          Хувийн сахилга бат{" "}
        </span>
        <span id="tz_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t10_8" class="t s1_8">
          Бие даан суралцах{" "}
        </span>
        <span id="t11_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t12_8" class="t s1_8">
          Дасан зохицох{" "}
        </span>
        <span id="t13_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t14_8" class="t s1_8">
          Өөрийгөө мэддэг{" "}
        </span>
        <span id="t15_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t16_8" class="t s1_8">
          Шаргуу байдал{" "}
        </span>
        <span id="t17_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t18_8" class="t s1_8">
          Өөрийгөө хүндэтгэх{" "}
        </span>
        <span id="t19_8" class="t s1_8">
          Дэлхийн иргэний чадвар{" "}
        </span>
        <span id="t1a_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1b_8" class="t s1_8">
          “Глобал/дэлхийн иргэний” талаар мэдлэг ойлголт{" "}
        </span>
        <span id="t1c_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1d_8" class="t s1_8">
          Хүлээцтэй байх{" "}
        </span>
        <span id="t1e_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1f_8" class="t s1_8">
          Нээлттэй байх{" "}
        </span>
        <span id="t1g_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1h_8" class="t s1_8">
          Хариуцлагатай байх{" "}
        </span>
        <span id="t1i_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1j_8" class="t s1_8">
          Ялгаатай байдалд хүндэтгэлтэй хандах{" "}
        </span>
        <span id="t1k_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1l_8" class="t s1_8">
          Ёс зүйтэйг ойлгох{" "}
        </span>
        <span id="t1m_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1n_8" class="t s1_8">
          Олон талт соёлыг ойлгох{" "}
        </span>
        <span id="t1o_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1p_8" class="t s1_8">
          Зөрчилдөөнийг шийдвэрлэх{" "}
        </span>
        <span id="t1q_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1r_8" class="t s1_8">
          Чөлөөт оролцоо{" "}
        </span>
        <span id="t1s_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1t_8" class="t s1_8">
          Хүрээлэн буй орчиндоо хүндэтгэлтэй хандах{" "}
        </span>
        <span id="t1u_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1v_8" class="t s1_8">
          Үндэсний онцлогоо мэдэх{" "}
        </span>
        <span id="t1w_8" class="t s1_8">
          Мэдээллийн технологийн хэрэглээний чадвар{" "}
        </span>
        <span id="t1x_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t1y_8" class="t s1_8">
          Мэдээлэл харилцааны технологиор дамжуулан мэдээлэл олж авах{" "}
        </span>
        <span id="t1z_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t20_8" class="t s1_8">
          Мэдээлэлд дүн шинжилгээ хийх{" "}
        </span>
        <span id="t21_8" class="t s1_8">
          -{" "}
        </span>
        <span id="t22_8" class="t s1_8">
          Мэдээлийн технологийг ёс зүйтэй ашиглах{" "}
        </span>
        <span id="t23_8" class="t s1_8">
          21-р зууны эдгээр чадварыг эзэмшүүлэхийн тулд багш нар хичээл,
          хичээлээс гадуурх{" "}
        </span>
        <span id="t24_8" class="t s1_8">
          сургалтын ажлаар практикт ашиглаж болох арга, хэрэгсэл, сургалтын
          стратеги, үйл{" "}
        </span>
        <span id="t25_8" class="t s1_8">
          ажиллагааг түлхүү сонгох хэрэгтэй юм.{" "}
        </span>
      </div>
    </div>
  );
}
