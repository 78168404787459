import React from "react";
;

export default function FiveTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_52{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_52{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_52{left:153px;bottom:1159px;}
#t4_52{left:180px;bottom:1159px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t5_52{left:153px;bottom:1141px;}
#t6_52{left:180px;bottom:1141px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t7_52{left:153px;bottom:1123px;}
#t8_52{left:180px;bottom:1123px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_52{left:153px;bottom:1105px;}
#ta_52{left:180px;bottom:1105px;letter-spacing:-0.14px;word-spacing:5.73px;}
#tb_52{left:180px;bottom:1087px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tc_52{left:153px;bottom:1069px;}
#td_52{left:180px;bottom:1069px;letter-spacing:-0.08px;word-spacing:-0.01px;}
#te_52{left:153px;bottom:1050px;}
#tf_52{left:180px;bottom:1050px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tg_52{left:153px;bottom:1032px;}
#th_52{left:180px;bottom:1032px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ti_52{left:110px;bottom:990px;letter-spacing:-0.2px;word-spacing:0.09px;}
#tj_52{left:110px;bottom:972px;letter-spacing:-0.13px;word-spacing:-1.64px;}
#tk_52{left:110px;bottom:954px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tl_52{left:110px;bottom:917px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tm_52{left:153px;bottom:893px;}
#tn_52{left:180px;bottom:893px;letter-spacing:-0.16px;word-spacing:0.9px;}
#to_52{left:180px;bottom:875px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tp_52{left:153px;bottom:857px;}
#tq_52{left:180px;bottom:857px;letter-spacing:-0.15px;}
#tr_52{left:266px;bottom:857px;letter-spacing:-0.17px;word-spacing:0.08px;}
#ts_52{left:153px;bottom:839px;}
#tt_52{left:180px;bottom:839px;letter-spacing:-0.14px;word-spacing:-1.77px;}
#tu_52{left:603px;bottom:839px;letter-spacing:-0.11px;word-spacing:-1.8px;}
#tv_52{left:153px;bottom:821px;}
#tw_52{left:180px;bottom:821px;letter-spacing:-0.1px;}
#tx_52{left:268px;bottom:821px;letter-spacing:-0.2px;word-spacing:0.11px;}
#ty_52{left:110px;bottom:778px;}
#tz_52{left:120px;bottom:778px;letter-spacing:-0.14px;word-spacing:0.06px;}
#t10_52{left:193px;bottom:778px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t11_52{left:110px;bottom:397px;letter-spacing:-0.15px;word-spacing:1.58px;}
#t12_52{left:110px;bottom:379px;letter-spacing:-0.12px;word-spacing:0.04px;}
#t13_52{left:110px;bottom:361px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_52{left:153px;bottom:337px;}
#t15_52{left:180px;bottom:337px;letter-spacing:-0.14px;word-spacing:-1.54px;}
#t16_52{left:180px;bottom:319px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t17_52{left:110px;bottom:294px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t18_52{left:153px;bottom:270px;}
#t19_52{left:180px;bottom:270px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1a_52{left:153px;bottom:252px;}
#t1b_52{left:180px;bottom:252px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1c_52{left:110px;bottom:228px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1d_52{left:153px;bottom:204px;}
#t1e_52{left:180px;bottom:204px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1f_52{left:153px;bottom:185px;}
#t1g_52{left:180px;bottom:185px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1h_52{left:153px;bottom:167px;}
#t1i_52{left:180px;bottom:167px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1j_52{left:153px;bottom:149px;}
#t1k_52{left:180px;bottom:149px;letter-spacing:-0.17px;word-spacing:0.07px;}

.s0_52{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_52{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_52{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_52{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s4_52{font-size:17px;font-family:Arial-ItalicMT_6y;color:#375FB2;}`}
        </style>

        <style id="fonts52" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg52" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="52/52.svg"
          type="image/svg+xml"
          id="pdf52"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_52" class="t s0_52">
          50{" "}
        </span>
        <span id="t2_52" class="t s1_52">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t4_52" class="t s1_52">
          Орчин болон сандал ширээний байрлал нь тохиромжтой байх{" "}
        </span>
        <span id="t5_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t6_52" class="t s1_52">
          Хүүхдийн өмнөөс хариулахгүй эсвэл өгүүлбэрийг нь дуусгахгүй
          яаруулахгүй байх{" "}
        </span>
        <span id="t7_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t8_52" class="t s1_52">
          Хүүхдүүдийг түр зуур ярихаа больсон байхад хэт их ярихгүй байх{" "}
        </span>
        <span id="t9_52" class="t s1_52">
          -{" "}
        </span>
        <span id="ta_52" class="t s1_52">
          Хүүхдүүд дохио зангаагаар ярилцлагад саад болж магадгүй учраас тэднийг{" "}
        </span>
        <span id="tb_52" class="t s1_52">
          ажиглах, өөрийнхөө үйл хөдлөлийг анхаарах{" "}
        </span>
        <span id="tc_52" class="t s1_52">
          -{" "}
        </span>
        <span id="td_52" class="t s1_52">
          Цагт хяналт тавих{" "}
        </span>
        <span id="te_52" class="t s1_52">
          -{" "}
        </span>
        <span id="tf_52" class="t s1_52">
          Дуусгах хамгийн тохиромжтой цагийг тодруулах{" "}
        </span>
        <span id="tg_52" class="t s1_52">
          -{" "}
        </span>
        <span id="th_52" class="t s1_52">
          Хэлэлцүүлгийг ерөнхийд нь дүгнэх эсвэл хаах{" "}
        </span>
        <span id="ti_52" class="t s2_52">
          Суурь боловсролд{" "}
        </span>
        <span id="tj_52" class="t s1_52">
          Сурагчид байгалийн гаралтай хоол, хүнсний талаар судалгаа хийж, түүний
          үр дүнд үндэслэн{" "}
        </span>
        <span id="tk_52" class="t s1_52">
          сургуулийн цайны газрын болон үдийн хоол хөтөлбөрийн цэсэнд өөрчлөлт
          оруулдаг байх{" "}
        </span>
        <span id="tl_52" class="t s3_52">
          Судалгаа хийх арга зүй{" "}
        </span>
        <span id="tm_52" class="t s1_52">
          →{" "}
        </span>
        <span id="tn_52" class="t s1_52">
          Судалгаа хэрэглээ, зорилго, шалтгаан, мэдээлэл цуглуулах арга, цаг
          хуагцаа, үр{" "}
        </span>
        <span id="to_52" class="t s1_52">
          дүнгээсээ хамааран харилцан адилгүй байна. Судалгааг{" "}
        </span>
        <span id="tp_52" class="t s1_52">
          →{" "}
        </span>
        <span id="tq_52" class="t s2_52">
          зорилгоор{" "}
        </span>
        <span id="tr_52" class="t s1_52">
          нь нээх, тодорхойлох, тайлбарлах;{" "}
        </span>
        <span id="ts_52" class="t s1_52">
          →{" "}
        </span>
        <span id="tt_52" class="t s2_52">
          шалтгаан, мэдээлэл цуглуулах арга, цаг хугацаагаар{" "}
        </span>
        <span id="tu_52" class="t s1_52">
          нь энгийн, шинжлэх ухааны;{" "}
        </span>
        <span id="tv_52" class="t s1_52">
          →{" "}
        </span>
        <span id="tw_52" class="t s2_52">
          үр дүнгээр{" "}
        </span>
        <span id="tx_52" class="t s1_52">
          нь тоон ба чанарын судалгаа гэж хуваадаг.{" "}
        </span>
        <span id="ty_52" class="t s2_52">
          З
        </span>
        <span id="tz_52" class="t s4_52">
          агвар 2:{" "}
        </span>
        <span id="t10_52" class="t s4_52">
          Судалгаа хийх үйл ажиллагааны төлөвлөлт{" "}
        </span>
        <span id="t11_52" class="t s1_52">
          Судалгааны үр дүнг тайлан болгосны дараа бусдад түгээхдээ дараах
          аргуудыг хэрэглэж{" "}
        </span>
        <span id="t12_52" class="t s1_52">
          болно. Үүнд:{" "}
        </span>
        <span id="t13_52" class="t s2_52">
          Тайлбарлах, тайлагнах аргууд{" "}
        </span>
        <span id="t14_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t15_52" class="t s1_52">
          Судалгааны ажлын гол дүнг тоймлон бичсэн хураангуйг танилцуулах
          (сонирхолтой{" "}
        </span>
        <span id="t16_52" class="t s1_52">
          товчхон бичнэ){" "}
        </span>
        <span id="t17_52" class="t s1_52">
          Бодит байдлыг бичлэг хэлбэрээр танилцуулах{" "}
        </span>
        <span id="t18_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t19_52" class="t s1_52">
          Видео бичлэг хийж тараана.{" "}
        </span>
        <span id="t1a_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t1b_52" class="t s1_52">
          Ухуулга, сурталчилгаа, санамж байдлаар хүргэнэ.{" "}
        </span>
        <span id="t1c_52" class="t s1_52">
          Уулзалт, олон нийтийн хурал дээр танилцуулах{" "}
        </span>
        <span id="t1d_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t1e_52" class="t s1_52">
          Эцэг эхийн өдөрлөг, байгууллагын хуралд оролцогчдод танилцуулна.{" "}
        </span>
        <span id="t1f_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t1g_52" class="t s1_52">
          Оролцогчид ямар ажил хийж болох талаар санал, зөвлөмжийг гаргаж болно.{" "}
        </span>
        <span id="t1h_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t1i_52" class="t s1_52">
          Өдөрлөг, үдэшлэгийн хөтөлбөрт тусган хөгжилтэй байдлаар хүргэнэ.{" "}
        </span>
        <span id="t1j_52" class="t s1_52">
          -{" "}
        </span>
        <span id="t1k_52" class="t s1_52">
          Хэвлэл мэдээлэлд нийтлэлээр хүргэж болно.{" "}
        </span>
      </div>
    </div>
  );
}
