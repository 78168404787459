import React from "react";
;

export default function Seven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_7{left:844px;bottom:21px;}
#t2_7{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_7{left:110px;bottom:1157px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t4_7{left:153px;bottom:1133px;}
#t5_7{left:180px;bottom:1133px;letter-spacing:-0.16px;word-spacing:3.15px;}
#t6_7{left:180px;bottom:1114px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t7_7{left:153px;bottom:1096px;}
#t8_7{left:180px;bottom:1096px;letter-spacing:-0.15px;word-spacing:4.69px;}
#t9_7{left:752px;bottom:1096px;letter-spacing:-0.06px;}
#ta_7{left:180px;bottom:1078px;letter-spacing:-0.13px;}
#tb_7{left:153px;bottom:1060px;}
#tc_7{left:180px;bottom:1060px;letter-spacing:-0.15px;word-spacing:1.34px;}
#td_7{left:180px;bottom:1042px;letter-spacing:-0.13px;word-spacing:0.04px;}
#te_7{left:153px;bottom:1024px;}
#tf_7{left:180px;bottom:1024px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tg_7{left:110px;bottom:999px;letter-spacing:-0.1px;}
#th_7{left:153px;bottom:975px;}
#ti_7{left:180px;bottom:975px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tj_7{left:153px;bottom:957px;}
#tk_7{left:180px;bottom:957px;letter-spacing:-0.16px;word-spacing:4.98px;}
#tl_7{left:180px;bottom:939px;letter-spacing:-0.1px;}
#tm_7{left:153px;bottom:921px;}
#tn_7{left:180px;bottom:921px;letter-spacing:-0.14px;word-spacing:0.04px;}
#to_7{left:110px;bottom:897px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tp_7{left:153px;bottom:872px;}
#tq_7{left:180px;bottom:872px;letter-spacing:-0.16px;word-spacing:3.19px;}
#tr_7{left:180px;bottom:854px;letter-spacing:-0.1px;}
#ts_7{left:153px;bottom:836px;}
#tt_7{left:180px;bottom:836px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tu_7{left:110px;bottom:794px;letter-spacing:-0.24px;word-spacing:0.12px;}
#tv_7{left:110px;bottom:776px;letter-spacing:-0.14px;}
#tw_7{left:110px;bottom:757px;letter-spacing:-0.15px;word-spacing:-1.54px;}
#tx_7{left:110px;bottom:739px;letter-spacing:-0.13px;word-spacing:2.88px;}
#ty_7{left:110px;bottom:721px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tz_7{left:110px;bottom:703px;letter-spacing:-0.13px;word-spacing:-1.1px;}
#t10_7{left:110px;bottom:685px;letter-spacing:-0.1px;}
#t11_7{left:153px;bottom:667px;}
#t12_7{left:180px;bottom:667px;letter-spacing:-0.17px;word-spacing:9.19px;}
#t13_7{left:180px;bottom:649px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t14_7{left:153px;bottom:630px;}
#t15_7{left:180px;bottom:630px;letter-spacing:-0.14px;word-spacing:-0.48px;}
#t16_7{left:180px;bottom:612px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t17_7{left:153px;bottom:594px;}
#t18_7{left:180px;bottom:594px;letter-spacing:-0.17px;word-spacing:4.4px;}
#t19_7{left:180px;bottom:576px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1a_7{left:153px;bottom:558px;}
#t1b_7{left:180px;bottom:558px;letter-spacing:-0.11px;word-spacing:1.62px;}
#t1c_7{left:180px;bottom:540px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1d_7{left:110px;bottom:522px;letter-spacing:-0.15px;word-spacing:-0.24px;}
#t1e_7{left:110px;bottom:504px;letter-spacing:-0.14px;word-spacing:-0.98px;}
#t1f_7{left:110px;bottom:485px;letter-spacing:-0.18px;}
#t1g_7{left:110px;bottom:467px;letter-spacing:-0.15px;word-spacing:2.5px;}
#t1h_7{left:110px;bottom:449px;letter-spacing:-0.17px;word-spacing:7.39px;}
#t1i_7{left:110px;bottom:431px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1j_7{left:110px;bottom:413px;letter-spacing:-0.19px;word-spacing:-0.04px;}
#t1k_7{left:153px;bottom:395px;}
#t1l_7{left:180px;bottom:395px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1m_7{left:153px;bottom:377px;}
#t1n_7{left:180px;bottom:377px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1o_7{left:153px;bottom:358px;}
#t1p_7{left:180px;bottom:358px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1q_7{left:110px;bottom:340px;letter-spacing:-0.16px;word-spacing:2.5px;}
#t1r_7{left:110px;bottom:322px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1s_7{left:153px;bottom:304px;}
#t1t_7{left:180px;bottom:304px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1u_7{left:153px;bottom:286px;}
#t1v_7{left:180px;bottom:286px;letter-spacing:-0.12px;}
#t1w_7{left:271px;bottom:286px;letter-spacing:-0.2px;word-spacing:0.11px;}
#t1x_7{left:153px;bottom:268px;}
#t1y_7{left:180px;bottom:268px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1z_7{left:153px;bottom:250px;}
#t20_7{left:180px;bottom:250px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t21_7{left:110px;bottom:231px;letter-spacing:-0.12px;word-spacing:-1.3px;}
#t22_7{left:153px;bottom:213px;}
#t23_7{left:180px;bottom:213px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t24_7{left:153px;bottom:195px;}
#t25_7{left:180px;bottom:195px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t26_7{left:153px;bottom:177px;}
#t27_7{left:180px;bottom:177px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_7{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_7{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_7{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_7{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>
        <style id="fonts7" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg7" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="7/7.svg"
          type="image/svg+xml"
          id="pdf4"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_7" class="t s0_7">
          5{" "}
        </span>
        <span id="t2_7" class="t s1_7">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_7" class="t s1_7">
          Зорилт 6. Суралцагчдын нийгэм, олон нийтийн үйл ажиллагааг дэмжих{" "}
        </span>
        <span id="t4_7" class="t s1_7">
          -{" "}
        </span>
        <span id="t5_7" class="t s1_7">
          Хүүхдийн эрх зүйн мэдлэг, соёлыг дээшлүүлэх, тэдний эрх, ашиг
          сонирхолтой{" "}
        </span>
        <span id="t6_7" class="t s1_7">
          холбоотой асуудлаар шийдвэр гаргахад чиглэсэн ажлыг эрчимжүүлэх{" "}
        </span>
        <span id="t7_7" class="t s1_7">
          -{" "}
        </span>
        <span id="t8_7" class="t s1_7">
          Сурагчдыг нийгэмд тустай үйл ажиллагаанд бүтээлчээр оролцоход{" "}
        </span>
        <span id="t9_7" class="t s1_7">
          дэмжлэг{" "}
        </span>
        <span id="ta_7" class="t s1_7">
          үзүүлэх{" "}
        </span>
        <span id="tb_7" class="t s1_7">
          -{" "}
        </span>
        <span id="tc_7" class="t s1_7">
          Боловсролын байгууллагуудтай хүмүүжлийн асуудлаар хамтран ажилладаг
          эцэг{" "}
        </span>
        <span id="td_7" class="t s1_7">
          эхийн болон{" "}
        </span>
        <span id="te_7" class="t s1_7">
          -{" "}
        </span>
        <span id="tf_7" class="t s1_7">
          Олон нийтийн бусад байгууллагын ажиллагааг хөхүүлэн дэмжих{" "}
        </span>
        <span id="tg_7" class="t s1_7">
          Зорилт 7. Эх оронч болон хөдөлмөрийн хүмүүжлийг дэмжих{" "}
        </span>
        <span id="th_7" class="t s1_7">
          -{" "}
        </span>
        <span id="ti_7" class="t s1_7">
          Хүүхдэд эх оронч хүмүүжил олгох хөтөлбөрүүдийг хэрэгжүүлэх{" "}
        </span>
        <span id="tj_7" class="t s1_7">
          -{" "}
        </span>
        <span id="tk_7" class="t s1_7">
          Суралцагчдад суралцах чиглэл, мэргэжлээ тодорхойлж, ухамсартай
          сонгоход{" "}
        </span>
        <span id="tl_7" class="t s1_7">
          дэмжлэг үзүүлэх{" "}
        </span>
        <span id="tm_7" class="t s1_7">
          -{" "}
        </span>
        <span id="tn_7" class="t s1_7">
          Ажил мэргэжлийн чиг баримжаа олгох үйл ажиллагааг хэрэгжүүлэх{" "}
        </span>
        <span id="to_7" class="t s1_7">
          Зорилт 8. Байгаль, экологийн хүмүүжлийг төлөвшүүлэх{" "}
        </span>
        <span id="tp_7" class="t s1_7">
          -{" "}
        </span>
        <span id="tq_7" class="t s1_7">
          Хүүхдэд байгаль дэлхий, эх орон газар нутгаа хайрлах хандлага
          төлөвшүүлэх{" "}
        </span>
        <span id="tr_7" class="t s1_7">
          ажлыг дэмжих, хэрэгжүүлэх{" "}
        </span>
        <span id="ts_7" class="t s1_7">
          -{" "}
        </span>
        <span id="tt_7" class="t s1_7">
          ТХБ-ын үзэл санааг суралцагчдад таниулах, сурталчилах{" "}
        </span>
        <span id="tu_7" class="t s2_7">
          ЗУРГАА. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫГ ЗОХИОН БАЙГУУЛАХ{" "}
        </span>
        <span id="tv_7" class="t s2_7">
          МЕНЕЖЕМЕНТ{" "}
        </span>
        <span id="tw_7" class="t s1_7">
          ЕБС-ийн хичээлээс гадуурх сургалтын үйл ажиллагааны бүх хэлбэр, чиглэл
          нь боловсролын{" "}
        </span>
        <span id="tx_7" class="t s1_7">
          үр дүн дээр төвлөрсөн байна. Боловсролын үр дүн нь тухайн үйл
          ажиллагаанд хүүхэд{" "}
        </span>
        <span id="ty_7" class="t s1_7">
          оролцох замаар ёс суртахуунтай хөгжих явдал юм.{" "}
        </span>
        <span id="tz_7" class="t s1_7">
          Хичээлээс гадуурх үйл ажиллагааг зохион байгуулахад дараах зүйлсийг
          анхаарах хэрэгтэй.{" "}
        </span>
        <span id="t10_7" class="t s1_7">
          Үүнд:{" "}
        </span>
        <span id="t11_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t12_7" class="t s1_7">
          Сэдэв, чиглэл, агуулга, хэлбэрийг сонгох, төлөвлөх, зохион
          байгуулахдаа{" "}
        </span>
        <span id="t13_7" class="t s1_7">
          суралцагчдын оролцоонд тулгуурлах{" "}
        </span>
        <span id="t14_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t15_7" class="t s1_7">
          Ажлын үр дүнг үнэлэхдээ тооноос илүү чанарыг, тухайлбал суралцагчдын
          авьяас,{" "}
        </span>
        <span id="t16_7" class="t s1_7">
          ур чадварт гарсан өөрчлөлтөд тулгуурлах{" "}
        </span>
        <span id="t17_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t18_7" class="t s1_7">
          Тайлагнах, үнэлэх арга хэлбэр нь нийтэд ил тод байхаас гадна
          суралцагчид{" "}
        </span>
        <span id="t19_7" class="t s1_7">
          өөрсдөө оролцож, ахиц дэвшлээ мэдэрч, урам авахаар байх{" "}
        </span>
        <span id="t1a_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1b_7" class="t s1_7">
          Суралцагчид бие дааж ажиллах, чадварын төлөвшил, нийгэмшилд эерэг
          нөлөө{" "}
        </span>
        <span id="t1c_7" class="t s1_7">
          үзүүлэх, ажил мэргэжлийн баримжаа, арга туршлага хуримтлуулахад
          анхаарах{" "}
        </span>
        <span id="t1d_7" class="t s1_7">
          Хичээлээс гадуурх сургалтын ажил нь боловсролын үйлчилгээний хавсарга,
          туслах хэлбэр{" "}
        </span>
        <span id="t1e_7" class="t s1_7">
          биш, харин ч тэрхүү үйлчилгээг өөрчлөх, шинэчлэх, хөгжүүлэх боломжийг
          нэмэгдүүлж өгдөг{" "}
        </span>
        <span id="t1f_7" class="t s1_7">
          байна.{" "}
        </span>
        <span id="t1g_7" class="t s1_7">
          Иймээс сургууль, суралцагчдын зорилгыг бодит үр дүн болгоход хичээлээс
          гадуурх үйл{" "}
        </span>
        <span id="t1h_7" class="t s1_7">
          ажиллагааг судлагдахуун болоод бусад үйл ажиллагаа, олон нийтийн
          байгууллага,{" "}
        </span>
        <span id="t1i_7" class="t s1_7">
          хөтөлбөрүүдтэй нягт уялдаатай холбож үзэн хэрэгжүүлэх шаардлагатай.
          Тухайлбал,{" "}
        </span>
        <span id="t1j_7" class="t s1_7">
          Хичээлээс гадуурх үйл ажиллагаа нь боловсролын үр дүнд хүрэхэд
          чиглэгддэг. Тухайлбал,{" "}
        </span>
        <span id="t1k_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1l_7" class="t s1_7">
          суралцагчдын нийгмийн туршлагыг олж авах{" "}
        </span>
        <span id="t1m_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1n_7" class="t s1_7">
          нийгмийн суурь үнэт зүйлсэд эерэг хандлагыг төлөвшүүлэх{" "}
        </span>
        <span id="t1o_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1p_7" class="t s1_7">
          сурагчид нийгмийн бие даасан үйл ажиллагааны туршлагыг олж авах{" "}
        </span>
        <span id="t1q_7" class="t s1_7">
          Үүнээс гадна сургуульд хичээлээс гадуурх үйл ажиллагаа удирдах багш
          нарт хэд хэдэн{" "}
        </span>
        <span id="t1r_7" class="t s1_7">
          маш чухал ажлыг шийдвэрлэх боломжийг олгодог байх хэрэгтэй. Тухайлбал,{" "}
        </span>
        <span id="t1s_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1t_7" class="t s1_7">
          сургуульд хүүхдийн таатай дасан зохицох нөхцлийг бүрдүүлэх{" "}
        </span>
        <span id="t1u_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1v_7" class="t s1_7">
          сурагчдын{" "}
        </span>
        <span id="t1w_7" class="t s1_7">
          ачааллыг оновчтой болгох{" "}
        </span>
        <span id="t1x_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t1y_7" class="t s1_7">
          хүүхдийн хөгжлийн нөхцөлийг сайжруулах{" "}
        </span>
        <span id="t1z_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t20_7" class="t s1_7">
          хүүхдийн нас, хувь хүний онцлогийг харгалзан үзэх{" "}
        </span>
        <span id="t21_7" class="t s1_7">
          Хичээлээс гадуурх үйл ажиллагааг дараах хөтөлбөрүүдийн хүрээнд явуулах
          хэрэгтэй. Үүнд:{" "}
        </span>
        <span id="t22_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t23_7" class="t s1_7">
          боловсролын байгууллагын сургалтын хөтөлбөр{" "}
        </span>
        <span id="t24_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t25_7" class="t s1_7">
          ерөнхий боловсролын сургалтын байгууллагын нэмэлт хөтөлбөр{" "}
        </span>
        <span id="t26_7" class="t s3_7">
          •{" "}
        </span>
        <span id="t27_7" class="t s1_7">
          хүүхдийн байгууллагуудын боловсролын хөтөлбөрүүд{" "}
        </span>
      </div>
    </div>
  );
}
