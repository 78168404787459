import React from "react";
;

export default function FourSeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`#t1_47{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_47{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_47{left:153px;bottom:1158px;}
#t4_47{left:180px;bottom:1158px;letter-spacing:-0.15px;word-spacing:-1.52px;}
#t5_47{left:180px;bottom:1140px;letter-spacing:-0.16px;word-spacing:7.21px;}
#t6_47{left:180px;bottom:1122px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t7_47{left:153px;bottom:1104px;}
#t8_47{left:180px;bottom:1104px;letter-spacing:-0.14px;word-spacing:2.8px;}
#t9_47{left:180px;bottom:1086px;letter-spacing:-0.14px;word-spacing:-0.87px;}
#ta_47{left:180px;bottom:1067px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tb_47{left:153px;bottom:1049px;}
#tc_47{left:180px;bottom:1049px;letter-spacing:-0.12px;word-spacing:1.61px;}
#td_47{left:180px;bottom:1031px;letter-spacing:-0.17px;word-spacing:-0.71px;}
#te_47{left:153px;bottom:1013px;}
#tf_47{left:180px;bottom:1013px;letter-spacing:-0.15px;word-spacing:3.98px;}
#tg_47{left:180px;bottom:995px;letter-spacing:-0.13px;word-spacing:1.46px;}
#th_47{left:180px;bottom:977px;letter-spacing:-0.63px;}
#ti_47{left:153px;bottom:959px;}
#tj_47{left:180px;bottom:959px;letter-spacing:-0.16px;word-spacing:1.26px;}
#tk_47{left:180px;bottom:940px;letter-spacing:-0.17px;word-spacing:-1.8px;}
#tl_47{left:180px;bottom:922px;letter-spacing:-0.16px;word-spacing:0.37px;}
#tm_47{left:180px;bottom:904px;letter-spacing:-0.49px;}
#tn_47{left:110px;bottom:862px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_47{left:110px;bottom:844px;letter-spacing:-0.17px;word-spacing:4.79px;}
#tp_47{left:110px;bottom:826px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tq_47{left:110px;bottom:807px;letter-spacing:-0.09px;}
#tr_47{left:110px;bottom:789px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ts_47{left:110px;bottom:771px;letter-spacing:-0.15px;word-spacing:1.18px;}
#tt_47{left:110px;bottom:753px;letter-spacing:-0.16px;word-spacing:-0.07px;}
#tu_47{left:110px;bottom:735px;letter-spacing:-0.16px;word-spacing:-0.34px;}
#tv_47{left:180px;bottom:699px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tw_47{left:172px;bottom:648px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tx_47{left:110px;bottom:607px;letter-spacing:-0.11px;}
#ty_47{left:231px;bottom:607px;letter-spacing:-0.13px;word-spacing:3.94px;}
#tz_47{left:110px;bottom:589px;letter-spacing:-0.14px;word-spacing:2.04px;}
#t10_47{left:110px;bottom:570px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t11_47{left:110px;bottom:534px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t12_47{left:142px;bottom:516px;letter-spacing:-0.05px;}
#t13_47{left:170px;bottom:516px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_47{left:142px;bottom:498px;letter-spacing:-0.05px;}
#t15_47{left:170px;bottom:498px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t16_47{left:142px;bottom:480px;letter-spacing:-0.05px;}
#t17_47{left:170px;bottom:480px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t18_47{left:142px;bottom:462px;letter-spacing:-0.05px;}
#t19_47{left:170px;bottom:462px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t1a_47{left:110px;bottom:443px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1b_47{left:110px;bottom:425px;letter-spacing:-0.13px;word-spacing:4.38px;}
#t1c_47{left:110px;bottom:407px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1d_47{left:153px;bottom:383px;}
#t1e_47{left:180px;bottom:383px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1f_47{left:153px;bottom:365px;}
#t1g_47{left:180px;bottom:365px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1h_47{left:110px;bottom:341px;letter-spacing:-0.14px;word-spacing:2.43px;}
#t1i_47{left:110px;bottom:322px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1j_47{left:110px;bottom:304px;letter-spacing:-0.11px;word-spacing:3.23px;}
#t1k_47{left:110px;bottom:286px;letter-spacing:-0.12px;word-spacing:-0.11px;}
#t1l_47{left:110px;bottom:268px;letter-spacing:-0.12px;word-spacing:0.02px;}

.s0_47{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_47{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_47{font-size:17px;font-family:ArialMT_5-;color:#4A7EBB;}
.s3_47{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_47{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}`}
        </style>

        <style id="fonts47" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg47" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="47/47.svg"
          type="image/svg+xml"
          id="pdf47"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_47" class="t s0_47">
          45{" "}
        </span>
        <span id="t2_47" class="t s1_47">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_47" class="t s1_47">
          -{" "}
        </span>
        <span id="t4_47" class="t s1_47">
          Хоёр гишүүнтэй бүлэг – Мэдээллийг маш сайн солилцдог, маргаанаас
          зайлсхийхэд{" "}
        </span>
        <span id="t5_47" class="t s1_47">
          тохиромжтой. Гэвч маргаан гарсан тохиолдолд аль нэг талыг дэмжих тал{" "}
        </span>
        <span id="t6_47" class="t s1_47">
          байхгүйгээс мухардалд орох магадлалтай.{" "}
        </span>
        <span id="t7_47" class="t s1_47">
          -{" "}
        </span>
        <span id="t8_47" class="t s1_47">
          Гурван гишүүнтэй бүлэг - Нэг хүнийг хоёр нь олонхийн зарчмаар
          давамгайлах{" "}
        </span>
        <span id="t9_47" class="t s1_47">
          сул талтай. Гэвч гурван гишүүнтэй бүлгийг гишүүдийг өөрчлөн хэрэглэвэл
          хамгийн{" "}
        </span>
        <span id="ta_47" class="t s1_47">
          тэнцвэртэй бүлэг гэж үзэж болно.{" "}
        </span>
        <span id="tb_47" class="t s1_47">
          -{" "}
        </span>
        <span id="tc_47" class="t s1_47">
          Тэгш тоотой гишүүнтэй бүлэг - Тэгш тоотой гишүүдтэй бүлэгт хамгийн их
          санал{" "}
        </span>
        <span id="td_47" class="t s1_47">
          зөрөлдөөн гардаг. Дахин тэгш дэд бүлэгт хуваагдан мухардалд орох
          магадлалтай.{" "}
        </span>
        <span id="te_47" class="t s1_47">
          -{" "}
        </span>
        <span id="tf_47" class="t s1_47">
          Таван гишүүнтэй бүлэг - Сургалтад хэрэглэхэд хамгийн тохиромжтой
          хэмжээ.{" "}
        </span>
        <span id="tg_47" class="t s1_47">
          Асуудал дээр санал хуваагдахад 2:3 харьцаа нь цөөнхөд дэмжлэг авах
          боломж{" "}
        </span>
        <span id="th_47" class="t s1_47">
          өгдөг.{" "}
        </span>
        <span id="ti_47" class="t s1_47">
          -{" "}
        </span>
        <span id="tj_47" class="t s1_47">
          Таваас дээш гишүүнтэй бүлэг - Бүлгийн хэмжээ нэмэгдэх тусам бүлгийн
          мэдлэг,{" "}
        </span>
        <span id="tk_47" class="t s1_47">
          чадварын хэмжээ төдий чинээ нэмэгддэг. Гэвч бүлгийн бүх гишүүдийг
          дуулгавартай{" "}
        </span>
        <span id="tl_47" class="t s1_47">
          байлгах, бүгдэд үг хэлэх боломж өгөх, бүлгийн ажлыг зохицуулахад
          бэрхшээлтэй{" "}
        </span>
        <span id="tm_47" class="t s1_47">
          байдаг.{" "}
        </span>
        <span id="tn_47" class="t s2_47">
          Загвар 3: Асуудал ба шийдэл{" "}
        </span>
        <span id="to_47" class="t s1_47">
          Зорилго: Хүүхэд өсвөр үеийнхэнд тулгамдаж байгаа асуудлаа шийдэхэд
          гарц олоход{" "}
        </span>
        <span id="tp_47" class="t s1_47">
          туслах, хамтын ажиллагааг дэмжихэд оршино.{" "}
        </span>
        <span id="tq_47" class="t s1_47">
          Хамрах хүрээ: 14-18 нас{" "}
        </span>
        <span id="tr_47" class="t s1_47">
          Хэрэглэгдэхүүн: Цаас харандаа{" "}
        </span>
        <span id="ts_47" class="t s1_47">
          Заавар: Хүүхдүүдийн өмнө тулгамдаж буй асуудлыг хамгийн хэцүү гэж
          бодож байгаагаас{" "}
        </span>
        <span id="tt_47" class="t s1_47">
          нь эхлэн эрэмбэлж бичүүлнэ. Баг болон хуваагдаж, хамгийн чухал
          асуудлуудыг сонгон авч{" "}
        </span>
        <span id="tu_47" class="t s1_47">
          шийдвэрлэх гарцыг багаараа ярилцан тодорхойлж жүжигчилсэн тоглолт
          зохион байгуулна.{" "}
        </span>
        <span id="tv_47" class="t s3_47">
          ДОЛОО. ЭХ ОРОНЧ БОЛОН ХӨДӨЛМӨРИЙН ХҮМҮҮЖЛИЙГ ДЭМЖИХ{" "}
        </span>
        <span id="tw_47" class="t s1_47">
          7.1 Хүүхдэд эх оронч хүмүүжил олгох хөтөлбөрүүдийг хэрэгжүүлэх{" "}
        </span>
        <span id="tx_47" class="t s4_47">
          7.1.1.Зорилго:{" "}
        </span>
        <span id="ty_47" class="t s1_47">
          Хичээлээс гадуурх сургалтын ажлын нэг онцлог нь сурагчдад эх оронч{" "}
        </span>
        <span id="tz_47" class="t s1_47">
          хүмүүжлийг төлөвшүүлэх явдал бөгөөд өөрсдийн орчин тойрон дахь
          тодорхой асуудлыг{" "}
        </span>
        <span id="t10_47" class="t s1_47">
          анги, баг, хамт олноороо шийдвэрлэх чадварт суралцахад дэмжлэг үзүүлэх
          юм.{" "}
        </span>
        <span id="t11_47" class="t s1_47">
          7.1.2.Үндсэн ойлголт:{" "}
        </span>
        <span id="t12_47" class="t s1_47">
          1.{" "}
        </span>
        <span id="t13_47" class="t s1_47">
          Эх орны тухай ойлголт{" "}
        </span>
        <span id="t14_47" class="t s1_47">
          2.{" "}
        </span>
        <span id="t15_47" class="t s1_47">
          Эх оронч үзэл гэж юу вэ?{" "}
        </span>
        <span id="t16_47" class="t s1_47">
          3.{" "}
        </span>
        <span id="t17_47" class="t s1_47">
          Эх оронч үзлийг төлөшүүлэх нь{" "}
        </span>
        <span id="t18_47" class="t s1_47">
          4.{" "}
        </span>
        <span id="t19_47" class="t s1_47">
          Эх оронч хүмүүжил олгох ажлын хэлбэрүүд{" "}
        </span>
        <span id="t1a_47" class="t s4_47">
          7.1.3.Үйл ажиллагаа:{" "}
        </span>
        <span id="t1b_47" class="t s1_47">
          Хүүхдэд эх оронч хүмүүжил, түүний дотор цэрэг-эх оронч хүмүүжил
          олгохдоо дараах{" "}
        </span>
        <span id="t1c_47" class="t s1_47">
          хөтөлбөрүүдтэй уялдуулан үйл ажиллагааг төлөвлөн хэрэгжүүлэх.
          Тухайлбал:{" "}
        </span>
        <span id="t1d_47" class="t s1_47">
          -{" "}
        </span>
        <span id="t1e_47" class="t s1_47">
          Аюулгүй, амьдрах ухаан хөтөлбөр{" "}
        </span>
        <span id="t1f_47" class="t s1_47">
          -{" "}
        </span>
        <span id="t1g_47" class="t s1_47">
          Иргэний ёс зүйн боловсрол хөтөлбөр{" "}
        </span>
        <span id="t1h_47" class="t s1_47">
          Энэхүү зорилтын хүрээнд дуут жагсаалын үзлэг, өсвөрийн аврагч, цэрэг
          спортын “Дөл”,{" "}
        </span>
        <span id="t1i_47" class="t s1_47">
          “Шонхор” тэмцээн, сурагч миний нэрийн хуудас зэрэг ажлуудыг зохион
          байгуулж болно.{" "}
        </span>
        <span id="t1j_47" class="t s1_47">
          Хүүхдийн клуб, бүлэгтэй ажиллах багш бол идэвхжүүлэгч, чиглүүлэгч
          бөгөөд хүүхдийн{" "}
        </span>
        <span id="t1k_47" class="t s1_47">
          бодол санааг сонсогч, суралцагч, нэгтгэгч, дүгнэгч гэдгийг ойлгох
          хэрэгтэй. Өөрөөр хэлбэл{" "}
        </span>
        <span id="t1l_47" class="t s1_47">
          хүүхдэд заах биш харин хамтран суралцах байрнаас хандах нь илүү үр
          дүнтэй юм.{" "}
        </span>
      </div>
    </div>
  );
}
