import React from "react";
;

export default function SevenTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_17{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_17{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_17{left:153px;bottom:1138px;}
#t4_17{left:180px;bottom:1138px;letter-spacing:-0.13px;word-spacing:0.66px;}
#t5_17{left:180px;bottom:1120px;letter-spacing:-0.12px;word-spacing:-0.99px;}
#t6_17{left:180px;bottom:1102px;letter-spacing:-0.14px;word-spacing:-0.18px;}
#t7_17{left:180px;bottom:1084px;letter-spacing:-0.14px;word-spacing:0.5px;}
#t8_17{left:180px;bottom:1066px;letter-spacing:-0.15px;word-spacing:2.24px;}
#t9_17{left:180px;bottom:1048px;letter-spacing:-0.12px;word-spacing:0.03px;}
#ta_17{left:153px;bottom:1024px;}
#tb_17{left:180px;bottom:1024px;letter-spacing:-0.13px;word-spacing:-0.24px;}
#tc_17{left:180px;bottom:1005px;letter-spacing:-0.17px;word-spacing:-0.32px;}
#td_17{left:180px;bottom:987px;letter-spacing:-0.16px;word-spacing:0.08px;}
#te_17{left:153px;bottom:963px;}
#tf_17{left:180px;bottom:963px;letter-spacing:-0.1px;word-spacing:-0.83px;}
#tg_17{left:180px;bottom:945px;letter-spacing:-0.13px;word-spacing:0.03px;}
#th_17{left:153px;bottom:921px;}
#ti_17{left:180px;bottom:921px;letter-spacing:-0.15px;word-spacing:7.34px;}
#tj_17{left:180px;bottom:902px;letter-spacing:-0.14px;word-spacing:4.88px;}
#tk_17{left:180px;bottom:884px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tl_17{left:110px;bottom:860px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tm_17{left:153px;bottom:836px;}
#tn_17{left:180px;bottom:836px;letter-spacing:-0.12px;word-spacing:1.21px;}
#to_17{left:180px;bottom:818px;letter-spacing:-0.14px;word-spacing:0.99px;}
#tp_17{left:180px;bottom:800px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tq_17{left:180px;bottom:781px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tr_17{left:153px;bottom:757px;}
#ts_17{left:180px;bottom:757px;letter-spacing:-0.15px;word-spacing:-0.17px;}
#tt_17{left:180px;bottom:739px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tu_17{left:153px;bottom:715px;}
#tv_17{left:180px;bottom:715px;letter-spacing:-0.15px;word-spacing:-0.74px;}
#tw_17{left:180px;bottom:697px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tx_17{left:153px;bottom:672px;}
#ty_17{left:180px;bottom:672px;letter-spacing:-0.12px;word-spacing:1.16px;}
#tz_17{left:180px;bottom:654px;letter-spacing:-0.12px;word-spacing:-1.33px;}
#t10_17{left:180px;bottom:636px;letter-spacing:-0.14px;word-spacing:-0.55px;}
#t11_17{left:180px;bottom:618px;letter-spacing:-0.16px;}
#t12_17{left:153px;bottom:594px;}
#t13_17{left:180px;bottom:594px;letter-spacing:-0.14px;word-spacing:4.12px;}
#t14_17{left:180px;bottom:576px;letter-spacing:-0.12px;word-spacing:0.94px;}
#t15_17{left:180px;bottom:558px;letter-spacing:-0.12px;word-spacing:0.87px;}
#t16_17{left:180px;bottom:539px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t17_17{left:153px;bottom:515px;}
#t18_17{left:180px;bottom:515px;letter-spacing:-0.13px;word-spacing:-1.09px;}
#t19_17{left:180px;bottom:497px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1a_17{left:110px;bottom:473px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t1b_17{left:153px;bottom:449px;}
#t1c_17{left:180px;bottom:449px;letter-spacing:-0.18px;word-spacing:4.38px;}
#t1d_17{left:180px;bottom:430px;letter-spacing:-0.18px;word-spacing:-0.01px;}
#t1e_17{left:180px;bottom:412px;letter-spacing:-0.13px;word-spacing:3.49px;}
#t1f_17{left:180px;bottom:394px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t1g_17{left:153px;bottom:370px;}
#t1h_17{left:180px;bottom:370px;letter-spacing:-0.12px;word-spacing:-0.08px;}
#t1i_17{left:180px;bottom:352px;letter-spacing:-0.13px;word-spacing:-0.05px;}
#t1j_17{left:180px;bottom:334px;letter-spacing:-0.17px;word-spacing:1.94px;}
#t1k_17{left:180px;bottom:315px;letter-spacing:-0.16px;word-spacing:5.15px;}
#t1l_17{left:180px;bottom:297px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1m_17{left:153px;bottom:273px;}
#t1n_17{left:180px;bottom:273px;letter-spacing:-0.12px;word-spacing:4.89px;}
#t1o_17{left:180px;bottom:255px;letter-spacing:-0.15px;word-spacing:0.44px;}
#t1p_17{left:180px;bottom:237px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1q_17{left:153px;bottom:213px;}
#t1r_17{left:180px;bottom:213px;letter-spacing:-0.15px;word-spacing:-0.26px;}
#t1s_17{left:180px;bottom:194px;letter-spacing:-0.12px;word-spacing:3.34px;}
#t1t_17{left:180px;bottom:176px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_17{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_17{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_17{font-size:17px;font-family:ArialMT_5q;color:#231F20;}
.s3_17{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}`}
        </style>
        <style id="fonts17" type="text/css">
          {`@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg17" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="17/17.svg"
          type="image/svg+xml"
          id="pdf17"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_17" class="t s0_17">
          15{" "}
        </span>
        <span id="t2_17" class="t s1_17">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t4_17" class="t s1_17">
          Хэл яриа-сэтгэхүйн логик чадварын хувьд, хүүхдүүд үзэгдэл юмсыг гадаад
          шинж{" "}
        </span>
        <span id="t5_17" class="t s1_17">
          байдлыг танин мэдэж нийтлэг шинжээр нь нэгтгэн дүгнэх нь илүү байна.
          Ялангуяа,{" "}
        </span>
        <span id="t6_17" class="t s1_17">
          анхаарлын төвлөрөл бага шаардсан зүйлийг таньж нэгтгэн дүгнэж, зүй
          тогтолд нь{" "}
        </span>
        <span id="t7_17" class="t s1_17">
          сэтгэдэг байна. Харин учир шалтгаан, мэдлэг ойлголтод үндэслэн зүй
          тогтолд нь{" "}
        </span>
        <span id="t8_17" class="t s1_17">
          сэтгэн нэгтгэн дүгнэх нь сул байна. Тухайн чадвар нь нас нэмэгдэхэд
          аажмаар{" "}
        </span>
        <span id="t9_17" class="t s1_17">
          сайжирсан байна.{" "}
        </span>
        <span id="ta_17" class="t s2_17">
          •{" "}
        </span>
        <span id="tb_17" class="t s1_17">
          Хүүхдүүдийн үгийн нөөцийн хувьд, бодит, ахуйн шинжтэй үгийн утгыг илүү
          мэддэг{" "}
        </span>
        <span id="tc_17" class="t s1_17">
          бол салаа/ижил утгатай үг, тэмдэг нэрийн үгийн утгыг таньж тайлбарлах,
          бүлэглэх{" "}
        </span>
        <span id="td_17" class="t s1_17">
          нь сул байна.{" "}
        </span>
        <span id="te_17" class="t s2_17">
          •{" "}
        </span>
        <span id="tf_17" class="t s1_17">
          Ярих чадварын хувьд, энгийн богино өгүүлбэрээр ерөнхийлөн дүгнэж,
          дунджаар 2{" "}
        </span>
        <span id="tg_17" class="t s1_17">
          минутад ойролцоогоор 31 үгээр санаагаа ярьж илэрхийлдэг байна.{" "}
        </span>
        <span id="th_17" class="t s2_17">
          •{" "}
        </span>
        <span id="ti_17" class="t s1_17">
          Охидын үгийн нөөц, үгийн утгыг оновчтой тайлбарлах, юмсыг хооронд нь{" "}
        </span>
        <span id="tj_17" class="t s1_17">
          харьцуулан төстэй ба ялгаатай талыг нь олох, нэгтгэн дүгнэх нь
          хөвгүүдтэй{" "}
        </span>
        <span id="tk_17" class="t s1_17">
          харьцуулахад сайн байна.{" "}
        </span>
        <span id="tl_17" class="t s3_17">
          Нийгэмшлийн онцлог:{" "}
        </span>
        <span id="tm_17" class="t s2_17">
          •{" "}
        </span>
        <span id="tn_17" class="t s1_17">
          Энэ насны хүүхдүүдийн өөрийгөө хүлээн зөвшөөрөх, сэтгэл санааны
          тогтвортой{" "}
        </span>
        <span id="to_17" class="t s1_17">
          байдал, өөрийн зан төлөвийг удирдах, ухамсар хариуцлагатай,
          анхааралтай зан{" "}
        </span>
        <span id="tp_17" class="t s1_17">
          төлөв, эцэг эх, хүүхэд хоорондын харилцаа, ээнэгшилт, бусадтай хамтрах
          чадвар{" "}
        </span>
        <span id="tq_17" class="t s1_17">
          нь нас нэмэгдэхэд бага зэрэг буурч байна.{" "}
        </span>
        <span id="tr_17" class="t s2_17">
          •{" "}
        </span>
        <span id="ts_17" class="t s1_17">
          Өөртөө хандах хандлага сайн байх тусам бие хүний онцлог, сэтгэлийн
          хөдөлгөөн,{" "}
        </span>
        <span id="tt_17" class="t s1_17">
          дасан зохицолт, ээнэгшилт болон харилцааны чадварууд эерэг байна.{" "}
        </span>
        <span id="tu_17" class="t s2_17">
          •{" "}
        </span>
        <span id="tv_17" class="t s1_17">
          Өөртөө хандах хандлага, бие хүний онцлог болон харилцааны чадварууд нь
          11-14{" "}
        </span>
        <span id="tw_17" class="t s1_17">
          насны хүүхдийн нийгэмшилд чухал нөлөөтэй байна.{" "}
        </span>
        <span id="tx_17" class="t s2_17">
          •{" "}
        </span>
        <span id="ty_17" class="t s1_17">
          Хөвгүүдийн харилцааны чадвар, нийгмийн хэм хэмжээг баримтлах чадвар,
          нийт{" "}
        </span>
        <span id="tz_17" class="t s1_17">
          хүүхдүүдийн өөртөө хүндэтгэлтэй хандах, бусдаас хамааралгүй бие даан
          шийдвэр{" "}
        </span>
        <span id="t10_17" class="t s1_17">
          гаргах, эцэг эхтэйгээ нээлттэй харилцаатай байх чадварыг нь дэмжих
          хэрэгцээтэй{" "}
        </span>
        <span id="t11_17" class="t s1_17">
          байна.{" "}
        </span>
        <span id="t12_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t13_17" class="t s1_17">
          Өсвөр насанд нийгмийн хэм хэмжээг сахих нь бага зэрэг буурч
          байна.Өсвөр{" "}
        </span>
        <span id="t14_17" class="t s1_17">
          наснаас хүүхдүүд эцэг эхтэйгээ хөндий харилцаж эхэлдэг байна. Ийм учир
          багш{" "}
        </span>
        <span id="t15_17" class="t s1_17">
          ангийн ажлаар чиглүүлэн эцэг эхэд нь ойлгуулан хүүхдийн нийгэмшлийг
          дэмжих{" "}
        </span>
        <span id="t16_17" class="t s1_17">
          чухал үе байна.{" "}
        </span>
        <span id="t17_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t18_17" class="t s1_17">
          Суурь болон бүрэн дунд боловсролын түвшинд суралцаж буй хүүхдүүдийн
          бусдын{" "}
        </span>
        <span id="t19_17" class="t s1_17">
          сэтгэл санааг ойлгож харилцах чадвар сул байна.{" "}
        </span>
        <span id="t1a_17" class="t s3_17">
          Бие бялдрын хөгжлийн онцлог:{" "}
        </span>
        <span id="t1b_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t1c_17" class="t s1_17">
          11-14 насанд хүүхдүүдийн биеийн ерөнхий хөгжил тухайлбал толгойн
          тойрог,{" "}
        </span>
        <span id="t1d_17" class="t s1_17">
          биеийн жин, бүсэлхийн тойрог, цээжний тойрог, биеийн өндөр нас
          нэмэгдэх тусам{" "}
        </span>
        <span id="t1e_17" class="t s1_17">
          өссөн үзүүлэлттэй байгаа нь насны зүйн тогтол, физиологийн хөгжлийн
          дагуу{" "}
        </span>
        <span id="t1f_17" class="t s1_17">
          явагдаж байна.{" "}
        </span>
        <span id="t1g_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t1h_17" class="t s1_17">
          Биеийн жингийн индексийн үзүүлэлтээр охидын 13-14 насанд таргалалтын 1
          ба 2{" "}
        </span>
        <span id="t1i_17" class="t s1_17">
          зэрэгт орж эхэлж байгаа энэ үед анхаарах тохирсон дасгал хөдөлгөөнийг
          хийлгэх{" "}
        </span>
        <span id="t1j_17" class="t s1_17">
          шаардлагатай болж байгааг харуулж байна. Хүүхдүүдийн биеийн жин
          нэмэгдэж{" "}
        </span>
        <span id="t1k_17" class="t s1_17">
          байгаатай холбоотой цээжний тойрог болон бүсэлхийн тойрог мөн нэмэгдэж{" "}
        </span>
        <span id="t1l_17" class="t s1_17">
          байгааг илэрхийлж байна.{" "}
        </span>
        <span id="t1m_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t1n_17" class="t s1_17">
          Хүүхдүүдийн биеийн өндөр 11-12 насанд 4.74 см, 13-14 насанд 2.41
          см-ээр{" "}
        </span>
        <span id="t1o_17" class="t s1_17">
          нэмэгдэж байгаа нь манай улсын эрдэмтдийн хийсэн судалгааны үр дүнтэй
          буюу{" "}
        </span>
        <span id="t1p_17" class="t s1_17">
          эрчимтэй хөгжлийн үе шатандаа явж байгааг илэрхийлж байна.{" "}
        </span>
        <span id="t1q_17" class="t s2_17">
          •{" "}
        </span>
        <span id="t1r_17" class="t s1_17">
          Хөвгүүдийн хувьд нас нэмэгдэх тусам хурдны чанар сайжирсан байна.
          Тухайлбал{" "}
        </span>
        <span id="t1s_17" class="t s1_17">
          11-12 насанд 0.50 секундээр, 13-14 насанд 0.20 секундээр нэмэгдсэн нь
          хурд{" "}
        </span>
        <span id="t1t_17" class="t s1_17">
          сайжирч байгааг илэрхийлж байна.{" "}
        </span>
      </div>
    </div>
  );
}
