import React from "react";
;

export default function FourTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_42{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_42{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_42{left:110px;bottom:1157px;letter-spacing:-0.16px;word-spacing:-1.33px;}
#t4_42{left:110px;bottom:1139px;letter-spacing:-0.15px;word-spacing:3.64px;}
#t5_42{left:110px;bottom:1121px;letter-spacing:-0.13px;word-spacing:0.41px;}
#t6_42{left:110px;bottom:1102px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t7_42{left:110px;bottom:1066px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t8_42{left:110px;bottom:1048px;letter-spacing:-0.09px;word-spacing:4.39px;}
#t9_42{left:243px;bottom:1048px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ta_42{left:110px;bottom:1030px;letter-spacing:-0.05px;}
#tb_42{left:138px;bottom:1030px;letter-spacing:-0.17px;word-spacing:1.68px;}
#tc_42{left:138px;bottom:1012px;letter-spacing:-0.14px;word-spacing:0.73px;}
#td_42{left:138px;bottom:994px;letter-spacing:-0.14px;word-spacing:0.84px;}
#te_42{left:138px;bottom:975px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tf_42{left:110px;bottom:957px;letter-spacing:-0.05px;}
#tg_42{left:138px;bottom:957px;letter-spacing:-0.11px;word-spacing:0.01px;}
#th_42{left:110px;bottom:939px;letter-spacing:-0.05px;}
#ti_42{left:138px;bottom:939px;letter-spacing:-0.16px;word-spacing:0.09px;}
#tj_42{left:138px;bottom:921px;letter-spacing:-0.18px;}
#tk_42{left:110px;bottom:903px;letter-spacing:-0.14px;word-spacing:2.97px;}
#tl_42{left:153px;bottom:879px;}
#tm_42{left:180px;bottom:879px;letter-spacing:-0.15px;word-spacing:0.4px;}
#tn_42{left:180px;bottom:861px;letter-spacing:-0.11px;word-spacing:2.62px;}
#to_42{left:180px;bottom:842px;letter-spacing:-0.14px;word-spacing:1.09px;}
#tp_42{left:180px;bottom:824px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tq_42{left:153px;bottom:806px;}
#tr_42{left:180px;bottom:806px;letter-spacing:-0.14px;word-spacing:3.25px;}
#ts_42{left:180px;bottom:788px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tt_42{left:153px;bottom:770px;}
#tu_42{left:180px;bottom:770px;letter-spacing:-0.12px;word-spacing:0.5px;}
#tv_42{left:180px;bottom:752px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tw_42{left:153px;bottom:734px;}
#tx_42{left:180px;bottom:734px;letter-spacing:-0.13px;word-spacing:0.21px;}
#ty_42{left:180px;bottom:715px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tz_42{left:153px;bottom:697px;}
#t10_42{left:180px;bottom:697px;letter-spacing:-0.16px;word-spacing:1.13px;}
#t11_42{left:180px;bottom:679px;letter-spacing:-0.12px;}
#t12_42{left:110px;bottom:655px;letter-spacing:-0.17px;word-spacing:2.85px;}
#t13_42{left:110px;bottom:637px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t14_42{left:110px;bottom:619px;letter-spacing:-0.05px;}
#t15_42{left:138px;bottom:619px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t16_42{left:110px;bottom:600px;letter-spacing:-0.05px;}
#t17_42{left:138px;bottom:600px;letter-spacing:-0.13px;word-spacing:-0.2px;}
#t18_42{left:138px;bottom:582px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t19_42{left:110px;bottom:564px;letter-spacing:-0.05px;}
#t1a_42{left:138px;bottom:564px;letter-spacing:-0.1px;word-spacing:0.38px;}
#t1b_42{left:138px;bottom:546px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1c_42{left:110px;bottom:528px;letter-spacing:-0.05px;}
#t1d_42{left:138px;bottom:528px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1e_42{left:110px;bottom:510px;letter-spacing:-0.14px;word-spacing:3.78px;}
#t1f_42{left:138px;bottom:492px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1g_42{left:110px;bottom:474px;letter-spacing:-0.05px;}
#t1h_42{left:138px;bottom:474px;letter-spacing:-0.13px;word-spacing:-0.82px;}
#t1i_42{left:138px;bottom:455px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1j_42{left:110px;bottom:437px;letter-spacing:-0.12px;word-spacing:2.88px;}
#t1k_42{left:138px;bottom:419px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1l_42{left:110px;bottom:401px;letter-spacing:-0.05px;}
#t1m_42{left:138px;bottom:401px;letter-spacing:-0.11px;word-spacing:0.1px;}
#t1n_42{left:138px;bottom:383px;letter-spacing:-0.15px;word-spacing:1.42px;}
#t1o_42{left:138px;bottom:365px;letter-spacing:-0.1px;}
#t1p_42{left:110px;bottom:347px;letter-spacing:-0.15px;word-spacing:2.93px;}
#t1q_42{left:110px;bottom:328px;letter-spacing:-0.15px;word-spacing:4.53px;}
#t1r_42{left:110px;bottom:310px;letter-spacing:-0.16px;word-spacing:2.74px;}
#t1s_42{left:110px;bottom:292px;letter-spacing:-0.18px;word-spacing:0.76px;}
#t1t_42{left:110px;bottom:274px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1u_42{left:110px;bottom:238px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1v_42{left:110px;bottom:220px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1w_42{left:110px;bottom:202px;letter-spacing:-0.15px;word-spacing:-0.73px;}
#t1x_42{left:110px;bottom:183px;letter-spacing:-0.15px;word-spacing:1.68px;}
#t1y_42{left:110px;bottom:165px;letter-spacing:-0.13px;word-spacing:0.86px;}
#t1z_42{left:110px;bottom:147px;letter-spacing:-0.17px;word-spacing:-0.83px;}
#t20_42{left:110px;bottom:129px;letter-spacing:-0.13px;word-spacing:0.61px;}
#t21_42{left:110px;bottom:111px;letter-spacing:-0.12px;word-spacing:2.36px;}
#t22_42{left:110px;bottom:93px;letter-spacing:-0.13px;word-spacing:0.09px;}
#t23_42{left:110px;bottom:75px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_42{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_42{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_42{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_42{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}`}
        </style>

        <style id="fonts42" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg42" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="42/42.svg"
          type="image/svg+xml"
          id="pdf42"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_42" class="t s0_42">
          40{" "}
        </span>
        <span id="t2_42" class="t s1_42">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_42" class="t s1_42">
          Өөрийн сурдаг сургууль болон анги хамт олондоо цахим орчинд дарамт,
          доромжлолд өртөж{" "}
        </span>
        <span id="t4_42" class="t s1_42">
          эрх нь зөрчигдсөн хүүхдийг хамгаалах чиглэлээр үйл ажиллагаа явуулах
          клуб, бүлгэм{" "}
        </span>
        <span id="t5_42" class="t s1_42">
          байгуулна. Тэрхүү клубуудыг нэгтгэсэн “Сургууль, орон нутгийн
          хамгааллын сүлжээ”-г бий{" "}
        </span>
        <span id="t6_42" class="t s1_42">
          болгох боломжтой.{" "}
        </span>
        <span id="t7_42" class="t s2_42">
          Загвар 2:{" "}
        </span>
        <span id="t8_42" class="t s1_42">
          1. Клубын нэр–“
        </span>
        <span id="t9_42" class="t s3_42">
          Цахим орчны соёлтой хэрэглээг хэвшүүлэх клуб”{" "}
        </span>
        <span id="ta_42" class="t s1_42">
          2.{" "}
        </span>
        <span id="tb_42" class="t s1_42">
          Клубын зорилго–Интернэт, гар утас, бусад цахим мэдээллийн хэрэгсэл
          хүүхэд, өсвөр{" "}
        </span>
        <span id="tc_42" class="t s1_42">
          үеийнхэнд сурч боловсрох, нийгмийн харилцаанд идэвхтэй оролцох, хөгжих
          мэдээлэл{" "}
        </span>
        <span id="td_42" class="t s1_42">
          авах, нийгмийн сүлжээгээ өргөжүүлэх, хувийн мэдээллийн сангаа бий
          болгох, бүтээлч{" "}
        </span>
        <span id="te_42" class="t s1_42">
          байдлаа хөгжүүлэх эерэг боломжуудыг олгоход чиглэгдэнэ.{" "}
        </span>
        <span id="tf_42" class="t s1_42">
          3.{" "}
        </span>
        <span id="tg_42" class="t s1_42">
          Хамрах хүрээ – 6-12 дугаар анги{" "}
        </span>
        <span id="th_42" class="t s1_42">
          4.{" "}
        </span>
        <span id="ti_42" class="t s1_42">
          Үйл ажиллагааны чиглэл–Клубын зорилготойгоо уялдуулан үйл ажиллагааны
          чиглэлээ{" "}
        </span>
        <span id="tj_42" class="t s1_42">
          тодорхойлно.{" "}
        </span>
        <span id="tk_42" class="t s1_42">
          5. Клубын ажлын чиглэл{" "}
        </span>
        <span id="tl_42" class="t s1_42">
          -{" "}
        </span>
        <span id="tm_42" class="t s1_42">
          сургууль дээр, анги доторх фэйсбүүк бүлгэмүүдэд бусдыг шоолж
          тохуурхсан, хэл{" "}
        </span>
        <span id="tn_42" class="t s1_42">
          амаар доромжилсон, гадаад төрхийг нь шүүмжилсэн, хувийн орон зайд нь
          хэт{" "}
        </span>
        <span id="to_42" class="t s1_42">
          халдсан өнгө аяс, хандлага байгаа эсэхийг таньж илрүүлэх, таслан
          зогсоох арга{" "}
        </span>
        <span id="tp_42" class="t s1_42">
          хэмжээг авдаг байх,{" "}
        </span>
        <span id="tq_42" class="t s1_42">
          -{" "}
        </span>
        <span id="tr_42" class="t s1_42">
          эерэг зөв харилцаа хандлагыг төлөвшүүлэх сургалт сурталчилгаа
          нөлөөллийн{" "}
        </span>
        <span id="ts_42" class="t s1_42">
          ажлууд зохион байгуулах{" "}
        </span>
        <span id="tt_42" class="t s1_42">
          -{" "}
        </span>
        <span id="tu_42" class="t s1_42">
          хүн бүр тэгш эрхтэй ч хүн бүр ялгаатай байдаг бөгөөд түүний онцлогийг
          хүндэлж{" "}
        </span>
        <span id="tv_42" class="t s1_42">
          хүлээн зөвшөөрөхийн чухлыг бусдад ойлгуулах{" "}
        </span>
        <span id="tw_42" class="t s1_42">
          -{" "}
        </span>
        <span id="tx_42" class="t s1_42">
          хэн нэгнийгээ цахим орчинд шүүмжлэх, шоолох, гадуурхах, зохисгүй үг
          хэллэгээр{" "}
        </span>
        <span id="ty_42" class="t s1_42">
          гомдоох явдлын эсрэг аян, хөдөлгөөн өрнүүлэх{" "}
        </span>
        <span id="tz_42" class="t s1_42">
          -{" "}
        </span>
        <span id="t10_42" class="t s1_42">
          хүүхдүүдэд бусдыг байгаагаар нь хүлээн зөвшөөрч, ойлгох чадвар бий
          болгоход{" "}
        </span>
        <span id="t11_42" class="t s1_42">
          анхаарах{" "}
        </span>
        <span id="t12_42" class="t s1_42">
          Хүүхэд цахим орчин дахь дарамт, хүчирхийлэлд өртсөн буюу өртөж
          болзошгүй эсэхийг{" "}
        </span>
        <span id="t13_42" class="t s1_42">
          шалгахдаа дараах асуулгыг хэрэглэж болно. Тухайлбал:{" "}
        </span>
        <span id="t14_42" class="t s1_42">
          1.{" "}
        </span>
        <span id="t15_42" class="t s1_42">
          Хүүхэд хэн нэгнээс өөрийг нь үзэн ядсан, заналхийлсэн зурвас,{" "}
        </span>
        <span id="t16_42" class="t s1_42">
          2.{" "}
        </span>
        <span id="t17_42" class="t s1_42">
          и-мэйл, фэйсбүүкийн зурвас болон бусад олон нийтийн сүлжээний
          хэрэглэгчээс зурвас{" "}
        </span>
        <span id="t18_42" class="t s1_42">
          авсан уу?{" "}
        </span>
        <span id="t19_42" class="t s1_42">
          3.{" "}
        </span>
        <span id="t1a_42" class="t s1_42">
          Хэн нэгэн хүн хүүхдэд онлайнаар ямар нэг зүйл бичиж доромжилсон уу?
          Тэр зүйлд нь{" "}
        </span>
        <span id="t1b_42" class="t s1_42">
          бусад хүн сэтгэгдэл бичсэн үү? Бусдад тараасан уу?{" "}
        </span>
        <span id="t1c_42" class="t s1_42">
          4.{" "}
        </span>
        <span id="t1d_42" class="t s1_42">
          Хэн нэгэн тэдний зурвас, и-мэйл хаягийг зөвшөөрөлгүйгээр бусдад
          дамжуулсан уу?{" "}
        </span>
        <span id="t1e_42" class="t s1_42">
          5. Хэн нэгэн тэдний зургийг доромж буюу гутаах байдлаар ашигласан уу?
          Тэр зургаа{" "}
        </span>
        <span id="t1f_42" class="t s1_42">
          бусдад үзүүлэхээр онлайнаар нийтэлсэн үү?{" "}
        </span>
        <span id="t1g_42" class="t s1_42">
          6.{" "}
        </span>
        <span id="t1h_42" class="t s1_42">
          Хэн нэгэн тэдний хувийн хаягийн нэвтрэх нууц үгийг хулгайлсан уу?
          Тэрийгээ зурвасаар{" "}
        </span>
        <span id="t1i_42" class="t s1_42">
          илгээж, тараасан уу?{" "}
        </span>
        <span id="t1j_42" class="t s1_42">
          7. Хэн нэгэн тэднийг ангийн болон сургуулийн фэйсбүүк бүлгэм, бусад
          онлайн бүлгээс{" "}
        </span>
        <span id="t1k_42" class="t s1_42">
          хассан уу? тэр нь хүүхдийн тухай цуу үг болж тарсан уу?{" "}
        </span>
        <span id="t1l_42" class="t s1_42">
          8.{" "}
        </span>
        <span id="t1m_42" class="t s1_42">
          Хэн нэгэн таны хүүхдийн тухай цуу үг тараах, сөрөг зүйл ярих зорилгоор
          доромжилсон{" "}
        </span>
        <span id="t1n_42" class="t s1_42">
          блог, фэйсбүүкийн хуудас, твиттерийн онцлох тэмдэглэгээ (#
          тэмдэглэгээ) ашигласан{" "}
        </span>
        <span id="t1o_42" class="t s1_42">
          уу?{" "}
        </span>
        <span id="t1p_42" class="t s1_42">
          Хэрэв хүүхэд дээрх асуултын аль нэгийг хэлж айдас түгшүүртэй байгаа
          тохиолдолд та{" "}
        </span>
        <span id="t1q_42" class="t s1_42">
          хүүхэдтэй ярилцаж, түүнтэй зөвлөх буюу сэтгэл санаагаар дэмжих, уг
          асуудлыг АУБ,{" "}
        </span>
        <span id="t1r_42" class="t s1_42">
          эцэг эх болон холбоотой бусад хүнд мэдэгдэх хэрэгтэй. Хэрэв шалгах
          асуултуудын нэг{" "}
        </span>
        <span id="t1s_42" class="t s1_42">
          буюу түүнээс илүүг баталгаажуулж байгаа бол цахим орчин дахь дарамт,
          хүчирхийлэлтэй{" "}
        </span>
        <span id="t1t_42" class="t s1_42">
          яаралтай тэмцэх, урьдчилан сэргийлэх шаардлагатай болжээ.{" "}
        </span>
        <span id="t1u_42" class="t s3_42">
          Эцэг эх, багш нарт:{" "}
        </span>
        <span id="t1v_42" class="t s3_42">
          3-9 насны хүүхдүүд: Техник технологитой харьцаж сурах үе{" "}
        </span>
        <span id="t1w_42" class="t s1_42">
          Эцэг эх асран хамгаалагчийн аль нэг нь шаардлагатай тохиолдолд багш
          хяналт тавьж байх{" "}
        </span>
        <span id="t1x_42" class="t s1_42">
          шаардлагатай. Гэрийн нөхцөлд зурагтын шүүлтүүртэй сувгуудын багцыг
          хэрэглэх, цахим{" "}
        </span>
        <span id="t1y_42" class="t s1_42">
          орчинд аюулгүй сайтуудын жагсаалтыг гаргах нь зүйтэй. Энэ насны
          хүүхдүүдийн аюулгүй{" "}
        </span>
        <span id="t1z_42" class="t s1_42">
          байдлыг хамгаалах гол зүйл нь зурагт, гар утасны зөв зохистой
          хэрэглээний дадал хэвшилд{" "}
        </span>
        <span id="t20_42" class="t s1_42">
          сургах, дэлгэцийн хамаарлаас урьдчилан сэргийлэх, эцэг эх асран
          хамгаалагчид өөрсдөө{" "}
        </span>
        <span id="t21_42" class="t s1_42">
          гэр бүл, нийгмийн орчин дахь цахим хэрэглээндээ зөв зохистой үлгэр
          дуурайл үзүүлэх,{" "}
        </span>
        <span id="t22_42" class="t s1_42">
          интернэт дэх аюулгүй байдлын үндэс, мэдээллийн орчин дахь ёс зүйн
          ерөнхий ойлголтыг{" "}
        </span>
        <span id="t23_42" class="t s1_42">
          бий болгоход оршино. Энэ насныхан нь цахим мэдээллийн орчны тусламж
          дэмжлэг{" "}
        </span>
      </div>
    </div>
  );
}
