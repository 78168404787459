import React from "react";
;

export default function FiveTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>
        <style type="text/css">
          {`
#t1_15{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_15{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_15{left:110px;bottom:1138px;letter-spacing:-0.14px;word-spacing:-0.41px;}
#t4_15{left:758px;bottom:1138px;letter-spacing:-0.09px;}
#t5_15{left:110px;bottom:1114px;letter-spacing:-0.18px;word-spacing:0.07px;}
#t6_15{left:153px;bottom:1090px;}
#t7_15{left:180px;bottom:1090px;letter-spacing:-0.19px;word-spacing:0.93px;}
#t8_15{left:180px;bottom:1072px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t9_15{left:153px;bottom:1048px;}
#ta_15{left:180px;bottom:1048px;letter-spacing:-0.14px;word-spacing:-0.57px;}
#tb_15{left:153px;bottom:1023px;}
#tc_15{left:180px;bottom:1023px;letter-spacing:-0.15px;word-spacing:4.29px;}
#td_15{left:180px;bottom:1005px;letter-spacing:-0.12px;word-spacing:0.03px;}
#te_15{left:153px;bottom:981px;}
#tf_15{left:180px;bottom:981px;letter-spacing:-0.19px;word-spacing:3.48px;}
#tg_15{left:180px;bottom:963px;letter-spacing:-0.18px;word-spacing:0.47px;}
#th_15{left:180px;bottom:945px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_15{left:153px;bottom:920px;}
#tj_15{left:180px;bottom:920px;letter-spacing:-0.13px;word-spacing:2.91px;}
#tk_15{left:180px;bottom:902px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tl_15{left:153px;bottom:878px;}
#tm_15{left:180px;bottom:878px;letter-spacing:-0.12px;word-spacing:-1.56px;}
#tn_15{left:180px;bottom:860px;letter-spacing:-0.12px;word-spacing:0.02px;}
#to_15{left:153px;bottom:836px;}
#tp_15{left:180px;bottom:836px;letter-spacing:-0.12px;word-spacing:0.19px;}
#tq_15{left:180px;bottom:818px;letter-spacing:-0.1px;word-spacing:4.47px;}
#tr_15{left:180px;bottom:799px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ts_15{left:153px;bottom:630px;}
#tt_15{left:180px;bottom:630px;letter-spacing:-0.16px;word-spacing:4.74px;}
#tu_15{left:180px;bottom:612px;letter-spacing:-0.12px;word-spacing:2.39px;}
#tv_15{left:180px;bottom:593px;letter-spacing:-0.14px;word-spacing:-0.61px;}
#tw_15{left:180px;bottom:575px;letter-spacing:-0.15px;word-spacing:-1.13px;}
#tx_15{left:153px;bottom:551px;}
#ty_15{left:180px;bottom:551px;letter-spacing:-0.16px;word-spacing:0.15px;}
#tz_15{left:180px;bottom:533px;letter-spacing:-0.16px;word-spacing:-1.03px;}
#t10_15{left:180px;bottom:515px;letter-spacing:-0.16px;}
#t11_15{left:153px;bottom:490px;}
#t12_15{left:180px;bottom:490px;letter-spacing:-0.13px;word-spacing:1.36px;}
#t13_15{left:180px;bottom:472px;letter-spacing:-0.16px;word-spacing:7.17px;}
#t14_15{left:180px;bottom:454px;letter-spacing:-0.15px;word-spacing:2.01px;}
#t15_15{left:180px;bottom:436px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t16_15{left:153px;bottom:412px;}
#t17_15{left:180px;bottom:412px;letter-spacing:-0.15px;word-spacing:3.38px;}
#t18_15{left:180px;bottom:394px;letter-spacing:-0.13px;word-spacing:0.67px;}
#t19_15{left:180px;bottom:376px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1a_15{left:153px;bottom:351px;}
#t1b_15{left:180px;bottom:351px;letter-spacing:-0.13px;word-spacing:1.88px;}
#t1c_15{left:180px;bottom:333px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1d_15{left:110px;bottom:309px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t1e_15{left:153px;bottom:285px;}
#t1f_15{left:180px;bottom:285px;letter-spacing:-0.11px;word-spacing:-0.96px;}
#t1g_15{left:180px;bottom:267px;letter-spacing:-0.13px;word-spacing:-0.98px;}
#t1h_15{left:180px;bottom:248px;letter-spacing:-0.13px;word-spacing:-2.1px;}
#t1i_15{left:180px;bottom:230px;letter-spacing:-0.15px;word-spacing:4.31px;}
#t1j_15{left:180px;bottom:212px;letter-spacing:-0.14px;word-spacing:-1.49px;}
#t1k_15{left:180px;bottom:194px;letter-spacing:-0.19px;word-spacing:0.1px;}
#t1l_15{left:153px;bottom:170px;}
#t1m_15{left:180px;bottom:170px;letter-spacing:-0.13px;word-spacing:4.94px;}
#t1n_15{left:180px;bottom:152px;letter-spacing:-0.18px;word-spacing:7.58px;}
#t1o_15{left:180px;bottom:133px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1p_15{left:153px;bottom:109px;}
#t1q_15{left:180px;bottom:109px;letter-spacing:-0.14px;word-spacing:1.96px;}
#t1r_15{left:180px;bottom:91px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1s_15{left:148px;bottom:765px;letter-spacing:-0.14px;word-spacing:5.43px;}
#t1t_15{left:148px;bottom:747px;letter-spacing:-0.13px;word-spacing:2.6px;}
#t1u_15{left:148px;bottom:729px;letter-spacing:-0.15px;word-spacing:-1px;}
#t1v_15{left:148px;bottom:711px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1w_15{left:148px;bottom:686px;letter-spacing:-0.17px;word-spacing:3.1px;}
#t1x_15{left:148px;bottom:668px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_15{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_15{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_15{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s3_15{font-size:17px;font-family:Arial-BoldMT_67;color:#231F20;}
.s4_15{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>
        <style id="fonts15" type="text/css">
          {`
@font-face {
	font-family: Arial-BoldMT_67;
	src: url("fonts/Arial-BoldMT_67.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg15" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="15/15.svg"
          type="image/svg+xml"
          id="pdf15"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_15" class="t s0_15">
          13{" "}
        </span>
        <span id="t2_15" class="t s1_15">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_15" class="t s2_15">
          Бага боловсролын түвшинд сурагчдын танин мэдэхүй, нийгэмшил, бие
          бялдрын{" "}
        </span>
        <span id="t4_15" class="t s2_15">
          онцлог:{" "}
        </span>
        <span id="t5_15" class="t s3_15">
          Танин мэдэхүйн онцлог:{" "}
        </span>
        <span id="t6_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t7_15" class="t s1_15">
          Энгийн богино өгүүллэг, үлгэр гэх зэргийг утгаар нь хялбар тогтоодог,
          анхаарлаа{" "}
        </span>
        <span id="t8_15" class="t s1_15">
          төвлөрүүлэн хурдтай ажиллахдаа бэрхшээдэг байна.{" "}
        </span>
        <span id="t9_15" class="t s4_15">
          •{" "}
        </span>
        <span id="ta_15" class="t s1_15">
          Юмс үзэгдлийг бодитоор харж, үйлдэж танин мэддэг бодит сэтгэхүй
          давамгайлна.{" "}
        </span>
        <span id="tb_15" class="t s4_15">
          •{" "}
        </span>
        <span id="tc_15" class="t s1_15">
          Мэдээллийг утгаар нь тогтоох чадвартай байх тусам богино хугацаанд
          харж,{" "}
        </span>
        <span id="td_15" class="t s1_15">
          сонсож тогтоох чадвар нь сайн байна.{" "}
        </span>
        <span id="te_15" class="t s4_15">
          •{" "}
        </span>
        <span id="tf_15" class="t s1_15">
          Үзэгдэл юмсыг хурдан хугацаанд ерөнхий дүр байдлаар хүртдэг, харин
          бүтэц,{" "}
        </span>
        <span id="tg_15" class="t s1_15">
          бүрэлдэхүүнийг ажиглан хүртэхдээ нэлээд удаан хугацаа шаарддаг. Юмсыг
          өнгө,{" "}
        </span>
        <span id="th_15" class="t s1_15">
          хэлбэр, дүрс зэргээр нь ангилах чадвартай.{" "}
        </span>
        <span id="ti_15" class="t s4_15">
          •{" "}
        </span>
        <span id="tj_15" class="t s1_15">
          Энгийн богино өгүүлбэрээр гадаад шинж байдлыг тоочин ярих нь давамгай,
          9{" "}
        </span>
        <span id="tk_15" class="t s1_15">
          наснаас дэлгэрэнгүй нийлмэл өгүүлбэрээр санаагаа илэрхийлэх нь
          сайжирдаг.{" "}
        </span>
        <span id="tl_15" class="t s4_15">
          •{" "}
        </span>
        <span id="tm_15" class="t s1_15">
          Хүүхдүүд богино хугацаанд ойролцоогоор 6-7 дүрс, зургийг хараад
          тогтоож сэргээн{" "}
        </span>
        <span id="tn_15" class="t s1_15">
          санадаг бол тоо/цифр хараад 3-4-ийг тогтоож сэргээн санадаг онцлогтой
          байна.{" "}
        </span>
        <span id="to_15" class="t s4_15">
          •{" "}
        </span>
        <span id="tp_15" class="t s1_15">
          6-7 настай хүүхдүүд ахуйн түвшний агуулгатай 22 үйл явдал бүхий утгын
          нэгжээс{" "}
        </span>
        <span id="tq_15" class="t s1_15">
          13-14 утгыг сэргээн санаж ярьсан бол 8-10 насанд 24 утгын нэгжтэй
          энгийн{" "}
        </span>
        <span id="tr_15" class="t s1_15">
          үлгэрээс 11-13 хүртэлх утгын нэгжийг сэргээн санаж байна.{" "}
        </span>
        <span id="ts_15" class="t s4_15">
          •{" "}
        </span>
        <span id="tt_15" class="t s1_15">
          7 наснаас эхлэн ухагдахууны талаарх ойлголтын төлөвшил нэмэгдэж байгаа{" "}
        </span>
        <span id="tu_15" class="t s1_15">
          ба 8-10 настай сурагчдын сэтгэхүйн задлан шинжлэх, нэгтгэн дүгнэх,
          үйлдлийг{" "}
        </span>
        <span id="tv_15" class="t s1_15">
          юмсын гол шинжийг олох, сэтгэхүйн харьцуулах үйлдэл нь дундаж буюу
          дунджаас{" "}
        </span>
        <span id="tw_15" class="t s1_15">
          доогуур түвшинд байгаа нь эдгээр үйлдлүүд сул хөгжилтэй байгааг
          харуулж байна.{" "}
        </span>
        <span id="tx_15" class="t s4_15">
          •{" "}
        </span>
        <span id="ty_15" class="t s1_15">
          Хэл яриа-сэтгэхүйн логик чадварын хувьд үгийн утга тайлбарлах, энгийн
          ойлголт,{" "}
        </span>
        <span id="tz_15" class="t s1_15">
          мэдлэг, сэтгэхүйн задлан шинжлэх, нэгтгэх чадварууд нь 6-10 насанд
          чухал байдаг{" "}
        </span>
        <span id="t10_15" class="t s1_15">
          байна.{" "}
        </span>
        <span id="t11_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t12_15" class="t s1_15">
          Бага ангид хүүхдүүдэд бодит үгийн нөөц давамгайлдаг ба ярих чадварын
          хувьд{" "}
        </span>
        <span id="t13_15" class="t s1_15">
          харагдаж байгаа зүйлийг байгаагаар нь тоочин энгийн богино өгүүлбэрээр{" "}
        </span>
        <span id="t14_15" class="t s1_15">
          ярих нь илүү давамгайлдаг байна. Харин салаа үг, тэмдэг нэрийн
          эзэмшилтийг{" "}
        </span>
        <span id="t15_15" class="t s1_15">
          сайжруулахад анхаарах нь зүйтэй байна.{" "}
        </span>
        <span id="t16_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t17_15" class="t s1_15">
          Математик тооцоолол хийх, юмсыг шинж байдлаар нь задлан шинжлэх, ижил{" "}
        </span>
        <span id="t18_15" class="t s1_15">
          төсөөтэйгөөр ангилах чадвар нь сэтгэхүй хэл ярианы бусад чадвартай
          харилцан{" "}
        </span>
        <span id="t19_15" class="t s1_15">
          хамаарал өндөртэй тул тухайн чадвар нь танин мэдэхүйд голлох үүрэгтэй
          байна.{" "}
        </span>
        <span id="t1a_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t1b_15" class="t s1_15">
          2 минутад зорилго чиглэлтэйгээр санаа бодлоо илэрхийлэхдээ дунджаар
          34-35{" "}
        </span>
        <span id="t1c_15" class="t s1_15">
          үгийг хэрэглэсэн байна.{" "}
        </span>
        <span id="t1d_15" class="t s3_15">
          Нийгэмшлийн онцлог:{" "}
        </span>
        <span id="t1e_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t1f_15" class="t s1_15">
          Бага боловсролын 6-10 настай хүүхдүүдийн зан төлөвийн ерөнхий онцлог
          нь ажил{" "}
        </span>
        <span id="t1g_15" class="t s1_15">
          үүрэгтээ хариуцлагатай хандах, өөрийн зан төлөвөө зохицуулах, үйл
          ажиллагаанд{" "}
        </span>
        <span id="t1h_15" class="t s1_15">
          идэвхтэй хандах, оролцох чадвар сайн, бусад хүмүүстэй харилцахдаа
          анхааралтай{" "}
        </span>
        <span id="t1i_15" class="t s1_15">
          хандах, анхаарал халамж тавих чадвартай, сэтгэл санааны хувьд
          тогтвортой{" "}
        </span>
        <span id="t1j_15" class="t s1_15">
          байдаг байна. Энэ насныхан сэтгэл санааны хувьд өөдрөг айдас түгшүүрт
          автагдах{" "}
        </span>
        <span id="t1k_15" class="t s1_15">
          нь бага байна.{" "}
        </span>
        <span id="t1l_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t1m_15" class="t s1_15">
          Энэ насны хүүхдүүдийн өөрийн сэтгэлийн хөдөлгөөнийг удирдах чадвар нас{" "}
        </span>
        <span id="t1n_15" class="t s1_15">
          нэмэгдэхэд бага зэрэг буурч байгаа нь бие даасан, шүүмжлэлт сэтгэхүйн{" "}
        </span>
        <span id="t1o_15" class="t s1_15">
          хөгждөгтэй холбоотой юм.{" "}
        </span>
        <span id="t1p_15" class="t s4_15">
          •{" "}
        </span>
        <span id="t1q_15" class="t s1_15">
          Хүүхдүүд бусадтай нээлттэй, идэвхтэй харилцах болон бусад хүмүүсийн
          сэтгэл{" "}
        </span>
        <span id="t1r_15" class="t s1_15">
          санааг ойлгож харилцах чадвартай болсон байдаг байна.{" "}
        </span>
        <span id="t1s_15" class="t s1_15">
          6-10 настай хүүхдүүд анхаарлаа төвлөрүүлэн хурдтай ажиллах нь
          хангалтгүй,{" "}
        </span>
        <span id="t1t_15" class="t s1_15">
          дүрс тэмдэгт хэлбэрийн мэдээллийг хараад тогтоох нь цифр, үг гэх мэт
          хийсвэр{" "}
        </span>
        <span id="t1u_15" class="t s1_15">
          мэдээллийг тогтоохоос илүү, энгийн богино өгүүллэг, үлгэр уншаад
          утгаар нь тогтоох{" "}
        </span>
        <span id="t1v_15" class="t s1_15">
          нь дундаж түвшинд байна.{" "}
        </span>
        <span id="t1w_15" class="t s1_15">
          Утгын ой тогтоолт сайтай байх тусмаа анхаарал, богино хугацааны ой
          тогтоолт,{" "}
        </span>
        <span id="t1x_15" class="t s1_15">
          мэдээллийг хүртэх чадвар сайн байна.{" "}
        </span>
      </div>
    </div>
  );
}
