import React from "react";
;

export default function ThirtyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p38" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }`}
        </style>
        <style type="text/css">
          {` #t1_38 {
          left: 34px;
          bottom: 19px;
          letter-spacing: 0.16px;
        }
        #t2_38 {
          left: 55px;
          bottom: 1217px;
          letter-spacing: -0.14px;
          word-spacing: 0.04px;
        }
        #t3_38 {
          left: 110px;
          bottom: 903px;
          letter-spacing: -0.13px;
          word-spacing: 0.03px;
        }
        #t4_38 {
          left: 153px;
          bottom: 879px;
        }
        #t5_38 {
          left: 180px;
          bottom: 879px;
          letter-spacing: -0.15px;
          word-spacing: -0.63px;
        }
        #t6_38 {
          left: 180px;
          bottom: 861px;
          letter-spacing: -0.17px;
          word-spacing: 0.08px;
        }
        #t7_38 {
          left: 153px;
          bottom: 842px;
        }
        #t8_38 {
          left: 180px;
          bottom: 842px;
          letter-spacing: -0.19px;
          word-spacing: 6.36px;
        }
        #t9_38 {
          left: 556px;
          bottom: 842px;
          letter-spacing: -0.15px;
          word-spacing: 6.32px;
        }
        #ta_38 {
          left: 180px;
          bottom: 824px;
          letter-spacing: -0.14px;
          word-spacing: 0.04px;
        }
        #tb_38 {
          left: 110px;
          bottom: 600px;
          letter-spacing: -0.11px;
          word-spacing: 0.02px;
        }
        #tc_38 {
          left: 153px;
          bottom: 576px;
        }
        #td_38 {
          left: 180px;
          bottom: 576px;
          letter-spacing: -0.14px;
          word-spacing: -1.66px;
        }
        #te_38 {
          left: 180px;
          bottom: 558px;
          letter-spacing: -0.16px;
        }
        #tf_38 {
          left: 153px;
          bottom: 540px;
        }
        #tg_38 {
          left: 180px;
          bottom: 540px;
          letter-spacing: -0.13px;
          word-spacing: 0.03px;
        }
        #th_38 {
          left: 110px;
          bottom: 244px;
          letter-spacing: -0.14px;
          word-spacing: 3.08px;
        }
        #ti_38 {
          left: 110px;
          bottom: 226px;
          letter-spacing: -0.15px;
          word-spacing: 9.14px;
        }
        #tj_38 {
          left: 110px;
          bottom: 207px;
          letter-spacing: -0.16px;
          word-spacing: -0.73px;
        }
        #tk_38 {
          left: 110px;
          bottom: 189px;
          letter-spacing: -0.13px;
          word-spacing: -0.77px;
        }
        #tl_38 {
          left: 110px;
          bottom: 171px;
          letter-spacing: -0.15px;
          word-spacing: 2px;
        }
        #tm_38 {
          left: 110px;
          bottom: 153px;
          letter-spacing: -0.13px;
          word-spacing: 0.04px;
        }

        .s0_38 {
          font-size: 18px;
          font-family: MinionPro-Regular_6m;
          color: #fff;
        }
        .s1_38 {
          font-size: 17px;
          font-family: ArialMT_5-;
          color: #231f20;
        }
        .s2_38 {
          font-size: 17px;
          font-family: Arial-ItalicMT_6y;
          color: #231f20;
        }`}
        </style>
        <style id="fonts38" type="text/css">
          {` @font-face {
          font-family: Arial-ItalicMT_6y;
          src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_5-;
          src: url("fonts/ArialMT_5-.woff") format("woff");
        }

        @font-face {
          font-family: MinionPro-Regular_6m;
          src: url("fonts/MinionPro-Regular_6m.woff") format("woff");`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg38" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="38/38.svg"
          type="image/svg+xml"
          id="pdf38"
          style={pdfStyle}></object>
      </div>
      <div class="text-container">
        <span id="t1_38" class="t s0_38">
          36{" "}
        </span>
        <span id="t2_38" class="t s1_38">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар)
        </span>
        <span id="t3_38" class="t s1_38">
          Алхам 3. Дизайны шинэ санаа, шийдэл гаргах
        </span>
        <span id="t4_38" class="t s1_38">
          -{" "}
        </span>
        <span id="t5_38" class="t s1_38">
          Судалгаа болон баримт мэдээлэл үндэслэн сургуулийн орчны төлөвлөлтийн
          план
        </span>
        <span id="t6_38" class="t s1_38">
          зураг зурах{" "}
        </span>
        <span id="t7_38" class="t s1_38">
          -{" "}
        </span>
        <span id="t8_38" class="t s1_38">
          Төлөвлөлт, санаагаа сайжруулах, хөгжүүлэх{" "}
        </span>
        <span id="t9_38" class="t s2_38">
          (Олон нийт болон мэргэжлийн{" "}
        </span>
        <span id="ta_38" class="t s2_38">
          хүмүүсийн санал, зөвлөгөөг авах)
        </span>
        <span id="tb_38" class="t s1_38">
          Алхам 4. Бүтээх, турших{" "}
        </span>
        <span id="tc_38" class="t s1_38">
          -{" "}
        </span>
        <span id="td_38" class="t s1_38">
          Сургуулийн орчны төлөвлөлтийн макет хийх материал, нөөц, технологи
          дарааллыг
        </span>
        <span id="te_38" class="t s1_38">
          төлөвлөх{" "}
        </span>
        <span id="tf_38" class="t s1_38">
          -{" "}
        </span>
        <span id="tg_38" class="t s1_38">
          Төлөвлөлтийн дагуу макет хийх{" "}
        </span>
        <span id="th_38" class="t s1_38">
          Ногоон байгууламж нь агаарыг цэвэршүүлж, чийгшүүлэх, тоосжилтыг
          багасгах, агаарыг
        </span>
        <span id="ti_38" class="t s1_38">
          бохирдлоос хамгаалах, дуу чимээг шингээх, ажиллаж, амьдрах тохьтой
          нөхцлийг
        </span>
        <span id="tj_38" class="t s1_38">
          бүрдүүлэх экосистемийг зохицуулдгаараа өндөр ач холбогдолтой. Ногоон
          байгууламж хийх
        </span>
        <span id="tk_38" class="t s1_38">
          цэцэрлэгжүүлэх төлөвлөгөөг гаргах хэрэгтэй. Иймд түргэн ургадаг мод,
          сөөг тарих, хөрсний
        </span>
        <span id="tl_38" class="t s1_38">
          бичил орчин бүрдүүлэх, шилмүүст мод болон бусад тохиромжтой ургамал
          тарих талаар
        </span>
        <span id="tm_38" class="t s1_38">
          судлах хэрэгтэй.{" "}
        </span>
      </div>
    </div>
  );
}
