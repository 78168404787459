import React from "react";
;

export default function Three() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_3{left:844px;bottom:21px;}
#t2_3{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_3{left:419px;bottom:1102px;letter-spacing:-0.4px;}
#t4_3{left:110px;bottom:1054px;letter-spacing:-0.4px;word-spacing:0.29px;}
#t5_3{left:743px;bottom:1054px;}
#t6_3{left:110px;bottom:1036px;letter-spacing:-1.04px;}
#t7_3{left:162px;bottom:1036px;letter-spacing:-0.22px;word-spacing:0.1px;}
#t8_3{left:743px;bottom:1036px;}
#t9_3{left:110px;bottom:1018px;letter-spacing:-0.31px;word-spacing:0.19px;}
#ta_3{left:743px;bottom:1018px;}
#tb_3{left:110px;bottom:999px;letter-spacing:-0.12px;}
#tc_3{left:179px;bottom:999px;letter-spacing:-0.17px;}
#td_3{left:743px;bottom:999px;}
#te_3{left:110px;bottom:981px;letter-spacing:-0.21px;word-spacing:0.09px;}
#tf_3{left:743px;bottom:981px;}
#tg_3{left:110px;bottom:963px;letter-spacing:-0.24px;word-spacing:0.12px;}
#th_3{left:110px;bottom:945px;letter-spacing:-0.14px;}
#ti_3{left:743px;bottom:945px;}
#tj_3{left:110px;bottom:927px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tk_3{left:743px;bottom:927px;}
#tl_3{left:110px;bottom:909px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tm_3{left:743px;bottom:909px;}
#tn_3{left:110px;bottom:891px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_3{left:743px;bottom:891px;}
#tp_3{left:110px;bottom:873px;letter-spacing:-0.23px;word-spacing:0.11px;}
#tq_3{left:743px;bottom:873px;}
#tr_3{left:110px;bottom:854px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ts_3{left:734px;bottom:854px;letter-spacing:-0.11px;}
#tt_3{left:110px;bottom:836px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tu_3{left:734px;bottom:836px;letter-spacing:-0.11px;}
#tv_3{left:110px;bottom:818px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tw_3{left:735px;bottom:818px;letter-spacing:-1.34px;}
#tx_3{left:110px;bottom:800px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ty_3{left:734px;bottom:800px;letter-spacing:-0.11px;}

.s0_3{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_3{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_3{font-size:17px;font-family:ArialMT_5-;color:#375FB2;}
.s3_3{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts3" type="text/css">
          {`
          @font-face {
            font-family: ArialMT_5-;
            src: url("fonts/ArialMT_5-.woff") format("woff");
          }

          @font-face {
            font-family: ArialMT_5q;
            src: url("fonts/ArialMT_5q.woff") format("woff");
          }

          @font-face {
            font-family: MinionPro-Regular_6m;
            src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
          }
      `}
        </style>
      </head>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg2" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style={pdfStyle}></object>
      </div>

      <div class="text-container">
        <span id="t1_3" class="t s0_3">
          1{" "}
        </span>
        <span id="t2_3" class="t s1_3">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_3" class="t s1_3">
          АГУУЛГА{" "}
        </span>
        <span id="t4_3" class="t s2_3">
          НЭГ. ҮНДЭСЛЭЛ{" "}
        </span>
        <span id="t5_3" class="t s3_3">
          2{" "}
        </span>
        <span id="t6_3" class="t s2_3">
          ХОЁР.{" "}
        </span>
        <span id="t7_3" class="t s2_3">
          ХИЧЭЭЛЭЭС ГАДУУРХ ҮЙЛ АЖИЛЛАГААНЫ ТУХАЙ ОЙЛГОЛТ{" "}
        </span>
        <span id="t8_3" class="t s3_3">
          2{" "}
        </span>
        <span id="t9_3" class="t s2_3">
          ГУРАВ. ЗОРИЛГО{" "}
        </span>
        <span id="ta_3" class="t s3_3">
          4{" "}
        </span>
        <span id="tb_3" class="t s2_3">
          ДӨРӨВ.{" "}
        </span>
        <span id="tc_3" class="t s2_3">
          ЗОРИЛТ{" "}
        </span>
        <span id="td_3" class="t s3_3">
          4{" "}
        </span>
        <span id="te_3" class="t s2_3">
          ТАВ. ЗОРИЛТУУДЫН ХҮРЭЭНД ХЭРЭГЖҮҮЛЭХ ҮЙЛ АЖИЛЛАГАА{" "}
        </span>
        <span id="tf_3" class="t s3_3">
          4{" "}
        </span>
        <span id="tg_3" class="t s2_3">
          ЗУРГАА. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫГ ЗОХИОН БАЙГУУЛАХ{" "}
        </span>
        <span id="th_3" class="t s2_3">
          МЕНЕЖЕМЕНТ{" "}
        </span>
        <span id="ti_3" class="t s3_3">
          5{" "}
        </span>
        <span id="tj_3" class="t s2_3">
          6.1. Хичээлээс гадуурх сургалтаар хөгжүүлэх чадвар:{" "}
        </span>
        <span id="tk_3" class="t s3_3">
          6{" "}
        </span>
        <span id="tl_3" class="t s2_3">
          6.2. ХГСА-ыг зохион байгуулах хэлбэр{" "}
        </span>
        <span id="tm_3" class="t s3_3">
          7{" "}
        </span>
        <span id="tn_3" class="t s2_3">
          6.3. Орчин, хэрэглэгдэхүүн{" "}
        </span>
        <span id="to_3" class="t s3_3">
          8{" "}
        </span>
        <span id="tp_3" class="t s2_3">
          ДОЛОО. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫГ ҮНЭЛЭХ{" "}
        </span>
        <span id="tq_3" class="t s3_3">
          9{" "}
        </span>
        <span id="tr_3" class="t s2_3">
          Эх сурвалж:{" "}
        </span>
        <span id="ts_3" class="t s1_3">
          10{" "}
        </span>
        <span id="tt_3" class="t s2_3">
          Хавсралт 1. Нэмэлт хөтөлбөрийн жагсаалт{" "}
        </span>
        <span id="tu_3" class="t s1_3">
          10{" "}
        </span>
        <span id="tv_3" class="t s2_3">
          Хавсралт 2. Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн төлөвлөгөө{" "}
        </span>
        <span id="tw_3" class="t s1_3">
          11{" "}
        </span>
        <span id="tx_3" class="t s2_3">
          ХГСА-ын үлгэрчилсэн хөтөлбөрийг хэрэгжүүлэх зөвлөмж{" "}
        </span>
        <span id="ty_3" class="t s1_3">
          12{" "}
        </span>
      </div>
    </div>
  );
}
