import React from "react";
;

export default function SixZero() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`
#t1_60{left:34px;bottom:19px;letter-spacing:0.16px;}
#t2_60{left:55px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_60{left:324px;bottom:1137px;letter-spacing:0.12px;word-spacing:0.02px;}
#t4_60{left:118px;bottom:1111px;}
#t5_60{left:152px;bottom:1111px;letter-spacing:0.12px;word-spacing:0.02px;}
#t6_60{left:152px;bottom:1094px;letter-spacing:0.1px;word-spacing:0.04px;}
#t7_60{left:152px;bottom:1078px;letter-spacing:0.06px;}
#t8_60{left:165px;bottom:1062px;letter-spacing:0.11px;word-spacing:0.03px;}
#t9_60{left:165px;bottom:1045px;letter-spacing:0.12px;word-spacing:0.02px;}
#ta_60{left:165px;bottom:1029px;letter-spacing:0.02px;word-spacing:0.12px;}
#tb_60{left:165px;bottom:1012px;letter-spacing:0.1px;word-spacing:0.04px;}
#tc_60{left:165px;bottom:996px;letter-spacing:0.04px;word-spacing:0.09px;}
#td_60{left:166px;bottom:979px;letter-spacing:0.07px;word-spacing:0.06px;}
#te_60{left:166px;bottom:963px;letter-spacing:0.09px;word-spacing:0.05px;}
#tf_60{left:152px;bottom:936px;letter-spacing:0.11px;word-spacing:0.03px;}
#tg_60{left:179px;bottom:920px;letter-spacing:0.11px;word-spacing:0.04px;}
#th_60{left:179px;bottom:903px;letter-spacing:0.1px;word-spacing:0.04px;}
#ti_60{left:179px;bottom:887px;word-spacing:0.14px;}
#tj_60{left:277px;bottom:860px;letter-spacing:0.1px;word-spacing:0.04px;}
#tk_60{left:118px;bottom:834px;}
#tl_60{left:118px;bottom:808px;}
#tm_60{left:118px;bottom:781px;}
#tn_60{left:110px;bottom:742px;letter-spacing:-0.12px;word-spacing:0.01px;}
#to_60{left:110px;bottom:724px;letter-spacing:-0.15px;word-spacing:4.88px;}
#tp_60{left:110px;bottom:706px;letter-spacing:-0.14px;word-spacing:-0.32px;}
#tq_60{left:110px;bottom:687px;letter-spacing:-0.15px;word-spacing:2.81px;}
#tr_60{left:110px;bottom:669px;letter-spacing:-0.09px;}
#ts_60{left:110px;bottom:651px;letter-spacing:-0.14px;word-spacing:2.71px;}
#tt_60{left:110px;bottom:633px;letter-spacing:-0.15px;word-spacing:2.48px;}
#tu_60{left:110px;bottom:615px;letter-spacing:-0.14px;word-spacing:-0.05px;}
#tv_60{left:110px;bottom:597px;letter-spacing:-0.12px;word-spacing:-1.35px;}
#tw_60{left:110px;bottom:561px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tx_60{left:225px;bottom:536px;letter-spacing:0.01px;}
#ty_60{left:573px;bottom:536px;letter-spacing:-0.05px;}
#tz_60{left:410px;bottom:509px;letter-spacing:0.08px;word-spacing:0.06px;}
#t10_60{left:509px;bottom:509px;letter-spacing:0.08px;word-spacing:0.05px;}
#t11_60{left:612px;bottom:509px;letter-spacing:0.08px;word-spacing:0.04px;}
#t12_60{left:721px;bottom:509px;letter-spacing:0.09px;word-spacing:0.05px;}
#t13_60{left:118px;bottom:483px;letter-spacing:0.11px;word-spacing:0.03px;}
#t14_60{left:118px;bottom:466px;letter-spacing:0.17px;word-spacing:-0.01px;}
#t15_60{left:118px;bottom:440px;letter-spacing:0.11px;word-spacing:0.03px;}
#t16_60{left:118px;bottom:423px;letter-spacing:0.08px;word-spacing:0.06px;}
#t17_60{left:118px;bottom:397px;letter-spacing:0.1px;word-spacing:0.04px;}
#t18_60{left:118px;bottom:381px;letter-spacing:0.12px;word-spacing:0.02px;}
#t19_60{left:118px;bottom:364px;letter-spacing:0.11px;}
#t1a_60{left:118px;bottom:338px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1b_60{left:118px;bottom:321px;letter-spacing:0.12px;}
#t1c_60{left:118px;bottom:295px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1d_60{left:118px;bottom:278px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1e_60{left:118px;bottom:252px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1f_60{left:118px;bottom:235px;letter-spacing:0.14px;word-spacing:0.01px;}
#t1g_60{left:118px;bottom:209px;letter-spacing:0.14px;word-spacing:0.01px;}
#t1h_60{left:118px;bottom:192px;letter-spacing:0.16px;word-spacing:-0.01px;}
#t1i_60{left:118px;bottom:166px;letter-spacing:0.12px;word-spacing:0.01px;}
#t1j_60{left:118px;bottom:150px;letter-spacing:0.13px;}

.s0_60{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_60{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_60{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s3_60{font-size:15px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts60" type="text/css">
          {`
@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg60" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="60/60.svg"
          type="image/svg+xml"
          id="pdf60"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_60" class="t s0_60">
          58{" "}
        </span>
        <span id="t2_60" class="t s1_60">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_60" class="t s2_60">
          Бие бялдар, авьяас чадварыг хөгжүүлэх{" "}
        </span>
        <span id="t4_60" class="t s2_60">
          1{" "}
        </span>
        <span id="t5_60" class="t s2_60">
          Спортын дугуйлангийн{" "}
        </span>
        <span id="t6_60" class="t s2_60">
          үйл ажиллагааг зохион{" "}
        </span>
        <span id="t7_60" class="t s2_60">
          байгуулах{" "}
        </span>
        <span id="t8_60" class="t s2_60">
          · Сагсан бөмбөг{" "}
        </span>
        <span id="t9_60" class="t s2_60">
          · Хөл бөмбөг{" "}
        </span>
        <span id="ta_60" class="t s2_60">
          · Гар бөмбөг{" "}
        </span>
        <span id="tb_60" class="t s2_60">
          · Бөхийн төрлүүд{" "}
        </span>
        <span id="tc_60" class="t s2_60">
          · Хөнгөн атлетик{" "}
        </span>
        <span id="td_60" class="t s2_60">
          · Шатар, даам гэх мэт{" "}
        </span>
        <span id="te_60" class="t s2_60">
          оюуны спортын төрлүүд{" "}
        </span>
        <span id="tf_60" class="t s2_60">
          Соёл, урлагийн дугуйлан{" "}
        </span>
        <span id="tg_60" class="t s2_60">
          ·Хөгжмийн төрлүүд{" "}
        </span>
        <span id="th_60" class="t s2_60">
          ·Бүжгийн төрлүүд{" "}
        </span>
        <span id="ti_60" class="t s2_60">
          ·Дууны төрлүүд г.м{" "}
        </span>
        <span id="tj_60" class="t s2_60">
          Мэдээллийн технологийн зохистой хэрэглээг дэмжих;{" "}
        </span>
        <span id="tk_60" class="t s2_60">
          1{" "}
        </span>
        <span id="tl_60" class="t s3_60">
          2{" "}
        </span>
        <span id="tm_60" class="t s2_60">
          3{" "}
        </span>
        <span id="tn_60" class="t s1_60">
          Жишээ 4: Ангийн жилийн төлөвлөгөө{" "}
        </span>
        <span id="to_60" class="t s1_60">
          Анги хамт олон хурлаараа ярилцан хэлэлцэж, асуудлыг илрүүлж хэрхэн
          шийдвэрлэх{" "}
        </span>
        <span id="tp_60" class="t s1_60">
          сайжруулах боломжийг тодорхойлж зорилгоо дэвшүүлнэ. Хамт олны зорилго
          хүрэх үр дүнг{" "}
        </span>
        <span id="tq_60" class="t s1_60">
          товч ойлгомжтой үгээр тодорхойлогдсон байх бөгөөд сурагч, эцэг эх,
          асран хамгаалагч{" "}
        </span>
        <span id="tr_60" class="t s1_60">
          дэмжсэн, хэрэгжүүлэхийг хүссэн үр дүнг хүлээсэн байна.{" "}
        </span>
        <span id="ts_60" class="t s1_60">
          Ангийн төлөвлөгөө нь анги хамт олны төлөвлшил хөгжлийг улам
          сайжруулах, зорилгоо{" "}
        </span>
        <span id="tt_60" class="t s1_60">
          бодит үр дүн болгон хэрэгжүүлэхэд чиглэсэн хамтран боловсруулсан
          баримт бичиг юм.{" "}
        </span>
        <span id="tu_60" class="t s1_60">
          Сургууль зорилгодоо хүрэхэд анги бүрийн зорилго, жилийн төлөвлөгөө
          чухал үүрэгтэй юм.{" "}
        </span>
        <span id="tv_60" class="t s1_60">
          Иймд хүүхэд нэг бүрийн хүсэл хэрэгцээг тусгасан, сургуулийн зорилгоо
          дэмжсэн байх ёстой.{" "}
        </span>
        <span id="tw_60" class="t s1_60">
          Ангийн хичээлээс гадуурх сургалтын ажлын төлөвлөлт{" "}
        </span>
        <span id="tx_60" class="t s2_60">
          Чиглэл{" "}
        </span>
        <span id="ty_60" class="t s2_60">
          Улирал{" "}
        </span>
        <span id="tz_60" class="t s2_60">
          I улирал{" "}
        </span>
        <span id="t10_60" class="t s2_60">
          II улирал{" "}
        </span>
        <span id="t11_60" class="t s2_60">
          III улирал{" "}
        </span>
        <span id="t12_60" class="t s2_60">
          IV улирал{" "}
        </span>
        <span id="t13_60" class="t s2_60">
          Гэр бүлийн хүрээнд олгох хүмүүжил,{" "}
        </span>
        <span id="t14_60" class="t s2_60">
          төлөвшлийг дэмжих{" "}
        </span>
        <span id="t15_60" class="t s2_60">
          Эрүүл, аюулгүй амьдрах арга{" "}
        </span>
        <span id="t16_60" class="t s2_60">
          ухаанд сургах;{" "}
        </span>
        <span id="t17_60" class="t s2_60">
          Болзошгүй эрсдэл, зөрчлөөс{" "}
        </span>
        <span id="t18_60" class="t s2_60">
          урьдчилан сэргийлэх ур чадвар{" "}
        </span>
        <span id="t19_60" class="t s2_60">
          эзэмшүүлэх;{" "}
        </span>
        <span id="t1a_60" class="t s2_60">
          Бие бялдар, авьяас чадварыг{" "}
        </span>
        <span id="t1b_60" class="t s2_60">
          хөгжүүлэх;{" "}
        </span>
        <span id="t1c_60" class="t s2_60">
          Мэдээллийн технологийн зохистой{" "}
        </span>
        <span id="t1d_60" class="t s2_60">
          хэрэглээг дэмжих;{" "}
        </span>
        <span id="t1e_60" class="t s2_60">
          Суралцагчдын нийгэм, олон нийтийн{" "}
        </span>
        <span id="t1f_60" class="t s2_60">
          үйл ажиллагааг дэмжих{" "}
        </span>
        <span id="t1g_60" class="t s2_60">
          Эх оронч болон хөдөлмөрийн{" "}
        </span>
        <span id="t1h_60" class="t s2_60">
          хүмүүжлийг дэмжих;{" "}
        </span>
        <span id="t1i_60" class="t s2_60">
          Байгаль, экологийн хүмүүжлийг{" "}
        </span>
        <span id="t1j_60" class="t s2_60">
          төлөвшүүлэх;{" "}
        </span>
      </div>
    </div>
  );
}
