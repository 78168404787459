import React from "react";
;

export default function FourOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_41{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_41{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_41{left:153px;bottom:1157px;}
#t4_41{left:180px;bottom:1157px;letter-spacing:-0.15px;word-spacing:-0.38px;}
#t5_41{left:180px;bottom:1139px;letter-spacing:-0.18px;word-spacing:1.81px;}
#t6_41{left:180px;bottom:1121px;letter-spacing:-0.28px;}
#t7_41{left:110px;bottom:1096px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t8_41{left:153px;bottom:1072px;}
#t9_41{left:180px;bottom:1072px;letter-spacing:-0.14px;word-spacing:4.29px;}
#ta_41{left:180px;bottom:1054px;letter-spacing:-0.14px;word-spacing:4.67px;}
#tb_41{left:180px;bottom:1036px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tc_41{left:153px;bottom:1018px;}
#td_41{left:180px;bottom:1018px;letter-spacing:-0.14px;word-spacing:6.5px;}
#te_41{left:180px;bottom:999px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tf_41{left:153px;bottom:981px;}
#tg_41{left:180px;bottom:981px;letter-spacing:-0.15px;word-spacing:7.9px;}
#th_41{left:180px;bottom:963px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ti_41{left:110px;bottom:939px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tj_41{left:153px;bottom:915px;}
#tk_41{left:180px;bottom:915px;letter-spacing:-0.12px;word-spacing:5.76px;}
#tl_41{left:180px;bottom:897px;letter-spacing:-0.16px;word-spacing:1.92px;}
#tm_41{left:180px;bottom:878px;letter-spacing:-0.11px;}
#tn_41{left:153px;bottom:860px;}
#to_41{left:180px;bottom:860px;letter-spacing:-0.15px;word-spacing:4.31px;}
#tp_41{left:180px;bottom:842px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tq_41{left:153px;bottom:824px;}
#tr_41{left:180px;bottom:824px;letter-spacing:-0.14px;word-spacing:6.15px;}
#ts_41{left:180px;bottom:806px;letter-spacing:-0.13px;word-spacing:1.38px;}
#tt_41{left:180px;bottom:788px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tu_41{left:110px;bottom:745px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tv_41{left:110px;bottom:727px;letter-spacing:-0.14px;word-spacing:4.11px;}
#tw_41{left:110px;bottom:709px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tx_41{left:110px;bottom:691px;letter-spacing:-0.18px;word-spacing:5.09px;}
#ty_41{left:110px;bottom:673px;letter-spacing:-0.14px;word-spacing:4.41px;}
#tz_41{left:110px;bottom:655px;letter-spacing:-0.14px;word-spacing:2.55px;}
#t10_41{left:110px;bottom:637px;letter-spacing:-0.14px;word-spacing:3.23px;}
#t11_41{left:110px;bottom:618px;letter-spacing:-0.16px;}
#t12_41{left:110px;bottom:600px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t13_41{left:110px;bottom:564px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t14_41{left:110px;bottom:546px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t15_41{left:153px;bottom:528px;}
#t16_41{left:180px;bottom:528px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t17_41{left:153px;bottom:510px;}
#t18_41{left:180px;bottom:510px;letter-spacing:-0.13px;word-spacing:0.05px;}
#t19_41{left:271px;bottom:510px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1a_41{left:153px;bottom:491px;}
#t1b_41{left:180px;bottom:491px;letter-spacing:-0.12px;word-spacing:5.58px;}
#t1c_41{left:180px;bottom:473px;letter-spacing:-0.15px;}
#t1d_41{left:110px;bottom:437px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1e_41{left:110px;bottom:419px;letter-spacing:-0.11px;word-spacing:0.48px;}
#t1f_41{left:110px;bottom:401px;letter-spacing:-0.15px;word-spacing:0.64px;}
#t1g_41{left:110px;bottom:383px;letter-spacing:-0.17px;word-spacing:-1.2px;}
#t1h_41{left:110px;bottom:365px;letter-spacing:-0.15px;word-spacing:3.09px;}
#t1i_41{left:110px;bottom:346px;letter-spacing:-0.11px;word-spacing:1.39px;}
#t1j_41{left:110px;bottom:328px;letter-spacing:-0.16px;word-spacing:-0.32px;}
#t1k_41{left:110px;bottom:310px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1l_41{left:110px;bottom:292px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1m_41{left:110px;bottom:274px;letter-spacing:-0.05px;}
#t1n_41{left:138px;bottom:274px;letter-spacing:-0.15px;word-spacing:0.52px;}
#t1o_41{left:138px;bottom:256px;letter-spacing:-0.13px;word-spacing:0.96px;}
#t1p_41{left:138px;bottom:238px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1q_41{left:110px;bottom:219px;letter-spacing:-0.05px;}
#t1r_41{left:138px;bottom:219px;letter-spacing:-0.13px;word-spacing:0.81px;}
#t1s_41{left:138px;bottom:201px;letter-spacing:-0.14px;word-spacing:-0.2px;}
#t1t_41{left:138px;bottom:183px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1u_41{left:110px;bottom:165px;letter-spacing:-0.05px;}
#t1v_41{left:138px;bottom:165px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1w_41{left:153px;bottom:141px;}
#t1x_41{left:180px;bottom:141px;letter-spacing:-0.14px;word-spacing:-1.31px;}
#t1y_41{left:180px;bottom:123px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1z_41{left:153px;bottom:105px;}
#t20_41{left:180px;bottom:105px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t21_41{left:153px;bottom:86px;}
#t22_41{left:180px;bottom:86px;letter-spacing:-0.12px;word-spacing:1.08px;}
#t23_41{left:180px;bottom:68px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_41{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_41{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_41{font-size:17px;font-family:Arial-ItalicMT_6y;color:#231F20;}
.s3_41{font-size:17px;font-family:Arial-ItalicMT_6y;color:#4A7EBB;}
.s4_41{font-size:17px;font-family:ArialMT_5q;color:#231F20;}`}
        </style>

        <style id="fonts41" type="text/css">
          {`@font-face {
	font-family: Arial-ItalicMT_6y;
	src: url("fonts/Arial-ItalicMT_6y.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg41" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="41/41.svg"
          type="image/svg+xml"
          id="pdf41"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_41" class="t s0_41">
          39{" "}
        </span>
        <span id="t2_41" class="t s1_41">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_41" class="t s1_41">
          -{" "}
        </span>
        <span id="t4_41" class="t s1_41">
          Мэдээллийн орчинд ёс суртахуунтай байх, хувийн мэдээллээ хамгаалах, үл
          таних{" "}
        </span>
        <span id="t5_41" class="t s1_41">
          хүний учруулж болох эрсдэлээс өөрийгөө хамгаалах мэдлэг ойлголт,
          чадвартай{" "}
        </span>
        <span id="t6_41" class="t s1_41">
          болгох{" "}
        </span>
        <span id="t7_41" class="t s1_41">
          б.Суурь боловсролд{" "}
        </span>
        <span id="t8_41" class="t s1_41">
          -{" "}
        </span>
        <span id="t9_41" class="t s1_41">
          Мэдээллийн орчинд байгаль, нийгмийн ухааны хичээлийг гүнзгийрүүлэн
          үзэх,{" "}
        </span>
        <span id="ta_41" class="t s1_41">
          алдартай хүмүүсийн амжилтад хүрсэн түүхээс судлах, зөв зохистой
          ашиглах{" "}
        </span>
        <span id="tb_41" class="t s1_41">
          боломжийг бүрдүүлэх{" "}
        </span>
        <span id="tc_41" class="t s1_41">
          -{" "}
        </span>
        <span id="td_41" class="t s1_41">
          Эрдэмтэн судлаачидтай уулзалт хийх, ирээдүйн мэргэжлийн чиг баримжаа,{" "}
        </span>
        <span id="te_41" class="t s1_41">
          хувийн чадвар, нөөц боломжоо тодорхойлоход дэмжлэг үзүүлэх{" "}
        </span>
        <span id="tf_41" class="t s1_41">
          -{" "}
        </span>
        <span id="tg_41" class="t s1_41">
          Мэдээллийн орчин дахь зохисгүй агуулга болон харилцаанд шүүмжлэлтэй{" "}
        </span>
        <span id="th_41" class="t s1_41">
          хандахад чиглүүлэх{" "}
        </span>
        <span id="ti_41" class="t s1_41">
          в.Бүрэн дунд боловсролд{" "}
        </span>
        <span id="tj_41" class="t s1_41">
          -{" "}
        </span>
        <span id="tk_41" class="t s1_41">
          Цахим орчны нээлттэй нөөцүүдээс суралцах, өөрийгөө хөгжүүлэх чиглэлээр{" "}
        </span>
        <span id="tl_41" class="t s1_41">
          багшийн чиглүүлгээр тодорхой даалгавруудыг гүйцэтгэх, хамтын судалгаа
          хийх,{" "}
        </span>
        <span id="tm_41" class="t s1_41">
          тайлагнах{" "}
        </span>
        <span id="tn_41" class="t s1_41">
          -{" "}
        </span>
        <span id="to_41" class="t s1_41">
          Мэдээллийн орчин дахь харилцаа, мэдээлэл хүлээж авах олон хэлбэрүүдийг{" "}
        </span>
        <span id="tp_41" class="t s1_41">
          судлах, бусад хичээлтэй холбож багш нартай хамтран ажиллах{" "}
        </span>
        <span id="tq_41" class="t s1_41">
          -{" "}
        </span>
        <span id="tr_41" class="t s1_41">
          Өсвөр насныхан бусдын хараа хяналтгүй цахим мэдээллийн орчноос шинэ{" "}
        </span>
        <span id="ts_41" class="t s1_41">
          найз нөхөд хайж олох, өөрийн үе тэнгийнхэнтэй харилцаа холбоо тогтоох
          хүсэл{" "}
        </span>
        <span id="tt_41" class="t s1_41">
          эрмэлзлэл ихтэй тул сониуч зан, сэтгэл хөдлөлөө хянахад дэмжлэг
          үзүүлэх{" "}
        </span>
        <span id="tu_41" class="t s2_41">
          5.1.4.Арга зүй{" "}
        </span>
        <span id="tv_41" class="t s1_41">
          Бага, суурь, бүрэн дунд боловсролын түвшинд: Мэдээллийн орчны
          зохистой, соёлтой{" "}
        </span>
        <span id="tw_41" class="t s1_41">
          хэрэглээг хэвшүүлнэ.{" "}
        </span>
        <span id="tx_41" class="t s1_41">
          Интернэт, гар утас, бусад цахим мэдээллийн хэрэгсэл хүүхэд, өсвөр
          үеийнхэнд сурч{" "}
        </span>
        <span id="ty_41" class="t s1_41">
          боловсрох, нийгмийн харилцаанд идэвхтэй оролцох, хөгжих мэдээлэл авах,
          нийгмийн{" "}
        </span>
        <span id="tz_41" class="t s1_41">
          сүлжээгээ өргөжүүлэх, хувийн мэдээллийн санг бий болгох, бүтээлч
          байдлаа хөгжүүлэх{" "}
        </span>
        <span id="t10_41" class="t s1_41">
          эерэг боломжуудыг олгох зэрэг олон ашиг тустайн зэрэгцээ бас аюул,
          эрсдэл дагуулж{" "}
        </span>
        <span id="t11_41" class="t s1_41">
          байна.{" "}
        </span>
        <span id="t12_41" class="t s1_41">
          Цахим орчинд аюулгүй байхын тулд хүүхдүүд юу мэдэх, юу хийх
          шаардлагатай вэ?{" "}
        </span>
        <span id="t13_41" class="t s3_41">
          Загвар 1: Дүрэм зохиох{" "}
        </span>
        <span id="t14_41" class="t s1_41">
          Сэдэв: Цахим мэдээллийн орчны зохистой хэрэглээний дүрэм{" "}
        </span>
        <span id="t15_41" class="t s4_41">
          •{" "}
        </span>
        <span id="t16_41" class="t s1_41">
          1-5 дугаар ангийн хүүхдүүд-Гэр бүлдээ мөрдөх дүрэм зохионо.{" "}
        </span>
        <span id="t17_41" class="t s4_41">
          •{" "}
        </span>
        <span id="t18_41" class="t s1_41">
          6-8 дугаар{" "}
        </span>
        <span id="t19_41" class="t s1_41">
          ангийн хүүхдүүд-Сургуулийн орчинд дагаж мөрдөх дүрэм зохионо.{" "}
        </span>
        <span id="t1a_41" class="t s4_41">
          •{" "}
        </span>
        <span id="t1b_41" class="t s1_41">
          9-12 дугаар ангийн хүүхдүүд-Цахим группын дүрэм \Фэйсбүүк групп\
          зохиож{" "}
        </span>
        <span id="t1c_41" class="t s1_41">
          мөрдөнө.{" "}
        </span>
        <span id="t1d_41" class="t s1_41">
          Зорилго: Дүрэм зохиоход суралцах, дүрмийн тухай мэдлэгээ баяжуулах{" "}
        </span>
        <span id="t1e_41" class="t s1_41">
          Багш дүрмийн талаар ярилцана. Дүрэм нь хүмүүсийг бие биеэ ойлгох,
          хамтран суралцах,{" "}
        </span>
        <span id="t1f_41" class="t s1_41">
          ажиллах, харилцан дэмжихэд тусалдаг. Дүрэм нь заалтуудаас бүрдэх
          бөгөөд заалтууд нь{" "}
        </span>
        <span id="t1g_41" class="t s1_41">
          зөвшөөрсөн, хориглосон эсвэл үүрэг болгосон байдаг. Дүрмийг хэлэлцэж
          гаргахад тодорхой{" "}
        </span>
        <span id="t1h_41" class="t s1_41">
          үе шат бий. Дүрэм гаргахын тулд уг дүрмийг хэрэглэх хүмүүс
          байгууллагыг оролцуулж{" "}
        </span>
        <span id="t1i_41" class="t s1_41">
          саналыг нь авах хэрэгтэй. Хүн бүр дүрмээ мэдэж байх нь түүнийг
          хэрэгжүүлэх нэг үндэс{" "}
        </span>
        <span id="t1j_41" class="t s1_41">
          болно. Дүрмийн хориглосон заалтыг зөрчвөл уг зөрчилд тохирсон
          хариуцлага оногдуулдаг.{" "}
        </span>
        <span id="t1k_41" class="t s1_41">
          Зөрчил гаргасан бол хариуцлага хүлээх нь шударга ёсонд нийцнэ.{" "}
        </span>
        <span id="t1l_41" class="t s1_41">
          Эргэцүүлэх асуулт:{" "}
        </span>
        <span id="t1m_41" class="t s1_41">
          1.{" "}
        </span>
        <span id="t1n_41" class="t s1_41">
          Дүрэм гэж юу вэ? та бүхэн гэртээ зурагт үзэх, гар утас таблет ашиглах
          болон сургууль{" "}
        </span>
        <span id="t1o_41" class="t s1_41">
          дээр хичээлийн үеэр гар утас хэрэглэх, ангийн болон найзуудын фэйсбүүк
          групп дээр{" "}
        </span>
        <span id="t1p_41" class="t s1_41">
          ямар, ямар дүрэм мөрддөг вэ?{" "}
        </span>
        <span id="t1q_41" class="t s1_41">
          2.{" "}
        </span>
        <span id="t1r_41" class="t s1_41">
          Дүрэмтэй байх нь хүмүүс хоорондын харилцаанд хэрхэн нөлөөлдөг вэ?
          Загвар гарган{" "}
        </span>
        <span id="t1s_41" class="t s1_41">
          тайлбарлана уу? Ангийн фэйсбүүк группын болон сургуулийн дүрэм ямар
          ялгаатай вэ?{" "}
        </span>
        <span id="t1t_41" class="t s1_41">
          Яагаад ялгаатай байдаг вэ?{" "}
        </span>
        <span id="t1u_41" class="t s1_41">
          3.{" "}
        </span>
        <span id="t1v_41" class="t s1_41">
          Дүрмийн талаар шинээр мэдэж авсан зүйлээ тэмдэглээрэй.{" "}
        </span>
        <span id="t1w_41" class="t s1_41">
          -{" "}
        </span>
        <span id="t1x_41" class="t s1_41">
          Ярилцсаны дараа хүүхдүүдийг баг болгон хувааж (ангиас хамаарч гэртээ,
          сургууль{" "}
        </span>
        <span id="t1y_41" class="t s1_41">
          дээр, фэйсбүүк групп дотор) ямар дүрэм мөрдөх талаар ярилцана.{" "}
        </span>
        <span id="t1z_41" class="t s1_41">
          -{" "}
        </span>
        <span id="t20_41" class="t s1_41">
          Баг тус бүр мөрдөх дүрмүүдийг ярилцаж, жагсаан бичиж танилцуулна.{" "}
        </span>
        <span id="t21_41" class="t s1_41">
          -{" "}
        </span>
        <span id="t22_41" class="t s1_41">
          Эцэст нь багуудын дүрмийг нэгтгэж, хамгийн чухал гэсэн 10 заалтыг авч
          нэгтгэн{" "}
        </span>
        <span id="t23_41" class="t s1_41">
          мөрдөхийг санал болгоно.{" "}
        </span>
      </div>
    </div>
  );
}
