import React from "react";
;

export default function FiveNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "909px",
    height: "1286px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "909px",
    height: "1286px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p4" style={containerStyle}>
      <head>
        <style class="shared-css" type="text/css">
          {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
        </style>

        <style type="text/css">
          {`#t1_59{left:844px;bottom:21px;letter-spacing:0.16px;}
#t2_59{left:214px;bottom:1217px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_59{left:110px;bottom:1161px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t4_59{left:143px;bottom:1001.4px;letter-spacing:0.09px;word-spacing:0.05px;}
#t5_59{left:449px;bottom:1138px;letter-spacing:0.01px;}
#t6_59{left:174.4px;bottom:968.7px;letter-spacing:0.13px;}
#t7_59{left:190.9px;bottom:968.7px;letter-spacing:0.07px;word-spacing:0.06px;}
#t8_59{left:207.4px;bottom:968.7px;letter-spacing:0.17px;word-spacing:-0.01px;}
#t9_59{left:253.3px;bottom:968.7px;letter-spacing:0.11px;word-spacing:0.03px;}
#ta_59{left:269.8px;bottom:968.7px;letter-spacing:0.1px;word-spacing:0.03px;}
#tb_59{left:286.2px;bottom:968.7px;letter-spacing:0.03px;}
#tc_59{left:332.2px;bottom:968.7px;letter-spacing:0.09px;word-spacing:0.04px;}
#td_59{left:348.7px;bottom:968.7px;letter-spacing:0.11px;word-spacing:0.03px;}
#te_59{left:365.2px;bottom:968.7px;letter-spacing:0.11px;word-spacing:0.02px;}
#tf_59{left:381.7px;bottom:968.7px;letter-spacing:0.12px;}
#tg_59{left:427.4px;bottom:968.7px;letter-spacing:0.11px;word-spacing:0.02px;}
#th_59{left:443.9px;bottom:968.7px;letter-spacing:0.12px;word-spacing:0.02px;}
#ti_59{left:491.6px;bottom:968.7px;letter-spacing:0.11px;}
#tj_59{left:508.1px;bottom:968.7px;letter-spacing:0.07px;word-spacing:0.07px;}
#tk_59{left:524.6px;bottom:968.7px;letter-spacing:0.13px;word-spacing:0.01px;}
#tl_59{left:574.1px;bottom:968.7px;letter-spacing:0.13px;word-spacing:0.01px;}
#tm_59{left:590.6px;bottom:968.7px;letter-spacing:0.12px;word-spacing:0.01px;}
#tn_59{left:607.1px;bottom:968.7px;letter-spacing:0.14px;word-spacing:0.01px;}
#to_59{left:656.5px;bottom:968.7px;letter-spacing:0.12px;word-spacing:0.01px;}
#tp_59{left:673px;bottom:968.7px;letter-spacing:0.14px;}
#tq_59{left:689.5px;bottom:968.7px;letter-spacing:0.16px;word-spacing:-0.01px;}
#tr_59{left:735.3px;bottom:968.7px;letter-spacing:0.11px;word-spacing:0.02px;}
#ts_59{left:751.8px;bottom:968.7px;letter-spacing:0.14px;}
#tt_59{left:768.3px;bottom:968.7px;letter-spacing:0.14px;}
#tu_59{left:118px;bottom:937px;}
#tv_59{left:118px;bottom:911px;letter-spacing:0.15px;}
#tw_59{left:118px;bottom:884px;letter-spacing:-0.99px;}
#tx_59{left:118px;bottom:858px;letter-spacing:0.15px;}
#ty_59{left:118px;bottom:831px;}
#tz_59{left:118px;bottom:805px;}
#t10_59{left:118px;bottom:779px;}
#t11_59{left:118px;bottom:752px;}
#t12_59{left:118px;bottom:726px;}
#t13_59{left:110px;bottom:687px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_59{left:118px;bottom:662px;}
#t15_59{left:198px;bottom:647px;letter-spacing:0.12px;word-spacing:0.03px;}
#t16_59{left:486px;bottom:662px;letter-spacing:0.2px;}
#t17_59{left:687px;bottom:647px;letter-spacing:0.13px;word-spacing:0.02px;}
#t18_59{left:367px;bottom:632px;}
#t19_59{left:395px;bottom:632px;letter-spacing:0.15px;}
#t1a_59{left:429px;bottom:632px;letter-spacing:-0.99px;}
#t1b_59{left:464px;bottom:632px;letter-spacing:0.15px;}
#t1c_59{left:504px;bottom:632px;}
#t1d_59{left:535px;bottom:632px;}
#t1e_59{left:564px;bottom:632px;}
#t1f_59{left:593px;bottom:632px;}
#t1g_59{left:622px;bottom:632px;}
#t1h_59{left:650px;bottom:632px;}
#t1i_59{left:242px;bottom:605px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1j_59{left:118px;bottom:579px;}
#t1k_59{left:152px;bottom:579px;letter-spacing:0.02px;word-spacing:0.12px;}
#t1l_59{left:118px;bottom:553px;}
#t1m_59{left:152px;bottom:553px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1n_59{left:152px;bottom:536px;letter-spacing:0.14px;}
#t1o_59{left:231px;bottom:536px;letter-spacing:0.11px;word-spacing:0.01px;}
#t1p_59{left:152px;bottom:520px;letter-spacing:0.03px;word-spacing:0.1px;}
#t1q_59{left:152px;bottom:503px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1r_59{left:179px;bottom:487px;letter-spacing:0.13px;}
#t1s_59{left:179px;bottom:470px;letter-spacing:0.13px;}
#t1t_59{left:179px;bottom:454px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1u_59{left:179px;bottom:437px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1v_59{left:179px;bottom:421px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1w_59{left:295px;bottom:394px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1x_59{left:118px;bottom:368px;}
#t1y_59{left:152px;bottom:368px;letter-spacing:0.01px;word-spacing:0.12px;}
#t1z_59{left:152px;bottom:351px;letter-spacing:0.11px;word-spacing:0.02px;}
#t20_59{left:152px;bottom:335px;letter-spacing:0.1px;word-spacing:0.04px;}
#t21_59{left:675px;bottom:368px;letter-spacing:0.08px;}
#t22_59{left:675px;bottom:351px;letter-spacing:0.08px;}
#t23_59{left:675px;bottom:335px;letter-spacing:0.13px;word-spacing:0.01px;}
#t24_59{left:675px;bottom:318px;letter-spacing:0.09px;word-spacing:0.04px;}
#t25_59{left:675px;bottom:302px;letter-spacing:0.12px;word-spacing:0.01px;}
#t26_59{left:675px;bottom:285px;letter-spacing:0.12px;word-spacing:0.02px;}
#t27_59{left:675px;bottom:269px;letter-spacing:0.13px;}
#t28_59{left:675px;bottom:252px;letter-spacing:0.1px;word-spacing:0.03px;}
#t29_59{left:675px;bottom:236px;letter-spacing:0.12px;}
#t2a_59{left:118px;bottom:210px;}
#t2b_59{left:152px;bottom:210px;letter-spacing:0.12px;word-spacing:0.03px;}
#t2c_59{left:152px;bottom:193px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2d_59{left:152px;bottom:177px;letter-spacing:0.13px;}
#t2e_59{left:179px;bottom:160px;letter-spacing:0.07px;word-spacing:0.06px;}
#t2f_59{left:179px;bottom:144px;letter-spacing:0.13px;word-spacing:0.01px;}
#t2g_59{left:179px;bottom:127px;letter-spacing:0.04px;word-spacing:0.09px;}
#t2h_59{left:179px;bottom:111px;letter-spacing:0.08px;word-spacing:0.06px;}
#t2i_59{left:179px;bottom:94px;letter-spacing:0.14px;}
#t2j_59{left:179px;bottom:78px;letter-spacing:0.06px;word-spacing:0.07px;}
#t2k_59{left:179px;bottom:61px;letter-spacing:0.06px;word-spacing:0.08px;}

.s0_59{font-size:18px;font-family:MinionPro-Regular_6m;color:#FFF;}
.s1_59{font-size:17px;font-family:ArialMT_5-;color:#231F20;}
.s2_59{font-size:15px;font-family:ArialMT_5-;color:#000;}
.s3_59{font-size:15px;font-family:ArialMT_5-;color:#231F20;}
.s4_59{font-size:15px;font-family:ArialMT_5q;color:#231F20;}
.t.m0_59{transform:matrix(0,-1,1,0,0,0);}`}
        </style>

        <style id="fonts59" type="text/css">
          {`@font-face {
	font-family: ArialMT_5-;
	src: url("fonts/ArialMT_5-.woff") format("woff");
}

@font-face {
	font-family: ArialMT_5q;
	src: url("fonts/ArialMT_5q.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_6m;
	src: url("fonts/MinionPro-Regular_6m.woff") format("woff");
}
`}
        </style>
      </head>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg59" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="59/59.svg"
          type="image/svg+xml"
          id="pdf59"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_59" class="t s0_59">
          57{" "}
        </span>
        <span id="t2_59" class="t s1_59">
          Хичээлээс гадуурх сургалтын ажлын үлгэрчилсэн хөтөлбөр (сайжруулсан
          хувилбар){" "}
        </span>
        <span id="t3_59" class="t s1_59">
          Жишээ 2: Зорилтод тулгуурласан төлөвлөлт{" "}
        </span>
        <span id="t4_59" class="t m0_59 s2_59">
          Хугацаа (сараар){" "}
        </span>
        <span id="t5_59" class="t s3_59">
          Чиглэл{" "}
        </span>
        <span id="t6_59" class="t m0_59 s2_59">
          Гэр бүлийн хүрээнд{" "}
        </span>
        <span id="t7_59" class="t m0_59 s2_59">
          олгох хүмүүжил,{" "}
        </span>
        <span id="t8_59" class="t m0_59 s2_59">
          төлөвшлийг дэмжих{" "}
        </span>
        <span id="t9_59" class="t m0_59 s2_59">
          Эрүүл, аюулгүй{" "}
        </span>
        <span id="ta_59" class="t m0_59 s2_59">
          амьдрах арга ухаанд{" "}
        </span>
        <span id="tb_59" class="t m0_59 s2_59">
          сургах{" "}
        </span>
        <span id="tc_59" class="t m0_59 s2_59">
          Болзошгүй эрсдэл,{" "}
        </span>
        <span id="td_59" class="t m0_59 s2_59">
          зөрчлөөс урьдчилан{" "}
        </span>
        <span id="te_59" class="t m0_59 s2_59">
          сэргийлэх ур чадвар{" "}
        </span>
        <span id="tf_59" class="t m0_59 s2_59">
          эзэмшүүлэх{" "}
        </span>
        <span id="tg_59" class="t m0_59 s2_59">
          Бие бялдар, авьяас{" "}
        </span>
        <span id="th_59" class="t m0_59 s2_59">
          чадварыг хөгжүүлэх{" "}
        </span>
        <span id="ti_59" class="t m0_59 s2_59">
          Мэдээллийн{" "}
        </span>
        <span id="tj_59" class="t m0_59 s2_59">
          технологийн зохистой{" "}
        </span>
        <span id="tk_59" class="t m0_59 s2_59">
          хэрэглээг дэмжих{" "}
        </span>
        <span id="tl_59" class="t m0_59 s2_59">
          Суралцагчдын нийгэм,{" "}
        </span>
        <span id="tm_59" class="t m0_59 s2_59">
          олон нийтийн үйл{" "}
        </span>
        <span id="tn_59" class="t m0_59 s2_59">
          ажиллагааг дэмжих{" "}
        </span>
        <span id="to_59" class="t m0_59 s2_59">
          Эх оронч болон{" "}
        </span>
        <span id="tp_59" class="t m0_59 s2_59">
          хөдөлмөрийн{" "}
        </span>
        <span id="tq_59" class="t m0_59 s2_59">
          хүмүүжлийг дэмжих{" "}
        </span>
        <span id="tr_59" class="t m0_59 s2_59">
          Байгаль, экологийн{" "}
        </span>
        <span id="ts_59" class="t m0_59 s2_59">
          хүмүүжлийг{" "}
        </span>
        <span id="tt_59" class="t m0_59 s2_59">
          төлөвшүүлэх{" "}
        </span>
        <span id="tu_59" class="t s4_59">
          9{" "}
        </span>
        <span id="tv_59" class="t s3_59">
          10{" "}
        </span>
        <span id="tw_59" class="t s3_59">
          11{" "}
        </span>
        <span id="tx_59" class="t s3_59">
          12{" "}
        </span>
        <span id="ty_59" class="t s3_59">
          1{" "}
        </span>
        <span id="tz_59" class="t s4_59">
          2{" "}
        </span>
        <span id="t10_59" class="t s3_59">
          3{" "}
        </span>
        <span id="t11_59" class="t s4_59">
          4{" "}
        </span>
        <span id="t12_59" class="t s4_59">
          5{" "}
        </span>
        <span id="t13_59" class="t s1_59">
          Жишээ 3. ХГСА-н жилийн календарчилсан төлөвлөлт{" "}
        </span>
        <span id="t14_59" class="t s3_59">
          №{" "}
        </span>
        <span id="t15_59" class="t s3_59">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t16_59" class="t s3_59">
          Сараар{" "}
        </span>
        <span id="t17_59" class="t s3_59">
          Хариуцах эзэн{" "}
        </span>
        <span id="t18_59" class="t s4_59">
          9{" "}
        </span>
        <span id="t19_59" class="t s3_59">
          10{" "}
        </span>
        <span id="t1a_59" class="t s3_59">
          11{" "}
        </span>
        <span id="t1b_59" class="t s3_59">
          12{" "}
        </span>
        <span id="t1c_59" class="t s3_59">
          1{" "}
        </span>
        <span id="t1d_59" class="t s4_59">
          2{" "}
        </span>
        <span id="t1e_59" class="t s3_59">
          3{" "}
        </span>
        <span id="t1f_59" class="t s4_59">
          4{" "}
        </span>
        <span id="t1g_59" class="t s4_59">
          5{" "}
        </span>
        <span id="t1h_59" class="t s4_59">
          6{" "}
        </span>
        <span id="t1i_59" class="t s3_59">
          Гэр бүлийн хүрээнд олгох хүмүүжил, төлөвшлийг дэмжих{" "}
        </span>
        <span id="t1j_59" class="t s3_59">
          1{" "}
        </span>
        <span id="t1k_59" class="t s3_59">
          Эцэг эхийн уулзалт, сургалт{" "}
        </span>
        <span id="t1l_59" class="t s4_59">
          2{" "}
        </span>
        <span id="t1m_59" class="t s3_59">
          Сурагчдын дунд хүмүүжил{" "}
        </span>
        <span id="t1n_59" class="t s3_59">
          төлөвшил{" "}
        </span>
        <span id="t1o_59" class="t s3_59">
          рүү чиглэсэн{" "}
        </span>
        <span id="t1p_59" class="t s3_59">
          сургалт, нэрэмжит өдрүүд{" "}
        </span>
        <span id="t1q_59" class="t s3_59">
          аян зохион байгуулах{" "}
        </span>
        <span id="t1r_59" class="t s3_59">
          · Цахим хэрэглээ,{" "}
        </span>
        <span id="t1s_59" class="t s3_59">
          цахим гэмт хэргээс{" "}
        </span>
        <span id="t1t_59" class="t s3_59">
          урьдчилан сэргийлэх{" "}
        </span>
        <span id="t1u_59" class="t s3_59">
          · Эрх зүйн зөрчлөөс{" "}
        </span>
        <span id="t1v_59" class="t s3_59">
          урьдчилан сэргийлэх{" "}
        </span>
        <span id="t1w_59" class="t s3_59">
          Эрүүл, аюулгүй амьдрах арга ухаанд сургах{" "}
        </span>
        <span id="t1x_59" class="t s3_59">
          1{" "}
        </span>
        <span id="t1y_59" class="t s3_59">
          Эрүүл мэндийн үзлэг,{" "}
        </span>
        <span id="t1z_59" class="t s3_59">
          оношилгоо хийх (эрсдэлтэй{" "}
        </span>
        <span id="t20_59" class="t s3_59">
          болон зорилтот бүлгийн){" "}
        </span>
        <span id="t21_59" class="t s3_59">
          Сургуулийн{" "}
        </span>
        <span id="t22_59" class="t s3_59">
          захиргаа,{" "}
        </span>
        <span id="t23_59" class="t s3_59">
          Нийгмийн ажилтан,{" "}
        </span>
        <span id="t24_59" class="t s3_59">
          сургуулийн эмч{" "}
        </span>
        <span id="t25_59" class="t s3_59">
          нь харъяа дүүрэг{" "}
        </span>
        <span id="t26_59" class="t s3_59">
          хорооны эрүүл{" "}
        </span>
        <span id="t27_59" class="t s3_59">
          мэндийн төв,{" "}
        </span>
        <span id="t28_59" class="t s3_59">
          Өрхийн эмнэлэгтэй{" "}
        </span>
        <span id="t29_59" class="t s3_59">
          хамтрах{" "}
        </span>
        <span id="t2a_59" class="t s4_59">
          2{" "}
        </span>
        <span id="t2b_59" class="t s3_59">
          Аюулгүй амьдрах ухааны{" "}
        </span>
        <span id="t2c_59" class="t s3_59">
          сургалтын хөтөлбөрийг{" "}
        </span>
        <span id="t2d_59" class="t s3_59">
          хэрэгжүүлэх{" "}
        </span>
        <span id="t2e_59" class="t s3_59">
          · Гамшиг ослоос{" "}
        </span>
        <span id="t2f_59" class="t s3_59">
          урьдчилан сэргийлэх{" "}
        </span>
        <span id="t2g_59" class="t s3_59">
          · Халдварт өвчин бусад{" "}
        </span>
        <span id="t2h_59" class="t s3_59">
          өвчнөөс урьдчилан{" "}
        </span>
        <span id="t2i_59" class="t s3_59">
          сэргийлэх{" "}
        </span>
        <span id="t2j_59" class="t s3_59">
          ·Хорт зуршилд автахгүй{" "}
        </span>
        <span id="t2k_59" class="t s3_59">
          байх, мэдээлэлтэй байх{" "}
        </span>
      </div>
    </div>
  );
}
