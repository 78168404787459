import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";

export default function SideBar({ setPaperIndex, paperIndex }) {
  const menu = [
    { name: "НЭГ. ҮНДЭСЛЭЛ ", paper: 2, pages: 1, path: "" },
    {
      name: "ХОЁР. ХИЧЭЭЛЭЭС ГАДУУРХ ҮЙЛ АЖИЛЛАГААНЫ ТУХАЙ ОЙЛГОЛТ",
      paper: 2,
      pages: 1,
      path: "author",
    },
    { name: "ГУРАВ. ЗОРИЛГО", paper: 4, pages: 1, path: 1 },
    {
      name: "ДӨРӨВ. ЗОРИЛТ",
      paper: 4,
      pages: 1,
      path: 2,
    },
    {
      name: "ТАВ. ЗОРИЛТУУДЫН ХҮРЭЭНД ХЭРЭГЖҮҮЛЭХ ҮЙЛ АЖИЛЛАГАА",
      paper: 4,
      pages: 1,
      path: 3,
    },
    {
      name: "ЗУРГАА. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫГ ЗОХИОН БАЙГУУЛАХ МЕНЕЖЕМЕНТ",
      paper: 5,
      pages: 1,
      path: 4,
    },
    {
      name: "6.1. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТААР ХӨГЖҮҮЛЭХ ЧАДВАР",
      paper: 6,
      pages: 1,
      path: 5,
    },
    {
      name: "6.2. ХГСА-ЫГ ЗОХИОН БАЙГУУЛАХ ХЭЛБЭР",
      paper: 7,
      pages: 1,
      path: 6,
    },
    {
      name: "6.3. ОРЧИН, ХЭРЭГЛЭГДЭХҮҮН",
      paper: 8,
      pages: 1,
      path: 7,
    },
    {
      name: "ДОЛОО. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫГ ҮНЭЛЭХ",
      paper: 9,
      pages: 1,
      path: 8,
    },
    { name: "ЭХ СУРВАЛЖ", paper: 10, pages: 1, path: 9 },
    {
      name: "ХАВСРАЛТ 1. НЭМЭЛТ ХӨТӨЛБӨРИЙН ЖАГСААЛТ",
      paper: 10,
      pages: 1,
      path: 9,
    },
    {
      name: "ХАВСРАЛТ 2. ХИЧЭЭЛЭЭС ГАДУУРХ СУРГАЛТЫН АЖЛЫН ҮЛГЭРЧИЛСЭН ТӨЛӨВЛӨГӨӨ",
      paper: 11,
      pages: 1,
      path: 10,
    },
    {
      name: "ХГСА-ЫН ҮЛГЭРЧИЛСЭН ХӨТӨЛБӨРИЙГ ХЭРЭГЖҮҮЛЭХ ЗӨВЛӨМЖ",
      paper: 12,
      pages: 1,
      path: 12,
    },
  ];

  return (
    <List>
      {menu.map((element, index) => (
        <ListItem
          sx={
            {
              // backgroundColor: paperIndex === element.paper && "rgba(59,130,246,0.5)",
            }
          }
          key={index}
          disablePadding
        >
          <a href={`#${element.paper}`} style={{ textDecoration: "none" }}>
            <ListItemButton
              onClick={() => setPaperIndex(element.paper)}
              variant="contained"
            >
              <Grid container spacing={2}>
                <Grid xs={10} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 500,
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={element.name}
                  />
                </Grid>
                <Grid xs={2} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={`(${element.paper})`}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </a>
        </ListItem>
      ))}
    </List>
  );
}
